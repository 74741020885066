import { Grid } from '@mui/material';
import React from 'react';
import { PickerPeriod } from '../../model/Pickers/PickerPeriod';
import ConnectedAverageDatePicker from '../PeriodPicker/ConnectedAverageDatePicker';

const AveragePeriodPicker: React.FC<AveragePeriodPickerProps> = ({
  dateDifference,
}: AveragePeriodPickerProps) => {
  return (
    <React.Fragment>
      <Grid item>
        <ConnectedAverageDatePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
      </Grid>
      <Grid item>
        <ConnectedAverageDatePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
      </Grid>
    </React.Fragment>
  );
};

interface AveragePeriodPickerProps {
  dateDifference?: number;
}

export default AveragePeriodPicker;
