import {
  CompanyDto,
  Device,
  IconCreationDto,
  IconScope,
  Pagination,
  Port,
} from '@thingslog/repositories';
import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  ReactNode,
  SyntheticEvent,
  MouseEvent,
} from 'react';
import { CompanyDropdownOptions, DeviceDropdownOptions, SensorDropdownOptions } from './models';
import JwtValidator from '../../common/JwtValidator';
import {
  companyQueryClient,
  devicesQueryClient,
  iconsQueryClient,
  initialConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { VisuallyHiddenInput } from './components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import { useToast } from '@thingslog/ui-components';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

export const AddIconPage: FC<AddIconPageProps> = ({}: AddIconPageProps) => {
  const { useCreateIcon } = iconsQueryClient;
  const { useDevicesData } = devicesQueryClient;
  const { useDevicePortsConfigData } = initialConfigQueryClient;
  const { useGetAllCompanies } = companyQueryClient;
  const { hasRole } = new JwtValidator();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();

  // #region State
  const [scope, setScope] = useState<IconScope>(IconScope.COMPANY);
  const [name, setName] = useState<string>('');
  const [fileBase64, setFileBase64] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);

  const [isDeviceSelectorVisible, setIsDeviceSelectorVisible] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceDropdownOptions | null>(null);
  const [deviceOptions, setDeviceOptions] = useState<DeviceDropdownOptions[]>([]);

  const [isSensorSelectorVisible, setIsSensorSelectorVisible] = useState<boolean>(false);
  const [selectedSensor, setSelectedSensor] = useState<SensorDropdownOptions | null>(null);
  const [sensorOptions, setSensorOptions] = useState<SensorDropdownOptions[]>([]);

  const [isCompanySelectorVisible, setIsCompanySelectorVisible] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDropdownOptions | null>(null);
  const [companyOptions, setCompanyOptions] = useState<CompanyDropdownOptions[]>([]);
  const reduxCompanyId = useSelector((state: ReduxState) => state.company.id);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  // #endregion

  // #region Queries/Mutations
  const createIconMutation = useCreateIcon({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetIcons]);
      navigate('/app/icons');
    },
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: error.response?.data?.message || t('icon_error_error_occured'),
      });
    },
  });

  const devicesQuery = useDevicesData(reduxCompanyId, 0, 9999, null, null, {
    enabled: isDeviceSelectorVisible,
    refetchOnWindowFocus: false,

    onSuccess: (data: Pagination<Device>) => {
      const deviceOptions: DeviceDropdownOptions[] = [];
      data.content.forEach((device: Device) => {
        deviceOptions.push({ deviceNumber: device.number, deviceName: device.name });
      });
      setDeviceOptions(deviceOptions);
      if (deviceOptions.length > 0 && !selectedDevice) {
        setSelectedDevice(deviceOptions[0]);
      }
    },
  });

  const portsQuery = useDevicePortsConfigData(selectedDevice?.deviceNumber || '', {
    enabled: selectedDevice !== null && isSensorSelectorVisible,
    refetchOnWindowFocus: false,
    onSuccess: (data: Record<number, Port>) => {
      const sensorOptions: SensorDropdownOptions[] = [];
      Object.entries(data).forEach(([index, port]: [string, Port]) => {
        sensorOptions.push({ sensorIndex: parseInt(index), sensorName: port.sensor.name });
      });
      setSensorOptions(sensorOptions);
    },
  });

  const companyQuery = useGetAllCompanies({
    enabled: isCompanySelectorVisible,
    refetchOnWindowFocus: false,
    onSuccess: (data: CompanyDto[]) => {
      const companyOptions: CompanyDropdownOptions[] = [];
      data.forEach((company: CompanyDto) => {
        companyOptions.push({ companyId: company.id, companyName: company.name });
      });
      setCompanyOptions(companyOptions);
      if (companyOptions.length > 0) {
        setSelectedCompany(companyOptions[0]);
      }
    },
  });

  // #endregion

  // #region Effects
  useEffect(() => {
    switch (scope) {
      case IconScope.GLOBAL:
        setIsCompanySelectorVisible(false);
        setIsDeviceSelectorVisible(false);
        setIsSensorSelectorVisible(false);
        break;
      case IconScope.COMPANY:
        setIsCompanySelectorVisible(true);
        setIsDeviceSelectorVisible(false);
        setIsSensorSelectorVisible(false);
        break;
      case IconScope.DEVICE:
        setIsCompanySelectorVisible(false);
        setIsDeviceSelectorVisible(true);
        setIsSensorSelectorVisible(false);
        break;
      case IconScope.SENSOR:
        setIsCompanySelectorVisible(false);
        setIsDeviceSelectorVisible(true);
        setIsSensorSelectorVisible(true);
        break;
      default:
        setIsCompanySelectorVisible(false);
        setIsDeviceSelectorVisible(false);
        setIsSensorSelectorVisible(false);
    }
  }, [scope]);

  useEffect(() => {
    setSelectedSensor(null);
    if (selectedDevice) {
      portsQuery.refetch();
    }
  }, [selectedDevice]);

  useEffect(() => {
    setSelectedDevice(null);
    setSelectedSensor(null);
    setSelectedCompany(null);
  }, [scope]);

  useEffect(() => {
    let error: string | null = null;
    let isSubmitDisabled = false;
    if (!name) {
      error = t('icon_error_enter_name');
      isSubmitDisabled = true;
    } else if (!fileBase64) {
      error = t('icon_error_enter_icon');
      isSubmitDisabled = true;
    } else if (scope === IconScope.COMPANY && !selectedCompany) {
      error = t('icon_error_select_company');
      isSubmitDisabled = true;
    } else if (scope === IconScope.DEVICE && !selectedDevice) {
      error = t('icon_error_select_device');
      isSubmitDisabled = true;
    } else if (scope === IconScope.SENSOR && !selectedDevice) {
      error = t('icon_error_select_device');
      isSubmitDisabled = true;
    } else if (scope === IconScope.SENSOR && !selectedSensor) {
      error = t('icon_error_select_sensor');
      isSubmitDisabled = true;
    }
    setError(error);
    setIsSubmitDisabled(isSubmitDisabled);
  }, [scope, name, fileBase64, selectedDevice, selectedSensor, selectedCompany]);
  // #endregion

  // #region Event Handlers
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    if (!['image/jpeg', 'image/png', 'image/svg+xml'].includes(fileType)) {
      setError(t('icon_error_unsupported_file_type'));
      setFileBase64(null);
      return;
    }
    setError(null);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      setFileBase64(reader.result as string);
    };
  };

  const handleSubmit = (): void => {
    if (!name) return;
    if (!fileBase64) return;
    if (scope === IconScope.COMPANY && !selectedCompany) return;
    if (scope === IconScope.DEVICE && !selectedDevice) return;
    if (scope === IconScope.SENSOR && !selectedDevice) return;
    if (scope === IconScope.SENSOR && !selectedSensor) return;

    let body: IconCreationDto | null = null;
    const icon = fileBase64.split(',')[1];

    if (scope === IconScope.GLOBAL) {
      body = {
        type: 'global',
        icon: icon,
        name: name,
      };
    } else if (scope === IconScope.COMPANY && selectedCompany) {
      body = {
        type: 'company',
        icon: icon,
        name: name,
        companyId: selectedCompany.companyId,
      };
    } else if (scope === IconScope.DEVICE && selectedDevice) {
      body = {
        type: 'device',
        icon: icon,
        name: name,
        deviceNumber: selectedDevice.deviceNumber,
      };
    } else if (scope === IconScope.SENSOR && selectedDevice && selectedSensor) {
      body = {
        type: 'sensor',
        icon: icon,
        name: name,
        deviceNumber: selectedDevice.deviceNumber,
        sensorIndex: selectedSensor.sensorIndex,
      };
    }

    body && createIconMutation.mutate(body);
  };
  // #endregion

  // #region Render
  return (
    <Header>
      <main className="flex justify-center items-center">
        <section className="flex flex-col gap-2 bg-gray-100 p-3 rounded-lg">
          <div className="flex gap-1.5">
            <TextField
              className="w-64"
              label={t('icon_input_name')}
              size="small"
              value={name}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => setName(event.target.value)}
            />
            <Button
              component="label"
              variant="contained"
              disableElevation
              className="flex flex-nowrap w-64"
              startIcon={<CloudUploadIcon />}
            >
              {t('icon_btn_upload_file')}
              <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
            </Button>
          </div>

          <div className="flex gap-1.5">
            <FormControl className="w-64">
              <InputLabel>{t('icon_scope')}</InputLabel>
              <Select
                size="small"
                value={scope}
                label={t('icon_scope')}
                onChange={(event: SelectChangeEvent<IconScope>): void => {
                  setScope(event.target.value as IconScope);
                }}
              >
                {hasRole('ROLE_SUPER_ADMIN') && (
                  <MenuItem value={IconScope.GLOBAL}>{t('icon_scope_global')}</MenuItem>
                )}
                <MenuItem value={IconScope.COMPANY}>{t('icon_scope_company')}</MenuItem>
                <MenuItem value={IconScope.DEVICE}>{t('icon_scope_device')}</MenuItem>
                <MenuItem value={IconScope.SENSOR}>{t('icon_scope_sensor')}</MenuItem>
              </Select>
            </FormControl>
            {isDeviceSelectorVisible && devicesQuery.isSuccess && (
              <Autocomplete
                className="w-64"
                value={selectedDevice}
                onChange={(event: SyntheticEvent, value: DeviceDropdownOptions | null): void => {
                  setSelectedDevice(value);
                }}
                options={deviceOptions}
                getOptionLabel={(option: DeviceDropdownOptions): string =>
                  `${option.deviceName} (${option.deviceNumber})`
                }
                renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                  <TextField {...params} size="small" label={t('icon_input_device')} />
                )}
              />
            )}
            {isDeviceSelectorVisible && devicesQuery.isLoading && <CircularProgress size={28} />}

            {isSensorSelectorVisible && selectedDevice !== null && portsQuery.isSuccess && (
              <Autocomplete
                className="w-64"
                value={selectedSensor}
                onChange={(event: SyntheticEvent, value: SensorDropdownOptions | null): void => {
                  setSelectedSensor(value);
                }}
                options={sensorOptions}
                getOptionLabel={(option: SensorDropdownOptions): string => option.sensorName}
                renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                  <TextField {...params} size="small" label={t('icon_input_sensor')} />
                )}
              />
            )}
            {isSensorSelectorVisible && selectedDevice !== null && portsQuery.isLoading && (
              <CircularProgress size={28} />
            )}
            {isCompanySelectorVisible && companyQuery.isSuccess && (
              <Autocomplete
                className="w-64"
                value={selectedCompany}
                onChange={(event: SyntheticEvent, value: CompanyDropdownOptions | null): void => {
                  setSelectedCompany(value);
                }}
                options={companyOptions}
                getOptionLabel={(option: CompanyDropdownOptions): string => option.companyName}
                renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                  <TextField {...params} size="small" label={t('icon_input_company')} />
                )}
              />
            )}
            {isCompanySelectorVisible && companyQuery.isLoading && <CircularProgress size={28} />}
          </div>
          <div className="flex justify-center">
            {fileBase64 && (
              <img src={fileBase64} className="w-64 h-auto" alt="uploaded image preview" />
            )}
          </div>
          <div className="flex justify-end items-center gap-1.5">
            {error && <span className="font-bold text-red-600">{error}</span>}
            <Button
              onClick={handleSubmit}
              disableElevation
              disabled={isSubmitDisabled}
              className="w-40 flex flex-nowrap"
              variant="contained"
            >
              {t('icon_btn_add_icon')}
            </Button>
          </div>
        </section>
      </main>
    </Header>
  );
  // #endregion
};

interface AddIconPageProps {}
