import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';

const ConfirmDeleteCompanyModal: FC<ConfirmDeleteCompanyModalProps> = ({
  companyName,
  deleteCompany,
}: ConfirmDeleteCompanyModalProps) => {
  const { t } = useTranslation();
  const { modal, closeModal } = useModal();

  return (
    <>
      <div>
        <div className="mb-2">{t('company_manager_delete_modal_description')}</div>
        <div className="flex p-5 justify-center items-center space-x-5">
          <Button
            variant="contained"
            color={'error'}
            className="w-5/6"
            onClick={(): void => {
              deleteCompany(companyName);
            }}
          >
            {t('company_manager_confirm_button')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="w-5/6"
            onClick={(): void => closeModal()}
          >
            {t('company_manager_cancel_button')}
          </Button>
        </div>
      </div>
    </>
  );
};

interface ConfirmDeleteCompanyModalProps {
  deleteCompany: (companyName: string) => void;
  companyName: string;
}

export default ConfirmDeleteCompanyModal;
