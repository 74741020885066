import Axios from './clients/Axios/Axios';

function getTimeZones(cb, ecb) {
  return Axios.get(`/api/timezones`).then(parseJSON).then(cb).catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceCountersClient = {
  getTimeZones,
};

export default DeviceCountersClient;
