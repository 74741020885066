import React, { FC, ReactElement, useMemo } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Controller, UseControllerReturn, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import Header from '../../components/header';
import { ReduxState } from '../../reducers';
import {
  deviceConfigQueryClient,
  eventValueRangeQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useToast } from '@thingslog/ui-components';
import { CopyConfigType } from '@thingslog/repositories';

const CopyConfigPage: FC<CopyConfigPageProps> = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { control, formState, register, handleSubmit } = useForm<CopyConfigFormFields>({
    defaultValues: {
      copyConfigType: 'REPLACE_EXISTING_DEVICE',
      fromDeviceNumber: null,
      shouldCopyConfig: true,
      shouldCopyValueRanges: true,
    },
    mode: 'onChange',
  });

  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);

  const { useCopyDeviceConfig } = useMemo(() => deviceConfigQueryClient, []);
  const { useCopyValueRanges } = useMemo(() => eventValueRangeQueryClient, []);

  const { mutate: copyDeviceConfig } = useCopyDeviceConfig({
    onSuccess: () => {
      toast({ message: t('copy_config_toast_copied_success'), type: 'success' });
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data?.message;
      toast({ message: message || t('copy_config_toast_copied_error'), type: 'error' });
    },
  });

  const { mutate: copyValueRanges } = useCopyValueRanges({
    onSuccess: () => {
      toast({ message: t('copy_config_value_ranges_toast_copied_success'), type: 'success' });
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data?.message;
      toast({
        message: message || t('copy_config_value_ranges_toast_copied_error'),
        type: 'error',
      });
    },
  });

  const onSubmit = (copyConfigFields: CopyConfigFormFields): void => {
    const { copyConfigType, fromDeviceNumber, shouldCopyConfig, shouldCopyValueRanges } =
      copyConfigFields;

    if (fromDeviceNumber === null || !selectedDevice) return;

    if (shouldCopyConfig) {
      copyDeviceConfig({
        copyConfigType,
        fromDeviceNumber,
        toDeviceNumber: selectedDevice.number,
      });
    }

    if (shouldCopyValueRanges) {
      copyValueRanges({
        copyDeviceNumber: fromDeviceNumber,
        deviceNumbers: [selectedDevice.number],
      });
    }
  };

  return (
    <Header>
      <div className="flex flex-col w-[420px] max-sm:w-full m-auto shadow-sm shadow-gray-400 rounded-md p-5">
        <div className="font-semibold text-2xl">{t('copy_config_copy_config')}</div>
        {selectedDevice && (
          <div>
            {t('copy_config_to_device_number_with_name', {
              device_name: selectedDevice.name,
              device_number: selectedDevice.number,
              context: selectedDevice.name ? 'present' : 'absent',
            })}
          </div>
        )}

        <form className="flex flex-col gap-4 mt-5" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('fromDeviceNumber', {
              required: true,
              minLength: 8,
              maxLength: 8,
              setValueAs: (value: string) => value || null,
            })}
            fullWidth
            size="small"
            label={`${t('copy_config_from_device_number')}`}
          />

          <Divider />

          <Controller
            control={control}
            name="copyConfigType"
            render={({
              field,
            }: UseControllerReturn<CopyConfigFormFields, 'copyConfigType'>): ReactElement => (
              <FormControl fullWidth>
                <FormLabel className="mr-auto">{t('copy_config_copy_config_type_label')}</FormLabel>
                <RadioGroup className="grid grid-cols-2 gap-10 px-3" {...field}>
                  <FormControlLabel
                    value="REPLACE_EXISTING_DEVICE"
                    control={<Radio />}
                    label={t('copy_config_existing_device_option')}
                  />
                  <FormControlLabel
                    value="CREATE_NEW_DEVICE"
                    control={<Radio />}
                    label={t('copy_config_new_device_option')}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Divider />

          <FormControl fullWidth>
            <FormLabel className="mr-auto">{t('copy_config_should_copy_label')}</FormLabel>
            <FormGroup className="grid grid-cols-2 gap-10 px-3">
              <Controller
                control={control}
                name="shouldCopyConfig"
                render={({
                  field,
                }: UseControllerReturn<CopyConfigFormFields, 'shouldCopyConfig'>): ReactElement => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t('copy_config_should_copy_config_option')}
                  />
                )}
              />

              <Controller
                control={control}
                name="shouldCopyValueRanges"
                render={({
                  field,
                }: UseControllerReturn<
                  CopyConfigFormFields,
                  'shouldCopyValueRanges'
                >): ReactElement => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t('copy_config_should_copy_value_ranges_option')}
                  />
                )}
              />
            </FormGroup>
          </FormControl>

          <Divider />

          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={formState.isValid === false}
          >
            {t('copy_config_btn_copy')}
          </Button>
        </form>
      </div>
    </Header>
  );
};

interface CopyConfigPageProps {}

interface CopyConfigFormFields {
  fromDeviceNumber: string | null;
  copyConfigType: CopyConfigType;
  shouldCopyConfig: boolean;
  shouldCopyValueRanges: boolean;
}

export default CopyConfigPage;
