import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const LicenseValidityIcon: FC<LicenseValidityIconProps> = ({
  licenseValidity,
}: LicenseValidityIconProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col items-center">
        <div
          className={`flex items-center justify-center h-16 w-16 rounded-full ${
            licenseValidity ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          {licenseValidity ? (
            <svg
              className="w-8 h-8 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          ) : (
            <svg
              className="w-8 h-8 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          )}
        </div>
        <p className="mt-2">
          {licenseValidity ? t('profile_page_valid_license') : t('profile_page_invalid_license')}
        </p>
      </div>
    </>
  );
};

interface LicenseValidityIconProps {
  licenseValidity: boolean;
}

export default LicenseValidityIcon;
