import React, { FC, useState } from 'react';
import { Button, Divider, TextField } from '@mui/material';
import { useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import ChildCompaniesModal from './ChildCompaniesModal';

const ChildCompanies: FC<ChildCompaniesProps> = ({ childCompanies }: ChildCompaniesProps) => {
  const { t } = useTranslation();
  const { modal, closeModal } = useModal();

  const showCompanies = (): void => {
    modal({
      title: t('accounts_child_companies'),
      content: <ChildCompaniesModal childCompanies={childCompanies} />,
    });
  };

  return (
    <div
      className="flex whitespace-normal break-words cursor-pointer transition duration-150 ease-in-out transform hover:scale-105 hover:bg-gray-200 hover:shadow-lg p-2 rounded-md"
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        event.stopPropagation();
        showCompanies();
      }}
    >
      {t('accounts_child_companies')} ({childCompanies.length})
    </div>
  );
};

interface ChildCompaniesProps {
  childCompanies: string[];
}

export default ChildCompanies;
