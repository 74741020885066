import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import EventValueRangeFunction from '@thingslog/repositories/src/valueRanges/EventValueRangeFunction';
import { format, getDaysInMonth } from 'date-fns';
import React, { FC, useState, SyntheticEvent, ChangeEvent } from 'react';
import { useModal } from '@thingslog/ui-components';
import EventNotificationType from '@thingslog/repositories/src/valueRanges/EventNotificationType';
import { eventValueRangeQueryClient } from '../../../clients/ReactQueryClients/ReactQueryClients';
import { EventValueRange } from '@thingslog/repositories';
import { Styles } from '@thingslog/repositories/src/valueRanges/Styles';
import LineChartStyles from '@thingslog/repositories/src/valueRanges/LineChartStyles';
import MarkerStyles from '@thingslog/repositories/src/valueRanges/MarkerStyles';
import { useQueryClient } from '@tanstack/react-query';
import ColorPickerButton from './ColorPickerButton';
import { useTranslation } from 'react-i18next';

const ConfirmDeleteValueRange: React.FC<ConfirmDeleteValueRangeProps> = ({
  deviceNumber,
  sensorIndex,
  valueRangeName,
}: ConfirmDeleteValueRangeProps) => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const eventValueRangeClient = eventValueRangeQueryClient;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { mutate: deleteValueRange } = eventValueRangeClient.useDeleteValueRangeData(
    deviceNumber,
    sensorIndex,
    valueRangeName,
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        closeModal();
      },
      onError: () => setErrorMsg(t('value_range_error_occured')),
    }
  );
  return (
    <div className="flex flex-col space-y-1">
      <span className="m-auto">{t('value_ranges_remove_modal_body')}</span>
      {errorMsg && <span className="m-auto text-red-500 font-semibold">{errorMsg}</span>}
      <Button
        color="error"
        fullWidth
        variant="contained"
        disableElevation
        onClick={(): void => {
          deleteValueRange();
        }}
      >
        {t('delete')}
      </Button>
    </div>
  );
};

interface ConfirmDeleteValueRangeProps {
  deviceNumber: string;
  sensorIndex: number;
  valueRangeName: string;
}

export default ConfirmDeleteValueRange;
