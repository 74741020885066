import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StatisticsRepository from '../clients/Statistics/StatisticsClient';
import Histogram from '../model/API/Histogram/Histogram';
import { ReduxState } from '../reducers';

const useFetchFlowTimeHistogram = (
  number: string | undefined,
  sensorIndex: number | null,
  every: number | null
): UseFetchTimeHistogramProps => {
  const fromDate = useSelector((state: ReduxState) => state.period.fromDate);
  const toDate = useSelector((state: ReduxState) => state.period.toDate);
  const [time, setTime] = useState<Histogram | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const statisticsRepository = new StatisticsRepository();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const time = await statisticsRepository.getDeviceCounterHistogramByTime(
          number!,
          sensorIndex!,
          every!,
          fromDate,
          toDate
        );
        setTime(time);
      } catch {
        setTime(null);
        setError(t('error_cannot_fetch_time_stats'));
      }
      setLoading(false);
    };

    number && every !== null && sensorIndex !== null && fetchData();
  }, [number, every, sensorIndex, fromDate, toDate]);

  return { time: time, timeLoading: loading, timeError: error };
};

interface UseFetchTimeHistogramProps {
  time: Histogram | null;
  timeError: string | null;
  timeLoading: boolean;
}

export default useFetchFlowTimeHistogram;
