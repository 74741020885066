import React, { useState, SyntheticEvent, useEffect } from 'react';

import { Tab, Tabs } from '@mui/material';
import GraphDataGrid, { GraphDataGridRow } from './GraphDataGrid';
import { useTranslation } from 'react-i18next';
import DeviceSensorGraphData from '../../model/DeviceSensorGraphData';
import { TimeSeriesData } from '@thingslog/repositories';
import { SensorsUtil } from '../../../../common/SensorsUtil';

export const GraphDataWrapper: React.FC<GraphDataWrapperProps> = ({
  data,
}: GraphDataWrapperProps) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState('flowData');
  const [gridData, setGridData] = useState<Record<DisplayGridTab, GraphDataGridRow[]>>({
    flowData: [],
    averageData: [],
    predictionData: [],
  });

  useEffect(() => {
    let gridData: Record<DisplayGridTab, GraphDataGridRow[]> = {
      flowData: [],
      averageData: [],
      predictionData: [],
    };

    data.forEach((deviceSensor: DeviceSensorGraphData) => {
      let currFlowRows = gridData['flowData'];
      const flowData = deviceSensor.flowData;
      const units = SensorsUtil.getSensorUnits(deviceSensor.port.sensor);

      flowData &&
        flowData.forEach((timeSeriesData: TimeSeriesData<number>, index: number) => {
          currFlowRows.push({
            id: `${deviceSensor.deviceNumber}-${deviceSensor.sensorIndex}-${index}`,
            sensor: deviceSensor.port.sensor.name,
            index: deviceSensor.sensorIndex,
            deviceNumber: deviceSensor.deviceNumber,
            deviceName: deviceSensor.device.name,
            timestamp: timeSeriesData.timestamp,
            reading: timeSeriesData.value,
            prevReading: index > 0 ? flowData[index - 1].value : null,
            delta: index > 0 ? flowData[index].value - flowData[index - 1].value : null,
            units: units,
          });
        });
      gridData['flowData'] = currFlowRows;

      let currAverageFlow = gridData['averageData'];
      const averageData = deviceSensor.averageFlowData;
      averageData &&
        averageData.forEach((timeSeriesData: TimeSeriesData<number>, index: number) => {
          currAverageFlow.push({
            id: `${deviceSensor.deviceNumber}-${deviceSensor.sensorIndex}-${index}`,
            sensor: deviceSensor.port.sensor.name,
            index: deviceSensor.sensorIndex,
            deviceNumber: deviceSensor.deviceNumber,
            deviceName: deviceSensor.device.name,
            timestamp: timeSeriesData.timestamp,
            reading: timeSeriesData.value,
            prevReading: index > 0 ? averageData[index - 1].value : null,
            delta: index > 0 ? averageData[index].value - averageData[index - 1].value : null,
            units: units,
          });
        });
      gridData['averageData'] = currAverageFlow;

      let currPredictionRows = gridData['predictionData'];
      const predictionData = deviceSensor.predictionData;
      predictionData &&
        predictionData.forEach((timeSeriesData: TimeSeriesData<number>, index: number) => {
          currPredictionRows.push({
            id: `${deviceSensor.deviceNumber}-${deviceSensor.sensorIndex}-${index}`,
            sensor: deviceSensor.port.sensor.name,
            index: deviceSensor.sensorIndex,
            deviceNumber: deviceSensor.deviceNumber,
            deviceName: deviceSensor.device.name,
            timestamp: timeSeriesData.timestamp,
            reading: timeSeriesData.value,
            prevReading: index > 0 ? predictionData[index - 1].value : null,
            delta: index > 0 ? predictionData[index].value - predictionData[index - 1].value : null,
            units: units,
          });
        });
      gridData['predictionData'] = currPredictionRows;
    });
    setGridData(gridData);
  }, [data]);

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setTab(newValue);
  };

  return (
    <div className="w-full flex h-fit">
      <div className="flex-none mb-2 h-full overflow-auto">
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          orientation="vertical"
          variant="scrollable"
        >
          <Tab value="flowData" label={t('graph_tabs_readings')} />
          <Tab value="predictionData" label={t('graph_tabs_prediction')} />
          <Tab value="averageData" label={t('graph_tabs_average')} />
        </Tabs>
      </div>
      <GraphDataGrid
        className="flex-grow h-full overflow-auto"
        headerHeight={70}
        disableSelectionOnClick
        graphDataGridRows={gridData[tab]}
      />
    </div>
  );
};

interface GraphDataWrapperProps {
  data: DeviceSensorGraphData[];
}

type DisplayGridTab = 'flowData' | 'averageData' | 'predictionData';
