import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMonths, addYears, isToday } from 'date-fns';
import {
  AccountSolution,
  CountriesListDto,
  Country,
  CreateCompanyDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseType,
} from '@thingslog/repositories';
import { LocalizedDatePicker } from '@thingslog/ui-components';
import CreateAccountModalStepper from './CreateAccountModalStepper';
import JwtValidator from '../../../common/JwtValidator';
import LicenseMaxCount from '../../companyManager/components/LicenseMaxCountTextField';
import { CompanySelectionState } from '../../../reducers/company_reducer';

const CreateAccountModal: FC<CreateAccountModalProps> = ({
  company,
  countriesList,
  timezones,
  createCompany,
}: CreateAccountModalProps) => {
  const [activeStep, setActiveStep] = useState<0 | 1>(0);
  const [companyName, setCompanyName] = useState<string>('');
  const [solutionType, setSolutionType] = useState<AccountSolution | 'None'>('None');
  const [country, setCountry] = useState<string>('Bulgaria');
  const [timezoneId, setTimezoneId] = useState<string>('UTC');

  const [isContactIdSelected, setIsContactIdSelected] = useState(true);
  const [isAccountIdSelected, setIsAccountIdSelected] = useState(false);
  const [contactId, setContactId] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [licenseType, setLicenseType] = useState<LicenseType>(LicenseType.PER_POC);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(addYears(new Date(), 1));
  const [maxReadings, setMaxReadings] = useState<number>(1);
  const [maxDataLoggers, setMaxDataLoggers] = useState<number>(1);
  const [maxSensors, setMaxSensors] = useState<number>(1);
  const [hasError, setHasError] = useState<boolean>(false);

  const [vatNumber, setVatNumber] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [area, setArea] = useState<string | null>(null);
  const [municipality, setMunicipality] = useState<string | null>(null);

  const { t } = useTranslation();

  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const isSuperAdmin = useMemo(() => jwtValidator.hasRole('ROLE_SUPER_ADMIN'), [jwtValidator]);
  const countries = useMemo(
    () => (countriesList ? countriesList.countries.map((country: Country) => country.name) : []),
    [countriesList]
  );

  const handleContactRadioButtonChange = useCallback((): void => {
    setIsContactIdSelected(true);
    setIsAccountIdSelected(false);
    setLicenseType(LicenseType.PER_POC);
    setAccountId('');
  }, []);

  const handleAccountRadioButtonChange = useCallback((): void => {
    setIsAccountIdSelected(true);
    setIsContactIdSelected(false);
    setLicenseType(LicenseType.PER_DEVICE);
    setContactId('');
  }, []);

  const handleLicenseTypeChange = useCallback((licenseType: LicenseType, value: number): void => {
    switch (licenseType) {
      case LicenseType.PER_READING:
        setMaxReadings(value);
        break;
      case LicenseType.PER_DEVICE:
        setMaxDataLoggers(value);
        break;
      case LicenseType.PER_SENSOR:
        setMaxSensors(value);
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = (): void => {
    const solutionValue = solutionType !== 'None' ? solutionType : null;

    const licenseObject = {
      companyName: companyName,
      '@type': licenseType,
      isActive: true,
      validityPeriodFrom: fromDate,
      validityPeriodTo: toDate,
    };

    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        (licenseObject as LicensePerDeviceDto).maxDataLoggers = maxDataLoggers;
        break;
      case LicenseType.PER_READING:
        (licenseObject as LicensePerReadingDto).maxReadings = maxReadings;
        break;
      case LicenseType.PER_SENSOR:
        (licenseObject as LicensePerSensorDto).maxSensors = maxSensors;
        break;
    }

    const companyData: CreateCompanyDto = {
      name: companyName,
      parentId: isSuperAdmin ? company.id : null,
      domain: '',
      solution: solutionValue,
      users: [],
      accountRef: accountId || null,
      contactRef: contactId || null,
      licenseData: accountId || contactId ? [licenseObject] : null,
      childCompaniesNames: [],
      zoneId: timezoneId,
      companyInfo: {
        companyName: companyName,
        country: country,
        address: address,
        vatNumber: vatNumber,
        city: city,
        zipCode: zipCode,
        municipality: municipality,
        area: area,
        logo: null,
      },
    };

    createCompany(companyData);
  };

  useEffect(() => {
    setMaxSensors(1);
    setMaxDataLoggers(1);
    setMaxReadings(1);
    if (licenseType === LicenseType.PER_POC && isToday(fromDate)) {
      setToDate(addMonths(fromDate, 6));
    }
  }, [licenseType]);

  return (
    <div className="flex flex-col gap-5 px-10 w-[480px]">
      {company.name ? (
        <div className="flex items-center gap-5">
          <WarningAmberIcon className="text-5xl text-amber-500" />
          <div className="text-3xl text-center">
            {t('company_manager_child_company_warning_message')}
            <div className="font-bold">{company.name}</div>
          </div>
        </div>
      ) : (
        <div className="text-3xl text-center">{t('company_manager_create_company')}</div>
      )}

      <CreateAccountModalStepper
        activeStep={activeStep}
        steps={[
          { label: t('company_manager_account'), icon: <ManageAccountsIcon /> },
          { label: t('company_manager_account_info'), icon: <InfoIcon /> },
        ]}
      />

      {activeStep === 0 && (
        <>
          <div className="flex flex-col gap-3 p-0">
            <TextField
              required
              autoFocus
              size="small"
              label={t('company_manager_account_name')}
              name="name"
              value={companyName}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setCompanyName(event.target.value)
              }
            />
            <FormControl>
              <InputLabel>{t('company_manager_solution_type')}</InputLabel>
              <Select
                variant="outlined"
                size="small"
                label={t('company_manager_solution_type')}
                value={solutionType}
                onChange={(event: SelectChangeEvent): void =>
                  setSolutionType(event.target.value as AccountSolution | 'None')
                }
              >
                <MenuItem value={'None'}>{t('company_manager_solution_option_none')}</MenuItem>
                <MenuItem value={AccountSolution.AGRI}>
                  {t('company_manager_solution_option_agri')}
                </MenuItem>
                <MenuItem value={AccountSolution.AIR_QUALITY}>
                  {t('company_manager_solution_option_air_quality')}
                </MenuItem>
                <MenuItem value={AccountSolution.PUMP_STATION}>
                  {t('company_manager_solution_option_street_lights')}
                </MenuItem>
                <MenuItem value={AccountSolution.STREET_LIGHTS}>
                  {t('company_manager_solution_option_pump_station')}
                </MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              options={countries}
              value={country}
              onChange={(event: SyntheticEvent, value: string | null): void => {
                setCountry(value || '');
              }}
              renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                <TextField required {...params} label={t('company_manager_country')} size="small" />
              )}
            />
            <Autocomplete
              options={Array.from(timezones || [])}
              value={timezoneId}
              onChange={(event: SyntheticEvent, value: string | null): void =>
                setTimezoneId(value || '')
              }
              renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                <TextField
                  required
                  {...params}
                  label={t('company_manager_timezone')}
                  size="small"
                />
              )}
            />
            {jwtValidator.hasRole('ROLE_SUPER_ADMIN') && (
              <>
                <div className="flex">
                  <RadioGroup value={isContactIdSelected ? 'contactId' : 'accountId'}>
                    <FormControlLabel
                      control={<Radio value="contactId" onClick={handleContactRadioButtonChange} />}
                      label=""
                    />
                    <FormControlLabel
                      control={<Radio value="accountId" onClick={handleAccountRadioButtonChange} />}
                      label=""
                    />
                  </RadioGroup>

                  <div className="flex flex-col gap-2 w-full">
                    <TextField
                      fullWidth
                      disabled={!isContactIdSelected}
                      label={t('company_manager_contact_id')}
                      size="small"
                      variant="outlined"
                      inputProps={{ maxLength: 8 }}
                      value={contactId}
                      onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                        setContactId(event.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      disabled={!isAccountIdSelected}
                      label={t('company_manager_account_id')}
                      size="small"
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                      value={accountId}
                      onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                        setAccountId(event.target.value)
                      }
                    />
                  </div>
                </div>
                <h5 className="text-left font-bold flex items-center">
                  {t('company_manager_license')}
                  <Tooltip
                    placement="right-start"
                    title={
                      <div style={{ fontSize: 13 }}>{t('company_manager_license_info_box')}</div>
                    }
                    arrow
                  >
                    <ErrorOutlineIcon className=" text-blue-600" />
                  </Tooltip>
                </h5>
                <FormControl>
                  <InputLabel>{t('company_manager_license_type')}</InputLabel>
                  <Select
                    disabled={!accountId && !contactId}
                    label={t('company_manager_license_type')}
                    variant="outlined"
                    size="small"
                    value={licenseType}
                    onChange={(event: SelectChangeEvent): void =>
                      setLicenseType(event.target.value as LicenseType)
                    }
                  >
                    <MenuItem value={LicenseType.PER_DEVICE} disabled={!accountId}>
                      {t('company_manager_license_per_device')}
                    </MenuItem>
                    <MenuItem value={LicenseType.PER_READING} disabled={!accountId}>
                      {t('company_manager_license_per_reading')}
                    </MenuItem>
                    <MenuItem value={LicenseType.PER_SENSOR} disabled={!accountId}>
                      {t('company_manager_license_per_sensor')}
                    </MenuItem>
                    <MenuItem value={LicenseType.PER_REMOTE_NODE} disabled={!accountId}>
                      {t('company_manager_license_per_remote_node')}
                    </MenuItem>
                    <MenuItem value={LicenseType.PER_POC} disabled={!contactId}>
                      {t('company_manager_license_per_poc')}
                    </MenuItem>
                  </Select>
                </FormControl>
                <LocalizedDatePicker
                  disabled={!accountId && !contactId}
                  label={t('company_manager_valid_from')}
                  value={fromDate}
                  maxDate={toDate}
                  minDate={licenseType === LicenseType.PER_READING ? new Date() : undefined}
                  onChange={(date: Date | null): void => {
                    if (date) {
                      setFromDate(date);
                    }
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInputTextField={{
                    size: 'small',
                    className: 'bg-white',
                    onKeyDown: (
                      e: BaseSyntheticEvent<
                        KeyboardEvent,
                        EventTarget & HTMLDivElement,
                        EventTarget
                      >
                    ): void => e.preventDefault(),
                    sx: {
                      '& .MuiInputBase-input': {
                        caretColor: 'transparent',
                      },
                    },
                  }}
                />
                <LocalizedDatePicker
                  disabled={!accountId && !contactId}
                  label={t('company_manager_valid_to')}
                  value={toDate}
                  minDate={fromDate}
                  onChange={(date: Date | null): void => {
                    if (date) {
                      setToDate(date);
                    }
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInputTextField={{
                    size: 'small',
                    className: 'bg-white',
                    onKeyDown: (
                      e: BaseSyntheticEvent<
                        KeyboardEvent,
                        EventTarget & HTMLDivElement,
                        EventTarget
                      >
                    ): void => e.preventDefault(),
                    sx: {
                      '& .MuiInputBase-input': {
                        caretColor: 'transparent',
                      },
                    },
                  }}
                />
                {licenseType !== LicenseType.PER_POC &&
                  licenseType !== LicenseType.PER_REMOTE_NODE && (
                    <LicenseMaxCount
                      hasError={hasError}
                      setHasError={setHasError}
                      onChange={handleLicenseTypeChange}
                      licenseType={licenseType}
                      maxSensors={maxSensors}
                      maxDataLoggers={maxDataLoggers}
                      maxReadings={maxReadings}
                      disabled={!accountId}
                    />
                  )}
              </>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              className="w-1/2"
              disabled={!companyName || !timezoneId || !country || hasError}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={(): void => {
                setActiveStep(1);
              }}
            >
              {t('button_continue')}
            </Button>
          </div>
        </>
      )}

      {activeStep === 1 && (
        <>
          <div className="flex flex-col gap-3 p-0">
            <TextField
              autoFocus
              label={t('company_manager_address')}
              name="address"
              size="small"
              variant="outlined"
              value={address}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setAddress(event.target.value)
              }
            />
            <TextField
              label={t('company_manager_city')}
              name="city"
              size="small"
              variant="outlined"
              value={city}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => setCity(event.target.value)}
            />
            <TextField
              label={t('company_manager_municipality')}
              name="municipality"
              size="small"
              variant="outlined"
              value={municipality}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setMunicipality(event.target.value)
              }
            />
            <TextField
              label={t('company_manager_area')}
              name="area"
              size="small"
              variant="outlined"
              value={area}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => setArea(event.target.value)}
            />
            <TextField
              label={t('company_manager_zip_code')}
              name="zipCode"
              size="small"
              variant="outlined"
              value={zipCode}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setZipCode(event.target.value)
              }
            />
            <TextField
              label={t('company_manager_vat_number')}
              name="vatNumber"
              size="small"
              variant="outlined"
              value={vatNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setVatNumber(event.target.value)
              }
            />
          </div>
          <div className="flex gap-5">
            <Button
              className="flex-1"
              variant="contained"
              startIcon={<SendIcon className="rotate-180" />}
              onClick={(): void => {
                setActiveStep(0);
              }}
            >
              {t('button_back')}
            </Button>
            <Button className="flex-1" variant="contained" onClick={handleSubmit}>
              {t('button_create')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

interface CreateAccountModalProps {
  company: CompanySelectionState;
  timezones: Set<string>;
  countriesList: CountriesListDto | null;
  createCompany: (body: CreateCompanyDto) => void;
}

export default CreateAccountModal;
