import { DataGrid, DataGridProps, GridRenderCellParams } from '@thingslog/ui-components';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import JwtValidator from '../../common/JwtValidator';
import { IconScope } from '@thingslog/repositories';
import { DeviceIconDto } from '@thingslog/repositories';

const IconsTable: React.FC<IconsTableProps> = ({
  pageSize,
  autoHeight,
  rowsPerPageOptions,
  onPageSizeChange,
  onEditIcon,
  ...props
}: IconsTableProps) => {
  const { t } = useTranslation();
  const { hasRole } = new JwtValidator();
  const [size, setSize] = useState(10);

  const onPageSizeChangeHandler = (pageSize: number): void => setSize(pageSize);

  return (
    <DataGrid
      pageSize={pageSize ? pageSize : size}
      rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50]}
      autoHeight={autoHeight ? autoHeight : true}
      onPageSizeChange={onPageSizeChange ? onPageSizeChange : onPageSizeChangeHandler}
      columns={[
        {
          field: 'name',
          headerName: t('icon_table_name'),
          flex: 1,
        },
        {
          field: 'scope',
          headerName: t('icon_table_scope'),
          flex: 1,
          renderCell: (params: GridRenderCellParams): ReactNode => {
            const iconScope: IconScope = params.value;
            switch (iconScope) {
              case IconScope.GLOBAL:
                return t('icon_scope_global');
              case IconScope.COMPANY:
                return t('icon_scope_company');
              case IconScope.DEVICE:
                return t('icon_scope_device');
              case IconScope.SENSOR:
                return t('icon_scope_sensor');
              default:
                return iconScope;
            }
          },
        },
        {
          field: 'icon',
          headerName: t('icon_table_icon'),
          sortable: false,
          filterable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams): ReactNode => {
            return <img className="h-6 w-auto" src={params.row.icon} />;
          },
        },
        {
          field: 'id',
          headerName: t('icon_table_actions'),
          sortable: false,
          filterable: false,
          renderCell: (params: GridRenderCellParams): ReactNode => {
            const deviceIcon: DeviceIconDto = params.row;
            const isSuperAdmin = hasRole('ROLE_SUPER_ADMIN');
            const isAdmin = hasRole('ROLE_ADMIN');
            const isShown = isSuperAdmin || (deviceIcon.scope !== IconScope.GLOBAL && isAdmin);
            if (!isShown) return <></>;
            return (
              <IconButton
                onClick={(): void => {
                  onEditIcon && onEditIcon(deviceIcon);
                }}
              >
                <EditIcon />
              </IconButton>
            );
          },
        },
      ]}
      {...props}
    />
  );
};

interface IconsTableProps extends Omit<DataGridProps, 'columns'> {
  onEditIcon?: (icon: DeviceIconDto) => void;
}

export default IconsTable;
