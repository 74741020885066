import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import deviceCountersStatisticsClient from './DeviceCountersStatisticsClient';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TextField from '@mui/material/TextField';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import * as actions from './actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ErrorUtil from './common/ErrorUtil';

class MultiDeviceCounterStatisticsQFlow extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null, description: null, every: 5 };
    this.loadDeviceCountersStatisticsFromServer =
      this.loadDeviceCountersStatisticsFromServer.bind(this);
    this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
    this.handleToDateChanged = this.handleToDateChanged.bind(this);
    this.handleEveryChange = this.handleEveryChange.bind(this);
    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.orientationDidChange = this.orientationDidChange.bind(this);
    this.mql = window.matchMedia('(orientation: portrait)');
  }

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
    this.setState({ data: [] });
  }

  handleFromDateChanged(event, date) {
    this.props.periodFromDateChanged(date);
  }

  handleToDateChanged(event, date) {
    this.props.periodToDateChanged(date);
  }

  handleApplyButtonClick() {
    this.loadDeviceCountersStatisticsFromServer();
  }

  handleEveryChange = (event) => {
    let every = event.target.value;
    this.setState({
      every: every,
    });
    this.loadDeviceCountersStatisticsFromServer();
  };

  orientationDidChange(orientationQuery) {
    this.setState({ isLendscapeOrientation: !orientationQuery.matches });
  }
  render() {
    const wellStyles = { maxWidth: 1200, margin: '0 auto 10px' };
    let options = {};

    if (this.state.error != null) {
      return (
        <div>
          Error: {this.state.error.message}. Go to <Link to="/app/Devices">devices</Link>
        </div>
      );
    }

    if (this.state.data != null) {
      var selectRowProp = {
        hideSelectColumn: true,
      };
      return (
        <div className="well" style={wellStyles}>
          <p>Device Flow Statistics</p>
          <Grid>
            <Row>
              <Col sm={2} md={2} lg={2}>
                <FormGroup controlId="Every">
                  <ControlLabel>Every, min</ControlLabel>
                  <FormControl
                    value={this.state.every}
                    componentClass="select"
                    placeholder="every"
                    onChange={this.handleEveryChange.bind(this)}
                  >
                    <option value={1}>1</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={60}>60</option>
                  </FormControl>
                </FormGroup>
              </Col>

              <Col sm={3} md={2} lg={2}>
                <ControlLabel>From Date</ControlLabel>
                <TextField
                  type="date"
                  onChange={this.handleFromDateChanged}
                  value={this.props.fromDate}
                />
              </Col>
              <Col sm={3} md={2} lg={2}>
                <ControlLabel>To Date</ControlLabel>
                <TextField
                  type="date"
                  onChange={this.handleToDateChanged}
                  value={this.props.toDate}
                />
              </Col>
              <Col sm={3} md={2} lg={2}>
                <Button variant="raised" onClick={this.handleApplyButtonClick.bind(this)}>
                  Apply
                </Button>
              </Col>
            </Row>
          </Grid>
          <BootstrapTable
            ref="table"
            data={this.state.data}
            selectRow={selectRowProp}
            striped={true}
            hover={true}
            options={options}
          >
            <TableHeaderColumn
              row="0"
              rowSpan="2"
              isKey={true}
              dataAlign="center"
              dataField="number"
            >
              number
            </TableHeaderColumn>
            <TableHeaderColumn
              row="0"
              rowSpan="2"
              dataField="businessPartnerNumber"
              className="hidden-xs-portrait"
              columnClassName="hidden-xs-portrait"
              dataAlign="center"
              width="15%"
            >
              BusinessPartner
            </TableHeaderColumn>
            <TableHeaderColumn
              row="0"
              rowSpan="2"
              dataField="address"
              dataAlign="center"
              width="15%"
              className="hidden-xs-portrait"
              columnClassName="hidden-xs-portrait"
            >
              Address
            </TableHeaderColumn>
            <TableHeaderColumn row="0" colSpan="2" dataAlign="center" width="15%">
              0 - Q1
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="value0" dataAlign="center">
              m^3/h
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="percent0" dataAlign="center">
              %
            </TableHeaderColumn>
            <TableHeaderColumn row="0" colSpan="2" dataAlign="center" width="15%">
              Q1 - Q2
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="value1" dataAlign="center">
              m^3/h
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="percent1" dataAlign="center">
              %
            </TableHeaderColumn>
            <TableHeaderColumn row="0" colSpan="2" dataAlign="center" width="15%">
              Q2 - Q3
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="value2" dataAlign="center">
              m^3/h
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="percent2" dataAlign="center">
              %
            </TableHeaderColumn>
            <TableHeaderColumn row="0" colSpan="2" dataAlign="center" width="15%">
              Q4 - Infinity
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="value3" dataAlign="center">
              m^3/h
            </TableHeaderColumn>
            <TableHeaderColumn row="1" dataField="percent3" dataAlign="center">
              %
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress size={80} thickness={5} />
        </div>
      );
    }
  }

  componentWillMount() {
    var isPortraitOrientation = this.mql.matches;
    this.setState({ isLendscapeOrientation: !isPortraitOrientation });
  }
  componentDidMount() {
    this.mql.addListener(this.orientationDidChange);
    this.loadDeviceCountersStatisticsFromServer();
  }

  componentWillUnmount() {
    this.mql.removeListener(this.orientationDidChange);
  }

  loadDeviceCountersStatisticsFromServer() {
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    deviceCountersStatisticsClient.getDevicesCountersStatisticsQFlow(
      this.props.devices,
      fromDateISO,
      toDateISO,
      this.state.every,
      (datas) => {
        let arr = Object.keys(datas).map(function (k) {
          let data = datas[k];
          let perFlow = [];
          for (let i = 0; i < data.value.nbins - 1; i++) {
            perFlow[i] = {
              range: data.value.edges[i] + ' - ' + data.value.edges[i + 1],
              value: data.value.values[i].toFixed(2),
              percent: ((100 * data.value.values[i]) / data.value.total).toFixed(2),
            };
          }
          return {
            number: data.device.number,
            businessPartnerNumber: data.device.customerInfo.businessPartnerNumber,
            address: data.device.customerInfo.address,
            range0: perFlow[0].range,
            value0: perFlow[0].value,
            percent0: perFlow[0].percent,
            range1: perFlow[1].range,
            value1: perFlow[1].value,
            percent1: perFlow[1].percent,
            range2: perFlow[2].range,
            value2: perFlow[2].value,
            percent2: perFlow[2].percent,
            range3: perFlow[3].range,
            value3: perFlow[3].value,
            percent3: perFlow[3].percent,
          };
        });

        this.setState({
          data: arr,
        });
      },
      this.errorCallback
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.dev.devices,
    fromDate: state.period.fromDate,
    toDate: state.period.toDate,
  };
}

export default connect(mapStateToProps, actions)(MultiDeviceCounterStatisticsQFlow);
