import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, DataGridProps, GridValueFormatterParams } from '@thingslog/ui-components';
import SignalStatus from '../../../src/model/API/DeviceSignal/SignalStatus';
import { DateUtil } from '../../common/DateUtil';

const SignalStatusTable: FC<SignalStatusTableProps> = ({
  signalStatusReadings,
  ...props
}: SignalStatusTableProps) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState<SignalStatusTableRow[]>([]);

  useEffect(() => {
    let rows: SignalStatusTableRow[] = [];
    signalStatusReadings.forEach((signalStatus: SignalStatus, index: number) => {
      const { date, rssi, ber } = signalStatus;

      rows.push({
        id: index,
        date: date,
        rssi: rssi,
        ber: ber,
      });
    });

    setRows(rows);
  }, [signalStatusReadings]);

  return (
    <DataGrid
      showPrintOption={false}
      rows={rows}
      columns={[
        {
          field: 'date',
          headerName: t('date'),
          flex: 1,
          minWidth: 140,
          type: 'date',
          filterable: false,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            DateUtil.formatTimeZoneAgnosticDate(params.value),
          sortComparator: (SignalDate1: string, SignalDate2: string) =>
            new Date(SignalDate1).getTime() - new Date(SignalDate2).getTime(),
        },
        {
          field: 'rssi',
          headerName: t('rssi'),
          flex: 0.5,
          minWidth: 70,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
        },
        {
          field: 'ber',
          headerName: t('ber'),
          flex: 0.5,
          minWidth: 70,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

interface SignalStatusTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  signalStatusReadings: SignalStatus[];
}

interface SignalStatusTableRow {
  id: number;
  date: Date;
  rssi: number;
  ber: number | null;
}

export default SignalStatusTable;
