import { FEATURE_FLAGS_UPDATE } from '../actions/types';

import {
  FeatureFlagsUpdateAction,
  FeatureFlagsAction,
} from '../state_management/actions/FeatureFlagAction';

export default function featureFlagsReducer(
  state: FeatureFlagState = initialState,
  action: FeatureFlagsAction
): FeatureFlagState {
  switch (action.type) {
    case FEATURE_FLAGS_UPDATE:
      const featureFlagsUpdateAction = action as FeatureFlagsUpdateAction;
      const featureFlags = featureFlagsUpdateAction.featureFlags;
      return { ...state, featureFlagsArray: featureFlags };
    default:
      return state;
  }
}

const initialState: FeatureFlagState = {
  featureFlagsArray: [],
};

export interface FeatureFlagState {
  featureFlagsArray: string[];
}
