import React, { ChangeEvent } from 'react';
import { getAllCommands } from '../../clients/Command/CommandClient';
import ErrorUtil from '../../common/ErrorUtil';
import Command, { CommandState } from '../../model/API/Command/Command';
import Header from '../../components/header';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import AddIcon from '@mui/icons-material/Add';
import withCommandPageContext from './context/withCommandPageContext';
import { ICommandContextProps } from './context/CommandPageContext';
import AddCommandModal from './components/AddCommandModal';
import EditCommandModal from './components/EditCommandModal';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithRouterProps, withRouter } from '../../common/withRouter';
import CommandFormatUtil from './utils/CommandFormatUtil';
import moment from 'moment';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import TooltipIconButton from '../../components/TooltipActionButton/TooltipActionButton';
import { red } from '@mui/material/colors';
import { Edit as EditIcon } from '@mui/icons-material';
import { ReduxState } from '../../reducers';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Device } from '@thingslog/repositories';
import CommandParametersPreview from './components/CommandParametersPreview';

class Commands extends React.Component<CommandsProps, CommandsState> {
  private intervalID: null | NodeJS.Timeout = null;

  public state: CommandsState = {
    commands: [],
    displayedCommands: [],
    isLoaded: false,
    page: 0,
    size: 10,
    isAutoRefreshOn: true,
  };

  public componentDidMount = async (): Promise<void> => {
    if (
      !this.props.match.params.deviceNumber ||
      this.props.match.params.deviceNumber.length !== 8
    ) {
      alert('Device Number missing or wrong.');
    } else {
      await this.fetchData();
    }

    if (this.state.isAutoRefreshOn) {
      this.startAutoRefresh();
    }
  };

  public componentDidUpdate = async (
    prevProps: CommandsProps,
    prevState: CommandsState
  ): Promise<void> => {
    if (prevState.page !== this.state.page || prevState.size !== this.state.size) {
      this.setState({
        displayedCommands: this.paginate(this.state.commands, this.state.page, this.state.size),
      });
    }

    if (prevProps.match.params.deviceNumber !== this.props.match.params.deviceNumber) {
      await this.fetchData();
    }

    if (prevProps.commandPageState.addModalOpen !== this.props.commandPageState.addModalOpen) {
      await this.fetchData();
    }
    if (prevProps.commandPageState.editModalOpen !== this.props.commandPageState.editModalOpen) {
      await this.fetchData();
    }

    if (prevState.isAutoRefreshOn !== this.state.isAutoRefreshOn) {
      if (this.state.isAutoRefreshOn) {
        this.startAutoRefresh();
      } else {
        this.stopAutoRefresh();
      }
    }
  };

  public componentWillUnmount(): void {
    this.stopAutoRefresh();
  }

  private fetchData = async (): Promise<void> => {
    try {
      const commands = await getAllCommands(this.props.match.params.deviceNumber!); // safe
      this.setState({
        commands: commands,
        displayedCommands: this.paginate(commands, this.state.page, this.state.size),
        isLoaded: true,
      });
    } catch (error) {
      ErrorUtil.handleErrorWithAlert(error);
    }
  };

  private isOpen = (modal: 'add' | 'edit'): boolean => {
    switch (modal) {
      case 'add':
        return this.props.commandPageState.addModalOpen;
      case 'edit':
        return (
          this.props.commandPageState.editModalOpen &&
          this.props.commandPageState.selectedCommandId !== null
        );
    }
  };

  private openAddModal = (): void => {
    this.props.commandPageDispatch({ type: 'toggle_add_modal', payload: true });
  };

  private openEditModal = (command: Command): void => {
    this.props.commandPageDispatch({ type: 'toggle_edit_modal', payload: true });
    this.props.commandPageDispatch({
      type: 'change_selected_command_id',
      payload: command.id,
    });
  };

  private paginate = (array: Command[], page: number, size: number): Command[] => {
    return array.slice(page * size).slice(0, size);
  };

  private changePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    this.setState({ page });
  };

  private changeRowsPerPage = (e: React.ChangeEvent): void => {
    this.setState({
      page: 0,
      size: e.target.value as number,
    });
  };

  private toggleAutoRefreshHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isAutoRefreshOn: event.target.checked });
  };

  private startAutoRefresh = (): void => {
    this.intervalID = setInterval(() => this.fetchData(), 10 * 1000);
  };

  private stopAutoRefresh = (): void => {
    if (this.intervalID !== null) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
  };

  public render = (): React.ReactNode => {
    return (
      <Header>
        <Container>
          <Modal open={this.isOpen('add')}>
            <AddCommandModal deviceNumber={this.props.match.params.deviceNumber!} />
          </Modal>
          <Modal open={this.isOpen('edit')}>
            <EditCommandModal deviceNumber={this.props.match.params.deviceNumber!} />
          </Modal>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            {this.props.selectedDevice && (
              <Grid item xs={12}>
                <TitleHeader
                  title={this.props.t('commands_header')}
                  deviceNumber={this.props.selectedDevice.number}
                  deviceName={this.props.selectedDevice.name}
                  customerInfo={this.props.selectedDevice.customerInfo}
                />
              </Grid>
            )}

            <Box display="flex" justifyContent="space-between" paddingY={1} flexGrow={1}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CachedOutlinedIcon />}
                  onClick={this.fetchData}
                  className="max-md:w-full max-md:mt-4"
                >
                  <b>{this.props.t('commands_btn_refresh')}</b>
                </Button>
                <Switch
                  checked={this.state.isAutoRefreshOn}
                  onChange={this.toggleAutoRefreshHandler}
                  title={this.props.t('alarms_export_switch_title_label')}
                  className="flex items-center"
                  icon={<CachedOutlinedIcon className="text-black bg-[#00bcd4] rounded-full " />}
                  checkedIcon={
                    <CachedOutlinedIcon className="text-black bg-green-500 rounded-full " />
                  }
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={this.openAddModal}
                className="max-md:w-full max-md:mt-4"
              >
                <b>{this.props.t('commands_btn_send_command')}</b>
              </Button>
            </Box>

            {this.state.isLoaded && (
              <Grid xs={12}>
                <Paper>
                  <TableContainer>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{this.props.t('commands_type')}</TableCell>
                          <TableCell>{this.props.t('commands_status')}</TableCell>
                          <TableCell align="left">{this.props.t('commands_parameters')}</TableCell>
                          <TableCell>{this.props.t('commands_created')}</TableCell>
                          <TableCell>{this.props.t('commands_sent')}</TableCell>
                          <TableCell>{this.props.t('commands_executed')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.displayedCommands.map((command: Command) => {
                          const cmdParams = CommandFormatUtil.convertCmdParamsToArray(
                            command.commandParameters
                          );
                          return (
                            <TableRow hover key={command.id}>
                              <TableCell>
                                <Box fontWeight="fontWeightBold" fontSize={16}>
                                  {this.props.t(
                                    CommandFormatUtil.translateCommandType(command.commandType)
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box display="flex" alignItems="center">
                                  <Chip
                                    label={
                                      <b>
                                        {this.props.t(
                                          CommandFormatUtil.translateCommandState(
                                            command.commandState
                                          )
                                        )}
                                      </b>
                                    }
                                    size="small"
                                    color="primary"
                                    clickable
                                  />
                                  {command.commandState === CommandState.PENDING && (
                                    <Grid item>
                                      <TooltipIconButton
                                        tooltipTitle={`${this.props.t('commands_tooltip_edit')}`}
                                        onClick={(): any => this.openEditModal(command)}
                                        icon={<EditIcon />}
                                        highlightColor={red[300]}
                                      />
                                    </Grid>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <CommandParametersPreview command={command} />
                              </TableCell>
                              <TableCell>
                                {command.creationDate &&
                                  moment(command.creationDate).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                              <TableCell>
                                {command.sentDate &&
                                  moment(command.sentDate).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                              <TableCell>
                                {command.executionDate &&
                                  moment(command.executionDate).format('DD/MM/YYYY HH:mm')}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={this.state.commands.length}
                    rowsPerPage={this.state.size}
                    page={this.state.page}
                    onPageChange={this.changePage}
                    onRowsPerPageChange={this.changeRowsPerPage}
                  />
                </Paper>
              </Grid>
            )}

            {!this.state.isLoaded && (
              <Grid xs={12}>
                <Box paddingTop={5}>
                  <CircularProgress size={45} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Header>
    );
  };
}

interface CommandsProps extends ICommandContextProps, WithTranslation, WithRouterProps {
  selectedDevice: Device | null;
}

interface CommandsState {
  commands: Command[];
  displayedCommands: Command[];
  isLoaded: boolean;
  page: number;
  size: number;
  isAutoRefreshOn: boolean;
}

const mapStateToProps = (state: ReduxState): { selectedDevice: Device } => {
  return {
    selectedDevice: state.dev.selectedDevice,
  };
};

export default withTranslation()(
  connect(mapStateToProps, actions)(withCommandPageContext(withRouter(Commands)))
);
