export enum MeasurementUnits {
  PULSES = 'PULSES',
  AMPER = 'AMPER',
  MILLIAMPER = 'MILLIAMPER',
  MILLIVOLT = 'MILLIVOLT',
  VOLT = 'VOLT',
  BAR = 'BAR',
  MILLIBAR = 'MILLIBAR',
  METERS_OF_WATER_COLUMN = 'METERS_OF_WATER_COLUMN',
  LITER = 'LITER',
  GALLON = 'GALLON',
  KWH = 'KWH',
  KW = 'KW',
  KVARH = 'KVARH',
  DEG_C = 'DEG_C',
  CUBIC_METER = 'CUBIC_METER',
  KILOLITRE = 'KILOLITRE',
  RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY',
  VOLUMETRIC_WATER_CONTENT = 'VOLUMETRIC_WATER_CONTENT',
  PERCENT = 'PERCENT',
  METER = 'METER',
  CENTIMETER = 'CENTIMETER',
  MILLIMETER = 'MILLIMETER',
  INCH = 'INCH',
  FOOT = 'FOOT',
  NO_UNITS = 'NO_UNITS',
  DEG_F = 'DEG_F',
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  TON = 'TON',
  NEWTON = 'NEWTON',
  KILONEWTON = 'KILONEWTON',
  MILLILITER = 'MILLILITER',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND',
  LITERS_PER_SECOND_SQUARE_METER = 'LITERS_PER_SECOND_SQUARE_METER',
  LITERS_PER_SQUARE_METER = 'LITERS_PER_SQUARE_METER',
  MM_PER_HOUR_PER_SQUARE_METER = 'MM_PER_HOUR_PER_SQUARE_METER',
  MM_PER_SQUARE_METER = 'MM_PER_SQUARE_METER',
  METERS_PER_SECOND = 'METERS_PER_SECOND',
  KNOT = 'KNOT',
  KILOMETER_PER_HOUR = 'KILOMETER_PER_HOUR',
  CUBIC_METERS_PER_HOUR = 'CUBIC_METERS_PER_HOUR',
  PASCAL = 'PASCAL',
  KILOPASCAL = 'KILOPASCAL',
  HECTOPASCAL = 'HECTOPASCAL',
  MILESPERHOUR = 'MILESPERHOUR',
  DEGREE = 'DEGREE',
  PICOFARAD = 'PICOFARAD',
  COUNTS_PER_MINUTE = 'COUNTS_PER_MINUTE',
  MILLI_ROENTGEN_PER_HOUR = 'MILLI_ROENTGEN_PER_HOUR',
  AQI = 'AQI',
  MICRO_GRAMS_PER_CUBIC_METER = 'MICRO_GRAMS_PER_CUBIC_METER',
  SIEVERT = 'SIEVERT',
  LITERS_PER_SQUARE_METER_PER_HOUR = 'LITERS_PER_SQUARE_METER_PER_HOUR',
  PARTS_PER_MILLION = 'PARTS_PER_MILLION',
  PARTS_PER_BILLION = 'PARTS_PER_BILLION',
  NEPHELOMETRIC_TURBIDITY_UNIT = 'NEPHELOMETRIC_TURBIDITY_UNIT',
  MILLIGRAMS_PER_LITER = 'MILLIGRAMS_PER_LITER',
  MILLIGRAMS_PER_CUBIC_METER = 'MILLIGRAMS_PER_CUBIC_METER',
  MICROSIEMENS_PER_CENTIMETER = 'MICROSIEMENS_PER_CENTIMETER',
  POTENTIAL_OF_HYDROGEN = 'POTENTIAL_OF_HYDROGEN',
}
