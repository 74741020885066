import React, { useContext } from 'react';
import CommandPageContext from './CommandPageContext';

const withCommandPageContext = (Component: any): any => {
  return function WrappedComponent(props: any): JSX.Element {
    const context = useContext(CommandPageContext);
    return (
      <Component
        {...props}
        commandPageState={context.commandPageState}
        commandPageDispatch={context.commandPageDispatch}
      />
    );
  };
};

export default withCommandPageContext;
