import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paperHeader: {
    backgroundColor: grey[100],
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));
