import { SettingsDto } from '@thingslog/repositories';
import { SET_SOLUTION_SETTINGS } from '../actions/types';

import {
  SetSolutionSettingsAction,
  SolutionSettingsAction,
} from '../state_management/actions/SolutionSettingsAction';

export default function solutionSettingsReducer(
  state: SolutionSettingsState = initialState,
  action: SolutionSettingsAction
): SolutionSettingsState {
  switch (action.type) {
    case SET_SOLUTION_SETTINGS:
      const setSolutionSettingsAction = action as SetSolutionSettingsAction;
      const settings = setSolutionSettingsAction.settings;
      return { ...settings };
    default:
      return state;
  }
}

const initialState: SolutionSettingsState = {
  data: null,
};

export interface SolutionSettingsState extends SettingsDto {}
