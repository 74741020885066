import { useMutation } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  MutationOptions,
  ReadingsExportDto,
  FormatAcceptHeaders,
  ReadingsExportRequest,
  MutationResult,
} from '@thingslog/repositories';

interface useMultipleReadingsExportVariables {
  body: ReadingsExportRequest;
}

export interface MultipleReadingsExportQueryClient {
  getMultipleReadingsExportCSV: ({
    body,
  }: useMultipleReadingsExportVariables) => Promise<ArrayBuffer>;
  getMultipleReadingsExportExcel: ({
    body,
  }: useMultipleReadingsExportVariables) => Promise<ArrayBuffer>;
  getMultipleReadingsExportJSON: ({
    body,
  }: useMultipleReadingsExportVariables) => Promise<ReadingsExportDto[]>;
  useMultipleReadingsExportCSV: (
    options?: MutationOptions<ArrayBuffer, useMultipleReadingsExportVariables>
  ) => MutationResult<ArrayBuffer, useMultipleReadingsExportVariables>;
  useMultipleReadingsExportExcel: (
    options?: MutationOptions<ArrayBuffer, useMultipleReadingsExportVariables>
  ) => MutationResult<ArrayBuffer, useMultipleReadingsExportVariables>;
  useMultipleReadingsExportJSON: (
    options?: MutationOptions<ReadingsExportDto[], useMultipleReadingsExportVariables>
  ) => MutationResult<ReadingsExportDto[], useMultipleReadingsExportVariables>;
}

export function createMultipleReadingsExportQueryClient(
  axios: AxiosInstance
): MultipleReadingsExportQueryClient {
  return new MultipleReadingsExportImp(axios);
}

class MultipleReadingsExportImp {
  public constructor(private axios: AxiosInstance) {}

  public getMultipleReadingsExportCSV = async ({
    body,
  }: useMultipleReadingsExportVariables): Promise<ArrayBuffer> => {
    let acceptHeader = FormatAcceptHeaders.CSV;

    return this.axios
      .post('/api/readings-export', body, {
        headers: { Accept: acceptHeader },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        return response.data;
      });
  };

  public getMultipleReadingsExportExcel = async ({
    body,
  }: useMultipleReadingsExportVariables): Promise<ArrayBuffer> => {
    let acceptHeader = FormatAcceptHeaders.XLSX;

    return this.axios
      .post('/api/readings-export', body, {
        headers: { Accept: acceptHeader },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        return response.data;
      });
  };

  public getMultipleReadingsExportJSON = async ({
    body,
  }: useMultipleReadingsExportVariables): Promise<ReadingsExportDto[]> => {
    let acceptHeader = FormatAcceptHeaders.JSON;

    return this.axios
      .post('/api/readings-export', body, {
        headers: { Accept: acceptHeader },
      })
      .then((response: AxiosResponse<ReadingsExportDto[]>) => {
        return response.data;
      });
  };

  public useMultipleReadingsExportCSV = (
    options?: MutationOptions<ArrayBuffer, useMultipleReadingsExportVariables>
  ): MutationResult<ArrayBuffer, useMultipleReadingsExportVariables> => {
    return useMutation<ArrayBuffer, AxiosError, useMultipleReadingsExportVariables>(
      [QueryKeys.MultipleReadingsExport, 'CSV'],
      (variables: useMultipleReadingsExportVariables) =>
        this.getMultipleReadingsExportCSV({ ...variables }),
      options
    );
  };

  public useMultipleReadingsExportExcel = (
    options?: MutationOptions<ArrayBuffer, useMultipleReadingsExportVariables>
  ): MutationResult<ArrayBuffer, useMultipleReadingsExportVariables> => {
    return useMutation<ArrayBuffer, AxiosError, useMultipleReadingsExportVariables>(
      [QueryKeys.MultipleReadingsExport, 'Excel'],
      (variables: useMultipleReadingsExportVariables) =>
        this.getMultipleReadingsExportExcel({ ...variables }),
      options
    );
  };

  public useMultipleReadingsExportJSON = (
    options?: MutationOptions<ReadingsExportDto[], useMultipleReadingsExportVariables>
  ): MutationResult<ReadingsExportDto[], useMultipleReadingsExportVariables> => {
    return useMutation<ReadingsExportDto[], AxiosError, useMultipleReadingsExportVariables>(
      [QueryKeys.MultipleReadingsExport, 'JSON'],
      (variables: useMultipleReadingsExportVariables) =>
        this.getMultipleReadingsExportJSON({ ...variables }),
      options
    );
  };
}
