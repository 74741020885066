import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';

export default class UserClient {
  public static rlogin = async (
    username: string,
    password: string,
    recaptchaToken: string | null
  ): Promise<AxiosResponse> => {
    return Axios.post('/api/rlogin', { username, password, recaptchaToken }).then(
      (response: AxiosResponse) => {
        return response;
      }
    );
  };
}
