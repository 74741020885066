const i18n_ptBr = {
  close_btn: 'Fechar',
  search_placeholder: 'Buscar',

  label_inactive: 'Inativo',
  label_invalid: 'Inválido',

  active_devices: ' {{devices_len}} Dispositivos ativos',
  change_language: 'Trocar idioma',
  language_changed_message: 'Idioma Trocado',

  error_select_device_message: 'Selecione o dispositivo primeiro',
  select_device: 'Selecione o dispositivo',
  select_company_menu_message: 'Selecione empresa para ver a lista de dispositivos',

  second: 'segundo',
  seconds: 'segundos',
  second_one: 'segundo',
  second_other: 'segundos',
  minute: 'minuto',
  minutes: 'minutos',
  minute_one: 'minuto',
  minute_other: 'minutos',
  hour: 'hora',
  hours: 'horas',
  hour_one: 'hora',
  hour_other: 'horas',
  day: 'dia',
  days: 'dias',
  day_one: 'dia',
  day_other: 'dias',
  start_date: 'Data Inicial',
  end_date: 'Data Final',
  from_date: 'De',
  to_date: 'Até',
  avg_from_date: 'Média desde a data',
  avg_to_date: 'Média até esta data',
  period: 'Período',
  description: 'Descrição',
  date: 'Data',
  number: 'Série',
  sensor: 'ID Sensor',
  apply: 'Aplicar',
  update: 'Atualizar',
  create: 'Criar',
  delete: 'Eliminar',
  export: 'Exportar',
  rssi: 'RSSI',
  ber: 'BER',
  reading: 'Leitura',
  model: 'Modelo',
  email_address: 'Correio eletrônico',
  password: 'Senha',
  confirm_password: 'Confirmar Senha',
  sign_in: 'Cadastre-se',
  graph: 'Gráfico',
  no_data: 'Sem dados',
  every: 'Por',
  for_company: 'Para a empresa',
  flow: 'Vazão',
  no_data_found: 'Dados não encontrados.',
  percent: 'Porcentagem',
  device_number_devicenum: 'Numero de Serie do Dispositivo: {{device_number}}',
  device_number: 'Numero de Serie do Dispositivo',
  successfully_saved: 'Salvo com Sucesso',
  error_occured: 'Ocorreu um erro',
  none: 'Nenhum',
  off: 'Apagado',
  no_unit: 'Sem unidade de medida',

  show_average: 'Mostrar média',
  show_readings: 'Mostrar leitura',

  yes: 'Sim',
  no: 'Não',
  capital_yes: 'SIM',
  capital_no: 'NÃO',

  sensor: 'Sensor',

  all_sensors: 'Todos os sensores',

  select_all: 'Marcar todos',
  deselect_all: 'Desmarcar Todos',

  function_last: 'Último',
  function_first: 'Primeiro',
  function_avg: 'Média',
  function_min: 'Min.',
  function_max: 'Max.',
  function_median: 'Mediana',
  function_count: 'Contar',
  function_last_flow: 'Última Vazão',
  function_first_flow: 'Primeira Vazão',
  function_avg_flow: 'Vazão média',
  function_max_flow: 'Vazão Max.',
  function_min_flow: 'Vazão Min.',
  function_median_flow: 'Vazão médiana',

  page_title_header_number_with_name_absent: '{{title}} - {{device_number}}',
  page_title_header_number_with_name_present: '{{title}} - {{device_name}} ({{device_number}})',

  // Sensor Types
  generic_analog_sensor: 'Sensor Analógico Genérico',
  generic_virtual_sensor: 'Sensor Virtual Genérico',
  generic_digital_sensor: 'Sensor Digital Genérico',
  modbus_generic_analog_sensor: 'Sensor Analógico Genérico Modbus',
  modbus_generic_digital_sensor: 'Sensor Digital Genérico Modbus',
  pressure_sensor: 'Sensor de Pressão',
  temperature_sensor: 'Sensor de Temperatura',
  humidity_sensor: 'Sensor de Umidade',
  level_sensor: 'Sensor de Nível',
  weight_meter: 'Medidor de Peso',
  weight_sensor: 'Sensor de Peso',
  load_cells: 'Células de Carga',
  wind_direction_sensor: 'Sensor de Direção do Vento',
  barometric_pressure_sensor: 'Sensor de Pressão Barométrica',
  soil_moisture_sensor: 'Sensor de Umidade do Solo',
  dew_point_sensor: 'Sensor de Ponto de Orvalho',
  soil_temperature_sensor: 'Sensor de Temperatura do Solo',
  soil_potential_sensor: 'Sensor de Potencial do Solo',
  particulate_matter_sensor: 'Sensor de Material Particulado',
  leaf_wetness_sensor: 'Sensor de Molhamento Foliar',
  water_meter: 'Medidor de Água',
  power_meter: 'Medidor de Energia',
  gas_flow_meter: 'Medidor de Fluxo de Gás',
  rain_sensor: 'Sensor de Chuva',
  wind_speed_sensor: 'Sensor de Velocidade do Vento',
  heat_meter: 'Medidor de Calor',
  geiger_mueller_counter: 'Contador Geiger Muller',
  on_off_sensor: 'Sensor Liga/Desliga',
  on_off_input_sensor: 'Sensor de Entrada Liga/Desliga',
  on_off_output_sensor: 'Sensor de Saída Liga/Desliga',
  on_off_auto_output_sensor: 'Sensor de Saída Liga/Desliga/Automático',
  tasmota_relay_sensor: 'Sensor de Relé Tasmota',

  /*
   * |===============|
   * | Period Picker |
   * |===============|
   */
  period_date_range: 'Período',
  period_custom: 'Personalizado',
  period_today: 'Hoje',
  period_week: 'Semana',
  period_month: 'Mês',
  period_yesterday: 'Ontem',
  period_last_week: 'Semana passada',
  period_last_month: 'Mês passado',
  period_last_1_hours: 'Ultima hora',
  period_last_2_hours: 'Ultimas 2 horas',
  period_last_4_hours: 'Ultimas 4 horas',
  period_last_24_hours: 'Ultimas 24 horas',
  period_last_48_hours: 'Ultimas 48 horas',

  /*
   * |=====================|
   * | Hook Error Messages |
   * |=====================|
   */
  error_cannot_fetch_device: 'Erro - Dispositivo não encontrado.',
  error_cannot_fetch_device_config: 'Não foi possível configurar o dispositivo.',
  error_cannot_fetch_ports_config: 'Não foi possível configurar a porta do dispositivo',
  error_cannot_fetch_flow_stats: 'Não foi possível encontrar as estatísticas da Vazão.',
  error_cannot_fetch_time_stats: 'Não foi possével encontar as estatísticas do tempo.',
  error_cannot_fetch_general_stats: 'Não foi possével encontar as estatísticas gerais.',
  error_cannot_fetch_qflow_stats: 'Não foi possével encontar as estatísticas de qflow.',

  signup_company_name: 'Nome de Empresa',

  sign_up_form_first_name: 'Nome',
  sign_up_form_last_name: 'Sobrenome',
  sign_up_form_email: 'E-Mail',
  sign_up_form_password: 'Senha',
  sign_up_form_confirm_password: 'Confirmar Senha',
  sign_up_form_company_name: 'Nome da Empresa',

  button_save: 'Salvar',
  button_apply: 'Aplicar',
  button_update: 'Atualizar',
  button_create: 'Criar',
  button_delete: 'Eliminar',
  button_continue: 'Continuar',
  button_back: 'Voltar',

  capital_add_btn: 'ADICIONAR',
  capital_create_btn: 'CRIAR',
  capital_update_btn: 'ATUALIZAR',
  capital_remove_btn: 'REMOVER',
  capital_cancel_btn: 'CANCELAR',

  role_roles: 'Funções',
  role_user: 'Usuário',
  role_public: 'Público',
  role_admin: 'Administrador',
  role_super_admin: 'Super-Administrador',
  role_restricted: 'Restringido',

  device_counters_cumulative_graph_graph:
    'Gráfico, numero de série do dispositivo: {{device_number}}',
  device_counters_cumulative_graph_must_have_analog_sensor:
    'Deve ter um sensor analógico habilitado.',

  sensor_statistics_min: 'Mín.: {{min}}',
  sensor_statistics_max: 'Máx.: {{max}}',
  sensor_statistics_avg: 'Média: {{avrg}}',
  sensor_statistics_total: 'Total: {{total}}',

  chart_statistics_min: 'Mín.:',
  chart_statistics_max: 'Máx.:',
  chart_statistics_avg: 'Média:',
  chart_statistics_total: 'Total:',

  menu_main: 'Principal',
  menu_device: 'Dispositivo',

  submenu_devices: 'Dispositivos',
  submenu_account: 'Conta',
  submenu_device_reports: 'Informações dos dispositivos',
  submenu_settings: 'Ajustes',

  link_selected_device: 'Dispositivo Selecionado',
  link_devices: 'Lista dos dispositivos',
  link_accounts: 'Contas',
  link_utility_rate: 'Tarifa',
  link_link_device: 'Vincular Dispositivo',
  link_create_user: 'Criar Usuário',
  link_icons: 'Ícones',
  link_licenses: 'Licenças',
  link_accounts_manager: 'Contas de Administrador',
  link_transmissions: 'Transmissões',
  link_map: 'Mapa',
  link_inventory: 'Inventário',
  link_devices_manager: 'Administrador dos Dispositivos',
  link_battery: 'Bateria',
  device_groups: 'Grupos de Dispositivos',
  link_signal: 'Sinal',
  link_alarms: 'Alarmes',
  link_value_ranges: 'Eventos de faixa de valor',
  link_device_account_lookup: 'Busca por dispositivos',
  link_show_users: 'Usuários',
  link_dashboard: 'Painel de Controle',
  link_reports: 'Relatórios',
  link_billing_report: 'Relatório de faturamento',

  link_all_devices: 'Todos os Dispositivos',
  link_readings: 'Leituras',
  link_flow: 'Vazão',
  link_graph: 'Gráfico',
  link_new_graph: 'Gráfico Instantâneo',
  link_pump_control: 'Controle da Bomba',
  link_relay_control: 'Controle do Relê',
  link_alarm_config: 'Configurar Alarmes',
  link_statistics: 'Estatísticas',
  link_customer: 'Dados do Cliente',
  link_meter: 'Dados do Medidor',
  link_config: 'Configurar Entrada',
  link_initial_config: 'Configuração Inicial',
  link_sensor_config: 'Configurar Sensor',
  link_sensors_and_network_config: 'Sensores e Rede',
  link_export: 'Exportar Leituras',
  link_customer_billing_report: 'Relátorio de faturamento de clientes',
  link_flow_generator: 'Gerador de Vazão',
  link_commands: 'Enviar Comandos',
  link_copy_config: 'Copiar configuração',
  switch_to_new_menu_label: 'Usar novo menu',
  switch_to_old_menu_label: 'Usar menu antigo',

  link_profile: 'Perfil',
  link_language: 'Idioma',
  link_sign_out: 'Desconectar',

  chip_early_access: 'Acesso Rápido',

  pulse_sensor_1: 'Sensor de Pulso 1',
  pulse_sensor_2: 'Sensor de Pulso 2',
  analog_sensor_1: 'Sensor Analogico 1',
  analog_sensor_2: 'Sensor Analogico 2',
  analog_sensor_3: 'Sensor Analogico 3',
  analog_sensor_4: 'Sensor Analogico 4',
  analog_sensor_5: 'Sensor 1',
  analog_sensor_6: 'Sensor 2',
  analog_sensor_7: 'Sensor 3',
  analog_sensor_8: 'Sensor 4',
  on_off_sensor_1: 'Sensor Liga/desliga 1',
  on_off_sensor_2: 'Sensor Liga/desliga 2',
  on_off_sensor_3: 'Relé Liga/desliga 1',
  on_off_sensor_4: 'Relé Liga/Desliga 2',

  device_icon: 'Ícone de dispositivo',
  sensor_icon: 'Ícone de sensor',
  icon_no_icon: 'Sem ícone',
  icon_barometer: 'Barômetro',
  icon_door_closed: 'Porta Fechada',
  icon_door_open: 'Porta Aberta',
  icon_electricity: 'Eletricidade',
  icon_energy_meter: 'Medidor de energia',
  icon_gas_flow_meter: 'Medidor de vazão de gas',
  icon_gas: 'Gas',
  icon_generic_particle_meter: 'Medidor de partículas genérica',
  icon_generic: 'Genérico',
  icon_heat_meter: 'Medidor de calor',
  icon_humidity: 'Humidade',
  icon_leaf_moisture: 'Humidade do solo',
  icon_level_metering: 'Medição de nível',
  icon_pivot: 'Pivô',
  icon_pressure: 'Pressão',
  icon_radiation: 'Radiação',
  icon_rain: 'Chuva',
  icon_soil_moisture: 'Humidade do solo',
  icon_temperature: 'Temperatura',
  icon_water_meter: 'Medidor de agua',
  icon_water: 'Agua',
  icon_wind_direction: 'Direção do vento',
  icon_wind_speed: 'Velocidade do vento',
  icon_pump: 'Bomba',
  icon_apartment: 'Apartamentos',
  icon_base_station: 'Estação Base',
  icon_clinic: 'Clínica',
  icon_hospital: 'Hospital',
  icon_house: 'Casa',
  icon_mayor_office: 'Prefeitura',
  icon_police_station: 'Delegacia',
  icon_public_restroom: 'Banheiro Publico',
  icon_stadium: 'Estadio',

  very_low_level_alarm: 'Alarme de nivel muito baixo',
  low_level_alarm: 'Alarme de nivel baixo',
  high_level_alarm: 'Alarme de nivel alto',
  very_high_level_alarm: 'Alarme de nivel muito alto',

  sensor_conversion_formula: 'Fórmula de converção do sensor',
  delete_old_counters: 'Apagar contagem anteriores',
  device_config_select_record_period: 'Selecionar período de registro',
  device_config_select_transmission_period: 'Selecionar período de transmissão',

  devices_table_number: 'Número',
  devices_table_name: 'Nome',
  devices_table_device_number: 'Número do dispositivo',
  devices_table_device_name: 'Nome do dispositivo',
  devices_table_business_partner: 'Parceiro comercial',
  devices_table_quarter: 'Trimestre',
  devices_table_address: 'Direção',
  devices_table_error: 'Não foi possível carregar os dispositivos',
  devices_table_no_devices_found: 'Dispositivos não encontrados',
  devices_table_description: 'Descrição',
  devices_column_header_search: 'Buscar...',

  alarm_config_header: 'Configurar alarmes',
  alarm_config_notification_email: 'E-mails de Notificação',
  alarm_config_notification_email_place_holder: 'Lista de E-mails (separada por virgula)',
  alarm_config_missed_transmission_email: 'E-mails de transmissão perdida',
  alarm_config_missed_transmission_email_place_holder:
    'Lista de E-mails de transmissões perdidas (separadas por virgulas',
  alarm_config_high_consumption_threshold:
    'Limite de alto consumo para período de transmissão {{transmission_every}} {{transmission_period}} [{{units}}]',
  alarm_config_low_flow_alarm_threshold: 'Limite de alarme de fluxo baixo [{{units}}]',
  alarm_config_high_flow_alarm_threshold: 'Limite de alarme de fluxo alto[{{units}}]',
  alarm_config_non_zero_consumption_time_range: 'Intervalo de tempo de consumo diferente de zero',
  alarm_config_high_low_flow_time_range: 'Intervalo de tempo do alarme de fluxo alto/baixo',
  alarm_config_start_time: 'Hora de início',
  alarm_config_end_time: 'Hora de término',
  alarm_config_flow_calc_time_base: 'Base de tempo para cálculo de fluxo',
  alarm_config_saved_msg: 'Configuração de alarme salva.',
  alarm_config_email_validation_error:
    'Por favor, insira endereços de e-mail válidos, separados por uma única vírgula e sem espaço, com um máximo de 255 caracteres.',

  alarm_config_non_zero_consumption_time_range_error: 'Deve ter hora de início e hora de fim',

  alarms_title: 'Alarmes',
  alarms_title_with_dev_num: 'Alarmes, Numero de série do dispositivo: {{device_number}}',
  alarms_type: 'Tipo de Alarme',
  alarms_active_status: 'Atividade',
  alarms_active_dropdown_label: 'Ativo',
  alarms_inactive_dropdown_label: 'Inativo',

  device_battery_title: 'Nível de bateria',
  device_battery_voltage: 'Voltagem, mV',
  device_battery_level: 'Nível, %',

  device_signal_title: 'Intensidade do sinal',

  device_counters_graph_header: 'Gráfico de consumo',

  device_instant_graph_header: 'Gráfico Instantâneo',

  devices_signal_title: 'Sinal dos dispositivos',
  devices_signal_level: 'Nível de sinal',
  devices_signal_level_percent: 'Nível, %',

  devices_battery_title: 'Nível de baterias',
  devices_battery_level: 'Nível de bateria, %',
  devices_battery_voltage: 'Voltagem, mV',

  reports_export_title: 'Exportação de Leituras',

  device_counter_export_title:
    'Exportar contadores dos dispositivos, número de série do dispositivo: {{device_number}}',

  device_config_record_and_transmission_period: 'Períodos de registro e transmissão',
  device_config_record_period: 'Período de Registro',
  device_config_transmission_period: 'Período de Transmissão',
  device_config_start_setup: 'Iniciar Configuração',
  device_config_setup_message: 'Configuração Salva. Passe um imã do lado do dispositivo.',
  device_config_header: 'Configurar dispositivo',
  device_config_config_date: 'Data da configuração: {{date}}',
  device_config_config_status: 'Estado',
  device_config_config_status_configured: 'Sincronizado',
  device_config_config_status_not_configured: 'Não sincronizado',
  device_config_time_zone: 'Zona horaria',
  device_config_pulse_per: '{{x}} por pulso',
  device_config_sensor_type: 'Tipo de Sensor',
  device_config_meter_type: 'Tipo de Medidor',
  device_config_select_time_zone: 'Seleciona a zona horaria',
  device_config_pulse_coef_not_changed_message:
    'Advertencia: O "Coeficiente de pulso" personalizado permanece sem alterações devido a alterações na "Leitura Inicial"',

  device_digits_fraction: 'Decimais',
  device_digits_digits: 'Inteiros',
  device_digits_initial_reading: 'Leitura Inicial',
  device_digits_alert_too_many_total_digits: 'O numero total de digitos deve ser <= 9',
  device_digits_alert_too_small_total_digits: 'O numero total de digitos deve ser >= 4',
  device_digits_alert_too_many_total_fraction:
    'O numero de digitos depois do ponto decimal deve ser <= 3 e o numero total de digitos deve ser <= 9',
  device_digits_alert_too_small_total_fraction:
    'O numero de digitos depois do ponto decimal deve ser >= 0 e o numero total de digitos deve ser >= 4',

  device_transmissions_at: 'Transmissão do dispositivo em {{query_date}}',
  device_transmissions_last_transmission_short: 'Última transmissão',
  device_transmissions_next_transmissions_short: 'Próxima transmissão',
  device_transmissions_days: 'Dias',
  device_transmissions_transmissions_short: 'Transm.',
  device_transmissions_count: 'Contar',
  device_transmissions_fail: 'Falhar',
  device_transmissions_customer: 'Cliente',
  device_transmissions_address: 'Direção',
  device_transmissions_ok: 'OK',

  link_device_link_device_to_my_account: 'Vincular dispositivo em minha conta',
  link_device_device_number: 'Número do Dispositivo',
  link_device_unlink_device: 'Desvincular Dispositivo',
  link_device_with_device_number: `Vincular dispositivo: {{device_number}}`,
  unlink_device_with_device_number: `Desvincular dispositivo: {{device_number}}`,
  link_device_devices_counts:
    'Dispositivos vinculados: {{linked_devices_count}} / {{allowed_devices_count}}',
  link_device_success_message: 'Dispositivo associado com sucesso',
  unlink_device_success_message: 'Dispositivo desvinculado com sucesso',
  link_device_enter_device_number_error: 'Entre com um número de dispositivo',
  link_device_select_company_error: 'Selecione uma empresa',
  link_device_default_error: 'Ocorreu um problema ao vincular/desvincular o dispositivo',
  link_device_company_not_found_error: 'Empresa não encontrada',
  link_device_device_not_found_error: 'Dispositivo {{device_number}} não encontrado',
  link_device_device_not_linked_error: 'Dispositivo {{device_number}} não está vinculado',
  link_device_device_already_linked_error: 'Dispositivo {{device_number}} já está vinculado',
  link_device_device_limit_reached_error: 'Límite de dispositivos alcansado',
  link_device_operation_not_allowed_error: 'Operação não permitida',

  device_search_device_number: 'Número do Dispositivo',

  device_manager_create_or_delete_device: 'Criar ou eliminar dispositivo: {{device_number}}',
  device_manager_device_number: 'Número do Dispositivo',
  device_manager_device_model: 'Modelo do Dispositivo',
  device_manager_sw_version_validation:
    'O formato requerido é X.X.X o X.X.X- e X.X.X_ seguido de caracteres, onde X é um número',
  device_manager_hw_version_validation: 'O formato requerido é X.X.X onde X é um número',
  device_manager_software_version: 'Versão do Software',
  device_manager_hardware_version: 'Versão do Hardware',

  dashboard_alarms: 'Alarmes',
  dashboard_batteries: 'Baterias',
  dashboard_signal: 'Sinal',
  dashboard_transmission: '{{alarm_type}} ({{alarm_count}})',
  dashboard_chart_battery_level: 'Nível de bateria',
  dashboard_chart_signal_level: 'Nível de sinal',
  dashboard_chart_number_of_devices: 'Número de dispositivos',
  dashboard_select_company_header: 'Selecione uma conta para prosseguir',
  dashboard_select_company_from: 'De aqui: ',
  dashboard_select_company_accounts_page_link: 'Contas',

  alarm_type_no_zero_consumption: 'Consumo Constante',
  alarm_type_high_consumption: 'Alto Consumo',
  alarm_type_high_flow: 'Alta Vazão',
  alarm_type_missed_transmission: 'Transmisión Fallida',
  alarm_type_low_flow: 'Baixa Vazão',
  alarm_type_all_zero_consumption: 'Sem Consumo',
  alarm_type_device_removed: 'Dispositivo Eliminado',
  alarm_type_on_off_alarm: 'Alarme de ligado/desligado',
  alarm_type_low_level: 'Alarme de Nível Baixo',
  alarm_type_high_level: 'Alarme de Nível Alto',
  alarm_type_tamper: 'Intervenção',
  alarm_type_oversized: 'Super-dimensionado',
  alarm_type_undersized: 'Sub-dimensionado',
  alarm_type_low_battery: 'Bateria baixa',
  alarm_type_blockage: 'Bloqueado',
  alarm_type_burst: 'Fuga Importante',
  alarm_type_reserve_flow: 'Vazão Inversa',

  device_counter_graph_min: 'Mín: {{min}}',
  device_counter_graph_max: 'Máx: {{max}}',
  device_counter_graph_avg: 'Média: {{avrg}}',
  device_counter_graph_total: 'Total: {{total}}',
  device_counter_graph_average: 'Média:',
  device_counter_graph_no_data: 'Sem dados',
  device_counter_graph_cant_calculate_flow_analog:
    'Não se pode calcular a vazão para um sensor analógico!',
  device_counter_graph_must_have_digital_sensor: 'Deve ter um sensor digital habilitado.',

  customer_info_header: 'Informações do Cliente',
  customer_info_business_partner_number: 'Numero do parceiro comercialConta',
  customer_info_contact_data: 'Dados de Contato',
  customer_info_contract_number: 'Número do contrato do cliente',
  customer_info_name: 'Nome do Cliente',
  customer_info_description: 'Descrição',
  customer_info_billing_group: 'Grupo de faturamento',
  customer_info_municipality: 'Municipio',
  customer_info_quarter: 'Região',
  customer_info_street: 'Rua',
  customer_info_block: 'Bloco',
  customer_info_entr: 'Departamento',
  customer_info_customer_type: 'Tipo de Cliente',
  customer_info_customer_type_commercial: 'Comercial',
  customer_info_customer_type_domestic: 'Doméstico',
  customer_info_customer_type_block_of_flats: 'Condominio',
  customer_info_customer_type_budget: 'Orçamento',

  meter_info_meter_info: 'Informação do Medidor: {{device_number}}',
  meter_info_factory_number: 'Número de Serie do Medidor',
  meter_info_brand: 'Fabricante',
  meter_info_enter_diameter: 'Diâmetro',
  meter_info_mount_date: 'Data de Instalação',
  meter_info_meter_info_saved: 'Informação do medidor salvo.',

  device_initial_config_header: 'Configuração Inicial do Dispositivo',
  device_initial_device_model: 'Modelo do Dispositivo',
  device_initial_config_address: 'Endereço',
  device_initial_config_port: 'Porta',
  device_initial_config_resolve_address: 'Resolver endereço (usar DNS)',
  device_initial_config_simcard_provider: 'Provedor do cartão SIM',
  device_initial_config_simcard_number: 'Número do cartão SIM',
  device_initial_config_bands: 'Faixas',
  device_initial_config_OTAA_appkey: 'OTAA - appkey',
  device_initial_config_OTAA_deveui: 'OTAA - deveui',
  device_initial_config_OTAA_appeui: 'OTAA - appeui',
  device_initial_config_ABP_appskey: 'ABP - appskey',
  device_initial_config_ABP_nwkskey: 'ABP - nwkskey',
  device_initial_config_ABP_devaddr: 'ABP - devaddr',
  device_initial_config_ABP_deveui: 'ABP - deveui',
  device_initial_config_lora_app_mode: 'Modo de aplicação',
  device_initial_config_app_id: 'ID da aplicação',
  device_initial_config_profile_id: 'ID do perfil',
  device_initial_config_platform_name: 'Nome da plataforma',
  device_initial_config_thingslog_lora_platform: 'Plataforma ThingsLog LoRa',
  device_receive_data_binary: 'Receber dados binarios',
  device_send_data_binary: 'Enviar dados binários',
  device_mqtt_config: 'MQTT configuração',
  device_mqtt_config_qos: 'Qualidade do serviço',
  device_mqtt_config_username: 'Nome de usuário',
  device_mqtt_config_password: 'Senha',
  device_mqtt_config_certificate: 'Certificado',
  device_initial_config_apn_username: 'Nome do Usuário APN',
  device_initial_config_apn_password: 'Senha APN',
  device_initial_config_gprs_radio_mode: 'Modo de Radio GPRS',
  device_initial_config_grps_radio_mode_auto_option: 'Automático',
  device_initial_config_net_attached_delay: 'Atraso de transmissão',
  device_initial_config_preferred_network_type: 'Tipo de Rede Preferido',
  device_initial_config_preferred_network_default_option: 'Por defeito',
  device_initial_config_mcc_mnc: 'MCC-MNC',
  device_initial_config_slave_devices: 'Dispositivos escravos',
  device_initial_config_modbus_address: 'Endereço Modbus',

  sensor_config_header: 'Configuração de Sensor',
  sensor_config_name: 'Nome',
  sensor_config_sensor_name: 'Nome do Sensor',
  sensor_config_sensor_icon: 'Nome do ícone',
  sensor_config_description: 'Descrição',
  sensor_config_model: 'Modelo',
  sensor_config_serial_number: 'Número de Série',
  sensor_config_manufacturer: 'Fabricante',
  sensor_config_diameter: 'Diâmetro',
  sensor_config_price: 'Preço',
  sensor_config_currency: 'Moeda',
  sensor_config_port_type: 'Tipo de Porta',
  sensor_config_port_type_digital_input_port: 'Porta de Entrada Digital',
  sensor_config_port_type_analog_input_port: 'Porta de Entrada Analógica',
  sensor_config_port_type_pulse_s0_input_port: 'Porta de Entrada de Pulso S0',
  sensor_config_port_type_pulse_input_port: 'Porta de Entrada de Pulsos',
  sensor_config_port_type_on_off_input_port: 'Porta de Entrada Ligado/Desligado',
  sensor_config_port_type_on_off_output_port: 'Porta de Saída Ligado/Desligado',
  sensor_config_port_conversion_formula: 'Fórmula de Converção de Porta',
  sensor_config_formula: 'Fórmula',
  sensor_config_offset: 'Compensação',
  sensor_config_sensor_index_tooltip: 'índice dos sensores: {{sensor_index}}',
  sensor_config_visible: 'Visibilidade',
  sensor_config_order_number: 'Número do pedido',
  sensor_config_visible_true: 'Visível',
  sensor_config_visible_false: 'Invisível',
  sensor_config_use_in_sensor_aggregation: 'Uso em agregação de sensores',
  sensor_config_use_in_sensor_aggregation_true: 'Usar',
  sensor_config_use_in_sensor_aggregation_false: 'Não usar',
  sensor_config_value_labels: 'Etiquetas de valores',
  sensor_config_label_for_value_zero: 'Etiqueta para valor "0"',
  sensor_config_label_for_value_one: 'Etiqueta para valor "1"',
  sensor_config_inverted_read_state_value: 'Estado de leitura invertido',
  sensor_config_inverted_read_state_value_true: 'Invertido',
  sensor_config_inverted_read_state_value_false: 'Não invertido',
  sensor_config_register_address: 'Endereço do Registro',
  sensor_config_data_type: 'Tipo de Dado',
  sensor_config_count: 'Quantidade',
  sensor_config_number_bytes: 'Número de Bytes',
  sensor_config_particle_size: 'Tamanho da partícula',
  sensor_config_power: 'Energia',

  device_counter_statistics_statistics_id: 'Estatísticas, id: {{device_number}}, {{description}}',
  device_counter_statistics_quarter: 'Localidade',
  device_counter_statistics_flow: 'Vazão',
  device_counter_statistics_hour: 'Hora',
  device_counter_statistics_quantity: 'Quantidade',
  device_counter_statistics_value: 'Valor',
  device_counter_statistics_digital_ports_must_be_present:
    'Deve ter uma entrada de pulso conectado ao sensor de um medidor de água para ver esta tabela',
  device_counter_statistics_name_must_have_initialized_value:
    'Os parámetros Q3, R e Diâmetro são necessários na entrada de pulsos conectada ao sensor do medidor de água.',

  company_manager_title: 'Criar ou apagar uma conta: {{company_name}}',
  company_manager_account_name: 'ID da Conta',
  company_manager_solution: 'Тipo de solução',
  company_manager_solution_option_none: 'Não atribuído',
  company_manager_solution_option_agri: 'Agricultura',
  company_manager_solution_option_air_quality: 'Qualidade de ar',
  company_manager_solution_option_street_lights: 'Iluminação pública',
  company_manager_solution_option_pump_station: 'Estação de bombeamento',
  company_manager_license: 'Licença',
  company_manager_account: 'Conta',
  company_manager_create_company: 'Criar uma conta',
  company_manager_create_company_error: 'Houve um problema ao criar sua conta',
  company_manager_license_info_box:
    'O anexo da licença reguer uma ID de conta válida. Verifique novamente sua ID da conta se deseja incluí-la no processo de criação.',
  company_manager_valid_from: 'Válido de',
  company_manager_valid_to: 'Válido até',
  company_manager_solution_type: 'Tipo de solução',
  company_manager_contact_id: 'ID de contato',
  company_manager_account_id: 'ID da conta',
  company_manager_license_type: 'Tipo de Licença',
  company_manager_timezone: 'Fuso Horário',
  company_manager_country: 'País',
  company_manager_countries_error: 'Não foi possível carregar os países',
  company_manager_timezones_error: 'Não foi possível carregar o fuso horário',
  company_manager_account_info: 'Informação da conta',
  company_manager_address: 'Endereço',
  company_manager_city: 'Cidade',
  company_manager_municipality: 'Município',
  company_manager_area: 'Área',
  company_manager_zip_code: 'Código postal',
  company_manager_vat_number: 'Número do IVA',

  company_manager_license_per_device: 'Por dispositivo',
  company_manager_license_per_reading: 'Por informe',
  company_manager_license_per_sensor: 'Por sensor',
  company_manager_license_per_poc: 'Por Prova de Conceito (POC)',
  company_manager_license_per_remote_node: 'Por Nó Remoto',

  company_manager_creating_account: 'Criando conta: {{company_name}}',
  company_manager_deleting_account: 'Eliminando conta: {{company_name}}',
  company_manager_account_created: 'Conta criada corretamente',
  company_manager_account_deleted: 'Conta eliminada corretamente',

  company_manager_update_company: 'Atualizar conta',
  company_manager_delete_company: 'Eliminar conta',
  company_manager_actions: 'Ações',
  company_manager_delete_modal_description:
    'Tem certeza que deseja excluir esta conta? Esta ação é irreversível.',
  company_manager_confirm_button: 'Confirmar',
  company_manager_cancel_button: 'Cancelar',
  company_manager_delete_company_error_message: 'Ocorreu um erro ao excluir esta conta',
  company_manager_update_btn: 'Atualizar',
  company_manager_child_company_warning_message: 'Criando Subconta',
  company_manager_current_logged_in_company: 'A conta na qual está conectado no momento',
  company_manager_current_selected_company: 'A conta na gual está selecionado no momento',
  accounts_child_companies: 'Contas secundárias',
  accounts_contactRef: 'ID do contato',
  accounts_accountRef: 'ID da conta',
  company_manager_accountRef_is_disabled_because_of_license_type:
    'O ID da conta está desabilitado porque há uma licença por POC',
  accounts_none: 'Nenhum',
  company_manager_update_company_success: 'Conta atualizada com exito',
  company_manager_update_company_error: 'Ocorreu um erro ao atualizar a conta',

  license_max_readings: 'Leituras máximas',
  license_max_devices: 'Dispositivos máximos',
  license_max_sensors: 'Sensores máximos',

  license_manager_title: 'Atualizar licença: {{company_name}}',
  license_manager_license: 'Licença',
  license_manager_license_updated: 'Licença atualizada',

  companies_title: 'ID Impresa: {{company_number}}',
  companies_table_name: 'Nome',
  companies_number_of_accounts: 'Numero das contas:',
  companies_search: 'Procurar',

  users_title: 'Usuarios',
  units_type: 'Tipo de Unidades',

  reports_export_format_type: 'Formato',

  login_form_forgot_password: 'Esqueseu sua senha??',

  pump_auto_every: 'A cada {{secs}} segundos',
  pump_svg_last_updated: 'Última Atualização: {{timestamp}}',
  pump_svg_power_supply: 'Fonte de alimentação',
  pump_svg_water_level_controller: 'Controlador de Nível de Água',
  pump_svg_pump: 'Bomba',
  pump_svg_level_sensor: 'Sensor de Nível',
  pump_svg_pump_on_sensor: 'Sensor Bomba Ligado',
  pump_svg_pump_off_sensor: 'Sensor Bomba Desligada',
  pump_svg_dry_run_sensor: 'Sensor de Funcionamento há Seco',

  device_groups_error_please_select_company: 'Selecione uma empresa.',

  device_groups_btn_add: 'Inserir',
  device_groups_btn_edit: 'Editar',
  device_groups_btn_delete: 'Apagar',
  device_groups_btn_cancel: 'Cancelar',
  device_groups_tooltip_edit: 'Editar',
  device_groups_tooltip_delete: 'Apagar',
  device_groups_color_picker_label: 'Cores do grupo de dispositivos',

  device_groups_name: 'Nome',
  device_groups_devices: 'Dispositivos',
  device_groups_view_devices: 'Ver {{count}} dispositivos',
  device_groups_group_name: 'Nome do grupo',
  device_groups_parent_device_group: 'Grupo pai de dispositivos',
  device_groups_menuitem_none: 'Nenhum',
  device_groups_add_device_group: 'Inserir grupo de dispositivos',
  device_groups_edit_device_group: 'Editar grupo de dispositivos',
  device_groups_delete_device_group: 'Eliminar grupo de dispositivos',

  device_grous_add_sensor_device: 'Inserir sensor e dispositivo de grupo',

  device_groups_table_sensor_group_name_header: 'Nome do grupo de sensores',
  device_groups_table_parent_group_name_header: 'Nome do grupo principal',
  device_groups_table_device_count_header: 'Contagem de dispositivos',

  device_groups_sensor_table_device_number_header: 'Número de série do dispositivo',
  device_groups_sensor_table_sensor_name_header: 'Nome do sensor',
  device_groups_sensor_table_sensor_index_header: 'Leitura do sensor',
  device_groups_sensor_table_select_message: 'Selecionar um grupo de sensores',

  device_groups_sensors_add_modal_device_placeholder: 'Número de série do dispositivo',
  device_groups_sensors_add_modal_sensor_placeholder: 'Sensor',
  device_groups_sensors_add_modal_device_group_name_required:
    'O nome do grupo de dispositivos é obrigatório',

  device_groups_sensors_delete_description:
    'A exclusão desta configuração removerá permanentemente do servidor.',
  device_groups_device_group_delete_description:
    'A exclusão do grupo de dispositivos removerá permanentemente do servidor.',

  device_groups_select_company_header: 'Selecione uma empresa para continuar',
  device_groups_select_company_from: 'Daqui: ',
  device_groups_select_company_accounts_page_link: 'Página da conta',

  device_groups_create_device_group_error:
    'Houve um problema ao criar um novo grupo de dispositivos',
  device_groups_update_device_group_error: 'Houve um problema ao atualizar o grupo de dispositivos',
  device_groups_delete_device_group_error: 'Houve um problema ao excluir o grupo de dispositivos',
  device_groups_add_device_sensor_device_group_error: 'Houve um problema ao inserir um sensor',
  device_groups_remove_device_sensor_device_group_error: 'houve um problema ao excluir um sensor',

  /*
   * |==============|
   * | Users Config |
   * |==============|
   */

  //Page
  users_header: 'Usuários',
  users_table_username: 'Nome de usuário',
  users_table_first_name: 'Nome',
  users_table_last_name: 'Sobrenome',
  users_table_company_name: 'Conta',
  users_table_email: 'E-mail',

  //Filters
  users_filter_username: 'Filtrar nome de usuário',
  users_filter_first_name: 'Filtrar nome',
  users_filter_last_name: 'Filtrar sobrenome',
  users_filter_email: 'Filtrar E-mail',
  users_filter_company_name: 'Filtrar conta',
  users_reset_filter_icon: 'Restabelecer filtros',
  users_table_is_contact_person: 'Pessoa de contato',

  //Modals
  users_add_modal_header: 'Adicionar usuário',
  users_update_modal_header: 'Atualizar usuário',
  user_remove_modal_icon: 'Eliminar usuário',
  users_remove_modal_header: 'Tem certeza que deseja excluir o usuário {{username}}?',
  users_remove_modal_body:
    'Ao clicar em excluir, o usuário escolhido será removido permanentemente do servidor.',
  users_is_dashboard_enabled: 'Painel de controle Ativado',
  users_is_contact_person: 'Pessoa de contato',

  //Errors
  users_password_match: 'As senhas não coincidem',
  users_email_blank: 'O E-mail não pode estar em branco',
  users_load_error: 'Não foi possível carregar os usuários',
  users_update_error: 'Não foi possível atualizar o usuário',
  users_delete_error: 'Não foi possível excluir o usuário',
  users_create_error: 'Não foi possível criar o usuário',

  // Success
  users_update_success: 'Usuário atualizado com êxito',
  users_delete_success: 'Usuário excluído com êxito',
  users_create_success: 'Usuário criado com êxito',
  /*
   * |=====================|
   * | Value Ranges Config |
   * |=====================|
   */

  // Buttons & tooltips
  value_ranges_btn_add: 'Adicionar',
  value_ranges_btn_defaults: 'Valores predeterminados',
  value_ranges_line_color: 'Cor da linha',
  value_ranges_node_color: 'Cor do nó',
  value_ranges_foreground_color: 'Cor do primeiro plano',
  value_ranges_background_color: 'Cor de fundo',
  value_ranges_loading: 'Carregando...',
  value_ranges_nothing_to_show: 'Nada para mostrar',

  //Page
  value_ranges_header: 'Eventos de faixa de valor',
  value_ranges_trigger: 'ativar',
  value_ranges_notifications: 'notificações',
  value_ranges_before: 'Antes',
  value_ranges_after: 'Depois',
  value_ranges_disabled: 'Desativado',

  //Modals
  value_ranges_add_event: 'Adicionar evento',
  value_ranges_edit_event: 'Editar evento',
  value_ranges_add_default_events: 'Adicionar eventos predeterminados',
  value_ranges_remove_modal_header: 'Está certo que deseja excluir a faixa de valores',
  value_ranges_remove_modal_body:
    'Ao clicar em excluir, irá eliminar permanentemente a faixa de valores escolhido',

  //Form
  value_range_form_name: 'Nome do evento',
  value_range_form_function: 'Função',
  value_range_form_sensor: 'Sensor',
  value_range_form_description: 'Descrição',
  value_range_form_min: 'Min.',
  value_range_form_max: 'Max.',
  value_range_form_start_day: 'Dia de início',
  value_range_form_start_month: 'Mês de início',
  value_range_form_start_time: 'Hora de início',
  value_range_form_end_day: 'Dia final',
  value_range_form_end_month: 'Mês final',
  value_range_form_end_time: 'Hora de finalização',
  value_range_form_enable_events: 'Habilitar eventos?',
  value_range_form_select_color: 'Cor selecionada',
  value_range_form_notification_email: 'E-mail',
  value_range_form_notification_push_notification: 'Notificação de inserção',
  value_range_form_notification_sms: 'SMS',
  value_range_form_notification_push: 'Notificação de inserção',
  value_range_form_notification_call: 'Chamar',
  value_range_form_line_custom_color: 'Cor da linha personalizado',
  value_range_form_marker_custom_color: 'Cor do marcador personalizado',
  value_range_form_no_notifications: 'Nenhuma Notificação',

  //Errors
  value_range_error_add: 'Não foi possível adicionar evento',
  value_range_error_edit: 'Não foi possível editar evento',
  value_range_name_is_required: 'É necessario o nome',
  value_range_name_already_exists: 'O nome do evento já existe para este dispositivo e sensor',
  value_range_min_greater_than_max: 'Min. não pode ser maior do que max.',
  value_range_min_equal_to_max: 'Min. não pode ser igual a max.',
  value_range_name_incorect_time: 'Formato de hora incorreto',
  value_range_min_max_is_required: 'Mínima ou Máxima é necessário',
  value_range_error_occured: 'Ocorreu um erro',
  value_range_start_incorrect_time:
    'A hora de início tem um formato incorreto. Deveria ser HH: mm. Correto: 01:00. Incorreto: 1:00.',
  value_range_end_incorrect_time:
    'A hora da finalização tem um formato incorreto. Deveria ser HH: mm. Correto: 01:00. Incorreto: 1:00.',
  value_range_start_day_missing_start_month: 'O dia de início deve ter o mês de início',
  value_range_end_day_missing_end_month: 'O dia de finalização deve ter um mês de finalização',
  value_range_start_month_missing_start_day: 'O mês de início de ter um dia de início',
  value_range_end_month_missing_end_day: 'O mês final deve ter um dia final',
  value_range_mixed_alphabets_error: 'Detectado o uso de idiomas mixtos, use apenas um idioma',

  /*
   * |==========|
   * | Commands |
   * |==========|
   */

  // Command State
  commands_command_state_pending: 'Pendente',
  commands_command_state_sent: 'Enviado',
  commands_command_state_cancelled: 'Cancelado',
  commands_command_state_status_ok: 'OK',
  commands_command_state_status_nok: 'Não OK',
  commands_command_state_unknown: 'Desconhecido',
  commands_command_state_timeout: 'Tempo de espera agotado',

  // Command Type
  commands_command_type_relay_switch: 'Interruptor de relê',
  commands_command_type_single_relay_switch: 'Interruptor de relê único',
  commands_command_type_relay_switch_with_delay: 'Chave do relê com atraso',
  commands_command_type_relay_statuses: 'Estados dos relés',
  commands_command_type_send_config_over_mqtt: 'Enviar configuração través do MQTT',
  commands_command_type_send_date_over_mqtt: 'Enviar dados a través do MQTT',
  commands_command_type_modbus: 'Modbus',
  commands_command_type_tasmota_relay_switch: 'Saídas de relê Tasmota',

  // Errors
  commands_error_missing_device_number: 'Série Dispositivo faltando ou errada.',

  // Buttons
  commands_btn_send_command: 'Enviar Comando',
  commands_btn_refresh: 'Atualizar',
  commands_btn_submit: 'Enviar',

  // Tooltips
  commands_tooltip_edit: 'Editar',

  // Popups
  commands_send_command: 'Enviar Comando',
  commands_edit_command: 'Editar Comando',
  commands_command_type: 'Tipo de Comando',
  commands_parameters: 'Parâmetros',
  commands_parameter_name: 'Nome do parâmetro',
  commands_parameter_value: 'Valor do parâmetro',

  // Page
  commands_header: 'Comandos',
  commands_type: 'Tipo',
  commands_status: 'Estado',
  commands_relay_1_status: 'Estado do Relê 1',
  commands_relay_2_status: 'Estado do Relê 2',
  commands_created: 'Criado',
  commands_sent: 'Enviado',
  commands_executed: 'Executado',
  commands_relay_status: 'Estado do relê',
  commands_relay_index: 'Índice do relê',
  commands_device_number_slave: 'Número do dispositivo escravo',
  commands_sensor_index: 'Índice do sensor',

  /*
   * |=============|
   * | Copy Config |
   * |=============|
   */

  // Page
  copy_config_copy_config: 'Copiar Configuração',
  copy_config_to_device_number_with_name_absent: 'Para o dispositivo: {{device_number}}',
  copy_config_to_device_number_with_name_present:
    'Para o dispositivo: {{device_name}} ({{device_number}})',
  copy_config_from_device_number: 'Do numero de série do dispositivo',

  // Buttons
  copy_config_btn_copy: 'Cópia',

  //Toast
  copy_config_toast_copied_success: 'A cópia da configuração do dispositivo executada com sucesso.',

  /*
   * |============|
   * | Statistics |
   * |============|
   */
  statistics_header: 'Estatísticas',
  statistics_header_hours: 'Horas',
  statistics_header_quantity: 'Quantidade',
  statistics_header_value: 'Valor',
  statistics_quantity_minimum: 'Mínimo',
  statistics_quantity_maximum: 'Máximo',
  statistics_quantity_average: 'Média',
  statistics_quantity_total: 'Total',
  statistics_quantity_hours: 'Horas',
  statistics_quantity_counters: 'Contadores',

  // Error
  statistics_error_definition_for_q3:
    'O sensor {{name}} contém uma definição para q3. Consulte a página de configuração do sensor.',
  statistics_eror_definition_for_diameter:
    'O sensor {{name}} contém uma definição de diâmetro. Consulte a página de configuração do sensor.',
  statistics_error_definition_for_r:
    'O sensor {{name}} contém uma definição para r. Consulte a página de configuração do sensor.',
  statistics_error_no_water_meters_found:
    'Não foi encontrado medidores de água. Visite a página de configuração do dispositivo.',

  /*
   * |=====|
   * | Map |
   * |=====|
   */
  map_add_device: 'Adicionar {{device}}',
  map_edit_device: 'Editar {{device}}',
  map_lat: 'Latitude',
  map_long: 'Longitude',
  map_coordinates: 'Coordenadas',
  map_polygon: 'Polígono',
  map_linked_in_polygons: 'Conteúdo em polígonos:',
  map_loading: 'Carregando...',

  // Button
  map_btn_link_device: 'Linkar dispositivo',
  map_btn_submit: 'Enviar',

  // Error
  map_err_add_device_to_map: 'Não foi possível Adicionar o dispositivo no mapa.',
  map_err_change_location: 'Não foi possível mudar a localização.',
  map_err_link_polygon: 'Não foi possível vincular o polígono.',

  // Device Modal
  map_btn_get_curr_location: 'Obter localização atual',
  map_geolocation_not_supported: 'A geolocalização não é compativel com este navegador.',
  map_lat_validation_message: 'Latitude não é válida',
  map_long_validation_message: 'Longitude não é válida',
  map_invalid_location: 'Localização inválida',
  map_invalid_coordinate_input:
    'Entrada de coordenadas não é válida. Deveria ser "lat, long", p.e. 42.123, 23.123',

  // Set Coordinates Modal
  map_coordinates_modal_btn_label: 'Estabelecer Coordenadas',
  map_coordinates_modal_title: 'Estabelecer Coordenadas',
  map_coordinates_modal_target_option_self: 'Para si mesmo',
  map_coordinates_modal_target_option_company: 'Para a Empresa',
  map_coordinates_modal_target_option_user: 'Para o Usuário',
  map_coordinates_modal_select_user_autocomplete_label: 'Nome do Usuário',
  map_coordinates_modal_save_for_self_warning_message:
    'Salvar definirá as coordenadas atuais como padrão para o usuário atual, Tem certeza de que deseja continuar?',
  map_coordinates_modal_save_for_company_warning_message:
    'Salvar definirá as coordenadas atuais como predeterminadas para cada usuário em sua empresa. Tem certeza de que deseja continuar?',
  map_coordinates_modal_save_for_user_warning_message:
    'Salvar definirá as coordenadas atuais como predeterminadas para o usuário selecionado. Tem certeza de que deseja continuar?',
  map_coordinates_modal_confirm_btn_label: 'Confirmar',
  map_coordinates_modal_cancel_btn_label: 'Cancelar',

  /*
   * |===============|
   * | Sensor Config |
   * |===============|
   */

  flow_time_units: 'Unidade de tempo',
  flow_measurement_units: 'Unidade de medida',
  flow_configuration_label: 'Configuração de fluxo',
  flow_coefficient: 'Coeficiente',
  sensor_config_sensor_group_name: 'Nome do grupo de sensores',
  flow_configuration_alert_message: 'Confirme as seguintes configurações de fluxo:\n{{message}}',
  flow_configuration_invalid_sensor_row_message:
    'Índice: {{sensor_index}} com nome: {{sensor_name}}\n',
  flow_configuration_sensor_name_not_set_label: 'Não estabelecido',

  /*
   * |===================|
   * | Device Prediction |
   * |===================|
   */
  device_prediction: 'Previsão de dispositivo',
  device_prediction_header: 'Análise de série temporal',
  device_prediction_sensor_name: 'Nome do sensor',
  device_prediction_forward_days: 'Dias futuros',
  device_prediction_backward_days: 'Dias atrás',
  device_prediction_generate_btn: 'Gerar',
  device_prediction_error: 'Erro',
  device_prediction_error_msg_json: 'Houve um problema ao gerar dados de previsão.',
  device_prediction_error_msg_image: 'Houve um problema ao gerar a imagem de previsão.',
  device_prediction_date: 'Data',
  device_prediction_trend: 'Tendência',
  device_prediction_time: 'Hora',
  device_prediction_legend_prediction: 'Previsão',
  device_prediction_legend_range: 'Faixa de previsão',
  device_prediction_legend_consumption: 'Consumo atual',
  device_prediction_help_title: 'Ajuda',
  device_prediction_help_title_input_guide: 'Guia de Entrada',
  device_prediction_help_input_guide_text:
    '<strong> 1. Período </strong> - Os usuarios podem selecionar uma faixa de datas entre opções predefinidas para establecer uma faixa personalizada. O sistema analiza os dados dentro deste período escolhido para calcular previsões futuras, oferecendo informação valiosa. <br> <strong> 2. Nome do Sensor </strong> - seleciona o sensor ao qual desejas ver suas previsões futuras. <br> <strong> 3. Cada </strong> - se utiliza para previsões mais detalhadas em términos da granularidade do tempo. <br> <strong> 4. Dias à frente </strong> - número de dias em que o usuario deseja prever no futuro. Estas previsões se extendem além do intervalo das datas selecionadas, dando ideias sobre o comportamento esperado de consumo. <br> <strong> 5. Dias atras </strong> - representa o numero de dias para mostrar no gráfico e na tabela, começando desde o ultimo dia do período selecionado. Isto mostra os dados historicos reais recebidos do dispositivo durante este periodo especificado. <br> <strong> 6. Botão Gerar </strong> - ao coicar em "Gerar", o sistema calcula o consumo em função da configuração selecionada e gera previsões. Os resultados se mostram tanto visualizando em Gráficos como na visualização em tabela. <br> <strong> 7. Gráfico </strong> - O grafico mostra tanto os dias para frente como para os dias para atrás, apresentando os dados reais recebidod e mostrando os dados previstos. <br> <strong> 8. Tabela </strong> - A tabela mostra todos os dados  do "periodo inicial" até o final dos dias seguintes. Cada linha inclui uma data específica, hora e valor de tendência correspondente, indicando a direção o padrão geral de dados ao longo do tempo. Ajuda a compreender se os valores estão aumentando, diminuindo ou permanecendo estável durante o período previsto.',
  device_prediction_help_title_legend: 'Legenda',
  device_prediction_help_black:
    '<strong> Color Negro </strong> - Representa os dados reais de consumo durante o período selecionado (exemplo: de 01 de julho até 31 de julho).',
  device_prediction_help_red:
    '<strong> Color Rojo </strong> - Representa o consumo real se há dados fora do período selecionado. Na imagem acima, há dados reais recebidos do dispositivo nos dias em que o modelo está configurado para prever. Assim, o usuário pode comparar os valores previstos(azul escuro) com os valores em vermelho(dados reais).',
  device_prediction_help_dark_blue:
    ' <strong> Color Azul Oscuro </strong> - Representa a previsão para o período selecionado incluindo dados antigos e estendendo-se para o futuro com os valores previstosdos dias seguintes. Esse valores previstos oferecem informações sobra a tendência de consumo esperada.',
  device_prediction_help_light_blue:
    '<strong> Color Azul Claro </strong> - Representa o intervalo de valores previstos. Indica que os dados de consumo real podem situar-se neste intervalo, salientando a incerteza associada ás previsões.',
  device_prediction_help_example:
    "Se a data 'DE' for definida para 1 de julho e a data 'PARA' for definida para 31 de julho, com 'Avanças dias a frente' de 30, 'Voltas dias' de 30 e 'CADA' 10 minutos, o gráfico mostrará o consumo real para os 30 dias seguintes (de 1 de julho a 1 de agosto) e os valores previstos para 30 dias seguintes (de 1 de agosto a 1 de setembro). A tabela mostrará o consumo real e previsto para cada intervalo de 10 minutos de 1 de julho a 1 de setembro de 2023.",

  /*
   * |=================|
   * | Counters Export |
   * |=================|
   */

  // Buttons and dropdowns
  counters_export_header: 'Exportar datos',
  counters_readings_header: 'Informes',
  counters_export_search: 'Buscar',
  counters_export_download: 'Baixar',
  counters_export_format: 'Formato',

  // Table columns
  counters_export_table_device_name: 'Nome do dispositivo',
  counters_export_table_device_number: 'Número do dispositivo',
  counters_export_table_sensor_index: 'Índice de sensores',
  counters_export_table_sensor_name: 'Nome do sensor',
  counters_export_table_date: 'Data',
  counters_export_table_counters: 'Informação',
  counters_export_table_prev_counters: 'Informação anterior',
  counters_export_table_dt: 'dt',
  counters_export_table_delta: 'Delta',
  counters_export_error_alert_message: 'Ocorreu um erro ao gerar os dados.',
  counter_export_select_devices_label: 'Selecionar dispositivos',
  counter_export_select_sensors_label: 'Selecionar sensores',
  counter_export_sensor_index: 'Índice de sensores: {{sensor_index}}',

  counters_export_maximum_capacity_error_message: 'Capacidade máxima de exportação alcançada',
  counters_export_maximum_capacity_suggestion_message:
    'Diminua a quantidade de dispositivos selecionados no período.',
  /*
   * |====================|
   * | Consumption Export |
   * |====================|
   */

  // Buttons and dropdowns
  consumption_export_header: 'Relatório',
  consumption_export_search: 'Buscar',
  consumption_export_download: 'Baixar',
  consumption_export_format: 'Formato',
  consumption_export_aggregation_period: 'Período de Agregação',
  consumption_export_aggregation_options_none: 'Nenhum',
  consumption_export_aggregation_options_daily: 'Diário',
  consumption_export_aggregation_options_weekly: 'Semanal',
  consumption_export_aggregation_options_monthly: 'Mensal',
  consumption_export_aggregation_options_yearly: 'Anual',

  // Table columns
  consumption_export_table_device_number: 'Número do dispositivo',
  consumption_export_table_sensor_index: 'Índice de sensores',
  consumption_export_table_address: 'Direção',
  consumption_export_table_sensor_name: 'Nome do sensor',
  consumption_export_table_first_reading_date: 'Data da primeira leitura',
  consumption_export_table_first_reading_value: 'Valor de primera leitura',
  consumption_export_table_last_reading_date: 'Data de última leitura',
  consumption_export_table_last_reading_value: 'Valor da última leitura',
  consumption_export_table_delta: 'Delta',
  consumption_export_table_average: 'Média',
  consumption_export_table_consumption_for_period: 'Consumo para o período',

  /*
   * |=================|
   * | Alarms Export |
   * |=================|
   */

  // Buttons and dropdowns
  alarms_export_header: 'Alarmes',
  alarms_export_search: 'Buscar',
  alarms_export_refresh: 'Atualizar',
  alarms_export_download: 'Baixar',
  alarms_export_format: 'Formato',

  alarms_return_to_alarms: 'Voltar para alarmes',
  alarms_redirect_to_graph: 'Voltar ao Gráfico',

  // Table columns
  alarms_export_table_date: 'Data',
  alarms_export_table_device_number: 'Número do dispositivo',
  alarms_export_table_device_name: 'Nome do dispositivo',
  alarms_export_table_sensor_index: 'Índice de sensores',
  alarms_export_table_sensor_name: 'Nome do sensor',
  alarms_export_table_alarm_type: 'Tipo',
  alarms_export_table_alarm_status: 'Estado',
  alarms_export_table_description: 'Descrição',
  alarms_export_table_link_to_graph: 'Link para o gráfico',
  alarms_export_chip_status_active: 'Ativo',
  alarms_export_chip_status_inactive: 'Inativo',
  alarms_export_switch_title_label: 'Atualização Automátiva',
  alarms_export_error_alert_message: 'Ocorreu um erro ao gerar os dados.',
  alarms_export_select_company_alert_message: 'Selecione uma empresa antes de pesquisar alarmes',
  alarms_export_select_device_or_company_alert_message:
    'Selecione um dispositivo ou uma empresa antes de pesquisar alarmes',
  alarms_export_error_too_big_query:
    'Recuperar o histórico de Super-Administrador para todos os dispositivos é uma pedido muito grande. Selecione uma empresa.',

  /*
   * |================|
   * | Inventory page |
   * |================|
   */

  //table
  device_inventory_page_header: 'Inventario',
  device_inventory_column_header_search: 'Pesquisar…',
  device_inventory_description: 'Descrição',
  device_inventory_device_name: 'Nome do dispositivo',
  device_inventory_device_number: 'Número do dispositivo',
  device_inventory_hardware_version: 'Versão do hardware',
  device_inventory_model: 'Modelo',
  device_inventory_software_version: 'Versão do software',
  device_inventory_actions: 'Ações',

  //modal
  device_inventory_add_device: 'Adicionar dispositivo',
  device_inventory_create_device_button: 'Criar dispositivo',
  device_inventory_delete_unlink_modal_body:
    'Esta ação eliminará permanentemente o dispositivo escolhido e irá desvincula-lo da empresa selecionada.',
  device_inventory_delete_modal_body:
    'Esta ação irá eliminar permanentemente o dispositivo selecionado.',
  device_inventory_hardware_version_helper: 'O formato requerido é X.X.X onde X é um número',
  device_inventory_update_device_button: 'Atualizar dispositivo',
  device_inventory_update_device_title: 'Atualizando {{number}}',
  device_inventory_modal_delete_device_title: 'Eliminar dispositivo {{number}}',
  device_inventory_software_version_helper:
    'O formato requerido é X.X.X o X.X.X- y X.X.X_ seguido de caracteres, onde X é um número',
  device_inventory_description_helper: 'Limite de caracteres da descrição alcançado',
  device_inventory_device_number_helper:
    'Por favor, digite 8 caracteres que contenham só letras (A-Z) e números (0-9).',
  device_inventory_modal_select_action: 'Selecionar ação para o dispositivo {{deviceNumber}}',

  //success
  device_inventory_create_device_success: 'Dispositivo criado com êxito',
  device_inventory_update_device_success: 'Dispositivo atualizado com êxito',
  device_inventory_delete_device_success: 'Dispositivo eliminado con êxito',
  device_inventory_unlink_device_success: 'Dispositivo desvinculado con êxito',

  //error
  device_inventory_create_device_error: 'Ocorreu um erro ao criar o dispositivo',
  device_inventory_delete_device_error: 'Ocorreu um erro ao eliminar o dispositivo',
  device_inventory_device_exists: 'O dispositovo já existe',
  device_inventory_empty_device_error: 'O numero do dispositivo não poede estar vazio',
  device_inventory_empty_hardware_error: 'A versão do hardware não pode estar vazio',
  device_inventory_empty_software_error: 'A versão do software não pode estar vazio',
  device_inventory_error: 'Não foi possível carregar o dispositivos',
  device_inventory_update_device_error: 'Ocorreu um erro ao atualizar o dispositivo',
  device_inventory_already_exists_error: 'O dispositivo com este número já existe',

  /*
   * |====================|
   * | Transmissions page |
   * |====================|
   */

  //page
  transmissions_page_title: 'Transmições',
  transmissions_page_refresh: 'Atualizar',
  transmissions_page_export_csv: 'Exportar a CSV',
  transmissions_page_error_message: 'Não foi possível carregar as transmissões',

  //table
  transmissions_table_device_number: 'Número do dispositivo',
  transmissions_table_device_name: 'Nome do dispositivo',
  transmissions_table_last_transmission: 'Última transmissão',
  transmissions_table_next_transmission: 'Próxima transmissão',
  transmissions_table_days: 'Dias',
  transmissions_table_transmission: 'Transmissão',
  transmissions_table_count: 'Quantidade',
  transmissions_table_customer: 'Cliente',
  transmissions_table_address: 'Endereço',
  transmissions_table_ok: 'OK',
  transmissions_table_devEUI: 'devEUI',
  transmissions_table_simcard_number: 'Número do cartão SIM',
  transmissions_table_simcard_provider: 'Provedor do cartão SIM',
  transmissions_table_apn: 'APN',

  /*
   * |===============|
   * | Device Config |
   * |===============|
   */

  // page
  device_config_period: 'Período',
  device_config_every: 'Por',

  // error
  device_config_error_record_period_postiive: 'O período de registro deve ser positivo.',
  device_config_error_transmission_period_positive: 'O período de transmissão deve ser positivo.',
  device_config_error_record_must_be_greater_than_transmission:
    'O período de registro não pode ser maior que o período de transmissão.',
  device_config_error_not_divisible:
    'O período de registro não é divisivel pelo período de transmissão.',
  device_config_error_max_threshold_reached:
    '{{curr}} registros por transmissão é maior do que o máximo {{max}}.',

  /*
   * |=======|
   * | Graph |
   * |=======|
   */
  graph_tabs_readings: 'Leituras',
  graph_tabs_prediction: 'Previsão',
  graph_tabs_average: 'Média',
  graph_tabs_previous: 'Anterior',
  graph_column_sensor: 'Sensor',
  graph_column_index: 'Índice',
  graph_column_device: 'Dispositivo',
  graph_column_device_name: 'Nome do dispositivo',
  graph_column_date: 'Data',
  graph_column_readings: 'Leituras',
  graph_column_previous_readings: 'Leituras anteriores',
  graph_column_delta: 'Delta',
  /*
   * |===============|
   * | Settings page |
   * |===============|
   */
  settings_page_header: 'Configurações',
  settings_submit_success_msg: 'Configurações salvas',
  settings_submit_error_msg: 'As configurações não puderam ser salvas',
  settings_input_invalid_format_error_msg: 'Formato de dados inválido',
  settings_certificate_not_found_error:
    'Um certificado não foi encontrado neste caminho de arquivo',
  settings_add_button: 'Adicionar',
  settings_submit_button: 'Enviar',
  settings_input_key_label: 'Chave',
  settings_input_type_label: 'Tipo',
  settings_input_value_label: 'Valor',
  settings_select_type_string: 'Corrente',
  settings_select_type_number: 'Número',
  settings_select_type_boolean: 'Booleano',
  settings_select_type_object: 'Objeto',
  settings_logo_label: 'Logo',
  settings_dashboard_layout_label: 'Desenho painel de controle',
  settings_certificate_label: 'Rota do certificado',
  settings_device_panel_title: 'Título do painel do dispositivo',
  settings_sensor_panel_title: 'Título do painel do sensor',
  settings_header_home_label: 'Etiqueta de início (Portal)',
  settings_header_dashboard_label: 'Etiqueta do painel de controle (Portal)',
  settings_footer_main_label: 'Etiqueta principal do rodapé da pagina (Portal)',
  settings_footer_link_label: 'Etiqueta do link do rodapé da página (Portal)',
  settings_footer_link_url: 'URL do link do rodapé da página (Portal)',
  settings_alarm_notification_display_period:
    'Período de exibição de notificação de alarme (Portal)',
  settings_alarm_notification_display_period:
    'Período de exibição de notificação de alarme (horas) (Portal)',

  /* |===========|
   * | New Graph |
   * |===========|
   */

  //page
  new_graph_add_device_group_modal_title: 'Configuração do sensor do grupo de dispositivos "Todos"',
  new_graph_add_device_group_modal_device_group: 'Grupo de dispositivos: ',
  new_graph_add_device_group_modal_sensors_to_add: 'Sensores para incluir: ',
  new_graph_add_device_group_modal_every_selector_mode_per_device: 'Por dispositivo',
  new_graph_add_device_group_modal_every_selector_mode_per_sensor: 'Por sensor',
  new_graph_add_device_group_modal_sensor_limit_message:
    'Você não pode exceder o limite gráfico de {{graphSensorsLimit}} sensores!',
  new_graph_add_device_group_modal_confirm_button: 'Confirmar',
  new_graph_add_device_group_modal_cancel_button: 'Cancelar',
  new_graph_select_sensor_heading: 'Selecionar sensor',
  new_graph_date_range_selection: 'Seleção de intervalo de datas',
  new_graph_selected_sensors_heading: 'Sensores selecionados',
  new_graph_selected_sensors_remove_all_tooltip: 'Apagar todos',
  new_graph_select_company_message: 'Por favor selecione uma empresa',
  new_graph_show_sensors_statistics_label: 'Mostrar estatísticas',

  //sensor selection
  new_graph_device_group_label: 'Grupo de dispositivos',
  new_graph_device_label: 'Dispositivo',
  new_graph_sensor_label: 'Sensor',

  //date range selection
  new_graph_average_days_label: 'Média de dias',
  new_graph_time_series_prediction_period_label: 'Período',
  new_graph_auto_refresh_label: 'Atualizar',
  new_graph_period_picker_heading: 'Leituras',
  new_graph_average_days_heading: 'Média',
  new_graph_time_series_prediction_period_heading: 'Previsão de série temporal',
  new_graph_none_option: 'Nenhum',
  new_graph_day_option_one: '{{count}} dia',
  new_graph_day_option_other: '{{count}} dias',
  new_graph_week_option_one: '{{count}} semana',
  new_graph_week_option_other: '{{count}} semanas',
  new_graph_month_option_one: '{{count}} mês',
  new_graph_month_option_other: '{{count}} meses',
  new_graph_year_option_one: '{{count}} ano',
  new_graph_year_option_other: '{{count}} anos',

  //tooltip
  new_graph_current_value_name: 'Atual',
  new_graph_average_value_name: 'Média',
  new_graph_prediction_value_name: 'Previsão',

  //error
  new_graph_fetch_device_groups_error: 'Falha ao carregar grupos de dispositivos para a empresa.',
  new_graph_fetch_devices_error: 'Não foi possível carregar dispositivos para empresas.',
  new_graph_fetch_device_config_error:
    'Falha ao carregar as configurações do dispositivo selecionado.',
  new_graph_fetch_device_ports_config_error:
    'Não foi possível carregar as portas do dispositivo selecionado.',
  new_graph_fetch_device_flow_error: 'Falha ao carregar a vazão do dispositivo {{deviceName}}.',
  new_graph_fetch_device_flow_avg_error:
    'Não foi possível carregar a vazão média do dispositivo {{deviceName}}.',
  new_graph_fetch_device_readings_error: 'Não foi possível carregar leituras para {{deviceName}}.',
  new_graph_fetch_device_readings_avg_error:
    'Não foi possível carregar leituras médias para {{deviceName}}.',
  new_graph_fetch_device_on_off_alarms_error:
    'Não foi possível carregar alarmes liga/desliga para {{deviceName}}.',
  new_graph_fetch_device_prediction_error:
    'Não foi possível carregar a previsão para {{deviceName}}.',
  new_graph_sensor_already_in_state_error: 'Este sensor específico já foi adicionado à lista.',

  /* |================|
   * | Billing Report |
   * |================|
   */

  billing_report_header: 'Relatório de faturamento',
  billing_report_btn_generate: 'Gerar',
  billing_report_device_number: 'Número do dispositivo',
  billing_report_first_reading_date: 'Data da primera leitura',
  billing_report_last_reading_date: 'Data da última leitura',
  billing_report_readings: 'Leituras',
  billing_report_battery: 'Bateria',
  billing_report_signal: 'Sinal',
  billing_report_total: 'Total',

  /* |================|
   * | Profile Page   |
   * |================|
   */
  profile_page_change_logo: 'Trocar logotipo',
  profile_page_logo_formats_message: 'Formatos permitidos: PNG, JPEG',
  profile_page_name: 'Nome',
  profile_page_email: 'E-mail',
  profile_page_language: 'Idioma',
  profile_page_password: 'Senha',
  profile_page_change_language: 'Trocar idioma',
  profile_page_update_license: 'Atualizar licença',
  profile_page_change_password: 'Trocar senha',
  profile_page_generate: 'Gerar',
  profile_page_header: 'Perfil',
  profile_page_user_info_label: 'Informação do usuario',
  profile_page_license_label: 'Licença ',
  profile_page_license_type: 'Tipo de licença',
  profile_page_valid_from: 'Válido de',
  profile_page_valid_to: 'Válido atá',
  profile_page_max_readings: 'Leituras máximas',
  profile_page_current_count: 'Conta actual',
  profile_page_first_reading: 'Primeira leitura',
  profile_page_last_reading: 'Última leitura',
  profile_page_license_status: 'Estado',
  profile_page_renew_license_button: 'Renovar licença',
  profile_page_deactivate_license_button: 'Desativar licença',
  profile_page_valid_license: 'Licença válida',
  profile_page_invalid_license: 'Licença inválida',
  profile_page_deactivate_license_success_message: 'Licença desativada.',
  profile_page_deactivate_license_error_message: 'Falha ao desativar licença.',
  profile_page_deactivate_modal_title: 'Desactivação da licencia',
  profile_page_confirm_button: 'Confirmar',
  profile_page_cancel_button: 'Cancelar',
  profile_page_deactivate_license_modal_description:
    'Tem certeza de que deseja desativar esta licença?',
  profile_page_activate_license_modal_description: 'Tem certeza de que deseja ativar esta licença?',
  profile_page_activate_modal_title: 'Ativação da Licença',
  profile_page_license_per_device: 'por dispositivo',
  profile_page_license_per_reading: 'por leitura',
  profile_page_license_per_sensor: 'por sensor',
  profile_page_license_per_poc: 'por prova de conceito (POC)',
  profile_page_license_per_remote_node: 'por nó remoto',
  profile_page_license_partial: 'parcial',
  profile_page_logo_file_format_error: 'Tipo de arquivo inválido. Por favor carregue uma imagem.',
  profile_page_logo_size_error:
    'O tamanho do arquivo excede 1 MB. Por favor carregue uma imagem menor.',
  profile_page_version_info: 'Versões atuais:',

  logo_error_message: 'Não foi possível carregar o logotipo da empresa',

  profile_page_country: 'País',
  profile_page_timezone: 'Fuso horário',
  profile_page_address: 'Direção',
  profile_page_city: 'Cidade',
  profile_page_municipality: 'Municipio',
  profile_page_area: 'Área',
  profile_page_zip_code: 'Código postal',
  profile_page_vat_number: 'Número de IVA',
  profile_page_edit: 'Editar conta',
  profile_page_update_account_success: 'Conta atualizada com sucesso',
  profile_page_license_info: 'Informações sobre licença',

  /* |===============|
   * | Utility Rates |
   * |===============|
   */

  util_rates: 'Tarifas de serviços públicos',

  // table
  util_rates_name: 'Nome',
  util_rates_price: 'Preço',
  util_rates_currency: 'Divisa',
  util_rates_unit: 'Unidade',
  util_rates_private: 'Privado',
  util_rate_company: 'Compania',
  util_rate_created: 'Criado',
  util_rates_updated: 'Atualizado',
  util_rates_actions: 'Ações',
  util_rates_tooltip_inspect: 'Inspecionar',

  // modals
  util_rates_add_modal_header: 'Adicionar tarifa de serviços públicos',
  util_rates_edit_modal_header: 'Editar {{name}}',
  util_rates_delete_modal_header: 'Apagar {{name}}',
  util_rates_delete_modal_body:
    'Isso removerá permanentemente a taxa de serviços públicos escolhida.',
  util_rates_confirm: 'Confirmar',
  util_rates_cancel: 'Cancelar',
  util_rates_loading: 'Carregando...',
  util_rates_error: 'Erro...',
  util_rates_error_name_exists: 'Este nome já existe',

  // errors
  util_rate_fetch_company_error: 'Não foi possível carregar empresas',
  util_rate_fetch_util_rates_error: 'Não foi possível cobrar tarifas de serviços públicos',

  // footer
  footer_powered_by: 'Suportado por',
  util_rates_name_required: 'Nome obrigatório',
  util_rates_price_required: 'Preço necessário',

  /*
   * |=======|
   * | Icons |
   * |=======|
   */
  icon_type: 'Tipo',
  icon_type_device: 'Dispositivo',
  icon_type_sensor: 'Sensor',

  icon_scope: 'Escopo',
  icon_scope_global: 'Global',
  icon_scope_company: 'Compania',
  icon_scope_device: 'Dispositivo',
  icon_scope_sensor: 'Sensor',

  icon_input_device: 'Dispositivo',
  icon_input_sensor: 'Sensor',
  icon_input_name: 'Nome',
  icon_input_company: 'Compania',

  icon_btn_add_icon: 'Adicionar icone',
  icon_btn_upload_file: 'Subir arquivo',
  icon_btn_cancel: 'Cancelar',
  icon_btn_save: 'Salvar',

  icon_table_name: 'Nome',
  icon_table_scope: 'Alcance',
  icon_table_icon: 'Ícone',
  icon_table_actions: 'Ações',

  // errors
  icon_error_update_icon: 'Não foi possível atualizar o Ícone',
  icon_error_unsupported_file_type:
    'Tipo de arquivo inválido. Somente JPEG, PNG e SVG são permitidos.',
  icon_error_error_occured: 'Ocorreu um erro',
  icon_error_enter_name: 'Por favor insira um nome',
  icon_error_enter_icon: 'Carregar um ícone',
  icon_error_select_company: 'Selecione uma empresa',
  icon_error_select_device: 'Selecione um dispositivo',
  icon_error_select_sensor: 'Selecione um sensor',

  /*
   * |=========================|
   * | Customer Billing Report |
   * |=========================|
   */
  customer_billing_report_account_id_table_header: 'ID da Conta',
  customer_billing_report_description_table_header: 'Descrição',
  customer_billing_report_customer_name_table_header: 'Nome do Cliente',
  customer_billing_report_street_table_header: 'Rua',
  customer_billing_report_quarter_table_header: 'Trimestre',
  customer_billing_report_last_reading_table_header: 'Última Leitura',
  customer_billing_report_last_reading_date_table_header: 'Data da Última Leitura',
  customer_billing_report_device_number_table_header: 'Número do Dispositivo',
  customer_billing_report_last_config_date_table_header: 'Data da última configuração',
  customer_billing_report_billing_group_table_header: 'Grupo de faturamento',
  customer_billing_report_meter_serial_table_header: 'Número de serie',
  customer_billing_report_diameter_table_header: 'Diâmetro',
  customer_billing_report_meter_brand_table_header: 'Marca',
  customer_billing_report_meter_model_table_header: 'Modelo',
  customer_billing_report_default_error: 'Ocorreu um erro ao gerar o relatório',
  customer_billing_report_contract_number_table_header: 'Número do contrato',
  customer_billing_report_device_name_table_header: 'Nome do dispositivo',
  customer_billing_report_sensor_name_table_header: 'Nome do sensor',

  //license
  license_license_warning: 'Aviso de licença',
  license_license_warning_per_reading_over_limit_singular: 'do tipo por leitura excede o limite',
  license_license_warning_expire_singular: 'preste a expirar',
  license_license_warning_per_reading_over_limit_plural: 'do tipo para leituras excedem o limite',
  license_license_warning_expire_plural: 'prestes a expirar',
  license_max_count_error: 'A contagem máxima não pode ser 0',

  license_header: 'Licença',
  license_table_company_name: 'Nombre da empresa',
  license_table_license_type: 'Tipo de licença',
  license_table_max_count: 'Conta máxima',
  license_table_valid_from: 'Válido de',
  license_table_valid_to: 'Válido até',
  license_table_first_reading_date: 'Data da primeira leitura',
  license_table_last_reading_date: 'Data da última leitura',
  license_table_active: 'Ativo',
  license_table_valid: 'Válido',
  license_table_yes: 'Sim',
  license_table_no: 'Não',
  license_table_actions: 'Ações',

  license_warning_access_hold: 'Acesso à conta em espera',
  license_warning_access_hold_description:
    'Seu acesso à plataforma foi temporariamente suspenso devido a um pagamento não resolvido. Incentivamos você a entrar em contato com nosso suporte ou entrar em contato com seu representante designado para ajudá-lo a resolver esse problema.',
  license_warning_no_valid_license: 'Nenhuma licença válida',
  license_warning_no_valid_license_description:
    'Não foi possível encontrar uma licença válida associada a esta conta. Entre em contato com seu representante designado ou entre em contato com nosso suporte para obter assistência.',
  license_warning_devices_count_limit_reached: 'Limite de dispositivos atingido',
  license_warning_devices_count_limit_reached_description:
    'Você atingiu o número máximo de dispositivos que pode vincular à conta de acordo com a licença. Se desejar adicionar mais, entre em contato com seu representante designado ou com nosso suporte para obter assistência.',
  license_info_renew_button: 'Renovar Licença',
  license_info_deactivate_button: 'Desactivar Licença',
  license_info_renew_modal_button: 'Renovar licença',
  license_info_used_readings: 'Leituras utilizadas',
  license_info_readings_left: 'Leituras restantes',
  license_renew_license_button: 'Renovar licença',
  license_deactivate_license_button: 'Desativar licença',
  license_select_company_warning_message: 'Selecione uma empresa para continuar',
  license_add_button: 'Adicionar Licença',
  license_add_success_message: 'Licencça criada com êxito',
  license_add_error_message: 'Ocorreu um erro durante o processo',
  license_available_licenses: 'Licenças disponíveis',
  license_max_devices: 'Máx Dispositivos',
  license_max_readings: 'Máx Informes',
  license_max_sensors: 'Máx Sensores',
  license_company: 'Empresa',
  license_deactivate_warning: 'Isso suspenderá o acesso dos usuários à plataforma',
  license_activate_warning: 'Isso restaurará o acesso dos usuários à plataforma',
  license_activate_license_button: 'Ativar',
  license_available_licenses: 'Licenças disponiveis',
  license_add_license: 'Adicionar licença',
  license_status_active: 'Ativo',
  license_status_inactive: 'Inativo',
  license_info_activate_button: 'Ativar licença',
  license_info_used_devices: 'Dispositivos usados',
  license_info_devices_left: 'Dispositivos restantes',
  license_error_page_access_restricted: 'Acesso restrito',
  license_valid_license_existing: 'Você já possui uma licença válida deste tipo!',
  license_conflicting_license_date: 'Existe uma licença válida no mesmo período',

  //Password
  change_password: 'Alterar a senha',
  current_password: 'Senha atual',
  new_password: 'Nova senha',
  confirm_new_password: 'Confirmar nova senha',
  customer_info_saved: 'Informações do cliente salvas',

  /*
   * |=========================|
   * |     Reset Password      |
   * |=========================|
   */
  reset_password_check_email_for_reset_link:
    'Verifique seu e-mail para obter o link de redefinição de senha',
  reset_password_default_error_message: 'Ocorreu um erro ao tentar redefinir sua senha',
  reset_password_change_password_label: 'Alterar a senha',
  reset_password_send_btn: 'Enviar',

  /*
   * |==============================|
   * |  Sensors and Network Config  |
   * |==============================|
   */
  sensors_and_network_config_network_config: 'Configuração de Rede do Dispositivo',
  sensors_and_network_config_device_details: 'Detalhes do Dispositivo',

  sensors_and_network_save_success: 'A configuração foi salva com sucesso.',
  sensors_and_network_save_error: 'Houve um problema ao salvar a configuração.',
  sensors_and_network_formula_validation_default_error: 'A validação da fórmula falhou.',
  sensors_and_network_config_required_field_error: 'Este campo é obrigatório',

  sensors_and_network_config_reset_btn: 'Redefinir',
};
export default i18n_ptBr;
