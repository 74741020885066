import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  QueryOptions,
  MutationOptions,
  UtilityRateDto,
  MutateUtilityRate,
  UtilityRateBareboneDto,
  MutationResult,
  QueryResult,
} from '@thingslog/repositories';

export interface UtilityRateQueryClient {
  getUtilityRates: (
    companyId: number | null,
    countryCode: string | null,
    stateCode: string | null
  ) => Promise<UtilityRateBareboneDto[]>;
  useUtilityRates: (
    companyId: number | null,
    countryCode: string | null,
    stateCode: string | null,
    options?: QueryOptions<UtilityRateBareboneDto[]>
  ) => QueryResult<UtilityRateBareboneDto[]>;
  getUtilityRate: (id: number) => Promise<UtilityRateDto>;
  useUtilityRate: (
    id: number,
    options?: QueryOptions<UtilityRateDto>
  ) => QueryResult<UtilityRateDto>;
  createUtilityRate: (body: MutateUtilityRate) => Promise<void>;
  useCreateUtilityRate: (
    options?: MutationOptions<void, MutateUtilityRate>
  ) => MutationResult<void, MutateUtilityRate>;
  updateUtilityRate: (id: number, body: MutateUtilityRate) => Promise<void>;
  useUpdateUtilityRate: (
    options?: MutationOptions<void, UpdateUtilityRateQueryProps>
  ) => MutationResult<void, UpdateUtilityRateQueryProps>;
  deleteUtilityRate: (id: number) => Promise<void>;
  useDeleteUtilityRate: (options?: MutationOptions<void, number>) => MutationResult<void, number>;
}

export function createUtilityRateQueryClient(axios: AxiosInstance): UtilityRateQueryClient {
  return new UtilityRateImp(axios);
}

class UtilityRateImp {
  public constructor(private axios: AxiosInstance) {}

  public getUtilityRates = async (
    companyId: number | null,
    countryCode: string | null,
    stateCode: string | null
  ): Promise<UtilityRateBareboneDto[]> => {
    return this.axios
      .get('/api/utility-rates', {
        params: {
          companyId: companyId || undefined,
          countryCode: countryCode || undefined,
          stateCode: stateCode || undefined,
        },
      })
      .then((response: AxiosResponse<UtilityRateBareboneDto[]>) => response.data);
  };

  public useUtilityRates = (
    companyId: number | null,
    countryCode: string | null,
    stateCode: string | null,
    options?: QueryOptions<UtilityRateBareboneDto[]>
  ): QueryResult<UtilityRateBareboneDto[]> => {
    return useQuery<UtilityRateBareboneDto[], AxiosError>(
      [QueryKeys.GetUtilityRates, companyId, countryCode, stateCode],
      () => this.getUtilityRates(companyId, countryCode, stateCode),
      options
    );
  };

  public getUtilityRate = async (id: number): Promise<UtilityRateDto> => {
    return this.axios
      .get(`/api/utility-rates/${id}`)
      .then((response: AxiosResponse<UtilityRateDto>) => response.data);
  };

  public useUtilityRate = (
    id: number,
    options?: QueryOptions<UtilityRateDto>
  ): QueryResult<UtilityRateDto> => {
    return useQuery<UtilityRateDto, AxiosError>(
      [QueryKeys.GetUtilityRate, id],
      () => this.getUtilityRate(id),
      options
    );
  };

  public createUtilityRate = async (body: MutateUtilityRate): Promise<void> => {
    return this.axios
      .post('/api/utility-rates', body)
      .then((response: AxiosResponse<void>) => response.data);
  };

  public useCreateUtilityRate = (
    options?: MutationOptions<void, MutateUtilityRate>
  ): MutationResult<void, MutateUtilityRate> => {
    return useMutation<void, AxiosError, MutateUtilityRate>(
      [QueryKeys.CreateUtilityRate],
      (body: MutateUtilityRate) => this.createUtilityRate(body),
      options
    );
  };

  public updateUtilityRate = async (id: number, body: MutateUtilityRate): Promise<void> => {
    return this.axios
      .put(`/api/utility-rates/${id}`, body)
      .then((response: AxiosResponse<void>) => response.data);
  };

  public useUpdateUtilityRate = (
    options?: MutationOptions<void, UpdateUtilityRateQueryProps>
  ): MutationResult<void, UpdateUtilityRateQueryProps> => {
    return useMutation<void, AxiosError, UpdateUtilityRateQueryProps>(
      [QueryKeys.UpdateUtilityRate],
      (props: UpdateUtilityRateQueryProps) => this.updateUtilityRate(props.id, props.body),
      options
    );
  };

  public deleteUtilityRate = async (id: number): Promise<void> => {
    return this.axios
      .delete(`/api/utility-rates/${id}`)
      .then((response: AxiosResponse<void>) => response.data);
  };

  public useDeleteUtilityRate = (
    options?: MutationOptions<void, number>
  ): MutationResult<void, number> => {
    return useMutation<void, AxiosError, number>(
      [QueryKeys.DeleteUtilityRate],
      (id: number) => this.deleteUtilityRate(id),
      options
    );
  };
}

interface UpdateUtilityRateQueryProps {
  id: number;
  body: MutateUtilityRate;
}
