import React, { FC, useMemo } from 'react';
import DeviceSensorGraphData from '../model/DeviceSensorGraphData';
import CircleIcon from '@mui/icons-material/Circle';
import _ from 'lodash';
import { SensorsUtil } from '../../../common/SensorsUtil';
import SensorsStatisticsValue from './SensorsStatisticsValue';
import { useTranslation } from 'react-i18next';

const SensorsStatistics: FC<SensorsStatisticsProps> = ({
  deviceSensorGraphData,
}: SensorsStatisticsProps) => {
  const { t } = useTranslation();

  const statistics: Statistics[] = useMemo(() => {
    const tempStatistics: Statistics[] = [];

    for (const graphDataEntry of deviceSensorGraphData) {
      const { device, port, graphLineColor, flowData } = graphDataEntry;
      const sensor = port.sensor;

      if (SensorsUtil.isOnOffSensor(sensor)) continue;

      const deviceNumber = device.number;
      const deviceName = device.name;
      const sensorName = sensor.name;
      const color = graphLineColor.flow;
      const units = SensorsUtil.getSensorUnits(sensor);
      const isSensorDigital = SensorsUtil.isGenericDigitalSensor(sensor);

      let min: number | null = null;
      let max: number | null = null;
      let avg: number | null = null;
      let total = 0;
      let count = 0;

      for (const dataPoint of flowData) {
        const value = dataPoint.value;

        if (min === null || min > value) {
          min = value;
        }
        if (max === null || max < value) {
          max = value;
        }

        total += value;
        count++;
      }

      if (count !== 0) avg = total / count;

      tempStatistics.push({
        label: `${deviceName || deviceNumber} - ${sensorName}`,
        color: color,
        units: units,
        min: min,
        max: max,
        avg: avg,
        total: isSensorDigital ? total : undefined,
      });
    }

    return tempStatistics;
  }, [deviceSensorGraphData]);

  return (
    <div className="flex flex-col gap-4 overflow-auto m-auto w-fit 2xl:w-11/12">
      {statistics.map((entry: Statistics) => {
        const { label, color, units, min, max, avg, total } = entry;

        return (
          <>
            {min !== null && max !== null && avg !== null && (
              <div className="flex flex-nowrap whitespace-nowrap">
                <CircleIcon style={{ color: color }} />
                <span className="font-bold mx-2">{label}</span>
                <div className="flex gap-6">
                  <span>{t('chart_statistics_min')}</span>
                  <SensorsStatisticsValue color={color} value={min} units={units} />
                  <span>{t('chart_statistics_max')}</span>
                  <SensorsStatisticsValue color={color} value={max} units={units} />
                  <span>{t('chart_statistics_avg')}</span>
                  <SensorsStatisticsValue color={color} value={avg} units={units} />
                  {total !== undefined && (
                    <>
                      <span>{t('chart_statistics_total')}</span>
                      <SensorsStatisticsValue color={color} value={total} units={units} />
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

interface Statistics {
  label: string;
  color: string;
  units: string;
  min: number | null;
  max: number | null;
  avg: number | null;
  total?: number;
}

interface SensorsStatisticsProps {
  deviceSensorGraphData: DeviceSensorGraphData[];
}

export default SensorsStatistics;
