import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  QueryOptions,
  MutationOptions,
  QueryResult,
  MutationResult,
} from '@thingslog/repositories';

export interface DevicesIconsQueryClient {
  getDevicesIcons: () => Promise<Record<string, string>>;
  useDevicesIcons: () => QueryResult<Record<string, string>>;

  updateDeviceIcon: (deviceNumber: string, params: DeviceIconParams) => Promise<void>;
  useUpdateDeviceIcon: (
    deviceNumber: string,
    options?: MutationOptions<void, DeviceIconParams>
  ) => MutationResult<void, DeviceIconParams>;
}

export function createDevicesIconsQueryClient(axios: AxiosInstance): DevicesIconsQueryClient {
  return new DevicesIconsImp(axios);
}

class DevicesIconsImp {
  public constructor(private axios: AxiosInstance) {}

  public getDevicesIcons = async (): Promise<Record<string, string>> => {
    return this.axios
      .get(`/api/devices/icons`)
      .then((response: AxiosResponse<Record<string, string>>) => {
        return response.data;
      });
  };

  public useDevicesIcons = (
    options?: QueryOptions<Record<string, string>>
  ): QueryResult<Record<string, string>> => {
    return useQuery<Record<string, string>, AxiosError>(
      [QueryKeys.GetDevicesIcons],
      () => this.getDevicesIcons(),
      options
    );
  };

  public updateDeviceIcon = async (
    deviceNumber: string,
    params: DeviceIconParams
  ): Promise<void> => {
    return await this.axios
      .put(`/api/devices/${deviceNumber}`, params)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useUpdateDeviceIcon = (
    deviceNumber: string,
    options?: MutationOptions<void, DeviceIconParams>
  ): MutationResult<void, DeviceIconParams> => {
    return useMutation<void, AxiosError, DeviceIconParams>(
      [QueryKeys.UpdateDeviceIcon, deviceNumber],
      (params: DeviceIconParams) => this.updateDeviceIcon(deviceNumber, params),
      options
    );
  };
}

interface DeviceIconParams {
  iconName: string;
}
