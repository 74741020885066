import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import {
  DeviceInitialConfig,
  MutationOptions,
  MutationResult,
  PortsConfig,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';

export function createInitialConfigQueryClient(axios: AxiosInstance): InitialConfigQueryClient {
  return new InitialConfigQueryClientImp(axios);
}

class InitialConfigQueryClientImp {
  public constructor(private axios: AxiosInstance) {}
  public useGetDevicesPortsConfigsBatch = (
    options?: MutationOptions<Record<string, PortsConfig>, string[]>
  ): MutationResult<Record<string, PortsConfig>, string[]> => {
    return useMutation<Record<string, PortsConfig>, AxiosError, string[]>(
      [QueryKeys.GetDevicesPortsConfigsBatch],
      (props: string[]) => this.getDevicesPortsConfigsBatch(props),
      options
    );
  };

  public useDeviceInitialConfig = (
    deviceNumber: string,
    options?: QueryOptions<DeviceInitialConfig>
  ): QueryResult<DeviceInitialConfig> => {
    return useQuery<DeviceInitialConfig, AxiosError>(
      [QueryKeys.DeviceInitialConfig, deviceNumber],
      () => this.getDeviceInitialConfig(deviceNumber),
      options
    );
  };

  public useDevicePortsConfigData = (
    deviceNumber: string,
    options?: QueryOptions<PortsConfig>
  ): QueryResult<PortsConfig> => {
    return useQuery<PortsConfig, AxiosError>(
      [QueryKeys.DevicePortsConfig, deviceNumber],
      () => this.getDevicePortsConfig(deviceNumber),
      options
    );
  };

  public useUpdateDeviceInitialConfig = (
    options?: MutationOptions<void, InitialConfigMutationParams>
  ): MutationResult<void, InitialConfigMutationParams> => {
    return useMutation<void, AxiosError, InitialConfigMutationParams>(
      [QueryKeys.UpdateDeviceInitialConfig],
      (params: InitialConfigMutationParams) =>
        this.updateDeviceInitialConfig(params.deviceNumber, params.body),
      options
    );
  };

  public getDeviceInitialConfig = async (deviceNumber: string): Promise<DeviceInitialConfig> => {
    return await this.axios
      .get(`/api/devices/${deviceNumber}/initial-config`)
      .then((response: AxiosResponse) => response.data);
  };

  public getDevicePortsConfig = async (deviceNumber: string): Promise<PortsConfig> => {
    return await this.axios
      .get(`/api/devices/${deviceNumber}/initial-config/ports`)
      .then((response: AxiosResponse) => response.data);
  };

  public getDevicesPortsConfigsBatch = async (
    deviceNumbers: string[]
  ): Promise<Record<string, PortsConfig>> => {
    return await this.axios
      .post(`/api/devices/batch/initial-config/ports`, deviceNumbers)
      .then((response: AxiosResponse) => response.data);
  };

  public updateDeviceInitialConfig = async (
    deviceNumber: string,
    body: DeviceInitialConfig
  ): Promise<void> => {
    return await this.axios
      .put(`/api/devices/${deviceNumber}/initial-config`, body)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };
}

export interface InitialConfigQueryClient {
  useDevicePortsConfigData: (
    deviceNumber: string,
    options?: QueryOptions<PortsConfig>
  ) => QueryResult<PortsConfig>;
  useDeviceInitialConfig: (
    deviceNumber: string,
    options?: QueryOptions<DeviceInitialConfig>
  ) => QueryResult<DeviceInitialConfig>;
  useGetDevicesPortsConfigsBatch: (
    options?: MutationOptions<Record<string, PortsConfig>, string[]>
  ) => MutationResult<Record<string, PortsConfig>, string[]>;
  useUpdateDeviceInitialConfig: (
    options?: MutationOptions<void, InitialConfigMutationParams>
  ) => MutationResult<void, InitialConfigMutationParams>;

  getDeviceInitialConfig: (deviceNumber: string) => Promise<DeviceInitialConfig>;
  getDevicePortsConfig: (deviceNumber: string) => Promise<PortsConfig>;
  getDevicesPortsConfigsBatch: (deviceNumbers: string[]) => Promise<Record<string, PortsConfig>>;
  updateDeviceInitialConfig: (deviceNumber: string, body: DeviceInitialConfig) => Promise<void>;
}

interface InitialConfigMutationParams {
  deviceNumber: string;
  body: DeviceInitialConfig;
}
