import JwtValidator from './JwtValidator';

const { yambolJWT, yambolUrl, bobovdolJWT, bobovdolUrl, pirdopUrl, pirdopJWT } =
  window['runConfig'];

export default class RedirectHelper {
  private jwtValidator: JwtValidator;
  private tokenString: string | null;

  public constructor(token?: string | null) {
    this.jwtValidator = new JwtValidator(token);
    this.tokenString = token ? token : localStorage.getItem('token');
  }

  // #region public methods
  public shouldRedirectToPortal = (): boolean => {
    if (
      yambolJWT &&
      yambolUrl &&
      window.location.hostname === yambolUrl &&
      localStorage.getItem('token') === yambolJWT
    ) {
      return true;
    }

    if (
      bobovdolJWT &&
      bobovdolUrl &&
      window.location.hostname === bobovdolUrl &&
      localStorage.getItem('token') === bobovdolJWT
    ) {
      return true;
    }

    if (
      pirdopJWT &&
      pirdopUrl &&
      window.location.hostname === pirdopUrl &&
      localStorage.getItem('token') === pirdopJWT
    ) {
      return true;
    }

    const isValidToken = this.jwtValidator.isValidToken;
    const decodedToken = this.jwtValidator.decodedToken;
    if (isValidToken && this.tokenString && decodedToken) {
      return decodedToken.isDashboardEnabled;
    }

    return false;
  };

  public redirectToPortal = (): void => {
    window.location.href = `${location.protocol}//${location.host}/portal`;
  };
  // #endregion
}
