import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import {
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { UserDto } from '@thingslog/repositories';

export interface UserQueryClient {
  getUsers: (companyId: number | null) => Promise<UserDto[]>;
  useUsers: (companyId: number | null, options?: QueryOptions<UserDto[]>) => QueryResult<UserDto[]>;
  getUser: (username: string) => Promise<UserDto>;
  useUser: (username: string, options?: QueryOptions<UserDto>) => QueryResult<UserDto>;
  updateUser: (username: string, body: UserDto) => Promise<void>;
  useUpdateUser: (options?: MutationOptions<void, UserDto>) => MutationResult<void, UserDto>;
  createUser: (user: UserDto) => Promise<void>;
  useCreateUser: (options?: MutationOptions<void, UserDto>) => MutationResult<void, UserDto>;
  deleteUser: (username: string) => Promise<void>;
  useDeleteUser: (options?: MutationOptions<void, string>) => MutationResult<void, string>;
}

export function createUserQueryClient(axios: AxiosInstance): UserQueryClient {
  return new UserQueryClientImp(axios);
}

class UserQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getUsers = async (companyId: number | null): Promise<UserDto[]> => {
    return await this.axios
      .get(`/api/users/`, { params: { companyId: companyId ? companyId : undefined } })
      .then((response: AxiosResponse) => response.data);
  };

  public useUsers = (
    companyId: number | null,
    options?: QueryOptions<UserDto[]>
  ): QueryResult<UserDto[]> => {
    return useQuery<UserDto[], AxiosError>(
      [QueryKeys.GetUsers, companyId],
      () => this.getUsers(companyId),
      options
    );
  };

  public getUser = async (username: string): Promise<UserDto> => {
    return await this.axios
      .get(`/api/users/${username}`)
      .then((response: AxiosResponse) => response.data);
  };

  public useUser = (username: string, options?: QueryOptions<UserDto>): QueryResult<UserDto> => {
    return useQuery<UserDto, AxiosError>(
      [QueryKeys.GetUser, username],
      () => this.getUser(username),
      options
    );
  };

  public updateUser = async (username: string, body: UserDto): Promise<void> => {
    return await this.axios
      .put(`/api/users/${username}`, body)
      .then((response: AxiosResponse) => response.data);
  };

  public useUpdateUser = (
    options?: MutationOptions<void, UserDto>
  ): MutationResult<void, UserDto> => {
    return useMutation<void, AxiosError, UserDto>(
      [QueryKeys.UpdateUser],
      (user: UserDto) => this.updateUser(user.username, user),
      options
    );
  };

  public createUser = async (user: UserDto): Promise<void> => {
    await this.axios.post('/api/users', user);
  };

  public useCreateUser = (
    options?: MutationOptions<void, UserDto>
  ): MutationResult<void, UserDto> => {
    return useMutation<void, AxiosError, UserDto>(
      [QueryKeys.CreateUser],
      (user: UserDto) => this.createUser(user),
      options
    );
  };

  public deleteUser = async (username: string): Promise<void> => {
    await this.axios.delete(`/api/users/${username}`);
  };

  public useDeleteUser = (
    options?: MutationOptions<void, string>
  ): MutationResult<void, string> => {
    return useMutation<void, AxiosError, string>(
      [QueryKeys.DeleteUser],
      (username: string) => this.deleteUser(username),
      options
    );
  };
}
