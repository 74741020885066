import { Button, Input, TextField } from '@mui/material';
import { DeviceIconDto, UpdateIconDto } from '@thingslog/repositories';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { VisuallyHiddenInput } from './VisuallyHiddenInput';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';

const EditIconModal: FC<EditIconModalProps> = ({
  icon,
  onCancel,
  onSubmit,
}: EditIconModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setName(icon.name);
    setFileBase64(icon.icon);
  }, []);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    if (!['image/jpeg', 'image/png', 'image/svg+xml'].includes(fileType)) {
      setError(t('icon_error_unsupported_file_type'));
      setFileBase64(null);
      return;
    }
    setError(null);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      setFileBase64(reader.result as string);
    };
  };

  return (
    <section className="w-72 flex flex-col gap-1.5">
      <TextField
        value={name}
        size="small"
        label={t('icon_input_name')}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
          setName(e.target.value);
        }}
      />
      <Button
        component="label"
        variant="contained"
        disableElevation
        fullWidth
        className="flex flex-nowrap"
        startIcon={<CloudUploadIcon />}
      >
        {t('icon_btn_upload_file')}
        <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
      </Button>
      <div className="flex justify-center">
        {fileBase64 && (
          <img src={fileBase64} className="w-64 h-auto" alt="uploaded image preview" />
        )}
      </div>
      {error && <span className="font-bold text-red-600">{error}</span>}
      <div className="flex gap-1.5">
        <Button
          className="flex-1"
          variant="outlined"
          color="error"
          onClick={(): void => {
            onCancel();
          }}
        >
          {t('icon_btn_cancel')}
        </Button>
        <Button
          className="flex-1"
          variant="contained"
          disableElevation
          disabled={!!error}
          onClick={(): void => {
            if (!fileBase64) return;
            onSubmit(icon.id, { name: name, icon: fileBase64 });
          }}
        >
          {t('icon_btn_save')}
        </Button>
      </div>
    </section>
  );
};

interface EditIconModalProps {
  icon: DeviceIconDto;
  onSubmit: (id: number, body: UpdateIconDto) => void;
  onCancel: () => void;
}

export default EditIconModal;
