import { DataGrid } from '@thingslog/ui-components';
import React, { FC, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import { GridRenderCellParams } from '@thingslog/ui-components';
import { format } from 'date-fns';
import { Tooltip } from '@mui/material';
import { SwaggerDefinitions } from '@thingslog/repositories/openapi-typescript';

const CustomerBillingReportTable: FC<CustomerBillingReportTableProps> = ({
  customerBillingReportRows,
}: CustomerBillingReportTableProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState<number>(10);

  const renderCellTooltip = (params: GridRenderCellParams): ReactNode => {
    return (
      <Tooltip key={params.id} title={params.value} arrow>
        <span> {params.value}</span>
      </Tooltip>
    );
  };

  return (
    <DataGrid
      rows={customerBillingReportRows}
      getRowId={(row: CustomerBillingReportTableRow): number => row.rowId}
      pageSize={size}
      rowsPerPageOptions={[10, 25, 50]}
      onPageSizeChange={(pageSize: number): void => {
        setSize(pageSize);
      }}
      autoHeight
      hideFooterSelectedRowCount
      selectAllEnabled
      checkboxSelection
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          csvOptions: { allColumns: true },
        },
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            street: false,
            quarter: false,
            billingGroup: false,
            lastConfigDate: false,
            contractNumber: false,
            description: false,
            sensorName: false,
          },
        },
      }}
      columns={[
        {
          field: 'accountId',
          headerName: t('customer_billing_report_account_id_table_header'),
          flex: 1,
          minWidth: 140,
          renderCell: renderCellTooltip,
        },
        {
          field: 'contractNumber',
          headerName: t('customer_billing_report_contract_number_table_header'),
          flex: 1,
          minWidth: 140,
          renderCell: renderCellTooltip,
        },
        {
          field: 'description',
          headerName: t('customer_billing_report_description_table_header'),
          flex: 1.2,
          minWidth: 170,
          renderCell: renderCellTooltip,
        },
        {
          field: 'customerName',
          headerName: t('customer_billing_report_customer_name_table_header'),
          flex: 1.2,
          minWidth: 170,
          renderCell: renderCellTooltip,
        },
        {
          field: 'street',
          headerName: t('customer_billing_report_street_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
        {
          field: 'quarter',
          headerName: t('customer_billing_report_quarter_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
        {
          field: 'lastReading',
          headerName: t('customer_billing_report_last_reading_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
        {
          field: 'lastReadingDate',
          headerName: t('customer_billing_report_last_reading_date_table_header'),
          flex: 1,
          minWidth: 200,
          type: 'dateTime',
          valueGetter: (params: GridValueGetterParams): string => {
            return format(new Date(params.value), 'dd MMM yyyy HH:mm');
          },
          renderCell: renderCellTooltip,
        },
        {
          field: 'deviceNumber',
          headerName: t('customer_billing_report_device_number_table_header'),
          flex: 1,
          minWidth: 140,
          renderCell: renderCellTooltip,
        },
        {
          field: 'deviceName',
          headerName: t('customer_billing_report_device_name_table_header'),
          flex: 1,
          minWidth: 180,
          renderCell: renderCellTooltip,
        },
        {
          field: 'sensorName',
          headerName: t('customer_billing_report_sensor_name_table_header'),
          flex: 1,
          minWidth: 140,
          renderCell: renderCellTooltip,
        },
        {
          field: 'lastConfigDate',
          headerName: t('customer_billing_report_last_config_date_table_header'),
          flex: 1,
          minWidth: 180,
          type: 'dateTime',
          valueGetter: (params: GridValueGetterParams): string => {
            return format(new Date(params.value), 'dd MMM yyyy HH:mm');
          },
          renderCell: renderCellTooltip,
        },
        {
          field: 'billingGroup',
          headerName: t('customer_billing_report_billing_group_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
        {
          field: 'meterSerial',
          headerName: t('customer_billing_report_meter_serial_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
        {
          field: 'diameter',
          headerName: t('customer_billing_report_diameter_table_header'),
          flex: 1,
          minWidth: 120,
          renderCell: renderCellTooltip,
        },
        {
          field: 'meterBrand',
          headerName: t('customer_billing_report_meter_brand_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
        {
          field: 'meterModel',
          headerName: t('customer_billing_report_meter_model_table_header'),
          flex: 1,
          minWidth: 150,
          renderCell: renderCellTooltip,
        },
      ]}
    />
  );
};

interface CustomerBillingReportTableProps {
  customerBillingReportRows: CustomerBillingReportTableRow[];
}

type CustomerBillingReportTableRow = SwaggerDefinitions['CustomerBillingReportDto'] & {
  rowId: number;
};

export default CustomerBillingReportTable;
