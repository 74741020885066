import EventNotificationType from '@thingslog/repositories/src/valueRanges/EventNotificationType';
import EventValueRangeFunction from '@thingslog/repositories/src/valueRanges/EventValueRangeFunction';
import { TFunction } from 'i18next';

export const translateEventNotification = (
  notification: EventNotificationType,
  t: TFunction
): string => {
  switch (notification) {
    case EventNotificationType.EMAIL:
      return t('value_range_form_notification_email');
    case EventNotificationType.SMS:
      return t('value_range_form_notification_sms');
    case EventNotificationType.PUSH_NOTIFICATION:
      return t('value_range_form_notification_push_notification');
    case EventNotificationType.NO_NOTIFICATION:
      return t('value_range_form_no_notifications');
    default:
      return notification;
  }
};

export const translateEventFunction = (func: EventValueRangeFunction, t: TFunction): string => {
  switch (func) {
    case EventValueRangeFunction.LAST:
      return t('function_last');
    case EventValueRangeFunction.FIRST:
      return t('function_first');
    case EventValueRangeFunction.AVG:
      return t('function_avg');
    case EventValueRangeFunction.MIN:
      return t('function_min');
    case EventValueRangeFunction.MAX:
      return t('function_max');
    case EventValueRangeFunction.MEDIAN:
      return t('function_median');
    case EventValueRangeFunction.COUNT:
      return t('function_count');
    case EventValueRangeFunction.LAST_FLOW:
      return t('function_last_flow');
    case EventValueRangeFunction.FIRST_FLOW:
      return t('function_first_flow');
    case EventValueRangeFunction.AVG_FLOW:
      return t('function_avg_flow');
    case EventValueRangeFunction.MAX_FLOW:
      return t('function_max_flow');
    case EventValueRangeFunction.MIN_FLOW:
      return t('function_min_flow');
    case EventValueRangeFunction.MEDIAN_FLOW:
      return t('function_median_flow');
    default:
      return func;
  }
};
