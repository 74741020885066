import React, { FC } from 'react';
import Command from '../../../model/API/Command/Command';
import CommandFormatUtil from '../utils/CommandFormatUtil';
import { useTranslation } from 'react-i18next';

const CommandParametersPreview: FC<CommandParametersPreviewProps> = ({
  command,
}: CommandParametersPreviewProps) => {
  const { t } = useTranslation();
  if (!command) {
    return <></>;
  }

  return (
    <>
      {CommandFormatUtil.convertCmdParamsToArray(command.commandParameters).map(
        ([cmdParam, value]: [string, string]) => (
          <div key={cmdParam}>
            <p className="text-left">
              {t(`commands_${cmdParam}`)}: {value}
            </p>
          </div>
        )
      )}
    </>
  );
};

interface CommandParametersPreviewProps {
  command: Command;
}
export default CommandParametersPreview;
