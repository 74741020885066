import React, { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react';
import Header from '../../components/header';
import { Button, TextField } from '@mui/material';
import { ReCaptchaV3 } from './ReCaptchaV3';
import { global_env } from '../../App';
import ReCAPTCHA from 'react-google-recaptcha';
import { resetPasswordQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useToast } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const ResetPassword: FC<ResetPasswordProps> = () => {
  let recaptchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation();
  const { toast } = useToast();
  const { useResetPassword } = useMemo(() => resetPasswordQueryClient, []);

  const [email, setEmail] = useState<string | null>(null);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [refreshReCaptchaHandler, setRefreshReCaptchaHandler] = useState<
    (() => Promise<void>) | null
  >(null);

  const handleResetPasswordSubmit = (): void => {
    resetPassword({ email: email!.trim(), recaptchaToken: recaptcha });
  };

  const handleRefreshReCaptchaCallback = useCallback(
    (handleReCaptchaVerify: () => Promise<void>) => {
      setRefreshReCaptchaHandler(() => handleReCaptchaVerify);
    },
    []
  );

  const { mutate: resetPassword } = useResetPassword({
    onSuccess: () => {
      toast({ type: 'success', message: t('reset_password_check_email_for_reset_link') });
    },
    onError: (error: AxiosError) => {
      let errorMessage = t('reset_password_default_error_message');

      if (error.response && error.response.data) {
        errorMessage =
          error.response.status === 400 ? error.response.data.message : error.response.data;
      }

      toast({ type: 'error', message: errorMessage });
    },
    onSettled: () => {
      if (global_env.recaptchaV3) {
        refreshReCaptchaHandler && refreshReCaptchaHandler();
      } else {
        recaptchaRef.current?.reset();
      }
    },
  });

  return (
    <Header>
      <div className="w-[360px] max-sm:w-full m-auto flex-col items-center space-y-5 p-5 bg-neutral-100 border border-solid border-gray-300 rounded">
        <div className="text-xl">{t('reset_password_change_password_label')}</div>
        <div>
          <TextField
            size="small"
            label="Email"
            type="text"
            value={email}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              event.target.value === '' ? setEmail(null) : setEmail(event.target.value)
            }
          />
        </div>
        {global_env.recaptchaRequired &&
          (global_env.recaptchaV3 ? (
            <ReCaptchaV3
              className="mt-2 text-center"
              action="login"
              refreshReCaptchaCallback={handleRefreshReCaptchaCallback}
              onReCaptchaExecute={setRecaptcha}
            />
          ) : (
            <div className="flex justify-center items-center pt-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={global_env.recaptcha}
                onExpired={(): void => {
                  setRecaptcha(null);
                }}
                onChange={(token: string | null): void => {
                  setRecaptcha(token);
                }}
              />
            </div>
          ))}
        <Button
          variant="contained"
          disabled={email === null || recaptcha === null}
          onClick={handleResetPasswordSubmit}
        >
          {t('reset_password_send_btn')}
        </Button>
      </div>
    </Header>
  );
};

interface ResetPasswordProps {}

export default ResetPassword;
