import React, { FC, MouseEvent } from 'react';

import {
  IconButton,
  TextField,
  TextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const ClearableTextField: FC<ClearableTextFieldProps> = ({
  showClear,
  onClear,
  InputProps,
  ...props
}: ClearableTextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: showClear && (
          <IconButton
            size="small"
            className="-mr-3"
            onClick={(e: MouseEvent<HTMLButtonElement>): void => onClear(e)}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};

type ClearableTextFieldProps = TextFieldPropsWithoutEndAdornment & {
  showClear: boolean;
  onClear: (e: MouseEvent<HTMLButtonElement>) => void;
};

type TextFieldPropsWithoutEndAdornment =
  | TextFieldVariantWithoutEndAdornment<StandardTextFieldProps>
  | TextFieldVariantWithoutEndAdornment<FilledTextFieldProps>
  | TextFieldVariantWithoutEndAdornment<OutlinedTextFieldProps>;

type TextFieldVariantWithoutEndAdornment<V extends TextFieldProps> = Omit<V, 'InputProps'> & {
  InputProps?: Omit<NonNullable<V['InputProps']>, 'endAdornment'>;
};

export default ClearableTextField;
