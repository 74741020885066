import React, { ChangeEvent, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../reducers';
import { Chip, FormControlLabel, FormGroup, Checkbox, TextField, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import { UserDto, UserDtoRole } from '@thingslog/repositories';
import { Controller, UseControllerReturn, useForm } from 'react-hook-form';

const EditUserModal: FC<EditModalProps> = (props: EditModalProps) => {
  const { t } = useTranslation();

  const currentUserRoles = useSelector((state: ReduxState) => state.auth.roles);

  const { selectedUser, onCloseModal, onUpdateUser } = props;

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm<EditUserProps>({
    defaultValues: {
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      password: '',
      confirmPassword: '',
      roles: selectedUser.roles,
      dashboardEnabled: selectedUser.dashboardEnabled,
      isContactPerson: selectedUser.isContactPerson,
    },
  });

  const assignRole = (role: UserDtoRole): void => {
    let currentRoles = watch('roles');
    if (currentRoles.includes(role)) {
      currentRoles = currentRoles.filter((x: UserDtoRole) => x != role);
    } else {
      currentRoles.push(role);
    }
    setValue('roles', currentRoles);
  };

  const checkAdminRights = (adminRole: string): boolean => {
    let result = currentUserRoles!.some((element: JwtRole) => {
      if (element.authority === adminRole) return true;
      return false;
    });

    return result;
  };

  const submitForm = (props: EditUserProps): void => {
    const { confirmPassword: _, ...userProps } = props;
    const { username, language, email, companyName } = selectedUser;
    const user: UserDto = { ...userProps, username, language, email, companyName };

    onUpdateUser(user);
    onCloseModal();
  };

  return (
    <form className="flex flex-col gap-7 mt-6" onSubmit={handleSubmit(submitForm)}>
      <div className="flex gap-7">
        <TextField
          {...register('firstName')}
          label={t('users_table_first_name')}
          size="small"
          fullWidth
        />
        <TextField
          {...register('lastName')}
          label={t('users_table_last_name')}
          size="small"
          fullWidth
        />
      </div>
      <TextField
        {...register('password')}
        label={t('password')}
        type="password"
        size="small"
        fullWidth
      />
      <TextField
        {...register('confirmPassword', {
          validate: (value: string) => value === watch('password'),
        })}
        label={t('confirm_password')}
        type="password"
        size="small"
        fullWidth
      />
      <div className="flex gap-2 mx-2 justify-center">
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('USER') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('USER')}
          label={t('role_user')}
        />
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('PUBLIC') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('PUBLIC')}
          label={t('role_public')}
        />
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('ADMIN') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('ADMIN')}
          label={t('role_admin')}
        />
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('RESTRICTED') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('RESTRICTED')}
          label={t('role_restricted')}
        />
        {checkAdminRights('ROLE_SUPER_ADMIN') && (
          <Chip
            size="small"
            color="primary"
            variant="filled"
            icon={
              watch('roles').includes('SUPER_ADMIN') ? <CheckCircleOutlineIcon /> : <span></span>
            }
            onClick={(): void => assignRole('SUPER_ADMIN')}
            label={t('role_super_admin')}
          />
        )}
      </div>
      <div className="flex justify-evenly">
        <FormGroup>
          <FormControlLabel
            control={
              <Controller
                name="dashboardEnabled"
                control={control}
                render={({
                  field,
                }: UseControllerReturn<EditUserProps, 'dashboardEnabled'>): ReactElement => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      field.onChange(e.target.checked)
                    }
                  />
                )}
              />
            }
            label={t('users_is_dashboard_enabled')}
          />
        </FormGroup>
        {checkAdminRights('ROLE_SUPER_ADMIN') && (
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name="isContactPerson"
                  control={control}
                  render={({
                    field,
                  }: UseControllerReturn<EditUserProps, 'isContactPerson'>): ReactElement => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        field.onChange(e.target.checked)
                      }
                    />
                  )}
                />
              }
              label={t('users_is_contact_person')}
            />
          </FormGroup>
        )}
      </div>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        disabled={isSubmitting || !isValid}
        disableElevation
      >
        {t('capital_update_btn')}
      </Button>
    </form>
  );
};

interface EditModalProps {
  onCloseModal: () => void;
  selectedUser: UserDto;
  onUpdateUser: (user: UserDto) => void;
}

interface EditUserProps {
  firstName: string | null;
  lastName: string | null;
  password: string;
  confirmPassword: string;
  roles: UserDtoRole[];
  dashboardEnabled: boolean;
  isContactPerson: boolean;
}

export default EditUserModal;
