import Axios from './clients/Axios/Axios';

function getDevice(deviceNumber, cb, ecb) {
  return Axios.get(`/api/v2/devices/${deviceNumber}`)
    .then((resp) => resp.data)
    .then(cb)
    .catch(ecb);
}

function getDevices(params, cb, ecb) {
  return Axios.get(`/api/v2/devices?page=0&size=9999`, {
    params: params,
  })
    .then((resp) => resp.data)
    .then(cb)
    .catch(ecb);
}

const DevicesClient = {
  getDevice,
  getDevices,
};
export default DevicesClient;
