enum DeviceFilterAttributes {
  deviceNumber = 'deviceNumber',
  deviceName = 'deviceName',
  street = 'street',
  sw_version = 'sw_version',
  hw_version = 'hw_version',
  model = 'model',
  businessPartnerNumber = 'businessPartnerNumber',
}

export default DeviceFilterAttributes;
