import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  QueryOptions,
  DeviceGroupDto,
  MutationOptions,
  MutationResult,
  MutateDeviceSensor,
} from '@thingslog/repositories';
import { MutateDeviceGroup } from '@thingslog/repositories';

export interface DeviceGroupsQueryClient {
  getDeviceGroups: (companyId: number | null) => Promise<DeviceGroupDto[]>;
  getDeviceGroupsFromParent: (
    companyId: number,
    deviceGroupId: number
  ) => Promise<DeviceGroupDto[]>;
  createDeviceGroup: (body: MutateDeviceGroup) => Promise<void>;
  updateDeviceGroup: (deviceGroupId: number, body: MutateDeviceGroup) => Promise<void>;
  deleteDeviceGroup: (deviceGroupId: number, companyId: number | null) => Promise<void>;
  addDeviceSensorToGroup: (
    companyId: number | null,
    deviceGroupId: number,
    deviceSensorDto: MutateDeviceSensor
  ) => Promise<void>;
  deleteDeviceAndSensorFromGroup: (
    companyId: number | null,
    deviceGroupId: number,
    deviceNumber: string,
    sensorIndex: number
  ) => Promise<void>;

  useDeviceGroupsData: (
    companyId: number | null,
    options?: QueryOptions<DeviceGroupDto[]>
  ) => UseQueryResult<DeviceGroupDto[], AxiosError>;
  useDeviceGroupsFromParent: (
    companyId: number,
    deviceGroupId: number,
    options?: QueryOptions<DeviceGroupDto[]>
  ) => UseQueryResult<DeviceGroupDto[], AxiosError>;
  useCreateDeviceGroup: (
    options?: MutationOptions<void, MutateDeviceGroup>
  ) => MutationResult<void, MutateDeviceGroup>;
  useUpdateDeviceGroup: (
    options?: MutationOptions<void, UpdateDeviceGroupQueryProps>
  ) => MutationResult<void, UpdateDeviceGroupQueryProps>;
  useDeleteDeviceGroup: (
    companyId: number | null,
    options?: MutationOptions<void, number>
  ) => MutationResult<void, number>;
  useAddDeviceSensorToGroup: (
    companyId: number | null,
    options?: MutationOptions<void, AddDeviceSensorToGroupProps>
  ) => MutationResult<void, AddDeviceSensorToGroupProps>;
  useDeleteDeviceAndSensorFromGroup: (
    companyId: number | null,
    options?: MutationOptions<void, DeleteDeviceAndSensorFromGroupProps>
  ) => MutationResult<void, DeleteDeviceAndSensorFromGroupProps>;
}

export function createDeviceGroupsQueryClient(axios: AxiosInstance): DeviceGroupsQueryClient {
  return new DeviceGroupImp(axios);
}

class DeviceGroupImp {
  public constructor(private axios: AxiosInstance) {}

  public getDeviceGroups = async (companyId: number | null): Promise<DeviceGroupDto[]> => {
    return this.axios
      .get(`/api/v2/device-groups`, {
        params: {
          companyId,
        },
      })
      .then((response: AxiosResponse<DeviceGroupDto[]>) => {
        return response.data;
      });
  };

  public getDeviceGroupsFromParent = async (
    companyId: number,
    deviceGroupId: number
  ): Promise<DeviceGroupDto[]> => {
    return this.axios
      .get(`/api/v2/device-groups/device-group-name/${deviceGroupId}/all`, {
        params: {
          companyId,
        },
      })
      .then((response: AxiosResponse<DeviceGroupDto[]>) => {
        return response.data;
      });
  };

  public createDeviceGroup = async (body: MutateDeviceGroup): Promise<void> => {
    return await this.axios.post(`/api/v2/device-groups`, body).then((response: AxiosResponse) => {
      return response.data;
    });
  };

  public updateDeviceGroup = async (
    deviceGroupId: number,
    body: MutateDeviceGroup
  ): Promise<void> => {
    return await this.axios
      .put(`/api/v2/device-groups/${deviceGroupId}`, body)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public deleteDeviceGroup = async (
    deviceGroupId: number,
    companyId: number | null
  ): Promise<void> => {
    return await this.axios
      .delete(`/api/v2/device-groups/${deviceGroupId}`, {
        params: {
          companyId,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public addDeviceSensorToGroup = async (
    companyId: number | null,
    deviceGroupId: number,
    body: MutateDeviceSensor
  ): Promise<void> => {
    return await this.axios.post(`/api/v2/device-groups/${deviceGroupId}/device-sensors`, body, {
      params: {
        companyId,
      },
    });
  };

  public deleteDeviceAndSensorFromGroup = async (
    companyId: number | null,
    deviceGroupId: number,
    deviceNumber: string,
    sensorIndex: number
  ): Promise<void> => {
    return await this.axios
      .delete(
        `/api/v2/device-groups/${deviceGroupId}/device-sensors/${deviceNumber}/${sensorIndex}`,
        {
          params: { companyId },
        }
      )
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useDeviceGroupsData = (
    companyId: number | null,
    options?: QueryOptions<DeviceGroupDto[]>
  ): UseQueryResult<DeviceGroupDto[], AxiosError> => {
    return useQuery<DeviceGroupDto[], AxiosError>(
      [QueryKeys.GetDeviceGroupsByCompanyId, companyId],
      () => this.getDeviceGroups(companyId),
      options
    );
  };

  public useDeviceGroupsFromParent = (
    companyId: number,
    deviceGroupId: number,
    options?: QueryOptions<DeviceGroupDto[]>
  ): UseQueryResult<DeviceGroupDto[], AxiosError> => {
    return useQuery<DeviceGroupDto[], AxiosError>(
      [QueryKeys.GetDeviceGroupsFromParent, companyId, deviceGroupId],
      () => this.getDeviceGroupsFromParent(companyId, deviceGroupId),
      options
    );
  };

  public useCreateDeviceGroup = (
    options?: MutationOptions<void, MutateDeviceGroup>
  ): MutationResult<void, MutateDeviceGroup> => {
    return useMutation<void, AxiosError, MutateDeviceGroup>(
      [QueryKeys.CreateDeviceGroup],
      (body: MutateDeviceGroup) => {
        return this.createDeviceGroup(body);
      },
      options
    );
  };

  public useUpdateDeviceGroup = (
    options?: MutationOptions<void, UpdateDeviceGroupQueryProps>
  ): MutationResult<void, UpdateDeviceGroupQueryProps> => {
    return useMutation<void, AxiosError, UpdateDeviceGroupQueryProps>(
      [QueryKeys.UpdateDeviceGroup],
      ({ deviceGroupId, body }: UpdateDeviceGroupQueryProps) => {
        return this.updateDeviceGroup(deviceGroupId, body);
      },
      options
    );
  };

  public useDeleteDeviceGroup = (
    companyId: number | null,
    options?: MutationOptions<void, number>
  ): MutationResult<void, number> => {
    return useMutation<void, AxiosError, number>(
      [QueryKeys.DeleteDeviceGroup],
      (deviceGroupId: number) => {
        return this.deleteDeviceGroup(deviceGroupId, companyId);
      },
      options
    );
  };

  public useAddDeviceSensorToGroup = (
    companyId: number | null,
    options?: MutationOptions<void, AddDeviceSensorToGroupProps>
  ): MutationResult<void, AddDeviceSensorToGroupProps> => {
    return useMutation<void, AxiosError, AddDeviceSensorToGroupProps>(
      [QueryKeys.AddDeviceSensorToGroup],
      ({ deviceGroupId, body }: AddDeviceSensorToGroupProps) => {
        return this.addDeviceSensorToGroup(companyId, deviceGroupId, body);
      },
      options
    );
  };

  public useDeleteDeviceAndSensorFromGroup = (
    companyId: number | null,
    options?: MutationOptions<void, DeleteDeviceAndSensorFromGroupProps>
  ): MutationResult<void, DeleteDeviceAndSensorFromGroupProps> => {
    return useMutation<void, AxiosError, DeleteDeviceAndSensorFromGroupProps>(
      [QueryKeys.UpdateCompany],
      ({ deviceGroupId, deviceNumber, sensorIndex }: DeleteDeviceAndSensorFromGroupProps) => {
        return this.deleteDeviceAndSensorFromGroup(
          companyId,
          deviceGroupId,
          deviceNumber,
          sensorIndex
        );
      },
      options
    );
  };
}

interface AddDeviceSensorToGroupProps {
  deviceGroupId: number;
  body: MutateDeviceSensor;
}
interface UpdateDeviceGroupQueryProps {
  deviceGroupId: number;
  body: MutateDeviceGroup;
}

interface DeleteDeviceAndSensorFromGroupProps {
  deviceGroupId: number;
  deviceNumber: string;
  sensorIndex: number;
}
