import { AuthState } from '../reducers/auth_reducer';
export default class AuthUtil {
  public static getAuthHeader(): AuthHeader {
    return { authorization: localStorage.getItem('token') };
  }

  public static hasRole(
    auth: AuthState,
    role: 'USER' | 'ADMIN' | 'SUPER_ADMIN' | 'AGRICULTURE' | 'PUBLIC' | 'RESTRICTED'
  ): boolean {
    const authRoles = auth.roles;
    if (authRoles) {
      for (const authRole of authRoles) {
        if (authRole.authority === 'ROLE_' + role) {
          return true;
        }
      }
    }
    return false;
  }

  public static hasDashboard(auth: AuthState): boolean {
    const isDashboardEnabled = auth.isDashboardEnabled;
    return isDashboardEnabled ? isDashboardEnabled : false;
  }
}

interface AuthHeader {
  authorization: string | null;
}
