export enum TimeUnits {
  YEARS = 'YEARS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  SECONDS = 'SECONDS',
  MILLISECONDS = 'MILLISECONDS',
}
