import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
  useMemo,
  ReactNode,
  SyntheticEvent,
} from 'react';
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  AccountSolution,
  CompanyDto,
  CountriesListDto,
  Country,
  UpdateCompanyDto,
} from '@thingslog/repositories';
import JwtValidator from '../../common/JwtValidator';

const UpdateCompanyModal: FC<UpdateCompanyModalProps> = ({
  company,
  countriesListDto,
  timezones,
  updateCompany,
  setDisableAccountRefCallback,
}: UpdateCompanyModalProps) => {
  const { t } = useTranslation();
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const [isAccountRefInputDisabled, setIsAccountRefInputDisabled] = useState<boolean>(false);
  // Company fields
  const [companyName, setCompanyName] = useState<string>(company.name);
  const [accountRef, setAccountRef] = useState<string | null>(company.accountRef);
  const [contactRef, setContactRef] = useState<string | null>(company.contactRef);
  const [timezoneId, setTimezoneId] = useState<string>(company.zoneId);
  const [solutionType, setSolutionType] = useState<AccountSolution | 'None'>(
    company.solution ? company.solution : 'None'
  );
  // CompanyInfo fields
  const [country, setCountry] = useState<string>(company.companyInfo?.country || '');
  const [vatNumber, setVatNumber] = useState<string | null>(company.companyInfo?.vatNumber || null);
  const [address, setAddress] = useState<string | null>(company.companyInfo?.address || null);
  const [city, setCity] = useState<string | null>(company.companyInfo?.city || null);
  const [zipCode, setZipCode] = useState<string | null>(company.companyInfo?.zipCode || null);
  const [municipality, setMunicipality] = useState<string | null>(
    company.companyInfo?.municipality || null
  );
  const [area, setArea] = useState<string | null>(company.companyInfo?.area || null);

  const renderSolutionTypeMenuItemLabel = (solutionType: AccountSolution): string => {
    switch (solutionType) {
      case AccountSolution.AGRI:
        return t('company_manager_solution_option_agri');
      case AccountSolution.AIR_QUALITY:
        return t('company_manager_solution_option_air_quality');
      case AccountSolution.STREET_LIGHTS:
        return t('company_manager_solution_option_street_lights');
      case AccountSolution.PUMP_STATION:
        return t('company_manager_solution_option_pump_station');
      default:
        return 'None';
    }
  };

  const handleSubmit = (): void => {
    const solutionValue = solutionType !== 'None' ? solutionType : null;
    const companyForUpdate: UpdateCompanyDto = {
      id: company.id,
      name: companyName,
      parentId: company.parentId,
      solution: solutionValue,
      contactRef: contactRef,
      accountRef: accountRef,
      zoneId: timezoneId,
      companyInfo: {
        companyName: companyName,
        country: country,
        vatNumber: vatNumber,
        address: address,
        city: city,
        zipCode: zipCode,
        municipality: municipality,
        area: area,
        logo: company.companyInfo?.logo || null,
      },
    };
    updateCompany(companyForUpdate);
  };

  useEffect(() => {
    setDisableAccountRefCallback && setDisableAccountRefCallback(setIsAccountRefInputDisabled);
  }, []);

  return (
    <>
      <div>
        <div className="flex p-5 justify-center items-center space-y-5 flex-col">
          <div className="w-full font-bold">{t('company_manager_account')}</div>
          {jwtValidator.hasRole('ROLE_SUPER_ADMIN') && (
            <>
              <TextField
                fullWidth
                autoFocus
                value={companyName}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  setCompanyName(event.target.value)
                }
                label={t('company_manager_account_name')}
                name="group_name"
                size="small"
                variant="outlined"
                error={!companyName}
                required
              />
              <FormControl fullWidth>
                <InputLabel>{t('company_manager_solution_type')}</InputLabel>
                <Select
                  variant="outlined"
                  label={t('company_manager_solution_type')}
                  value={solutionType}
                  size="small"
                  onChange={(event: SelectChangeEvent): void =>
                    setSolutionType(event.target.value as AccountSolution)
                  }
                >
                  <MenuItem value={'None'}>{t('company_manager_solution_option_none')}</MenuItem>
                  {Object.entries(AccountSolution).map(([key, value]: [string, string]) => (
                    <MenuItem value={value} key={value}>
                      {renderSolutionTypeMenuItemLabel(value as AccountSolution)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                value={accountRef}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  setAccountRef(event.target.value)
                }
                label={t('accounts_accountRef')}
                size="small"
                variant="outlined"
                disabled={
                  !!contactRef ||
                  isAccountRefInputDisabled ||
                  !jwtValidator.hasRole('ROLE_SUPER_ADMIN')
                }
                inputProps={{ maxLength: 12 }}
              />
              {isAccountRefInputDisabled && (
                <div className="text-xs text-gray-500">
                  {t('company_manager_accountRef_is_disabled_because_of_license_type')}
                </div>
              )}
              <TextField
                fullWidth
                value={contactRef}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  setContactRef(event.target.value)
                }
                label={t('accounts_contactRef')}
                size="small"
                variant="outlined"
                disabled={!!accountRef || !jwtValidator.hasRole('ROLE_SUPER_ADMIN')}
                inputProps={{ maxLength: 8 }}
              />
            </>
          )}
          <Autocomplete
            defaultValue={timezoneId}
            className="w-full"
            options={Array.from(timezones || [])}
            value={timezoneId}
            onChange={(event: SyntheticEvent, newValue: string | null): void =>
              setTimezoneId(newValue as string)
            }
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                required
                {...params}
                error={!timezoneId}
                label={t('company_manager_timezone')}
                size="small"
              />
            )}
          />
          <div className="w-full font-bold">{t('company_manager_account_info')}</div>
          <Autocomplete
            defaultValue={country}
            className="w-full"
            options={
              countriesListDto
                ? countriesListDto.countries.map((country: Country) => country.name)
                : []
            }
            value={country}
            onChange={(event: SyntheticEvent, value: string | null): void => {
              setCountry(value || '');
            }}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                error={!country}
                required
                {...params}
                label={t('company_manager_country')}
                size="small"
              />
            )}
          />
          <TextField
            fullWidth
            value={address}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setAddress(event.target.value)
            }
            label={t('company_manager_address')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={city}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => setCity(event.target.value)}
            label={t('company_manager_city')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={municipality}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setMunicipality(event.target.value)
            }
            label={t('company_manager_municipality')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={area}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => setArea(event.target.value)}
            label={t('company_manager_area')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={zipCode}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setZipCode(event.target.value)
            }
            label={t('company_manager_zip_code')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={vatNumber}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setVatNumber(event.target.value)
            }
            label={t('company_manager_vat_number')}
            size="small"
            variant="outlined"
          />
          <Button
            variant="contained"
            color={'primary'}
            disabled={!companyName || !country}
            className="w-full"
            onClick={(): void => {
              handleSubmit();
            }}
          >
            {t('company_manager_update_btn')}
          </Button>
        </div>
      </div>
    </>
  );
};

interface UpdateCompanyModalProps {
  updateCompany: (body: UpdateCompanyDto) => void;
  company: CompanyDto;
  timezones: Set<string>;
  countriesListDto: CountriesListDto | null;
  setDisableAccountRefCallback?: (accountRefUpdater: (disabled: boolean) => void) => void;
}

export default UpdateCompanyModal;
