import React, { ChangeEvent, FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import { GPRSConfigModel, NbIoTConfigModel } from '@thingslog/repositories';
import ConnectForm from '../../../../../common/reactHookForm/ConnectForm';

const MqttConfig: FC<MqttConfigProps> = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <ConnectForm<GPRSConfigModel | NbIoTConfigModel>>
      {({
        watch,
        register,
        control,
        formState: { defaultValues },
      }: UseFormReturn<GPRSConfigModel | NbIoTConfigModel>): ReactElement => (
        <>
          <FormControlLabel
            className="col-span-full"
            control={
              <Controller
                control={control}
                name="mqttConfig"
                render={({
                  field,
                }: UseControllerReturn<
                  GPRSConfigModel | NbIoTConfigModel,
                  'mqttConfig'
                >): ReactElement => (
                  <Checkbox
                    {...field}
                    checked={field.value !== null}
                    onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
                      field.onChange(checked ? { ...defaultValues?.mqttConfig } : null);
                    }}
                  />
                )}
              />
            }
            label={t('device_mqtt_config')}
            labelPlacement="end"
          />
          {watch('mqttConfig') !== null && (
            <>
              <TextField
                className="row-span-2"
                {...register(`mqttConfig.certificate`)}
                multiline
                rows={4}
                size="small"
                label={t('device_mqtt_config_certificate')}
              />

              <TextField
                {...register(`mqttConfig.username`)}
                size="small"
                label={t('device_mqtt_config_username')}
              />

              <TextField
                {...register(`mqttConfig.password`)}
                size="small"
                label={t('device_mqtt_config_password')}
              />

              <TextField
                {...register(`mqttConfig.qos`)}
                size="small"
                label={t('device_mqtt_config_qos')}
              />

              <TextField {...register(`mqttConfig.rootTopic`)} size="small" label="rootTopic" />
            </>
          )}
        </>
      )}
    </ConnectForm>
  );
};

export default MqttConfig;

interface MqttConfigProps {}
