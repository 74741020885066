import React, { FC } from 'react';
import logo from '../../resources/logos/logo-dark.png';
import { useTranslation } from 'react-i18next';

export const Footer: FC<FooterProps> = (props: FooterProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center w-full mt-4 bg-white">
      <a href="https://thingslog.com" className="text-black p-2">
        <span>{t('footer_powered_by')} </span>
        <img src={logo} alt="image" className="h-6 cursor-pointer" />
      </a>
    </div>
  );
};

interface FooterProps {}
