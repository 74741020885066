import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';

export const getApiToken = async (expirationTimeHours: number, name: string): Promise<string> => {
  const encodedName = encodeURIComponent(name);
  return await Axios.post(
    `/api/api-token?expirationTimeHours=${expirationTimeHours}&name=${encodedName}`,
    null
  ).then((response: AxiosResponse) => {
    return response.data;
  });
};
