export default interface Command {
  id: number;
  commandType:
    | 'RELAY_SWITCH'
    | 'RELAY_SWITCH_WITH_DELAY'
    | 'SINGLE_RELAY_SWITCH'
    | 'RELAY_STATUSES'
    | 'SEND_CONFIG_OVER_MQTT'
    | 'SEND_DATE_OVER_MQTT'
    | string;
  commandState: CommandState;
  commandParameters: CommandParameters;
  creationDate: Date;
  executionDate?: Date;
  sentDate?: Date;
}

export interface AddCommand {
  commandType: string;
  commandParameters: CommandParameters;
}

export interface EditCommand {
  commandState: 'CANCELED';
}

export interface CommandParameters {
  [prop: string]: string;
}

export enum CommandState {
  PENDING = 'PENDING',
  SENT = 'SENT',
  CANCELED = 'CANCELED',
  STATUS_OK = 'STATUS_OK',
  STATUS_NOK = 'STATUS_NOK',
  UNKNOWN = 'UNKNOWN',
  TIMEOUT = 'TIMEOUT',
}
