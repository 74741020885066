import React, { FC, ReactElement } from 'react';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ParticulateMatterSize } from '@thingslog/repositories';
import { hash } from '@thingslog/ui-components';

const ParticulateMatterSensorConfig: FC<ParticulateMatterSensorConfig> = ({
  index,
}: ParticulateMatterSensorConfig) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <div>
          <Controller
            control={control}
            name={`ports.${index}.sensor.particleSize`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.particleSize`
            >): ReactElement => (
              <FormControl fullWidth size="small">
                <InputLabel>{t('sensor_config_particle_size')}</InputLabel>
                <Select
                  {...field}
                  label={t('sensor_config_particle_size')}
                  onChange={(event: SelectChangeEvent<ParticulateMatterSize | 'none'>): void =>
                    field.onChange(event.target.value === 'none' ? null : event.target.value)
                  }
                  value={field.value === null ? 'none' : field.value}
                >
                  {Object.entries(ParticulateMatterSize).map(([name, value]: [string, string]) => (
                    <MenuItem key={hash(name)} value={name}>
                      {value}
                    </MenuItem>
                  ))}
                  <MenuItem key={hash('none')} value="none">
                    {t('none')}
                  </MenuItem>
                </Select>
                <FormHelperText> </FormHelperText>
              </FormControl>
            )}
          />
        </div>
      )}
    </ConnectForm>
  );
};

interface ParticulateMatterSensorConfig {
  index: number;
}
export default ParticulateMatterSensorConfig;
