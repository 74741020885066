import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useToast } from '@thingslog/ui-components';
import { SvgIconComponent } from '@mui/icons-material';

const MenuLink: React.FC<MenuLinkProps> = (props: MenuLinkProps) => {
  const { t } = useTranslation();

  const selectedPageId = useSelector((state: ReduxState) => state.dev.selectedPageId);

  const getLinkPath = (): string => {
    if (props.isMainMenu || (!props.isMainMenu && props.selectedDevice)) {
      return props.path.replace('{deviceNumber}', props.selectedDevice ? props.selectedDevice : '');
    }

    return '';
  };

  return (
    <Link
      to={getLinkPath()}
      className={clsx(
        'flex items-center pl-2 text-gray-700 no-underline hover:bg-gray-100',
        selectedPageId === props.pageId && 'bg-cyan-100'
      )}
      onClick={(): void => props.onClick(props.gaId)}
    >
      <props.icon className="m-2 text-3xl" />
      <span className="w-full text-left truncate">{t(props.name)}</span>
    </Link>
  );
};

interface MenuLinkProps {
  name: string;
  path: string;
  icon: SvgIconComponent;
  gaId?: string;
  isMainMenu: boolean;
  selectedDevice: string | null;
  pageId: string;
  onClick: (gaId?: string) => void;
}

export default MenuLink;
