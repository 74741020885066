import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { GPRSConfigModel, NbIoTConfigModel } from '@thingslog/repositories';
import ConnectForm from '../../../../../common/reactHookForm/ConnectForm';

const BinaryDataConfig: FC<BinaryDataConfigProps> = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <ConnectForm<GPRSConfigModel | NbIoTConfigModel>>
      {({ control }: UseFormReturn<GPRSConfigModel | NbIoTConfigModel>): ReactElement => (
        <>
          <FormControl size="small">
            <InputLabel>{t('device_receive_data_binary')}</InputLabel>
            <Controller
              control={control}
              name="isReceiveDataBin"
              render={({
                field,
              }: UseControllerReturn<
                GPRSConfigModel | NbIoTConfigModel,
                'isReceiveDataBin'
              >): ReactElement => (
                <Select<boolean>
                  {...field}
                  onChange={(event: SelectChangeEvent): void => {
                    field.onChange(event.target.value === 'true' ? true : false);
                  }}
                  label={t('device_receive_data_binary')}
                >
                  <MenuItem value="true">{t('yes')}</MenuItem>
                  <MenuItem value="false">{t('no')}</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <FormControl size="small">
            <InputLabel>{t('device_send_data_binary')}</InputLabel>
            <Controller
              control={control}
              name="isSendDataBin"
              render={({
                field,
              }: UseControllerReturn<
                GPRSConfigModel | NbIoTConfigModel,
                'isSendDataBin'
              >): ReactElement => (
                <Select<boolean>
                  {...field}
                  onChange={(event: SelectChangeEvent): void => {
                    field.onChange(event.target.value === 'true' ? true : false);
                  }}
                  label={t('device_send_data_binary')}
                >
                  <MenuItem value="true">{t('yes')}</MenuItem>
                  <MenuItem value="false">{t('no')}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </>
      )}
    </ConnectForm>
  );
};

export default BinaryDataConfig;

interface BinaryDataConfigProps {}
