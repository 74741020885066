import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  RelayState,
  Device,
  DeviceCreateUpdateBody,
  DeviceFilterAttributes,
  MutationOptions,
  MutationResult,
  Pagination,
  QueryOptions,
  QueryResult,
} from '@thingslog/repositories';

export interface DevicesQueryClient {
  getDevices: (
    companyId: number | null,
    page: number,
    size: number,
    attributes: DeviceFilterAttributes | null,
    values: string | null
  ) => Promise<Pagination<Device>>;
  useDevicesData: (
    companyId: number | null,
    page: number,
    size: number,
    attributes: DeviceFilterAttributes | null,
    values: string | null,
    options?: QueryOptions<Pagination<Device>>
  ) => QueryResult<Pagination<Device>>;
  getDevice: (deviceNumber: string) => Promise<Device>;
  useDeviceData: (deviceNumber: string, options?: QueryOptions<Device>) => QueryResult<Device>;
  createDevice: (body: DeviceCreateUpdateBody) => Promise<void>;
  useCreateDevice: (
    options?: MutationOptions<void, DeviceCreateUpdateBody>
  ) => MutationResult<void, DeviceCreateUpdateBody>;
  updateDevice: (deviceNumber: string, body: DeviceCreateUpdateBody) => Promise<void>;
  useUpdateDevice: (
    options?: MutationOptions<void, DeviceMutationParams>
  ) => MutationResult<void, DeviceMutationParams>;
  deleteDevice: (deviceNumber: string) => Promise<void>;
  useDeleteDevice: (options?: MutationOptions<void, string>) => MutationResult<void, string>;
  getRelayStatus: (deviceNumber: string, sensorIndex: number) => Promise<RelayState>;
  useRelayStatus: (
    deviceNumber: string,
    sensorIndex: number,
    options?: QueryOptions<RelayState>
  ) => QueryResult<RelayState>;
  setRelayStatus: (
    deviceNumber: string,
    sensorIndex: number,
    relayState: RelayState
  ) => Promise<void>;
  useSetRelayStatus: (
    options?: MutationOptions<void, RelayMutationParams>
  ) => MutationResult<void, RelayMutationParams>;
}

export function createDevicesQueryClient(axios: AxiosInstance): DevicesQueryClient {
  return new DeviceImp(axios);
}

class DeviceImp {
  public constructor(private axios: AxiosInstance) {}

  public getDevices = async (
    companyId: number | null,
    page: number,
    size: number,
    attributes: DeviceFilterAttributes | null,
    values: string | null
  ): Promise<Pagination<Device>> => {
    return this.axios
      .get(`/api/v2/devices`, {
        params: {
          page: page,
          size: size,
          companyId: companyId ? companyId : undefined,
          attributes: attributes && values ? attributes : undefined,
          values: values && attributes ? values : undefined,
        },
      })
      .then((response: AxiosResponse<Pagination<Device>>) => {
        return response.data;
      });
  };

  public useDevicesData = (
    companyId: number | null,
    page: number,
    size: number,
    attributes: DeviceFilterAttributes | null,
    values: string | null,
    options?: QueryOptions<Pagination<Device>>
  ): QueryResult<Pagination<Device>> => {
    return useQuery<Pagination<Device>, AxiosError>(
      [QueryKeys.UseDeviceData, page, size, companyId, attributes, values],
      () => this.getDevices(companyId, page, size, attributes, values),
      options
    );
  };

  public getDevice = async (deviceNumber: string): Promise<Device> => {
    return await this.axios
      .get(`/api/v2/devices/${deviceNumber}`)
      .then((response: AxiosResponse<Device>) => {
        return response.data;
      });
  };

  public useDeviceData = (
    deviceNumber: string,
    options?: QueryOptions<Device>
  ): QueryResult<Device> => {
    return useQuery<Device, AxiosError>(
      [QueryKeys.UseDeviceData, deviceNumber],
      () => this.getDevice(deviceNumber),
      options
    );
  };

  public createDevice = async (body: DeviceCreateUpdateBody): Promise<void> => {
    return await this.axios.post(`/api/v2/devices`, body).then((response: AxiosResponse<void>) => {
      return response.data;
    });
  };

  public useCreateDevice = (
    options?: MutationOptions<void, DeviceCreateUpdateBody>
  ): MutationResult<void, DeviceCreateUpdateBody> => {
    return useMutation<void, AxiosError, DeviceCreateUpdateBody>(
      [QueryKeys.CreateDevice],
      (params: DeviceCreateUpdateBody) => this.createDevice(params),
      options
    );
  };

  public updateDevice = async (
    deviceNumber: string,
    body: DeviceCreateUpdateBody
  ): Promise<void> => {
    return await this.axios
      .put(`/api/v2/devices/${deviceNumber}`, body)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useUpdateDevice = (
    options?: MutationOptions<void, DeviceMutationParams>
  ): MutationResult<void, DeviceMutationParams> => {
    return useMutation<void, AxiosError, DeviceMutationParams>(
      [QueryKeys.UpdateDevice],
      (params: DeviceMutationParams) => this.updateDevice(params.deviceNumber, params.body),
      options
    );
  };

  public deleteDevice = async (deviceNumber: string): Promise<void> => {
    return await this.axios
      .delete(`/api/devices/${deviceNumber}`)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useDeleteDevice = (
    options?: MutationOptions<void, string>
  ): MutationResult<void, string> => {
    return useMutation<void, AxiosError, string>(
      [QueryKeys.DeleteDevice],
      (deviceNumber: string) => this.deleteDevice(deviceNumber),
      options
    );
  };

  public getRelayStatus = (deviceNumber: string, sensorIndex: number): Promise<RelayState> => {
    return this.axios
      .get(`/api/v2/devices/${deviceNumber}/${sensorIndex}/auto-relay-switch`)
      .then((response: AxiosResponse<RelayState>) => response.data);
  };

  public useRelayStatus = (
    deviceNumber: string,
    sensorIndex: number,
    options?: QueryOptions<RelayState>
  ): QueryResult<RelayState> => {
    return useQuery<RelayState, AxiosError>(
      [QueryKeys.UseRelayStatus, deviceNumber, sensorIndex],
      () => this.getRelayStatus(deviceNumber, sensorIndex),
      options
    );
  };

  public setRelayStatus = (
    deviceNumber: string,
    sensorIndex: number,
    relayState: RelayState
  ): Promise<void> => {
    return this.axios
      .post(`/api/v2/devices/${deviceNumber}/${sensorIndex}/auto-relay-switch`, undefined, {
        params: {
          relayState: relayState,
        },
      })
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useSetRelayStatus = (
    options?: MutationOptions<void, RelayMutationParams>
  ): MutationResult<void, RelayMutationParams> => {
    return useMutation<void, AxiosError, RelayMutationParams>(
      [QueryKeys.UseRelayStatus],
      (params: RelayMutationParams) =>
        this.setRelayStatus(params.deviceNumber, params.sensorIndex, params.relayState),
      options
    );
  };
}

interface RelayMutationParams {
  deviceNumber: string;
  sensorIndex: number;
  relayState: RelayState;
}

interface DeviceMutationParams {
  deviceNumber: string;
  body: DeviceCreateUpdateBody;
}
