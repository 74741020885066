import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../enums/QueryKeys';
import {
  MapSettings,
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
} from '@thingslog/repositories';

export interface MapSettingsQueryClient {
  getMap: (mapName: string) => Promise<MapSettings>;
  useMap: (mapName: string, options?: QueryOptions<MapSettings>) => QueryResult<MapSettings>;
  updateMapSettings: (mapName: string, body: MapSettings) => Promise<void>;
  useUpdateMapSettings: (
    options?: MutationOptions<void, MutateMapSettingsBody>
  ) => MutationResult<void, MutateMapSettingsBody>;
}

export function createMapSettingsQueryClient(axios: AxiosInstance): MapSettingsQueryClient {
  return new MapSettingImplementation(axios);
}

class MapSettingImplementation {
  public constructor(private axios: AxiosInstance) {}

  public getMap = async (mapName: string): Promise<MapSettings> => {
    return this.axios
      .get(`/api/v2/maps-settings/${mapName}`)
      .then((response: AxiosResponse<MapSettings>) => {
        return response.data;
      });
  };

  public updateMapSettings = async (
    mapName: string,
    body: Partial<MapSettings>,
    username?: string,
    companyName?: string
  ): Promise<void> => {
    return this.axios
      .patch(`/api/v2/maps-settings/${mapName}`, body, {
        params: { username: username, companyName: companyName },
      })
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useMap = (
    mapName: string,
    options?: QueryOptions<MapSettings>
  ): QueryResult<MapSettings> => {
    return useQuery<MapSettings, AxiosError>(
      [QueryKeys.GetMap, mapName],
      () => this.getMap(mapName),
      options
    );
  };

  public useUpdateMapSettings = (
    options?: MutationOptions<void, MutateMapSettingsBody>
  ): MutationResult<void, MutateMapSettingsBody> => {
    return useMutation<void, AxiosError, MutateMapSettingsBody>(
      [QueryKeys.UpdateMap],
      ({ mapName, settings, username, companyName }: MutateMapSettingsBody) =>
        this.updateMapSettings(mapName, settings, username, companyName),
      options
    );
  };
}

interface MutateMapSettingsBody {
  mapName: string;
  settings: Partial<MapSettings>;
  username?: string;
  companyName?: string;
}
