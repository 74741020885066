import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import { Action } from 'redux';

export enum AuthActionType {
  AUTH_USER = 'AUTH_USER',
  AUTH_ERROR = 'AUTH_ERROR',
}

export type AuthAction = Action<AuthActionType>;

export interface AuthUserAction extends AuthAction {
  type: AuthActionType.AUTH_USER;
  payload: {
    username: string;
    roles: JwtRole[];
    companyName: string;
    email: string;
    language: string;
    authenticated: boolean;
    signedup: boolean;
    error: string | null;
    isDashboardEnabled: boolean;
  };
}

export interface AuthErrorAction extends AuthAction {
  type: AuthActionType.AUTH_ERROR;
  payload: string;
}
