import { AnalogSensorType, DigitalSensorType, OnOffSensorType } from '@thingslog/repositories';

export const analogSensorTypeOptions: Record<AnalogSensorType, string> = {
  generic_analog_sensor: 'generic_analog_sensor',
  modbus_generic_analog_sensor: 'modbus_generic_analog_sensor',
  generic_virtual_sensor: 'generic_virtual_sensor',
  pressure_sensor: 'pressure_sensor',
  temperature_sensor: 'temperature_sensor',
  humidity_sensor: 'humidity_sensor',
  level_sensor: 'level_sensor',
  weight_meter: 'weight_meter',
  weight_sensor: 'weight_sensor',
  load_cells: 'load_cells',
  wind_direction_sensor: 'wind_direction_sensor',
  barometric_pressure_sensor: 'barometric_pressure_sensor',
  soil_moisture_sensor: 'soil_moisture_sensor',
  dew_point_sensor: 'dew_point_sensor',
  soil_temperature_sensor: 'soil_temperature_sensor',
  soil_potential_sensor: 'soil_potential_sensor',
  particulate_matter_sensor: 'particulate_matter_sensor',
  leaf_wetness_sensor: 'leaf_wetness_sensor',
  co_sensor: 'co_sensor',
  no2_sensor: 'no2_sensor',
  so2_sensor: 'so2_sensor',
};

export const digitalSensorTypeOptions: Record<DigitalSensorType, string> = {
  generic_digital_sensor: 'generic_digital_sensor',
  modbus_generic_digital_sensor: 'modbus_generic_digital_sensor',
  water_meter: 'water_meter',
  power_meter: 'power_meter',
  gas_flow_meter: 'gas_flow_meter',
  rain_sensor: 'rain_sensor',
  wind_speed_sensor: 'wind_speed_sensor',
  heat_meter: 'heat_meter',
  geiger_mueller_counter: 'geiger_mueller_counter',
};

export const onOffSensorTypeOptions: Record<OnOffSensorType, string> = {
  on_off_sensor: 'on_off_sensor',
  on_off_input_sensor: 'on_off_input_sensor',
  on_off_output_sensor: 'on_off_output_sensor',
  on_off_auto_output_sensor: 'on_off_auto_output_sensor',
  tasmota_relay_sensor: 'tasmota_relay_sensor',
};
