import React, { Component, Fragment } from 'react';
import 'react-select/dist/react-select.css';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Grid from '@mui/material/Grid/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { cyan } from '@mui/material/colors';
import { cn } from '@thingslog/ui-components';

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class DeviceDigits extends Component {
  constructor(props) {
    super(props);
    let cnt = '0';
    if (props.counter !== null && props.counter !== undefined) {
      cnt = this.props.counter;
    }
    let digitsArr = Array.from(cnt.padStart(props.digits, 0).toString()).map(Number);
    this.state = { digits: props.digits, fraction: props.fraction, digitsArr: digitsArr };
    this.handleDigitChange = this.handleDigitChange.bind(this);
    this.addDigit = this.addDigit.bind(this);
    this.removeDigit = this.removeDigit.bind(this);
    this.addFraction = this.addFraction.bind(this);
    this.removeFraction = this.removeFraction.bind(this);
    this.fireCounterChanged = this.fireCounterChanged.bind(this);
  }

  handleDigitChange(index, event) {
    let digitsArr = this.state.digitsArr;
    digitsArr[index] = Number(event.target.value);
    let newState = {
      digitsArr: digitsArr,
      fraction: this.state.fraction,
      digits: this.state.digits,
    };
    this.setState(newState);
    this.fireCounterChanged(newState);
  }

  fireCounterChanged(ev) {
    let digitsArr = ev.digitsArr;
    let pow10 = 1;
    let counter = 0;
    for (let i = digitsArr.length - 1; i >= 0; i--) {
      counter += pow10 * digitsArr[i];
      pow10 *= 10;
    }
    this.props.counterChanged({ digits: ev.digits, fraction: ev.fraction, counter: '' + counter });
  }

  addDigit() {
    if (this.state.digits < 9) {
      let digitsArr = this.state.digitsArr;
      digitsArr.unshift(0);
      let newState = {
        digits: this.state.digits + 1,
        digitsArr: digitsArr,
        fraction: this.state.fraction,
      };
      this.setState(newState);
      this.fireCounterChanged(newState);
    } else {
      alert(this.props.t('device_digits_alert_too_many_total_digits'));
    }
  }

  removeDigit() {
    if (this.state.digits > 4) {
      let digitsArr = this.state.digitsArr;
      digitsArr.shift();
      let newState = {
        digits: this.state.digits - 1,
        digitsArr: digitsArr,
        fraction: this.state.fraction,
      };
      this.setState(newState);
      this.fireCounterChanged(newState);
    } else {
      alert(this.props.t('device_digits_alert_too_small_total_digits'));
    }
  }

  addFraction() {
    if (this.state.fraction < 3 && this.state.digits < 9) {
      let digitsArr = this.state.digitsArr;
      digitsArr.push(0);
      let newState = {
        digits: this.state.digits + 1,
        fraction: this.state.fraction + 1,
        digitsArr: digitsArr,
      };
      this.setState(newState);
      this.fireCounterChanged(newState);
    } else {
      alert(this.props.t('device_digits_alert_too_many_total_fraction'));
    }
  }

  removeFraction() {
    if (this.state.fraction > 0 && this.state.digits > 4) {
      let digitsArr = this.state.digitsArr;
      digitsArr.pop();
      let newState = {
        digits: this.state.digits - 1,
        fraction: this.state.fraction - 1,
        digitsArr: digitsArr,
      };
      this.setState(newState);
      this.fireCounterChanged(newState);
    } else {
      alert(this.props.t('device_digits_alert_too_small_total_fraction'));
    }
  }

  render() {
    const { classes } = this.props;
    let self = this;
    return (
      <FormGroup>
        <ControlLabel>{this.props.t('device_digits_initial_reading')}</ControlLabel>
        <div className={cn(this.props.digitsClassName)}>
          <Grid container justifyContent={'center'}>
            {this.state.digitsArr.map((object, i) => (
              <Fragment key={'dig_' + i}>
                <Grid
                  item
                  style={{
                    border:
                      '1px solid ' +
                      (self.state.digits - i <= self.state.fraction ? 'red' : cyan[500]),
                  }}
                >
                  <FormControl
                    disabled={self.props.readOnly}
                    className={cn('p-0', self.props.readOnly && 'pointer-events-none')}
                    value={self.state.digitsArr[i]}
                    componentClass="select"
                    placeholder="select every"
                    onChange={(val) => self.handleDigitChange(i, val)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                  </FormControl>
                </Grid>
                {i === self.state.digits - self.state.fraction - 1 && (
                  <Grid item style={{ bottom: 0, position: 'relative', fontSize: 20 }}>
                    &nbsp;
                    <div style={{ position: 'absolute', bottom: '0px', fontWeight: 'bold' }}>,</div>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
          {self.props.readOnly !== true && (
            <Grid container justifyContent={'center'} alignItems={'center'} direction="row">
              <Grid item>
                <IconButton
                  aria-label="Add"
                  className={classes.margin}
                  style={{ padding: 0 }}
                  onClick={this.addDigit.bind(this)}
                  size="large"
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item style={{ padding: 0 }}>
                {this.props.t('device_digits_digits')}
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="Remove"
                  className={classes.margin}
                  style={{ padding: 0 }}
                  onClick={this.removeDigit.bind(this)}
                  size="large"
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="Add"
                  onClick={this.addFraction.bind(this)}
                  style={{ padding: 0 }}
                  size="large"
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item style={{ padding: 0 }}>
                {this.props.t('device_digits_fraction')}
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="Remove"
                  style={{ padding: 0 }}
                  onClick={this.removeFraction.bind(this)}
                  size="large"
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </div>
      </FormGroup>
    );
  }
}

export default withStyles(styles)(withTranslation()(DeviceDigits));
