import {
  DataGrid,
  DataGridProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@thingslog/ui-components';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Transmission } from '@thingslog/repositories';
import { t } from 'i18next';
import { DateUtil } from '../../common/DateUtil';

const TransmissionsTable: FC<TransmissionsTableProps> = ({
  transmissions,
  ...props
}: TransmissionsTableProps) => {
  const [rows, setRows] = useState<TransmissionsTableRow[]>([]);

  useEffect(() => {
    if (transmissions) {
      let rows: TransmissionsTableRow[] = [];
      transmissions.forEach((transmission: Transmission) => {
        rows.push({
          deviceNumber: transmission.deviceNumber,
          deviceName: transmission.deviceName,
          lastTransmission: transmission.lastTransmissionDate,
          nextTransmission: transmission.nextTransmissionDate,
          days: transmission.transmissionDaysNumber,
          transmission: transmission.lastTransmissionSeq,
          count: transmission.transmittedCountersNumber,
          customer: transmission.customerInfo ? transmission.customerInfo.name : '',
          address: transmission.customerInfo ? transmission.customerInfo.address : '',
          ok: transmission.isTransmitting ? 'Y' : 'N',
          devEUI: transmission.devEUI,
          simCardNumber: transmission.simCardNumber,
          simCardProvider: transmission.simCardProvider,
          apn: transmission.apn,
          id: transmission.deviceNumber,
        });
      });
      setRows(rows);
    }
  }, [transmissions]);

  return (
    <DataGrid
      showPrintOption={false}
      selectAllEnabled={true}
      rows={rows}
      columns={[
        {
          field: 'deviceNumber',
          headerName: t('transmissions_table_device_number'),
          headerAlign: 'left',
          flex: 0.4,
          minWidth: 140,
        },
        {
          field: 'deviceName',
          headerName: t('transmissions_table_device_name'),
          headerAlign: 'left',
          flex: 0.4,
          minWidth: 140,
        },
        {
          field: 'lastTransmission',
          headerName: t('transmissions_table_last_transmission'),
          headerAlign: 'left',
          flex: 0.7,
          type: 'date',
          minWidth: 200,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            DateUtil.formatTimeZoneAgnosticDate(params.value),
          valueGetter: (params: GridValueFormatterParams<string>) => params.value,
          sortComparator: (TransmissionDate1: string, TransmissionDate2: string) =>
            new Date(TransmissionDate1).getTime() - new Date(TransmissionDate2).getTime(),
        },
        {
          field: 'nextTransmission',
          headerName: t('transmissions_table_next_transmission'),
          headerAlign: 'left',
          flex: 0.7,
          type: 'date',
          minWidth: 200,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            DateUtil.formatTimeZoneAgnosticDate(params.value),
          valueGetter: (params: GridValueFormatterParams<string>) => params.value,
          sortComparator: (TransmissionDate1: string, TransmissionDate2: string) =>
            new Date(TransmissionDate1).getTime() - new Date(TransmissionDate2).getTime(),
        },
        {
          field: 'days',
          headerName: t('transmissions_table_days'),
          headerAlign: 'left',
          type: 'number',
          minWidth: 100,
          flex: 0.4,
        },
        {
          field: 'transmission',
          headerName: t('transmissions_table_transmission'),
          headerAlign: 'left',
          type: 'number',
          minWidth: 150,
          flex: 0.5,
        },
        {
          field: 'count',
          headerName: t('transmissions_table_count'),
          headerAlign: 'left',
          type: 'number',
          minWidth: 100,
          flex: 0.4,
        },
        {
          field: 'customer',
          headerName: t('transmissions_table_customer'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 150,
          flex: 0.7,
        },
        {
          field: 'address',
          headerName: t('transmissions_table_address'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'ok',
          headerName: t('transmissions_table_ok'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 100,
          flex: 0.3,
          renderCell(params: GridRenderCellParams<string>): ReactNode {
            return params.value === 'Y' ? (
              <span className="flex items-center justify-center w-8 h-8 rounded-full bg-green-600 text-white">
                Y
              </span>
            ) : (
              <span className=" flex items-center justify-center w-8 h-8 rounded-full bg-red-500  text-white ">
                N
              </span>
            );
          },
        },
        {
          field: 'devEUI',
          headerName: t('transmissions_table_devEUI'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 150,
          flex: 1,
        },
        {
          field: 'simCardNumber',
          headerName: t('transmissions_table_simcard_number'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'simCardProvider',
          headerName: t('transmissions_table_simcard_provider'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'apn',
          headerName: t('transmissions_table_apn'),
          headerAlign: 'left',
          type: 'string',
          minWidth: 150,
          flex: 1,
        },
      ]}
      {...props}
    />
  );
};

export default TransmissionsTable;

interface TransmissionsTableRow {
  deviceNumber: string;
  deviceName: string;
  lastTransmission: string;
  nextTransmission: string;
  days: number;
  transmission: number;
  count: number;
  customer?: string | null;
  address?: string | null;
  ok: string;
  devEUI: string | null;
  simCardNumber: string | null;
  simCardProvider: string | null;
  apn: string | null;
  id: string;
}

interface TransmissionsTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  transmissions: Transmission[];
}
