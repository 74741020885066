import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, DataGridProps, GridValueFormatterParams } from '@thingslog/ui-components';
import { DeviceBattery } from '@thingslog/repositories/src/battery/DeviceBattery';
import { DateUtil } from '../../common/DateUtil';

const DeviceBatteryTable: FC<DeviceBatteryTableProps> = ({
  deviceBatteryReadings,
  ...props
}: DeviceBatteryTableProps) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState<DeviceBatteryRow[]>([]);

  useEffect(() => {
    let rows: DeviceBatteryRow[] = [];
    deviceBatteryReadings.forEach((deviceBattery: DeviceBattery, index: number) => {
      const { date, battery, level } = deviceBattery;

      rows.push({
        id: index,
        date: date,
        battery: battery,
        level: level,
      });
    });

    setRows(rows);
  }, [deviceBatteryReadings]);

  return (
    <DataGrid
      showPrintOption={false}
      selectAllEnabled={true}
      rows={rows}
      columns={[
        {
          field: 'date',
          headerName: t('date'),
          flex: 1,
          minWidth: 140,
          type: 'date',
          filterable: false,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            DateUtil.formatTimeZoneAgnosticDate(params.value),
          sortComparator: (BatteryDate1: string, BatteryDate2: string) =>
            new Date(BatteryDate1).getTime() - new Date(BatteryDate2).getTime(),
        },
        {
          field: 'battery',
          headerName: t('devices_battery_voltage'),
          flex: 0.5,
          minWidth: 110,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
          valueFormatter: (params: GridValueFormatterParams): string => {
            // Format the number without a thousands separator
            return params.value.toString().replace(/,/g, '');
          },
        },
        {
          field: 'level',
          headerName: t('devices_battery_level'),
          flex: 0.5,
          minWidth: 110,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

interface DeviceBatteryTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  deviceBatteryReadings: DeviceBattery[];
}

interface DeviceBatteryRow {
  id: number;
  date: Date;
  battery: number;
  level: number;
}

export default DeviceBatteryTable;
