import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MapSettingsModalButtons: FC<MapSettingsModalButtonsProps> = ({
  disabled = false,
  onConfirm,
  onCancel,
}: MapSettingsModalButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-10">
      <Button
        className="flex-1 rounded-full"
        disabled={disabled}
        variant="outlined"
        color="success"
        onClick={onConfirm}
      >
        {t('map_coordinates_modal_confirm_btn_label')}
      </Button>
      <Button className="flex-1 rounded-full" variant="outlined" color="error" onClick={onCancel}>
        {t('map_coordinates_modal_cancel_btn_label')}
      </Button>
    </div>
  );
};

interface MapSettingsModalButtonsProps {
  disabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default MapSettingsModalButtons;
