import { LineColors, LineChartColors } from './model/LineChartColors';

export const lineColors: Record<LineColors, LineChartColors> = {
  [LineColors.RED]: {
    flow: '#E53935',
    average: '#F6685E',
    prediction: '#EA8F8F',
  },
  [LineColors.ORANGE]: {
    flow: '#FF8C00',
    average: '#FFA500',
    prediction: '#FFC570',
  },
  [LineColors.YELLOW]: {
    flow: '#F2C94C',
    average: '#FCEDAB',
    prediction: '#FFF5CC',
  },
  [LineColors.BLUE]: {
    flow: '#2196F3',
    average: '#4DABF5',
    prediction: '#83C3F7',
  },
  [LineColors.GREEN]: {
    flow: '#43A047',
    average: '#68B36B',
    prediction: '#9AD29C',
  },
  [LineColors.PURPLE]: {
    flow: '#AB47BC',
    average: '#BB6BC9',
    prediction: '#C786D3',
  },
  [LineColors.LIME]: {
    flow: '#EEFF41',
    average: '#E3FF79',
    prediction: '#F6FF9A',
  },
  [LineColors.PINK]: {
    flow: '#FF69B4',
    average: '#FFB6C1',
    prediction: '#FFC0CB',
  },
  [LineColors.BROWN]: {
    flow: '#795548',
    average: '#A1887F',
    prediction: '#BCAAA4',
  },
  [LineColors.OLIVE]: {
    flow: '#3F6212',
    average: '#4D7C0F',
    prediction: '#65A30D',
  },
  [LineColors.BLUEGRAY]: {
    flow: '#546E7A',
    average: '#78909C',
    prediction: '#B0BEC5',
  },
  [LineColors.CYAN]: {
    flow: '#2ACFCF',
    average: '#80FFFF',
    prediction: '#C0FFFF',
  },
  [LineColors.SPRINGGREEN]: {
    flow: '#91E24C',
    average: '#91FF35',
    prediction: '#C1FF7A',
  },
  [LineColors.INDIGO]: {
    flow: '#6A52B3',
    average: '#A597D1',
    prediction: '#AEA1D5',
  },
  [LineColors.TEAL]: {
    flow: '#22A699',
    average: '#80CBC4',
    prediction: '#B2DFDB',
  },
  [LineColors.LAVENDER]: {
    flow: '#B3B3FF',
    average: '#C9C9FF',
    prediction: '#E0E0FF',
  },
  [LineColors.CORAL]: {
    flow: '#FF7F50',
    average: '#FFA07A',
    prediction: '#FFDAB9',
  },
  [LineColors.ROSEWOOD]: {
    flow: '#C65D7B',
    average: '#D78DA2',
    prediction: '#DC9DAF',
  },
  [LineColors.GRAY]: {
    flow: '#4D4D4D',
    average: '#808080',
    prediction: '#999999',
  },
  [LineColors.MAROON]: {
    flow: '#800000',
    average: '#B03060',
    prediction: '#C04080',
  },
};
