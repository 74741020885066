import { DeviceLocationDto } from '@thingslog/repositories';
import { useModal } from '@thingslog/ui-components';
import React, { FC, useEffect, useState, MouseEvent } from 'react';
import MutateLocationPopup from './MutateLocationPopup';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Fab } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeviceLocationEditor: FC<DeviceLocationEditorProps> = ({
  paramsDeviceNumber,
  selectedDeviceNumber,
  deviceLocations,
  onChangeLocation,
}: DeviceLocationEditorProps) => {
  const [isParamDeviceHasLocation, setIsParamDeviceHasLocation] = useState<boolean>(false);
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const { modal } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    if (paramsDeviceNumber) {
      const hasLocation = deviceLocations[paramsDeviceNumber] !== undefined;
      setIsParamDeviceHasLocation(hasLocation);
    }
  }, [paramsDeviceNumber, deviceLocations]);

  useEffect(() => {
    if (selectedDeviceNumber) {
      const location: DeviceLocationDto | undefined = deviceLocations[selectedDeviceNumber];
      if (location) {
        setLatitude(location.latitude);
        setLongitude(location.longitude);
      }
    } else {
      setLatitude(0);
      setLongitude(0);
    }
  }, [selectedDeviceNumber, deviceLocations]);

  const openModal = (
    mode: 'add' | 'edit',
    deviceNumber: string,
    longitude: number,
    latitude: number
  ): void => {
    modal({
      title:
        mode === 'add'
          ? t('map_add_device', { device: deviceNumber })
          : t('map_edit_device', { device: deviceNumber }),
      content: (
        <MutateLocationPopup
          initialLocation={{ latitude, longitude }}
          onUpdatedLocation={(newLocation: DeviceLocationDto): void => {
            onChangeLocation(deviceNumber, newLocation.latitude, newLocation.longitude);
          }}
        />
      ),
    });
  };

  return (
    <div className="flex">
      {!isParamDeviceHasLocation && paramsDeviceNumber && (
        <Fab
          color="primary"
          size="small"
          onClick={(e: MouseEvent): void => {
            openModal('add', paramsDeviceNumber, longitude, latitude);
          }}
        >
          <AddIcon />
        </Fab>
      )}
      {selectedDeviceNumber && (
        <Fab
          color="primary"
          size="small"
          onClick={(e: MouseEvent): void => {
            openModal('edit', selectedDeviceNumber, longitude, latitude);
          }}
        >
          <EditIcon />
        </Fab>
      )}
    </div>
  );
};

interface DeviceLocationEditorProps {
  paramsDeviceNumber: string | null;
  selectedDeviceNumber: string | null;
  deviceLocations: Map<string, DeviceLocationDto>;
  onChangeLocation: (deviceNumber: string, latitude: number, longitude: number) => void;
}

export default DeviceLocationEditor;
