import React, { MouseEvent } from 'react';
import { IconButton, Select, SelectProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const ClearableSelect = <T,>({
  showClear,
  onClear,
  sx,
  ...props
}: ClearableSelectProps<T>): JSX.Element => {
  return (
    <Select
      {...props}
      sx={{
        ...sx,
        '& .MuiSelect-iconOutlined': {
          display: showClear ? 'none' : undefined,
        },
      }}
      endAdornment={
        showClear && (
          <IconButton
            size="small"
            className="-mr-3"
            onClick={(e: MouseEvent<HTMLButtonElement>): void => onClear(e)}
          >
            <ClearIcon />
          </IconButton>
        )
      }
    />
  );
};

interface ClearableSelectProps<T> extends Omit<SelectProps<T>, 'endAdornment'> {
  showClear: boolean;
  onClear: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default ClearableSelect;
