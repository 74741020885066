import React, { ChangeEvent, memo } from 'react';
import { Search } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DataGridHeader = <T,>({
  filterValue,
  headerName,
  attribute,
  onFilterValueChange,
  activeAttribute,
}: DataGridHeaderProps<T>): JSX.Element => {
  const { t } = useTranslation();

  const checkIfDisabled = (attribute: T): boolean => {
    return activeAttribute !== attribute && activeAttribute !== null;
  };

  const renderValue = (attribute: T): string => {
    return attribute === activeAttribute && filterValue ? filterValue : '';
  };

  return attribute ? (
    <div className="flex flex-col justify-start items-start">
      <span className="leading-loose text-base font-medium">{headerName}</span>
      <TextField
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        onChange={onFilterValueChange}
        size="small"
        className="w-full"
        placeholder={t('devices_column_header_search')}
        variant="outlined"
        id={typeof attribute === 'string' ? attribute : undefined}
        value={renderValue(attribute)}
        disabled={checkIfDisabled(attribute)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </div>
  ) : (
    <span className="leading-loose text-base font-medium">{headerName}</span>
  );
};

interface DataGridHeaderProps<T> {
  filterValue?: string;
  headerName?: string;
  attribute?: T;
  onFilterValueChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeAttribute?: T | null;
}

export default memo(DataGridHeader) as typeof DataGridHeader;
