import { AxiosError } from 'axios';

export default class ErrorUtil {
  public static handleErrorWithAlert = (error: AxiosError): void => {
    if (error.response?.data) {
      if (typeof error.response.data === 'string') {
        alert(error.response.data);
      } else if (error.response.data.message) {
        alert(error.response.data.message);
      }
    } else {
      throw error;
    }
  };
}
