import {
  CompanyLicenseDto,
  LicenseDto,
  LicensePerReadingDto,
  LicenseType,
} from '@thingslog/repositories';
import { areIntervalsOverlapping, differenceInDays, endOfDay, startOfDay } from 'date-fns';

export default class LicenseValidator {
  // Define specific license types for date conflicts
  private static conflictingLicenseTypes = [
    LicenseType.PER_READING,
    LicenseType.PER_REMOTE_NODE,
    LicenseType.PER_POC,
  ];

  public static isCompanyNonPartialLicense = (license: LicenseDto): boolean => {
    return license['@type'] !== LicenseType.COMPANY_PARTIAL_LICENSE;
  };

  // Helper function to check if the license is of a specific type and valid
  public static isConflicting = (license: LicenseDto): boolean => {
    return this.conflictingLicenseTypes.includes(license['@type']);
  };

  // Helper function to check if the date ranges overlap
  // Uses inclusive dates to prevent same-day license overlap
  public static isDateRangeOverlapping = (
    existingLicense: CompanyLicenseDto,
    fromDate: Date,
    toDate: Date
  ): boolean => {
    const existingPeriod = {
      start: startOfDay(new Date(existingLicense.validityPeriodFrom)),
      end: endOfDay(new Date(existingLicense.validityPeriodTo)),
    };
    const newPeriod = {
      start: startOfDay(fromDate),
      end: endOfDay(toDate),
    };
    return areIntervalsOverlapping(existingPeriod, newPeriod, { inclusive: true });
  };

  public static detectLicenseConflict = (
    existingLicenses: LicenseDto[],
    fromDate: Date,
    toDate: Date
  ): boolean => {
    const conflictingLicense = existingLicenses.find(
      (license: LicenseDto) =>
        this.isCompanyNonPartialLicense(license) &&
        this.isConflicting(license) &&
        this.isDateRangeOverlapping(license as CompanyLicenseDto, fromDate, toDate)
    );
    return !!conflictingLicense; // Convert to boolean
  };

  public static separateExpiringAndOverLimitLicenses = (
    licenses: LicenseDto[]
  ): { expiringLicenses: LicenseDto[]; overLimitReadingsLicenses: LicensePerReadingDto[] } => {
    let expiringLicenses = licenses.filter(
      (license: LicenseDto) =>
        this.isCompanyNonPartialLicense(license) &&
        differenceInDays(new Date((license as CompanyLicenseDto).validityPeriodTo), new Date()) <
          30 &&
        license.isValid
    );

    let licensesPerReading = licenses.filter((license: LicenseDto) => {
      if (license['@type'] === LicenseType.PER_READING) {
        let perReadingLicense = license as LicensePerReadingDto;
        return perReadingLicense;
      }
    });

    let overLimitReadingsLicenses = (licensesPerReading as LicensePerReadingDto[]).filter(
      (perReadingLicense: LicensePerReadingDto) => {
        if (perReadingLicense.isOverLimit && perReadingLicense.isValid) {
          return perReadingLicense;
        }
      }
    );

    return {
      expiringLicenses: expiringLicenses,
      overLimitReadingsLicenses: overLimitReadingsLicenses,
    };
  };
}
