import { AxiosPromise, AxiosResponse } from 'axios';
import Axios from '../../clients/Axios/Axios';

type CallbackFunction = (...args: any[]) => any;

function getRegistrationConfirmation(
  token: String,
  cb: CallbackFunction,
  ecb: CallbackFunction
): AxiosPromise {
  return Axios.get(`/api/confirm-registration?token=${token}`)
    .then((resp: AxiosResponse) => resp)
    .then(cb)
    .catch(ecb);
}

const ConfirmRegistrationClient = {
  getRegistrationConfirmation,
};

export default ConfirmRegistrationClient;
