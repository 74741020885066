import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { AUTH_USER } from './actions/types';
import jwtDecode from 'jwt-decode';

import { composeWithDevTools } from 'redux-devtools-extension';

const { yambolJWT, bobovdolJWT, pirdopJWT, nonRestrictedAccessPages } = window['runConfig'];

// import i18n (needs to be bundled ;))
import './i18n';
import { ContextProviders } from './ContextProviders';
import Axios from './clients/Axios/Axios';
import { ErrorResponse } from '@thingslog/repositories';
import { AxiosError } from 'axios';

const store: Store = createStore(reducers, composeWithDevTools(applyMiddleware(reduxThunk)));

//const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
//const store = createStoreWithMiddleware(reducers);
const token: string | null = localStorage.getItem('token');
const decoded_token: string | null = localStorage.getItem('decoded_token');

if (token && token !== yambolJWT && token !== bobovdolJWT && token !== pirdopJWT) {
  const decoded: { [key: string]: string } = decoded_token
    ? JSON.parse(decoded_token)
    : jwtDecode(token);
  store.dispatch({
    type: AUTH_USER,
    payload: {
      username: decoded.sub,
      roles: decoded.roles,
      companyName: decoded.companyName,
      language: decoded.language,
      isDashboardEnabled: decoded.isDashboardEnabled,
    },
  });
  Axios.defaults.headers.common['Authorization'] = token;
}

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    if (error.response?.status) {
      switch (error.response.status) {
        case 401:
          if (window.location.pathname !== '/app/signin') {
            window.location.href = `${window.location.origin}/app/signout`;
          }
          break;
        case 403:
          if (error.response.data.errorCode === 'NO_VALID_OR_ACTIVE_LICENSE') {
            if (
              window.location.pathname !== '/app/restricted-access' &&
              !nonRestrictedAccessPages.includes(window.location.pathname)
            ) {
              window.location.href = `${window.location.origin}/app/restricted-access`;
            }
          } else {
            if (
              window.location.pathname !== '/app?error=forbidden' &&
              window.location.pathname !== '/app/signin'
            ) {
              window.location.href = `${window.location.origin}/app?error=forbidden`;
            }
          }
          break;
        default:
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ContextProviders>
        <App style={{ width: '100%', height: '100%' }} />
      </ContextProviders>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
