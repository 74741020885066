import React, { FC, useState, useMemo, useEffect } from 'react';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';

import { GridColumnVisibilityModel } from '@thingslog/ui-components/src/DataGrid';
import { differenceInDays } from 'date-fns';
import { Pagination, ExportFormats, ReadingsExportDto, Port } from '@thingslog/repositories';
import { useToast } from '@thingslog/ui-components';

import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import MultipleSelectTable, {
  MultipleSelectTableOption,
} from '../../components/MultipleSelectTable/MultipleSelectTable';
import { useTranslation } from 'react-i18next';
import CountersTable from './CountersTable';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';

import { Device } from '@thingslog/repositories';

import DeviceSensorsDto from '@thingslog/repositories/src/deviceSensorsDto/DeviceSensorsDto';

import {
  devicesQueryClient,
  initialConfigQueryClient,
  multipleReadingsExportQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useParams } from 'react-router-dom';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import getDefaultSensorName from '../../common/SensorNameHelper';

const CountersExport: FC<CountersExportProps> = () => {
  const { useDevicesData } = useMemo(() => devicesQueryClient, []);
  const { useDevicePortsConfigData } = useMemo(() => initialConfigQueryClient, []);
  const {
    useMultipleReadingsExportCSV,
    useMultipleReadingsExportExcel,
    useMultipleReadingsExportJSON,
  } = useMemo(() => multipleReadingsExportQueryClient, []);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const { toast } = useToast();
  const { deviceNumber } = useParams();

  const companyId = useSelector((state: ReduxState) => state.company.id);
  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);
  const toDateTz = useSelector((state: ReduxState) => state.period.toDateTz);
  const fromDateTz = useSelector((state: ReduxState) => state.period.fromDateTz);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    deviceName: true,
    deviceNumber: false,
    sensorIndex: true,
    previousCounter: true,
    dt: true,
    delta: true,
  });

  const [exportFormat, setExportFormat] = useState<ExportFormats>('Excel');
  const [data, setData] = useState<ReadingsExportDto[]>([]);
  const [deviceTableOptions, setDeviceTableOptions] = useState<MultipleSelectTableOption<string>[]>(
    []
  );
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [selectedSensors, setSelectedSensors] = useState<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ]);
  const [selectedSensor, setSelectedSensor] = useState<number | null>(null);
  const [enabledPorts, setEnabledPorts] = useState<Record<number, Port>>({});

  //Devices * DayDifference(fromDate and toDate)
  const [isExceedingRequestLimit, setIsExceedingRequestLimit] = useState<boolean>(false);

  useEffect(() => {
    if (deviceNumber) {
      setSelectedDevices([deviceNumber]);
      jsonMutate({
        body: {
          deviceSensors: [
            {
              deviceNumber: deviceNumber,
              sensorIndexes: selectedSensors,
            },
          ],
          fromDate: fromDateTz,
          toDate: toDateTz,
        },
      });
    }

    setColumnVisibilityModel((prevModel: GridColumnVisibilityModel) =>
      deviceNumber
        ? { ...prevModel, deviceName: false, deviceNumber: false }
        : { ...prevModel, deviceName: true }
    );
  }, [deviceNumber]);

  useEffect(() => {
    let daysDifference = differenceInDays(toDateTz, fromDateTz);
    let selectedDevicesCount = selectedDevices.length;
    if (daysDifference * selectedDevicesCount > 900 && !deviceNumber) {
      setIsExceedingRequestLimit(true);
    } else {
      setIsExceedingRequestLimit(false);
    }
  }, [selectedDevices, fromDateTz, toDateTz]);

  useEffect(() => {
    const isMobile = width < 1024;
    setColumnVisibilityModel((prevModel: GridColumnVisibilityModel) => ({
      ...prevModel,
      sensorIndex: !isMobile,
      previousCounter: !isMobile,
      dt: !isMobile,
      delta: !isMobile,
    }));
  }, [width]);

  //query for devices
  const { isLoading: isLoadingDevices } = useDevicesData(companyId, 0, 9999, null, null, {
    onSuccess: (data: Pagination<Device>) => {
      let deviceTableOptions = data.content.map((device: Device) => {
        return {
          value: device.number,
          label: device.name ? `${device.name} (${device.number})` : device.number,
        };
      });
      setDeviceTableOptions(deviceTableOptions);
    },
    onError: () => {
      toast({ message: t('counters_export_error_alert_message'), type: 'error', duration: 5000 });
    },
    refetchOnWindowFocus: false,
  });

  //query for enabled ports
  const { isLoading: isDevicePortsConfigLoading, isSuccess: isDevicePortsConfigSuccess } =
    useDevicePortsConfigData(deviceNumber!, {
      enabled: !!deviceNumber,
      onSuccess: (portsConfig: Record<number, Port>) => {
        let enabledPortsFromConfig: Record<number, Port> = {};

        Object.entries(portsConfig).forEach(([sensorIndex, port]: [string, Port]) => {
          if (port.enabled) {
            enabledPortsFromConfig[sensorIndex] = port;
          }
        });

        setEnabledPorts(enabledPortsFromConfig);
      },
      onError: () => {
        toast({ message: t('error_cannot_fetch_ports_config'), type: 'error', duration: 5000 });
      },
    });

  //mutations for json load and export
  const {
    mutate: jsonMutate,
    isLoading: isJSONLoading,
    isSuccess,
  } = useMultipleReadingsExportJSON({
    onSuccess: (data: ReadingsExportDto[]) => {
      setData(data);
    },
    onError: () => {
      toast({ message: t('counters_export_error_alert_message'), type: 'error', duration: 5000 });
    },
  });

  const { mutate: exportMutateCSV, isLoading: isExportingCSV } = useMultipleReadingsExportCSV({
    onSuccess: (data: ArrayBuffer) => {
      const fileName = `ReadingsExport-${exportFormat}-from-${fromDateTz.toISOString()}-to-${toDateTz.toISOString()}.zip`;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
    },
    onError: () => {
      toast({ message: t('counters_export_error_alert_message'), type: 'error', duration: 5000 });
    },
  });

  const { mutate: exportMutateExcel, isLoading: isExportingExcel } = useMultipleReadingsExportExcel(
    {
      onSuccess: (data: ArrayBuffer) => {
        const fileName = `ReadingsExport-${exportFormat}-from-${fromDateTz.toISOString()}-to-${toDateTz.toISOString()}.zip`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      },
      onError: () => {
        toast({ message: t('counters_export_error_alert_message'), type: 'error', duration: 5000 });
      },
    }
  );

  const formDeviceSensorsOptions = (): DeviceSensorsDto[] => {
    const deviceSensorOptions = selectedDevices.map((deviceNumber: string) => {
      return {
        deviceNumber: deviceNumber,
        sensorIndexes: selectedSensor !== null ? [selectedSensor] : selectedSensors,
      };
    });

    return deviceSensorOptions;
  };

  const downloadHandler = (): void => {
    const deviceSensors = formDeviceSensorsOptions();
    switch (exportFormat) {
      case 'CSV':
        exportMutateCSV({
          body: {
            deviceSensors: deviceSensors,
            fromDate: fromDateTz,
            toDate: toDateTz,
          },
        });
        break;
      case 'Excel':
        exportMutateExcel({
          body: {
            deviceSensors: deviceSensors,
            fromDate: fromDateTz,
            toDate: toDateTz,
          },
        });
        break;
      default:
        break;
    }
  };

  const loadJsonDataHandler = (): void => {
    const deviceSensors = formDeviceSensorsOptions();
    jsonMutate({
      body: {
        deviceSensors: deviceSensors,
        fromDate: fromDateTz,
        toDate: toDateTz,
      },
    });
  };

  const disableButtons = (): boolean => {
    return isLoadingDevices || isJSONLoading || isExportingCSV || isExportingExcel;
  };

  return (
    <Header>
      <Container>
        <TitleHeader
          className="mb-4"
          title={deviceNumber ? t('counters_readings_header') : t('counters_export_header')}
          deviceNumber={deviceNumber && selectedDevice && selectedDevice.number}
          deviceName={deviceNumber && selectedDevice && selectedDevice.name}
          customerInfo={deviceNumber && selectedDevice && selectedDevice.customerInfo}
        />
        <section className="flex flex-wrap gap-2 gap-y-5 justify-between">
          <div className="flex flex-wrap gap-2 gap-y-5 max-md:w-full">
            <div className="flex flex-wrap gap-2 max-md:w-full">
              <PeriodPicker alwaysShowDatePickers dateDifference={deviceNumber ? undefined : 30} />
            </div>
            <div className="flex gap-2 max-md:w-full">
              {deviceNumber && isDevicePortsConfigSuccess && (
                <FormControl size="small" className="w-56 max-md:w-1/2">
                  <InputLabel id="sensor-select-label">{t('sensor')}</InputLabel>
                  <Select
                    labelId="sensor-select-label"
                    id="sensor-select"
                    value={selectedSensor === null ? 'all' : String(selectedSensor)}
                    label={t('sensor')}
                    onChange={(event: SelectChangeEvent<string>): void =>
                      setSelectedSensor(
                        event.target.value === 'all' ? null : Number(event.target.value)
                      )
                    }
                  >
                    <MenuItem value="all">{t('all_sensors')}</MenuItem>

                    {Object.entries(enabledPorts).map(([sensorIndex, port]: [string, Port]) => {
                      let sensorName = port.sensor.name || getDefaultSensorName(sensorIndex);
                      return (
                        <MenuItem key={sensorIndex} value={sensorIndex}>
                          {sensorName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <Button
                variant="contained"
                color="primary"
                className="max-md:w-1/2"
                disableElevation
                startIcon={isJSONLoading ? <CircularProgress size={15} /> : <SearchIcon />}
                disabled={disableButtons() || isExceedingRequestLimit}
                onClick={loadJsonDataHandler}
              >
                {t('counters_export_search')}
              </Button>
            </div>
          </div>
          <div className="flex gap-2 max-md:w-full justify-start">
            <FormControl className="max-md:w-1/2">
              <InputLabel>{t('counters_export_format')}</InputLabel>
              <Select
                size="small"
                value={exportFormat}
                label={t('counters_export_format')}
                onChange={(e: SelectChangeEvent<ExportFormats>): void => {
                  setExportFormat(e.target.value as ExportFormats);
                }}
              >
                <MenuItem value={'CSV'}>CSV</MenuItem>
                <MenuItem value={'Excel'}>Excel</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="max-md:w-1/2"
              disableElevation
              startIcon={
                isExportingCSV || isExportingExcel ? (
                  <CircularProgress size={15} />
                ) : (
                  <FileDownloadIcon />
                )
              }
              disabled={disableButtons() || isExceedingRequestLimit}
              onClick={downloadHandler}
            >
              {t('counters_export_download')}
            </Button>
          </div>
        </section>
        {!deviceNumber && isExceedingRequestLimit && (
          <section>
            <div className="w-full my-5">
              <h3 className="text-red-500">
                {t('counters_export_maximum_capacity_error_message')}
              </h3>
              <h4 className="text-gray-700">
                {t('counters_export_maximum_capacity_suggestion_message')}
              </h4>
            </div>
          </section>
        )}
        {!deviceNumber && (
          <section>
            <div className="w-full my-5">
              <MultipleSelectTable
                title={t('counter_export_select_devices_label')}
                options={deviceTableOptions}
                selectedOptions={selectedDevices}
                setSelectedOptions={(options: (string | number)[]): void =>
                  setSelectedDevices(options as string[])
                }
              />
            </div>
          </section>
        )}
        <section>
          <div className="w-full my-5">
            {isJSONLoading ? (
              <CircularProgress />
            ) : (
              <>
                {isSuccess && (
                  <CountersTable
                    rows={data}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel): void =>
                      setColumnVisibilityModel(newModel)
                    }
                    density="compact"
                    getRowId={(row: ReadingsExportDto): string =>
                      row.deviceNumber + row.sensorIndex + row.date
                    }
                    initialState={{ sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } }}
                  />
                )}
              </>
            )}
          </div>
        </section>
      </Container>
    </Header>
  );
};

interface CountersExportProps {}

interface CountersExportUrlParams {
  deviceNumber: string;
}

export default CountersExport;
