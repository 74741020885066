import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PeriodMode } from '../../../model/Pickers/PeriodMode';
import { ReduxState } from '../../../reducers';
import * as periodActionsCreators from '../../../state_management/actions/PeriodSelectionActionCreator';

const usePeriodHelper = (alwaysShowDatePickers: boolean): usePeriodHelperProps => {
  // #region State
  const dispatch = useDispatch();
  const periodMode = useSelector((state: ReduxState) => state.period.periodMode);
  const { changePeriodMode } = bindActionCreators(periodActionsCreators, dispatch);
  const [displayFromDatePicker, setDisplayFromDatePicker] = useState(false);
  const [displayFromDateTimePicker, setDisplayFromDateTimePicker] = useState(false);
  const [displayToDatePicker, setDisplayToDatePicker] = useState(false);
  const [displayToDateTimePicker, setDisplayToDateTimePicker] = useState(false);
  // #endregion

  // #region UseEffects
  useEffect(() => {
    setDisplayFromDatePicker(periodMode === PeriodMode.DATE_RANGE);
    setDisplayFromDateTimePicker(periodMode === PeriodMode.CUSTOM);
    setDisplayToDatePicker(periodMode === PeriodMode.DATE_RANGE);
    setDisplayToDateTimePicker(periodMode === PeriodMode.CUSTOM);
  }, [periodMode]);

  useEffect(() => {
    if (alwaysShowDatePickers && periodMode !== PeriodMode.DATE_RANGE) {
      changePeriodMode(PeriodMode.DATE_RANGE);
    }
  }, [alwaysShowDatePickers, periodMode]);
  // #endregion

  return {
    displayFromDatePicker,
    displayFromDateTimePicker,
    displayToDatePicker,
    displayToDateTimePicker,
  };
};

interface usePeriodHelperProps {
  displayFromDatePicker: boolean;
  displayFromDateTimePicker: boolean;
  displayToDatePicker: boolean;
  displayToDateTimePicker: boolean;
}

export default usePeriodHelper;
