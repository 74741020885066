import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Description from './CustomerInfoDescription';
import { CustomerInfoResponse } from '@thingslog/repositories';

const TitleHeader: FC<TitleHeaderProps> = ({
  className,
  title,
  deviceNumber,
  deviceName,
  customerInfo = null,
  pageType = 'base',
}: TitleHeaderProps) => {
  const { t } = useTranslation();

  const titleContent = useMemo(() => {
    if (deviceNumber !== undefined) {
      return t('page_title_header_number_with_name', {
        title,
        device_number: deviceNumber,
        device_name: deviceName,
        context: deviceName ? 'present' : 'absent',
      });
    }
    return title;
  }, [title, deviceNumber, deviceName]);

  return (
    <div className={clsx(className && className)}>
      <div
        className={clsx(
          pageType === 'base' && 'text-2xl font-bold',
          pageType === 'form' && 'text-xl font-semibold'
        )}
      >
        {titleContent}
      </div>
      {customerInfo && <Description customerInfo={customerInfo} />}
    </div>
  );
};

interface TitleHeaderProps {
  className?: string;
  title: string;
  deviceNumber?: string;
  deviceName?: string;
  customerInfo?: CustomerInfoResponse | null;
  pageType?: 'base' | 'form';
}

export default TitleHeader;
