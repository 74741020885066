import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { digitalSensorTypeOptions } from './common/SensorTypeOptions';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import DeviceDigits from '../../../../DeviceDigits';
import { Units } from '../../../../model/Units/Units';
import { hash, HelperTextInputField } from '@thingslog/ui-components';

const GenericDigitalConfig: FC<GenericDigitalConfigProps> = ({
  index,
}: GenericDigitalConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, register, getValues }: UseFormReturn<InitialConfigFormFields>): ReactElement => {
        return (
          <>
            <div className="h-fit order-[-1]">
              <DeviceDigits
                counter={getValues(`ports.${index}.sensor.initialValue`).toString()}
                digits={getValues(`ports.${index}.sensor.precision`)}
                fraction={getValues(`ports.${index}.sensor.scale`)}
                readOnly={true}
              />
            </div>

            <HelperTextInputField
              className="order-[-1]"
              disabled
              {...register(`ports.${index}.sensor.pulseRate`)}
              label={t('device_config_pulse_per', {
                x: Units[getValues(`ports.${index}.sensor.units`)],
              })}
              size="small"
            />

            <Controller
              control={control}
              name={`ports.${index}.sensor.@type`}
              render={({
                field,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.@type`
              >): ReactElement => (
                <FormControl className="order-[-1]" size="small">
                  <InputLabel>{t('device_config_meter_type')}</InputLabel>
                  <Select
                    {...field}
                    disabled={field.value === 'modbus_generic_digital_sensor'}
                    label={t('device_config_meter_type')}
                  >
                    {Object.entries(digitalSensorTypeOptions).map(
                      ([value, label]: [string, string]) => (
                        <MenuItem
                          key={hash(`sensorType-${value}`)}
                          value={value}
                          disabled={value === 'modbus_generic_digital_sensor'}
                        >
                          {t(label)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              )}
            />
          </>
        );
      }}
    </ConnectForm>
  );
};

interface GenericDigitalConfigProps {
  index: number;
}

export default GenericDigitalConfig;
