import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import MqttConfig from './common/MqttConfiguration';
import BinaryDataConfig from './common/BinaryDataConfig';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { stringOrNull } from '../../utils/SetValueAsUtil';
import { GPRSConfigModel, PreferredNetworkType, GprsRadioMode } from '@thingslog/repositories';

const GPRSNetworkConfiguration: FC<GPRSNetworkConfigurationProps> = () => {
  const { t } = useTranslation();

  return (
    <ConnectForm<GPRSConfigModel>>
      {({ register, control }: UseFormReturn<GPRSConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 gap-5">
          <TextField
            {...register('address')}
            label={t('device_initial_config_address')}
            size="small"
          />

          <TextField {...register('port')} label={t('device_initial_config_port')} size="small" />
          <TextField {...register('pin')} label="PIN" size="small" />

          <FormControl size="small">
            <InputLabel>{t('device_initial_config_resolve_address')}</InputLabel>
            <Controller
              control={control}
              name="resolveAddress"
              render={({
                field,
              }: UseControllerReturn<GPRSConfigModel, 'resolveAddress'>): ReactElement => (
                <Select<boolean>
                  {...field}
                  onChange={(e: SelectChangeEvent): void =>
                    field.onChange(e.target.value === 'true' ? true : false)
                  }
                  label={t('device_initial_config_resolve_address')}
                >
                  <MenuItem value="true">{t('yes')}</MenuItem>
                  <MenuItem value="false">{t('no')}</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <TextField
            {...register('apn', {
              setValueAs: stringOrNull,
            })}
            label="APN"
            size="small"
          />

          <TextField
            {...register('apnUsername', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_apn_username')}
            size="small"
          />

          <TextField
            {...register('apnPassword', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_apn_password')}
            size="small"
          />

          <TextField
            {...register('imei', {
              setValueAs: stringOrNull,
            })}
            label="IMEI"
            size="small"
          />

          <TextField
            {...register('netAttachedDelay')}
            label={t('device_initial_config_net_attached_delay')}
            size="small"
          />

          <FormControl size="small">
            <InputLabel>{t('device_initial_config_gprs_radio_mode')}</InputLabel>
            <Controller
              control={control}
              name="gprsRadioMode"
              render={({
                field,
              }: UseControllerReturn<GPRSConfigModel, 'gprsRadioMode'>): ReactElement => (
                <Select<GprsRadioMode | null>
                  {...field}
                  label={t('device_initial_config_gprs_radio_mode')}
                >
                  <MenuItem value="AUTO">
                    {t('device_initial_config_grps_radio_mode_auto_option')}
                  </MenuItem>
                  <MenuItem value="GSM2G">GSM2G</MenuItem>
                  <MenuItem value="LTE4G">LTE4G</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <FormControl size="small">
            <InputLabel>{t('device_initial_config_preferred_network_type')}</InputLabel>
            <Controller
              control={control}
              name="preferredNetworkType"
              render={({
                field,
              }: UseControllerReturn<GPRSConfigModel, 'preferredNetworkType'>): ReactElement => (
                <Select<PreferredNetworkType | null>
                  {...field}
                  label={t('device_initial_config_preferred_network_type')}
                >
                  <MenuItem value="GSM">GSM</MenuItem>
                  <MenuItem value="LTE4G">LTE4G</MenuItem>
                  <MenuItem value="NB_IOT">NB_IOT</MenuItem>
                  <MenuItem value="CAT_M1">CAT_M1</MenuItem>
                  <MenuItem value="DEFAULT">
                    {t('device_initial_config_preferred_network_default_option')}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <TextField
            {...register('mccMnc', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_mcc_mnc')}
            size="small"
          />

          <TextField
            {...register('simCardProvider', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_simcard_provider')}
            size="small"
          />

          <TextField
            {...register('simCardNumber', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_simcard_number')}
            size="small"
          />

          <BinaryDataConfig />

          <MqttConfig />
        </div>
      )}
    </ConnectForm>
  );
};

interface GPRSNetworkConfigurationProps {}

export default GPRSNetworkConfiguration;
