import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { InputLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { hash } from '@thingslog/ui-components';

const FractionSelector: FC<FractionSelectorProps> = ({
  className,
  disabled = false,
  fractionOptions = [1, 2, 3, 4, 5, 6, 7],
  fraction,
  onFractionChange,
}: FractionSelectorProps) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(className && className)}>
      <InputLabel className="font-extrabold text-black">{t('device_digits_fraction')}</InputLabel>
      <ToggleButtonGroup
        disabled={disabled}
        color="primary"
        value={fraction}
        exclusive
        onChange={onFractionChange}
      >
        {fractionOptions.map((fractionOption: number) => {
          return (
            <ToggleButton key={hash(`fraction-${fractionOption}`)} value={fractionOption}>
              {fractionOption}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

interface FractionSelectorProps {
  className?: string;
  disabled?: boolean;
  fractionOptions?: number[];
  fraction: number;
  onFractionChange?: (event: MouseEvent<HTMLElement>, value: number | null) => void;
}

export default FractionSelector;
