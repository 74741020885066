import React, { ReactNode, Component } from 'react';
import { Grid } from '@mui/material';
import SensorButton from './SensorButton';
import { GraphType } from '../model/GraphType';
import { Port } from '@thingslog/repositories';
import PortUtil from '../utils/PortUtil';

class SensorSelector extends Component<SensorSelectorProps> {
  private addOrRemoveIndex(id: number): void {
    let selectedIndexes = [...this.props.selectedIndexes];

    if (this.isSelected(id)) {
      selectedIndexes = selectedIndexes.filter((i: number) => {
        return i !== id;
      });
    } else {
      selectedIndexes.push(id);
    }

    this.props.onSelectedIndexesChanged(selectedIndexes);
  }

  private getColor(id: number): string {
    const sensorIsSelected = this.props.selectedIndexes.includes(id);
    if (sensorIsSelected) {
      return this.props.buttonColorGenerator(id, false);
    }
    return 'inherit';
  }

  private isSelected(id: number): boolean {
    return this.props.selectedIndexes.includes(id);
  }

  public render(): ReactNode {
    const { portsConfig, graphType, selectedIndexes } = this.props;
    return (
      <Grid container direction="row" spacing={1}>
        {Object.entries(portsConfig).map(([index, port]: [string, Port]) => {
          if (port.enabled) {
            if (graphType === GraphType.DIGITAL && PortUtil.isAnalogPort(port['@type'])) {
              return null;
            }
            if (graphType === GraphType.ANALOG && PortUtil.isDigitalPort(port['@type'])) {
              return null;
            }
            return (
              <Grid key={index} item>
                <SensorButton
                  port={port}
                  portIndex={Number(index)}
                  selectedIndexes={selectedIndexes}
                  graphType={graphType}
                  color={this.getColor(Number(index))}
                  onClick={(id: number): void => {
                    this.addOrRemoveIndex(id);
                  }}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    );
  }
}

interface SensorSelectorProps {
  portsConfig: Record<number, Port>;
  selectedIndexes: number[];
  onSelectedIndexesChanged: (selectedIndexes: number[]) => void;
  graphType: GraphType;
  onPortsConfigChanged: (portsConfig: Record<number, Port>) => void;
  buttonColorGenerator: (index: number, isAverage: boolean) => string;
}

export default SensorSelector;
