import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useModal } from '@thingslog/ui-components';

const DeleteGroupModal: FC<DeleteGroupModalProps> = ({
  handleOnDeleteDeviceGroup,
}: DeleteGroupModalProps) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <div className="flex justify-end mt-6">
      <Button onClick={handleOnDeleteDeviceGroup} autoFocus>
        {t('device_groups_btn_delete')}
      </Button>
      <Button onClick={closeModal}>{t('device_groups_btn_cancel')}</Button>
    </div>
  );
};

interface DeleteGroupModalProps {
  handleOnDeleteDeviceGroup: () => void;
}

export default DeleteGroupModal;
