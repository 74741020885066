import {
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, { ReactNode } from 'react';
import {
  addCommand,
  getCommandTypeParameters,
  getCommandTypes,
} from '../../../clients/Command/CommandClient';
import ErrorUtil from '../../../common/ErrorUtil';
import LoadingModalContentWrapper from '../../../components/LoadingModalContentWrapper/LoadingModalContentWrapper';
import { styles } from '../Commands.styles';
import { ICommandContextProps } from '../context/CommandPageContext';
import withCommandPageContext from '../context/withCommandPageContext';
import { grey } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import CommandFormatUtil from '../utils/CommandFormatUtil';
import { AddCommand } from '../../../model/API/Command/Command';
import { WithTranslation, withTranslation } from 'react-i18next';
import CommandTypeParameter, {
  CommandTypeParameterEnum,
  CommandTypeParameterRange,
} from '../../../model/API/Command/CommandTypeParameter';

class AddCommandModal extends React.Component<AddCommandModalProps, AddDeviceModalState> {
  public state: AddDeviceModalState = {
    allCommandTypes: [],
    allCommandParameters: [],
    commandType: null,
    inputCommandKey: '',
    inputCommandKeyType: null,
    inputCommandValue: '',
    commandParameters: new Map<string, string>(),
    isLoaded: false,
    isCommandLoading: false,
  };

  public componentDidMount = async (): Promise<void> => {
    await this.fetchCommandTypes();
  };

  public componentDidUpdate = async (
    prevProps: AddCommandModalProps,
    prevState: AddDeviceModalState
  ): Promise<void> => {
    if (prevState.commandType !== this.state.commandType && this.state.commandType) {
      this.fetchCommandParams(this.state.commandType);
    }

    if (prevState.inputCommandKey !== this.state.inputCommandKey) {
      this.updateCommandKeyType(this.state.inputCommandKey);
    }
  };

  private fetchCommandParams = async (commandType: string): Promise<void> => {
    this.setState({
      isLoaded: false,
    });

    try {
      const commandTypeParams = await getCommandTypeParameters(
        commandType,
        this.state.commandType === 'MODBUS' ? this.props.deviceNumber : undefined
      );
      this.setState({
        allCommandParameters: commandTypeParams,
        commandParameters: new Map<string, string>(), // Wipe previous params
        inputCommandValue: '',
        inputCommandKey: '',
        isLoaded: true,
      });
    } catch (error) {
      ErrorUtil.handleErrorWithAlert(error);
      this.closeSelf();
    }
  };

  private fetchCommandTypes = async (): Promise<void> => {
    this.setState({ isLoaded: false });
    try {
      const allCommandTypes = await getCommandTypes();
      if (allCommandTypes.length > 0) {
        this.setState({
          allCommandTypes: allCommandTypes,
          commandType: allCommandTypes[0],
          isLoaded: true,
        });
      } else {
        this.closeSelf();
      }
    } catch (error) {
      ErrorUtil.handleErrorWithAlert(error);
      this.closeSelf();
    }
  };

  private closeSelf = (): void => {
    this.props.commandPageDispatch({ type: 'toggle_add_modal', payload: false });
  };

  private handleCommandTypeChange = (
    e: SelectChangeEvent<string | null>,
    child: ReactNode
  ): void => {
    e.preventDefault();
    this.setState({ commandType: e.target.value as string });
  };

  private handleCommandKeyChange = (e: SelectChangeEvent<string>): void => {
    e.preventDefault();
    this.setState({ inputCommandKey: e.target.value as string, inputCommandValue: '' });
  };

  private handleCommandValueChange = (e: SelectChangeEvent<string>): void => {
    e.preventDefault();
    this.setState({ inputCommandValue: e.target.value as string });
  };

  private handleCommandValueChangeRange = (
    e: React.ChangeEvent,
    min: number,
    max: number
  ): void => {
    e.preventDefault();
    let inputCommandValue = e.target.value as number;
    if (inputCommandValue > max) {
      inputCommandValue = max;
    } else if (inputCommandValue < min) {
      inputCommandValue = min;
    }
    this.setState({ inputCommandValue: `${inputCommandValue}` });
  };

  private handleOnSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    this.setState({ isCommandLoading: true });
    const body: AddCommand = {
      commandType: this.state.commandType!, // safe
      commandParameters: CommandFormatUtil.convertMapToCmdParams(this.state.commandParameters),
    };

    try {
      await addCommand(this.props.deviceNumber, body);
    } catch (error) {
      ErrorUtil.handleErrorWithAlert(error);
    } finally {
      this.setState({ isCommandLoading: false });
      this.closeSelf();
    }
  };

  private isNotEmpty = (value: string): boolean => {
    return value.length > 0;
  };

  private isValidCmdParamInput = (): boolean => {
    const paramKey = this.state.inputCommandKey.trim();
    const paramValue = this.state.inputCommandValue.trim();

    if (this.isNotEmpty(paramKey) && this.isNotEmpty(paramValue)) {
      return true;
    }
    return false;
  };

  private addCommandParameter = (): void => {
    const paramKey = this.state.inputCommandKey.trim();
    const paramValue = this.state.inputCommandValue.trim();

    const commandParameters = this.state.commandParameters;
    commandParameters.set(paramKey, paramValue);
    this.setState({
      commandParameters: commandParameters,
      inputCommandKey: '',
      inputCommandValue: '',
    });
  };

  private updateCommandKeyType = (inputCommandKey: string): void => {
    const foundCmdTypeParam = [...this.state.allCommandParameters].find(
      (cmdTypeParam: CommandTypeParameterEnum | CommandTypeParameterRange): boolean =>
        cmdTypeParam.parameterName === inputCommandKey
    );

    if (foundCmdTypeParam) {
      this.setState({
        inputCommandKeyType: foundCmdTypeParam['@type'],
      });
    } else {
      this.setState({
        inputCommandKeyType: null,
      });
    }
  };

  private removeCommandParameter = (key: string): void => {
    const commandParameters = this.state.commandParameters;
    commandParameters.delete(key);
    this.setState({ commandParameters });
  };

  private getUnusedParameterNames = (): string[] => {
    const viableCmdParamNames = this.state.allCommandParameters.map(
      (cmdParam: CommandTypeParameter) => cmdParam.parameterName
    );
    this.state.commandParameters.forEach((value: string, key: string): void => {
      viableCmdParamNames.splice(viableCmdParamNames.indexOf(key), 1);
    });
    return viableCmdParamNames;
  };

  private checkSubmitBtnDisabled = (): boolean => {
    if (
      this.state.commandType === 'MODBUS' &&
      this.getUnusedParameterNames()[0] === 'sensor_index'
    ) {
      return false;
    } else {
      return this.getUnusedParameterNames().length > 0;
    }
  };

  private renderCommandValueInput = (): React.ReactNode => {
    const foundCmdTypeParam = [...this.state.allCommandParameters].find(
      (cmdTypeParam: CommandTypeParameterEnum | CommandTypeParameterRange): boolean =>
        cmdTypeParam.parameterName === this.state.inputCommandKey
    );

    if (foundCmdTypeParam && this.state.inputCommandKeyType === 'enum') {
      const cmdTypeParam = foundCmdTypeParam as CommandTypeParameterEnum;
      if (cmdTypeParam.values) {
        if (cmdTypeParam.parameterName === 'sensor_index' && this.state.commandType === 'MODBUS') {
          return (
            <TextField
              value={this.state.inputCommandValue}
              onChange={(e: React.ChangeEvent): void => {
                this.setState({ inputCommandValue: (e.target as HTMLInputElement).value });
              }}
              label={this.props.t('sensor_index')}
              size="small"
            />
          );
        }
        return (
          <FormControl
            className={this.props.classes.modalFormControl}
            variant="outlined"
            size="small"
          >
            <InputLabel>Parameter Value</InputLabel>
            <Select
              value={this.state.inputCommandValue}
              label="Command Value"
              onChange={this.handleCommandValueChange}
            >
              {cmdTypeParam.values.map(
                (value: { displayName: string; value: string }, index: number) => (
                  <MenuItem key={index} value={value.value}>
                    {value.value}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        );
      }
    } else if (foundCmdTypeParam && this.state.inputCommandKeyType === 'range') {
      const cmdTypeParam = foundCmdTypeParam as CommandTypeParameterRange;
      if (cmdTypeParam.startValue && cmdTypeParam.endValue) {
        return (
          <TextField
            fullWidth
            className={this.props.classes.modalTextField}
            value={this.state.inputCommandValue}
            onChange={(e: React.ChangeEvent): void =>
              this.handleCommandValueChangeRange(e, cmdTypeParam.startValue, cmdTypeParam.endValue)
            }
            type="number"
            InputProps={{
              inputProps: { min: cmdTypeParam.startValue, max: cmdTypeParam.endValue },
            }}
            size="small"
            variant="outlined"
          />
        );
      }
    } else {
      return (
        <TextField
          fullWidth
          className={this.props.classes.modalTextField}
          disabled
          size="small"
          variant="outlined"
        />
      );
    }
  };

  public renderCommandInputButton = (): React.ReactNode => {
    if (this.isValidCmdParamInput()) {
      return (
        <Fade in={true} timeout={500}>
          <IconButton
            size="small"
            className={this.props.classes.colorfulIconButton}
            onClick={this.addCommandParameter}
          >
            <DoneIcon />
          </IconButton>
        </Fade>
      );
    }
  };

  public render = (): React.ReactNode => {
    const { classes } = this.props;

    return (
      <LoadingModalContentWrapper
        isLoaded={this.state.isLoaded}
        title={this.props.t('commands_send_command')}
        closeSelf={this.closeSelf}
      >
        <form autoComplete="off" method="post" onSubmit={this.handleOnSubmit}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormControl className={classes.modalFormControl} variant="outlined" size="small">
                <InputLabel>{this.props.t('commands_command_type')}</InputLabel>
                <Select
                  value={this.state.commandType}
                  onChange={this.handleCommandTypeChange}
                  label={this.props.t('commands_command_type')}
                >
                  {this.state.allCommandTypes.map((commandType: string, index: number) => (
                    <MenuItem key={index} value={commandType}>
                      {this.props.t(CommandFormatUtil.translateCommandType(commandType))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography style={{ fontSize: 16, color: grey[600] }}>
                    {this.props.t('commands_parameters')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {[...this.state.commandParameters].map((cmdParam: [string, string], index: number) => (
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      className={classes.modalTextField}
                      value={cmdParam[0]}
                      name={`cmd_param_key_${index}`}
                      size="small"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      className={classes.modalTextField}
                      value={cmdParam[1]}
                      name={`cmd_param_value_${index}`}
                      size="small"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={(): void => this.removeCommandParameter(cmdParam[0])}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {this.getUnusedParameterNames().length > 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                  <Grid item xs={this.isValidCmdParamInput() ? 5 : 6}>
                    <FormControl
                      className={classes.modalFormControl}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel>{this.props.t('commands_parameter_name')}</InputLabel>
                      <Select
                        value={this.state.inputCommandKey}
                        label={this.props.t('commands_parameter_name')}
                        onChange={this.handleCommandKeyChange}
                      >
                        {this.getUnusedParameterNames().map(
                          (commandType: string, index: number) => (
                            <MenuItem key={index} value={commandType}>
                              {this.props.t(CommandFormatUtil.translateCommandType(commandType))}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={this.isValidCmdParamInput() ? 5 : 6}>
                    {this.renderCommandValueInput()}
                  </Grid>
                  {this.isValidCmdParamInput() && (
                    <Grid item>
                      <Fade in={true} timeout={500}>
                        <IconButton
                          size="small"
                          className={classes.colorfulIconButton}
                          onClick={this.addCommandParameter}
                        >
                          <DoneIcon />
                        </IconButton>
                      </Fade>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid xs={12}>
              <Button
                fullWidth
                color="primary"
                type="submit"
                endIcon={this.state.isCommandLoading ? <CircularProgress size={20} /> : null}
                variant="contained"
                className={classes.modalButton}
                disabled={this.checkSubmitBtnDisabled() || this.state.isCommandLoading}
              >
                {this.props.t('commands_btn_submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </LoadingModalContentWrapper>
    );
  };
}

interface AddCommandModalProps
  extends ICommandContextProps,
    WithStyles<typeof styles>,
    WithTranslation {
  deviceNumber: string;
}

interface AddDeviceModalState {
  allCommandTypes: string[];
  commandType: string | null;
  allCommandParameters: (CommandTypeParameterEnum | CommandTypeParameterRange)[];
  commandParameters: Map<string, string>;
  inputCommandKey: string;
  inputCommandKeyType: 'enum' | 'range' | null;
  inputCommandValue: string;
  isLoaded: boolean;
  isCommandLoading: boolean;
}

export default withTranslation()(withStyles(styles)(withCommandPageContext(AddCommandModal)));
