import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React, { FC } from 'react';
import { Icon } from '@mui/material';

const NotificationCheckbox: FC<NotificationCheckboxProps> = ({
  notificationCheckboxes,
}: NotificationCheckboxProps) => {
  return (
    <div className="flex">
      {notificationCheckboxes.map((item: CheckboxWithIcon, index: number) => {
        const { icon, ...checkbox } = item;
        return (
          <div key={index} className="flex items-center">
            <Icon>{icon}</Icon>
            <Checkbox {...checkbox} />
          </div>
        );
      })}
    </div>
  );
};

interface NotificationCheckboxProps {
  notificationCheckboxes: CheckboxWithIcon[];
}

interface CheckboxWithIcon extends CheckboxProps {
  icon: JSX.Element;
}
export default NotificationCheckbox;
