import React, { ReactNode, FC } from 'react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ErrorIcon } from './assets/ErrorIcon';
import { CloseIcon } from './assets/CloseIcon';
import { InfoIcon } from './assets/InfoIcon';
import { SuccessIcon } from './assets/SuccessIcon';
import { WarningIcon } from './assets/WarningIcon';
import { ToastProps } from './model/ToastProps';
import { ToastPosition } from './model/ToastPosition';
import { RequiredToastProps } from './model/ToastProps';
import { useToast } from './useToast';
import { Close } from '@mui/icons-material';

const Toast: FC<ToastProps> = (props: ToastProps) => {
  // #region styles
  const getIcon = (type: RequiredToastProps['type']): JSX.Element => {
    return {
      info: InfoIcon,
      error: ErrorIcon,
      success: SuccessIcon,
      warning: WarningIcon,
    }[type];
  };

  const backgroundStyles = (type: RequiredToastProps['type']): string => {
    const wrapperClasses: Record<RequiredToastProps['type'], string> = {
      info: 'bg-blue-600 text-blue-100',
      success: 'bg-green-600 text-green-100',
      warning: 'bg-orange-500 text-orange-100',
      error: 'bg-red-600 text-red-100',
    };
    return wrapperClasses[type];
  };

  const iconStyles = (type: RequiredToastProps['type']): string => {
    const iconClasses: Record<RequiredToastProps['type'], string> = {
      info: 'bg-blue-100 text-blue-500',
      success: 'bg-green-100 text-green-500',
      warning: 'bg-orange-100 text-orange-400',
      error: 'bg-red-100 text-red-500',
    };
    return iconClasses[type];
  };

  const getAnimation = (position: ToastPosition): string => {
    const animationVariables: Record<ToastPosition, string> = {
      topRight: 'translateX(2000px)',
      topCenter: 'translateY(-1300px)',
      topLeft: 'translateX(-2000px)',
      bottomLeft: 'translateX(-2000px)',
      bottomCenter: 'translateY(1300px)',
      bottomRight: 'translateX(2000px)',
    };
    return animationVariables[position];
  };
  // #endregion

  // #region init
  let { type = 'info', icon = '', message = '---', id, duration = 5000 } = props;
  icon = icon === '' ? getIcon(type) : icon;
  duration = typeof duration === 'string' ? +duration : duration;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { remove, position } = useToast();
  // #endregion

  // #region auto dismiss
  const dismissRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (duration) {
      dismissRef.current = setTimeout(() => {
        remove(id, wrapperRef);
      }, duration);
    }

    return (): void => {
      clearTimeout(dismissRef.current);
    };
  }, []);
  // #endregion

  // #region progress bar
  const progressBarRef = useRef<ReturnType<typeof setInterval>>();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const complete = 100;

    if (duration) {
      progressBarRef.current = setInterval(() => {
        if (progress < complete) {
          setProgress((prev: number) => prev + 1);
        } else {
          return;
        }
      }, duration / complete);
    }

    return (): void => {
      clearInterval(progressBarRef.current);
    };
  }, []);
  // #endregion

  // #region render
  return (
    <div
      style={{ ['--elm-translate' as any]: getAnimation(position) }}
      className={clsx(
        backgroundStyles(type),
        'animate-toastIn',
        'relative my-3 flex items-center justify-between overflow-hidden rounded-md shadow-lg'
      )}
      ref={wrapperRef}
      role={'alert'}
    >
      {!!duration && (
        <div className="absolute bottom-0 right-0 left-0 h-1 w-full bg-neutral-100 dark:bg-neutral-500">
          <span
            className="absolute left-0 top-0 bottom-0 h-full bg-neutral-200"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}

      {icon && (
        <div className={clsx(iconStyles(type), 'flex p-3')}>
          <div className="inline-flex h-10 w-6 items-center justify-center">
            <span className="sr-only">{type} Icon</span>
            {icon}
          </div>
        </div>
      )}
      <div className="flex-grow p-3 text-sm font-semibold">{message}</div>
      <Close
        onClick={(): void => {
          remove(id, wrapperRef);
        }}
        className="mx-3 inline-flex h-4 w-4 items-center justify-center rounded-md text-gray-400 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:text-gray-200 dark:hover:text-white"
      />
    </div>
  );
  // #endregion
};

export default Toast;
