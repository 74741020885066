import Axios from './clients/Axios/Axios';
function postDevice(data, cb, ecb) {
  return Axios.post(`/api/v2/devices/`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function deleteDevice(deviceNumber, cb, ecb) {
  return Axios.delete(`/api/devices/${deviceNumber}/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceConfigClient = {
  postDevice,
  deleteDevice,
};

export default DeviceConfigClient;
