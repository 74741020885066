import Axios from './clients/Axios/Axios';

function getDeviceCountersStatisticsFlow(
  deviceNumber,
  sensorIndex,
  startDate,
  endDate,
  every,
  cb,
  ecb
) {
  return Axios.get(
    `/api/devices/${deviceNumber}/${sensorIndex}/counters/statistics/flow/?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDeviceCountersStatisticsQFlow(
  deviceNumber,
  sensorIndex,
  startDate,
  endDate,
  every,
  cb,
  ecb
) {
  return Axios.get(
    `/api/devices/${deviceNumber}/${sensorIndex}/counters/statistics/qflow?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDeviceCountersStatisticsGeneral(
  deviceNumber,
  sensorIndex,
  startDate,
  endDate,
  every,
  cb,
  ecb
) {
  return Axios.get(
    `/api/devices/${deviceNumber}/${sensorIndex}/counters/statistics/general/?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDeviceCountersStatisticsTime(deviceNumber, sensorIndex, startDate, endDate, cb, ecb) {
  return Axios.get(
    `/api/devices/${deviceNumber}/${sensorIndex}/counters/statistics/time/?fromDate=${startDate}&toDate=${endDate}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDevicesCountersStatisticsGeneral(
  deviceNumbers,
  sensorIndex,
  startDate,
  endDate,
  every,
  cb,
  ecb
) {
  return Axios.get(
    `/api/devices/[${deviceNumbers}]/${sensorIndex}/counters/statistics/general/?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDevicesCountersStatisticsFlow(
  deviceNumbers,
  sensorIndex,
  startDate,
  endDate,
  every,
  cb,
  ecb
) {
  return Axios.get(
    `/api/devices/[${deviceNumbers}]/${sensorIndex}/counters/statistics/flow/?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDevicesCountersStatisticsQFlow(
  deviceNumbers,
  sensorIndex,
  startDate,
  endDate,
  every,
  cb,
  ecb
) {
  return Axios.get(
    `/api/devices/[${deviceNumbers}]/${sensorIndex}/counters/statistics/qflow?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDevicesCountersStatisticsTime(deviceNumbers, sensorIndex, startDate, endDate, cb, ecb) {
  return Axios.get(
    `/api/devices/[${deviceNumbers}]/${sensorIndex}/counters/statistics/time/?fromDate=${startDate}&toDate=${endDate}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceCountersStatisticsClient = {
  getDeviceCountersStatisticsFlow,
  getDeviceCountersStatisticsQFlow,
  getDeviceCountersStatisticsGeneral,
  getDeviceCountersStatisticsTime,
  getDevicesCountersStatisticsGeneral,
  getDevicesCountersStatisticsFlow,
  getDevicesCountersStatisticsQFlow,
  getDevicesCountersStatisticsTime,
};

export default DeviceCountersStatisticsClient;
