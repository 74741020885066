import Axios from './clients/Axios/Axios';

function getUser(username, cb, ecb) {
  return Axios.get(`/api/users/${username}`).then(parseJSON).then(cb).catch(ecb);
}

function getUsers(params, cb, ecb) {
  return Axios.get(`/api/users`, {
    params: params,
  })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function putPasswordChange(currentPassword, newPassword, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };
  return Axios.put(`/api/password`, body, config).then(parseJSON).then(cb).catch(ecb);
}

function setNewPassword(confirmationToken, newPassword, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    newPassword: newPassword,
    confirmationToken: confirmationToken,
  };
  return Axios.put(`/api/set-new-password`, body, config).then(parseJSON).then(cb).catch(ecb);
}

function putResetPassword(email, recaptchaToken, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    email: email,
    recaptchaToken: recaptchaToken,
  };
  return Axios.put(`/api/reset-password`, body, config).then(parseJSON).then(cb).catch(ecb);
}

function putUser(username, user, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/users/${username}`, user, config).then(parseJSON).then(cb).catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const UserClient = {
  putPasswordChange,
  getUser,
  getUsers,
  putUser,
  putResetPassword,
  setNewPassword,
};

export default UserClient;
