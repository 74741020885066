import {
  ConfPeriod,
  Device,
  DeviceConfig,
  DeviceGroupDto,
  DeviceSensorDto,
  Port,
  PortsConfig,
} from '@thingslog/repositories';
import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { RecordPeriod } from '../../model/RecordPeriod/RecordPeriod';
import { lineColors } from './GraphColors';
import { LineColors } from './model/LineChartColors';
import DeviceSensorGraphData from './model/DeviceSensorGraphData';
import { addDays, addMonths, addWeeks, addYears, getDaysInMonth, formatDuration } from 'date-fns';
import { Period } from './model/Period';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import { t } from 'i18next';
import _ from 'lodash';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import { EverySelector, useModal } from '@thingslog/ui-components';
import { convertSecondsToDuration } from './utils/PeriodFormatterUtils';
import AddDeviceGroupToGraphModal from './AddDeviceGroupToGraphModal';
import { GRAPH_SENSORS_LIMIT } from './GraphConstants';
import { LoadingButton } from '@mui/lab';

const DeviceSensorGraphSelector: FC<DeviceSensorGraphSelectorProps> = ({
  deviceGroups,
  selectedDeviceGroup,
  onSelectedDeviceGroupChange,
  isDeviceGroupsFromParentFetching,
  deviceGroupsFromParent,
  selectableDevices,
  selectedDevice,
  onSelectedDeviceChange,
  devicesConfigs,
  portsConfigs,
  deviceSensorGraphData,
  every,
  onEveryChange,
  onDeviceSensorGraphDataChange,
  onAveragePeriodChange,
  onPredictionPeriodChange,
  onRefreshButtonClick,
  onAutoRefreshIntervalMillisecondsChange,
  autoRefreshIntervalMilliseconds,
  fromDate,
  toDate,
  onModalOpened,
  isDevicesConfigsLoading,
  isPortsConfigsLoading,
}: DeviceSensorGraphSelectorProps) => {
  const [selectablePortsConfigs, setSelectablePortsConfigs] = useState<PortsConfig>({});
  const [selectedPortConfig, setSelectedPortConfig] = useState<{
    sensorIndex: number;
    port: Port;
  } | null>(null);
  const [confPeriod, setConfPeriod] = useState<ConfPeriod | null>(null);
  const [averagePeriod, setAveragePeriod] = useState<Period | null>(null);
  const [predictionPeriod, setPredictionPeriod] = useState<Period | null>(null);
  const [updateIsLoadingHandler, setUpdateIsLoadingHandler] = useState<
    ((isLoading: boolean) => void) | null
  >(null);
  const [updateDeviceConfigsHandler, setUpdateDeviceConfigsHandler] = useState<
    ((deviceConfigs: Record<string, DeviceConfig> | null) => void) | null
  >(null);
  const [updatePortConfigsHandler, setUpdatePortConfigsHandler] = useState<
    ((portConfigs: Record<string, PortsConfig> | null) => void) | null
  >(null);

  const { modal } = useModal();

  const autoRefreshOptionInSeconds = [
    5 * 60,
    15 * 60,
    30 * 60,
    60 * 60,
    2 * 60 * 60,
    3 * 60 * 60,
    6 * 60 * 60,
    12 * 60 * 60,
  ];

  const selectedDevicePortConfig = useMemo(() => {
    if (selectedDevice && portsConfigs && portsConfigs[selectedDevice.number]) {
      return portsConfigs[selectedDevice.number];
    } else {
      return null;
    }
  }, [selectedDevice, portsConfigs]);

  const selectedDeviceConfig = useMemo(() => {
    if (selectedDevice && devicesConfigs && devicesConfigs[selectedDevice.number]) {
      return devicesConfigs[selectedDevice.number];
    } else {
      return null;
    }
  }, [selectedDevice, devicesConfigs]);

  const deviceSensorsFromParentDeviceGroup = useMemo(() => {
    return _.flatMap(
      deviceGroupsFromParent,
      (deviceGroup: DeviceGroupDto) => deviceGroup.deviceSensors || []
    );
  }, [deviceGroupsFromParent]);

  const isAddDeviceGroupAllowed = useMemo(() => {
    return selectedDeviceGroup !== null && selectedDevice === null;
  }, [selectedDeviceGroup, selectedDevice]);

  const isAddDeviceSensorAllowed = useMemo(() => {
    return (
      selectedDevice !== null &&
      selectedDeviceConfig !== null &&
      selectedPortConfig !== null &&
      every !== null
    );
  }, [selectedDevice, selectedDeviceConfig, selectedPortConfig, every]);

  const isAddButtonDisabled = useMemo(() => {
    if (deviceSensorGraphData.length >= GRAPH_SENSORS_LIMIT) return true;

    if (isAddDeviceGroupAllowed || isAddDeviceSensorAllowed) return false;

    return true;
  }, [deviceSensorGraphData, isAddDeviceGroupAllowed, isAddDeviceSensorAllowed]);

  const handleIsLoadingChangeCallback = useCallback(
    (handleIsLoadingChange: (isLoading: boolean) => void) => {
      setUpdateIsLoadingHandler(() => handleIsLoadingChange);
    },
    []
  );

  const handleDeviceConfigsChangeCallback = useCallback(
    (handleDeviceConfigsChange: (deviceConfigs: Record<string, DeviceConfig> | null) => void) => {
      setUpdateDeviceConfigsHandler(() => handleDeviceConfigsChange);
    },
    []
  );
  const handlePortConfigsChangeCallback = useCallback(
    (handlePortConfigsChange: (portConfigs: Record<string, PortsConfig> | null) => void) => {
      setUpdatePortConfigsHandler(() => handlePortConfigsChange);
    },
    []
  );

  const isSensorAlreadyAdded = useCallback(
    (sensorIndex: number): boolean => {
      if (selectedDevice) {
        return deviceSensorGraphData
          .filter((data: DeviceSensorGraphData) => data.deviceNumber === selectedDevice.number)
          .some((data: DeviceSensorGraphData) => data.sensorIndex === Number(sensorIndex));
      } else return true;
    },
    [deviceSensorGraphData, selectedDevice]
  );

  useEffect(() => {
    if (updateIsLoadingHandler !== null) {
      updateIsLoadingHandler(isDevicesConfigsLoading || isPortsConfigsLoading);
    }
  }, [isDevicesConfigsLoading, isPortsConfigsLoading]);

  useEffect(() => {
    if (updateDeviceConfigsHandler !== null) {
      updateDeviceConfigsHandler(devicesConfigs);
    }
  }, [devicesConfigs]);

  useEffect(() => {
    if (updatePortConfigsHandler !== null) {
      updatePortConfigsHandler(portsConfigs);
    }
  }, [portsConfigs]);

  useEffect(() => {
    let confPeriod: ConfPeriod | null = null;
    if (selectedDeviceConfig?.recordPeriod === RecordPeriod.DAYS) confPeriod = 'DAYS';
    if (selectedDeviceConfig?.recordPeriod === RecordPeriod.HOURS) confPeriod = 'HOURS';
    if (selectedDeviceConfig?.recordPeriod === RecordPeriod.MINUTES) confPeriod = 'MINUTES';
    if (selectedDeviceConfig?.recordPeriod === RecordPeriod.SECONDS) confPeriod = 'SECONDS';

    setConfPeriod(confPeriod);
  }, [selectedDeviceConfig]);

  useEffect(() => {
    onSelectedDeviceChange(null);
  }, [selectableDevices]);

  useEffect(() => {
    setSelectedPortConfig(null);
    let selectablePortsFromConfig: PortsConfig = {};

    if (selectedDevice && selectedDevicePortConfig) {
      onEveryChange(1);
      if (selectedDeviceGroup) {
        const sensorIndexesFromDeviceGroup: number[] = deviceSensorsFromParentDeviceGroup
          .filter(
            (deviceSensor: DeviceSensorDto) => deviceSensor.deviceNumber === selectedDevice.number
          )
          .map((deviceSensor: DeviceSensorDto) => deviceSensor.sensorIndex);
        Object.entries(selectedDevicePortConfig)
          .filter(
            ([sensorIndex, port]: [string, Port]) =>
              sensorIndexesFromDeviceGroup.includes(parseInt(sensorIndex)) &&
              port.enabled === true &&
              port['@type'] !== 'on_off_output_port'
          )
          .forEach(([sensorIndex, port]: [string, Port]) => {
            selectablePortsFromConfig[sensorIndex] = port;
          });
      } else {
        Object.entries(selectedDevicePortConfig)
          .filter(
            ([sensorIndex, port]: [string, Port]) =>
              port.enabled === true && port['@type'] !== 'on_off_output_port'
          )
          .forEach(([sensorIndex, port]: [string, Port]) => {
            selectablePortsFromConfig[sensorIndex] = port;
          });
      }
    } else {
      onEveryChange(null);
    }

    setSelectablePortsConfigs(selectablePortsFromConfig);
  }, [selectedDevice, selectedDevicePortConfig]);

  useEffect(() => {
    switch (averagePeriod) {
      case null:
        onAveragePeriodChange(null, null);
        break;
      case Period.DAY:
        onAveragePeriodChange(addDays(fromDate, -1), fromDate);
        break;
      case Period.WEEK:
        onAveragePeriodChange(addWeeks(fromDate, -1), fromDate);
        break;
      case Period.MONTH:
        onAveragePeriodChange(addMonths(fromDate, -1), fromDate);
        break;
      case Period.YEAR:
        onAveragePeriodChange(addYears(fromDate, -1), fromDate);
        break;
    }
  }, [averagePeriod, fromDate]);

  useEffect(() => {
    switch (predictionPeriod) {
      case null:
        onPredictionPeriodChange(null);
        break;
      case Period.DAY:
        onPredictionPeriodChange(1);
        break;
      case Period.WEEK:
        onPredictionPeriodChange(7);
        break;
      case Period.MONTH:
        const daysInMonth = getDaysInMonth(toDate);
        onPredictionPeriodChange(daysInMonth);
        break;
    }
  }, [predictionPeriod, toDate]);

  const onAddButtonClick = (): void => {
    if (isAddDeviceGroupAllowed) {
      modal({
        title: t('new_graph_add_device_group_modal_title'),
        content: (
          <AddDeviceGroupToGraphModal
            deviceGroupName={selectedDeviceGroup!.deviceGroupName}
            deviceSensorsFromParentDeviceGroup={deviceSensorsFromParentDeviceGroup}
            devices={selectableDevices}
            deviceSensorGraphData={deviceSensorGraphData}
            onAddDeviceGroupToGraphData={(deviceGroupSensors: DeviceSensorGraphData[]): void => {
              onDeviceSensorGraphDataChange([...deviceSensorGraphData, ...deviceGroupSensors]);
              onSelectedDeviceGroupChange(null);
            }}
            onModalOpened={onModalOpened}
            isLoadingChangeCallback={handleIsLoadingChangeCallback}
            deviceConfigsChangeCallback={handleDeviceConfigsChangeCallback}
            portConfigsChangeCallback={handlePortConfigsChangeCallback}
          />
        ),
      });
    } else if (isAddDeviceSensorAllowed) {
      const color = LineColors[deviceSensorGraphData.length % Object.keys(lineColors).length];

      const currentDeviceSensorData: DeviceSensorGraphData = {
        deviceNumber: selectedDevice!.number,
        sensorIndex: selectedPortConfig!.sensorIndex,
        device: selectedDevice!,
        port: selectedPortConfig!.port,
        deviceConfig: selectedDeviceConfig!,
        graphLineColor: lineColors[LineColors[color]],
        flowData: [],
        averageFlowData: [],
        predictionData: [],
        every: every,
      };

      onDeviceSensorGraphDataChange([...deviceSensorGraphData, currentDeviceSensorData]);
      onSelectedDeviceGroupChange(null);
      onSelectedDeviceChange(null);
      setSelectedPortConfig(null);
      onEveryChange(null);
    }
  };

  return (
    <>
      <div className="text-lg font-bold text-left mb-3">{t('new_graph_select_sensor_heading')}</div>
      <div className="flex gap-x-2 gap-y-3 flex-wrap mb-5">
        <FormControl size="small" className="w-72 max-md:w-full">
          <InputLabel>{t('new_graph_device_group_label')}</InputLabel>
          <Select
            label={t('new_graph_device_group_label')}
            value={selectedDeviceGroup === null ? 'None' : selectedDeviceGroup.deviceGroupName}
            onChange={(event: SelectChangeEvent<string>): void => {
              const deviceGroup = deviceGroups.find(
                (deviceGroup: DeviceGroupDto) => deviceGroup.deviceGroupName === event.target.value
              );
              onSelectedDeviceGroupChange(deviceGroup ? deviceGroup : null);
            }}
          >
            <MenuItem value="None">{t('new_graph_none_option')}</MenuItem>
            {deviceGroups.map((deviceGroup: DeviceGroupDto) => {
              return (
                <MenuItem key={deviceGroup.deviceGroupName} value={deviceGroup.deviceGroupName}>
                  {deviceGroup.deviceGroupName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="flex gap-x-2 max-md:w-full">
          <FormControl size="small" className="w-72 max-md:w-1/2">
            <InputLabel>{t('new_graph_device_label')}</InputLabel>
            <Select
              label={t('new_graph_device_label')}
              disabled={isDeviceGroupsFromParentFetching}
              value={selectedDevice === null ? 'None' : selectedDevice.number}
              onChange={(event: SelectChangeEvent<string>): void => {
                const device = selectableDevices.find(
                  (device: Device) => device.number === event.target.value
                );
                onSelectedDeviceChange(device ? device : null);
              }}
            >
              <MenuItem value="None">{t('new_graph_none_option')}</MenuItem>
              {selectableDevices.map((device: Device) => {
                return (
                  <MenuItem key={device.number} value={device.number}>
                    {device.name ? `${device.name} (${device.number})` : device.number}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl size="small" className="w-72 max-md:w-1/2">
            <InputLabel>{t('new_graph_sensor_label')}</InputLabel>
            <Select
              disabled={!selectedDevice}
              label={t('new_graph_sensor_label')}
              value={
                selectedPortConfig === null ? 'None' : selectedPortConfig.sensorIndex.toString()
              }
              onChange={(event: SelectChangeEvent<string>): void => {
                if (event.target.value === 'None') {
                  setSelectedPortConfig(null);
                } else {
                  const selectedSensorIndex = Object.keys(selectablePortsConfigs).find(
                    (sensorIndex: string) => {
                      return sensorIndex === event.target.value;
                    }
                  );

                  setSelectedPortConfig(
                    selectedSensorIndex
                      ? {
                          sensorIndex: parseInt(selectedSensorIndex),
                          port: selectablePortsConfigs[selectedSensorIndex],
                        }
                      : null
                  );
                }
              }}
            >
              <MenuItem value="None">{t('new_graph_none_option')}</MenuItem>
              {Object.entries(selectablePortsConfigs).length > 0 &&
                Object.entries(selectablePortsConfigs).map(
                  ([sensorIndex, port]: [string, Port]) => {
                    return (
                      <MenuItem
                        disabled={isSensorAlreadyAdded(Number(sensorIndex))}
                        key={sensorIndex}
                        value={sensorIndex}
                      >
                        {port.sensor.name}
                      </MenuItem>
                    );
                  }
                )}
            </Select>
          </FormControl>
        </div>
        {every !== null &&
          confPeriod &&
          selectedDeviceConfig &&
          selectedPortConfig &&
          selectedPortConfig.port['@type'] !== 'on_off_input_port' &&
          selectedPortConfig.port['@type'] !== 'on_off_output_port' && (
            <FormControl size="small" className="w-72 max-md:w-full">
              <EverySelector
                confEvery={selectedDeviceConfig.every}
                confPeriod={confPeriod}
                every={every}
                onEveryChange={onEveryChange}
                translation={{
                  day: t('day'),
                  days: t('days'),
                  hour: t('hour'),
                  hours: t('hours'),
                  minute: t('minute'),
                  minutes: t('minutes'),
                  second: t('second'),
                  seconds: t('seconds'),
                  label: t('every'),
                }}
              />
            </FormControl>
          )}
        <LoadingButton
          className="min-w-0 w-11 max-md:w-full"
          loading={isDeviceGroupsFromParentFetching}
          disabled={isAddButtonDisabled}
          variant="contained"
          onClick={onAddButtonClick}
        >
          <AddIcon />
        </LoadingButton>
      </div>
      <div className="text-lg font-bold text-left">{t('new_graph_date_range_selection')}</div>
      <div className="flex flex-wrap gap-x-5 gap-y-3 items-center align-middle">
        <div className="flex flex-col justify-start items-start max-md:w-full">
          <span className="mb-3 text-sm font-bold">{t('new_graph_period_picker_heading')}</span>
          <div className="flex flex-wrap w-full gap-x-10 gap-y-3">
            <Grid container gap={2}>
              <PeriodPicker datePickerClassName="flex-grow max-md:w-min" />
            </Grid>
          </div>
        </div>
        <div className="flex gap-5 max-md:w-full">
          <Divider orientation="vertical" flexItem className="h-9 mt-9 max-md:hidden" />
          <div className="flex flex-col justify-start items-start max-md:w-1/2">
            <span className="mb-3 text-sm font-bold">{t('new_graph_average_days_heading')}</span>
            <FormControl size="small" className="w-64 max-md:w-full">
              <InputLabel>{t('new_graph_average_days_label')}</InputLabel>
              <Select
                label={t('new_graph_average_days_label')}
                value={averagePeriod !== null ? averagePeriod : 'None'}
                onChange={(event: SelectChangeEvent<Period | 'None'>): void =>
                  setAveragePeriod(
                    event.target.value !== 'None' ? (event.target.value as Period) : null
                  )
                }
              >
                <MenuItem value="None">{t('new_graph_none_option')}</MenuItem>
                <MenuItem value={Period.DAY}>{t('new_graph_day_option', { count: 1 })}</MenuItem>
                <MenuItem value={Period.WEEK}>{t('new_graph_week_option', { count: 1 })}</MenuItem>
                <MenuItem value={Period.MONTH}>
                  {t('new_graph_month_option', { count: 1 })}
                </MenuItem>
                <MenuItem value={Period.YEAR}>{t('new_graph_year_option', { count: 1 })}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation="vertical" flexItem className="h-9 mt-9 max-md:hidden" />
          <div className="flex flex-col justify-start items-start max-md:w-1/2">
            <span className="mb-3 text-sm font-bold">
              {t('new_graph_time_series_prediction_period_heading')}
            </span>
            <FormControl size="small" className="w-64 max-md:w-full">
              <InputLabel>{t('new_graph_time_series_prediction_period_label')}</InputLabel>
              <Select
                label={t('new_graph_time_series_prediction_period_label')}
                value={predictionPeriod !== null ? predictionPeriod : 'None'}
                onChange={(event: SelectChangeEvent<Period | 'None'>): void =>
                  setPredictionPeriod(
                    event.target.value !== 'None' ? (event.target.value as Period) : null
                  )
                }
              >
                <MenuItem value="None">{t('new_graph_none_option')}</MenuItem>
                <MenuItem value={Period.DAY}>{t('new_graph_day_option', { count: 1 })}</MenuItem>
                <MenuItem value={Period.WEEK}>{t('new_graph_week_option', { count: 1 })}</MenuItem>
                <MenuItem value={Period.MONTH}>
                  {t('new_graph_month_option', { count: 1 })}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Divider orientation="vertical" flexItem className="h-9 mt-9 max-md:hidden" />
        <div className="flex self-end max-md:w-full">
          <FormControl size="small" className="w-full">
            <InputLabel>{t('new_graph_auto_refresh_label')}</InputLabel>
            <Select
              label={t('new_graph_auto_refresh_label')}
              value={
                autoRefreshIntervalMilliseconds !== null ? autoRefreshIntervalMilliseconds : 'Off'
              }
              onChange={(event: SelectChangeEvent<number | 'Off'>): void =>
                onAutoRefreshIntervalMillisecondsChange(
                  event.target.value !== 'Off' ? Number(event.target.value) : null
                )
              }
              startAdornment={
                <InputAdornment position="start">
                  <IconButton className="-ml-4" onClick={onRefreshButtonClick}>
                    <CachedIcon />
                  </IconButton>
                  <Divider className="h-10" orientation="vertical" />
                </InputAdornment>
              }
            >
              <MenuItem value="Off">{t('off')}</MenuItem>
              {autoRefreshOptionInSeconds.map((optionInSeconds: number) => (
                <MenuItem key={optionInSeconds} value={optionInSeconds * 1000}>
                  {formatDuration(convertSecondsToDuration(optionInSeconds))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

interface DeviceSensorGraphSelectorProps {
  deviceGroups: DeviceGroupDto[];
  selectedDeviceGroup: DeviceGroupDto | null;
  onSelectedDeviceGroupChange: (deviceGroup: DeviceGroupDto | null) => void;
  isDeviceGroupsFromParentFetching: boolean;
  deviceGroupsFromParent: DeviceGroupDto[];
  selectableDevices: Device[];
  selectedDevice: Device | null;
  onSelectedDeviceChange: (device: Device | null) => void;
  devicesConfigs: Record<string, DeviceConfig> | null;
  portsConfigs: Record<string, PortsConfig> | null;
  deviceSensorGraphData: DeviceSensorGraphData[];
  every: number | null;
  onEveryChange: (every: number | null) => void;
  onDeviceSensorGraphDataChange: (deviceSensorGraphData: DeviceSensorGraphData[]) => void;
  onAveragePeriodChange: (fromAverage: Date | null, toAverage: Date | null) => void;
  onPredictionPeriodChange: (forwardDays: number | null) => void;
  onRefreshButtonClick: () => void;
  onAutoRefreshIntervalMillisecondsChange: (autoRefreshIntervalMilliseconds: number | null) => void;
  autoRefreshIntervalMilliseconds: number | null;
  fromDate: Date;
  toDate: Date;
  isAddDeviceGroupModalOpen: boolean;
  onModalOpened: () => void;
  isDevicesConfigsLoading: boolean;
  isPortsConfigsLoading: boolean;
}

export default DeviceSensorGraphSelector;
