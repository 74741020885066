import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteSensorModal from './modals/DeleteSensorModal';
import AddSensorModal from './modals/AddSensorModal';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../reducers';
import {
  DeviceGroupDto,
  DeviceSensorDto,
  MutateDeviceSensor,
  PortsConfig,
} from '@thingslog/repositories';

//Component
const SensorsTable: FC<SensorsTableProps> = ({
  selectedDeviceGroup,
  selectedDeviceNumber,
  onSelectedDeviceChange,
  selectedDevicePorts,
  isOpenedDeleteSensorModal,
  isOpenedAddSensorModal,
  onAddSensorModalStateChange,
  onDeleteSensorModalStateChange,
  addDeviceSensorToGroup,
  deleteDeviceAndSensorFromGroup,
}: SensorsTableProps) => {
  const { t } = useTranslation();

  const devices = useSelector((state: ReduxState) => state.dev.devicesArray);

  //Sensors by group states
  const [sensorsByGroupPage, setSensorsByGroupPage] = useState<number>(0);
  const [sensorsByGroupPageSize, setSensorsByGroupPageSize] = useState<number>(15);
  const [displayedSensorsByGroup, setDisplayedSensorsByGroup] = useState<DeviceSensorDto[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<DeviceSensorDto>({
    deviceNumber: '',
    sensorIndex: 0,
    sensorName: '',
  });

  //Devices by group table related functions
  const paginateSensorsByGroup = (
    array: DeviceSensorDto[] | undefined,
    page: number,
    size: number
  ): DeviceSensorDto[] => {
    if (array) {
      return array.slice(page * size).slice(0, size);
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (selectedDeviceGroup && selectedDeviceGroup.deviceSensors) {
      setDisplayedSensorsByGroup(
        paginateSensorsByGroup(
          selectedDeviceGroup.deviceSensors,
          sensorsByGroupPage,
          sensorsByGroupPageSize
        )
      );
    } else {
      setDisplayedSensorsByGroup([]);
    }
  }, [selectedDeviceGroup, sensorsByGroupPage, sensorsByGroupPageSize]);

  return (
    <>
      <TableContainer className="ml-5">
        <Table size="small" stickyHeader>
          <TableHead className="h-16">
            <TableRow>
              <TableCell className="font-extrabold text-base bg-primary-main" size="small">
                {t('device_groups_sensor_table_device_number_header')}
              </TableCell>
              <TableCell className="font-extrabold text-base bg-primary-main" size="small">
                {t('device_groups_sensor_table_sensor_name_header')}
              </TableCell>
              <TableCell className="font-extrabold text-base bg-primary-main" size="small">
                {t('device_groups_sensor_table_sensor_index_header')}
              </TableCell>
              {selectedDeviceGroup && (
                <TableCell className="flex justify-end bg-primary-main" size="small" align="center">
                  <Tooltip title={<span>{t('device_groups_btn_add')}</span>} arrow placement="top">
                    <IconButton
                      aria-label="Add"
                      size="large"
                      onClick={(): void => onAddSensorModalStateChange(true)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedSensorsByGroup.map((sensorDeviceGroup: DeviceSensorDto) => {
              return (
                <TableRow hover>
                  <TableCell size="small">
                    <Tooltip title={sensorDeviceGroup.deviceNumber} arrow placement="top">
                      <Typography variant="subtitle1">{sensorDeviceGroup.deviceNumber}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small">
                    <Tooltip title={sensorDeviceGroup.sensorName} arrow placement="top">
                      <Typography variant="subtitle1">{sensorDeviceGroup.sensorName}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Tooltip title={sensorDeviceGroup.sensorIndex} arrow placement="top">
                      <Typography variant="subtitle1">{sensorDeviceGroup.sensorIndex}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small" align="right">
                    <Tooltip
                      title={<span>{t('device_groups_btn_delete')}</span>}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        aria-label="Delete"
                        size="large"
                        onClick={(): void => {
                          onDeleteSensorModalStateChange(true);
                          setSelectedSensor(sensorDeviceGroup);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {selectedDeviceGroup === null && (
          <Typography className="my-20" variant="h5">
            {t('device_groups_sensor_table_select_message')}
          </Typography>
        )}
        <TablePagination
          style={{ marginRight: 35 }}
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          component="div"
          count={
            selectedDeviceGroup && selectedDeviceGroup.deviceSensors
              ? selectedDeviceGroup.deviceSensors.length
              : 0
          }
          rowsPerPage={sensorsByGroupPageSize}
          page={sensorsByGroupPage}
          onPageChange={(event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
            setSensorsByGroupPage(page);
          }}
          onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setSensorsByGroupPage(0);
            setSensorsByGroupPageSize(Number(event.target.value));
          }}
        />
      </TableContainer>
      <DeleteSensorModal
        selectedDeviceGroupId={selectedDeviceGroup?.deviceGroupId || null}
        isOpenedDeleteSensorModal={isOpenedDeleteSensorModal}
        selectedSensor={selectedSensor}
        handleOnCloseModal={(): void => onDeleteSensorModalStateChange(false)}
        handleOnDeleteDeviceAndSensorFromGroup={deleteDeviceAndSensorFromGroup}
      />
      <AddSensorModal
        isOpenedAddSensorModal={isOpenedAddSensorModal}
        selectedDeviceGroupId={selectedDeviceGroup?.deviceGroupId || null}
        devices={devices}
        selectedDeviceNumber={selectedDeviceNumber}
        onSelectedDeviceChange={onSelectedDeviceChange}
        selectedDevicePorts={selectedDevicePorts}
        handleOnCloseModal={(): void => onAddSensorModalStateChange(false)}
        handleOnCreateSensorDeviceToGroup={addDeviceSensorToGroup}
      />
    </>
  );
};

interface SensorsTableProps {
  companyId: number;
  selectedDeviceNumber: string | null;
  onSelectedDeviceChange: (deviceNumber: string | null) => void;
  selectedDeviceGroup: DeviceGroupDto | null;
  selectedDevicePorts: PortsConfig;
  isOpenedAddSensorModal: boolean;
  onAddSensorModalStateChange: (isOpen: boolean) => void;
  addDeviceSensorToGroup: (deviceGroupId: number, body: MutateDeviceSensor) => void;
  isOpenedDeleteSensorModal: boolean;
  onDeleteSensorModalStateChange: (isOpen: boolean) => void;
  deleteDeviceAndSensorFromGroup: (
    deviceGroupId: number,
    deviceNumber: string,
    sensorIndex: number
  ) => void;
}

export default SensorsTable;
