import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { BatteryStatus } from '@thingslog/repositories/src/battery/BatteryStatus';
import { DeviceBattery } from '@thingslog/repositories/src/battery/DeviceBattery';

export interface DevicesBatteriesQueryClient {
  getBatteryStatus: (
    fromLevel: number,
    toLevel: number,
    companyId: number | null
  ) => Promise<BatteryStatus[]>;
  useBatteryStatus: (
    companyId: number | null,
    fromLevel?: number,
    toLevel?: number,
    options?: QueryOptions<BatteryStatus[]>
  ) => UseQueryResult<BatteryStatus[], AxiosError>;
  getDeviceBattery: (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date
  ) => Promise<DeviceBattery[]>;
  useDeviceBattery: (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date,
    options?: QueryOptions<DeviceBattery[]>
  ) => UseQueryResult<DeviceBattery[], AxiosError>;
}

export function createDevicesBatteryQueryClient(axios: AxiosInstance): DevicesBatteriesQueryClient {
  return new DevicesBatteriesQueryClientImp(axios);
}

class DevicesBatteriesQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getBatteryStatus = async (
    fromLevel: number,
    toLevel: number,
    companyId: number | null
  ): Promise<BatteryStatus[]> => {
    return this.axios
      .get('/api/devices/battery', {
        params: { fromLevel: fromLevel, toLevel: toLevel, companyId: companyId },
      })
      .then((response: AxiosResponse<BatteryStatus[]>) => {
        return response.data;
      });
  };

  public useBatteryStatus = (
    companyId: number | null,
    fromLevel: number = 0,
    toLevel: number = 100,
    options?: QueryOptions<BatteryStatus[]>
  ): UseQueryResult<BatteryStatus[], AxiosError> => {
    return useQuery<BatteryStatus[], AxiosError>(
      [QueryKeys.GetBatteryStatus, companyId, fromLevel, toLevel],
      () => this.getBatteryStatus(fromLevel, toLevel, companyId),
      options
    );
  };

  public getDeviceBattery = async (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date
  ): Promise<DeviceBattery[]> => {
    return this.axios
      .get(`/api/devices/${deviceNumber}/battery`, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      })
      .then((response: AxiosResponse<DeviceBattery[]>) => {
        return response.data;
      });
  };

  public useDeviceBattery = (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date,
    options?: QueryOptions<DeviceBattery[]>
  ): UseQueryResult<DeviceBattery[], AxiosError> => {
    return useQuery<DeviceBattery[], AxiosError>(
      [QueryKeys.GetDeviceBattery, deviceNumber, fromDate, toDate],
      () => this.getDeviceBattery(deviceNumber, fromDate, toDate),
      options
    );
  };
}
