import React, { useReducer } from 'react';
import CommandPageContext, {
  commandPageReducer,
  initialCommandPageState,
} from './CommandPageContext';

const CommandPageContextProvider: React.FunctionComponent<ICommandPageContextProviderProps> = (
  props: ICommandPageContextProviderProps
) => {
  const [commandPageState, commandPageDispatch] = useReducer(
    commandPageReducer,
    initialCommandPageState
  );

  const commandContextValue = {
    commandPageState,
    commandPageDispatch,
  };

  return (
    <CommandPageContext.Provider value={commandContextValue}>
      {props.children}
    </CommandPageContext.Provider>
  );
};

interface ICommandPageContextProviderProps {
  children: React.ReactNode;
}

export default CommandPageContextProvider;
