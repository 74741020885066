import React, { BaseSyntheticEvent, FC, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { LocalizedDatePicker, useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import { addMonths, addYears, isAfter } from 'date-fns';
import {
  LicenseDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseRequest,
  LicenseType,
  CompanyLicenseDto,
} from '@thingslog/repositories';
import LicenseMaxCount from '../../companyManager/components/LicenseMaxCountTextField';
import LicenseValidator from '../../../common/LicenseValidator';

const RenewLicenseModal: FC<RenewLicenseModalProps> = ({
  licenses,
  license,
  addLicense,
}: RenewLicenseModalProps) => {
  const { t } = useTranslation();

  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(
    (license as CompanyLicenseDto)['@type'] === LicenseType.PER_POC
      ? addMonths(new Date(), 6)
      : addYears(new Date(), 1)
  );
  const [maxReadings, setMaxReadings] = useState<number>(1);
  const [maxDataLoggers, setMaxDataLoggers] = useState<number>(1);
  const [maxSensors, setMaxSensors] = useState<number>(1);
  const [hasError, setHasError] = useState<boolean>(false);
  const [conflictingLicenseDetected, setConflictingLicenseDetected] = useState<boolean>(false);

  useEffect(() => {
    if (licenses) {
      // Check for conflicting date ranges for specific license types (POC, Reading, Remote Node)
      if (LicenseValidator.detectLicenseConflict(licenses, fromDate, toDate)) {
        setConflictingLicenseDetected(true);
      } else {
        setConflictingLicenseDetected(false);
      }
    }
  }, [license['@type'], licenses, fromDate, toDate]);

  useEffect(() => {
    switch (license['@type']) {
      case LicenseType.PER_READING:
        let licensePerReading = license as LicensePerReadingDto;
        setMaxReadings(licensePerReading.maxReadings);
        break;
      case LicenseType.PER_DEVICE:
        let licensePerDevice = license as LicensePerDeviceDto;
        setMaxDataLoggers(licensePerDevice.maxDataLoggers);
        break;
      case LicenseType.PER_SENSOR:
        let licensePerSensor = license as LicensePerSensorDto;
        setMaxSensors(licensePerSensor.maxSensors);
        break;
      default:
        break;
    }
  }, [license]);

  const handleFromDateChange = (newDate: Date | null): void => {
    if (newDate) {
      setFromDate(newDate);
    }
  };

  const handleToDateChange = (newDate: Date | null): void => {
    if (newDate) {
      setToDate(newDate);
    }
  };

  const handleChange = (licenseType: LicenseType, value: number): void => {
    switch (licenseType) {
      case LicenseType.PER_READING:
        setMaxReadings(value);
        break;
      case LicenseType.PER_DEVICE:
        setMaxDataLoggers(value);
        break;
      case LicenseType.PER_SENSOR:
        setMaxSensors(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (): void => {
    const licenseData: LicenseRequest = {
      companyName: license.companyName,
      '@type': license['@type'],
      isActive: true,
      validityPeriodFrom: fromDate,
      validityPeriodTo: toDate,
    };

    switch (license['@type']) {
      case LicenseType.PER_DEVICE:
        (licenseData as LicensePerDeviceDto).maxDataLoggers = maxDataLoggers;
        break;
      case LicenseType.PER_READING:
        (licenseData as LicensePerReadingDto).maxReadings = maxReadings;
        break;
      case LicenseType.PER_SENSOR:
        (licenseData as LicensePerSensorDto).maxSensors = maxSensors;
        break;
    }

    addLicense(licenseData);
  };

  return (
    <>
      <div className="p-3 flex justify-center align-middle flex-col space-y-4">
        <LocalizedDatePicker
          label={t('profile_page_valid_from')}
          value={fromDate}
          onChange={handleFromDateChange}
          maxDate={toDate}
          minDate={
            (license as CompanyLicenseDto)['@type'] === LicenseType.PER_READING
              ? new Date()
              : undefined
          }
          inputFormat="dd/MM/yyyy"
          renderInputTextField={{
            size: 'small',
            className: 'bg-white, w-full',
            onKeyDown: (
              e: BaseSyntheticEvent<KeyboardEvent, EventTarget & HTMLDivElement, EventTarget>
            ): void => e.preventDefault(),
            sx: {
              '& .MuiInputBase-input': {
                caretColor: 'transparent',
              },
            },
          }}
        />
        <LocalizedDatePicker
          label={t('profile_page_valid_to')}
          value={toDate}
          onChange={handleToDateChange}
          inputFormat="dd/MM/yyyy"
          minDate={fromDate}
          renderInputTextField={{
            size: 'small',
            className: 'bg-white, w-full',
            onKeyDown: (
              e: BaseSyntheticEvent<KeyboardEvent, EventTarget & HTMLDivElement, EventTarget>
            ): void => e.preventDefault(),
            sx: {
              '& .MuiInputBase-input': {
                caretColor: 'transparent',
              },
            },
          }}
        />
        {license['@type'] !== LicenseType.PER_POC &&
          license['@type'] !== LicenseType.PER_REMOTE_NODE && (
            <LicenseMaxCount
              setHasError={setHasError}
              hasError={hasError}
              onChange={handleChange}
              licenseType={license['@type']}
              maxSensors={maxSensors}
              maxDataLoggers={maxDataLoggers}
              maxReadings={maxReadings}
              disabled={false}
            />
          )}
        {conflictingLicenseDetected && (
          <div className="text-red-400 text-center">{t('license_conflicting_license_date')}</div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className="w-full"
          disabled={hasError || isAfter(fromDate, toDate) || conflictingLicenseDetected}
        >
          {t('license_info_renew_modal_button')}
        </Button>
      </div>
    </>
  );
};

interface RenewLicenseModalProps {
  license: LicenseDto;
  licenses: LicenseDto[] | undefined;
  addLicense: (body: LicenseRequest) => void;
}

export default RenewLicenseModal;
