import GenericAnalogSensor from './GenericAnalogSensor';

export interface ParticulateMatterSensor extends GenericAnalogSensor {
  particleSize: ParticulateMatterSize | null;
  '@type': 'particulate_matter_sensor';
}

export enum ParticulateMatterSize {
  PM1 = '1',
  PM2_5 = '2.5',
  PM4 = '4',
  PM10 = '10',
}
