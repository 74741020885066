import { TextField } from '@mui/material';
import { LicenseType } from '@thingslog/repositories';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const LicenseMaxCount: React.FC<LicenseMaxCountProps> = ({
  licenseType,
  disabled,
  maxReadings,
  maxDataLoggers,
  maxSensors,
  onChange,
  hasError,
  setHasError,
}: LicenseMaxCountProps) => {
  const { t } = useTranslation();

  const fieldProperties = {
    PER_READING: {
      key: 'per_reading_field',
      id: 'per_reading_count',
      value: maxReadings.toString(),
      label: t('license_max_readings'),
      name: 'per_reading',
    },
    PER_DEVICE: {
      key: 'per_device_field',
      id: 'per_device_count',
      value: maxDataLoggers.toString(),
      label: t('license_max_devices'),
      name: 'per_device',
    },
    PER_SENSOR: {
      key: 'per_sensor_field',
      id: 'per_sensor_count',
      value: maxSensors.toString(),
      label: t('license_max_sensors'),
      name: 'per_sensor',
    },
  };

  const fieldProps = fieldProperties[licenseType] || {};

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const value = event.target.value;

    // Use a regular expression to allow only digit characters
    if (value === '' || /^\d+$/.test(value)) {
      let numericValue = Number(value);

      // Check if numericValue is 0 and set error accordingly
      if (numericValue === 0) {
        setHasError(true);
      } else {
        setHasError(false);
      }

      onChange(licenseType, numericValue);
    }
    // If the value is not a number, don't call onChange
  };

  return (
    <>
      <TextField
        onChange={handleChange}
        {...fieldProps}
        disabled={disabled}
        fullWidth
        size="small"
        variant="outlined"
      />
      {hasError && <div className="text-red-400 text-center">{t('license_max_count_error')}</div>}
    </>
  );
};

interface LicenseMaxCountProps {
  licenseType: LicenseType;
  disabled?: boolean;
  maxReadings: number;
  maxDataLoggers: number;
  maxSensors: number;
  hasError: boolean;
  setHasError: (errorStatus: boolean) => void;
  onChange: (licenseType: LicenseType, value: number) => void;
}

export default LicenseMaxCount;
