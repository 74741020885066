export const convertSecondsToDuration = (totalSeconds: number): Duration => {
  let remainingSecs = totalSeconds;

  const days = Math.floor(remainingSecs / (24 * 60 * 60));
  if (days > 0) remainingSecs -= days * 24 * 60 * 60;

  const hours = Math.floor(remainingSecs / (60 * 60));
  if (hours > 0) remainingSecs -= hours * 60 * 60;

  const minutes = Math.floor(remainingSecs / 60);
  if (minutes > 0) remainingSecs -= minutes * 60;

  const seconds = remainingSecs;

  return { days, hours, minutes, seconds };
};

interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
