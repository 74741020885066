import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import { Company } from '../../model/API/Company/Company';
import { CompanyDto } from '@thingslog/repositories';

export const getAllCompanies = async (): Promise<CompanyDto[]> => {
  return await Axios.get(`/api/companies`).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getCompaniesForDevice = async (deviceNumber: string): Promise<Company[]> => {
  return await Axios.get(`/api/companies?deviceNumber=${deviceNumber}`).then(
    (response: AxiosResponse) => {
      return response.data;
    }
  );
};

export const getCompany = async (id: number): Promise<Company> => {
  return await Axios.get(`/api/companies/${id}`).then((response: AxiosResponse) => {
    return response.data;
  });
};
