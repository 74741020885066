import { useToast, useModal } from '@thingslog/ui-components';
import { AxiosError } from 'axios';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { companySelected } from '../../actions';
import {
  companyQueryClient,
  countriesQueryClient,
  licenseQueryClient,
  timezonesQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import Accounts from './Accounts';
import { ReduxState } from '../../reducers';
import { ErrorResponse } from '@thingslog/repositories';

const AccountsPage: FC<AccountsPageProps> = ({}: AccountsPageProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { toast } = useToast();
  const auth = useSelector((state: ReduxState) => state.auth);
  const company = useSelector((state: ReduxState) => state.company);
  const featureFlags = useSelector((state: ReduxState) => state.featureFlags);
  const { closeModal } = useModal();
  const { useDeleteCompany, useUpdateCompany, useCreateCompany } = useMemo(
    () => companyQueryClient,
    []
  );
  const { useGetCountries } = useMemo(() => countriesQueryClient, []);
  const { useGetTimezones } = useMemo(() => timezonesQueryClient, []);
  const { useLicenses } = useMemo(() => licenseQueryClient, []);
  const [editingCompanyId, setEditingCompanyId] = useState<number | null>(null);

  const { mutate: deleteCompanyMutation, isSuccess: isDeleteSuccess } = useDeleteCompany({
    onSuccess: async () => {
      localStorage.removeItem('selectedCompany'); //Unselect
      dispatch(companySelected({ id: null, name: null }));
      closeModal();
    },
    onError: (error: AxiosError) => {
      toast({ message: t('company_manager_delete_company_error_message'), type: 'error' });
    },
  });

  const { mutate: updateCompanyMutation, isSuccess: isUpdateSuccess } = useUpdateCompany({
    onSuccess: async () => {
      toast({ message: t('company_manager_update_company_success'), type: 'success' });
      localStorage.removeItem('selectedCompany'); //Unselect
      dispatch(companySelected({ id: null, name: null }));
      closeModal();
    },
    onError: () => {
      toast({ message: t('company_manager_update_company_error'), type: 'error' });
      closeModal();
    },
  });

  const { mutate: createCompanyMutation, isSuccess: isCreateSuccess } = useCreateCompany({
    onSuccess: async () => {
      toast({ message: t('company_manager_account_created'), type: 'success' });
      closeModal();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error.response?.status === 409) {
        toast({ message: t('company_manager_account_name_exists'), type: 'error' });
      } else {
        toast({ message: t('company_manager_create_company_error'), type: 'error' });
        closeModal();
      }
    },
  });

  const timezonesQuery = useGetTimezones({
    onError: () => {
      toast({ message: t('company_manager_timezones_error'), type: 'error' });
    },
    refetchOnWindowFocus: false,
  });

  const countriesQuery = useGetCountries({
    onError: () => {
      toast({ message: t('company_manager_countries_error'), type: 'error' });
    },
    refetchOnWindowFocus: false,
  });

  const licenses = useLicenses(editingCompanyId, undefined, undefined, {
    enabled: editingCompanyId !== null,
  });

  const shouldRefresh = isCreateSuccess || isUpdateSuccess || isDeleteSuccess;

  return (
    <Accounts
      t={t}
      timezones={timezonesQuery.data || new Set()}
      countries={countriesQuery.data || null}
      licensesForEditingCompany={licenses.data}
      onSelectCompanyForEditing={setEditingCompanyId}
      shouldRefresh={shouldRefresh}
      auth={auth}
      company={company}
      featureFlags={featureFlags}
      createCompanyMutation={createCompanyMutation}
      updateCompanyMutation={updateCompanyMutation}
      deleteCompanyMutation={deleteCompanyMutation}
    />
  );
};

interface AccountsPageProps {}

export default AccountsPage;
