import { useMutation } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  MutationOptions,
  ConsumptionExportRequest,
  FormatAcceptHeaders,
  ConsumptionExportDto,
  AggregationOptions,
  MutationResult,
} from '@thingslog/repositories';

export interface MultipleConsumptionExportQueryClient {
  getMultipleConsumptionExportCSV: ({
    body,
    fetchType,
  }: useMultipleConsumptionExportVariables) => Promise<ArrayBuffer>;
  getMultipleConsumptionExportExcel: ({
    body,
    fetchType,
  }: useMultipleConsumptionExportVariables) => Promise<ArrayBuffer>;
  getMultipleConsumptionExportJSON: ({
    body,
    fetchType,
  }: useMultipleConsumptionExportVariables) => Promise<ConsumptionExportDto[]>;
  useMultipleConsumptionExportCSV: (
    options?: MutationOptions<ArrayBuffer, useMultipleConsumptionExportVariables>
  ) => MutationResult<ArrayBuffer, useMultipleConsumptionExportVariables>;
  useMultipleConsumptionExportExcel: (
    options?: MutationOptions<ArrayBuffer, useMultipleConsumptionExportVariables>
  ) => MutationResult<ArrayBuffer, useMultipleConsumptionExportVariables>;
  useMultipleConsumptionExportJSON: (
    options?: MutationOptions<ConsumptionExportDto[], useMultipleConsumptionExportVariables>
  ) => MutationResult<ConsumptionExportDto[], useMultipleConsumptionExportVariables>;
}

export function createMultipleConsumptionExportQueryClient(
  axios: AxiosInstance
): MultipleConsumptionExportQueryClient {
  return new MultipleConsumptionExportImp(axios);
}

class MultipleConsumptionExportImp {
  public constructor(private axios: AxiosInstance) {}

  public getMultipleConsumptionExportCSV = async ({
    body,
    fetchType,
  }: useMultipleConsumptionExportVariables): Promise<ArrayBuffer> => {
    let acceptHeader = FormatAcceptHeaders.CSV;

    return this.axios
      .post('/api/consumptions-export', body, {
        headers: { Accept: acceptHeader },
        responseType: 'arraybuffer',
        params: { fetchType },
      })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        return response.data;
      });
  };

  public getMultipleConsumptionExportExcel = async ({
    body,
    fetchType,
  }: useMultipleConsumptionExportVariables): Promise<ArrayBuffer> => {
    let acceptHeader = FormatAcceptHeaders.XLSX;

    return this.axios
      .post('/api/consumptions-export', body, {
        headers: { Accept: acceptHeader },
        responseType: 'arraybuffer',
        params: { fetchType },
      })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        return response.data;
      });
  };

  public getMultipleConsumptionExportJSON = async ({
    body,
    fetchType,
  }: useMultipleConsumptionExportVariables): Promise<ConsumptionExportDto[]> => {
    let acceptHeader = FormatAcceptHeaders.JSON;

    return this.axios
      .post('/api/consumptions-export', body, {
        headers: { Accept: acceptHeader },
        params: { fetchType },
      })
      .then((response: AxiosResponse<ConsumptionExportDto[]>) => {
        return response.data;
      });
  };

  public useMultipleConsumptionExportCSV = (
    options?: MutationOptions<ArrayBuffer, useMultipleConsumptionExportVariables>
  ): MutationResult<ArrayBuffer, useMultipleConsumptionExportVariables> => {
    return useMutation<ArrayBuffer, AxiosError, useMultipleConsumptionExportVariables>(
      [QueryKeys.GetConsumptionExport, 'CSV'],
      (variables: useMultipleConsumptionExportVariables) =>
        this.getMultipleConsumptionExportCSV({ ...variables }),
      options
    );
  };

  public useMultipleConsumptionExportExcel = (
    options?: MutationOptions<ArrayBuffer, useMultipleConsumptionExportVariables>
  ): MutationResult<ArrayBuffer, useMultipleConsumptionExportVariables> => {
    return useMutation<ArrayBuffer, AxiosError, useMultipleConsumptionExportVariables>(
      [QueryKeys.GetConsumptionExport, 'Excel'],
      (variables: useMultipleConsumptionExportVariables) =>
        this.getMultipleConsumptionExportExcel({ ...variables }),
      options
    );
  };

  public useMultipleConsumptionExportJSON = (
    options?: MutationOptions<ConsumptionExportDto[], useMultipleConsumptionExportVariables>
  ): MutationResult<ConsumptionExportDto[], useMultipleConsumptionExportVariables> => {
    return useMutation<ConsumptionExportDto[], AxiosError, useMultipleConsumptionExportVariables>(
      [QueryKeys.GetConsumptionExport, 'JSON'],
      (variables: useMultipleConsumptionExportVariables) =>
        this.getMultipleConsumptionExportJSON({ ...variables }),
      options
    );
  };
}

interface useMultipleConsumptionExportVariables {
  body: ConsumptionExportRequest;
  fetchType?: AggregationOptions;
}
