import React, { ForwardedRef, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';

const HelperTextInputField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ helperText, ...props }: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    return <TextField {...props} inputRef={ref} helperText={helperText || ' '} />;
  }
);

export default HelperTextInputField;
