import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme: Theme) => ({
  fixedWidthTextField: {
    width: 180,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
