import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import companiesClient from './CompaniesClient';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';

import { withRouter } from './common/withRouter';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';
import ErrorUtil from './common/ErrorUtil';

class LicenseManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      open: false,
      buttonsDisabled: false,
      license: '',
    };
    this.handleLicenseUpdated = this.handleLicenseUpdated.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleLicenseChange = this.handleLicenseChange.bind(this);
    this.handleUpdateLicense = this.handleUpdateLicense.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    if (error.response.data) {
      this.setState({
        message:
          'Error: ' + error.response.data.message ||
          error.response.data.error ||
          error.response.data,
        open: true,
        buttonsDisabled: false,
      });
    }
  }

  handleLicenseChange = (ev) => this.setState({ license: ev.target.value });

  handleUpdateLicense(ev) {
    if (this.props.company.name === '') {
      alert('select company name');
      return;
    }
    this.setState({
      open: true,
      message: this.props.t('license_manager_creating_account', {
        company_name: this.props.company.name,
      }),
      buttonsDisabled: true,
    });
    companiesClient.putCompany(
      {
        id: this.props.company.id,
        name: this.props.company.name,
        license: this.state.license,
      },
      this.handleLicenseUpdated,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleLicenseUpdated() {
    this.setState({
      message: this.props.t('license_manager_license_updated'),
      open: true,
      buttonsDisabled: false,
    });
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    return (
      <Header>
        <div className="well" style={wellStyles}>
          <p>{this.props.t('license_manager_title', { company_name: this.props.company.name })}</p>
          <form>
            <FormGroup controlId="License">
              <ControlLabel>{this.props.t('license_manager_license')}</ControlLabel>
              <FormControl
                value={this.state.license}
                type="text"
                componentClass={'textarea'}
                placeholder={this.props.t('license_manager_license')}
                rows={10}
                onChange={this.handleLicenseChange}
              />
            </FormGroup>
            <Button onClick={this.handleUpdateLicense} disabled={this.state.buttonsDisabled}>
              {this.props.t('button_update')}
            </Button>
            <Snackbar
              open={this.state.open}
              message={this.state.message}
              autoHideDuration={4000}
              onClose={this.handleRequestClose}
            />
          </form>
        </div>
      </Header>
    );
  }

  componentDidMount() {
    companiesClient.getCompany(this.props.company.id, (company) => {
      this.setState({ license: company.license });
    });
  }

  componentWillUnmount() {}
}

function mapStateToProps(state) {
  return {
    company: state.company,
  };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(LicenseManager)));
