import { styled, TableCell, tableCellClasses, Theme } from '@mui/material';

const PrimaryColorTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default PrimaryColorTableCell;
