import React, { FC, ForwardedRef, forwardRef, PropsWithChildren, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography, Grid, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import DeviceGroupTable from './components/DeviceGroupTable/DeviceGroupTable';
import SensorsTable from './components/SensorsTable/SensorsTable';
import Header from '../../components/header';
import { ReduxState } from '../../reducers';
import useDetermineCompanyId from '../../hooks/useDetermineCompanyId';
import JwtValidator from '../../common/JwtValidator';
import {
  deviceGroupsQueryClient,
  initialConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { AxiosError } from 'axios';
import { queryClient, QueryKeys } from '@thingslog/queries';
import { DeviceGroupDto, MutateDeviceGroup, MutateDeviceSensor } from '@thingslog/repositories';
import { useModal } from '@thingslog/ui-components';

//Alert component
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props: PropsWithChildren<AlertProps>,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeviceGroups: FC = () => {
  const companyId = useDetermineCompanyId();
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const { useDevicePortsConfigData } = useMemo(() => initialConfigQueryClient, []);
  const {
    useDeviceGroupsData,
    useCreateDeviceGroup,
    useUpdateDeviceGroup,
    useDeleteDeviceGroup,
    useAddDeviceSensorToGroup,
    useDeleteDeviceAndSensorFromGroup,
  } = useMemo(() => deviceGroupsQueryClient, []);

  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<DeviceGroupDto | null>(null);
  const [selectedDeviceNumber, setSelectedDeviceNumber] = useState<string | null>(null);
  const [isOpenedDeleteSensorModal, setIsOpenedDeleteSensorModal] = useState<boolean>(false);
  const [isOpenedAddSensorModal, setIsOpenedAddSensorModal] = useState<boolean>(false);

  //Snackbar states
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: selectedDevicePorts } = useDevicePortsConfigData(selectedDeviceNumber!, {
    enabled: selectedDeviceNumber !== null,
  });

  const { data: deviceGroups } = useDeviceGroupsData(companyId);

  const { mutate: createDeviceGroup } = useCreateDeviceGroup({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetDeviceGroupsByCompanyId]);
      closeModal();
    },
    onError: (error: AxiosError) => {
      showErrorMessage(
        error.response?.data.message || t('device_groups_create_device_group_error'),
        true
      );
    },
  });

  const { mutate: updateDeviceGroup } = useUpdateDeviceGroup({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetDeviceGroupsByCompanyId]);
      closeModal();
    },
    onError: (error: AxiosError) => {
      showErrorMessage(
        error.response?.data.message || t('device_groups_update_device_group_error'),
        true
      );
    },
  });

  const { mutate: deleteDeviceGroup } = useDeleteDeviceGroup(companyId, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetDeviceGroupsByCompanyId]);
      closeModal();
      setSelectedDeviceGroup(null);
    },
    onError: (error: AxiosError) => {
      showErrorMessage(
        error.response?.data.message || t('device_groups_delete_device_group_error'),
        true
      );
    },
  });

  const { mutate: addDeviceSensorToGroup } = useAddDeviceSensorToGroup(companyId, {
    onSuccess: () => {
      setIsOpenedAddSensorModal(false);
      queryClient.invalidateQueries([QueryKeys.GetDeviceGroupsByCompanyId]);
    },
    onError: (error: AxiosError) => {
      showErrorMessage(
        error.response?.data.message || t('device_groups_add_device_sensor_device_group_error'),
        true
      );
    },
  });

  const { mutate: deleteDeviceAndSensorFromGroup } = useDeleteDeviceAndSensorFromGroup(companyId, {
    onSuccess: () => {
      setIsOpenedDeleteSensorModal(false);
      queryClient.invalidateQueries([QueryKeys.GetDeviceGroupsByCompanyId]);
    },
    onError: (error: AxiosError) => {
      showErrorMessage(
        error.response?.data.message || t('device_groups_remove_device_sensor_device_group_error'),
        true
      );
    },
  });

  const showErrorMessage = (errorMessage: string, showError: boolean): void => {
    setErrorMessage(errorMessage);
    setShowError(showError);
  };

  if (
    companyId === null ||
    jwtValidator.isAllowedUnlessSuperAdminWithoutSelectedCompany(companyIdRedux) === false
  ) {
    return (
      <Header>
        <div className="flex flex-col justify-center items-center h-full">
          <Typography variant="h4">{t('device_groups_select_company_header')}</Typography>
          <Typography variant="h5">
            {t('device_groups_select_company_from')}
            <Link to={`/app/Accounts`}>{t('device_groups_select_company_accounts_page_link')}</Link>
          </Typography>
        </div>
      </Header>
    );
  }

  return (
    <Header>
      <Grid container spacing={4}>
        <Grid item lg={6} md={12} pl={1}>
          <DeviceGroupTable
            companyId={companyId}
            deviceGroups={deviceGroups || []}
            selectedDeviceGroup={selectedDeviceGroup}
            onSelectedDeviceGroupChange={setSelectedDeviceGroup}
            createDeviceGroup={(deviceGroup: MutateDeviceGroup): void =>
              createDeviceGroup(deviceGroup)
            }
            updateDeviceGroup={(deviceGroupId: number, body: MutateDeviceGroup): void =>
              updateDeviceGroup({ deviceGroupId: deviceGroupId, body: body })
            }
            deleteDeviceGroup={(deviceGroupId: number): void => deleteDeviceGroup(deviceGroupId)}
          />
        </Grid>
        <Grid item lg={6} md={12} pr={4}>
          <SensorsTable
            companyId={companyId}
            selectedDeviceGroup={selectedDeviceGroup}
            selectedDeviceNumber={selectedDeviceNumber}
            onSelectedDeviceChange={setSelectedDeviceNumber}
            selectedDevicePorts={selectedDevicePorts || {}}
            isOpenedDeleteSensorModal={isOpenedDeleteSensorModal}
            isOpenedAddSensorModal={isOpenedAddSensorModal}
            onDeleteSensorModalStateChange={setIsOpenedDeleteSensorModal}
            onAddSensorModalStateChange={setIsOpenedAddSensorModal}
            addDeviceSensorToGroup={(deviceGroupId: number, body: MutateDeviceSensor): void => {
              addDeviceSensorToGroup({ deviceGroupId, body });
            }}
            deleteDeviceAndSensorFromGroup={(
              deviceGroupId: number,
              deviceNumber: string,
              sensorIndex: number
            ): void => deleteDeviceAndSensorFromGroup({ deviceGroupId, deviceNumber, sensorIndex })}
          />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showError}
        autoHideDuration={6000}
        onClose={(): void => setShowError(false)}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Header>
  );
};

export default DeviceGroups;
