import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  TimeSeriesData,
  DevicesReadingsAvgQueryParams,
  DevicesReadingsQueryParams,
  QueryOptions,
  DeviceLastReadingDto,
} from '@thingslog/repositories';

export function createDeviceCountersQueryClient(axios: AxiosInstance): DeviceCountersQueryClient {
  return new DeviceCountersQueryClientImp(axios);
}

class DeviceCountersQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getCurrentReadings = async (
    deviceNumber: string,
    every: number
  ): Promise<DeviceLastReadingDto[]> => {
    return this.axios
      .get(`/api/v2/devices/${deviceNumber}/readings/current`, {
        params: { every: every },
      })
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useCurrentReadings = (
    deviceNumber: string,
    every: number,
    options?: QueryOptions<DeviceLastReadingDto[]>
  ): UseQueryResult<DeviceLastReadingDto[], AxiosError> => {
    return useQuery<DeviceLastReadingDto[], AxiosError>(
      [QueryKeys.GetCurrentReadings, deviceNumber, every],
      () => this.getCurrentReadings(deviceNumber, every),
      options
    );
  };

  public useDevicesReadingsQueries = (
    args: DevicesReadingsQueryParams<TimeSeriesData<number>[]>[]
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError>[] =>
    useQueries({
      queries: args.map(
        ({
          deviceNumber,
          sensorIndex,
          fromDate,
          toDate,
          every,
          options,
        }: DevicesReadingsQueryParams<TimeSeriesData<number>[]>) => ({
          queryKey: [
            QueryKeys.GetDevicesReadings,
            deviceNumber,
            sensorIndex,
            fromDate,
            toDate,
            every,
          ],
          queryFn: () =>
            this.getDeviceReadingsTimeSeriesData(
              deviceNumber,
              sensorIndex,
              fromDate,
              toDate,
              every
            ),
          ...options,
        })
      ),
    });

  public useDevicesReadingsAvgQueries = (
    args: DevicesReadingsAvgQueryParams<TimeSeriesData<number>[]>[]
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError>[] =>
    useQueries({
      queries: args.map(
        ({
          deviceNumber,
          sensorIndex,
          avgFromDate,
          avgEndDate,
          fromDate,
          toDate,
          every,
          options,
        }: DevicesReadingsAvgQueryParams<TimeSeriesData<number>[]>) => ({
          queryKey: [
            QueryKeys.GetDevicesReadingsAvg,
            deviceNumber,
            sensorIndex,
            avgFromDate,
            avgEndDate,
            fromDate,
            toDate,
            every,
          ],
          queryFn: () =>
            this.getDeviceReadingsAvgTimeSeriesData(
              deviceNumber,
              sensorIndex,
              avgFromDate,
              avgEndDate,
              fromDate,
              toDate,
              every
            ),
          ...options,
        })
      ),
    });

  public useDeviceReadings = (
    deviceNumber: string,
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number,
    options?: QueryOptions<TimeSeriesData<number>[]>
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError> => {
    return useQuery<TimeSeriesData<number>[], AxiosError>(
      [QueryKeys.GetDevicesReadings, deviceNumber, sensorIndex, fromDate, toDate, every],
      () =>
        this.getDeviceReadingsTimeSeriesData(deviceNumber, sensorIndex, fromDate, toDate, every),
      options
    );
  };

  public getDeviceReadingsTimeSeriesData = async (
    deviceNumber: string,
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number
  ): Promise<TimeSeriesData<number>[]> => {
    return await this.axios
      .get(`/api/v2/devices/${deviceNumber}/${sensorIndex}/readings`, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          every: every,
        },
      })
      .then((response: AxiosResponse) => {
        const values = response.data;
        const timeSeriesData: TimeSeriesData<number>[] = [];
        values.forEach(({ date, reading }: { date: Date; reading: number }) => {
          const timestamp = new Date(date).getTime();
          const readingDataPoint: TimeSeriesData<number> = { timestamp: timestamp, value: reading };
          timeSeriesData.push(readingDataPoint);
        });

        return timeSeriesData;
      });
  };

  public getDeviceReadingsAvgTimeSeriesData = async (
    deviceNumber: string,
    sensorIndex: number,
    avgFromDate: Date,
    avgEndDate: Date,
    fromDate: Date,
    toDate: Date,
    every: number
  ): Promise<TimeSeriesData<number>[]> => {
    const targetStartDate = new Date(fromDate).toISOString().substring(0, 10);
    const targetEndDate = new Date(toDate).toISOString().substring(0, 10);
    return await this.axios
      .get(`/api/v2/devices/${deviceNumber}/${sensorIndex}/readings/avg`, {
        params: {
          fromDate: avgFromDate,
          toDate: avgEndDate,
          targetFromDate: targetStartDate,
          targetToDate: targetEndDate,
          every: every,
        },
      })
      .then((response: AxiosResponse) => {
        const values = response.data.value;
        const timeSeriesData: TimeSeriesData<number>[] = [];
        values.forEach(({ date, reading }: { date: Date; reading: number }) => {
          const timestamp = new Date(date).getTime();
          const readingDataPoint: TimeSeriesData<number> = { timestamp: timestamp, value: reading };
          timeSeriesData.push(readingDataPoint);
        });

        return timeSeriesData;
      });
  };
}

export interface DeviceCountersQueryClient {
  useDevicesReadingsQueries: (
    args: DevicesReadingsQueryParams<TimeSeriesData<number>[]>[]
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>[];

  useDevicesReadingsAvgQueries: (
    args: DevicesReadingsAvgQueryParams<TimeSeriesData<number>[]>[]
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>[];

  getDeviceReadingsTimeSeriesData: (
    deviceNumber: string,
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number
  ) => Promise<TimeSeriesData<number>[]>;

  getDeviceReadingsAvgTimeSeriesData: (
    deviceNumber: string,
    sensorIndex: number,
    avgFromDate: Date,
    avgEndDate: Date,
    fromDate: Date,
    toDate: Date,
    every: number
  ) => Promise<TimeSeriesData<number>[]>;

  useCurrentReadings: (
    deviceNumber: string,
    every: number,
    options?: QueryOptions<DeviceLastReadingDto[]>
  ) => UseQueryResult<DeviceLastReadingDto[], AxiosError>;

  getCurrentReadings: (deviceNumber: string, every: number) => Promise<DeviceLastReadingDto[]>;

  useDeviceReadings: (
    deviceNumber: string,
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number,
    options?: QueryOptions<TimeSeriesData<number>[]>
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>;
}
