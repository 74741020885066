import { Theme } from '@mui/material';

import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    paper: {
      width: 400,
    },
    formWrapper: {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 15,
      marginRight: 15,
    },
    floatingCloseButton: {
      position: 'absolute',
      top: 20,
      right: 20,
    },
    closeIconButton: {
      color: theme.palette.grey[400],
      '&:hover': {
        color: theme.palette.error.light,
      },
    },
  });
