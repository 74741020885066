import React, { useEffect, FC, useMemo, useState } from 'react';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from '../../reducers';
import Axios from '../../clients/Axios/Axios';
import {
  devSelected,
  signoutUser,
  featureFlagsUpdate,
  companySelected,
  setSolutionSettings,
  setSelectedPage,
  setCompanyLogo,
  selectedDeviceChanged,
  restrictUser,
  setCompanyLicenses,
  setLicensesAccess,
} from '../../actions/index';
import {
  CompanyInfoDto,
  Device,
  JwtRole,
  LicenseDto,
  LicensePerReadingDto,
  Pagination,
  SettingsDto,
  UserRole,
} from '@thingslog/repositories';
import JwtValidator from '../../common/JwtValidator';
import {
  featureFlagsQueryClient,
  settingsQueryClient,
  companyInfoQueryClient,
  devicesQueryClient,
  licenseQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { setDevices } from '../../state_management/actions/DevicesActionCreator';
import { t } from 'i18next';
import { useToast } from '@thingslog/ui-components';
import default_logo from '../../resources/logos/logo-dark.png';
import LicenseValidator from '../../common/LicenseValidator';
import useDetermineCompanyId from '../../hooks/useDetermineCompanyId';

const PrivateOutlet: FC<PrivateOutletProps> = ({
  allowedRoles,
  pageId,
  isNonRestrictedAccess,
}: PrivateOutletProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);

  const { useSettings } = useMemo(() => settingsQueryClient, []);
  const { useDeviceData, useDevicesData } = useMemo(() => devicesQueryClient, []);
  const { useCompanyInfo } = useMemo(() => companyInfoQueryClient, []);
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const { useEnabledFeatureFlags } = useMemo(() => featureFlagsQueryClient, []);
  const { useLicenses } = useMemo(() => licenseQueryClient, []);
  const params = useParams();

  const auth = useSelector((state: ReduxState) => state.auth);
  const dev = useSelector((state: ReduxState) => state.dev);
  const company = useSelector((state: ReduxState) => state.company);

  const companyId = useDetermineCompanyId();

  useDevicesData(companyId, 0, 9999, null, null, {
    enabled: jwtValidator.isAllowedUnlessSuperAdminWithoutSelectedCompany(company.id),
    onSuccess: (data: Pagination<Device>) => {
      dispatch(setDevices(data.content));
    },
    refetchOnWindowFocus: false,
  });

  const { data: featureFlags, refetch: fetchFlags } = useEnabledFeatureFlags({
    enabled: false,
    onSuccess: (data: string[]) => {
      dispatch(featureFlagsUpdate(data));
    },
    refetchOnWindowFocus: false,
  });

  const { data: licenses } = useLicenses(Number(companyId), undefined, undefined, {
    onSuccess: (data: LicenseDto[]) => {
      dispatch(setCompanyLicenses(data));

      if (
        data &&
        featureFlags!.includes('LICENSES_MANAGEMENT_RESTRICT_USER_ACCESS_ON_STATUS') &&
        data.length !== 0
      ) {
        // RESTRICT USER REGION
        let inactiveLicense = data.some((license: LicenseDto) => !license.isActive);
        let hasAtleastOneValidLicense = data.some((license: LicenseDto) => license.isValid);
        if (
          (inactiveLicense || !hasAtleastOneValidLicense) &&
          !jwtValidator.hasRole('ROLE_SUPER_ADMIN') &&
          auth.authenticated
        ) {
          dispatch(restrictUser());
          if (!isNonRestrictedAccess) {
            navigate('/app/restricted-access');
          }
        }

        //NOTIFICATION LICENSES REGION
        const { expiringLicenses, overLimitReadingsLicenses } =
          LicenseValidator.separateExpiringAndOverLimitLicenses(data);

        if (auth.authenticated) {
          if (expiringLicenses.length !== 0 || overLimitReadingsLicenses.length !== 0) {
            triggerLicenseNotifications(expiringLicenses, overLimitReadingsLicenses);
          }
        }
      }
    },
    refetchOnWindowFocus: false,
    enabled:
      featureFlags !== undefined &&
      auth.isRestricted === undefined &&
      auth.authenticated &&
      companyId !== null,
  });

  const {} = useSettings(companyId, {
    enabled: companyId !== null,
    onSuccess: (settingsDto: SettingsDto) => {
      if (settingsDto.data !== null) {
        dispatch(setSolutionSettings(settingsDto));
      } else {
        dispatch(setSolutionSettings({ data: { dashboardLayout: null } }));
      }
    },
  });

  const {} = useDeviceData(selectedDevice!, {
    enabled: !!selectedDevice,
    onSuccess: (device: Device) => {
      dispatch(selectedDeviceChanged(device));
    },
  });

  const convertBase64StringToBlob = (base64: string): Blob => {
    const byteCharacters = atob(base64);
    const byteNumbers = Array.from(byteCharacters).map((char: string) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  };

  const fetchImageAndConvertToBlob = async (imagePath: string): Promise<Blob> => {
    const response = await fetch(imagePath);
    return response.blob();
  };

  useCompanyInfo(Number(companyId), {
    onSuccess: (data: CompanyInfoDto) => {
      if (data.logo) {
        let blobCompanyLogo = convertBase64StringToBlob(data.logo);
        dispatch(setCompanyLogo(blobCompanyLogo));
      } else {
        fetchImageAndConvertToBlob(default_logo)
          .then((blob: Blob) => {
            dispatch(setCompanyLogo(blob));
          })
          .catch((error: string) => {
            console.error('Failed to convert image to blob', error);
          });
      }
    },
    onError: () => {
      toast({ type: 'error', message: t('logo_error_message') });
    },
    enabled: companyId !== null,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let hasLicensesAccess: boolean = false;
    const hasLicenses = licenses && licenses.length > 0;
    const isSuperAdmin = jwtValidator.hasRole('ROLE_SUPER_ADMIN');

    if (featureFlags !== undefined) {
      const isLicenseManagementFetureFlagOn = featureFlags.includes(
        'LICENSE_MANAGEMENT_FEATURE_FLAG'
      );

      const isHideLinkForAccountsWithNoLicenseFeatureFlagOn = featureFlags.includes(
        'LICENSE_MANAGEMENT_HIDE_LINK_FOR_ACCOUNTS_WITH_NO_LICENSE_FEATURE_FLAG'
      );

      if (isLicenseManagementFetureFlagOn) {
        if (isSuperAdmin || (isHideLinkForAccountsWithNoLicenseFeatureFlagOn && hasLicenses)) {
          hasLicensesAccess = true;
        }
      }
    }

    dispatch(setLicensesAccess(hasLicensesAccess));
  }, [licenses, featureFlags, jwtValidator]);

  useEffect(() => {
    if (dev.devices.length === 1) {
      setSelectedDevice(dev.devices[0]);
    } else {
      setSelectedDevice(null);
      dispatch(selectedDeviceChanged(null));
    }
  }, [dev.devices]);

  useEffect(() => {
    if (!jwtValidator.isAllowedUnlessSuperAdminWithoutSelectedCompany(company.id)) {
      dispatch(setDevices([]));
    }
  }, [jwtValidator, company]);

  useEffect(() => {
    if (jwtValidator.isValidToken) {
      fetchFlags();
    }
  }, [jwtValidator]);

  useEffect(() => {
    // Sets default JWT for all future requests
    const token = localStorage.getItem('token');
    if (token) {
      Axios.defaults.headers.common['Authorization'] = token;
    }
  }, []);

  useEffect(() => {
    // Set redux selected device number if not present
    if (dev.devices.length === 0) {
      if (params['deviceNumber']) {
        dispatch(devSelected([params['deviceNumber']], 'radio'));
      }
    }
  }, []);

  useEffect(() => {
    if (pageId) {
      dispatch(setSelectedPage(pageId));
    }
  }, [pageId]);

  useEffect(() => {
    // Logs user out if missing roles
    const userRoles = auth.roles;
    if (allowedRoles && userRoles) {
      let access: boolean = false;
      userRoles.forEach((role: JwtRole): void => {
        if (allowedRoles.includes(role.authority)) {
          access = true;
        }
      });
      if (!access) {
        navigate('/app');
        toast({
          type: 'error',
          message: t('error_forbidden_operation_not_allowed'),
        });
      }
    }
  }, [allowedRoles]);

  useEffect(() => {
    // Logs user out if not authenticated
    if (!auth.authenticated) {
      onUserUnauthenticated();
    }
  }, [auth]);

  useEffect(() => {
    // if there is an error query param, show a toast message
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('error') === 'forbidden') {
      toast({
        type: 'error',
        message: t('error_forbidden_operation_not_allowed'),
      });
    }
  }, [location]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent): void => {
      if (e.key === 'selectedCompany') {
        const selectedCompany = localStorage.getItem('selectedCompany');
        dispatch(
          companySelected(selectedCompany ? JSON.parse(selectedCompany) : { id: null, name: null })
        );
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // At initial render, get the initial value if exists
    const initialSelectedCompany = localStorage.getItem('selectedCompany');
    dispatch(
      companySelected(
        initialSelectedCompany ? JSON.parse(initialSelectedCompany) : { id: null, name: null }
      )
    );

    // Clean up function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  const onUserUnauthenticated = (): void => {
    dispatch(signoutUser());
    navigate('/app/signin');
  };

  const triggerLicenseNotifications = (
    expiringLicenses: LicenseDto[],
    overLimitReadingsLicenses: LicensePerReadingDto[]
  ): void => {
    let expiringLicensesCount = expiringLicenses.length;
    let overLimitLicensesCount = overLimitReadingsLicenses.length;

    let warningMessage = `${t('license_license_warning')}:\n`;
    let licenseWarnings: string[] = [];

    if (expiringLicensesCount !== 0) {
      licenseWarnings.push(
        `${expiringLicensesCount} ${
          expiringLicensesCount === 1
            ? t('license_license_warning_expire_singular')
            : t('license_license_warning_expire_plural')
        }`
      );
    }

    if (overLimitLicensesCount !== 0) {
      licenseWarnings.push(
        `${overLimitLicensesCount} ${
          overLimitLicensesCount === 1
            ? t('license_license_warning_per_reading_over_limit_singular')
            : t('license_license_warning_per_reading_over_limit_plural')
        }`
      );

      overLimitReadingsLicenses.forEach((readingsLicense: LicensePerReadingDto) => {
        licenseWarnings.push(
          ` - ${readingsLicense.licenseReadingsCount} / ${readingsLicense.maxReadings}`
        );
      });
    }

    toast({
      type: 'warning',
      message: (
        <div className="whitespace-pre-line">
          {warningMessage}
          <ul>
            {licenseWarnings.map((warning: string) => (
              <li>{warning}</li>
            ))}
          </ul>
        </div>
      ),
      position: 'bottomRight',
      duration: 30000,
    });
  };

  return <Outlet />;
};

interface PrivateOutletProps {
  allowedRoles?: UserRole[];
  pageId: string;
  isNonRestrictedAccess: boolean | undefined;
}

export default PrivateOutlet;
