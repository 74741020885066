import React from 'react';
import { styles } from './SearchBar.styles';
import { WithStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid, IconButton, Input, Paper, Box } from '@mui/material';
import classNames from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

class SearchBar extends React.Component<SearchBarProps> {
  private handleInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    this.props.onChange(e.target.value);
  };

  private handleRequestSearch = (): void => {
    if (this.props.onRequestSearch) {
      this.props.onRequestSearch();
    }
  };

  private handleCancel = (): void => {
    this.props.onChange('');
    if (this.props.onCancelSearch) {
      this.props.onCancelSearch();
    }
  };

  public render = (): React.ReactNode => {
    const { classes, t } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={11}>
            <div className={classes.searchContainer}>
              <Input
                fullWidth
                value={this.props.value}
                onChange={this.handleInput}
                disableUnderline
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="end" alignItems="end" marginRight={2}>
              <IconButton
                onClick={this.handleRequestSearch}
                className={classNames(classes.iconButton, classes.searchIconButton, {
                  [classes.iconButtonHidden]: this.props.value !== '',
                })}
                disabled={this.props.disabled}
              >
                {React.cloneElement(this.props.searchIcon || <SearchIcon />, {
                  classes: { root: classes.icon },
                })}
              </IconButton>
              <IconButton
                onClick={this.handleCancel}
                className={classNames(classes.iconButton, {
                  [classes.iconButtonHidden]: this.props.value === '',
                })}
                disabled={this.props.disabled}
              >
                {React.cloneElement(this.props.closeIcon || <ClearIcon />, {
                  classes: { root: classes.icon },
                })}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    );
  };
}

interface SearchBarProps extends WithStyles<typeof styles>, WithTranslation {
  value: string;
  onCancelSearch?(): void; // Fired when the search is cancelled.
  onChange(query: string): void; // Fired when the text value changes.
  onRequestSearch?(): void; // Fired when the search icon is clicked.
  searchIcon?: JSX.Element;
  closeIcon?: JSX.Element;
  disabled?: boolean;
  placeholder?: string;
}

export default withStyles(styles, { withTheme: true })(withTranslation()(SearchBar));
