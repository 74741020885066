import { Action } from 'redux';

export enum CompanySelectionActionType {
  COMPANY_SELECTED = 'COMPANY_SELECTED',
  COMPANY_LOGO_SET = 'COMPANY_LOGO_SET',
}

export type CompanySelectionAction = Action<CompanySelectionActionType>;

export interface CompanySelectedAction extends CompanySelectionAction {
  company: {
    id: number | null;
    name: string | null;
  };
  type: CompanySelectionActionType.COMPANY_SELECTED;
}

export interface CompanySetLogoAction extends CompanySelectionAction {
  companyLogoBlob: Blob;
  type: CompanySelectionActionType.COMPANY_LOGO_SET;
}
