import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { modbusReturnedDataTypeOptions } from './common/ModbusReturnedDataTypeOptions';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { hash, HelperTextInputField } from '@thingslog/ui-components';
import { ModbusReturnedDataType } from '@thingslog/repositories';

const BaseModbusConfig: FC<BaseModbusConfigProps> = ({ index }: BaseModbusConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, register }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <HelperTextInputField
            disabled
            {...register(`ports.${index}.sensor.registerAddress`)}
            label={t('sensor_config_register_address')}
            size="small"
          />

          <Controller
            control={control}
            name={`ports.${index}.sensor.dataType`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.dataType`
            >): ReactElement => (
              <FormControl size="small">
                <InputLabel>{t('sensor_config_data_type')}</InputLabel>
                <Select<ModbusReturnedDataType> {...field} label={t('sensor_config_data_type')}>
                  {modbusReturnedDataTypeOptions.map((option: ModbusReturnedDataType) => {
                    return (
                      <MenuItem key={hash(`dataType-${option}`)} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText> </FormHelperText>
              </FormControl>
            )}
          />
        </>
      )}
    </ConnectForm>
  );
};

interface BaseModbusConfigProps {
  index: number;
}

export default BaseModbusConfig;
