import React, { FC } from 'react';
import { useModal } from '@thingslog/ui-components';
import CreateAccountModal from './CreateAccountModal';
import { CountriesListDto, CreateCompanyDto } from '@thingslog/repositories';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanySelectionState } from '../../../reducers/company_reducer';

const CreateAccountButton: FC<CreateAccountButtonProps> = ({
  company,
  countriesList,
  timezones,
  createCompany,
}: CreateAccountButtonProps) => {
  const { modal } = useModal();
  const { t } = useTranslation();

  return (
    <Tooltip title={<span>{t('company_manager_create_company')}</span>} placement="top" arrow>
      <IconButton
        onClick={(): void => {
          modal({
            content: (
              <CreateAccountModal
                company={company}
                countriesList={countriesList}
                timezones={timezones}
                createCompany={createCompany}
              />
            ),
          });
        }}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

interface CreateAccountButtonProps {
  company: CompanySelectionState;
  timezones: Set<string>;
  countriesList: CountriesListDto | null;
  createCompany: (body: CreateCompanyDto) => void;
}

export default CreateAccountButton;
