import React, { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import LicenseWarningBoxStatus from './LicenseWarningBoxStatus';

const LicenseWarningBox: FC<LicenseWarningBoxProps> = ({
  warningStatus,
  translation,
}: LicenseWarningBoxProps) => {
  const { noActiveLicense, noValidLicense, devicesCountLimitReached } = warningStatus;

  let messageToShow: { title: string; description: string };
  let isGenericMessage = false;

  if (noActiveLicense) {
    messageToShow = {
      title: translation.accessOnHoldTitle,
      description: translation.accessOnHoldDescription,
    };
  } else if (noValidLicense) {
    messageToShow = {
      title: translation.noValidLicenseTitle,
      description: translation.noValidLicenseDescription,
    };
  } else if (devicesCountLimitReached) {
    messageToShow = {
      title: translation.deviceLimitReachedTitle,
      description: translation.deviceLimitReachedDescription,
    };
  } else {
    messageToShow = {
      title: translation.defaultGenericTitle,
      description: translation.defaultGenericDescription,
    };
    isGenericMessage = true;
  }

  return (
    <div className="flex w-1/2 bg-red-200 p-5 items-start">
      <div className="p-1">
        <ErrorOutlineIcon className="text-3xl text-red-600 mr-5" />
      </div>
      <div className="text-left">
        <h2 className="text-red-600 font-bold text-xl">{messageToShow.title}</h2>
        <p className="text-gray-900">{messageToShow.description}</p>
        {!isGenericMessage && (
          <Button
            color="primary"
            startIcon={<MailIcon />}
            href="mailto:sales@thingslog.com"
            className="text-gray-900 font-bold"
          >
            sales@thingslog.com
          </Button>
        )}
      </div>
    </div>
  );
};

interface LicenseWarningBoxProps {
  warningStatus: LicenseWarningBoxStatus;
  translation: {
    accessOnHoldTitle: string;
    accessOnHoldDescription: string;
    noValidLicenseTitle: string;
    noValidLicenseDescription: string;
    deviceLimitReachedTitle: string;
    deviceLimitReachedDescription: string;
    defaultGenericTitle: string;
    defaultGenericDescription: string;
  };
}

export default LicenseWarningBox;
