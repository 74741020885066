import { ClientCount, DataPointsCounterDto } from '@thingslog/repositories';
import { DataGrid, DataGridProps, GridValueFormatterParams } from '@thingslog/ui-components';
import React, { FC, useEffect, useState } from 'react';

import { t } from 'i18next';
import { format } from 'date-fns';

const BillingReportTable: FC<BillingReportTableProps> = ({
  clientCountData,
  ...props
}: BillingReportTableProps) => {
  const [rows, setRows] = useState<BillingReportTableRows[]>([]);

  useEffect(() => {
    if (clientCountData) {
      const rows = Object.entries(clientCountData.deviceDataPoints).map(
        ([deviceNumber, deviceData]: [string, DataPointsCounterDto]) => ({
          deviceNumber,
          firstReadingDate: deviceData.fromDate,
          lastReadingDate: deviceData.toDate,
          readings: deviceData.readingsCount,
          battery: deviceData.batteryCount,
          signal: deviceData.signalCount,
          total: deviceData.totalCount,
          id: deviceNumber,
        })
      );
      setRows(rows);
    } else {
      setRows([]);
    }
  }, [clientCountData]);
  return (
    <DataGrid
      showPrintOption={false}
      selectAllEnabled={true}
      rows={rows}
      columns={[
        {
          field: 'deviceNumber',
          headerName: t('billing_report_device_number'),
          minWidth: 150,
          type: 'string',
          flex: 1,
        },
        {
          field: 'firstReadingDate',
          headerName: t('billing_report_first_reading_date'),
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            format(new Date(params.value as string), 'dd/MM/yyyy'),
          minWidth: 180,
          type: 'date',
          flex: 1,
        },

        {
          field: 'lastReadingDate',
          headerName: t('billing_report_last_reading_date'),
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            format(new Date(params.value as string), 'dd/MM/yyyy'),
          minWidth: 180,
          type: 'date',
          flex: 1,
        },
        {
          field: 'readings',
          headerName: t('billing_report_readings'),
          minWidth: 140,
          type: 'number',
          flex: 0.8,
        },
        {
          field: 'battery',
          headerName: t('billing_report_battery'),
          minWidth: 140,
          type: 'number',
          flex: 0.8,
        },
        {
          field: 'signal',
          headerName: t('billing_report_signal'),
          minWidth: 140,
          type: 'number',
          flex: 0.8,
        },
        {
          field: 'total',
          headerName: t('billing_report_total'),
          minWidth: 140,
          type: 'number',
          flex: 0.8,
        },
      ]}
      {...props}
    />
  );
};

export default BillingReportTable;

interface BillingReportTableRows {
  deviceNumber: string;
  firstReadingDate: string;
  lastReadingDate: string;
  readings: number;
  battery: number;
  signal: number;
  total: number;
}

interface BillingReportTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  clientCountData: ClientCount | null;
}
