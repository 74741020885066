import { createContext } from 'react';
import { ToastContextProps } from './model/ToastContextProps';

const ToastContext = createContext<ToastContextProps>({
  toast: () => {},
  remove: () => {},
  position: 'bottomCenter',
});

export default ToastContext;
