import Axios from './clients/Axios/Axios';

function getDeviceCounters(deviceNumber, sensorIndex, startDate, endDate, cb, ecb) {
  return Axios.get(
    `/api/v2/devices/${deviceNumber}/${sensorIndex}/readings?fromDate=${startDate}&toDate=${endDate}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDeviceCountersAvg(
  deviceNumber,
  sensorIndex,
  avgStartDate,
  avgEndDate,
  startDate,
  endDate,
  cb,
  ecb
) {
  let targetStartDate = new Date(startDate).toISOString().substring(0, 10);
  let targetEndDate = new Date(endDate).toISOString().substring(0, 10);
  return Axios.get(
    `/api/v2/devices/${deviceNumber}/${sensorIndex}/readings/avg/?fromDate=${avgStartDate}&toDate=${avgEndDate}&targetFromDate=${targetStartDate}&targetToDate=${targetEndDate}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceCountersClient = {
  getDeviceCounters,
  getDeviceCountersAvg,
};

export default DeviceCountersClient;
