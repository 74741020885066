import React, { FC, ReactNode, SyntheticEvent, useState, useMemo } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Autocomplete, TextField, Box, Tab, AutocompleteRenderInputParams } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { UserDto } from '@thingslog/repositories';
import MapSettingsModalButtons from './MapSettingsModalButtons';
import { useModal } from '@thingslog/ui-components';

const SetMapSettingsModal: FC<SetMapSettingsModalProps> = ({
  users,
  isAdmin,
  saveMapSettings,
}: SetMapSettingsModalProps) => {
  const [openTab, setOpenTab] = useState<TabOption>('self');
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);

  const { t } = useTranslation();
  const { closeModal } = useModal();

  const { username, companyName } = useSelector((state: ReduxState) => state.auth);
  const selectedCompanyName = useSelector((state: ReduxState) => state.company.name);

  const userOptions = useMemo(() => {
    if (users) {
      return users.map((user: UserDto) => user.username);
    } else {
      return [];
    }
  }, [users]);

  return (
    <div className="flex flex-col max-md:w-full -mb-4">
      <TabContext value={openTab}>
        {isAdmin && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(event: SyntheticEvent, tab: TabOption): void => {
                setOpenTab(tab);
              }}
            >
              <Tab label={t('map_coordinates_modal_target_option_self')} value="self" />
              <Tab label={t('map_coordinates_modal_target_option_company')} value="company" />
              <Tab
                disabled={users === undefined}
                label={t('map_coordinates_modal_target_option_user')}
                value="user"
              />
            </TabList>
          </Box>
        )}
        <TabPanel value="self">
          <div className="text-xl text-center font-extrabold mb-5">{username}</div>
          <div className="text-justify w-[400px] mb-10">
            {t('map_coordinates_modal_save_for_self_warning_message')}
          </div>
          <MapSettingsModalButtons
            onConfirm={(): void => {
              saveMapSettings();
              closeModal();
            }}
            onCancel={closeModal}
          />
        </TabPanel>
        <TabPanel value="company">
          <div className="text-xl text-center font-extrabold mb-5">
            {selectedCompanyName || companyName}
          </div>
          <div className="text-justify w-[400px] mb-10">
            {t('map_coordinates_modal_save_for_company_warning_message')}
          </div>
          <MapSettingsModalButtons
            onConfirm={(): void => {
              saveMapSettings(undefined, selectedCompanyName || companyName);
              closeModal();
            }}
            onCancel={closeModal}
          />
        </TabPanel>
        <TabPanel value="user">
          <Autocomplete
            className="mb-5"
            size="small"
            value={selectedUsername}
            options={userOptions}
            onChange={(event: SyntheticEvent, selectedUser: string | null): void =>
              setSelectedUsername(selectedUser)
            }
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                required
                {...params}
                label={t('map_coordinates_modal_select_user_autocomplete_label')}
                size="small"
              />
            )}
          />
          <div className="text-justify w-[400px] mb-10">
            {t('map_coordinates_modal_save_for_user_warning_message')}
          </div>
          <MapSettingsModalButtons
            disabled={selectedUsername === null}
            onConfirm={(): void => {
              saveMapSettings(selectedUsername!);
              closeModal();
            }}
            onCancel={closeModal}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

type TabOption = 'self' | 'company' | 'user';

interface SetMapSettingsModalProps {
  users?: UserDto[];
  isAdmin: boolean;
  saveMapSettings: (userName?: string, companyName?: string) => void;
}

export default SetMapSettingsModal;
