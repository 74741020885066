export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_BILLING = 'ROLE_BILLING';

export function hasRole(auth, aRole) {
  let roles = auth.roles;
  if (roles) {
    for (const role of roles) {
      if (role.authority === aRole) {
        return true;
      }
    }
  }
  return false;
}
