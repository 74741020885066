import React, { FC } from 'react';

const ColorLegend: FC<ColorLegendProps> = ({ items }: ColorLegendProps) => (
  <div className="grid  flex-col space-y-2">
    {items.map((item: LegendItem, index: number) => (
      <div className="flex" key={index}>
        <span
          className="flex-shrink-0 w-2"
          style={{
            backgroundColor: item.legendColor,
          }}
        ></span>
        <div className="ml-3 text-left">{item.text}</div>
      </div>
    ))}
  </div>
);

export default ColorLegend;

interface ColorLegendProps {
  items: LegendItem[];
}

interface LegendItem {
  legendColor: string;
  text: string | JSX.Element;
}
