import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const RedirectToGraphDialog: FC<RedirectToGraphDialogProps> = (
  props: RedirectToGraphDialogProps
) => {
  const { t } = useTranslation();

  return (
    <div className="p-3">
      <Button
        variant="contained"
        color="primary"
        onClick={(): void => props.onConfirm()}
        className="w-full mb-4"
      >
        {t('alarms_redirect_to_graph')}
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={(): void => props.onCancel()}
        className="w-full"
      >
        {t('alarms_return_to_alarms')}
      </Button>
    </div>
  );
};

interface RedirectToGraphDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export default RedirectToGraphDialog;
