import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  LinearProgress,
  Box,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrimaryColorTableCell from '../../../components/PrimaryColorTableCell/PrimaryColorTableCell';
import Statistics from '../../../model/API/Statistics/Statistics';

const GeneralStatisticsTable: React.FC<GeneralStatisticsTableProps> = ({
  general,
  loading,
  error,
}: GeneralStatisticsTableProps) => {
  const { t } = useTranslation();

  const renderContent = (): JSX.Element => {
    if (general) {
      return (
        <TableBody>
          <TableRow hover>
            <TableCell align="center">
              <Box fontWeight="fontWeightMedium">{t('statistics_quantity_minimum')}</Box>
            </TableCell>
            <TableCell align="center">
              {general.min?.toFixed(2) || 0} m<sup>3</sup>/h
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell align="center">
              <Box fontWeight="fontWeightMedium">{t('statistics_quantity_maximum')}</Box>
            </TableCell>
            <TableCell align="center">
              {general.max?.toFixed(2) || 0} m<sup>3</sup>/h
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell align="center">
              <Box fontWeight="fontWeightMedium">{t('statistics_quantity_average')}</Box>
            </TableCell>
            <TableCell align="center">
              {general.avg?.toFixed(2) || 0} m<sup>3</sup>/h
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell align="center">
              <Box fontWeight="fontWeightMedium">{t('statistics_quantity_total')}</Box>
            </TableCell>
            <TableCell align="center">
              {general.total?.toFixed(2) || 0} m<sup>3</sup>/h
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell align="center">
              <Box fontWeight="fontWeightMedium">{t('statistics_quantity_hours')}</Box>
            </TableCell>
            <TableCell align="center">{general.hours} h</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell align="center">
              <Box fontWeight="fontWeightMedium">{t('statistics_quantity_counters')}</Box>
            </TableCell>
            <TableCell align="center">{general.counters}</TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow hover>
          <TableCell colSpan={3} align="center">
            <Typography variant="subtitle1" color="gray" paddingTop={8} paddingBottom={8}>
              {error || t('no_data_found')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <PrimaryColorTableCell align="center">
              {t('statistics_header_quantity')}
            </PrimaryColorTableCell>
            <PrimaryColorTableCell align="center">
              {t('statistics_header_value')}
            </PrimaryColorTableCell>
          </TableRow>
        </TableHead>
        {!loading && renderContent()}
      </Table>
      {loading && <LinearProgress color="primary" />}
    </TableContainer>
  );
};

interface GeneralStatisticsTableProps {
  general: Statistics | null;
  loading: boolean;
  error: string | null;
}

export default GeneralStatisticsTable;
