export enum GaEventCategory {
  /* NAVBAR CATEGORIES */
  MENU_NAVIGATION = 'Menu Navigation',
  PROFILE_PAGE = 'Profile Page',

  /* DEFAULT PAGE CATEGORIES */
  SELECTED_DEVICE_PAGE = 'Selected Device Page',
  DEVICES_PAGE = 'Devices Page',
  MAP_PAGE = 'Map Page',
  TRANSMISSIONS_PAGE = 'Transmissions Page',
  INVENTORY_PAGE = 'Inventory Page',
  LINK_DEVICE_PAGE = 'Link Device Page',
  USERS_PAGE = 'Users Page',
  CREATE_USER_PAGE = 'Create User Page',
  DEVICE_MANAGER_PAGE = 'Device Manager Page',
  ACCOUNTS_PAGE = 'Accounts Page',
  MANAGE_ACCOUNTS_PAGE = 'Manage Accounts Page',
  DEVICES_BATTERY_PAGE = 'Devices Battery Page',
  DEVICES_SIGNAL_PAGE = 'Devices Signal Page',
  DASHBOARD_PAGE = 'Dashboard Page',
  REPORTS_PAGE = 'Reports Page',
  ALL_ALARMS_PAGE = 'All Alarms Page',

  /* DEVICE PAGE CATEGORIES */
  ALL_DEVICES_PAGE = 'All Devices Page',
  READINGS_PAGE = 'Readings Page',
  NEW_GRAPH_PAGE = 'New Graph Page',
  FLOW_GRAPH_PAGE = 'Flow Graph Page',
  GRAPH_PAGE = 'Graph Page',
  ALARM_CONFIG_PAGE = 'Alarm Config Page',
  DEVICE_MAP_PAGE = 'Device Map Page',
  STATISTICS_PAGE = 'Statistics Page',
  EXPORT_PAGE = 'Export Page',
  CUSTOMER_PAGE = 'Customer Page',
  CONFIG_PAGE = 'Config Page',
  INITIAL_CONFIG_PAGE = 'Initial Config Page',
  SENSOR_CONFIG_PAGE = 'Sensor Config Page',
  DEVICE_SIGNAL_PAGE = 'Device Signal Page',
  DEVICE_BATTERY_PAGE = 'Device Battery Page',
  BATTERY_STATUS_PAGE = 'Battery Status Page',
  ALARMS_PAGE = 'Alarms Page',
}
