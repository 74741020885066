import { SensorDefaultQueryDto } from '@thingslog/repositories';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

interface SensorDefaultsContextType {
  sensorDefaults: SensorDefaultQueryDto | null;
  setSensorDefaults: Dispatch<SetStateAction<SensorDefaultQueryDto | null>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const SensorDefaultsContext = createContext<SensorDefaultsContextType | undefined>(undefined);

export const useSensorDefaults = (): SensorDefaultsContextType => {
  const context = useContext(SensorDefaultsContext);
  if (context === undefined) {
    throw new Error('useSensorDefaults must be used within a SensorDefaultsProvider');
  }
  return context;
};

interface SensorDefaultsProviderProps {
  children: ReactNode;
}

export const SensorDefaultsProvider: React.FC<SensorDefaultsProviderProps> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [sensorDefaults, setSensorDefaults] = useState<SensorDefaultQueryDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <SensorDefaultsContext.Provider
      value={{ sensorDefaults, setSensorDefaults, isLoading, setIsLoading }}
    >
      {children}
    </SensorDefaultsContext.Provider>
  );
};
