import React, { FC, useState } from 'react';
import { Button, Divider, TextField } from '@mui/material';
import { useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';

const ChildCompaniesModal: FC<ChildCompaniesModalProps> = ({
  childCompanies,
}: ChildCompaniesModalProps) => {
  const { t } = useTranslation();
  const { modal, closeModal } = useModal();

  const [filteredCompanies, setFilteredCompanies] = useState<string[]>(childCompanies);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const filtered = childCompanies.filter((company: string) =>
      company.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  return (
    <>
      <div className="space-y-6">
        <div className="my-6">
          <TextField
            fullWidth
            size={'small'}
            variant="outlined"
            label={t('search_placeholder')}
            onChange={handleSearch}
            autoComplete="off"
          />
        </div>
        <div className="overflow-auto h-[180px] w-[240px]">
          {filteredCompanies.map((childCompanyName: string, index: number) => (
            <React.Fragment key={index}>
              <div className="p-2 break-words">{childCompanyName}</div>
              <Divider />
            </React.Fragment>
          ))}
        </div>
        <div className="flex p-5 justify-center items-center space-x-5">
          <Button variant="contained" color="primary" className="w-5/6" onClick={closeModal}>
            {t('close_btn')}
          </Button>
        </div>
      </div>
    </>
  );
};

interface ChildCompaniesModalProps {
  childCompanies: string[];
}

export default ChildCompaniesModal;
