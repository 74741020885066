import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { FormulaValidationProps, MutationOptions, MutationResult } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { FormulaValidationResult } from '@thingslog/repositories';
import _ from 'lodash';

export interface FormulaValidatorQueryClient {
  validateFormula: (formula: string) => Promise<FormulaValidationResult>;
  useValidateFormula: (
    options?: MutationOptions<FormulaValidationResult, FormulaValidationProps>
  ) => MutationResult<FormulaValidationResult, FormulaValidationProps>;
}

export function createFormulaValidatorQueryClient(
  axios: AxiosInstance
): FormulaValidatorQueryClient {
  return new FormulaValidatorQueryClientImp(axios);
}

class FormulaValidatorQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public validateFormula = async (formula: string): Promise<FormulaValidationResult> => {
    const paramsRegex = /(S(?:1[0-3]|[0-9])|x)/g;
    const formulaParams = formula.match(paramsRegex);
    const uniqueFormulaParams = _.uniq(formulaParams);

    return await this.axios
      .post(`/api/formula-validator`, {
        formula: formula,
        params: uniqueFormulaParams,
      })
      .then((response: AxiosResponse) => response.data);
  };

  public useValidateFormula = (
    options?: MutationOptions<FormulaValidationResult, FormulaValidationProps>
  ): MutationResult<FormulaValidationResult, FormulaValidationProps> => {
    return useMutation<FormulaValidationResult, AxiosError, FormulaValidationProps>(
      [QueryKeys.ValidateFormula],
      (props: FormulaValidationProps) => this.validateFormula(props.formula),
      options
    );
  };
}
