import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDeleteCompanyModal from './ConfirmDeleteCompanyModal';
import UpdateCompanyModal from '../../../components/UpdateCompanyModal/UpdateCompanyModal';
import {
  CompanyDto,
  CountriesListDto,
  LicenseDto,
  LicenseType,
  UpdateCompanyDto,
} from '@thingslog/repositories';
import { AxiosError } from 'axios';
import { UseMutateFunction } from '@tanstack/react-query';
const AccountActions: FC<AccountActionsProps> = ({
  company,
  timezones,
  countriesListDto,
  updateCompanyMutation,
  deleteCompanyMutation,
  onSelectCompanyForEditing,
  licensesForEditingCompany,
}: AccountActionsProps) => {
  const { t } = useTranslation();
  const [updateAccountRefHandler, setUpdateAccountRefHandler] = useState<
    ((isDisabled: boolean) => void) | null
  >(null);

  const { modal } = useModal();

  const handleSetAccountRefDisabled = useCallback(
    (accountRefUpdater: (isDisabled: boolean) => void) => {
      setUpdateAccountRefHandler(() => accountRefUpdater);
    },
    []
  );

  const updateCompanyHandler = async (): Promise<void> => {
    modal({
      title: t('company_manager_update_company'),
      content: (
        <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
          <UpdateCompanyModal
            timezones={timezones}
            countriesListDto={countriesListDto}
            company={company}
            updateCompany={updateCompanyMutation}
            setDisableAccountRefCallback={handleSetAccountRefDisabled}
          />
        </div>
      ),
    });
  };

  const deleteCompanyHandler = (): void => {
    modal({
      title: t('company_manager_delete_company'),
      content: (
        <ConfirmDeleteCompanyModal
          companyName={company.name}
          deleteCompany={deleteCompanyMutation}
        />
      ),
    });
  };

  useEffect(() => {
    if (updateAccountRefHandler !== null && licensesForEditingCompany !== undefined) {
      updateAccountRefHandler(
        licensesForEditingCompany.some(
          (license: LicenseDto) => license['@type'] === LicenseType.PER_POC
        )
      );
    } else if (updateAccountRefHandler !== null) {
      updateAccountRefHandler(false);
    } else {
      setUpdateAccountRefHandler(null);
    }
  }, [licensesForEditingCompany]);

  return (
    <Box display="flex">
      <Tooltip title={<span>{t('company_manager_update_company')}</span>} arrow placement="top">
        <IconButton
          size="large"
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
            event?.stopPropagation();
            onSelectCompanyForEditing(company.id);
            updateCompanyHandler();
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>{t('company_manager_delete_company')}</span>} arrow placement="top">
        <IconButton
          size="large"
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
            event?.stopPropagation();
            deleteCompanyHandler();
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

interface AccountActionsProps {
  company: CompanyDto;
  licensesForEditingCompany: LicenseDto[] | undefined;
  timezones: Set<string>;
  countriesListDto: CountriesListDto | null;
  deleteCompanyMutation: UseMutateFunction<void, AxiosError<any, any>, string, unknown>;
  updateCompanyMutation: UseMutateFunction<void, AxiosError<any, any>, UpdateCompanyDto, unknown>;
  onSelectCompanyForEditing: (companyId: number) => void;
}

export default AccountActions;
