import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Link } from 'react-router-dom';

class Signout extends Component {
  componentWillMount() {
    this.props.signoutUser();
    this.props.setSolutionSettings({ data: null });
  }

  render() {
    return (
      <div>
        <br />
        <p>
          You are logged out. Click <Link to="/app">here</Link> to login again
        </p>
      </div>
    );
  }
}

export default connect(null, actions)(Signout);
