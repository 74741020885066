import Axios from './clients/Axios/Axios';

function getDeviceConfig(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/config`).then(parseJSON).then(cb).catch(ecb);
}

function getDeviceConfigAsync(deviceNumber) {
  return Axios.get(`/api/devices/${deviceNumber}/config`).then(parseJSON);
}

function putDeviceConfig(deviceNumber, deviceConfig, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/devices/${deviceNumber}/config`, deviceConfig, config)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceConfigClient = {
  getDeviceConfig,
  putDeviceConfig,
  getDeviceConfigAsync,
};

export default DeviceConfigClient;
