import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReduxState } from '../../reducers';
import * as periodActionsCreators from '../../state_management/actions/PeriodSelectionActionCreator';
import { PickerPeriod } from '../../model/Pickers/PickerPeriod';
import { styles } from './PeriodPicker.styles';
import { useTheme } from '@mui/material';
import { LocalizedDatePicker } from '@thingslog/ui-components';
import { addDays, isAfter, isBefore, isValid, subDays } from 'date-fns';
import { MUI_LIMIT_DATE_PICKER_MAX_DATE, MUI_LIMIT_DATE_PICKER_MIN_DATE } from './DatePickerLimits';

const ConnectedAverageDatePicker: FC<ConnectedAverageDatePickerProps> = ({
  period,
  dateDifference,
}: ConnectedAverageDatePickerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeAvgFromDate, changeAvgToDate } = bindActionCreators(
    periodActionsCreators,
    dispatch
  );
  const theme = useTheme();
  const classes = styles(theme);
  const avgFromDateRedux = useSelector((state: ReduxState) => state.period.avgFromDate);
  const avgToDateRedux = useSelector((state: ReduxState) => state.period.avgToDate);

  const [avgFromDate, setAvgFromDate] = useState<Date | null>(avgFromDateRedux);
  const [avgToDate, setAvgToDate] = useState<Date | null>(avgToDateRedux);
  const [minAvgFromDate, setMinAvgFromDate] = useState<Date | undefined>();
  const [maxAvgToDate, setMaxAvgToDate] = useState<Date | undefined>();

  useEffect(() => {
    setMinAvgFromDate(dateDifference ? subDays(avgToDateRedux, dateDifference) : undefined);
  }, [avgToDateRedux]);

  useEffect(() => {
    setMaxAvgToDate(dateDifference ? addDays(avgFromDateRedux, dateDifference) : undefined);
  }, [avgFromDateRedux]);

  useEffect(() => {
    if (avgFromDate === null || !isValid(avgFromDate)) return;
    if (isAfter(avgFromDate, avgToDateRedux)) return;
    if (minAvgFromDate && isBefore(avgFromDate, minAvgFromDate)) return;
    if (isBefore(avgFromDate, MUI_LIMIT_DATE_PICKER_MIN_DATE)) return;

    changeAvgFromDate(avgFromDate);
  }, [avgFromDate]);

  useEffect(() => {
    if (avgToDate === null || !isValid(avgToDate)) return;
    if (isBefore(avgToDate, avgFromDateRedux)) return;
    if (maxAvgToDate && isAfter(avgToDate, maxAvgToDate)) return;
    if (isAfter(avgToDate, MUI_LIMIT_DATE_PICKER_MAX_DATE)) return;

    changeAvgToDate(avgToDate);
  }, [avgToDate]);

  if (period === PickerPeriod.FROM) {
    return (
      <LocalizedDatePicker
        label={t('avg_from_date')}
        value={avgFromDate}
        onChange={(date: Date | null): void => {
          setAvgFromDate(date);
        }}
        minDate={minAvgFromDate}
        maxDate={avgToDateRedux}
        inputFormat="dd/MM/yyyy"
        renderInputTextField={{
          size: 'small',
          className: classes.fixedWidthTextField,
          onBlur: (): void => {
            avgFromDate !== avgFromDateRedux && setAvgFromDate(avgFromDateRedux);
          },
        }}
      />
    );
  } else if (period === PickerPeriod.TO) {
    return (
      <LocalizedDatePicker
        label={t('avg_to_date')}
        value={avgToDate}
        onChange={(date: Date | null): void => {
          setAvgToDate(date);
        }}
        minDate={avgFromDateRedux}
        maxDate={maxAvgToDate}
        inputFormat="dd/MM/yyyy"
        renderInputTextField={{
          size: 'small',
          className: classes.fixedWidthTextField,
          onBlur: (): void => {
            avgToDate !== avgToDateRedux && setAvgToDate(avgToDateRedux);
          },
        }}
      />
    );
  }
  return <></>;
};

interface ConnectedAverageDatePickerProps {
  period: PickerPeriod;
  dateDifference?: number;
}

export default ConnectedAverageDatePicker;
