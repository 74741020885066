import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  Ref,
  RefAttributes,
  ForwardedRef,
  MutableRefObject,
} from 'react';
import clsx from 'clsx';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  MarkerProps,
  PopupProps,
  MapContainerProps,
  useMap,
  Polygon,
  PolygonProps,
} from 'react-leaflet';
import { Map as LeafletMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface MapContentProps {
  markers: MapMarker[];
  polygons?: MapPolygon[];
  ref: React.Ref<{ map: LeafletMap | null }>;
}

const MapContent: FC<MapContentProps> = forwardRef(
  ({ markers, polygons }: MapContentProps, ref: ForwardedRef<{ map: LeafletMap | null }>) => {
    const map = useMap();

    // Expose the map object
    useImperativeHandle(ref, () => ({
      map,
    }));

    return (
      <>
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={19} />
        {markers.map((marker: MapMarker, idx: number) => (
          <Marker key={`marker-${idx}`} {...marker.markerProps}>
            {marker.popupProps && <Popup {...marker.popupProps} />}
          </Marker>
        ))}
        {polygons &&
          polygons.map((polygon: MapPolygon, idx: number) => (
            <Polygon key={`poly-${idx}`} {...polygon} />
          ))}
      </>
    );
  }
);

interface MapProps extends MapContainerProps, RefAttributes<{ map: LeafletMap | null }> {
  markers: MapMarker[];
  polygons?: MapPolygon[];
}

const Map: FC<MapProps> = forwardRef(
  (
    { markers, polygons, className, ...props }: MapProps,
    ref: ForwardedRef<{
      map: LeafletMap | null;
    }>
  ) => {
    return (
      <MapContainer className={clsx('w-full z-0 h-full', className)} {...props}>
        <MapContent markers={markers} polygons={polygons} ref={ref} />
      </MapContainer>
    );
  }
);

export { LeafletMap };

export interface MapMarker {
  markerProps: MarkerProps;
  popupProps?: PopupProps;
}

export interface MapPolygon extends PolygonProps {}

export default Map;
