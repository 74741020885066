// import { Dispatch } from 'redux';
import UserClient from '../../clients/User/UserClient';
import { AuthActionType, AuthErrorAction, AuthUserAction } from './AuthAction';
import jwtDecode from 'jwt-decode';
import { Dispatch } from 'react';
import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import Axios from '../../clients/Axios/Axios';

const authUser = (
  username: string,
  roles: JwtRole[],
  companyName: string,
  email: string,
  language: string,
  isDashboardEnabled: boolean
): AuthUserAction => {
  return {
    type: AuthActionType.AUTH_USER,
    payload: {
      username: username,
      roles: roles,
      companyName: companyName,
      email: email,
      language: language,
      authenticated: true,
      error: null,
      signedup: false,
      isDashboardEnabled: isDashboardEnabled,
    },
  };
};

const authError = (error: string): AuthErrorAction => {
  return {
    type: AuthActionType.AUTH_ERROR,
    payload: error,
  };
};

export const loginUser = (
  username: string,
  password: string,
  recaptchaToken: string | null
): ((dispatch: Dispatch<AuthUserAction | AuthErrorAction>) => void) => {
  return async (dispatch: Dispatch<AuthUserAction | AuthErrorAction>): Promise<void> => {
    try {
      const rloginResponse = await UserClient.rlogin(username, password, recaptchaToken);
      if (rloginResponse.status === 200) {
        const token = rloginResponse.headers.authorization;
        localStorage.setItem('token', token);
        const decoded: any = jwtDecode(token);
        localStorage.setItem('decoded_token', JSON.stringify(decoded));
        Axios.defaults.headers.common['Authorization'] = token;
        dispatch(
          authUser(
            decoded.sub,
            decoded.roles,
            decoded.companyName,
            decoded.email,
            decoded.language,
            decoded.isDashboardEnabled
          )
        );
      } else {
        dispatch(authError('Could not log in.'));
      }
    } catch (error) {
      dispatch(authError('Invalid username or password.'));
    }
  };
};
