import { COMPANY_SELECTED, COMPANY_LOGO_SET } from '../actions/types';
import {
  CompanySelectedAction,
  CompanySelectionAction,
  CompanySetLogoAction,
} from '../state_management/actions/CompanySelectionAction';

export default function devReducer(
  state: CompanySelectionState = noState,
  action: CompanySelectionAction
): CompanySelectionState {
  switch (action.type) {
    case COMPANY_SELECTED:
      const companySelectedAction = action as CompanySelectedAction;
      const company = companySelectedAction.company;
      return { ...state, id: company.id, name: company.name };
    case COMPANY_LOGO_SET:
      const companyLogoSetAction = action as CompanySetLogoAction;
      const companyLogoBlob = companyLogoSetAction.companyLogoBlob;
      return { ...state, companyLogoBlob: companyLogoBlob };
    default:
      return state;
  }
}

const noState: CompanySelectionState = {
  id: null,
  name: null,
  companyLogoBlob: null,
};

export interface CompanySelectionState {
  id: number | null;
  name: string | null;
  companyLogoBlob: Blob | null;
}
