import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { TimeSeriesPredictionJson, TimeSeriesPredictionQueryParams } from '@thingslog/repositories';

export function createTimeSeriesPredictionQueryClient(
  axios: AxiosInstance
): TimeSeriesPredictionQueryClient {
  return new TimeSeriesPredictionQueryClientImp(axios);
}

class TimeSeriesPredictionQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public useTimeSeriesPredictionQueries = (
    args: TimeSeriesPredictionQueryParams<TimeSeriesPredictionJson>[]
  ): UseQueryResult<TimeSeriesPredictionJson, AxiosError>[] =>
    useQueries({
      queries: args.map(
        ({
          deviceNumber,
          sensorIndex,
          startDate,
          endDate,
          every,
          forwardDays,
          backwardDays,
          options,
        }: TimeSeriesPredictionQueryParams<TimeSeriesPredictionJson>) => ({
          queryKey: [
            QueryKeys.GetTimeSeriesPrediction,
            deviceNumber,
            sensorIndex,
            startDate,
            endDate,
            every,
            forwardDays,
            backwardDays,
          ],
          queryFn: () =>
            this.getTimeSeriesPrediction(
              deviceNumber,
              sensorIndex,
              startDate,
              endDate,
              every,
              forwardDays,
              backwardDays
            ),
          ...options,
        })
      ),
    });

  public getTimeSeriesPrediction = async (
    deviceNumber: string,
    sensorIndex: number,
    startDate: Date,
    endDate: Date,
    every: number,
    forwardDays: number,
    backwardDays: number
  ): Promise<TimeSeriesPredictionJson> => {
    return await this.axios
      .get(`/api/prediction/devices/${deviceNumber}/${sensorIndex}`, {
        headers: { Accept: 'application/json' },
        params: {
          startDate: startDate,
          endDate: endDate,
          every: every,
          forwardDays: forwardDays,
          backwardDays: backwardDays,
          daysPrediction: 0,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };
}

export interface TimeSeriesPredictionQueryClient {
  useTimeSeriesPredictionQueries: (
    args: TimeSeriesPredictionQueryParams<TimeSeriesPredictionJson>[]
  ) => UseQueryResult<TimeSeriesPredictionJson, AxiosError>[];

  getTimeSeriesPrediction: (
    deviceNumber: string,
    sensorIndex: number,
    startDate: Date,
    endDate: Date,
    every: number,
    forwardDays: number,
    backwardDays: number,
    daysPrediction: number
  ) => Promise<TimeSeriesPredictionJson>;
}
