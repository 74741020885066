import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import { DevicePredictionJson } from '../../model/API/DevicePrediction/DevicePrediction';

export default class DevicePredictionRepository {
  public getDevicePredictionJson = async (
    deviceNumber: string,
    selectedSensorIndex: number,
    startDate: Date,
    endDate: Date,
    forwardDays: number,
    backwardDays: number,
    every: number,
    newTrain: number,
    daysPrediction: number
  ): Promise<DevicePredictionJson> => {
    const startDateNoMilliseconds = startDate.toISOString().slice(0, -5);
    const endDateNoMilliseconds = endDate.toISOString().slice(0, -5);

    return await Axios.get(`/api/prediction/devices/${deviceNumber}/${selectedSensorIndex}`, {
      headers: { Accept: 'application/json' },
      params: {
        startDate: startDateNoMilliseconds,
        endDate: endDateNoMilliseconds,
        forwardDays,
        backwardDays,
        every,
        newTrain: 0,
        daysPrediction: 0,
      },
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  };

  public getDevicePredictionImg = async (
    deviceNumber: string,
    selectedSensorIndex: number,
    startDate: Date,
    endDate: Date,
    forwardDays: number,
    backwardDays: number,
    every: number,
    newTrain: number,
    daysPrediction: number
  ): Promise<string> => {
    const startDateNoMilliseconds = startDate.toISOString().slice(0, -5);
    const endDateNoMilliseconds = endDate.toISOString().slice(0, -5);

    return await Axios.get(`/api/prediction/devices/${deviceNumber}/${selectedSensorIndex}`, {
      headers: {
        Accept: 'image/png',
      },
      params: {
        startDate: startDateNoMilliseconds,
        endDate: endDateNoMilliseconds,
        forwardDays,
        backwardDays,
        every,
        newTrain: 0,
        daysPrediction: 0,
      },
      responseType: 'arraybuffer',
    }).then((response: AxiosResponse) => response.data);
  };
}
