import { useMutation, useQuery } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import {
  EventValueRange,
  EventValueRangeConfig,
  MutateEventValueRange,
  MutationResult,
  QueryResult,
  SensorDefaultQueryDto,
  SensorType,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions, MutationOptions } from '@thingslog/repositories';

export interface SensorDefaultConfigQueryClient {
  getSensorTypeConfig: (type: SensorType) => Promise<SensorDefaultQueryDto>;
  useGetSensorTypeConfig: (
    type: SensorType,
    options?: QueryOptions<SensorDefaultQueryDto>
  ) => QueryResult<SensorDefaultQueryDto>;
}

export function createSensorDefaultConfigQueryClient(
  axios: AxiosInstance
): SensorDefaultConfigQueryClient {
  return new SensorDefaultConfigQueryClientImpl(axios);
}

class SensorDefaultConfigQueryClientImpl {
  public constructor(private axios: AxiosInstance) {}

  public getSensorTypeConfig = async (type: SensorType): Promise<SensorDefaultQueryDto> => {
    return await this.axios
      .get(`/api/v2/sensor-types/${type}/defaults`)
      .then((response: AxiosResponse) => response.data);
  };

  public useGetSensorTypeConfig = (
    type: SensorType,
    options?: QueryOptions<SensorDefaultQueryDto>
  ): QueryResult<SensorDefaultQueryDto> => {
    return useQuery<SensorDefaultQueryDto, AxiosError>(
      [QueryKeys.SensorTypeConfig, type],
      () => this.getSensorTypeConfig(type),
      options
    );
  };
}
