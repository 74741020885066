import React, { FC } from 'react';

import { DeviceType } from '@thingslog/repositories';
import LoRaNetworkConfiguration from './networkConfigs/LoRaNetworkConfiguration';
import GPRSNetworkConfiguration from './networkConfigs/GPRSNetworkConfiguration';
import NbIoTNetworkConfiguration from './networkConfigs/NbIoTNetworkConfiguration';
import ModbusSlaveNetworkConfiguration from './networkConfigs/ModbusSlaveNetworkConfiguration';
import LocalControllerNetworkConfiguration from './networkConfigs/LocalControllerNetworkConfiguration';

const NetworkConfigurationMapper: FC<NetworkConfigurationMapperProps> = ({
  deviceType,
}: NetworkConfigurationMapperProps) => {
  switch (deviceType) {
    case 'BL_100_L':
    case 'INNOTAS_WATERMETER':
    case 'LPMDL_1103':
    case 'LPMDL_1105':
    case 'W1':
    case 'ZENNER_EDC':
    case 'ZENNER_MICRO':
      return <LoRaNetworkConfiguration />;
    case 'LPMDL_1101':
    case 'LPMDL_1104':
    case 'LPMDL_1106':
      return <GPRSNetworkConfiguration />;
    case 'LPMDL_1102':
      return <NbIoTNetworkConfiguration />;
    case 'MODBUS_SLAVE':
      return <ModbusSlaveNetworkConfiguration />;
    case 'LOCAL_CONTROLLER':
      return <LocalControllerNetworkConfiguration />;
    default:
      return <></>;
  }
};

interface NetworkConfigurationMapperProps {
  deviceType: DeviceType;
}

export default NetworkConfigurationMapper;
