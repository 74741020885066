import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import {
  MutationOptions,
  QueryOptions,
  MutationResult,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { CompanyInfoDto } from '@thingslog/repositories';

export interface CompanyInfoQueryClient {
  getCompanyInfo: (companyId: number) => Promise<CompanyInfoDto>;
  useCompanyInfo: (
    companyId: number,
    options?: QueryOptions<CompanyInfoDto>
  ) => QueryResult<CompanyInfoDto>;
  updateCompanyInfo: (companyId: number, body: CompanyInfoDto) => Promise<void>;
  useUpdateCompanyInfo: (
    companyId: number,
    options?: MutationOptions<void, CompanyInfoDto>
  ) => MutationResult<void, CompanyInfoDto>;
  deleteCompanyInfo: (companyId: number) => Promise<void>;
  useDeleteCompanyInfoData: (
    companyId: number,
    options?: MutationOptions<void, void>
  ) => MutationResult<void, void>;
}

export function createCompanyInfoQueryClient(axios: AxiosInstance): CompanyInfoQueryClient {
  return new CompanyInfoQueryClientImp(axios);
}

class CompanyInfoQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getCompanyInfo = async (companyId: number): Promise<CompanyInfoDto> => {
    return await this.axios
      .get(`/api/companies/info/${companyId}`)
      .then((response: AxiosResponse) => response.data);
  };

  public useCompanyInfo = (
    companyId: number,
    options?: QueryOptions<CompanyInfoDto>
  ): QueryResult<CompanyInfoDto> => {
    return useQuery<CompanyInfoDto, AxiosError>(
      [QueryKeys.GetUser, companyId],
      () => this.getCompanyInfo(companyId),
      options
    );
  };

  public updateCompanyInfo = async (companyId: number, body: CompanyInfoDto): Promise<void> => {
    return await this.axios
      .put(`/api/companies/info/${companyId}`, body)
      .then((response: AxiosResponse) => response.data);
  };

  public useUpdateCompanyInfo = (
    companyId: number,
    options?: MutationOptions<void, CompanyInfoDto>
  ): MutationResult<void, CompanyInfoDto> => {
    return useMutation<void, AxiosError, CompanyInfoDto>(
      [QueryKeys.UpdateCompanyInfo, companyId],
      (variables: CompanyInfoDto) => this.updateCompanyInfo(companyId, variables),
      options
    );
  };

  public deleteCompanyInfo = async (companyId: number): Promise<void> => {
    return await this.axios
      .delete(`/api/companies/info/${companyId}`)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  };

  public useDeleteCompanyInfoData = (
    companyId: number,
    options?: MutationOptions<void, void>
  ): MutationResult<void, void> => {
    return useMutation<void, AxiosError, void>(
      [QueryKeys.DeleteCompanyInfo, companyId],
      () => this.deleteCompanyInfo(companyId),
      options
    );
  };
}
