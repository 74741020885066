import Axios from './clients/Axios/Axios';

function postFlowGenerator(deviceNumber, sensorIndex, flowGeneratorDto, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.post(
    `/api/devices/${deviceNumber}/sensors/${sensorIndex}/flow-generator`,
    flowGeneratorDto,
    config
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const FlowGeneratorClient = {
  postFlowGenerator,
};
export default FlowGeneratorClient;
