import { Button } from '@mui/material';
import { DeviceLocationDto } from '@thingslog/repositories';
import { useModal } from '@thingslog/ui-components';
import React, { FC, ChangeEvent, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

const MutateLocationPopup: FC<MutateLocationPopupProps> = ({
  initialLocation,
  onUpdatedLocation,
}: MutateLocationPopupProps) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const [latitude, setLatitude] = useState<string | number>(initialLocation.latitude);
  const [longitude, setLongitude] = useState<string | number>(initialLocation.longitude);
  const [coordinates, setCoordinates] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const isValidLatitude = (latitude: string): boolean => {
    const LATITUDE_REGEX = /^(-?[1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    return LATITUDE_REGEX.test(latitude);
  };

  const isValidLongitude = (longitude: string): boolean => {
    const LONGITUDE_REGEX = /^(-?(?:1[0-7]|[1-9])?\d(\.\d+)?|180(\.0+)?)$/;
    return LONGITUDE_REGEX.test(longitude);
  };

  const isValidLocation = (latitude: string, longitude: string): boolean => {
    return isValidLatitude(latitude) && isValidLongitude(longitude);
  };

  const handleCoordinateChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value;
    setCoordinates(input);

    try {
      const parts = input.split(',').map((part: string) => part.trim());
      if (parts.length !== 2) throw new Error(t('map_invalid_coordinate_input'));

      const lat = parseFloat(parts[0]);
      const lng = parseFloat(parts[1]);

      if (isNaN(lat) || isNaN(lng)) throw new Error(t('map_invalid_coordinate_input'));

      setLatitude(lat);
      setLongitude(lng);
      setErrorMsg(null);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(t('map_invalid_location'));
      }
    }
  };

  const getLocation = (): void => {
    if (!navigator.geolocation) {
      setErrorMsg(t('map_geolocation_not_supported'));
      return;
    }

    const success = (position: GeolocationPosition): void => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLatitude(latitude);
      setLongitude(longitude);
      setErrorMsg(null);
    };

    const error = (err: GeolocationPositionError): void => {
      setErrorMsg(err.message);
    };

    navigator.geolocation.getCurrentPosition(success, error);
  };

  return (
    <div className="flex flex-col space-y-3 w-72">
      <div className="flex flex-col">
        <label className="mb-1">{t('map_lat')}</label>
        <input
          className="border border-gray-400 rounded-lg py-2 px-3 text-grey-darkest"
          value={latitude}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setLatitude(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col mt-4">
        <label className="mb-1">{t('map_long')}</label>
        <input
          className="border border-gray-400 rounded-lg py-2 px-3 text-grey-darkest"
          value={longitude}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setLongitude(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col">
        <label className="mb-1">{t('map_coordinates')}</label>
        <input
          className="border border-gray-400 rounded-lg py-2 px-3 text-grey-darkest"
          value={coordinates}
          placeholder='e.g. "37.4219983, -122.084"'
          onChange={handleCoordinateChange}
        />
      </div>
      {errorMsg && (
        <div className="bg-red-100 rounded-lg px-3 py-2">
          <span className="text-red-950">{errorMsg}</span>
        </div>
      )}
      <div>
        <Button variant="outlined" fullWidth onClick={getLocation}>
          {t('map_btn_get_curr_location')}
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          fullWidth
          onClick={(e: MouseEvent): void => {
            if (isValidLocation(latitude.toString(), longitude.toString())) {
              setErrorMsg(null);
              onUpdatedLocation({ latitude: Number(latitude), longitude: Number(longitude) });
              closeModal();
            } else {
              setErrorMsg(t('map_invalid_location'));
            }
          }}
        >
          {t('update')}
        </Button>
      </div>
    </div>
  );
};

interface MutateLocationPopupProps {
  initialLocation: DeviceLocationDto;
  onUpdatedLocation: (location: DeviceLocationDto) => void;
}

export default MutateLocationPopup;
