import Axios from './clients/Axios/Axios';

function getAlarms(companyId, startDate, endDate, alarmType, showActive, cb, ecb) {
  let alarmTypeQParam = '';
  if (alarmType != null) {
    alarmTypeQParam += '&alarmType=' + alarmType;
  }

  if (showActive) {
    alarmTypeQParam += '&showOnlyActiveAlarms=' + showActive;
  } else if (!showActive && showActive !== null) {
    alarmTypeQParam += '&showOnlyActiveAlarms=' + showActive;
  }

  if (companyId !== undefined && companyId !== null) {
    alarmTypeQParam += '&companyId=' + companyId;
  }
  return Axios.get(`/api/devices/alarms/?fromDate=${startDate}&toDate=${endDate}${alarmTypeQParam}`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getAlarmsForDevice(deviceNumber, startDate, endDate, alarmType, cb, ecb) {
  let alarmTypeQParam = '';
  if (alarmType != null) {
    alarmTypeQParam += '&alarmType=' + alarmType;
  }

  return Axios.get(
    `/api/devices/${deviceNumber}/alarms/?fromDate=${startDate}&toDate=${endDate}${alarmTypeQParam}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getAlarmsForDeviceAsync(deviceNumber, startDate, endDate, alarmTypе) {
  let alarmTypeQParam = '';
  if (alarmType != null) {
    alarmTypeQParam += '&alarmType=' + alarmType;
  }

  return Axios.get(
    `/api/devices/${deviceNumber}/alarms/?fromDate=${startDate}&toDate=${endDate}${alarmTypeQParam}`
  ).then(parseJSON);
}

function getAlarmsCount(companyId, startDate, endDate, cb, ecb) {
  let alarmTypeQParam = '';
  if (companyId !== undefined && companyId !== null) {
    alarmTypeQParam += '&companyId=' + companyId;
  }
  return Axios.get(
    `/api/devices/alarms/count?fromDate=${startDate}&toDate=${endDate}${alarmTypeQParam}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const AlarmsClient = {
  getAlarms,
  getAlarmsForDevice,
  getAlarmsCount,
  getAlarmsForDeviceAsync,
};

export default AlarmsClient;
