import React, { FC, ReactElement, JSXElementConstructor } from 'react';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getDefaultOptions } from 'date-fns';
import { DefaultDateOptions } from './DefaultDateOptions';
import { TextField, TextFieldProps } from '@mui/material';

const LocalizedDatePicker: FC<LocalizedDatePickerProps> = ({
  renderInputTextField,
  ...props
}: LocalizedDatePickerProps) => {
  const defaultOptions: DefaultDateOptions = getDefaultOptions() as DefaultDateOptions;
  const locale = defaultOptions.locale;

  return (
    <LocalizationProvider locale={locale} dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props}
        renderInput={(
          params: TextFieldProps
        ): ReactElement<any, string | JSXElementConstructor<any>> => (
          <TextField {...params} {...renderInputTextField} />
        )}
      />
    </LocalizationProvider>
  );
};

export interface LocalizedDatePickerProps extends Omit<DatePickerProps<Date, Date>, 'renderInput'> {
  renderInputTextField: TextFieldProps;
}

export default LocalizedDatePicker;
