import { TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/material';
import React, { FC, ChangeEvent, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

const CertificatePathEdit: FC<CertificatePathEditProps> = ({
  certificatePath,
  isCertificatePathValid,
  onChange,
  setIsCertificatePathValid,
}: CertificatePathEditProps) => {
  const { t } = useTranslation();

  const getCertificateUrl = (imagePath: string): string => {
    return `${window.location.protocol}//${window.location.host}${imagePath}`;
  };

  const checkFileExists = async (imagePath: string): Promise<void> => {
    try {
      const response = await fetch(getCertificateUrl(imagePath));
      setIsCertificatePathValid(true);
    } catch (error) {
      setIsCertificatePathValid(false);
    }
  };

  useEffect(() => {
    if (certificatePath !== '') {
      const timeout = setTimeout(() => checkFileExists(certificatePath), 1000);
      return (): void => clearTimeout(timeout);
    } else {
      setIsCertificatePathValid(true);
    }
  }, [certificatePath]);

  return (
    <>
      <TextField
        className="w-full"
        label={t('settings_input_value_label')}
        value={certificatePath}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
          onChange(e.target.value)
        }
      />
      {!isCertificatePathValid && (
        <p className="text-red-500 mt-2">{t('settings_certificate_not_found_error')}</p>
      )}
    </>
  );
};

interface CertificatePathEditProps {
  certificatePath: string;
  isCertificatePathValid: boolean;
  onChange: (value: string) => void;
  setIsCertificatePathValid: (value: boolean) => void;
}

export default CertificatePathEdit;
