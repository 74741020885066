import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import customerInfoClient from './CustomerInfoClient';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from './actions';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Snackbar,
  Select,
  Button,
  Paper,
  Grid,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import TitleHeader from './components/TitleHeader/TitleHeader';
import Header from './components/header';
import { CUSTOMER_TYPES } from './types/CustomerTypes';
import ErrorUtil from './common/ErrorUtil';

const CUSTOMER_TYPES_ARRAY = {
  commercial: 'customer_info_customer_type_commercial',
  domestic: 'customer_info_customer_type_domestic',
  'block of flats': 'customer_info_customer_type_block_of_flats',
  budget: 'customer_info_customer_type_budget',
};

const styles = (theme) => ({});

class CustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { customerInfo: null, open: false };
    this.loadCustomerInfoFromServer = this.loadCustomerInfoFromServer.bind(this);
    this.handleBusinessPartnerNumberChange = this.handleBusinessPartnerNumberChange.bind(this);
    this.handleContractNumberChange = this.handleContractNumberChange.bind(this);
    this.handleContactDataChange = this.handleContactDataChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleBillingGroupChange = this.handleBillingGroupChange.bind(this);
    this.handleMunicipalityChange = this.handleMunicipalityChange.bind(this);
    this.handleQuarterChange = this.handleQuarterChange.bind(this);
    this.handleStreetChange = this.handleStreetChange.bind(this);
    this.handleStreetNumberChange = this.handleStreetNumberChange.bind(this);
    this.handleBlockChange = this.handleBlockChange.bind(this);
    this.handleEntranceChange = this.handleEntranceChange.bind(this);
    this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
  }

  handleBusinessPartnerNumberChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, businessPartnerNumber: event.target.value },
    });
  handleContactDataChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, contactData: event.target.value },
    });
  handleContractNumberChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, contractNumber: event.target.value },
    });
  handleNameChange = (event) =>
    this.setState({ customerInfo: { ...this.state.customerInfo, name: event.target.value } });
  handleDescriptionChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, description: event.target.value },
    });
  handleBillingGroupChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, billingGroup: event.target.value },
    });
  handleMunicipalityChange = (event) => {
    this.setState({
      customerInfo: { ...this.state.customerInfo, municipality: event.target.value },
    });
  };
  handleCustomerTypeChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, customerTypeId: event.target.value },
    });
  handleQuarterChange = (event) => {
    this.setState({
      customerInfo: {
        ...this.state.customerInfo,
        quarter: event.target.value,
      },
    });
  };
  handleStreetChange = (event) =>
    this.setState({ customerInfo: { ...this.state.customerInfo, street: event.target.value } });
  handleStreetNumberChange = (event) =>
    this.setState({
      customerInfo: { ...this.state.customerInfo, streetNumber: event.target.value },
    });
  handleBlockChange = (event) =>
    this.setState({ customerInfo: { ...this.state.customerInfo, block: event.target.value } });
  handleEntranceChange = (event) =>
    this.setState({ customerInfo: { ...this.state.customerInfo, entrance: event.target.value } });

  handleRequestClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit(event) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.CUSTOMER_PAGE,
      GaEventAction.CUSTOMER_PAGE_SAVE
    );
    this.setState({ saveDisabled: true });
    customerInfoClient.putCustomerInfo(
      this.props.match.params.deviceNumber,
      this.state.customerInfo,
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  }

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
  }

  render() {
    if (this.state.customerInfo != null) {
      return (
        <Header>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
              <Paper>
                <Grid container justifyContent={'center'} style={{ padding: 20 }} spacing={2}>
                  {this.props.selectedDevice && (
                    <Grid item xs={12}>
                      <TitleHeader
                        pageType="form"
                        title={this.props.t('customer_info_header')}
                        deviceNumber={this.props.selectedDevice.number}
                        deviceName={this.props.selectedDevice.name}
                        customerInfo={this.props.selectedDevice.customerInfo}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_business_partner_number')}
                      value={this.state.customerInfo.businessPartnerNumber ?? ''}
                      onChange={this.handleBusinessPartnerNumberChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_contact_data')}
                      value={this.state.customerInfo.contactData ?? ''}
                      onChange={this.handleContactDataChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_contract_number')}
                      value={this.state.customerInfo.contractNumber ?? ''}
                      onChange={this.handleContractNumberChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_name')}
                      value={this.state.customerInfo.name ?? ''}
                      onChange={this.handleNameChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_description')}
                      value={this.state.customerInfo.description ?? ''}
                      onChange={this.handleDescriptionChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_billing_group')}
                      value={this.state.customerInfo.billingGroup ?? ''}
                      onChange={this.handleBillingGroupChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_municipality')}
                      value={this.state.customerInfo.municipality ?? ''}
                      onChange={this.handleMunicipalityChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={this.props.t('customer_info_quarter')}
                      value={this.state.customerInfo.quarter ?? ''}
                      onChange={this.handleQuarterChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      size="small"
                      fullWidth
                      label={this.props.t('customer_info_street')}
                      value={this.state.customerInfo.street ?? ''}
                      onChange={this.handleStreetChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      size="small"
                      label={this.props.t('number')}
                      value={this.state.customerInfo.streetNumber ?? ''}
                      onChange={this.handleStreetNumberChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      size="small"
                      label={this.props.t('customer_info_block')}
                      value={this.state.customerInfo.block ?? ''}
                      onChange={this.handleBlockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      size="small"
                      label={this.props.t('customer_info_entr')}
                      value={this.state.customerInfo.entrance ?? ''}
                      onChange={this.handleEntranceChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel htmlFor="select-cust-type">
                        {this.props.t('customer_info_customer_type')}
                      </InputLabel>
                      <Select
                        size="small"
                        label={this.props.t('customer_info_customer_type')}
                        value={this.state.customerInfo.customerTypeId ?? ''}
                        onChange={this.handleCustomerTypeChange}
                        inputProps={{
                          name: 'customerTypeId',
                          id: 'select-cust-type',
                        }}
                      >
                        <MenuItem value={''}></MenuItem>
                        {CUSTOMER_TYPES.map((name, idx) => (
                          <MenuItem value={idx}>
                            {this.props.t(CUSTOMER_TYPES_ARRAY[name])}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box paddingTop={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={this.handleSubmit}
                        disabled={this.state.saveDisabled}
                      >
                        {this.props.t('button_save')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Snackbar
                  open={this.state.open}
                  message="Customer Info Saved."
                  autoHideDuration={4000}
                  onClose={this.handleRequestClose}
                />
              </Paper>
            </Grid>
          </Grid>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadCustomerInfoFromServer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber) {
      this.loadCustomerInfoFromServer();
    }
  }

  componentWillUnmount() {}

  loadCustomerInfoFromServer() {
    customerInfoClient.getCustomerInfo(
      this.props.match.params.deviceNumber,
      (customerInfo) => {
        if (customerInfo === null || customerInfo === '') {
          customerInfo = {
            block: '',
            entrance: '',
            street: '',
            streetNumber: '',
            quarter: '',
            municipality: '',
            name: '',
            description: '',
            billingGroup: '',
            contractNumber: '',
            businessPartnerNumber: '',
            customerTypeId: '',
          };
        }
        if (customerInfo.block === null) customerInfo.block = '';
        if (customerInfo.entrance === null) customerInfo.entrance = '';

        this.setState({
          customerInfo: customerInfo,
        });
      },
      this.errorCallback
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDevice: state.dev.selectedDevice,
  };
};

export default withRouter(
  connect(mapStateToProps, actions)(withStyles(styles)(withTranslation()(CustomerInfo)))
);
