import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { QueryOptions, QueryResult } from '@thingslog/repositories';

export interface TimezonesDeviceQueryClient {
  getTimezones: () => Promise<Set<string>>;
  useGetTimezones: (options?: UseQueryOptions<Set<string>, AxiosError>) => QueryResult<Set<string>>;
}

export function createTimezonesDeviceQueryClient(axios: AxiosInstance): TimezonesDeviceQueryClient {
  return new TimezonesDeviceQueryImp(axios);
}

class TimezonesDeviceQueryImp {
  public constructor(private axios: AxiosInstance) {}

  public getTimezones = async (): Promise<Set<string>> => {
    return await this.axios
      .get('/api/timezones')
      .then((response: AxiosResponse<Set<string>>) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  public useGetTimezones = (options?: QueryOptions<Set<string>>): QueryResult<Set<string>> => {
    return useQuery<Set<string>, AxiosError>(
      [QueryKeys.GetTimezones],
      () => this.getTimezones(),
      options
    );
  };
}
