import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import SelectionIndicator from '@mui/icons-material/Brightness1';
import { GraphType } from '../model/GraphType';
import { Port } from '@thingslog/repositories';
import { SensorsUtil } from '../../../common/SensorsUtil';

export default class SensorButton extends Component<SensorButtonProps, SensorButtonState> {
  public state: SensorButtonState = {};

  private buttonRef = React.createRef<HTMLDivElement>();

  private isSelected = (id: number): boolean => {
    return this.props.selectedIndexes.includes(id);
  };

  public render(): React.ReactNode {
    const { portIndex, color, port, onClick } = this.props;
    const units = SensorsUtil.getSensorUnits(port.sensor);
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant="outlined" ref={this.buttonRef}>
            <Button sx={{ borderColor: color }} onClick={(): void => onClick(portIndex)}>
              {this.isSelected(portIndex) && (
                <SelectionIndicator style={{ color: color, marginRight: 10 }} />
              )}
              {port.sensor.name}
            </Button>
            {units && (
              <Button sx={{ textTransform: 'none', borderColor: color }} size="small">
                {units}
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }
}

interface SensorButtonProps {
  port: Port;
  portIndex: number;
  color: string;
  graphType: GraphType;
  selectedIndexes: number[];
  onClick: (id: number) => void;
}

interface SensorButtonState {}
