import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ClientCount, QueryOptions } from '@thingslog/repositories';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { QueryKeys } from '../enums/QueryKeys';

export interface ClientCountQueryClient {
  getClientCount: (companyId: number, fromDate: Date, toDate: Date) => Promise<ClientCount>;
  useClientCount: (
    companyId: number | null,
    fromDate: Date,
    toDate: Date,
    options?: QueryOptions<ClientCount>
  ) => UseQueryResult<ClientCount, AxiosError>;
}

export function createClientCountQueryClient(axios: AxiosInstance): ClientCountQueryClient {
  return new ClientCountImp(axios);
}

class ClientCountImp {
  public constructor(private axios: AxiosInstance) {}

  public getClientCount = async (
    companyId: number | null,
    fromDate: Date,
    toDate: Date
  ): Promise<ClientCount> => {
    return this.axios
      .get('/v2/device/readings/client-count', {
        params: { companyId: companyId, fromDate: fromDate, toDate: toDate },
      })
      .then((response: AxiosResponse<ClientCount>) => {
        return response.data;
      });
  };

  public useClientCount = (
    companyId: number | null,
    fromDate: Date,
    toDate: Date,
    options?: QueryOptions<ClientCount>
  ): UseQueryResult<ClientCount, AxiosError> => {
    return useQuery<ClientCount, AxiosError>(
      [QueryKeys.GetClientCount, companyId, fromDate, toDate],
      () => this.getClientCount(companyId, fromDate, toDate),
      options
    );
  };
}
