import Axios from './clients/Axios/Axios';

function getDeviceInitialConfig(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/initial-config`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function putDeviceInitialConfig(deviceNumber, deviceConfig, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/devices/${deviceNumber}/initial-config`, deviceConfig, config)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceInitialConfigClient = {
  getDeviceInitialConfig,
  putDeviceInitialConfig,
};

export default DeviceInitialConfigClient;
