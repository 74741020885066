import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  LicenseDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseRequest,
} from '@thingslog/repositories';
import { LicenseType } from '@thingslog/repositories/src/license/LicenseType';
import { LocalizedDatePicker } from '@thingslog/ui-components';
import React, { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import LicenseMaxCount from '../../companyManager/components/LicenseMaxCountTextField';

import { useTranslation } from 'react-i18next';
import { isAfter, addYears, addMonths, isToday } from 'date-fns';
import LicenseValidator from '../../../common/LicenseValidator';

const AddLicenseModal: FC<AddLicenseModalProps> = ({
  companyName,
  addLicense,
  licenses,
}: AddLicenseModalProps) => {
  const { t } = useTranslation();

  const [licenseType, setLicenseType] = useState<LicenseType>(LicenseType.PER_DEVICE);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(addYears(new Date(), 1));
  const [maxReadings, setMaxReadings] = useState<number>(1);
  const [maxDataLoggers, setMaxDataLoggers] = useState<number>(1);
  const [maxSensors, setMaxSensors] = useState<number>(1);
  const [hasError, setHasError] = useState<boolean>(false);
  const [conflictingLicenseDetected, setConflictingLicenseDetected] = useState<boolean>(false);

  useEffect(() => {
    if (licenses) {
      // Check for conflicting date ranges for specific license types (POC, Reading, Remote Node)
      if (LicenseValidator.detectLicenseConflict(licenses, fromDate, toDate)) {
        setConflictingLicenseDetected(true);
      } else {
        setConflictingLicenseDetected(false);
      }
    }
  }, [licenseType, licenses, fromDate, toDate]);

  const determineLicenseDefaultValidityPeriod = (
    licenseType: LicenseType,
    fromDate: Date
  ): void => {
    if (licenseType === LicenseType.PER_POC && isToday(fromDate)) {
      setToDate(addMonths(fromDate, 6));
    } else {
      setToDate(addYears(fromDate, 1));
    }
  };

  const handleLicenseTypeChange = (event: SelectChangeEvent): void => {
    determineLicenseDefaultValidityPeriod(event.target.value as LicenseType, fromDate);
    setLicenseType(event.target.value as LicenseType);
  };

  const handleFromDateChange = (newDate: Date | null): void => {
    if (newDate) {
      determineLicenseDefaultValidityPeriod(licenseType, newDate);
      setFromDate(newDate);
    }
  };

  const handleToDateChange = (newDate: Date | null): void => {
    if (newDate) {
      setToDate(newDate);
    }
  };

  const handleChange = (licenseType: LicenseType, value: number): void => {
    switch (licenseType) {
      case LicenseType.PER_READING:
        setMaxReadings(value);
        break;
      case LicenseType.PER_DEVICE:
        setMaxDataLoggers(value);
        break;
      case LicenseType.PER_SENSOR:
        setMaxSensors(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (): void => {
    const licenseData: LicenseRequest = {
      companyName: companyName,
      '@type': licenseType,
      isActive: true,
      validityPeriodFrom: fromDate,
      validityPeriodTo: toDate,
    };

    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        (licenseData as LicensePerDeviceDto).maxDataLoggers = maxDataLoggers;
        break;
      case LicenseType.PER_READING:
        (licenseData as LicensePerReadingDto).maxReadings = maxReadings;
        break;
      case LicenseType.PER_SENSOR:
        (licenseData as LicensePerSensorDto).maxSensors = maxSensors;
        break;
    }

    addLicense(licenseData);
  };

  return (
    <div className="space-y-5">
      <FormControl fullWidth>
        <InputLabel>{t('license_table_license_type')}</InputLabel>
        <Select
          variant="outlined"
          label={t('license_table_license_type')}
          value={licenseType}
          fullWidth
          size="small"
          onChange={handleLicenseTypeChange}
        >
          <MenuItem value={LicenseType.PER_DEVICE}>
            {t('company_manager_license_per_device')}
          </MenuItem>
          <MenuItem value={LicenseType.PER_READING}>
            {t('company_manager_license_per_reading')}
          </MenuItem>
          <MenuItem value={LicenseType.PER_SENSOR}>
            {t('company_manager_license_per_sensor')}
          </MenuItem>
          <MenuItem value={LicenseType.PER_POC}>{t('company_manager_license_per_poc')}</MenuItem>
          <MenuItem value={LicenseType.PER_REMOTE_NODE}>
            {t('company_manager_license_per_remote_node')}
          </MenuItem>
        </Select>
      </FormControl>
      <LocalizedDatePicker
        label={t('profile_page_valid_from')}
        value={fromDate}
        onChange={handleFromDateChange}
        inputFormat="dd/MM/yyyy"
        maxDate={toDate}
        minDate={licenseType === LicenseType.PER_READING ? new Date() : undefined}
        renderInputTextField={{
          size: 'small',
          className: 'bg-white, w-full',
          onKeyDown: (
            e: BaseSyntheticEvent<KeyboardEvent, EventTarget & HTMLDivElement, EventTarget>
          ): void => e.preventDefault(),
          sx: {
            '& .MuiInputBase-input': {
              caretColor: 'transparent',
            },
          },
        }}
      />
      <LocalizedDatePicker
        label={t('profile_page_valid_to')}
        value={toDate}
        onChange={handleToDateChange}
        inputFormat="dd/MM/yyyy"
        minDate={fromDate}
        renderInputTextField={{
          size: 'small',
          className: 'bg-white, w-full',
          onKeyDown: (
            e: BaseSyntheticEvent<KeyboardEvent, EventTarget & HTMLDivElement, EventTarget>
          ): void => e.preventDefault(),
          sx: {
            '& .MuiInputBase-input': {
              caretColor: 'transparent',
            },
          },
        }}
      />
      {licenseType !== LicenseType.PER_POC && licenseType !== LicenseType.PER_REMOTE_NODE && (
        <LicenseMaxCount
          onChange={handleChange}
          licenseType={licenseType}
          maxSensors={maxSensors}
          maxDataLoggers={maxDataLoggers}
          maxReadings={maxReadings}
          disabled={false}
          hasError={hasError}
          setHasError={setHasError}
        />
      )}
      {conflictingLicenseDetected && (
        <div className="text-red-400 text-center">{t('license_conflicting_license_date')}</div>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className="w-full"
        disabled={hasError || isAfter(fromDate, toDate) || conflictingLicenseDetected}
      >
        {t('button_create')}
      </Button>
    </div>
  );
};

interface AddLicenseModalProps {
  companyName: string;
  addLicense: (body: LicenseRequest) => void;
  licenses: LicenseDto[] | undefined;
}

export default AddLicenseModal;
