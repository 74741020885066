import Axios from './clients/Axios/Axios';

function getDeviceBattery(deviceNumber, startDate, endDate, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/battery/?fromDate=${startDate}&toDate=${endDate}`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDevicesBattery(fromLevel, toLevel, cb, ecb) {
  if (fromLevel === null && toLevel === null) {
    return Axios.get(`/api/devices/battery/`).then(parseJSON).then(cb).catch(ecb);
  } else {
    return Axios.get(`/api/devices/battery/?fromLevel=${fromLevel}&toLevel=${toLevel}`)
      .then(parseJSON)
      .then(cb)
      .catch(ecb);
  }
}

function getDevicesBatteryHistogram(companyId, cb, ecb) {
  let url = `/api/devices/battery/histogram`;

  const searchParams = new URLSearchParams();
  if (!!companyId) {
    searchParams.append('companyId', companyId);
  }
  const queryString = searchParams.toString();
  if (queryString) {
    url += `?${queryString}`;
  }

  return Axios.get(url).then(parseJSON).then(cb).catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceBatteryClient = {
  getDeviceBattery,
  getDevicesBattery,
  getDevicesBatteryHistogram,
};

export default DeviceBatteryClient;
