import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, Component } from 'react';
import { TimeSeriesData } from '../../../model/TimeSeriesData/TimeSeriesData';
import { TimeSeriesChartData } from '../model/TimeSeriesChartData';
import { Port } from '@thingslog/repositories';
import { SensorsUtil } from '../../../common/SensorsUtil';
import { t } from 'i18next';

class SensorReadingsTable extends Component<SensorReadingsTableProps, SensorReadingsTableState> {
  public state: SensorReadingsTableState = {
    tableReadings: [],
    tableColumnNames: [],
    columnCount: 0,
    page: 0,
    size: 15,
  };

  public componentDidMount(): void {
    this.updateTableData();
  }

  public componentDidUpdate(prevProps: SensorReadingsTableProps): void {
    if (prevProps.chartData.length !== this.props.chartData.length) {
      this.updateTableData();
    }
  }

  private updateTableData = (): void => {
    const chartData = [...this.props.chartData].sort(
      (a: TimeSeriesChartData, b: TimeSeriesChartData) => a.sensorIndex - b.sensorIndex
    );

    this.setState({
      tableReadings: this.getTableReadings(chartData),
      tableColumnNames: this.getTableColumnNames(chartData),
      columnCount: this.getTableColumnNames(chartData).length,
    });
  };

  private getTableReadings(chartData: TimeSeriesChartData[]): PaginationRow[] {
    const table: MultiColumnTable = this.convertChartDataToMultiColumnTable(chartData);
    // Converted to array, because it's more easily mapped onto UI and paginated.
    const rows: PaginationRow[] = this.convertMultiColumnTableToArray(table);

    // Newest data first.
    rows.sort((a: PaginationRow, b: PaginationRow) => b.time - a.time);

    return rows;
  }

  private getTableColumnNames(chartData: TimeSeriesChartData[]): TableColumn[] {
    return chartData.map((chartDataItem: TimeSeriesChartData) => {
      return { sensorIndex: chartDataItem.sensorIndex, name: chartDataItem.label };
    });
  }

  private paginate(page: number, size: number): PaginationRow[] {
    return this.state.tableReadings.slice(page * size).slice(0, size);
  }

  private convertChartDataToMultiColumnTable = (
    chartData: TimeSeriesChartData[]
  ): MultiColumnTable => {
    const table: MultiColumnTable = {
      rows: new Map<number, Row>(),
    };

    chartData.forEach((timeSeriesChartData: TimeSeriesChartData) => {
      const sensorIndex = timeSeriesChartData.sensorIndex;
      timeSeriesChartData.data.map((timeSeriesData: TimeSeriesData) => {
        const timestamp = timeSeriesData.x.getTime();
        const value = timeSeriesData.y;

        let sensorValues: Map<number, number> = new Map();
        if (table.rows.has(timestamp)) {
          sensorValues = table.rows.get(timestamp)!.sensorValues;
        }
        sensorValues.set(sensorIndex, value);
        const row: Row = { sensorValues };
        table.rows.set(timestamp, row);
      });
    });

    return table;
  };

  private convertMultiColumnTableToArray = (table: MultiColumnTable): PaginationRow[] => {
    const rows: PaginationRow[] = [];
    table.rows.forEach((value: Row, key: number) => {
      rows.push({
        time: key,
        sensorValus: value.sensorValues,
      });
    });
    return rows;
  };

  public render(): React.ReactNode {
    return (
      <Paper style={{ padding: 5 }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('date')}</TableCell>
                  {Array.from({ length: this.state.columnCount }, (v: number, k: number) => k).map(
                    (index: number) => {
                      const { name, sensorIndex } = this.state.tableColumnNames[index];
                      const sensor = this.props.portsConfig[sensorIndex].sensor;
                      const measurementUnit = SensorsUtil.getSensorUnits(sensor);

                      return <TableCell key={index}>{`${name} ${measurementUnit}`}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.paginate(this.state.page, this.state.size).map((row: PaginationRow) => (
                  <TableRow hover>
                    <TableCell>{moment(new Date(row.time)).format('DD/MM/YYYY, HH:mm')}</TableCell>
                    {Array.from(Array(this.state.columnCount).keys()).map((i: number) => {
                      const sensorIndex = this.state.tableColumnNames[i].sensorIndex;
                      const value = row.sensorValus.get(sensorIndex)?.toFixed(2) || '';
                      return <TableCell>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
              component="div"
              count={this.state.tableReadings.length}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(
                e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
                page: number
              ): void => {
                this.setState({ page });
              }}
              onRowsPerPageChange={(e: ChangeEvent<MuiEventTarget>): void => {
                this.setState({ page: 0, size: e.target.value as number });
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

interface SensorReadingsTableProps {
  readonly chartData: TimeSeriesChartData[];
  readonly portsConfig: Record<number, Port>;
}

interface SensorReadingsTableState {
  tableReadings: PaginationRow[];
  tableColumnNames: TableColumn[];
  columnCount: number;
  page: number;
  size: number;
}

interface MultiColumnTable {
  rows: Map<number, Row>;
}

interface Row {
  sensorValues: Map<number, number>;
}

interface PaginationRow {
  time: number;
  sensorValus: Map<number, number>;
}

interface TableColumn {
  sensorIndex: number;
  name: string;
}

export default SensorReadingsTable;
