import React, { FC } from 'react';
import { Tooltip, Typography } from '@mui/material';

const SensorsStatisticsValue: FC<SensorsStatisticsValueProps> = ({
  color,
  value,
  units,
}: SensorsStatisticsValueProps) => {
  return (
    <Typography
      sx={{
        textDecorationColor: color,
        textDecorationThickness: 3,
        display: 'inline',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        textDecorationLine: 'underline',
      }}
    >
      <Tooltip title={units} placement="top" arrow>
        <span>{value.toFixed(3)}</span>
      </Tooltip>
    </Typography>
  );
};

interface SensorsStatisticsValueProps {
  color: string;
  value: number;
  units: string;
}

export default SensorsStatisticsValue;
