import React, { FC } from 'react';
import DeviceSensorGraphData from './model/DeviceSensorGraphData';
import SelectedSensorsDisplay, { SelectedSensorsEntry } from './SelectedSensorsDisplay';

const SelectedSensors: FC<SelectedSensorsProps> = ({
  deviceSensorGraphData,
  onSensorDeselect,
  onAllSensorsDeselect,
}: SelectedSensorsProps) => {
  return (
    <SelectedSensorsDisplay
      selectedSensors={deviceSensorGraphData.map((entry: DeviceSensorGraphData) => ({
        deviceName: entry.device.name,
        deviceNumber: entry.deviceNumber,
        sensor: entry.port.sensor,
        sensorIndex: entry.sensorIndex,
        color: entry.graphLineColor.flow,
      }))}
      onRemoveClicked={(deviceNumber: string, sensorIndex: number): void => {
        onSensorDeselect(deviceNumber, sensorIndex);
      }}
      onRemoveAllSensorsClicked={onAllSensorsDeselect}
    />
  );
};

interface SelectedSensorsProps {
  deviceSensorGraphData: DeviceSensorGraphData[];
  onSensorDeselect: (deviceNumber: string, sensorIndex: number) => void;
  onAllSensorsDeselect: () => void;
}

export default SelectedSensors;
