import React, { FC } from 'react';
import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
} from '@mui/material';
import clsx from 'clsx';

const CreateAccountModalStepper: FC<CreateAccountModalStepperProps> = ({
  activeStep,
  steps,
}: CreateAccountModalStepperProps) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
      {steps.map(({ label, icon }: { label: string; icon: JSX.Element }) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIcon} icon={icon}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const CustomStepIcon: FC<StepIconProps> = ({ active, completed, icon }: StepIconProps) => {
  return (
    <div
      className={clsx(
        'text-white w-12 h-12 flex rounded-full justify-center items-center',
        active || completed
          ? 'bg-gradient-to-br from-cyan-500 to-cyan-800 shadow-md'
          : 'bg-gray-300'
      )}
    >
      {icon}
    </div>
  );
};

const CustomStepConnector: FC<CustomStepConnectorProps> = () => {
  return (
    <StepConnector
      sx={{
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,#06b6d4 0%,#155e75 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,#06b6d4 0%,#155e75 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor: '#eaeaf0',
          borderRadius: 1,
        },
      }}
    />
  );
};

interface CustomStepConnectorProps {}

interface CreateAccountModalStepperProps {
  activeStep: number;
  steps: { label: string; icon: JSX.Element }[];
}

export default CreateAccountModalStepper;
