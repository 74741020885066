import { minBy, maxBy, meanBy } from 'lodash';
import { TimeSeriesData } from '../../../model/TimeSeriesData/TimeSeriesData';
import { ChartSensorStatistics } from '../model/ChartSensorStatistics';
import { TimeSeriesChartData } from '../model/TimeSeriesChartData';

export default class SensorStatisticsUtil {
  public static calculateStatisticsFromChartData = (
    chartData: TimeSeriesChartData
  ): ChartSensorStatistics => {
    let min = 0;
    let max = 0;
    let avg = 0;

    const minTimeSeriesItem = minBy(chartData.data, (tsd: TimeSeriesData) => tsd.y);
    const maxTimeSeriesItem = maxBy(chartData.data, (tsd: TimeSeriesData) => tsd.y);

    if (minTimeSeriesItem && maxTimeSeriesItem) {
      min = minTimeSeriesItem.y;
      max = maxTimeSeriesItem.y;
      avg = meanBy(chartData.data, (tsd: TimeSeriesData) => tsd.y);
    }

    const statisticsItem: ChartSensorStatistics = {
      deviceNumber: chartData.deviceNumber,
      sensorIndex: chartData.sensorIndex,
      min: min,
      max: max,
      avg: avg,
    };

    return statisticsItem;
  };
}
