import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {
  blue,
  cyan,
  deepOrange,
  green,
  lightBlue,
  purple,
  deepPurple,
  red,
  yellow,
  orange,
  teal,
  indigo,
  pink,
  lightGreen,
} from '@mui/material/colors';
import { ChartDataSets } from 'chart.js';
import React from 'react';
import ChartComponent, { Bar, ChartData, Line, ChartComponentProps } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { BarChartOptions, LineChartOptions } from '../ChartOptions';

import SensorSelector from './SensorSelector';
import BarChartIcon from '@mui/icons-material/BarChart';
import LineChartIcon from '@mui/icons-material/ShowChart';
import ChartStatistics from './ChartStatistics';
import { styles } from './Chart.styles';
import SensorStatisticsUtil from '../utils/SensorStatisticsUtil';
import ErrorUtil from '../../../common/ErrorUtil';
import {
  getSensorReadingsAsTimeSeriesData,
  getSensorReadingsAsTimeSeriesDataAvg,
} from '../../../clients/DeviceReadings/DeviceReadingsClient';
import {
  getSensorFlowAsTimeSeriesData,
  getSensorFlowAsTimeSeriesDataAvg,
} from '../../../clients/DeviceFlow/DeviceFlowClient';
import { TimeSeriesData } from '../../../model/TimeSeriesData/TimeSeriesData';
import { GraphMode } from '../model/GraphMode';
import { GraphType } from '../model/GraphType';
import { TimeSeriesChartData } from '../model/TimeSeriesChartData';
import { ChartSensorStatistics } from '../model/ChartSensorStatistics';
import { EventValueRange, Port } from '@thingslog/repositories';
import getDefaultSensorName from '../../../common/SensorNameHelper';
import PortUtil from '../utils/PortUtil';
import { SensorsUtil } from '../../../common/SensorsUtil';

class Chart extends React.Component<ChartProps, ChartState> {
  private chartRef = React.createRef<ChartComponent<ChartComponentProps>>();
  public state: ChartState = {
    chartData: [],
    graphMode: GraphMode.LINE,
    selectedIndexes: [],
    displayRawData: false,
    isLoaded: false,
    statistics: [],
  };

  public componentDidMount = async (): Promise<void> => {
    this.autoSelectFirstEnabledSensor();
  };

  public componentDidUpdate = async (
    prevProps: ChartProps,
    prevState: ChartState
  ): Promise<void> => {
    if (
      prevProps.avgFromDateTz !== this.props.avgFromDateTz ||
      prevProps.avgToDateTz !== this.props.avgToDateTz ||
      prevProps.fromDateTz !== this.props.fromDateTz ||
      prevProps.toDateTz !== this.props.toDateTz ||
      prevProps.every !== this.props.every ||
      prevProps.showAverage !== this.props.showAverage
    ) {
      await this.loadData();
    }
  };

  private setGraphMode = (graphMode: GraphMode): void => {
    this.setState({ graphMode });
  };

  private toggleDisplayRawData = async (): Promise<void> => {
    this.setState(
      { displayRawData: !this.state.displayRawData },
      async (): Promise<void> => await this.loadData()
    );
  };

  private setSelectedIndexes = async (selectedIndexes: number[]): Promise<void> => {
    this.setState(
      { selectedIndexes: selectedIndexes },
      async (): Promise<void> => await this.loadData()
    );
    this.props.onUpdateSelectedSensors(selectedIndexes);
  };

  private setPortsConfig = async (portsConfig: Record<number, Port>): Promise<void> => {
    this.props.setPortsConfig(portsConfig);
    await this.loadData();
  };

  private autoSelectFirstEnabledSensor = (): void => {
    const { portsConfig, graphType } = this.props;

    for (const [index, port] of Object.entries(portsConfig)) {
      const isCorrectPortType =
        (PortUtil.isDigitalPort(port['@type']) && graphType === GraphType.DIGITAL) ||
        (PortUtil.isAnalogPort(port['@type']) && graphType === GraphType.ANALOG);

      if (isCorrectPortType && port.enabled) {
        const selectedIndexes = [Number(index)];
        this.setSelectedIndexes(selectedIndexes);
        break;
      }
    }
  };

  private shouldDisplayFlowData = (): boolean =>
    this.props.graphType === GraphType.DIGITAL && !this.state.displayRawData;

  private loadData = async (): Promise<void> => {
    this.setState({ chartData: [], statistics: [], isLoaded: false }, (): void => {
      this.props.setChartData(this.state.chartData);
    });

    this.shouldDisplayFlowData() ? await this.loadFlowData() : await this.loadRawData();
    this.setState({ isLoaded: true });
  };

  private loadRawData = async (): Promise<void> => {
    const statistics: ChartSensorStatistics[] = [];
    const chartData: TimeSeriesChartData[] = [];

    for (let i = 0; i < this.state.selectedIndexes.length; i++) {
      const index = this.state.selectedIndexes[i];
      const port = this.props.portsConfig[index];

      try {
        const timeSeriesData = await getSensorReadingsAsTimeSeriesData(
          this.props.deviceNumber,
          index,
          this.props.fromDateTz.toISOString(),
          this.props.toDateTz.toISOString(),
          this.props.every
        );
        const rawData = this.addTimeSeriesChart(
          this.props.deviceNumber,
          index,
          port,
          timeSeriesData,
          false
        );

        chartData.push(rawData[0]);
        statistics.push(rawData[1]);
      } catch (error) {
        ErrorUtil.handleErrorWithAlert(error);
      }

      if (this.props.showAverage) {
        try {
          const timeSeriesData = await getSensorReadingsAsTimeSeriesDataAvg(
            this.props.deviceNumber,
            index,
            this.props.avgFromDateTz.toISOString(),
            this.props.avgToDateTz.toISOString(),
            this.props.fromDateTz.toISOString(),
            this.props.toDateTz.toISOString(),
            this.props.every
          );
          const avgRawData = this.addTimeSeriesChart(
            this.props.deviceNumber,
            index,
            port,
            timeSeriesData,
            true
          );
          chartData.push(avgRawData[0]);
          statistics.push(avgRawData[1]);
        } catch (error) {
          ErrorUtil.handleErrorWithAlert(error);
        }
      }
    }

    this.setState({ chartData, statistics }, (): void => {
      this.props.setChartData(chartData);
    });
  };

  private loadFlowData = async (): Promise<void> => {
    const statistics: ChartSensorStatistics[] = [];
    const chartData: TimeSeriesChartData[] = [];

    for (let i = 0; i < this.state.selectedIndexes.length; i++) {
      const index = this.state.selectedIndexes[i];
      const port = this.props.portsConfig[index];
      const every = this.props.every;

      try {
        const timeSeriesData = await getSensorFlowAsTimeSeriesData(
          this.props.deviceNumber,
          index,
          this.props.fromDateTz.toISOString(),
          this.props.toDateTz.toISOString(),
          every
        );

        const flowData = this.addTimeSeriesChart(
          this.props.deviceNumber,
          index,
          port,
          timeSeriesData,
          false
        );
        chartData.push(flowData[0]);
        statistics.push(flowData[1]);
      } catch (error) {
        ErrorUtil.handleErrorWithAlert(error);
      }

      if (this.props.showAverage) {
        try {
          const timeSeriesData = await getSensorFlowAsTimeSeriesDataAvg(
            this.props.deviceNumber,
            index,
            this.props.avgFromDateTz.toISOString(),
            this.props.avgToDateTz.toISOString(),
            this.props.fromDateTz.toISOString(),
            this.props.toDateTz.toISOString(),
            every
          );
          const avgFlowData = this.addTimeSeriesChart(
            this.props.deviceNumber,
            index,
            port,
            timeSeriesData,
            true
          );
          chartData.push(avgFlowData[0]);
          statistics.push(avgFlowData[1]);
        } catch (error) {
          ErrorUtil.handleErrorWithAlert(error);
        }
      }
    }

    this.setState({ chartData, statistics }, (): void => {
      this.props.setChartData(chartData);
    });
  };

  private addTimeSeriesChart = (
    deviceNumber: string,
    index: number,
    port: Port | undefined,
    timeSeriesData: TimeSeriesData[],
    isAverage: boolean
  ): [TimeSeriesChartData, ChartSensorStatistics] => {
    const label = port?.sensor.name || getDefaultSensorName(index);
    const data = this.mapTimeSeriesDataToTimezone(timeSeriesData, this.props.timezone);
    const primaryColor = this.getColor(index, isAverage);

    const chartData: TimeSeriesChartData = {
      deviceNumber: deviceNumber,
      sensorIndex: index,
      label: label,
      data: data,
      primaryColor: primaryColor,
      units: port && SensorsUtil.getSensorUnits(port.sensor),
    };

    const statisticsItem = SensorStatisticsUtil.calculateStatisticsFromChartData(chartData);
    return [chartData, statisticsItem];
  };

  private recalculateStatisticsOnZoom = (minTimeInEpoch: number, maxTimeInEpoch: number): void => {
    this.setState({ statistics: [] });

    const chartData = this.state.chartData.map((item: TimeSeriesChartData) => ({ ...item }));
    const statistics: ChartSensorStatistics[] = [];

    chartData.forEach((chartDataItem: TimeSeriesChartData) => {
      const data = chartDataItem.data.filter((tsd: TimeSeriesData) => {
        const time = tsd.x.getTime();
        return time >= minTimeInEpoch && time <= maxTimeInEpoch;
      });
      chartDataItem.data = data;
      const statisticsItem = SensorStatisticsUtil.calculateStatisticsFromChartData(chartDataItem);
      statistics.push(statisticsItem);
    });

    const chart = this.chartRef.current?.chartInstance;

    if (chart) {
      let coordinates = chartData[0].data[0];
      if (coordinates) {
        let startingPointX = coordinates.x;
        let minTime = startingPointX;

        let xAxisOptions = chart.options?.scales?.xAxes?.[0];
        if (xAxisOptions && xAxisOptions.time) {
          xAxisOptions.time.min = minTime.toISOString();
          chart.update();
          this.setState({ statistics: statistics });
        } else {
          this.setState({ statistics: statistics });
        }
      }
    } else {
      this.setState({ statistics: statistics });
    }
  };

  private mapTimeSeriesDataToTimezone = (
    timeSeriesData: TimeSeriesData[],
    timezone: string
  ): TimeSeriesData[] => {
    let timeSeriesDataTz: TimeSeriesData[] = [];
    timeSeriesData.map((dataPoint: TimeSeriesData) => {
      const date = new Date(dataPoint.x.getTime());
      const value = dataPoint.y;
      timeSeriesDataTz.push({ x: date, y: value });
    });
    return timeSeriesDataTz;
  };

  private getChartData = (): ChartData<{ datasets: ChartDataSets[] }> => {
    let datasets: ChartDataSets[] = [];
    if (this.state.chartData.length === 0) return { datasets };

    this.state.chartData.forEach((chart: TimeSeriesChartData) => {
      switch (this.state.graphMode) {
        case GraphMode.LINE:
          const lineChartDataset: ChartDataSets = {
            label: chart.label,
            data: chart.data,
            fill: false,
            borderColor: chart.primaryColor,
            lineTension: 0.1,
            borderWidth: 1,
          };
          datasets.push(lineChartDataset);
          break;
        case GraphMode.BAR:
          const barChartDataset: ChartDataSets = {
            label: chart.label,
            data: chart.data,
            backgroundColor: chart.primaryColor,
            barThickness: this.calculateBarThickness(this.props.every),
          };
          datasets.push(barChartDataset);
          break;
        default:
          throw new Error('Unknown Graph Mode');
      }
    });

    return { datasets };
  };

  private calculateBarThickness = (every: number): number => {
    switch (every) {
      case 1:
        return 10;
      case 2:
        return 15;
      case 3:
      case 5:
        return 20;
      case 10:
        return 25;
      case 15:
        return 35;
      case 30:
        return 40;
      case 60:
        return 45;
      default:
        return 10;
    }
  };

  private getColor = (index: number, isAverage: boolean): string => {
    const colors = [
      { base: deepPurple[300], avg: deepPurple[900] },
      { base: lightBlue[500], avg: blue[900] },
      { base: purple[400], avg: purple[900] },
      { base: green[400], avg: green[900] },
      { base: yellow[600], avg: yellow[900] },
      { base: cyan[400], avg: cyan[900] },
      { base: orange[400], avg: orange[900] },
      { base: teal[400], avg: teal[900] },
      { base: indigo[400], avg: indigo[900] },
      { base: pink[400], avg: pink[900] },
      { base: lightGreen[400], avg: lightGreen[900] },
      { base: red[300], avg: red[900] },
      { base: lightBlue[500], avg: blue[900] },
      { base: deepOrange[400], avg: deepOrange[900] },
    ];
    return isAverage ? colors[index].avg : colors[index].base;
  };

  private containsData = (): boolean => {
    return this.state.chartData.some((chartData: TimeSeriesChartData) => chartData.data.length > 0);
  };

  private shouldDisplayChart = (): boolean => {
    const { portsConfig, graphType } = this.props;

    return Object.values(portsConfig).some((port: Port) => {
      return (
        port.enabled &&
        ((PortUtil.isAnalogPort(port['@type']) && graphType === GraphType.ANALOG) ||
          (PortUtil.isDigitalPort(port['@type']) && graphType === GraphType.DIGITAL))
      );
    });
  };

  private onZoomOrPanComplete = ({ chart }: { chart: ChartComponentProps }): void => {
    const minTimeInEpoch = Number(chart.options?.scales?.xAxes?.[0].ticks?.min);
    const maxTimeInEpoch = Number(chart.options?.scales?.xAxes?.[0].ticks?.max);
    this.recalculateStatisticsOnZoom(minTimeInEpoch, maxTimeInEpoch);
  };

  public render(): React.ReactNode {
    const { classes } = this.props;
    const lineChartOptions = LineChartOptions({
      onComplete: this.onZoomOrPanComplete,
      statistics: this.state.statistics,
      chartData: this.state.chartData,
      valueRanges: this.props.valueRanges,
      fromDate: this.props.fromDate,
      toDate: this.props.toDate,
    });
    const barChartOptions = BarChartOptions({
      onComplete: this.onZoomOrPanComplete,
      statistics: this.state.statistics,
      chartData: this.state.chartData,
      valueRanges: this.props.valueRanges,
      fromDate: this.props.fromDate,
      toDate: this.props.toDate,
    });

    if (!this.shouldDisplayChart()) {
      return null;
    }

    return (
      <Paper>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.chartOptionsWrapper}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <SensorSelector
                      selectedIndexes={this.state.selectedIndexes}
                      onSelectedIndexesChanged={this.setSelectedIndexes}
                      portsConfig={this.props.portsConfig}
                      onPortsConfigChanged={this.setPortsConfig}
                      graphType={this.props.graphType}
                      buttonColorGenerator={this.getColor}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  {this.props.graphType === GraphType.DIGITAL && (
                    <>
                      <Grid item>
                        <FormGroup row className={classes.formGroup}>
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                checked={this.state.displayRawData}
                                onChange={this.toggleDisplayRawData}
                              />
                            }
                            label="Raw Data"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item>
                        <div className={classes.verticalDivider}></div>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <Button
                      size="large"
                      color={this.state.graphMode === GraphMode.LINE ? 'primary' : 'inherit'}
                      onClick={(): void => this.setGraphMode(GraphMode.LINE)}
                    >
                      <LineChartIcon />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      color={this.state.graphMode === GraphMode.BAR ? 'primary' : 'inherit'}
                      onClick={(): void => this.setGraphMode(GraphMode.BAR)}
                    >
                      <BarChartIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ChartStatistics
          statistics={this.state.statistics}
          chartData={this.state.chartData}
          graphType={this.props.graphType}
          every={this.props.every}
        />

        <Grid container direction="row">
          <Grid item xs={12}>
            {this.state.selectedIndexes.length === 0 && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.statusContainer}
              >
                <Grid item>
                  <Typography>
                    <Box fontWeight={600} fontSize={18}>
                      No Selected indexes
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {this.state.selectedIndexes.length > 0 && !this.containsData() && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.statusContainer}
              >
                <Grid item>
                  <Typography>
                    <Box fontWeight={600} fontSize={18}>
                      {this.state.isLoaded ? 'No Data' : 'Loading'}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {this.state.selectedIndexes.length > 0 &&
              this.containsData() &&
              this.state.graphMode === GraphMode.BAR &&
              this.state.isLoaded && (
                <Bar data={this.getChartData()} options={barChartOptions} height={500} />
              )}
            {this.state.selectedIndexes.length > 0 &&
              this.containsData() &&
              this.state.graphMode === GraphMode.LINE &&
              this.state.isLoaded && (
                <Line
                  data={this.getChartData()}
                  options={lineChartOptions}
                  height={500}
                  ref={this.chartRef}
                />
              )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

interface ChartProps extends PeriodStateProps, WithStyles<typeof styles> {
  graphType: GraphType;
  deviceNumber: string;
  timezone: string;
  every: number;
  portsConfig: Record<number, Port>;
  showAverage: boolean;
  valueRanges: EventValueRange[];
  setPortsConfig: (portsConfig: Record<number, Port>) => void;
  setChartData: (chartData: TimeSeriesChartData[]) => void;
  onUpdateSelectedSensors: (selectedSensors: number[]) => void;
}

interface ChartState {
  chartData: TimeSeriesChartData[];
  graphMode: GraphMode;
  selectedIndexes: number[];
  displayRawData: boolean;
  isLoaded: boolean;
  statistics: ChartSensorStatistics[];
}

interface PeriodStateProps {
  fromDate: Date;
  toDate: Date;
  fromDateTz: Date;
  toDateTz: Date;
  avgFromDateTz: Date;
  avgToDateTz: Date;
}

const mapStateToProps = (stateProps: { period: PeriodStateProps }): PeriodStateProps => ({
  fromDate: stateProps.period.fromDate,
  toDate: stateProps.period.toDate,
  fromDateTz: stateProps.period.fromDateTz,
  toDateTz: stateProps.period.toDateTz,
  avgFromDateTz: stateProps.period.avgFromDateTz,
  avgToDateTz: stateProps.period.avgToDateTz,
});

export default connect(mapStateToProps, {})(withStyles(styles)(Chart));
