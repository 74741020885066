import {
  DEV_SENSOR_INDEX_CHANGED,
  DEV_SELECTED,
  DEV_SELECTED_CHANGED,
  DEV_OPEN_CLOSE_MENU,
  DEV_FROM_DATE_CHANGED,
  DEV_TO_DATE_CHANGED,
  DEV_OPEN_CLOSE_DEV_MENU,
  FILTER_CHANGED,
  SET_SELECTED_PAGE,
  SET_DEVICES,
} from '../actions/types';

export default function devReducer(
  state = {
    devices: [],
    devicesArray: [],
    selectedDevice: null,
    selectMode: 'radio',
    dataGridFilters: [],
    menuOpen: false,
    devMenu: false,
    sensorIndex: 0,
    selectedPageId: '',
  },
  action
) {
  switch (action.type) {
    case DEV_SELECTED:
      return {
        ...state,
        devices: action.devices,
        selectMode: action.selectMode,
        menuOpen: action.menuOpen,
        devMenu: action.devMenu,
      };
    case SET_DEVICES:
      return { ...state, devicesArray: action.payload };
    case DEV_SELECTED_CHANGED:
      return { ...state, selectedDevice: action.selectedDevice };
    case FILTER_CHANGED:
      return {
        ...state,
        dataGridFilters: action.dataGridFilters,
      };
    case DEV_FROM_DATE_CHANGED:
      return { ...state, fromDate: action.fromDate };
    case DEV_TO_DATE_CHANGED:
      return { ...state, toDate: action.toDate };
    case DEV_OPEN_CLOSE_MENU:
      return { ...state, menuOpen: action.menuOpen };
    case DEV_OPEN_CLOSE_DEV_MENU:
      return { ...state, menuOpen: action.menuOpen, devMenu: action.devMenu };
    case DEV_SENSOR_INDEX_CHANGED:
      return { ...state, sensorIndex: action.sensorIndex };
    case SET_SELECTED_PAGE:
      return { ...state, selectedPageId: action.pageId };
    default:
      return state;
  }
}
