import React, { FC, ReactElement } from 'react';

import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';

import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { ModbusSlaveConfigModel } from '@thingslog/repositories';

const ModbusSlaveNetworkConfiguration: FC<ModbusSlaveNetworkConfigurationProps> = () => {
  const { t } = useTranslation();

  return (
    <ConnectForm<ModbusSlaveConfigModel>>
      {({ control }: UseFormReturn<ModbusSlaveConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 gap-5">
          <Controller
            control={control}
            name="modbusAddress"
            rules={{
              min: { value: 0, message: t('device_network_config_modbus_address_min') },
              max: { value: 255, message: t('device_network_config_modbus_address_min') },
            }}
            render={({
              field,
            }: UseControllerReturn<ModbusSlaveConfigModel, 'modbusAddress'>): ReactElement => (
              <TextField
                {...field}
                type="number"
                value={field.value < 0 ? field.value + 256 : field.value}
                label={t('device_initial_config_modbus_address')}
                size="small"
              />
            )}
          />
        </div>
      )}
    </ConnectForm>
  );
};

interface ModbusSlaveNetworkConfigurationProps {}

export default ModbusSlaveNetworkConfiguration;
