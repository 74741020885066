import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  AccountSolution,
  Country,
  CreateCompanyDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseType,
  ErrorResponse,
} from '@thingslog/repositories';
import { LocalizedDatePicker, useToast } from '@thingslog/ui-components';
import {
  companyQueryClient,
  countriesQueryClient,
  timezonesQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { useNavigate } from 'react-router-dom';
import LicenseMaxCount from './components/LicenseMaxCountTextField';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { addMonths, addYears, isToday } from 'date-fns';
import JwtValidator from '../../common/JwtValidator';
import { AxiosError } from 'axios';

const CompanyManagerV2: FC<CompanyManagerV2Props> = (props: CompanyManagerV2Props) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const { useCreateCompany } = useMemo(() => companyQueryClient, []);
  const { useGetTimezones } = useMemo(() => timezonesQueryClient, []);
  const { useGetCountries } = useMemo(() => countriesQueryClient, []);

  const company = useSelector((state: ReduxState) => state.company);
  const authCompanyName = useSelector((state: ReduxState) => state.auth.companyName);

  const [companyName, setCompanyName] = useState<string>('');
  const [solutionType, setSolutionType] = useState<AccountSolution | 'None'>('None');
  const [timezoneId, setTimezoneId] = useState<string>('UTC');
  const [isContactIdSelected, setIsContactIdSelected] = useState(true);
  const [isAccountIdSelected, setIsAccountIdSelected] = useState(false);

  const [contactId, setContactId] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');

  const [licenseType, setLicenseType] = useState<LicenseType>(LicenseType.PER_POC);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(addYears(new Date(), 1));
  const [maxReadings, setMaxReadings] = useState<number>(1);
  const [maxDataLoggers, setMaxDataLoggers] = useState<number>(1);
  const [maxSensors, setMaxSensors] = useState<number>(1);
  const [hasError, setHasError] = useState<boolean>(false);

  const isOnlyAdminRole = useMemo(
    () => jwtValidator.hasRole('ROLE_ADMIN') && !jwtValidator.hasRole('ROLE_SUPER_ADMIN'),
    [jwtValidator]
  );

  const [country, setCountry] = useState<string>('Bulgaria');
  const [vatNumber, setVatNumber] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [area, setArea] = useState<string | null>(null);
  const [municipality, setMunicipality] = useState<string | null>(null);

  //Clear input upon change, makes companyObject always up-to-date for creation
  useEffect(() => {
    setMaxSensors(1);
    setMaxDataLoggers(1);
    setMaxReadings(1);
    if (licenseType === LicenseType.PER_POC && isToday(fromDate)) {
      setToDate(addMonths(fromDate, 6));
    }
  }, [licenseType]);

  const { mutate: createCompany } = useCreateCompany({
    onSuccess: () => {
      toast({ message: t('company_manager_account_created'), type: 'success' });
      navigate('/app/Accounts');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error.response?.status === 409) {
        toast({ message: t('company_manager_account_name_exists'), type: 'error' });
      } else {
        toast({ message: t('company_manager_create_company_error'), type: 'error' });
      }
    },
  });

  const timezonesQuery = useGetTimezones({
    onError: () => {
      toast({ message: t('company_manager_timezones_error'), type: 'error' });
    },
    refetchOnWindowFocus: false,
  });

  const countriesQuery = useGetCountries({
    onError: () => {
      toast({ message: t('company_manager_countries_error'), type: 'error' });
    },
    refetchOnWindowFocus: false,
  });

  const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCompanyName(event.target.value);
  };

  const handleSolutionTypeChange = (event: SelectChangeEvent): void => {
    setSolutionType(event.target.value as AccountSolution);
  };

  const handleContactIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setContactId(event.target.value);
  };

  const handleAccountIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setAccountId(event.target.value);
  };

  const handleContactRadioButtonChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsContactIdSelected(true);
    setIsAccountIdSelected(false);
    setLicenseType(LicenseType.PER_POC);
    setAccountId('');
  };

  const handleAccountRadioButtonChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsAccountIdSelected(true);
    setIsContactIdSelected(false);
    setLicenseType(LicenseType.PER_DEVICE);
    setContactId('');
  };

  const handleLicenseTypeChange = (event: SelectChangeEvent): void => {
    setLicenseType(event.target.value as LicenseType);
  };

  const handleFromDateChange = (newDate: Date | null): void => {
    if (newDate) {
      setFromDate(newDate);
    }
  };

  const handleToDateChange = (newDate: Date | null): void => {
    if (newDate) {
      setToDate(newDate);
    }
  };

  const handleSubmit = (): void => {
    const solutionValue = solutionType !== 'None' ? solutionType : null;

    const licenseObject = {
      companyName: companyName,
      '@type': licenseType,
      isActive: true,
      validityPeriodFrom: fromDate,
      validityPeriodTo: toDate,
    };

    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        (licenseObject as LicensePerDeviceDto).maxDataLoggers = maxDataLoggers;
        break;
      case LicenseType.PER_READING:
        (licenseObject as LicensePerReadingDto).maxReadings = maxReadings;
        break;
      case LicenseType.PER_SENSOR:
        (licenseObject as LicensePerSensorDto).maxSensors = maxSensors;
        break;
    }

    const companyData: CreateCompanyDto = {
      name: companyName,
      parentId: isOnlyAdminRole ? null : company.id,
      domain: '',
      solution: solutionValue,
      users: [],
      accountRef: accountId || null,
      contactRef: contactId || null,
      licenseData: accountId || contactId ? [licenseObject] : null,
      childCompaniesNames: [],
      zoneId: timezoneId,
      companyInfo: {
        companyName: companyName,
        country: country,
        address: address,
        vatNumber: vatNumber,
        city: city,
        zipCode: zipCode,
        municipality: municipality,
        area: area,
        logo: null,
      },
    };

    createCompany(companyData);
  };

  const handleChange = (licenseType: LicenseType, value: number): void => {
    switch (licenseType) {
      case LicenseType.PER_READING:
        setMaxReadings(value);
        break;
      case LicenseType.PER_DEVICE:
        setMaxDataLoggers(value);
        break;
      case LicenseType.PER_SENSOR:
        setMaxSensors(value);
        break;
      default:
        break;
    }
  };

  const renderSolutionTypeMenuItemLabel = (solutionType: AccountSolution): string => {
    switch (solutionType) {
      case AccountSolution.AGRI:
        return t('company_manager_solution_option_agri');
      case AccountSolution.AIR_QUALITY:
        return t('company_manager_solution_option_air_quality');
      case AccountSolution.STREET_LIGHTS:
        return t('company_manager_solution_option_street_lights');
      case AccountSolution.PUMP_STATION:
        return t('company_manager_solution_option_pump_station');
      default:
        return 'None';
    }
  };

  const renderWarningCompanyName = (): string => {
    if (isOnlyAdminRole && company.name) {
      return (
        authCompanyName ||
        jwtValidator.decodedToken?.companyName ||
        t('company_manager_current_logged_in_company')
      );
    } else {
      return company.name || t('company_manager_current_selected_company');
    }
  };

  return (
    <Header>
      <div className="flex flex-col justify-center items-center w-full ">
        <div className="w-1/5 space-y-4 min-w-[360px]">
          <TitleHeader pageType="form" title={t('company_manager_create_company')} />
          <h5 className="text-left font-bold">{t('company_manager_account')}</h5>
          <TextField
            fullWidth
            autoFocus
            value={companyName}
            onChange={handleCompanyNameChange}
            label={t('company_manager_account_name')}
            name="group_name"
            size="small"
            variant="outlined"
            required
          />
          <FormControl fullWidth>
            <InputLabel>{t('company_manager_solution_type')}</InputLabel>
            <Select
              variant="outlined"
              label={t('company_manager_solution_type')}
              value={solutionType}
              size="small"
              onChange={handleSolutionTypeChange}
            >
              <MenuItem value={'None'}>{t('company_manager_solution_option_none')}</MenuItem>
              {Object.entries(AccountSolution).map(([key, value]: [string, string]) => (
                <MenuItem value={value} key={value}>
                  {renderSolutionTypeMenuItemLabel(value as AccountSolution)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {timezonesQuery.isSuccess && (
            <Autocomplete
              defaultValue={timezoneId}
              className="w-full"
              options={Array.from(timezonesQuery.data || [])}
              value={timezoneId}
              onChange={(event: SyntheticEvent, newValue: string | null): void =>
                setTimezoneId(newValue as string)
              }
              renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                <TextField
                  required
                  {...params}
                  error={!timezoneId}
                  label={t('company_manager_timezone')}
                  size="small"
                />
              )}
            />
          )}
          {jwtValidator.hasRole('ROLE_SUPER_ADMIN') && (
            <>
              <div className="flex justify-start items-center">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={isContactIdSelected ? 'contactId' : 'accountId'}
                >
                  <FormControlLabel
                    value="contactId"
                    control={<Radio onChange={handleContactRadioButtonChange} />}
                    label=""
                  />
                  <FormControlLabel
                    value="accountId"
                    control={<Radio onChange={handleAccountRadioButtonChange} />}
                    label=""
                  />
                </RadioGroup>
                <div className="space-y-5">
                  <TextField
                    disabled={isAccountIdSelected}
                    fullWidth
                    autoFocus
                    value={contactId}
                    onChange={handleContactIdChange}
                    label={t('company_manager_contact_id')}
                    name="group_name"
                    size="small"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 8 }}
                  />
                  <TextField
                    disabled={isContactIdSelected}
                    fullWidth
                    autoFocus
                    value={accountId}
                    onChange={handleAccountIdChange}
                    label={t('company_manager_account_id')}
                    name="group_name"
                    size="small"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 12 }}
                  />
                </div>
              </div>
              <h5 className="text-left font-bold flex items-center">
                {t('company_manager_license')}
                <Tooltip
                  placement="right-start"
                  title={
                    <div style={{ fontSize: 13 }}>{t('company_manager_license_info_box')}</div>
                  }
                  arrow
                >
                  <ErrorOutlineIcon className=" text-blue-600" />
                </Tooltip>
              </h5>
              <FormControl fullWidth>
                <InputLabel>{t('company_manager_license_type')}</InputLabel>
                <Select
                  disabled={!accountId && !contactId}
                  variant="outlined"
                  label={t('company_manager_license_type')}
                  value={licenseType}
                  size="small"
                  onChange={handleLicenseTypeChange}
                >
                  <MenuItem value={LicenseType.PER_DEVICE} disabled={!accountId}>
                    {t('company_manager_license_per_device')}
                  </MenuItem>
                  <MenuItem value={LicenseType.PER_READING} disabled={!accountId}>
                    {t('company_manager_license_per_reading')}
                  </MenuItem>
                  <MenuItem value={LicenseType.PER_SENSOR} disabled={!accountId}>
                    {t('company_manager_license_per_sensor')}
                  </MenuItem>
                  <MenuItem value={LicenseType.PER_REMOTE_NODE} disabled={!accountId}>
                    {t('company_manager_license_per_remote_node')}
                  </MenuItem>
                  <MenuItem value={LicenseType.PER_POC} disabled={!contactId}>
                    {t('company_manager_license_per_poc')}
                  </MenuItem>
                </Select>
              </FormControl>
              <LocalizedDatePicker
                disabled={!accountId && !contactId}
                label={t('company_manager_valid_from')}
                value={fromDate}
                maxDate={toDate}
                minDate={licenseType === LicenseType.PER_READING ? new Date() : undefined}
                onChange={handleFromDateChange}
                inputFormat="dd/MM/yyyy"
                renderInputTextField={{
                  size: 'small',
                  className: 'bg-white, w-full',
                  onKeyDown: (
                    e: BaseSyntheticEvent<KeyboardEvent, EventTarget & HTMLDivElement, EventTarget>
                  ): void => e.preventDefault(),
                  sx: {
                    '& .MuiInputBase-input': {
                      caretColor: 'transparent',
                    },
                  },
                }}
              />
              <LocalizedDatePicker
                disabled={!accountId && !contactId}
                label={t('company_manager_valid_to')}
                value={toDate}
                minDate={fromDate}
                onChange={handleToDateChange}
                inputFormat="dd/MM/yyyy"
                renderInputTextField={{
                  size: 'small',
                  className: 'bg-white, w-full',
                  onKeyDown: (
                    e: BaseSyntheticEvent<KeyboardEvent, EventTarget & HTMLDivElement, EventTarget>
                  ): void => e.preventDefault(),
                  sx: {
                    '& .MuiInputBase-input': {
                      caretColor: 'transparent',
                    },
                  },
                }}
              />
              {licenseType !== LicenseType.PER_POC &&
                licenseType !== LicenseType.PER_REMOTE_NODE && (
                  <LicenseMaxCount
                    hasError={hasError}
                    setHasError={setHasError}
                    onChange={handleChange}
                    licenseType={licenseType}
                    maxSensors={maxSensors}
                    maxDataLoggers={maxDataLoggers}
                    maxReadings={maxReadings}
                    disabled={!accountId}
                  />
                )}
            </>
          )}
          {company.name && (
            <>
              <div className="text-gray-800 grid grid-cols-5 items-center bg-gray-100 p-2 rounded">
                <WarningAmberIcon className="text-5xl text-orange-600 col-span-1" />
                <div className="col-span-4">
                  <div className="text-lg">
                    {t('company_manager_child_company_warning_message')}
                  </div>
                  <div className="font-semibold">{renderWarningCompanyName()}</div>
                </div>
              </div>
            </>
          )}
          <div className="text-left font-bold">{t('company_manager_account_info')}</div>
          <Autocomplete
            defaultValue={country}
            className="w-full"
            options={countriesQuery.data?.countries.map((country: Country) => country.name) || []}
            value={country}
            onChange={(event: SyntheticEvent, newValue: string | null): void =>
              setCountry(newValue || '')
            }
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                error={!country}
                required
                {...params}
                label={t('company_manager_country')}
                size="small"
              />
            )}
          />
          <TextField
            fullWidth
            value={address}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setAddress(event.target.value)
            }
            label={t('company_manager_address')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={city}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => setCity(event.target.value)}
            label={t('company_manager_city')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={municipality}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setMunicipality(event.target.value)
            }
            label={t('company_manager_municipality')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={area}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => setArea(event.target.value)}
            label={t('company_manager_area')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={zipCode}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setZipCode(event.target.value)
            }
            label={t('company_manager_zip_code')}
            size="small"
            variant="outlined"
          />
          <TextField
            fullWidth
            value={vatNumber}
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              setVatNumber(event.target.value)
            }
            label={t('company_manager_vat_number')}
            size="small"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="w-full"
            disabled={!companyName || hasError || !country}
          >
            {t('button_create')}
          </Button>
        </div>
      </div>
    </Header>
  );
};

interface CompanyManagerV2Props {}

export default CompanyManagerV2;
