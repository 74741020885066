import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlProps,
} from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import ValidDropdownOptions from './EverySelectorDropdownOptions';

const EverySelector: FC<EverySelectorProps> = ({
  confEvery,
  confPeriod,
  every,
  maximumInSec,
  minimumInSec,
  onInvalidEvery,
  onEveryChange,
  formControlProps,
  translation,
}: EverySelectorProps) => {
  // #region State
  const [confInSec, setConfInSec] = useState<number | null>(null);
  const [dropdownOptionsInSec, setDropdownOptionsInSec] = useState<number[]>([]);
  // #endregion

  // #region Function
  const generateDropdownOptions = (confInSec: number): number[] => {
    let validDropdownOptions = [...ValidDropdownOptions];
    let personalizedDropdownOptions: number[] = [];
    [1, 2, 3, 4, 5, 10, 15, 30, 60].forEach((value: number): void => {
      personalizedDropdownOptions.push(value * confInSec);
    });
    let allDropdownOtions = [...validDropdownOptions, ...personalizedDropdownOptions];
    let uniqueDropdownOptions = Array.from(new Set(allDropdownOtions));
    uniqueDropdownOptions.sort((a: number, b: number) => a - b);
    let filteredDropdownOptions: number[] = [];
    uniqueDropdownOptions.forEach((value: number): void => {
      let isValid = true;
      if (value <= 0) isValid = false;
      if (value < confInSec) isValid = false;
      if (minimumInSec && value < minimumInSec) isValid = false;
      if (maximumInSec && value > maximumInSec) isValid = false;
      if (value % confInSec !== 0) isValid = false;
      if (isValid) filteredDropdownOptions.push(value);
    });
    return filteredDropdownOptions;
  };

  const createDateString = (
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  ): string => {
    let output = '';
    if (days === 1) output += `${days} ${translation?.day || 'day'} `;
    if (days > 1) output += `${days} ${translation?.days || 'days'} `;
    if (hours === 1) output += `${hours} ${translation?.hour || 'hour'} `;
    if (hours > 1) output += `${hours} ${translation?.hours || 'hours'} `;
    if (minutes === 1) output += `${minutes} ${translation?.minute || 'minute'} `;
    if (minutes > 1) output += `${minutes} ${translation?.minutes || 'minutes'} `;
    if (seconds === 1) output += `${seconds} ${translation?.second || 'second'}`;
    if (seconds > 1) output += `${seconds} ${translation?.second || 'seconds'}`;

    return output;
  };

  const convertSecondsToString = (totalSeconds: number): string => {
    let remainingSecs = totalSeconds;

    const days = Math.floor(remainingSecs / (24 * 60 * 60));
    if (days > 0) remainingSecs -= days * 24 * 60 * 60;

    const hours = Math.floor(remainingSecs / (60 * 60));
    if (hours > 0) remainingSecs -= hours * 60 * 60;

    const minutes = Math.floor(remainingSecs / 60);
    if (minutes > 0) remainingSecs -= minutes * 60;

    const seconds = remainingSecs;

    return createDateString(days, hours, minutes, seconds);
  };
  // #endregion

  // #region Effects
  useEffect(() => {
    let confInSec = 0;
    if (confPeriod === 'SECONDS') {
      confInSec = confEvery * 1;
    } else if (confPeriod === 'MINUTES') {
      confInSec = confEvery * 60;
    } else if (confPeriod === 'HOURS') {
      confInSec = confEvery * 60 * 60;
    } else if (confPeriod === 'DAYS') {
      confInSec = confEvery * 60 * 60 * 24;
    }
    setConfInSec(confInSec);
  }, [confEvery, confPeriod]);

  useEffect(() => {
    if (confInSec) {
      const dropdownOptions = generateDropdownOptions(confInSec);
      setDropdownOptionsInSec(dropdownOptions);
    }
  }, [confInSec]);

  useEffect(() => {
    if (confInSec) {
      let isInvalid = false;
      if (minimumInSec && minimumInSec > every * confInSec) {
        isInvalid = true;
      }
      if (maximumInSec && maximumInSec < every * confInSec) {
        isInvalid = true;
      }
      if (isInvalid && onInvalidEvery) {
        const dropdownOptions = generateDropdownOptions(confInSec);
        const validEveryOptions = dropdownOptions.map((i: number) => i / confInSec);
        onInvalidEvery(validEveryOptions);
      }
    }
  }, [every, confInSec, minimumInSec, maximumInSec, onInvalidEvery]);
  // #endregion

  // #region Render
  if (confInSec) {
    return (
      <FormControl variant="outlined" size="small" {...formControlProps}>
        <InputLabel>{translation?.label || 'Granularity'}</InputLabel>
        <Select
          value={every * confInSec}
          onChange={(e: SelectChangeEvent<number>): void => {
            e.preventDefault();
            const periodInSec = Number(e.target.value);
            const every = periodInSec / confInSec;
            onEveryChange(every);
          }}
          fullWidth
          autoWidth
          label={translation?.label || 'Granularity'}
        >
          {dropdownOptionsInSec.map((i: number) => (
            <MenuItem key={i} value={i}>
              {convertSecondsToString(i)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return <></>;
  // #endregion
};

interface EverySelectorProps {
  confEvery: number;
  confPeriod: 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
  every: number;
  onEveryChange: (newEvery: number) => void;
  onInvalidEvery?: (validEveryOptions: number[]) => void;
  maximumInSec?: number;
  minimumInSec?: number;
  formControlProps?: FormControlProps;
  translation?: {
    label?: string;
    second?: string;
    seconds?: string;
    minute?: string;
    minutes?: string;
    hour?: string;
    hours?: string;
    day?: string;
    days?: string;
  };
}

export default EverySelector;
