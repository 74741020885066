import React, { FC, ReactNode, RefObject, useState } from 'react';
import clsx from 'clsx';
import Toast from './Toast';
import { ToastPosition } from './model/ToastPosition';
import { ToastProps } from './model/ToastProps';
import ToastContext from './ToastContext';

const ToastProvider: FC<ToastProviderProps> = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [position, setPosition] = useState<ToastPosition>('bottomCenter');

  const toast = (toast: Omit<ToastProps, 'id'>): void => {
    //first check for position
    if (toast.position && toast.position !== position) {
      setPosition(toast.position);
    }

    // add it to the list
    setToasts((toasts: ToastProps[]) => [...toasts, { ...toast, id: Math.random() * 10000 }]);
  };

  const remove = (toastId: number, ref: RefObject<HTMLDivElement>): void => {
    ref?.current?.classList.remove('animate-toastIn');
    ref?.current?.classList.add('animate-toastOut');

    //remove element after animation is done
    ref?.current?.addEventListener('animationend', () => {
      // lets remove it
      setToasts((toasts: ToastProps[]) =>
        toasts.filter((toast: ToastProps) => toast.id !== toastId)
      );
    });
  };

  const getLocation = (position: ToastPosition): string => {
    const positionClasses: Record<ToastPosition, string> = {
      topRight: 'top-0 right-1',
      topCenter: 'top-0 right-1/2 translate-x-1/2',
      topLeft: 'top-0 left-1',
      bottomLeft: 'bottom-0 left-1',
      bottomCenter: 'bottom-0 right-1/2 translate-x-1/2',
      bottomRight: 'bottom-0 right-1',
    };
    return positionClasses[position];
  };

  return (
    <div className="">
      <ToastContext.Provider value={{ toast, remove, position }}>
        {children}
        {/* toast list */}
        <div className={clsx(getLocation(position), 'fixed z-50 w-screen max-w-xs')}>
          {toasts.map((toast: ToastProps) => (
            <Toast key={toast.id} {...toast} />
          ))}
        </div>
      </ToastContext.Provider>
    </div>
  );
};

interface ToastProviderProps {
  children: ReactNode;
}

export default ToastProvider;
