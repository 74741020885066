import React, { FC } from 'react';

import { Chip } from '@mui/material';

import { cn } from '../Utils/cnUtil';

const AlarmSeverityChip: FC<AlarmSeverityChipProps> = ({
  className,
  value,
}: AlarmSeverityChipProps) => {
  return (
    <Chip
      label={value}
      size="small"
      color={value <= 3 ? 'info' : value <= 6 ? 'warning' : 'error'}
      className={cn(className, 'aspect-square')}
    />
  );
};

interface AlarmSeverityChipProps {
  className?: string;
  value: number;
}

export default AlarmSeverityChip;
