import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeviceInitialConfigRepository from '../../clients/DeviceInitialConfig/DeviceInitialConfigRepository';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { ReduxState } from '../../reducers';
import deviceConfigClient from '../../DeviceConfigClient';
import { DeviceConfigResponse } from '../../model/API/DeviceConfig/DeviceConfigResponse';
import { StyledTableCell, StyledTableRow } from './DevicePrediction.styles';
import DevicePredictionRepository from '../../clients/DevicePrediction/DevicePredictionRepository';
import format from 'date-fns/format';
import { Trans, useTranslation } from 'react-i18next';
import getDefaultSensorName from '../../common/SensorNameHelper';
import { TimeUnits } from '../../model/TimeUnits/TimeUnits';
import { EverySelector } from '@thingslog/ui-components';
import ColorLegend from '../../components/ExpandableInfoDisplay/ColorLegend';
import ExpandableInfoDisplay from '../../components/ExpandableInfoDisplay/ExpandableInfoDisplay';

const DevicePrediction: React.FC<DevicePredictionProps> = (props: DevicePredictionProps) => {
  const { t } = useTranslation();
  const [selectedSensorIndex, setSelectedSensorIndex] = React.useState(0);
  const [sensors, setSensors] = React.useState<Record<number, string>>({});
  const [every, setEvery] = React.useState<number | null>(1);
  const [forwardDays, setForwardDays] = React.useState<number>(5);
  const [backwardDays, setBackwardDays] = React.useState<number>(5);
  const [img, setImg] = React.useState<string>('');
  const [jsonData, setJsonData] = React.useState<Record<number, number> | null>(null);
  const [errorJson, setErrorJson] = React.useState<boolean>(false);
  const [errorImg, setErrorImg] = React.useState<boolean>(false);
  const [confEvery, setConfEvery] = React.useState<number>(1);
  const [confPeriod, setConfPeriod] = React.useState<
    'DAYS' | 'HOURS' | 'MINUTES' | 'SECONDS' | null
  >(null);
  const [isJsonLoading, setIsJsonLoading] = React.useState<boolean>(false);
  const [isImgLoading, setIsImgLoading] = React.useState<boolean>(false);

  const initialConfigClient = useMemo(() => new DeviceInitialConfigRepository(), []);
  const devicePrediction = useMemo(() => new DevicePredictionRepository(), []);
  const { deviceNumber } = useParams();
  const toDate = useSelector((state: ReduxState) => state.period.toDate);
  const fromDate = useSelector((state: ReduxState) => state.period.fromDate);
  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);

  useEffect(() => {
    fetchPortsConfig();
    loadDeviceConfig(deviceNumber!);
    setImg('');
    setJsonData(null);
    setErrorJson(false);
    setErrorImg(false);
  }, [deviceNumber]);

  const fetchPortsConfig = async (): Promise<void> => {
    const portsConfig = await initialConfigClient.getDevicePortsConfig(deviceNumber!);
    let sensors: Record<number, string> = {};
    for (const k in portsConfig) {
      const key = k;
      if (portsConfig[k].enabled === true) {
        const value = portsConfig[k].sensor.name || getDefaultSensorName(k);
        sensors[key] = value;
      }
    }
    setSensors(sensors);
  };

  const loadDeviceConfig = async (deviceNumber: string): Promise<void> => {
    await deviceConfigClient.getDeviceConfig(deviceNumber, (config: DeviceConfigResponse): void => {
      setConfEvery(config.every);
      let confPeriod: 'DAYS' | 'HOURS' | 'MINUTES' | 'SECONDS' | null = null;
      if (config.recordPeriod === TimeUnits.DAYS) confPeriod = 'DAYS';
      if (config.recordPeriod === TimeUnits.HOURS) confPeriod = 'HOURS';
      if (config.recordPeriod === TimeUnits.MINUTES) confPeriod = 'MINUTES';
      if (config.recordPeriod === TimeUnits.SECONDS) confPeriod = 'SECONDS';
      setConfPeriod(confPeriod);
    });
  };

  function handleForwardDaysChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setForwardDays(parseInt(event.target.value));
  }

  function handleBackwardDaysChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setBackwardDays(parseInt(event.target.value));
  }

  const fetchJson = async (): Promise<void> => {
    setIsJsonLoading(true);
    setJsonData(null);
    setErrorJson(false);
    try {
      const predictionJson = await devicePrediction.getDevicePredictionJson(
        deviceNumber!,
        selectedSensorIndex,
        fromDate,
        toDate,
        forwardDays,
        backwardDays,
        every!,
        0,
        0
      );

      const jsonData: Record<number, number> = {};
      for (const data of predictionJson.data) {
        const timestamp = data[0];
        const trend = data[1];
        jsonData[trend] = timestamp;
      }
      setJsonData(jsonData);
    } catch (error) {
      console.error(error);
      setIsJsonLoading(false);
      setErrorJson(true);
    } finally {
      setIsJsonLoading(false);
    }
  };

  const fetchImg = async (): Promise<void> => {
    setIsImgLoading(true);
    setImg('');
    setErrorImg(false);
    try {
      const predictionImg = await devicePrediction.getDevicePredictionImg(
        deviceNumber!,
        selectedSensorIndex,
        fromDate,
        toDate,
        forwardDays,
        backwardDays,
        every!,
        0,
        0
      );
      let base64ImageString = Buffer.from(predictionImg, 'binary').toString('base64');
      let srcValue = 'data:image/png;base64,' + base64ImageString;
      setImg(srcValue);
    } catch (error) {
      console.error(error);
      setErrorImg(true);
      setIsImgLoading(false);
    } finally {
      setIsImgLoading(false);
    }
  };

  return (
    <Header>
      {selectedDevice && (
        <TitleHeader
          className="mb-4"
          title={t('device_prediction_header')}
          deviceNumber={selectedDevice.number}
          deviceName={selectedDevice.name}
          customerInfo={selectedDevice.customerInfo}
        />
      )}
      <FormControl size="small" className="flex flex-row justify-center gap-4">
        <PeriodPicker />
        <Box>
          <InputLabel className="left-auto">{t('device_prediction_sensor_name')}</InputLabel>
          <Select className="w-44" label="Sensor Name">
            {Object.keys(sensors).map((key: string) => (
              <MenuItem value={key}>{sensors[key]}</MenuItem>
            ))}
          </Select>
        </Box>
        {every && confPeriod && confEvery && (
          <EverySelector
            confEvery={confEvery}
            confPeriod={confPeriod}
            every={every}
            onEveryChange={setEvery}
            translation={{
              day: t('day'),
              days: t('days'),
              hour: t('hour'),
              hours: t('hours'),
              minute: t('minute'),
              minutes: t('minutes'),
              second: t('second'),
              seconds: t('seconds'),
              label: t('every'),
            }}
          />
        )}
        <TextField
          size="small"
          label={t('device_prediction_forward_days')}
          variant="outlined"
          onChange={handleForwardDaysChange}
        />
        <TextField
          size="small"
          label={t('device_prediction_backward_days')}
          variant="outlined"
          onChange={handleBackwardDaysChange}
        />
        <Button
          onClick={(): void => {
            fetchImg();
            fetchJson();
          }}
          variant="contained"
        >
          {t('device_prediction_generate_btn')}
        </Button>
      </FormControl>

      {img && (
        <div className="overflow-x-auto">
          <Box className="pt-9">
            <img src={img} alt="prediction" />
          </Box>
        </div>
      )}

      <div className="m-auto w-1/2 py-10">
        <ExpandableInfoDisplay
          disableGutters
          elevation={0}
          titleContent={t('device_prediction_help_title')}
        >
          <ExpandableInfoDisplay
            disableGutters
            elevation={0}
            titleContent={t('device_prediction_help_title_legend')}
            className="mb-1"
          >
            <ColorLegend
              items={[
                {
                  legendColor: '#0977B5',
                  text: <Trans>{t('device_prediction_help_dark_blue')}</Trans>,
                },
                {
                  legendColor: '#96BED6',
                  text: <Trans>{t('device_prediction_help_light_blue')} </Trans>,
                },
                { legendColor: 'black', text: <Trans>{t('device_prediction_help_black')} </Trans> },
                {
                  legendColor: 'red',
                  text: <Trans>{t('device_prediction_help_red')}</Trans>,
                },
              ]}
            />
          </ExpandableInfoDisplay>
          <ExpandableInfoDisplay
            className="text-left"
            titleContent={t('device_prediction_help_title_input_guide')}
            disableGutters
            elevation={0}
          >
            <Trans i18nKey="device_prediction_help_input_guide_text" />
            <Typography className="mt-3">{t('device_prediction_help_example')} </Typography>
          </ExpandableInfoDisplay>
        </ExpandableInfoDisplay>
      </div>

      {errorJson && (
        <Box sx={{ width: '50%', margin: 'auto', paddingTop: '30px' }} marginTop={2}>
          <Alert severity="error">
            <strong>{t('device_prediction_error')}: </strong>
            {t('device_prediction_error_msg_json')}
          </Alert>
        </Box>
      )}

      {errorImg && (
        <Box sx={{ width: '50%', margin: 'auto', paddingTop: '30px' }} marginTop={2}>
          <Alert sx={{ marginTop: '10px' }} severity="error">
            <strong>{t('device_prediction_error')}: </strong>
            {t('device_prediction_error_msg_image')}
          </Alert>
        </Box>
      )}

      {isImgLoading && isJsonLoading && <CircularProgress />}

      {!isJsonLoading && isImgLoading && <CircularProgress />}

      {isJsonLoading && !isImgLoading && <CircularProgress />}

      {jsonData && (
        <Table className="mt-9 m-auto w-1/2">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">{t('device_prediction_date')}</StyledTableCell>
              <StyledTableCell align="center">{t('device_prediction_time')}</StyledTableCell>
              <StyledTableCell align="center">{t('device_prediction_trend')}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {Object.entries(jsonData).map(([trend, timestamp]: [string, number]) => (
              <StyledTableRow>
                <StyledTableCell align="center">
                  {format(new Date(timestamp), 'dd MMMM yyyy')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {format(new Date(timestamp), 'HH:mm:ss')}
                </StyledTableCell>
                <StyledTableCell align="center">{trend}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Header>
  );
};

interface DevicePredictionProps {}

export default DevicePrediction;
