import React, { useState, useEffect, FC, ChangeEvent } from 'react';
import DeviceSensorGraphData from '../model/DeviceSensorGraphData';
import { TimeSeriesData } from '@thingslog/repositories';
import { LineChart, LineProps } from '@thingslog/ui-components';
import { format } from 'date-fns';
import { SensorsUtil } from '../../../common/SensorsUtil';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import SensorsStatistics from './SensorsStatistics';

export const MultiDeviceDataLineChart: FC<MultiDeviceDataLineChartProps> = ({
  data,
}: MultiDeviceDataLineChartProps) => {
  const [timestampedData, setTimestampedData] = useState<TimestampedDataMap>(new Map());
  const [chartNodes, setChartNodes] = useState<ChartDataPoint[]>([]);
  const [lines, setLines] = useState<LineProps[]>([]);
  const [showSensorsStatistics, setShowSensorsStatistics] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    let chartData: TimestampedDataMap = new Map();

    const allTimestamps: number[] = [];
    data.forEach((deviceSensorData: DeviceSensorGraphData) => {
      deviceSensorData.flowData.forEach((timeSeriesData: TimeSeriesData<number>) => {
        allTimestamps.push(timeSeriesData.timestamp);
      });
      deviceSensorData.averageFlowData.forEach((timeSeriesData: TimeSeriesData<number>) => {
        allTimestamps.push(timeSeriesData.timestamp);
      });
      deviceSensorData.predictionData.forEach((timeSeriesData: TimeSeriesData<number>) => {
        allTimestamps.push(timeSeriesData.timestamp);
      });
    });
    const uniqueTimestamps = new Set(allTimestamps.sort());

    uniqueTimestamps.forEach((timestamp: number) => {
      chartData.set(timestamp, {
        averageData: new Map(),
        flowData: new Map(),
        predictionData: new Map(),
      });
    });

    data.forEach((deviceSensorData: DeviceSensorGraphData) => {
      const deviceSensorKey: DeviceSensorKey = {
        deviceNumber: deviceSensorData.deviceNumber,
        sensorIndex: deviceSensorData.sensorIndex,
      };
      deviceSensorData.flowData.forEach((timeSeriesData: TimeSeriesData<number>) => {
        chartData
          .get(timeSeriesData.timestamp)! // safe to use ! because we have all timestamps in chartData
          .flowData.set(deviceSensorKey, timeSeriesData.value);
      });
      deviceSensorData.averageFlowData.forEach((timeSeriesData: TimeSeriesData<number>) => {
        chartData
          .get(timeSeriesData.timestamp)! // safe to use ! because we have all timestamps in chartData
          .averageData.set(deviceSensorKey, timeSeriesData.value);
      });
      deviceSensorData.predictionData.forEach((timeSeriesData: TimeSeriesData<number>) => {
        chartData
          .get(timeSeriesData.timestamp)! // safe to use ! because we have all timestamps in chartData
          .predictionData.set(deviceSensorKey, timeSeriesData.value);
      });
    });
    setTimestampedData(chartData);
  }, [data]);

  useEffect(() => {
    let chartNodes: ChartDataPoint[] = [];
    timestampedData.forEach((sensorDataPoint: SensorDataPoint, timestamp: number) => {
      const flow: Map<string, number> = new Map();
      sensorDataPoint.flowData.forEach((value: number, key: DeviceSensorKey) => {
        flow.set(`${key.deviceNumber}-${key.sensorIndex}`, parseFloat(value.toFixed(5)));
      });

      const average: Map<string, number> = new Map();
      sensorDataPoint.averageData.forEach((value: number, key: DeviceSensorKey) => {
        average.set(`${key.deviceNumber}-${key.sensorIndex}`, parseFloat(value.toFixed(5)));
      });

      const prediction: Map<string, number> = new Map();
      sensorDataPoint.predictionData.forEach((value: number, key: DeviceSensorKey) => {
        prediction.set(`${key.deviceNumber}-${key.sensorIndex}`, parseFloat(value.toFixed(5)));
      });

      chartNodes.push({
        timestamp: timestamp,
        flowData: flow,
        averageData: average,
        predictionData: prediction,
      });
    });
    setChartNodes(chartNodes);
  }, [timestampedData]);

  useEffect(() => {
    const lines: LineProps[] = [];

    data.map((deviceSensorData: DeviceSensorGraphData) => {
      const graphLineColor = deviceSensorData.graphLineColor;
      const deviceNumber = deviceSensorData.device.number;
      const sensorIndex = deviceSensorData.sensorIndex;
      const units = SensorsUtil.getSensorUnits(deviceSensorData.port.sensor);
      const numberOfDataPoints = deviceSensorData.flowData.length;

      lines.push({
        type: units === 'pulses' ? 'stepAfter' : 'monotone',
        unit: ` ${units}`,
        name: t('new_graph_current_value_name'),
        key: `${deviceNumber}-${sensorIndex}-current`,
        dataKey: (lineChartData: ChartDataPoint): number | undefined =>
          lineChartData.flowData.get(`${deviceNumber}-${sensorIndex}`),
        stroke: graphLineColor.flow,
        color: graphLineColor.flow,
        dot: numberOfDataPoints <= 100 && {
          stroke: graphLineColor.flow,
          strokeWidth: 2,
          fill: graphLineColor.flow,
        },
        isAnimationActive: false,
        strokeWidth: 3,
        activeDot: true,
        connectNulls: true,
      });

      lines.push({
        type: 'monotone',
        unit: ` ${units}`,
        name: t('new_graph_average_value_name'),
        key: `${deviceNumber}-${sensorIndex}-average`,
        dataKey: (lineChartData: ChartDataPoint): number | undefined =>
          lineChartData.averageData.get(`${deviceNumber}-${sensorIndex}`),
        stroke: graphLineColor.average,
        color: graphLineColor.average,
        dot: false,
        strokeWidth: 3,
        strokeDasharray: '5 5',
        activeDot: false,
        connectNulls: true,
      });

      lines.push({
        type: 'monotone',
        unit: ` ${units}`,
        name: t('new_graph_prediction_value_name'),
        key: `${deviceNumber}-${sensorIndex}-prediction`,
        dataKey: (lineChartData: ChartDataPoint): number | undefined =>
          lineChartData.predictionData.get(`${deviceNumber}-${sensorIndex}`),
        stroke: graphLineColor.prediction,
        color: graphLineColor.prediction,
        dot: false,
        strokeWidth: 3,
        strokeDasharray: '10 10',
        activeDot: false,
        connectNulls: true,
      });
    });
    setLines(lines);
  }, [data, chartNodes]);

  const yAxisLabelValue: string | null = data.some(
    (entry: DeviceSensorGraphData): boolean =>
      SensorsUtil.getSensorUnits(entry.port.sensor) !==
      SensorsUtil.getSensorUnits(data[0].port.sensor)
  )
    ? null
    : SensorsUtil.getSensorUnits(data[0].port.sensor);

  if (chartNodes.length > 0) {
    return (
      <div className="h-fit">
        <div className="w-full flex justify-end">
          <FormControl size="small">
            <FormControlLabel
              label={t('new_graph_show_sensors_statistics_label')}
              labelPlacement="start"
              control={
                <Switch
                  checked={showSensorsStatistics}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                    setShowSensorsStatistics(event.target.checked)
                  }
                />
              }
            />
          </FormControl>
        </div>
        <div className="h-[400px]">
          <LineChart
            tooltip={{
              labelFormatter: (label: number) => format(label, 'dd/MM HH:mm'),
            }}
            container={{ width: '100%', height: '100%', debounce: 300 }}
            lineChart={{ data: chartNodes }}
            grid={{ vertical: false, stroke: '#e9e9e9' }}
            xAxis={{
              dataKey: 'timestamp',
              dx: 20,
              domain: ['dataMin', 'dataMax'],
              type: 'number',
              tickMargin: 15,
              tickCount: 15,
              tickLine: true,
              axisLine: true,
              tickFormatter: (value: number): string => `${format(value, 'dd/MM HH:mm')}`,
            }}
            yAxis={{
              label: {
                value: yAxisLabelValue,
                style: { textAnchor: 'middle' },
                angle: -90,
                position: 'left',
                offset: 0,
              },
              tickCount: 10,
              tickMargin: 0,
              axisLine: true,
              tickLine: true,
              type: 'number',
              domain: ['auto', 'auto'],
            }}
            lines={lines}
          />
        </div>
        {showSensorsStatistics && (
          <div className="mt-7">
            <SensorsStatistics deviceSensorGraphData={data} />
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

interface ChartDataPoint {
  timestamp: number;
  flowData: Map<string, number>;
  averageData: Map<string, number>;
  predictionData: Map<string, number>;
}

type TimestampedDataMap = Map<number, SensorDataPoint>;

interface SensorDataPoint {
  flowData: Map<DeviceSensorKey, number>;
  averageData: Map<DeviceSensorKey, number>;
  predictionData: Map<DeviceSensorKey, number>;
}

interface DeviceSensorKey {
  deviceNumber: string;
  sensorIndex: number;
}

interface MultiDeviceDataLineChartProps {
  data: DeviceSensorGraphData[];
}
