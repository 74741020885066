import jwtDecode from 'jwt-decode';
import { Jwt, JwtRole, UserRole } from '@thingslog/repositories';

const { yambolJWT, yambolUrl, bobovdolJWT, bobovdolUrl, pirdopUrl, pirdopJWT } =
  window['runConfig'];

export default class JwtValidator {
  private tokenString: string | null;
  public decodedToken: Jwt | null;
  public isValidToken: boolean;

  public constructor(token?: string | null) {
    if (yambolJWT && yambolUrl && window.location.hostname === yambolUrl) {
      localStorage.setItem('token', yambolJWT);
    }

    if (bobovdolJWT && bobovdolUrl && window.location.hostname === bobovdolUrl) {
      localStorage.setItem('token', bobovdolJWT);
    }

    if (pirdopJWT && pirdopUrl && window.location.hostname === pirdopUrl) {
      localStorage.setItem('token', pirdopJWT);
    }

    this.tokenString = token ? token : localStorage.getItem('token');
    this.decodedToken = this.decodeToken();
    this.isValidToken = this.isValid();
  }

  // #region public methods
  public hasRole = (userRole: UserRole): boolean => {
    if (!this.decodedToken) return false;
    let hasRole = false;

    try {
      this.decodedToken.roles.forEach((role: JwtRole) => {
        if (role.authority === userRole) {
          hasRole = true;
        }
      });
    } catch {
      return false;
    }

    return hasRole;
  };

  public hasRoles = (userRoles: UserRole[]): boolean => {
    if (!this.decodedToken) return false;
    let hasRole = false;

    try {
      this.decodedToken.roles.forEach((role: JwtRole) => {
        if (userRoles.includes(role.authority)) {
          hasRole = true;
        }
      });
    } catch {
      return false;
    }

    return hasRole;
  };

  // returns false if Superadmin has not selected company, otherwise always returns true
  public isAllowedUnlessSuperAdminWithoutSelectedCompany = (
    companyIdFromRedux: number | null
  ): boolean => {
    if (this.hasRole('ROLE_SUPER_ADMIN')) {
      return companyIdFromRedux ? true : false;
    }

    return true;
  };

  // returns companyId from redux if there is selected company or from token if not.
  // Note: its slightly different from useDetermineCompanyId as this Returns null for Superadmin
  public determineCompanyIdForUserRole = (companyIdFromRedux: number | null): number | null => {
    if (!this.decodedToken) return null;
    if (this.hasRole('ROLE_SUPER_ADMIN')) {
      return companyIdFromRedux ? companyIdFromRedux : null;
    } else if (this.hasRole('ROLE_ADMIN')) {
      return companyIdFromRedux ? companyIdFromRedux : Number(this.decodedToken?.companyId);
    } else {
      return Number(this.decodedToken.companyId);
    }
  };

  // #endregion

  // #region private methods
  private isValid = (): boolean => {
    if (!this.tokenString) return false;
    if (!this.decodedToken) return false;

    try {
      const exp = new Date(this.decodedToken.exp * 1000);
      const now = new Date();
      return exp < now ? false : true;
    } catch {
      return false;
    }
  };

  private decodeToken = (): Jwt | null => {
    const token = this.tokenString;
    try {
      return token ? jwtDecode(token) : null;
    } catch {
      return null;
    }
  };
  // #endregion
}
