import { TextareaAutosize } from '@mui/material';
import React, { FC, ChangeEvent, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

const DashboardLayoutEdit: FC<DashboardLayoutEditProps> = ({
  dashboardLayout,
  isDashboardLayoutValid,
  onChange,
  setIsDashboardLayoutValid,
}: DashboardLayoutEditProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    let isValidJson = true;
    try {
      JSON.parse(dashboardLayout);
      isValidJson = true;
    } catch {
      isValidJson = false;
    }
    setIsDashboardLayoutValid(isValidJson);
  }, [dashboardLayout]);

  return (
    <>
      <TextareaAutosize
        className="resize-y w-full"
        maxRows={6}
        minRows={2}
        value={dashboardLayout}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => onChange(e.target.value)}
      />
      {!isDashboardLayoutValid && (
        <p className="text-red-500 mt-2">{t('settings_input_invalid_format_error_msg')}</p>
      )}
    </>
  );
};

interface DashboardLayoutEditProps {
  dashboardLayout: string;
  isDashboardLayoutValid: boolean;
  onChange: (value: string) => void;
  setIsDashboardLayoutValid: (value: boolean) => void;
}

export default DashboardLayoutEdit;
