import { GaEventAction } from './GaEventAction';
import { GaEventCategory } from './GaEventCategory';
import ReactGA from 'react-ga4';

export default class GoogleAnalyticsService {
  public static initGA = (): void => {
    try {
      ReactGA.initialize('G-0X0YKZ5FPH');
    } catch (error) {
      console.error(error.message || 'Could not initialize Google Analytics.');
    }
  };

  public static triggerEvent(
    category: GaEventCategory,
    action: string | GaEventAction,
    label?: string,
    nonInteraction?: boolean,
    value?: number
  ): void {
    let event: GaEvent = {
      category: category,
      action: action,
    };
    if (nonInteraction) event.nonInteraction = nonInteraction;
    if (label) event.label = label;
    if (value) event.value = value;

    try {
      ReactGA.event(event);
    } catch (error) {
      console.error(error.message || 'Could not send event to Google Analytics.');
    }
  }
}

interface GaEvent {
  category: GaEventCategory;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}
