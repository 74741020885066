import { Button } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DeleteUtilityRateModal: FC<DeleteUtilityRateModalProps> = ({
  onConfirm,
  onCancel,
}: DeleteUtilityRateModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-2">
      <span>{t('util_rates_delete_modal_body')}</span>
      <div className="flex space-x-2">
        <Button className="flex-1" onClick={onConfirm} color="error" variant="contained">
          {t('util_rates_confirm')}
        </Button>
        <Button
          className="flex-1"
          onClick={onCancel}
          color="inherit"
          disableElevation
          variant="contained"
        >
          {t('util_rates_cancel')}
        </Button>
      </div>
    </div>
  );
};

interface DeleteUtilityRateModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export default DeleteUtilityRateModal;
