import { format } from 'date-fns';
import React, { FC, ReactNode } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useTranslation } from 'react-i18next';

const ValueRangePeriodVisualizer: FC<ValueRangePeriodVisualizerProps> = ({
  startDay,
  endDay,
  startMonth,
  endMonth,
  startHour,
  endHour,
  startMinute,
  endMinute,
}: ValueRangePeriodVisualizerProps) => {
  const { t } = useTranslation();

  const renderIcon = (): ReactNode => {
    if (startDay !== null || endDay !== null || startMonth !== null || endMonth !== null) {
      return <CalendarMonthIcon />;
    }
    if (startHour !== null || endHour !== null || startMinute !== null || endMinute !== null) {
      return <ScheduleIcon />;
    }
    return <></>;
  };

  const renderText = (): ReactNode => {
    const from = formatDate(startDay, startMonth, startHour, startMinute);
    const to = formatDate(endDay, endMonth, endHour, endMinute);

    if (
      (startDay !== null || startMonth !== null || startHour !== null || startMinute !== null) &&
      (endDay !== null || endMonth !== null || endHour !== null || endMinute !== null)
    ) {
      return `${from} - ${to}`;
    }

    if (
      startDay === null &&
      startMonth === null &&
      startHour === null &&
      startMinute === null &&
      (endDay !== null || endMonth !== null || endHour !== null || endMinute !== null)
    ) {
      return `${t('value_ranges_before')} ${to}`;
    }

    if (
      (startDay !== null || startMonth !== null || startHour !== null || startMinute !== null) &&
      endDay === null &&
      endMonth === null &&
      endHour === null &&
      endMinute === null
    ) {
      return `${t('value_ranges_after')} ${from}`;
    }

    return <></>;
  };

  const formatDate = (
    day: number | null,
    month: number | null,
    hour: number | null,
    minute: number | null
  ): string => {
    let output = '';
    if (day !== null && month !== null) {
      const date = new Date(2022, month - 1, day);
      const formattedDate = format(date, 'dd MMM');
      output += formattedDate;
    }

    if (hour !== null && minute !== null) {
      if (output.length > 0) output += ' ';
      const date = new Date();
      date.setHours(hour);
      date.setMinutes(minute);
      const formattedTime = format(date, 'HH:mm');
      output += formattedTime;
    }

    return output;
  };

  return (
    <span className="flex space-x-1">
      <span>{renderIcon()}</span>
      <span>{renderText()}</span>
    </span>
  );
};

interface ValueRangePeriodVisualizerProps {
  startDay: number | null;
  endDay: number | null;
  startMonth: number | null;
  endMonth: number | null;
  startHour: number | null;
  endHour: number | null;
  startMinute: number | null;
  endMinute: number | null;
}

export default ValueRangePeriodVisualizer;
