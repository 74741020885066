import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  AvailableIconsDto,
  DeviceIconAndLocationDto,
  IconCreationDto,
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
  UpdateIconDto,
} from '@thingslog/repositories';

export interface IconsQueryClient {
  getIcons: (
    deviceNumber: string | null,
    sensorIndex: number | null,
    companyId: number | null
  ) => Promise<AvailableIconsDto>;
  useGetIcons: (
    deviceNumber: string | null,
    sensorIndex: number | null,
    companyId: number | null,
    options?: QueryOptions<AvailableIconsDto>
  ) => QueryResult<AvailableIconsDto>;
  getMapPins: () => Promise<DeviceIconAndLocationDto>;
  useGetMapPins: (
    options?: QueryOptions<DeviceIconAndLocationDto>
  ) => QueryResult<DeviceIconAndLocationDto>;
  createIcon: (body: IconCreationDto) => Promise<void>;
  useCreateIcon: (
    options?: MutationOptions<void, IconCreationDto>
  ) => MutationResult<void, IconCreationDto>;
  updateIcon: (id: number, body: UpdateIconDto) => Promise<void>;
  useUpdateIcon: (
    options?: MutationOptions<void, UpdateMutationProps>
  ) => MutationResult<void, UpdateMutationProps>;
}

export function createIconsQueryClient(axios: AxiosInstance): IconsQueryClient {
  return new IconsImp(axios);
}

class IconsImp {
  public constructor(private axios: AxiosInstance) {}

  public getIcons = async (
    deviceNumber: string | null,
    sensorIndex: number | null,
    companyId: number | null
  ): Promise<AvailableIconsDto> => {
    return await this.axios
      .get('/api/icons', {
        params: {
          deviceNumber: deviceNumber,
          sensorIndex: sensorIndex,
          companyId: companyId,
        },
      })
      .then((response: AxiosResponse) => response.data);
  };

  public useGetIcons = (
    deviceNumber: string | null,
    sensorIndex: number | null,
    companyId: number | null,
    options?: QueryOptions<AvailableIconsDto>
  ): QueryResult<AvailableIconsDto> => {
    return useQuery<AvailableIconsDto, AxiosError>(
      [QueryKeys.GetIcons, deviceNumber, sensorIndex],
      () => {
        return this.getIcons(deviceNumber, sensorIndex, companyId);
      },
      options
    );
  };

  public getMapPins = async (): Promise<DeviceIconAndLocationDto> => {
    return await this.axios
      .get('/api/devices/map/pins')
      .then((response: AxiosResponse) => response.data);
  };

  public useGetMapPins = (
    options?: QueryOptions<DeviceIconAndLocationDto>
  ): QueryResult<DeviceIconAndLocationDto> => {
    return useQuery<DeviceIconAndLocationDto, AxiosError>(
      [QueryKeys.GetMapPins],
      () => {
        return this.getMapPins();
      },
      options
    );
  };

  public createIcon = async (body: IconCreationDto): Promise<void> => {
    return await this.axios
      .post('/api/icons', body)
      .then((response: AxiosResponse) => response.data);
  };

  public useCreateIcon = (
    options?: MutationOptions<void, IconCreationDto>
  ): MutationResult<void, IconCreationDto> => {
    return useMutation<void, AxiosError, IconCreationDto>(
      [QueryKeys.CreateIcon],
      (body: IconCreationDto) => this.createIcon(body),
      options
    );
  };

  public updateIcon = async (id: number, body: UpdateIconDto): Promise<void> => {
    return await this.axios
      .put(`/api/icons/${id}`, body)
      .then((response: AxiosResponse) => response.data);
  };

  public useUpdateIcon = (
    options?: MutationOptions<void, UpdateMutationProps>
  ): MutationResult<void, UpdateMutationProps> => {
    return useMutation<void, AxiosError, UpdateMutationProps>(
      [QueryKeys.UpdateIcon],
      (props: UpdateMutationProps) => this.updateIcon(props.id, props.body),
      options
    );
  };
}

interface UpdateMutationProps {
  id: number;
  body: UpdateIconDto;
}
