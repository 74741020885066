import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionProps,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandableInfoDisplayProps extends AccordionProps {
  titleContent: string;
}

const ExpandableInfoDisplay: FC<ExpandableInfoDisplayProps> = ({
  titleContent,
  children,
  ...accordionProps
}: ExpandableInfoDisplayProps) => {
  return (
    <Accordion {...accordionProps}>
      <AccordionSummary
        className="bg-[#A7E2E8] flex-row-reverse gap-3"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="subtitle1">{titleContent}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ExpandableInfoDisplay;
