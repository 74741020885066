import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../reducers';
import JwtValidator from '../common/JwtValidator';

// Retrieves companyId from redux if there is selected company or from token if not. Always returns id
const useDetermineCompanyId = (): number | null => {
  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const { decodedToken } = useMemo(() => new JwtValidator(), []);
  if (companyIdRedux) {
    return companyIdRedux;
  } else if (decodedToken?.companyId) {
    return Number(decodedToken.companyId);
  } else {
    //should return null only if not auth or no token
    return null;
  }
};

export default useDetermineCompanyId;
