import { PeriodMode } from '../../model/Pickers/PeriodMode';
import {
  PeriodSelectionAction,
  PeriodSelectionActionType,
  FromDatePeriodAction,
  ToDatePeriodAction,
  AvgFromDatePeriodAction,
  AvgToDatePeriodAction,
  PeriodModePeriodAction,
} from './PeriodSelectionAction';

export const changeFromDate = (fromDate: Date | null): FromDatePeriodAction => {
  let date = fromDate === null ? new Date() : fromDate;
  return {
    fromDate: date,
    type: PeriodSelectionActionType.PERIOD_FROM_DATE_CHANGED,
  };
};

export const changeToDate = (toDate: Date | null): ToDatePeriodAction => {
  let date = toDate === null ? new Date() : toDate;
  return {
    toDate: date,
    type: PeriodSelectionActionType.PERIOD_TO_DATE_CHANGED,
  };
};

export const changeAvgFromDate = (avgFromDate: Date | null): AvgFromDatePeriodAction => {
  let date = avgFromDate === null ? new Date() : avgFromDate;
  return {
    avgFromDate: date,
    type: PeriodSelectionActionType.PERIOD_AVG_FROM_DATE_CHANGED,
  };
};

export const changeAvgToDate = (avgToDate: Date | null): AvgToDatePeriodAction => {
  let date = avgToDate === null ? new Date() : avgToDate;
  return {
    avgToDate: date,
    type: PeriodSelectionActionType.PERIOD_AVG_TO_DATE_CHANGED,
  };
};

export const changePeriodMode = (periodMode: PeriodMode | null): PeriodModePeriodAction => {
  let mode = periodMode === null ? PeriodMode.CUSTOM : periodMode;
  return {
    periodMode: mode,
    type: PeriodSelectionActionType.PERIOD_TIMESPAN_MODE_CHANGED,
  };
};
