import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import format from 'date-fns/format';

import { TimeSeriesData } from '../../model/TimeSeriesData/TimeSeriesData';
import { Readings } from '@thingslog/repositories';

export async function getSensorReadingsAsTimeSeriesData(
  deviceNumber: string,
  sensorIndex: number,
  startDate: string,
  endDate: string,
  every: number
): Promise<TimeSeriesData[]> {
  return await Axios.get(
    `/api/v2/devices/${deviceNumber}/${sensorIndex}/readings?fromDate=${startDate}&toDate=${endDate}&every=${every}`
  ).then((response: AxiosResponse) => {
    return convertSensorReadingsToTimeSeriesData(response.data);
  });
}

export async function getSensorReadingsAsTimeSeriesDataAvg(
  deviceNumber: string,
  sensorIndex: number,
  avgStartDate: string,
  avgEndDate: string,
  startDate: string,
  endDate: string,
  every: number
): Promise<TimeSeriesData[]> {
  // TODO: Already passing start/endDate as ISO string. Make a function to get
  //       the date and NOT the time from the ISO string, rather than doing this.
  let targetStartDate = new Date(startDate).toISOString().substring(0, 10);
  let targetEndDate = new Date(endDate).toISOString().substring(0, 10);
  return await Axios.get(
    `/api/v2/devices/${deviceNumber}/${sensorIndex}/readings/avg/?fromDate=${avgStartDate}&toDate=${avgEndDate}&targetFromDate=${targetStartDate}&targetToDate=${targetEndDate}&every=${every}`
  ).then((response: AxiosResponse) => {
    return convertSensorReadingsToTimeSeriesData(response.data.value);
  });
}

function convertSensorReadingsToTimeSeriesData(data: Readings[]): TimeSeriesData[] {
  const uniqueDates = new Set<string>();
  let timeSeriesData: TimeSeriesData[] = [];
  data.forEach((dataPoint: { date: string; reading: number }) => {
    const date = format(new Date(dataPoint.date), 'yyyy-MM-dd HH:mm');
    if (!uniqueDates.has(date)) {
      uniqueDates.add(date);
      const readingDataPoint: TimeSeriesData = { x: new Date(date), y: dataPoint.reading };
      timeSeriesData.push(readingDataPoint);
    }
  });
  return timeSeriesData;
}
