import React, { FC, useState, useMemo, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
  Switch,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColumnVisibilityModel } from '@thingslog/ui-components/src/DataGrid';
import { ExportFormats, AlarmDto } from '@thingslog/repositories';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import AlarmsTable from './AlarmsTable';
import RedirectToGraphDialog from './RedirectToGraphDialog';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { useParams } from 'react-router-dom';
import { alarmsQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useToast, useModal, GridRowParams } from '@thingslog/ui-components';
import { AxiosError } from 'axios';
import JwtValidator from '../../common/JwtValidator';
import useDetermineCompanyId from '../../hooks/useDetermineCompanyId';

const AlarmsExport: FC<AlarmsExportProps> = () => {
  const { toast } = useToast();
  const { modal, closeModal } = useModal();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1024px)');
  const navigate = useNavigate();

  const { deviceNumber, alarmType } = useParams();
  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);
  const toDateTz = useSelector((state: ReduxState) => state.period.toDateTz);
  const fromDateTz = useSelector((state: ReduxState) => state.period.fromDateTz);

  const { isAllowedUnlessSuperAdminWithoutSelectedCompany } = useMemo(() => new JwtValidator(), []);
  const { useAlarmsExportCSV, useAlarmsExportExcel, useAlarmsExportJSON } = useMemo(
    () => alarmsQueryClient,
    []
  );

  const companyId = useDetermineCompanyId();
  const [exportFormat, setExportFormat] = useState<ExportFormats>('Excel');
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState<boolean>(true);
  const [data, setData] = useState<AlarmDto[]>([]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel | undefined>({
    date: true,
    deviceNumber: true,
    deviceName: true,
    sensorIndex: true,
    sensorName: true,
    alarmType: true,
    alarmStatusType: true,
    description: true,
    linkToGraph: true,
  });

  useEffect(() => {
    setVisibilityModel({
      ...visibilityModel,
      linkToGraph: !isMobile,
    });
  }, [isMobile]);

  // Excel/CSV for download
  const { refetch: exportAlarmsCSV, isFetching: isDownloadingCSV } = useAlarmsExportCSV(
    fromDateTz,
    toDateTz,
    companyId,
    deviceNumber,
    alarmType,
    {
      enabled: false,
      onSuccess: (data: ArrayBuffer) => {
        let fileExtension = 'csv';
        const fileName = `AlarmsExport-${exportFormat}-${fromDateTz.toISOString()}-${toDateTz.toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      },
      onError: () => {
        toast({ message: t('alarms_export_error_alert_message'), type: 'error', duration: 5000 });
      },
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: exportAlarmsExcel, isFetching: isDownloadingExcel } = useAlarmsExportExcel(
    fromDateTz,
    toDateTz,
    companyId,
    deviceNumber,
    alarmType,
    {
      enabled: false,
      onSuccess: (data: ArrayBuffer) => {
        let fileExtension = 'xlsx';
        const fileName = `AlarmsExport-${exportFormat}-${fromDateTz.toISOString()}-${toDateTz.toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      },
      onError: () => {
        toast({ message: t('alarms_export_error_alert_message'), type: 'error', duration: 5000 });
      },
      refetchOnWindowFocus: false,
    }
  );

  // JSON format for table data
  const { refetch: refetchAlarmsJSON, isFetching } = useAlarmsExportJSON(
    fromDateTz,
    toDateTz,
    companyId,
    deviceNumber,
    undefined,
    alarmType,
    {
      enabled:
        isAllowedUnlessSuperAdminWithoutSelectedCompany(companyIdRedux) ||
        deviceNumber !== undefined,
      onSuccess: (data: AlarmDto[]) => {
        setData(data);
      },
      onError: (error: AxiosError) => {
        if (error.response?.data.errorCode === 'SUPER_ADMIN_ALARM_HISTORY') {
          toast({
            message: t('alarms_export_error_too_big_query'),
            type: 'error',
            duration: 10000,
          });
        } else {
          toast({ message: t('alarms_export_error_alert_message'), type: 'error', duration: 5000 });
        }
        setData([]);
      },
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      refetchInterval: isAutoRefreshOn ? 10 * 60 * 1000 : false,
    }
  );

  useEffect(() => {
    if (!isAllowedUnlessSuperAdminWithoutSelectedCompany(companyIdRedux) && !deviceNumber) {
      toast({
        message: t('alarms_export_select_device_or_company_alert_message'),
        type: 'warning',
        duration: 10000,
      });
    }
  }, [isFetching, deviceNumber]);

  const downloadHandler = (): void => {
    switch (exportFormat) {
      case 'Excel':
        exportAlarmsExcel();
        break;
      case 'CSV':
        exportAlarmsCSV();
        break;
      default:
        break;
    }
  };

  const toggleAutoRefreshHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsAutoRefreshOn(event.target.checked);
  };

  const onAlarmClick = (data: GridRowParams): void => {
    if (isMobile) {
      modal({
        title: `${t('device_number')}: ${data.row.deviceNumber}`,
        content: (
          <div className="overflow-y-auto">
            <RedirectToGraphDialog
              onConfirm={(): void => {
                closeModal();
                redirectToGraph(data.row.deviceNumber);
              }}
              onCancel={(): void => {
                closeModal();
              }}
            />
          </div>
        ),
      });
    }
  };

  const redirectToGraph = (deviceNumber: string): void => {
    navigate(`/app/graph/${deviceNumber}`);
    closeModal();
  };

  return (
    <Header>
      <TitleHeader
        className="mb-4"
        title={t('alarms_export_header')}
        deviceNumber={deviceNumber && selectedDevice && selectedDevice.number}
        deviceName={deviceNumber && selectedDevice && selectedDevice.name}
        customerInfo={deviceNumber && selectedDevice && selectedDevice.customerInfo}
      />
      <section className="flex justify-between flex-wrap">
        <div className="flex flex-wrap md:space-x-2 max-md:space-y-4">
          <PeriodPicker alwaysShowDatePickers />
          <Button
            className="max-md:w-1/2"
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<CachedOutlinedIcon />}
            onClick={(): void => {
              refetchAlarmsJSON();
            }}
            disabled={isFetching || isDownloadingCSV || isDownloadingExcel}
          >
            {t('alarms_export_refresh')}
          </Button>
          <Switch
            checked={isAutoRefreshOn}
            onChange={toggleAutoRefreshHandler}
            title={t('alarms_export_switch_title_label')}
            className="flex items-center"
            icon={<CachedOutlinedIcon className="text-black bg-[#00bcd4] rounded-full " />}
            checkedIcon={<CachedOutlinedIcon className="text-black bg-green-500 rounded-full " />}
          />
        </div>
        <div className="flex space-x-2 max-lg:mt-4 max-md:w-full">
          <FormControl className="max-md:w-1/2">
            <InputLabel>{t('alarms_export_format')}</InputLabel>
            <Select
              size="small"
              value={exportFormat}
              label={t('alarms_export_format')}
              onChange={(e: SelectChangeEvent<ExportFormats>): void => {
                setExportFormat(e.target.value as ExportFormats);
              }}
            >
              <MenuItem value={'CSV'}>CSV</MenuItem>
              <MenuItem value={'Excel'}>Excel</MenuItem>
            </Select>
          </FormControl>
          <Button
            className="max-md:w-1/2"
            variant="contained"
            color="primary"
            disableElevation
            startIcon={
              isFetching || isDownloadingCSV || isDownloadingExcel ? (
                <CachedOutlinedIcon />
              ) : (
                <FileDownloadIcon />
              )
            }
            onClick={(): void => {
              downloadHandler();
            }}
            disabled={isFetching || isDownloadingCSV || isDownloadingExcel}
          >
            {t('alarms_export_download')}
          </Button>
        </div>
      </section>
      <section>
        <div className="w-full my-5">
          {isFetching ? (
            <CircularProgress />
          ) : (
            <AlarmsTable
              rows={data}
              density="compact"
              getRowId={(row: AlarmDto): string => row.deviceNumber + row.sensorIndex + row.date}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel): void =>
                setVisibilityModel(newModel)
              }
              onRowClick={onAlarmClick}
              onGraphIconClickCallback={redirectToGraph}
            />
          )}
        </div>
      </section>
    </Header>
  );
};

interface AlarmsExportProps {}

export default AlarmsExport;
