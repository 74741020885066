import React, { FC, ReactElement, ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, useForm } from 'react-hook-form';
import { Button, Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material';
import ColorsPicker from './components/ColorsPicker';
import { DeviceGroupDto, MutateDeviceGroup } from '@thingslog/repositories';

const AddGroupModal: FC<AddGroupModalProps> = ({
  companyId,
  deviceGroups,
  handleOnCreateDeviceGroup,
}: AddGroupModalProps) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<MutateDeviceGroup>({
    defaultValues: {
      companyId: companyId,
      deviceGroupColor: '#f44336',
      deviceGroupName: '',
      parentDeviceGroupId: null,
    },
  });

  return (
    <form className="flex flex-col mt-2 gap-5" onSubmit={handleSubmit(handleOnCreateDeviceGroup)}>
      <TextField
        {...register('deviceGroupName', {
          required: {
            value: true,
            message: t('device_groups_sensors_add_modal_device_group_name_required'),
          },
        })}
        fullWidth
        label={t('device_groups_group_name')}
        size="small"
      />
      <Controller
        control={control}
        name="parentDeviceGroupId"
        render={({
          field,
        }: UseControllerReturn<MutateDeviceGroup, 'parentDeviceGroupId'>): ReactElement => {
          const { value, onChange } = field;
          return (
            <Autocomplete
              options={deviceGroups}
              getOptionLabel={(deviceGroup: DeviceGroupDto): string => deviceGroup.deviceGroupName}
              value={
                deviceGroups.find(
                  (deviceGroup: DeviceGroupDto) => deviceGroup.deviceGroupId === value
                ) ?? null
              }
              onChange={(event: SyntheticEvent, newValue: DeviceGroupDto | null): void => {
                onChange(newValue ? newValue.deviceGroupId : null);
              }}
              renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                <TextField
                  {...params}
                  label={t('device_groups_parent_device_group')}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          );
        }}
      />
      <div className="flex justify-center items-center">
        <Controller
          name="deviceGroupColor"
          control={control}
          render={({
            field,
          }: UseControllerReturn<MutateDeviceGroup, 'deviceGroupColor'>): ReactElement => {
            const { onChange, value } = field;
            return (
              <ColorsPicker
                isColorPickerOpen={isColorPickerOpen}
                setIsColorPickerOpen={setIsColorPickerOpen}
                color={value}
                onColorChange={(color: string): void => onChange(color)}
              />
            );
          }}
        />
      </div>

      {!!errors.deviceGroupName && (
        <span className="m-auto text-red-500 font-semibold">
          <span>{errors.deviceGroupName.message}</span>
        </span>
      )}

      {!isColorPickerOpen && (
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          disableElevation
        >
          {t('device_groups_btn_add')}
        </Button>
      )}
    </form>
  );
};

interface AddGroupModalProps {
  companyId: number;
  deviceGroups: DeviceGroupDto[];
  handleOnCreateDeviceGroup: (deviceGroup: MutateDeviceGroup) => void;
}

export default AddGroupModal;
