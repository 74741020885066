import React, { useState, ReactNode, FC, useMemo } from 'react';
import { Chip, Tooltip, IconButton } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridValueFormatterParams,
  useModal,
  useToast,
} from '@thingslog/ui-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  GridRenderCellParams,
  GridValueGetterParams,
  GridColumnVisibilityModel,
} from '@thingslog/ui-components/src/DataGrid';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { LicenseDto, CompanyLicenseDto } from '@thingslog/repositories';
import LicenseValidator from '../../../common/LicenseValidator';
import ConfirmActivityStatusChangeModal from './ConfirmActivityStatusChangeModal';
import { licenseQueryClient } from '../../../clients/ReactQueryClients/ReactQueryClients';
import JwtValidator from '../../../common/JwtValidator';

const LicensesTable: FC<LicensesTableProps> = ({
  pageSize,
  autoHeight,
  rowsPerPageOptions,
  onPageSizeChange,
  loadLicenses,
  ...props
}: LicensesTableProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { modal } = useModal();
  const { useChangeLicenseActivity } = useMemo(() => licenseQueryClient, []);
  const { hasRole } = useMemo(() => new JwtValidator(), []);
  const [size, setSize] = useState(10);
  const onPageSizeChangeHandler = (pageSize: number): void => setSize(pageSize);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    companyName: true,
    type: true,
    maxCount: true,
    validityPeriodFrom: true,
    validityPeriodTo: true,
    licenseReadingsFirstDate: true,
    licenseReadingsLastDate: true,
    isActive: true,
    isValid: true,
    actions: hasRole('ROLE_SUPER_ADMIN'),
  });

  const { mutate: updateLicenseActivityStatus } = useChangeLicenseActivity({
    onSuccess: () => {
      toast({ message: t('profile_page_deactivate_license_success_message'), type: 'success' });
      loadLicenses();
    },
    onError: () => {
      toast({ message: t('profile_page_deactivate_license_error_message'), type: 'error' });
    },
  });

  const changeLicenseStatusHandler = (license: LicenseDto, status: 'ACTIVE' | 'INACTIVE'): void => {
    if (
      LicenseValidator.isCompanyNonPartialLicense(license) &&
      (license as CompanyLicenseDto).licenseId
    ) {
      modal({
        title:
          status === 'INACTIVE'
            ? t('profile_page_deactivate_modal_title')
            : t('profile_page_activate_modal_title'),
        content: (
          <ConfirmActivityStatusChangeModal
            status={status}
            license={license}
            licenseId={(license as CompanyLicenseDto).licenseId}
            updateLicenseActivityStatus={updateLicenseActivityStatus}
          />
        ),
      });
    }
  };

  return (
    <DataGrid
      pageSize={pageSize ? pageSize : size}
      getRowId={(row: CompanyLicenseDto): string => row.licenseId + row.companyName + row['@type']}
      rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50]}
      autoHeight={autoHeight ? autoHeight : true}
      onPageSizeChange={onPageSizeChange ? onPageSizeChange : onPageSizeChangeHandler}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(model: GridColumnVisibilityModel): void => {
        setColumnVisibilityModel({
          ...model,
          actions: hasRole('ROLE_SUPER_ADMIN') && model.actions,
        });
      }}
      columns={[
        {
          field: 'companyName',
          headerName: t('license_table_company_name'),
          type: 'text',
          flex: 0.75,
          minWidth: 140,
        },
        {
          field: '@type',
          headerName: t('license_table_license_type'),
          flex: 0.75,
          minWidth: 110,
        },
        {
          field: 'maxCount',
          align: 'left',
          headerName: t('license_table_max_count'),
          headerAlign: 'left',
          type: 'number',
          flex: 0.75,
          minWidth: 150,
          valueFormatter: (params: GridValueFormatterParams<string>) => params.value || '--',
        },
        {
          field: 'validityPeriodFrom',
          headerName: t('license_table_valid_from'),
          type: 'date',
          flex: 0.75,
          minWidth: 140,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '',
        },
        {
          field: 'validityPeriodTo',
          headerName: t('license_table_valid_to'),
          type: 'date',
          flex: 0.75,
          minWidth: 140,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '',
        },
        {
          field: 'licenseReadingsFirstDate',
          headerName: t('license_table_first_reading_date'),
          type: 'date',
          flex: 0.75,
          minWidth: 140,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '',
        },
        {
          field: 'licenseReadingsLastDate',
          headerName: t('license_table_last_reading_date'),
          type: 'date',
          flex: 0.75,
          minWidth: 140,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '',
        },
        {
          field: 'isActive',
          headerName: t('license_table_active'),
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams): string | null => {
            if (params.value !== null) {
              return params.value === true ? t('license_table_yes') : t('license_table_no');
            } else {
              return null;
            }
          },
          minWidth: 90,
          renderCell: (params: GridRenderCellParams<string | null>): ReactNode => {
            if (params.value !== null) {
              return (
                <Chip
                  title={params.value}
                  className={clsx(
                    params.value === t('license_table_yes') && 'bg-green-400 text-white ',
                    params.value === t('license_table_no') && 'bg-gray-500 text-white'
                  )}
                  label={params.value}
                  variant="outlined"
                  size="medium"
                />
              );
            } else {
              return <></>;
            }
          },
        },
        {
          field: 'isValid',
          headerName: t('license_table_valid'),
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams): string | null => {
            if (params.value !== null) {
              return params.value === true ? t('license_table_yes') : t('license_table_no');
            } else {
              return null;
            }
          },
          minWidth: 90,
          renderCell: (params: GridRenderCellParams<string | null>): ReactNode => {
            if (params.value !== null) {
              return (
                <Chip
                  title={params.value}
                  className={clsx(
                    params.value === t('license_table_yes') && 'bg-green-400 text-white ',
                    params.value === t('license_table_no') && 'bg-red-500 text-white'
                  )}
                  label={params.value}
                  variant="outlined"
                  size="medium"
                />
              );
            } else {
              return <></>;
            }
          },
        },
        {
          field: 'actions',
          headerName: t('license_table_actions'),
          flex: 0.5,
          minWidth: 90,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<string | null>): ReactNode => {
            let license = params.row as LicenseDto;
            return (
              <div className="flex justify-center items-center">
                {license.isActive ? (
                  <Tooltip
                    title={<span>{t('license_deactivate_license_button')}</span>}
                    arrow
                    placement="top"
                  >
                    <IconButton
                      aria-label={t('license_deactivate_license_button')}
                      size="medium"
                      onClick={(): void => changeLicenseStatusHandler(params.row, 'INACTIVE')}
                    >
                      <HighlightOffIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={<span>{t('license_activate_license_button')}</span>}
                    arrow
                    placement="top"
                  >
                    <IconButton
                      aria-label={t('license_activate_license_button')}
                      size="medium"
                      onClick={(): void => changeLicenseStatusHandler(params.row, 'ACTIVE')}
                    >
                      <CheckCircleOutlineIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            );
          },
        },
      ]}
      {...props}
    />
  );
};

interface LicensesTableProps extends Omit<DataGridProps, 'columns'> {
  loadLicenses: () => void;
}

export default LicensesTable;
