import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import {
  AUTH_USER,
  AUTH_ERROR,
  LANGUAGE_CHANGED,
  SIGN_OUT,
  AUTH_RESTRICT_USER,
  SET_COMPANY_LICENSES,
  SET_LICENSES_ACCESS,
} from '../actions/types';
import { LicenseDto } from '@thingslog/repositories';

export default function authReducer(state: any = {}, action: any): AuthState {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        isRestricted: undefined,
        error: null,
        authenticated: true,
        signedup: false,
        username: action.payload.username,
        roles: action.payload.roles,
        companyName: action.payload.companyName,
        email: action.payload.email,
        language: action.payload.language,
        isDashboardEnabled: action.payload.isDashboardEnabled,
        companyLicenses: action.payload.companyLicenses,
      };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case LANGUAGE_CHANGED:
      return { ...state, language: action.payload };
    case SIGN_OUT:
      return {};
    case AUTH_RESTRICT_USER:
      return { ...state, isRestricted: true };
    case SET_COMPANY_LICENSES:
      return { ...state, licenses: action.payload };
    case SET_LICENSES_ACCESS:
      return { ...state, hasLicensesAccess: action.payload };
    default:
      return state;
  }
}

export interface AuthState {
  authenticated?: boolean;
  isRestricted?: boolean;
  companyName?: string;
  error?: string;
  email?: string;
  language?: string;
  roles?: JwtRole[];
  username?: string;
  isDashboardEnabled?: boolean;
  licenses?: LicenseDto[];
  hasLicensesAccess?: boolean;
}
