import { ModbusReturnedDataType } from '@thingslog/repositories';

export const modbusReturnedDataTypeOptions: ModbusReturnedDataType[] = [
  'RAW',
  'BIT',
  'HEX',
  'FLOAT',
  'UINT8',
  'UINT16',
  'UINT32',
  'INT8',
  'INT16',
  'INT32',
];
