import {
  bgBG as bgMuiCoreLocale,
  ruRU as ruMuiCoreLocale,
  deDE as deMuiCoreLocale,
  enUS as enMuiCoreLocale,
  elGR as grMuiCoreLocale,
  esES as esMuiCoreLocale,
  Localization,
} from '@mui/material/locale';
import { LabelDisplayedRowsArgs } from '@mui/material';

interface MuiCoreLocalization {
  bg: Localization;
  ru: Localization;
  de: Localization;
  en: Localization;
  gr: Localization;
  es: Localization;
}

const MuiCoreLocale: MuiCoreLocalization = {
  bg: {
    ...bgMuiCoreLocale,
    components: {
      ...bgMuiCoreLocale.components,
      MuiTablePagination: {
        ...bgMuiCoreLocale.components?.MuiTablePagination,
        defaultProps: {
          ...bgMuiCoreLocale.components?.MuiTablePagination?.defaultProps,
          labelDisplayedRows({ from, to, count }: LabelDisplayedRowsArgs) {
            return `${from}–${to} от ${count !== -1 ? count : `повече от ${to}`}`;
          },
        },
      },
    },
  },
  ru: ruMuiCoreLocale,
  de: deMuiCoreLocale,
  en: enMuiCoreLocale,
  gr: grMuiCoreLocale,
  es: esMuiCoreLocale,
};

export default MuiCoreLocale;
