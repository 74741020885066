import Axios from './clients/Axios/Axios';

function getMultiDeviceFlows(
  deviceNumbers,
  sensorIndex,
  startDate,
  endDate,
  multiply,
  every,
  cb,
  ecb
) {
  return Axios.get(`/api/devices/${deviceNumbers}/${sensorIndex}/flows/`, {
    params: {
      fromDate: startDate,
      toDate: endDate,
      multiply: multiply,
      every: every,
    },
  })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getMultiDeviceFlowsAvg(
  deviceNumbers,
  sensorIndex,
  avgStartDate,
  avgEndDate,
  startDate,
  endDate,
  multiply,
  every,
  cb,
  ecb
) {
  let targetStartDate = new Date(startDate).toISOString().substring(0, 10);
  let targetEndDate = new Date(endDate).toISOString().substring(0, 10);
  return Axios.get(
    `/api/devices/[${deviceNumbers}]/${sensorIndex}/flows/avg/?fromDate=${avgStartDate}&toDate=${avgEndDate}&targetFromDate=${targetStartDate}&targetToDate=${targetEndDate}&multiply=${multiply}&every=${every}`
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceFlowsClient = {
  getMultiDeviceFlows,
  getMultiDeviceFlowsAvg,
};

export default DeviceFlowsClient;
