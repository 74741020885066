export interface LineChartColors {
  flow: string;
  average: string;
  prediction: string;
}

export enum LineColors {
  RED,
  ORANGE,
  YELLOW,
  BLUE,
  GREEN,
  PURPLE,
  LIME,
  BROWN,
  OLIVE,
  BLUEGRAY,
  TEAL,
  INDIGO,
  PINK,
  MAROON,
  LAVENDER,
  ROSEWOOD,
  GRAY,
  CYAN,
  SPRINGGREEN,
  CORAL,
}
