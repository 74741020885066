import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import DeviceSignal from '../../../../apps/management/src/model/API/DeviceSignal/DeviceSignal';
import SignalStatus from '../../../../apps/management/src/model/API/DeviceSignal/SignalStatus';

export interface DevicesSignalsQueryClient {
  getDevicesSignal: (
    fromLevel: number,
    toLevel: number,
    companyId: number | null
  ) => Promise<DeviceSignal[]>;
  useDevicesSignal: (
    companyId: number | null,
    fromLevel?: number,
    toLevel?: number,
    options?: QueryOptions<DeviceSignal[]>
  ) => UseQueryResult<DeviceSignal[], AxiosError>;
  getSignalStatus: (deviceNumber: string, fromDate: Date, toDate: Date) => Promise<SignalStatus[]>;
  useSignalStatus: (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date,
    options?: QueryOptions<SignalStatus[]>
  ) => UseQueryResult<SignalStatus[], AxiosError>;
}

export function createDevicesSignalQueryClient(axios: AxiosInstance): DevicesSignalsQueryClient {
  return new DevicesSignalsQueryClientImp(axios);
}

class DevicesSignalsQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getDevicesSignal = async (
    fromLevel: number,
    toLevel: number,
    companyId: number | null
  ): Promise<DeviceSignal[]> => {
    return this.axios
      .get('/api/devices/signal/', {
        params: { fromLevel: fromLevel, toLevel: toLevel, companyId: companyId },
      })
      .then((response: AxiosResponse<DeviceSignal[]>) => {
        return response.data;
      });
  };

  public useDevicesSignal = (
    companyId: number | null,
    fromLevel: number = 0,
    toLevel: number = 100,
    options?: QueryOptions<DeviceSignal[]>
  ): UseQueryResult<DeviceSignal[], AxiosError> => {
    return useQuery<DeviceSignal[], AxiosError>(
      [QueryKeys.GetDevicesSignal, fromLevel, toLevel, companyId],
      () => this.getDevicesSignal(fromLevel, toLevel, companyId),
      options
    );
  };

  public getSignalStatus = async (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date
  ): Promise<SignalStatus[]> => {
    return this.axios
      .get(`/api/devices/${deviceNumber}/signal`, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      })
      .then((response: AxiosResponse<SignalStatus[]>) => {
        return response.data;
      });
  };

  public useSignalStatus = (
    deviceNumber: string,
    fromDate: Date,
    toDate: Date,
    options?: QueryOptions<SignalStatus[]>
  ): UseQueryResult<SignalStatus[], AxiosError> => {
    return useQuery<SignalStatus[], AxiosError>(
      [QueryKeys.GetSignalStatus, deviceNumber, fromDate, toDate],
      () => this.getSignalStatus(deviceNumber, fromDate, toDate),
      options
    );
  };
}
