import React, { FC, useEffect, useMemo, useState, useCallback, MouseEvent } from 'react';
import { Button, LinearProgress, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { EverySelector, useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Device, DeviceConfig, DeviceSensorDto, PortsConfig } from '@thingslog/repositories';
import DeviceSensorGraphData from './model/DeviceSensorGraphData';
import { LineColors } from './model/LineChartColors';
import { lineColors } from './GraphColors';
import { GRAPH_SENSORS_LIMIT } from './GraphConstants';

const AddDeviceGroupToGraphModal: FC<AddDeviceGroupToGraphModalProps> = ({
  deviceGroupName,
  deviceSensorsFromParentDeviceGroup,
  devices,
  deviceSensorGraphData,
  onAddDeviceGroupToGraphData,
  onModalOpened,
  isLoadingChangeCallback,
  deviceConfigsChangeCallback,
  portConfigsChangeCallback,
}: AddDeviceGroupToGraphModalProps) => {
  const [everySelectorMode, setEverySelectorMode] = useState<EverySelectorMode>('perDevice');
  const [deviceSensorGraphDataNewEntries, setDeviceSensorGraphDataNewEntries] = useState<
    DeviceSensorGraphData[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceConfigs, setDeviceConfigs] = useState<Record<string, DeviceConfig> | null>(null);
  const [portConfigs, setPortConfigs] = useState<Record<string, PortsConfig> | null>(null);

  const { t } = useTranslation();
  const { closeModal } = useModal();

  const isGraphSensorLimitReached = useMemo(() => {
    return (
      deviceSensorGraphData.length + deviceSensorGraphDataNewEntries.length > GRAPH_SENSORS_LIMIT
    );
  }, [deviceSensorGraphData, deviceSensorGraphDataNewEntries]);

  const everySelectorModeBasedEntries = useMemo(() => {
    return everySelectorMode === 'perSensor'
      ? deviceSensorGraphDataNewEntries
      : _.uniqBy(deviceSensorGraphDataNewEntries, 'deviceNumber');
  }, [everySelectorMode, deviceSensorGraphDataNewEntries]);

  const handleOnEveryChange = useCallback(
    (every: number, entryDeviceNumber: string, entryIndex: number) => {
      const newEntriesWithUpdatedEvery = [...deviceSensorGraphDataNewEntries];
      if (everySelectorMode === 'perSensor') {
        newEntriesWithUpdatedEvery[entryIndex].every = every;
      } else if (everySelectorMode === 'perDevice') {
        newEntriesWithUpdatedEvery.forEach((updatedEntry: DeviceSensorGraphData) => {
          if (updatedEntry.deviceNumber === entryDeviceNumber) {
            updatedEntry.every = every;
          }
        });
      }
      setDeviceSensorGraphDataNewEntries(newEntriesWithUpdatedEvery);
    },
    [everySelectorMode, deviceSensorGraphDataNewEntries]
  );

  useEffect(() => {
    onModalOpened();
    isLoadingChangeCallback && isLoadingChangeCallback(setIsLoading);
    deviceConfigsChangeCallback && deviceConfigsChangeCallback(setDeviceConfigs);
    portConfigsChangeCallback && portConfigsChangeCallback(setPortConfigs);
  }, []);

  useEffect(() => {
    if (deviceConfigs === null || portConfigs === null) return;
    const deviceSensorGraphDataNewEntries: DeviceSensorGraphData[] = [];
    let indexInGraphData = deviceSensorGraphData.length;

    deviceSensorsFromParentDeviceGroup.map((deviceSensor: DeviceSensorDto) => {
      const { deviceNumber, sensorIndex } = deviceSensor;

      const deviceConfig = deviceConfigs[deviceNumber];
      const portsConfig = portConfigs[deviceNumber];
      const device = devices.find((device: Device) => device.number === deviceNumber);

      if (deviceConfig && portsConfig && device) {
        const port = portsConfig[sensorIndex];
        if (port.enabled) {
          const isSensorAlreadyAdded = deviceSensorGraphData.some(
            (deviceSensorGraphData: DeviceSensorGraphData) =>
              deviceSensorGraphData.deviceNumber === deviceNumber &&
              deviceSensorGraphData.sensorIndex === sensorIndex
          );

          if (!isSensorAlreadyAdded) {
            const color = LineColors[indexInGraphData % Object.keys(lineColors).length];

            const currentDeviceSensorData: DeviceSensorGraphData = {
              deviceNumber: deviceNumber,
              sensorIndex: sensorIndex,
              device: device,
              port: port,
              deviceConfig: deviceConfig,
              graphLineColor: lineColors[LineColors[color]],
              flowData: [],
              averageFlowData: [],
              predictionData: [],
              every: 1,
            };
            deviceSensorGraphDataNewEntries.push(currentDeviceSensorData);
            indexInGraphData++;
          }
        }
      }
    });

    setDeviceSensorGraphDataNewEntries(deviceSensorGraphDataNewEntries);
  }, [
    deviceConfigs,
    portConfigs,
    devices,
    deviceSensorsFromParentDeviceGroup,
    deviceSensorGraphData,
  ]);

  return (
    <div className="w-[450px] max-md:w-full">
      {isLoading ? (
        <LinearProgress className="my-6" />
      ) : (
        <>
          <div className="flex justify-between space-x-4 items-end mb-3 mt-2">
            <div className="flex-col">
              <div>
                {t('new_graph_add_device_group_modal_device_group')}
                <span className="font-semibold">{deviceGroupName}</span>
              </div>
              <div>
                {t('new_graph_add_device_group_modal_sensors_to_add')}
                <span className="font-semibold">{deviceSensorGraphDataNewEntries.length}</span>
              </div>
            </div>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={everySelectorMode}
              exclusive
              onChange={(event: MouseEvent<HTMLElement>, value: EverySelectorMode): void => {
                if (value !== null) {
                  setEverySelectorMode(value);
                }
              }}
            >
              <ToggleButton className="whitespace-nowrap" value="perDevice">
                {t('new_graph_add_device_group_modal_every_selector_mode_per_device')}
              </ToggleButton>
              <ToggleButton className="whitespace-nowrap" value="perSensor">
                {t('new_graph_add_device_group_modal_every_selector_mode_per_sensor')}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <Divider />

          <div className="grid grid-cols-2 gap-y-5 gap-x-2 py-3 max-h-[360px] overflow-y-auto bg-slate-50">
            {everySelectorModeBasedEntries.map((entry: DeviceSensorGraphData, index: number) => (
              <div
                key={index}
                className="flex flex-col justify-between font-semibold p-2 bg-white border border-solid border-slate-200 rounded"
              >
                <span>
                  {entry.device.name ? entry.device.name : entry.deviceNumber}
                  {everySelectorMode === 'perSensor' && ` - ${entry.port.sensor.name}`}
                </span>
                <div className="mt-3">
                  <EverySelector
                    formControlProps={{ fullWidth: true }}
                    confEvery={entry.deviceConfig.every}
                    confPeriod={entry.deviceConfig.recordPeriod}
                    every={entry.every!}
                    onEveryChange={(value: number): void => {
                      handleOnEveryChange(value, entry.deviceNumber, index);
                    }}
                    translation={{
                      day: t('day'),
                      days: t('days'),
                      hour: t('hour'),
                      hours: t('hours'),
                      minute: t('minute'),
                      minutes: t('minutes'),
                      second: t('second'),
                      seconds: t('seconds'),
                      label: t('every'),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>

          <Divider />
        </>
      )}

      {isGraphSensorLimitReached && (
        <div className="mt-2 text-red-700">
          {t('new_graph_add_device_group_modal_sensor_limit_message', {
            graphSensorsLimit: GRAPH_SENSORS_LIMIT,
          })}
        </div>
      )}
      <div className="flex justify-end gap-4 pt-3">
        <Button
          className="rounded-full"
          disabled={isGraphSensorLimitReached || isLoading}
          variant="outlined"
          color="success"
          onClick={(): void => {
            onAddDeviceGroupToGraphData(deviceSensorGraphDataNewEntries);
            closeModal();
          }}
        >
          {t('new_graph_add_device_group_modal_confirm_button')}
        </Button>
        <Button className="rounded-full" variant="outlined" color="error" onClick={closeModal}>
          {t('new_graph_add_device_group_modal_cancel_button')}
        </Button>
      </div>
    </div>
  );
};

export default AddDeviceGroupToGraphModal;

interface AddDeviceGroupToGraphModalProps {
  deviceGroupName: string;
  deviceSensorsFromParentDeviceGroup: DeviceSensorDto[];
  devices: Device[];
  deviceSensorGraphData: DeviceSensorGraphData[];
  onAddDeviceGroupToGraphData: (deviceGroupSensors: DeviceSensorGraphData[]) => void;
  onModalOpened: () => void;
  isLoadingChangeCallback?: (isLoadingUpdater: (isLoading: boolean) => void) => void;
  deviceConfigsChangeCallback?: (
    deviceConfigsUpdater: (deviceConfigs: Record<string, DeviceConfig> | null) => void
  ) => void;
  portConfigsChangeCallback?: (
    portConfigsUpdater: (portConfigs: Record<string, PortsConfig> | null) => void
  ) => void;
}

type EverySelectorMode = 'perDevice' | 'perSensor';
