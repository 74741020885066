import { Theme } from '@mui/material';

import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: 0,
    },
    chartContainer: {
      marginTop: 20,
    },
    graphWrapper: {
      position: 'relative',
      margin: 'auto',
      width: 'auto',
    },
    readingsTableContainer: {
      marginTop: 20,
    },
    barChartButton: {
      marginRight: 10,
    },
    verticalDivider: {
      borderLeft: '2px solid black',
      height: 25,
      marginTop: 5,
      marginBottom: 5,
    },
    statusContainer: {
      minHeight: 250,
    },
  });
