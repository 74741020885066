export enum DeviceModel {
  LPMDL_1101 = 'LPMDL_1101',
  LPMDL_1102 = 'LPMDL_1102',
  LPMDL_1103 = 'LPMDL_1103',
  LPMDL_1104 = 'LPMDL_1104',
  LPMDL_1105 = 'LPMDL_1105',
  LPMDL_1106 = 'LPMDL_1106',
  BL_100_L = 'BL_100_L',
  W1 = 'W1',
  ZENNER_MICRO = 'ZENNER_MICRO',
  ZENNER_EDC = 'ZENNER_EDC',
  LPMDL_1103_OPENLORA = 'LPMDL_1103_OPENLORA',
  INNOTAS_WATERMETER = 'INNOTAS_WATERMETER',
  INNOTAS_HCA = 'INNOTAS_HCA',
  MANUAL_WATER_METER = 'MANUAL_WATER_METER',
  MANUAL_ENERGY_METER = 'MANUAL_ENERGY_METER',
  MANUAL_HEAT_METER = 'MANUAL_HEAT_METER',
  MANUAL_GAS_METER = 'MANUAL_GAS_METER',
  TLCTL_21A4S = 'TLCTL_21A4S',
  FINECO_EM735 = 'FINECO_EM735',
  FINECO_EM4374 = 'FINECO_EM4374',
}
