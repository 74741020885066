import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import { FormControl, Checkbox, Button } from '@mui/material';
import { ControlLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface MultipleSelectTableOption<T> {
  value: T;
  label: string;
}

interface TableSelectProps<T> {
  title: string;
  componentMaxHeight?: number;
  options: MultipleSelectTableOption<T>[];
  selectedOptions: T[];
  setSelectedOptions: (selectedOptions: T[]) => void;
}

export const MultipleSelectTable: FC<TableSelectProps<number | string>> = <T extends unknown>({
  title,
  componentMaxHeight,
  options,
  selectedOptions,
  setSelectedOptions,
}: TableSelectProps<T>) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<Record<string, boolean>>({});

  useEffect(() => {
    let tempItems: Record<string, boolean> = {};

    options.map((option: MultipleSelectTableOption<T>) => {
      Object.assign(tempItems, { [String(option.value)]: false });
    });
    selectedOptions.map((selectedOption: T) => {
      Object.assign(tempItems, { [String(selectedOption)]: true });
    });

    setItems(tempItems);
  }, [options, selectedOptions]);

  return (
    <section className="border border-solid border-gray-300 rounded-md m-auto p-1">
      <FormControl className="min-w-full">
        <div className="flex justify-between flex-wrap px-2 py-1">
          <div className="text-lg font-bold max-md:w-full max-md:text-center">{title}</div>
          <ControlLabel className="max-md:flex max-md:justify-center max-md:w-full">
            <Button
              onClick={(): void => {
                const allOptions: T[] = options.map(
                  (option: MultipleSelectTableOption<T>) => option.value
                );
                setSelectedOptions(allOptions);
              }}
            >
              {t<string>('select_all')}
            </Button>
            <Button
              onClick={(): void => {
                setSelectedOptions([]);
              }}
            >
              {t<string>('deselect_all')}
            </Button>
          </ControlLabel>
        </div>
        <div
          style={{ maxHeight: !!componentMaxHeight ? componentMaxHeight : 'none' }}
          className={`overflow-y-auto grid sm:grid-cols-3 grid-cols-1 gap-1`}
        >
          {options.map((option: MultipleSelectTableOption<T>) => {
            let isSelected: boolean = items[String(option.value)] || false;

            return (
              <div>
                <ControlLabel
                  key={option.value}
                  className="bg-gray-100 text-left line-clamp-1 max-md:w-full"
                >
                  <Checkbox
                    checked={isSelected}
                    value={option.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      {
                        let copySelectedOptions: T[] = [...selectedOptions];

                        event.target.checked
                          ? copySelectedOptions.push(option.value)
                          : (copySelectedOptions = copySelectedOptions.filter((option: T) => {
                              return option != event.target.value;
                            }));
                        setSelectedOptions(copySelectedOptions);
                      }
                    }}
                  />
                  {option.label}
                </ControlLabel>
              </div>
            );
          })}
        </div>
      </FormControl>
    </section>
  );
};

export default MultipleSelectTable;
