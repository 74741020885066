import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import { Button, Paper, Typography, Alert, Avatar, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from '../../components/Wrapper/Wrapper';
import { global_env } from '../../App';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, Link } from 'react-router-dom';
import * as authActionsCreators from '../../state_management/actions/AuthActionCreator';
import { bindActionCreators } from 'redux';
import { ReduxState } from '../../reducers';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/LockOutlined';
import { setSolutionSettings } from '../../actions';
import { ReCaptchaV3 } from './ReCaptchaV3';
import { useForm } from 'react-hook-form';

const Signin: FC<SigninProps> = (props: SigninProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginUser } = bindActionCreators(authActionsCreators, dispatch);
  const authenticated = useSelector((state: ReduxState) => state.auth.authenticated);
  const error = useSelector((state: ReduxState) => state.auth.error);
  let recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [refreshReCaptchaHandler, setRefreshReCaptchaHandler] = useState<
    (() => Promise<void>) | null
  >(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginProps>();

  useEffect(() => {
    dispatch(setSolutionSettings({ data: null }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated]);

  const handleRefreshReCaptchaCallback = useCallback(
    (handleReCaptchaVerify: () => Promise<void>) => {
      setRefreshReCaptchaHandler(() => handleReCaptchaVerify);
    },
    []
  );

  const submitForm = ({ username, password }: LoginProps): void => {
    loginUser(username, password, recaptcha);

    if (global_env.recaptchaV3) {
      refreshReCaptchaHandler && refreshReCaptchaHandler();
    } else {
      recaptchaRef.current?.reset();
    }
  };

  return (
    <div className="bg-primary-dark flex justify-center items-center h-[100vh]">
      <Wrapper variant="small">
        <Paper elevation={0} className="px-6 py-9 flex flex-col justify-center items-center gap-2">
          <Avatar className="bg-primary-main">
            <LockIcon />
          </Avatar>

          <Typography variant="h5">Sign In</Typography>

          <form className="flex flex-col mt-7" onSubmit={handleSubmit(submitForm)}>
            <TextField
              {...register('username', { required: true })}
              label="Username"
              size="small"
              fullWidth
            />

            <TextField
              className="mt-5"
              {...register('password', { required: true })}
              label="Password"
              type="password"
              size="small"
              fullWidth
            />

            {global_env.recaptchaRequired &&
              (global_env.recaptchaV3 ? (
                <ReCaptchaV3
                  className="mt-2 text-left"
                  action="login"
                  refreshReCaptchaCallback={handleRefreshReCaptchaCallback}
                  onReCaptchaExecute={setRecaptcha}
                />
              ) : (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={global_env.recaptcha}
                  onExpired={(): void => {
                    setRecaptcha(null);
                  }}
                  onChange={(token: string | null): void => {
                    setRecaptcha(token);
                  }}
                />
              ))}

            <Button
              className="mt-5"
              fullWidth
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              disableElevation
            >
              Login
            </Button>

            <Link className="mt-4 text-right" to={`/app/ResetPassword`}>
              {t('login_form_forgot_password')}
            </Link>
          </form>
        </Paper>
        {error && (
          <Alert severity="error">
            <strong>Error: </strong>
            {error}
          </Alert>
        )}
      </Wrapper>
    </div>
  );
};

interface SigninProps {}

interface LoginProps {
  username: string;
  password: string;
}

export default Signin;
