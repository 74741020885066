import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import {
  LicenseDto,
  LicenseRequest,
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { DeviceLimits } from '@thingslog/repositories/src/license/DeviceLimits';

export interface LicenseQueryClient {
  getLicenses: (
    companyId: number | null,
    isActive?: boolean,
    isValid?: boolean
  ) => Promise<LicenseDto[]>;
  useLicenses: (
    companyId: number | null,
    isActive?: boolean,
    isValid?: boolean,
    options?: QueryOptions<LicenseDto[]>
  ) => QueryResult<LicenseDto[]>;
  getLicense: (licenseId: number) => Promise<LicenseDto>;
  useLicense: (licenseId: number, options?: QueryOptions<LicenseDto>) => QueryResult<LicenseDto>;
  createLicense: (body: LicenseRequest) => Promise<void>;
  useCreateLicense: (
    options?: MutationOptions<void, LicenseRequest>
  ) => MutationResult<void, LicenseRequest>;
  changeLicenseActivity: (licenseId: number, body: ActivityStatus) => Promise<void>;
  useChangeLicenseActivity: (
    options?: MutationOptions<void, ChangeLicenseActivityMutation>
  ) => MutationResult<void, ChangeLicenseActivityMutation>;
  getDeviceLimits: (companyId: number) => Promise<DeviceLimits>;
  useDeviceLimits: (
    companyId: number,
    options?: QueryOptions<DeviceLimits>
  ) => QueryResult<DeviceLimits>;
}

export function createLicenseQueryClient(axios: AxiosInstance): LicenseQueryClient {
  return new LicenseQueryClientImp(axios);
}

class LicenseQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getLicenses = async (
    companyId: number | null,
    isActive?: boolean,
    isValid?: boolean
  ): Promise<LicenseDto[]> => {
    return await this.axios
      .get(`/api/licenses/`, {
        params: {
          companyId: companyId ? companyId : undefined,
          isActive: isActive !== undefined ? isActive : undefined,
          isValid: isValid !== undefined ? isValid : undefined,
        },
      })
      .then((response: AxiosResponse) => response.data);
  };

  public useLicenses = (
    companyId: number | null,
    isActive?: boolean,
    isValid?: boolean,
    options?: QueryOptions<LicenseDto[]>
  ): QueryResult<LicenseDto[]> => {
    return useQuery<LicenseDto[], AxiosError>(
      [QueryKeys.GetLicenses, companyId, isActive, isValid],
      () => this.getLicenses(companyId, isActive, isValid),
      options
    );
  };

  public createLicense = async (body: LicenseRequest): Promise<void> => {
    return await this.axios
      .post(`/api/licenses/`, body)
      .then((response: AxiosResponse) => response.data);
  };

  public useCreateLicense = (
    options?: MutationOptions<void, LicenseRequest>
  ): MutationResult<void, LicenseRequest> => {
    return useMutation<void, AxiosError, LicenseRequest>(
      [QueryKeys.CreateLicense],
      (variables: LicenseRequest) => this.createLicense(variables),
      options
    );
  };

  public getLicense = async (licenseId: number): Promise<LicenseDto> => {
    return await this.axios
      .get(`/api/licenses/${licenseId}`)
      .then((response: AxiosResponse) => response.data);
  };

  public useLicense = (
    licenseId: number,
    options?: QueryOptions<LicenseDto>
  ): QueryResult<LicenseDto> => {
    return useQuery<LicenseDto, AxiosError>(
      [QueryKeys.GetLicense, licenseId],
      () => this.getLicense(licenseId),
      options
    );
  };

  public changeLicenseActivity = async (licenseId: number, body: ActivityStatus): Promise<void> => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return await this.axios
      .put(`/api/licenses/${licenseId}/status`, body, config)
      .then((response: AxiosResponse) => response.data);
  };

  public useChangeLicenseActivity = (
    options?: MutationOptions<void, ChangeLicenseActivityMutation>
  ): MutationResult<void, ChangeLicenseActivityMutation> => {
    return useMutation<void, AxiosError, ChangeLicenseActivityMutation>(
      [QueryKeys.ChangeLicenseActivity],
      ({ licenseId, body }: ChangeLicenseActivityMutation) =>
        this.changeLicenseActivity(licenseId, body),
      options
    );
  };

  public getDeviceLimits = async (companyId: number): Promise<DeviceLimits> => {
    return await this.axios
      .get(`/api/licenses/device-limits/`, {
        params: {
          companyId: companyId,
        },
      })
      .then((response: AxiosResponse) => response.data);
  };

  public useDeviceLimits = (
    companyId: number,
    options?: QueryOptions<DeviceLimits>
  ): QueryResult<DeviceLimits> => {
    return useQuery<DeviceLimits, AxiosError>(
      [QueryKeys.GetDeviceLimits, companyId],
      () => this.getDeviceLimits(companyId),
      options
    );
  };
}

type ActivityStatus = 'ACTIVE' | 'INACTIVE';

interface ChangeLicenseActivityMutation {
  licenseId: number;
  body: ActivityStatus;
}
