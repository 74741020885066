import React, { FC, ReactElement } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';

import { stringOrNull } from '../../utils/SetValueAsUtil';
import MqttConfig from './common/MqttConfiguration';
import BinaryDataConfig from './common/BinaryDataConfig';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { NbIoTConfigModel } from '@thingslog/repositories';

const NbIoTNetworkConfiguration: FC<NbIoTNetworkConfigurationProps> = () => {
  const { t } = useTranslation();

  return (
    <ConnectForm<NbIoTConfigModel>>
      {({ register, control }: UseFormReturn<NbIoTConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 gap-5">
          <TextField
            {...register('address')}
            label={t('device_initial_config_address')}
            size="small"
          />
          <TextField {...register('port')} label={t('device_initial_config_port')} size="small" />

          <TextField
            {...register('apn', {
              setValueAs: stringOrNull,
            })}
            label="APN"
            size="small"
          />

          <TextField {...register('bands')} label={t('device_initial_config_bands')} size="small" />

          <FormControl size="small">
            <InputLabel>{t('device_initial_config_resolve_address')}</InputLabel>
            <Controller
              control={control}
              name="resolveAddress"
              render={({
                field,
              }: UseControllerReturn<NbIoTConfigModel, 'resolveAddress'>): ReactElement => (
                <Select<boolean>
                  {...field}
                  onChange={(e: SelectChangeEvent): void =>
                    field.onChange(e.target.value === 'true' ? true : false)
                  }
                  label={t('device_initial_config_resolve_address')}
                >
                  <MenuItem value="true">{t('yes')}</MenuItem>
                  <MenuItem value="false">{t('no')}</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <TextField
            {...register('simCardProvider', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_simcard_provider')}
            size="small"
          />

          <TextField
            {...register('simCardNumber', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_simcard_number')}
            size="small"
          />

          <BinaryDataConfig />

          <MqttConfig />
        </div>
      )}
    </ConnectForm>
  );
};

interface NbIoTNetworkConfigurationProps {}

export default NbIoTNetworkConfiguration;
