import React, { FC, useState, SyntheticEvent, ReactNode, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Typography,
  AutocompleteRenderInputParams,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Device, MutateDeviceSensor, Port, PortsConfig } from '@thingslog/repositories';
import getDefaultSensorName from '../../../../../common/SensorNameHelper';

const AddSensorModal: FC<AddSensorModalProps> = ({
  isOpenedAddSensorModal,
  selectedDeviceGroupId,
  devices,
  selectedDeviceNumber,
  onSelectedDeviceChange,
  selectedDevicePorts,
  handleOnCloseModal,
  handleOnCreateSensorDeviceToGroup,
}: AddSensorModalProps) => {
  const [selectedSensorIndex, setSelectedSensorIndex] = useState<number | null>(null);

  const { t } = useTranslation();

  const deviceNumbers = useMemo(() => devices.map((device: Device) => device.number), [devices]);
  const sensorsByDevice = useMemo(() => {
    let sensorObjectEntries = Object.entries(selectedDevicePorts);
    let mappedList: sensorNameMapList[] = [];
    sensorObjectEntries.map((x: [string, Port]) => {
      let sensorIndex = Number(x[0]);
      let label = x[1].sensor.name || getDefaultSensorName(sensorIndex);
      if (x[1].enabled) {
        mappedList.push({ label: label, id: sensorIndex });
      }
    });
    return mappedList;
  }, [selectedDevicePorts]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'backdropClick') {
      return;
    }

    handleOnCloseModal();
    onSelectedDeviceChange(null);
    setSelectedSensorIndex(null);
  };

  const handleSubmit = (deviceNumber: string, sensorIndex: number): void => {
    if (selectedDeviceGroupId != null) {
      handleOnCreateSensorDeviceToGroup(selectedDeviceGroupId, {
        deviceNumber,
        sensorIndex,
      });
      onSelectedDeviceChange(null);
    }
  };

  return (
    <Dialog
      open={isOpenedAddSensorModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '500px',
          },
        },
      }}
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        id="alert-dialog-title"
      >
        <Typography sx={{ fontSize: 24, color: 'primary.main' }}>
          {t('device_grous_add_sensor_device')}
        </Typography>
        <div>
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              '&:hover': {
                color: 'error.main',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} marginTop={1}>
          <Autocomplete
            fullWidth
            options={deviceNumbers}
            onChange={(event: any): void => {
              onSelectedDeviceChange(event.target.innerText);
            }}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                {...params}
                label={t('device_groups_sensors_add_modal_device_placeholder')}
                variant="outlined"
                size="small"
              />
            )}
          />
          <Autocomplete
            fullWidth
            options={sensorsByDevice}
            disabled={selectedDeviceNumber === null}
            getOptionLabel={(sensor: sensorNameMapList): string => sensor.label}
            value={
              sensorsByDevice.find(
                (sensor: sensorNameMapList) => sensor.id === selectedSensorIndex
              ) ?? null
            }
            onChange={(event: SyntheticEvent, value: sensorNameMapList | null): void => {
              if (value) {
                setSelectedSensorIndex(value.id);
              }
            }}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                {...params}
                label={t('device_groups_sensors_add_modal_sensor_placeholder')}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Stack>
        <Button
          sx={{ marginTop: 3 }}
          fullWidth
          variant="contained"
          disabled={selectedDeviceNumber === null || selectedSensorIndex === null}
          disableElevation
          onClick={(): void => {
            handleSubmit(selectedDeviceNumber!, selectedSensorIndex!);
          }}
        >
          {t('device_groups_btn_add')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

interface AddSensorModalProps {
  isOpenedAddSensorModal: boolean;
  selectedDeviceGroupId: number | null;
  devices: Device[];
  selectedDeviceNumber: string | null;
  onSelectedDeviceChange: (deviceNumber: string | null) => void;
  selectedDevicePorts: PortsConfig;
  handleOnCloseModal: () => void;
  handleOnCreateSensorDeviceToGroup: (deviceGroupId: number, body: MutateDeviceSensor) => void;
}

interface sensorNameMapList {
  label: string;
  id: number;
}

export default AddSensorModal;
