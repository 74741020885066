const SECOND = 1;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const ValidDropdownOptions = [
  1 * SECOND,
  2 * SECOND,
  5 * SECOND,
  10 * SECOND,
  15 * SECOND,
  20 * SECOND,
  30 * SECOND,
  1 * MINUTE,
  2 * MINUTE,
  3 * MINUTE,
  5 * MINUTE,
  10 * MINUTE,
  15 * MINUTE,
  30 * MINUTE,
  45 * MINUTE,
  1 * HOUR,
  2 * HOUR,
  4 * HOUR,
  8 * HOUR,
  12 * HOUR,
  1 * DAY,
  2 * DAY,
];

export default ValidDropdownOptions;
