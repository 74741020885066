import React from 'react';
import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from './LoadingModalContentWrapper.styles';

class LoadingModalContentWrapper extends React.Component<LoadingModalContentWrapperProps> {
  public render = (): React.ReactNode => {
    const { classes } = this.props;

    if (!this.props.isLoaded) {
      return (
        <Grid container alignItems="center" justifyContent="center" className={classes.root}>
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              color="primary"
              onClick={this.props.closeSelf}
              className={classes.floatingCloseButton}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container alignItems="center" justifyContent="center" className={classes.root}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid item xs={12} className={classes.formWrapper}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginBottom: 15 }}
                >
                  <Grid item>
                    <Typography variant="h6">
                      <Box fontWeight={500}>{this.props.title}</Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      className={classes.closeIconButton}
                      onClick={this.props.closeSelf}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {this.props.children}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };
}

interface LoadingModalContentWrapperProps extends WithStyles<typeof styles> {
  isLoaded: boolean;
  title: string;
  closeSelf: () => void;
  children: React.ReactNode;
}

export default withStyles(styles, { withTheme: true })(LoadingModalContentWrapper);
