import React, { useEffect, useMemo, useState } from 'react';
import { ReduxState } from '../../reducers';
import { useSelector } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { SvgIconComponent } from '@mui/icons-material';

import { MenuItem } from '../../model/Navigation/MenuItem';
import JwtValidator from '../../common/JwtValidator';
import MenuLink from './MenuLink';

const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const SubMenu: React.FC<SubMenuProps> = (props: SubMenuProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedPageId = useSelector((state: ReduxState) => state.dev.selectedPageId);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const jwtValidator = useMemo(() => new JwtValidator(), []);

  useEffect(() => {
    if (props.menuOpen && searchForSelectedPage(props.menu, selectedPageId)) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [props.menuOpen, props.isMainMenu, props.menu]);

  const searchForSelectedPage = (menu: MenuItem[], pageId: string): boolean => {
    for (const item of menu) {
      if (item.id === pageId) return true;
      if (item.subMenu) {
        const child = searchForSelectedPage(item.subMenu, pageId);
        if (child) return true;
      }
    }

    return false;
  };

  return (
    <Accordion
      disableGutters={true}
      className="rounded-none border-none shadow-none before:border-none"
      expanded={isExpanded}
      onChange={(): void => setIsExpanded((state: boolean) => !state)}
    >
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        classes={{ content: classes.content, expanded: classes.expanded }}
        className="pl-2 pr-2 min-h-[2.5rem] text-gray-700 hover:bg-gray-100"
      >
        <div className="flex items-center">
          <props.icon className="m-2 text-3xl" />
          <span>{t(props.name)}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className="py-0 pl-4 pr-0">
        {props.menu.map((item: MenuItem) => (
          <div key={item.id}>
            {(item.roles.length === 0 || jwtValidator.hasRoles(item.roles)) && (
              <>
                {item.subMenu && item.subMenu.length !== 0 ? (
                  <SubMenu
                    menu={item.subMenu}
                    icon={item.icon}
                    name={item.name}
                    isMainMenu={props.isMainMenu}
                    selectedDevice={props.selectedDevice}
                    menuOpen={props.menuOpen}
                    handleLinkClick={props.handleLinkClick}
                  />
                ) : (
                  <MenuLink
                    name={item.name}
                    path={item.path}
                    icon={item.icon}
                    gaId={item.ga_id}
                    isMainMenu={props.isMainMenu}
                    selectedDevice={props.selectedDevice}
                    pageId={item.id}
                    onClick={props.handleLinkClick}
                  />
                )}
              </>
            )}
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

interface SubMenuProps {
  menu: MenuItem[];
  icon: SvgIconComponent;
  name: string;
  isMainMenu: boolean;
  selectedDevice: string | null;
  menuOpen: boolean;
  handleLinkClick: (gaId?: string) => void;
}

export default SubMenu;
