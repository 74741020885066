import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import { UserDto } from '@thingslog/repositories';

const DeleteUserModal: React.FC<DeleteUserModalProps> = (props: DeleteUserModalProps) => {
  const { selectedUser, onCloseModal, onDeleteUser } = props;
  const { t } = useTranslation();

  return (
    <>
      {t<string>('users_remove_modal_body')}
      <div className="flex gap-5 mt-5 justify-end">
        <Button
          color="error"
          variant="contained"
          onClick={(): void => {
            onDeleteUser(selectedUser.username);
            onCloseModal && onCloseModal();
          }}
          autoFocus
        >
          {t<string>('capital_remove_btn')}
        </Button>
        <Button variant="contained" onClick={onCloseModal}>
          {t<string>('capital_cancel_btn')}
        </Button>
      </div>
    </>
  );
};

interface DeleteUserModalProps {
  selectedUser: UserDto;
  onCloseModal: () => void;
  onDeleteUser: (username: string) => void;
}

export default DeleteUserModal;
