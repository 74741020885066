import { useMutation, useQuery } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  QueryOptions,
  QueryResult,
  DeviceLocationDto,
  MutationOptions,
  MutationResult,
  MutateDeviceLocation,
} from '@thingslog/repositories';

export interface DeviceLocationQueryClient {
  getDeviceLocation: (deviceNumber: string) => Promise<DeviceLocationDto>;
  getDeviceLocations: (companyId: number | null) => Promise<Map<string, DeviceLocationDto>>;
  updateDeviceLocation: (deviceNumber: string, body: DeviceLocationDto) => Promise<void>;
  useDeviceLocationData: (
    deviceNumber: string,
    options?: QueryOptions<DeviceLocationDto>
  ) => QueryResult<DeviceLocationDto>;
  useDeviceLocationsData: (
    companyId: number | null,
    options?: QueryOptions<Map<string, DeviceLocationDto>>
  ) => QueryResult<Map<string, DeviceLocationDto>>;
  useUpdateDeviceLocation: (
    options?: MutationOptions<void, MutateDeviceLocation>
  ) => MutationResult<void, MutateDeviceLocation>;
}

export function createDeviceLocationsQueryClient(axios: AxiosInstance): DeviceLocationQueryClient {
  return new DeviceLocationImp(axios);
}

class DeviceLocationImp {
  public constructor(private axios: AxiosInstance) {}

  public getDeviceLocation = async (deviceNumber: string): Promise<DeviceLocationDto> => {
    return this.axios
      .get(`/api/devices/${deviceNumber}/location`)
      .then((response: AxiosResponse<DeviceLocationDto>) => {
        return response.data;
      });
  };

  public getDeviceLocations = async (
    companyId: number | null
  ): Promise<Map<string, DeviceLocationDto>> => {
    return this.axios
      .get(`/api/devices/locations`, {
        params: { companyId: companyId ? companyId : undefined },
      })
      .then((response: AxiosResponse<Map<string, DeviceLocationDto>>) => {
        return response.data;
      });
  };

  public updateDeviceLocation = async (
    deviceNumber: string,
    body: DeviceLocationDto
  ): Promise<void> => {
    return this.axios
      .put(`/api/devices/${deviceNumber}/location`, body)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useDeviceLocationData = (
    deviceNumber: string,
    options?: QueryOptions<DeviceLocationDto>
  ): QueryResult<DeviceLocationDto> => {
    return useQuery<DeviceLocationDto, AxiosError>(
      [QueryKeys.UseDeviceLocationData, deviceNumber],
      () => this.getDeviceLocation(deviceNumber),
      options
    );
  };

  public useDeviceLocationsData = (
    companyId: number | null,
    options?: QueryOptions<Map<string, DeviceLocationDto>>
  ): QueryResult<Map<string, DeviceLocationDto>> => {
    return useQuery<Map<string, DeviceLocationDto>, AxiosError>(
      [QueryKeys.UseDeviceLocationData, companyId || 'none'],
      () => this.getDeviceLocations(companyId),
      options
    );
  };

  public useUpdateDeviceLocation = (
    options?: MutationOptions<void, MutateDeviceLocation>
  ): MutationResult<void, MutateDeviceLocation> => {
    return useMutation<void, AxiosError, MutateDeviceLocation>(
      [QueryKeys.UseUpdateDeviceLocationData],
      (params: MutateDeviceLocation) =>
        this.updateDeviceLocation(params.deviceNumber, params.location),
      options
    );
  };
}
