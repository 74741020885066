import React, { createContext } from 'react';

export interface ICommandPageState {
  selectedCommandId: number | null;
  addModalOpen: boolean;
  editModalOpen: boolean;
}

export const initialCommandPageState: ICommandPageState = {
  selectedCommandId: null,
  addModalOpen: false,
  editModalOpen: false,
};

export interface ICommandPageActions {
  type: 'change_selected_command_id' | 'toggle_add_modal' | 'toggle_edit_modal';
  payload: boolean | number | null;
}

export const commandPageReducer = (
  state: ICommandPageState,
  action: ICommandPageActions
): ICommandPageState => {
  const payload = action.payload;
  switch (action.type) {
    case 'change_selected_command_id':
      if (payload === null || typeof payload == 'number') {
        return {
          ...state,
          selectedCommandId: payload,
        };
      } else {
        return state;
      }
    case 'toggle_add_modal':
      if (typeof payload == 'boolean') {
        return {
          ...state,
          addModalOpen: payload,
        };
      } else {
        return state;
      }
    case 'toggle_edit_modal':
      if (typeof payload == 'boolean') {
        return {
          ...state,
          editModalOpen: payload,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export interface ICommandContextProps {
  commandPageState: ICommandPageState;
  commandPageDispatch: React.Dispatch<ICommandPageActions>;
}

const CommandPageContext = createContext<ICommandContextProps>({
  commandPageState: initialCommandPageState,
  commandPageDispatch: () => {},
});

export default CommandPageContext;
