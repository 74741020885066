import { Theme } from '@mui/material';
import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { cyan } from '@mui/material/colors';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    tableContainer: {
      zIndex: -10,
    },
    modalTextField: {
      marginTop: 5,
      marginBottom: 5,
    },
    modalButton: {
      width: '100%',
      marginTop: 10,
    },
    modalDeleteButton: {
      width: '100%',
      marginTop: 5,
      backgroundColor: theme.palette.error.main,
      '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.error.dark },
    },
    modalFormControl: {
      width: '100%',
      marginTop: 5,
      marginBottom: 5,
    },
    colorfulIconButton: {
      backgroundColor: cyan[500],
      color: 'white',
      '&:hover': { backgroundColor: cyan[700] },
    },
  });
