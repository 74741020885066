import { Theme } from '@mui/material';

import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    mainContainer: {
      marginLeft: 15,
      marginBottom: 10,
    },
    measurementUnderline: {
      textDecoration: 'underline',
      textDecorationThickness: 3,
    },
    measurementGridItem: {
      minWidth: 120,
      margin: 0,
      padding: 0,
    },
  });
