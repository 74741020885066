import { Device } from '@thingslog/repositories';
import { Action } from 'redux';

export enum DevicesActionType {
  SET_DEVICES = 'SET_DEVICES',
}

export type DevicesAction = Action<DevicesActionType>;

export interface GetDevicesAction extends DevicesAction {
  payload: Device[];
  type: DevicesActionType.SET_DEVICES;
}
