import { CompanyDto, MutateUtilityRate, UtilityRateBareboneDto } from '@thingslog/repositories';
import React, { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import {
  companyQueryClient,
  utilityRateQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import Header from '../../components/header';
import UtilityRateTable from './UtilityRateTable';
import { Button, Container } from '@mui/material';
import { useModal, useToast } from '@thingslog/ui-components';
import ViewUtilRateModal from './components/ViewUtilRateModal';
import { AxiosError } from 'axios';
import MutateUtilRateModal from './components/MutateUtilRateModal';
import DeleteUtilityRateModal from './components/DeleteUtilRateModal';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { useQueryClient } from '@tanstack/react-query';

const UtilityRatePage: FC<UtilityRatePageProps> = () => {
  // #region State
  const [pageSize, setPageSize] = useState(10);
  const selectedCompanyId = useSelector((state: ReduxState) => state.company.id);
  const companiesRef = useRef<CompanyDto[] | null>(null);
  // #endregion

  // #region Hooks
  const { modal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { t } = useTranslation();
  const { useUtilityRates, useCreateUtilityRate, useDeleteUtilityRate, useUpdateUtilityRate } =
    utilityRateQueryClient;
  const { useGetAllCompanies } = companyQueryClient;
  // #endregion

  // #region HTTP
  useGetAllCompanies({
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: (data: CompanyDto[]) => {
      companiesRef.current = data;
    },
    onError: (error: AxiosError) => {
      toast({
        message: t('util_rate_fetch_company_error'),
      });
    },
  });

  const utilityRatesQuery = useUtilityRates(selectedCompanyId, null, null, {
    onError: () => {
      toast({
        message: t('util_rate_fetch_util_rates_error'),
      });
    },
  });

  const createUtilityRateMutation = useCreateUtilityRate({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetUtilityRates]);
    },
    onError: (error: AxiosError) => {
      onMutationError(error);
    },
  });

  const updateUtilityRateMutation = useUpdateUtilityRate({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetUtilityRates]);
    },
    onError: (error: AxiosError) => {
      onMutationError(error);
    },
  });

  const deleteUtilityRateMutation = useDeleteUtilityRate({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetUtilityRates]);
    },
    onError: () => {
      toast({
        type: 'error',
        message: t('util_rates_error'),
      });
    },
  });
  // #endregion

  // #region Event Handlers
  const onMutationError = (error: AxiosError): void => {
    const errorCode: string | undefined = error.response?.data?.errorCode;
    const errorMessage: string | undefined = error.response?.data?.message;
    let toastMessage = t('util_rates_error');
    if (errorCode === 'UTILITY_RATE_ALREADY_EXISTS') {
      toastMessage = t('util_rates_error_name_exists');
    } else if (errorMessage !== undefined) {
      toastMessage = errorMessage;
    }
    toast({
      type: 'error',
      message: toastMessage,
    });
  };

  const onInspect = (utilityRate: UtilityRateBareboneDto): void => {
    modal({
      title: `${utilityRate.name}`,
      content: <ViewUtilRateModal utilityRate={utilityRate} />,
    });
    queryClient.invalidateQueries([QueryKeys.GetUtilityRate]);
  };

  const onCreate = (): void => {
    modal({
      title: t('util_rates_add_modal_header'),
      content: (
        <MutateUtilRateModal
          onSubmit={(body: MutateUtilityRate): void => {
            createUtilityRateMutation.mutate(body);
            closeModal();
          }}
          companies={companiesRef.current}
        />
      ),
    });
  };

  const onEdit = (utilityRate: UtilityRateBareboneDto): void => {
    modal({
      title: t('util_rates_edit_modal_header', {
        name: utilityRate.name,
      }),
      content: (
        <MutateUtilRateModal
          utilityRate={utilityRate}
          onSubmit={(body: MutateUtilityRate): void => {
            updateUtilityRateMutation.mutate({ id: utilityRate.id, body });
            closeModal();
          }}
          companies={companiesRef.current}
        />
      ),
    });
  };

  const onDelete = (utilityRate: UtilityRateBareboneDto): void => {
    modal({
      title: t('util_rates_delete_modal_header', {
        name: utilityRate.name,
      }),
      content: (
        <DeleteUtilityRateModal
          onConfirm={(): void => {
            deleteUtilityRateMutation.mutate(utilityRate.id);
            closeModal();
          }}
          onCancel={(): void => {
            closeModal();
          }}
        />
      ),
    });
  };
  // #endregion

  return (
    <Header>
      <Container>
        <section className="flex flex-row space-x-2 justify-between mb-2">
          <div>{/* Country dropdown */}</div>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className="w-full sm:w-auto"
            onClick={(): void => {
              onCreate();
            }}
          >
            {t('create')}
          </Button>
        </section>
        {utilityRatesQuery.isSuccess && (
          <section className="w-full">
            <UtilityRateTable
              utilityRates={utilityRatesQuery.data}
              autoHeight={true}
              rowHeight={40}
              disableSelectionOnClick
              pageSize={pageSize}
              onPageSizeChange={(newPageSize: number): void => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
              onInspect={onInspect}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </section>
        )}
      </Container>
    </Header>
  );
};

interface UtilityRatePageProps {}

export default UtilityRatePage;
