import { TableCell, tableCellClasses, TableRow, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    fontWeight: 'bold',
    borderRadius: 10,

    [`&.${tableCellClasses.body}`]: {},
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  ' td, th': {
    border: `1px solid ${theme.palette.divider}`,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
