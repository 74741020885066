import { t } from 'i18next';

const getDefaultSensorName = (sensorIndex: number | string): string => {
  try {
    const index = Number(sensorIndex);
    switch (index) {
      case 0:
        return t('pulse_sensor_1');
      case 1:
        return t('pulse_sensor_2');
      case 2:
        return t('analog_sensor_1');
      case 3:
        return t('analog_sensor_2');
      case 4:
        return t('on_off_sensor_1');
      case 5:
        return t('on_off_sensor_2');
      case 6:
        return t('on_off_sensor_3');
      case 7:
        return t('on_off_sensor_4');
      case 8:
        return t('analog_sensor_3');
      case 9:
        return t('analog_sensor_4');
      case 10:
        return t('analog_sensor_5');
      case 11:
        return t('analog_sensor_6');
      case 12:
        return t('analog_sensor_7');
      case 13:
        return t('analog_sensor_8');
      default:
        return '';
    }
  } catch (error) {
    return '';
  }
};

export default getDefaultSensorName;
