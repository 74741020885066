import { Localization } from '@mui/x-data-grid/utils/getGridLocalization';
import {
  bgBG as bgDataGridLocale,
  deDE as deDataGridLocale,
  esES as esDataGridLocale,
  enUS as enDataGridLocale,
  elGR as grDataGridLocale,
  ruRU as ruDataGridLocale,
} from '@mui/x-data-grid';

interface Locales {
  bg: Localization;
  de: Localization;
  es: Localization;
  en: Localization;
  gr: Localization;
  ru: Localization;
}

const DataGridLocale: Locales = {
  de: deDataGridLocale,
  es: esDataGridLocale,
  en: enDataGridLocale,
  ru: ruDataGridLocale,
  bg: {
    ...bgDataGridLocale,
    components: {
      ...bgDataGridLocale.components,
      MuiDataGrid: {
        ...bgDataGridLocale.components.MuiDataGrid,
        defaultProps: {
          ...bgDataGridLocale.components.MuiDataGrid.defaultProps,
          localeText: {
            ...bgDataGridLocale.components.MuiDataGrid.defaultProps.localeText,
            detailPanelToggle: 'Превключване на панела с детайли',
            filterPanelLinkOperator: 'Логически оператор',
            aggregationMenuItemHeader: 'Агрегиране',
            aggregationFunctionLabelSum: 'сума',
            aggregationFunctionLabelAvg: 'средно',
            aggregationFunctionLabelMin: 'минимум',
            aggregationFunctionLabelMax: 'максимум',
            aggregationFunctionLabelSize: 'размер',
          },
        },
      },
    },
  },
  gr: {
    ...grDataGridLocale,
    components: {
      ...grDataGridLocale.components,
      MuiDataGrid: {
        ...grDataGridLocale.components.MuiDataGrid,
        defaultProps: {
          ...grDataGridLocale.components.MuiDataGrid.defaultProps,
          localeText: {
            ...grDataGridLocale.components.MuiDataGrid.defaultProps.localeText,
            MuiTablePagination: {
              labelRowsPerPage: 'σειρές ανά σελίδα:',
            },
            toolbarQuickFilterPlaceholder: 'Αναζήτηση...',
            toolbarQuickFilterLabel: 'Αναζήτηση',
            toolbarQuickFilterDeleteIconLabel: 'Καθαρισμός',
            toolbarExportPrint: 'Εκτύπωση',
            toolbarExportExcel: 'Λήψη ως Excel',
            filterOperatorIsAnyOf: 'είναι οποιοδήποτε από',
            filterValueAny: 'οποιοδήποτε',
            filterValueTrue: 'αληθές',
            filterValueFalse: 'ψευδές',
            checkboxSelectionHeaderName: 'Επιλογή με κουτάκι',
            checkboxSelectionSelectAllRows: 'Επιλογή όλων των γραμμών',
            checkboxSelectionUnselectAllRows: 'Αποεπιλογή όλων των γραμμών',
            checkboxSelectionSelectRow: 'Επιλογή γραμμής',
            checkboxSelectionUnselectRow: 'Αποεπιλογή γραμμής',
            booleanCellTrueLabel: 'ναι',
            booleanCellFalseLabel: 'όχι',
            pinToLeft: 'Καρφίτσωμα αριστερά',
            pinToRight: 'Καρφίτσωμα δεξιά',
            unpin: 'Αποσύνδεση από το πλαίσιο',
            treeDataGroupingHeaderName: 'Ομαδοποίηση',
            treeDataExpand: 'Εμφάνιση παιδικών κόμβων',
            treeDataCollapse: 'Απόκρυψη παιδικών κόμβων',
            groupingColumnHeaderName: 'Ομαδοποίηση',
            groupColumn: (name) => `Ομαδοποίηση κατά ${name}`,
            unGroupColumn: (name) => ` Διακοπή ομαδοποίησης κατά ${name}`,
            detailPanelToggle: 'Εναλλαγή λεπτομερειών',
            expandDetailPanel: 'Εμφάνιση',
            collapseDetailPanel: 'Απόκρυψη',
            rowReorderingHeaderName: 'Αναδιάταξη γραμμών',
            aggregationMenuItemHeader: 'Συγκέντρωσ',
            filterPanelLinkOperator: 'Χειριστής λογικής',
            aggregationFunctionLabelAvg: 'μέσος όρος',
            aggregationFunctionLabelMax: 'μέγιστος',
            aggregationFunctionLabelMin: 'ελάχιστος',
            aggregationFunctionLabelSize: 'μέγεθος',
            aggregationFunctionLabelSum: 'άθροιση',
          },
        },
      },
    },
  },
};

export default DataGridLocale;
