import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import AuthUtil from '../../common/AuthUtil';
import Histogram from '../../model/API/Histogram/Histogram';
import Statistics from '../../model/API/Statistics/Statistics';

export default class StatisticsRepository {
  public getDeviceCounterFlowStatistics = async (
    number: string,
    sensorIndex: number,
    every: number,
    fromDate: Date,
    toDate: Date
  ): Promise<Histogram> => {
    return await Axios.get(
      `/api/devices/${number}/${sensorIndex}/counters/statistics/flow?every=${every}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
    ).then((response: AxiosResponse) => response.data);
  };

  public getDeviceCounterGeneralStatistics = async (
    number: string,
    sensorIndex: number,
    every: number,
    fromDate: Date,
    toDate: Date
  ): Promise<Statistics> => {
    return await Axios.get(
      `/api/devices/${number}/${sensorIndex}/counters/statistics/general?every=${every}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
    ).then((response: AxiosResponse) => response.data);
  };

  public getDeviceCounterQFlowStatistics = async (
    number: string,
    sensorIndex: number,
    every: number,
    fromDate: Date,
    toDate: Date
  ): Promise<Histogram> => {
    return await Axios.get(
      `/api/devices/${number}/${sensorIndex}/counters/statistics/qflow?every=${every}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
    ).then((response: AxiosResponse) => response.data);
  };

  public getDeviceCounterHistogramByTime = async (
    number: string,
    sensorIndex: number,
    every: number,
    fromDate: Date,
    toDate: Date
  ): Promise<Histogram> => {
    return await Axios.get(
      `/api/devices/${number}/${sensorIndex}/counters/statistics/time?every=${every}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
    ).then((response: AxiosResponse) => response.data);
  };
}
