import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IndexedWaterMeter from '../model/IndexedWaterMeter';

const WaterMeterSelector: React.FC<WaterMeterSelectorProps> = ({
  waterMeters,
  selectedIndex,
  setSelectedIndex,
}: WaterMeterSelectorProps) => {
  const { t } = useTranslation();

  return (
    <FormControl size="small">
      <InputLabel>{t('sensor')}</InputLabel>
      <Select
        size="small"
        value={selectedIndex}
        label={t('sensor')}
        onChange={(e: SelectChangeEvent<number | null>): void => {
          e.preventDefault();
          setSelectedIndex(e.target.value as number);
        }}
      >
        {waterMeters.map((indexedWaterMeter: IndexedWaterMeter): React.ReactNode => {
          return (
            <MenuItem value={indexedWaterMeter.index}>
              {indexedWaterMeter.waterMeter.name} ({indexedWaterMeter.index})
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

interface WaterMeterSelectorProps {
  waterMeters: IndexedWaterMeter[];
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

export default WaterMeterSelector;
