import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { QueryOptions, TransmissionsDto } from '@thingslog/repositories';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { QueryKeys } from '../enums/QueryKeys';

export interface TransmissionsQueryClient {
  getTransmissions: (companyId: number | null) => Promise<TransmissionsDto>;
  useTransmissions: (
    companyId: number | null,
    options?: QueryOptions<TransmissionsDto>
  ) => UseQueryResult<TransmissionsDto, AxiosError>;
}

export function createTransmissionsQueryClient(axios: AxiosInstance): TransmissionsQueryClient {
  return new TransmissionsImp(axios);
}

class TransmissionsImp {
  public constructor(private axios: AxiosInstance) {}

  public getTransmissions = async (companyId: number | null): Promise<TransmissionsDto> => {
    return this.axios
      .get('/api/transmissions', {
        params: { companyId: companyId ? companyId : undefined },
      })
      .then((response: AxiosResponse<TransmissionsDto>) => {
        return response.data;
      });
  };

  public useTransmissions = (
    companyId: number | null,
    options?: QueryOptions<TransmissionsDto>
  ): UseQueryResult<TransmissionsDto, AxiosError> => {
    return useQuery<TransmissionsDto, AxiosError>(
      [QueryKeys.getTransmissions, companyId],
      () => this.getTransmissions(companyId),
      options
    );
  };
}
