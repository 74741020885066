export enum FlowTimeUnits {
  NANOS = 'NANOS',
  MICROS = 'MICROS',
  MILLIS = 'MILLIS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  HALFDAYS = 'HALFDAYS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
  DECADES = 'DECADES',
  CENTURIES = 'CENTURIES',
  MILLENNIA = 'MILLENNIA',
  ERAS = 'ERAS',
  FOREVER = 'FOREVER',
}
