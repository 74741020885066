import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { DeviceSensorDto } from '@thingslog/repositories';

const DeleteSensorModal: FC<DeleteSensorModalProps> = ({
  isOpenedDeleteSensorModal,
  selectedSensor,
  selectedDeviceGroupId,
  handleOnCloseModal,
  handleOnDeleteDeviceAndSensorFromGroup,
}: DeleteSensorModalProps) => {
  const { t } = useTranslation();

  const handleClose = (event: SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'backdropClick') {
      return;
    }

    handleOnCloseModal();
  };

  return (
    <>
      <Dialog
        open={isOpenedDeleteSensorModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            {t('device_groups_delete_device_group')}: <b>{selectedSensor.sensorName}</b>?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('device_groups_sensors_delete_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void =>
              handleOnDeleteDeviceAndSensorFromGroup(
                selectedDeviceGroupId!,
                selectedSensor.deviceNumber,
                selectedSensor.sensorIndex
              )
            }
            autoFocus
          >
            {t('device_groups_btn_delete')}
          </Button>
          <Button onClick={handleClose}>{t('device_groups_btn_cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface DeleteSensorModalProps {
  isOpenedDeleteSensorModal: boolean;
  selectedSensor: DeviceSensorDto;
  selectedDeviceGroupId: number | null;
  handleOnCloseModal: () => void;
  handleOnDeleteDeviceAndSensorFromGroup: (
    deviceGroupId: number,
    deviceNumber: string,
    sensorIndex: number
  ) => void;
}

export default DeleteSensorModal;
