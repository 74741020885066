import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n_en from './i18n-en';
import i18n_bg from './i18n-bg';
import i18n_gr from './i18n-gr';
import i18n_de from './i18n-de';
import i18n_es from './i18n-es';
import i18n_ru from './i18n-ru';
import i18n_ptBr from './i18n-ptBr';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: i18n_en,
  },
  bg: {
    translation: i18n_bg,
  },
  gr: {
    translation: i18n_gr,
  },
  de: {
    translation: i18n_de,
  },
  es: {
    translation: i18n_es,
  },
  ru: {
    translation: i18n_ru,
  },
  ptBr: {
    translation: i18n_ptBr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
