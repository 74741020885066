import { EventValueRange } from '@thingslog/repositories';
import Chart, { ChartOptions, ChartTooltipItem, ChartData, ChartTooltipLabelColor } from 'chart.js';
import {
  LineAnnotationOptions,
  BoxAnnotationOptions,
  AnnotationConfig,
} from 'chartjs-plugin-annotation';
import _ from 'lodash';
import { TimeSeriesData } from '../../model/TimeSeriesData/TimeSeriesData';
import { ChartSensorStatistics } from './model/ChartSensorStatistics';
import { TimeSeriesChartData } from './model/TimeSeriesChartData';

const getMaximumValue = (
  statistics: ChartSensorStatistics[],
  bufferPercent: number,
  valueRanges: EventValueRange[]
): number => {
  let minValue = _.minBy(statistics, (stat: ChartSensorStatistics) => stat.min)?.min || 0;
  let maxValue = _.maxBy(statistics, (stat: ChartSensorStatistics) => stat.max)?.max || 0;
  if (valueRanges && valueRanges.length !== 0) {
    valueRanges.map((valueRange: EventValueRange) => {
      if (valueRange.min !== null && maxValue < valueRange.min) {
        maxValue = valueRange.min;
      } else if (valueRange.max !== null && maxValue < valueRange.max) {
        maxValue = valueRange.max;
      }
    });
  }
  const bufferValue = (bufferPercent / 100) * (maxValue - minValue);
  return maxValue < 0 && bufferValue < 0 ? maxValue - bufferValue : maxValue + bufferValue;
};

const getMinimumValue = (
  statistics: ChartSensorStatistics[],
  bufferPercent: number,
  valueRanges: EventValueRange[]
): number => {
  let minValue = _.minBy(statistics, (stat: ChartSensorStatistics) => stat.min)?.min || 0;
  let maxValue = _.maxBy(statistics, (stat: ChartSensorStatistics) => stat.max)?.max || 0;
  if (valueRanges && valueRanges.length !== 0) {
    valueRanges.map((valueRange: EventValueRange) => {
      if (valueRange.max !== null && minValue > valueRange.max) {
        minValue = valueRange.max;
      } else if (valueRange.min !== null && minValue > valueRange.min) {
        minValue = valueRange.min;
      }
    });
  }
  const bufferValue = (bufferPercent / 100) * (maxValue - minValue);
  return minValue < 0 && bufferValue < 0 ? minValue + bufferValue : minValue - bufferValue;
};

const getEarliestDate = (chartData: TimeSeriesChartData[]): Date => {
  let earliestDate = new Date();
  chartData.forEach((chartDataItem: TimeSeriesChartData) => {
    const data = chartDataItem.data;
    const dates = data.map((tsd: TimeSeriesData) => tsd.x);
    const tsdEarliestDate = _.min(dates);
    if (tsdEarliestDate && tsdEarliestDate.getTime() < earliestDate.getTime()) {
      earliestDate = new Date(tsdEarliestDate.getTime());
    }
  });
  earliestDate.setHours(earliestDate.getHours() - 1);
  return earliestDate;
};

const getLatestDate = (chartData: TimeSeriesChartData[]): Date => {
  let latestDate = new Date();
  chartData.forEach((chartDataItem: TimeSeriesChartData) => {
    const data = chartDataItem.data;
    const dates = data.map((tsd: TimeSeriesData) => tsd.x);
    const tsdLatestDate = _.min(dates);
    if (tsdLatestDate && tsdLatestDate.getTime() > latestDate.getTime()) {
      latestDate = new Date(tsdLatestDate.getTime());
    }
  });
  latestDate.setHours(latestDate.getHours() + 1);
  return latestDate;
};

const generateTooltipLabel = (
  tooltipItem: ChartTooltipItem,
  data: ChartData,
  chartData: TimeSeriesChartData[]
): string | string[] => {
  const datasets = data.datasets;
  const datasetIndex = Number(tooltipItem.datasetIndex);

  let label: string = '';
  let value: string | number = '';
  let units: string = '';

  if (datasets && datasets[datasetIndex]) {
    const datasetValue = tooltipItem.yLabel || null;
    const datasetLabel = datasets[datasetIndex].label || null;

    if (datasetValue) {
      value = datasetValue;
    }
    if (datasetLabel) {
      label = datasetLabel;
    }
  }

  if (chartData && chartData[datasetIndex] && chartData[datasetIndex].units) {
    units = chartData[datasetIndex].units || '';
  }

  if (!isNaN(+value)) {
    value = Number(value).toFixed(3);
  }

  return `${label}: ${value} ${units}`;
};

const generateTooltipColor = (
  tooltipItem: ChartTooltipItem,
  chart: Chart
): ChartTooltipLabelColor => {
  const defaultColor = chart.config.options?.defaultColor || 'rgba(0,0,0,0.1)';
  const datasetIndex = Number(tooltipItem.datasetIndex);
  const datasets = chart.config.data?.datasets || null;

  let backgroundColor = defaultColor;
  let borderColor = defaultColor;

  if (datasetIndex !== null && datasets !== null) {
    const dataset = datasets[datasetIndex];
    const datasetBackgroundColor = dataset.backgroundColor || null;
    const datasetBorderColor = dataset.borderColor || null;

    if (datasetBackgroundColor) {
      backgroundColor = datasetBackgroundColor.toString();
    }
    if (datasetBorderColor) {
      borderColor = datasetBorderColor.toString();
    }
  }

  return { backgroundColor, borderColor };
};

const hexToRgb = (hex: string, opacity: string): string => {
  try {
    const hexWithoutHash = hex.replace('#', '');
    const hexValue = parseInt(hexWithoutHash, 16);
    const red = (hexValue >> 16) & 255;
    const green = (hexValue >> 8) & 255;
    const blue = hexValue & 255;
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  } catch (error) {
    return '#FF0000';
  }
};

const getValueRangeBox = (
  valueRanges: EventValueRange[],
  data: TimeSeriesChartData[],
  fromDate: Date,
  toDate: Date
): (LineAnnotationOptions | BoxAnnotationOptions)[] => {
  if (valueRanges && valueRanges.length !== 0 && data && data.length !== 0) {
    let annotation: (LineAnnotationOptions | BoxAnnotationOptions)[] = [];
    valueRanges.map((valueRange: EventValueRange) => {
      let color =
        data.find((item: TimeSeriesChartData) => item.sensorIndex === valueRange.sensorIndex)
          ?.primaryColor ?? '#FF0000';

      if (valueRange.min && valueRange.max) {
        let box: BoxAnnotationOptions = {
          type: 'box',
          xScaleID: 'x-axis-0',
          yScaleID: 'y-axis-0',
          xMax: toDate,
          xMin: fromDate,
          yMax: valueRange.max!,
          yMin: valueRange.min!,
          borderColor: hexToRgb(color, '0.3'),
          borderWidth: 2,
          backgroundColor: hexToRgb(color, '0.3'),
        };

        annotation.push(box);
      } else {
        let line: LineAnnotationOptions = {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: valueRange.max ? valueRange.max : valueRange.min!, //Its not possible bouth min and max to be null
          borderColor: hexToRgb(color, '0.3'),
          borderWidth: 2,
          label: {
            backgroundColor: hexToRgb(color, '0'),
            position: 'right',
            yAdjust: -10,
            enabled: true,
            fontColor: hexToRgb(color, '1'),
            content: valueRange.name,
          },
        };

        annotation.push(line);
      }
    });
    return annotation;
  } else {
    return [];
  }
};

// TODO: Add option for manipulating the tooltip text
//       - Round number to 3 digits after decimal
//       - Display flow unit after the value if not cumulative
export const LineChartOptions = (props: ChartOptionsProps): ChartOptionsWithAnnotation => {
  const chartOptions: ChartOptionsWithAnnotation = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) =>
          generateTooltipLabel(tooltipItem, data, props.chartData),
        labelColor: generateTooltipColor,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: getMinimumValue(props.statistics, 15, props.valueRanges),
            max: getMaximumValue(props.statistics, 15, props.valueRanges),
          },
          afterTickToLabelConversion: function (scaleInstance: any): void {
            // set the first and last tick to null so it does not display
            // note, ticks[0] is the last tick and ticks[length - 1] is the first
            scaleInstance.ticks[0] = scaleInstance.ticks[0] === '0' ? scaleInstance.ticks[0] : null;
            scaleInstance.ticks[scaleInstance.ticks.length - 1] =
              scaleInstance.ticks[scaleInstance.ticks.length - 1] === '0'
                ? scaleInstance.ticks[scaleInstance.ticks.length - 1]
                : null;

            // need to do the same thing for this similiar array which is used internally
            scaleInstance.ticksAsNumbers[0] =
              scaleInstance.ticksAsNumbers[0] === '0' ? scaleInstance.ticksAsNumbers[0] : null;
            scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] =
              scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] === '0'
                ? scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1]
                : null;
          },
        },
      ],
    },
    annotation: {
      annotations: getValueRangeBox(
        props.valueRanges,
        props.chartData,
        props.fromDate,
        props.toDate
      ),
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 20,
          threshold: 10,
          onPanComplete: props.onComplete,
          rangeMin: {
            x: getEarliestDate(props.chartData),
          },
          rangeMax: {
            x: getLatestDate(props.chartData),
          },
        },
        zoom: {
          enabled: true,
          drag: false,
          mode: 'x',
          speed: 0.1,
          threshold: 20,
          sensitivity: 3,
          onZoomComplete: props.onComplete,
          rangeMin: {
            x: getEarliestDate(props.chartData),
          },
          rangeMax: {
            x: getLatestDate(props.chartData),
          },
        },
      },
    },
    animation: {
      duration: 500,
      easing: 'linear',
    },
  };

  return chartOptions;
};

export const BarChartOptions = (props: ChartOptionsProps): ChartOptionsWithAnnotation => {
  const chartOptions: ChartOptionsWithAnnotation = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) =>
          generateTooltipLabel(tooltipItem, data, props.chartData),
        labelColor: generateTooltipColor,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: getMinimumValue(props.statistics, 15, props.valueRanges),
            max: getMaximumValue(props.statistics, 15, props.valueRanges),
          },
          afterTickToLabelConversion: function (scaleInstance: any): void {
            // set the first and last tick to null so it does not display
            // note, ticks[0] is the last tick and ticks[length - 1] is the first
            scaleInstance.ticks[0] = scaleInstance.ticks[0] === '0' ? scaleInstance.ticks[0] : null;
            scaleInstance.ticks[scaleInstance.ticks.length - 1] =
              scaleInstance.ticks[scaleInstance.ticks.length - 1] === '0'
                ? scaleInstance.ticks[scaleInstance.ticks.length - 1]
                : null;

            // need to do the same thing for this similiar array which is used internally
            scaleInstance.ticksAsNumbers[0] =
              scaleInstance.ticksAsNumbers[0] === '0' ? scaleInstance.ticksAsNumbers[0] : null;
            scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] =
              scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] === '0'
                ? scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1]
                : null;
          },
        },
      ],
    },
    annotation: {
      annotations: getValueRangeBox(
        props.valueRanges,
        props.chartData,
        props.fromDate,
        props.toDate
      ),
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 20,
          threshold: 10,
          onPanComplete: props.onComplete,
          rangeMin: {
            x: getEarliestDate(props.chartData),
          },
          rangeMax: {
            x: getLatestDate(props.chartData),
          },
        },
        zoom: {
          enabled: true,
          drag: false,
          mode: 'x',
          speed: 0.1,
          threshold: 20,
          sensitivity: 3,
          onZoomComplete: props.onComplete,
          rangeMin: {
            x: getEarliestDate(props.chartData),
          },
          rangeMax: {
            x: getLatestDate(props.chartData),
          },
        },
      },
    },
    animation: {
      duration: 500,
      easing: 'linear',
    },
  };
  return chartOptions;
};

export const StackedBarChartOptions = (props: ChartOptionsProps): ChartOptionsWithAnnotation => {
  const chartOptions: ChartOptionsWithAnnotation = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) =>
          generateTooltipLabel(tooltipItem, data, props.chartData),
        labelColor: generateTooltipColor,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    annotation: {
      annotations: getValueRangeBox(
        props.valueRanges,
        props.chartData,
        props.fromDate,
        props.toDate
      ),
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 20,
          threshold: 10,
          onPanComplete: props.onComplete,
          rangeMin: {
            x: getEarliestDate(props.chartData),
          },
          rangeMax: {
            x: getLatestDate(props.chartData),
          },
        },
        zoom: {
          enabled: true,
          drag: false,
          mode: 'x',
          speed: 0.1,
          threshold: 20,
          sensitivity: 3,
          onZoomComplete: props.onComplete,
          rangeMin: {
            x: getEarliestDate(props.chartData),
          },
          rangeMax: {
            x: getLatestDate(props.chartData),
          },
        },
      },
    },
    animation: {
      duration: 500,
      easing: 'linear',
    },
  };
  return chartOptions;
};

interface ChartOptionsWithAnnotation extends ChartOptions {
  annotation: AnnotationConfig;
}

interface ChartOptionsProps {
  onComplete?: ({ chart }: any) => void;
  statistics: ChartSensorStatistics[];
  chartData: TimeSeriesChartData[];
  valueRanges: EventValueRange[];
  fromDate: Date;
  toDate: Date;
}
