import { Device, DeviceFilterAttributes } from '@thingslog/repositories';
import {
  DataGrid,
  DataGridHeader,
  DataGridProps,
  GridColumnHeaderParams,
} from '@thingslog/ui-components';
import React, { ReactNode, useEffect, useState, FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const DevicesTable: FC<DevicesTableProps> = ({
  devices,
  setFilterValue,
  setAttributes,
  filterValue,
  onFilterRemovedCallback,
  ...props
}: DevicesTableProps) => {
  const { t } = useTranslation();
  const [activeAttribute, setActiveAttribute] = useState<DeviceFilterAttributes | null>(null);

  const [rows, setRows] = useState<DeviceTableRow[]>([]);
  useEffect(() => {
    let rows: DeviceTableRow[] = [];

    devices.forEach((device: Device) => {
      let quarter = '';
      let street = '';
      if (device.customerInfo?.street) {
        street += device.customerInfo.street + ' ';
      }
      if (device.customerInfo?.streetNumber) {
        street += device.customerInfo.streetNumber;
      }
      if (device.customerInfo?.quarter) {
        quarter += device.customerInfo.quarter + ' ';
      }

      rows.push({
        deviceNumber: device.number,
        deviceName: device.name,
        businessPartnerNumber: device.customerInfo?.businessPartnerNumber || '',
        quarter: quarter,
        street: street,
        description: device.description || '',
        id: device.number,
      });
    });
    setRows(rows);
  }, [devices]);

  const handleFilterValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '' || event.target.value === null) {
      onFilterRemovedCallback();
      setActiveAttribute(null);
      setAttributes(null);
      setFilterValue('');
    } else {
      setActiveAttribute(DeviceFilterAttributes[event.target.id]);
      setAttributes(DeviceFilterAttributes[event.target.id]);
      setFilterValue(event.target.value);
    }
  };

  return (
    <DataGrid
      rows={rows}
      columns={[
        {
          field: 'deviceNumber',
          headerName: t('devices_table_device_number'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.deviceNumber}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },

        {
          field: 'deviceName',
          headerName: t('devices_table_device_name'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.deviceName}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'street',
          headerName: t('devices_table_address'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.street}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'businessPartnerNumber',
          headerName: t('devices_table_business_partner'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName!}
                attribute={DeviceFilterAttributes.businessPartnerNumber}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'description',
          headerName: t('devices_table_description'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },

        {
          field: 'quarter',
          headerName: t('devices_table_quarter'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
      ]}
      {...props}
    />
  );
};

interface DevicesTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  devices: Device[];
  setAttributes: (attribute: DeviceFilterAttributes | null) => void;
  setFilterValue: (filterValue: string) => void;
  filterValue: string;
  onFilterRemovedCallback: () => void;
}

export interface DeviceTableRow {
  deviceNumber: string;
  deviceName: string;
  businessPartnerNumber: string;
  quarter: string;
  street: string;
  description: string;
  id: string;
}

export default DevicesTable;
