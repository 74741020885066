import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  DataGridHeader,
  DataGridProps,
  GridColumnHeaderParams,
  GridValueFormatterParams,
} from '@thingslog/ui-components';
import DeviceSignal from '../../../src/model/API/DeviceSignal/DeviceSignal';
import { DeviceFilterAttributes } from '@thingslog/repositories';
import { DateUtil } from '../../common/DateUtil';

const DevicesSignalTable: FC<DevicesSignalTableProps> = ({
  devicesSignalReadings,
  ...props
}: DevicesSignalTableProps) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState<DevicesSignalTableRow[]>([]);
  const [activeAttribute, setActiveAttribute] = useState<DeviceFilterAttributes | null>(null);
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    let rows: DevicesSignalTableRow[] = [];
    devicesSignalReadings.forEach((deviceSignal: DeviceSignal, index: number) => {
      const { deviceNumber, deviceName, date, level, rssi } = deviceSignal;

      rows.push({
        id: deviceNumber,
        deviceNumber: deviceNumber,
        deviceName: deviceName,
        date: date,
        level: level,
        rssi: rssi,
      });
    });

    if (activeAttribute && filterValue) {
      rows = rows.filter((row: DevicesSignalTableRow) => {
        return row[activeAttribute].toLowerCase().includes(filterValue.toLowerCase());
      });
    }

    setRows(rows);
  }, [devicesSignalReadings, activeAttribute, filterValue]);

  const handleFilterValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '' || event.target.value === null) {
      setActiveAttribute(null);
      setFilterValue('');
    } else {
      setActiveAttribute(DeviceFilterAttributes[event.target.id]);
      setFilterValue(event.target.value);
    }
  };

  return (
    <DataGrid
      showPrintOption={false}
      selectAllEnabled={true}
      rows={rows}
      columns={[
        {
          field: 'date',
          headerName: t('date'),
          flex: 1,
          minWidth: 140,
          type: 'date',
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            DateUtil.formatTimeZoneAgnosticDate(params.value),
          sortComparator: (SignalDate1: string, SignalDate2: string) =>
            new Date(SignalDate1).getTime() - new Date(SignalDate2).getTime(),
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName} />;
          },
        },
        {
          field: 'deviceNumber',
          headerName: t('devices_table_device_number'),
          flex: 1,
          minWidth: 170,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={DeviceFilterAttributes.deviceNumber}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'deviceName',
          headerName: t('devices_table_device_name'),
          flex: 1,
          minWidth: 160,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={DeviceFilterAttributes.deviceName}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'rssi',
          headerName: t('rssi'),
          flex: 0.5,
          minWidth: 70,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName} />;
          },
        },
        {
          field: 'level',
          headerName: t('devices_signal_level_percent'),
          flex: 0.5,
          minWidth: 90,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName} />;
          },
        },
      ]}
      {...props}
    />
  );
};

interface DevicesSignalTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  devicesSignalReadings: DeviceSignal[];
}

interface DevicesSignalTableRow {
  id: string;
  deviceNumber: string;
  deviceName: string;
  date: Date;
  level: number;
  rssi: number;
}

export default DevicesSignalTable;
