import React, { FC, useEffect, useMemo, useState, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GridRowId, GridToolbar, GridFeatureMode, GridDensity } from '@mui/x-data-grid';
import * as actions from '../../actions/index';
import { Container, CircularProgress, Grid, Slider } from '@mui/material';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header';
import DeviceBatteryTable from './DeviceBatteryTable';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import { ReduxState } from '../../reducers';
import { devicesBatteryQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { AxiosError } from 'axios';
import { DataGridProps, useToast } from '@thingslog/ui-components';
import { CustomerInfoResponse } from '@thingslog/repositories';
import { DeviceBattery } from '@thingslog/repositories/src/battery/DeviceBattery';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { BatteryStatus } from '@thingslog/repositories/src/battery/BatteryStatus';
import BatteryStatusTable from './BatteryStatusTable';
import { GaEventCategory } from '../../common/GaEventCategory';
import { GaEventAction } from '../../common/GaEventAction';
import GoogleAnalyticsService from '../../common/GoogleAnalyticsService';
import { GridFilterModel, GridFilterItem } from '@thingslog/ui-components/src/DataGrid';
import { GridColumnVisibilityModel } from '@thingslog/ui-components';

const DeviceBatteryPageV2: FC<DeviceBatteryProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  const { deviceNumber: selectedDeviceNumber, fromLevel: fromLevelFromUrl } = useParams();

  const [size, setSize] = useState<number>(10);
  const [fromLevel, setFromLevel] = useState<number>(
    fromLevelFromUrl !== undefined ? Number(fromLevelFromUrl) : 0
  );
  const [toLevel, setToLevel] = useState<number>(
    fromLevelFromUrl !== undefined ? Number(fromLevelFromUrl) + 10 : 100
  );
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoResponse>();
  const [deviceBatteryReadings, setDeviceBatteryReadings] = useState<DeviceBattery[]>([]);
  const [accountBatteryReadings, setAccountBatteryReadings] = useState<BatteryStatus[]>([]);
  const [selectedTableRows, setSelectedTableRows] = useState<GridRowId[]>([]);
  const [deviceColumnVisibilityModel, setDeviceColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [batteryStatusColumnVisibilityModel, setBatteryStatusColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const companyId = useSelector((state: ReduxState) => state.company.id);
  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);
  const toDateTz = useSelector((state: ReduxState) => state.period.toDateTz);
  const fromDateTz = useSelector((state: ReduxState) => state.period.fromDateTz);
  const dataGridFilters: GridFilterItem[] = useSelector(
    (state: ReduxState) => state.dev.dataGridFilters
  );

  const { useBatteryStatus, useDeviceBattery } = useMemo(() => devicesBatteryQueryClient, []);

  const handleError = (error: AxiosError): void => {
    toast({
      type: 'error',
      message: error.response?.data.message,
      duration: 5000,
    });
  };

  const { isInitialLoading: isBatteryStatusLoading } = useBatteryStatus(
    companyId,
    fromLevel,
    toLevel,
    {
      enabled: !selectedDeviceNumber,
      onSuccess: (data: BatteryStatus[]) => {
        setAccountBatteryReadings(data);
      },
      onError: (error: AxiosError) => {
        handleError(error);
      },
    }
  );

  const { isInitialLoading: isDeviceBatteryLoading, refetch: refetchDeviceBattery } =
    useDeviceBattery(selectedDeviceNumber!, fromDateTz, toDateTz, {
      enabled: !!selectedDeviceNumber,
      onSuccess: (data: DeviceBattery[]) => {
        setDeviceBatteryReadings(data);
      },
      onError: (error: AxiosError) => {
        handleError(error);
      },
    });

  useEffect(() => {
    !!selectedDeviceNumber && refetchDeviceBattery();
  }, [toDateTz, fromDateTz]);

  useEffect(() => {
    !selectedDeviceNumber && setCustomerInfo(undefined);
  }, [selectedDeviceNumber]);

  const handleSelectionModelChange = (selectedRowIds: GridRowId[]): void => {
    const selectedDeviceRedux = selectedRowIds.length === 1 ? selectedRowIds : [];
    setSelectedTableRows(selectedRowIds);
    dispatch(actions.devSelected(selectedDeviceRedux, 'radio'));
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.BATTERY_STATUS_PAGE,
      GaEventAction.BATTERY_STATUS_PAGE_DEVICE_NUMBER_CLICK,
      String(selectedDeviceRedux)
    );
  };

  const handleOnFilterModelChange = (model: GridFilterModel): void => {
    if (model.items.some((item: GridFilterItem) => item.value !== '')) {
      dispatch(actions.filterChanged(model.items));
    } else {
      dispatch(actions.filterChanged([]));
    }
  };

  const commonBatteryTableProps: Omit<DataGridProps, 'columns' | 'rows'> = {
    pageSize: size,
    autoHeight: true,
    disableColumnMenu: true,
    rowsPerPageOptions: [10, 25, 50],
    filterMode: 'client' as GridFeatureMode,
    paginationMode: 'client' as GridFeatureMode,
    density: 'compact' as GridDensity,
    components: {
      Toolbar: GridToolbar,
    },
    onPageSizeChange: (pageSize: number): void => {
      setSize(pageSize);
    },
  };

  return (
    <Header>
      <Container>
        <section className="mb-4">
          <TitleHeader
            className="mb-4"
            title={t('device_battery_title')}
            deviceNumber={selectedDeviceNumber && selectedDevice && selectedDevice.number}
            deviceName={selectedDeviceNumber && selectedDevice && selectedDevice.name}
            customerInfo={selectedDeviceNumber && selectedDevice && selectedDevice.customerInfo}
          />
          {!selectedDeviceNumber && (
            <div className="flex justify-end max-md:justify-center">
              <div>
                <BatteryChargingFullIcon />
              </div>
              <div className="w-64 mx-3">
                <Slider
                  min={0}
                  max={100}
                  size="small"
                  valueLabelDisplay="auto"
                  defaultValue={[fromLevel, toLevel]}
                  marks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((mark: number) => {
                    return { value: mark, label: mark };
                  })}
                  onChangeCommitted={(
                    event: Event | SyntheticEvent<Element, Event>,
                    levelRange: number | number[]
                  ): void => {
                    setFromLevel(levelRange[0]), setToLevel(levelRange[1]);
                  }}
                />
              </div>
            </div>
          )}
          {selectedDeviceNumber && (
            <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
              <PeriodPicker alwaysShowDatePickers />
            </Grid>
          )}
        </section>
        {isDeviceBatteryLoading || isBatteryStatusLoading ? (
          <div className="mt-20">
            <CircularProgress size={80} thickness={5} />
          </div>
        ) : selectedDeviceNumber ? (
          <DeviceBatteryTable
            headerHeight={70}
            deviceBatteryReadings={deviceBatteryReadings}
            disableSelectionOnClick
            columnVisibilityModel={deviceColumnVisibilityModel}
            onColumnVisibilityModelChange={(columnModel: GridColumnVisibilityModel): void => {
              setDeviceColumnVisibilityModel(columnModel);
            }}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fileName: `Battery_${selectedDeviceNumber}`,
                },
              },
            }}
            {...commonBatteryTableProps}
          />
        ) : (
          <BatteryStatusTable
            headerHeight={120}
            batteryStatusReadings={accountBatteryReadings}
            checkboxSelection
            selectionModel={selectedTableRows}
            onSelectionModelChange={handleSelectionModelChange}
            filterModel={{ items: dataGridFilters }}
            onFilterModelChange={handleOnFilterModelChange}
            columnVisibilityModel={batteryStatusColumnVisibilityModel}
            onColumnVisibilityModelChange={(columnModel: GridColumnVisibilityModel): void => {
              setBatteryStatusColumnVisibilityModel(columnModel);
            }}
            componentsProps={{
              toolbar: {
                csvOptions: {
                  fileName: 'Battery_export',
                },
              },
            }}
            {...commonBatteryTableProps}
          />
        )}
      </Container>
    </Header>
  );
};

interface DeviceBatteryProps {}

export default DeviceBatteryPageV2;
