import { IconButton } from '@mui/material';
import React, { ChangeEvent, useState, useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import './ColorsPicker.css';

const DEFAULT_COLORS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#4caf50',
  '#ff9800',
  '#ff5722',
  '#795548',
];

const ColorsPicker: React.FC<ColorsPickerProps> = (props: ColorsPickerProps) => {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [isSelectedColorValid, setIsSelectedColorValid] = useState<boolean>(false);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    if (isValidHexColor(props.color)) {
      setSelectedColor(props.color);
    } else {
      setSelectedColor('#ffffff');
    }
    setIsSelectedColorValid(true);
  }, [props.color]);

  useEffect(() => {
    if (props.colors === undefined) {
      setColors(DEFAULT_COLORS);
    } else {
      let colors: string[] = [];
      props.colors?.forEach((color: string) => {
        if (isValidHexColor(color)) {
          colors.push(color);
        }
      });
      setColors(colors);
    }
  }, [props.colors]);

  const isValidHexColor = (hexString: string): boolean => {
    const hexColorRegex = /^#([A-Fa-f0-9]{6})$/;
    return hexColorRegex.test(hexString);
  };

  const handleSubmitColor = (color: string): void => {
    props.onColorChange(color);
    props.setIsColorPickerOpen(!props.isColorPickerOpen);
  };

  if (!props.isColorPickerOpen) {
    return (
      <>
        <span style={{ color: '#393D47', fontWeight: 'bold' }}>
          {t('device_groups_color_picker_label')}
        </span>
        <IconButton
          onClick={(): void => {
            props.setIsColorPickerOpen(!props.isColorPickerOpen);
          }}
        >
          <div
            style={{
              backgroundColor: props.color,
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          />
        </IconButton>
      </>
    );
  }

  if (props.isColorPickerOpen) {
    return (
      <div className="flex">
        <div>
          <div className="grid-container">
            {colors.map((hex: string) => (
              <div
                className="grid-item"
                onClick={(): void => {
                  setSelectedColor(hex);
                  const isValid = isValidHexColor(hex);
                  setIsSelectedColorValid(isValid);
                }}
                style={{
                  border: hex === selectedColor ? '3px solid black' : '',
                  backgroundColor: hex,
                }}
              />
            ))}
          </div>
          <div>
            <input
              value={selectedColor}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                e.preventDefault();
                setSelectedColor(e.target.value);
                const isValid = isValidHexColor(e.target.value);
                setIsSelectedColorValid(isValid);
              }}
            />

            <button
              className="color-picker-submit-btn"
              style={{
                backgroundColor: !isSelectedColorValid ? 'gray' : selectedColor,
              }}
              disabled={!isSelectedColorValid}
              onClick={(): void => {
                handleSubmitColor(selectedColor);
              }}
            >
              <DoneIcon />
            </button>
          </div>
        </div>
        <div>
          <IconButton
            onClick={(): void => {
              props.setIsColorPickerOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  }

  return <></>;
};

interface ColorsPickerProps {
  colors?: string[];
  color: string;
  onColorChange: (newColor: string) => void;
  isColorPickerOpen: boolean;
  setIsColorPickerOpen: (open: boolean) => void;
}

export default ColorsPicker;
