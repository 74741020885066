import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PickerPeriod } from '../../model/Pickers/PickerPeriod';
import { ReduxState } from '../../reducers';
import * as periodActionsCreators from '../../state_management/actions/PeriodSelectionActionCreator';
import { addDays, isAfter, isBefore, isValid, subDays } from 'date-fns';
import { LocalizedDatePicker } from '@thingslog/ui-components';
import { MUI_LIMIT_DATE_PICKER_MAX_DATE, MUI_LIMIT_DATE_PICKER_MIN_DATE } from './DatePickerLimits';

const ConnectedDatePicker: FC<ConnectedDatePickerProps> = ({
  period,
  dateDifference,
}: ConnectedDatePickerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeFromDate, changeToDate } = bindActionCreators(periodActionsCreators, dispatch);

  const toDateRedux = useSelector((state: ReduxState) => state.period.toDate);
  const fromDateRedux = useSelector((state: ReduxState) => state.period.fromDate);

  const [fromDate, setFromDate] = useState<Date | null>(fromDateRedux);
  const [toDate, setToDate] = useState<Date | null>(toDateRedux);
  const [minFromDate, setMinFromDate] = useState<Date | undefined>();
  const [maxToDate, setMaxToDate] = useState<Date | undefined>();

  useEffect(() => {
    setMinFromDate(dateDifference ? subDays(toDateRedux, dateDifference) : undefined);
  }, [toDateRedux]);

  useEffect(() => {
    setMaxToDate(dateDifference ? addDays(fromDateRedux, dateDifference) : undefined);
  }, [fromDateRedux]);

  useEffect(() => {
    if (fromDate === null || !isValid(fromDate)) return;
    if (isAfter(fromDate, toDateRedux)) return;
    if (minFromDate && isBefore(fromDate, minFromDate)) return;
    if (isBefore(fromDate, MUI_LIMIT_DATE_PICKER_MIN_DATE)) return;

    changeFromDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    if (toDate === null || !isValid(toDate)) return;
    if (isBefore(toDate, fromDateRedux)) return;
    if (maxToDate && isAfter(toDate, maxToDate)) return;
    if (isAfter(toDate, MUI_LIMIT_DATE_PICKER_MAX_DATE)) return;

    changeToDate(toDate);
  }, [toDate]);

  if (period === PickerPeriod.FROM) {
    return (
      <LocalizedDatePicker
        label={t('from_date')}
        value={fromDate}
        onChange={(date: null | Date): void => {
          setFromDate(date);
        }}
        minDate={minFromDate}
        maxDate={toDateRedux}
        inputFormat="dd/MM/yyyy"
        renderInputTextField={{
          size: 'small',
          className: 'w-[200px] max-md:w-full',
          onBlur: (): void => {
            fromDate !== fromDateRedux && setFromDate(fromDateRedux);
          },
        }}
      />
    );
  } else if (period === PickerPeriod.TO) {
    return (
      <LocalizedDatePicker
        label={t('to_date')}
        value={toDate}
        onChange={(date: null | Date): void => {
          setToDate(date);
        }}
        minDate={fromDateRedux}
        maxDate={maxToDate}
        inputFormat="dd/MM/yyyy"
        renderInputTextField={{
          size: 'small',
          className: 'w-[200px] max-md:w-full',
          onBlur: (): void => {
            toDate !== toDateRedux && setToDate(toDateRedux);
          },
        }}
      />
    );
  }
  return <></>;
};

interface ConnectedDatePickerProps {
  period: PickerPeriod;
  dateDifference?: number;
}

export default ConnectedDatePicker;
