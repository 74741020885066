import { AxiosPromise, AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import moment from 'moment';

export async function postSwitchRelay(
  deviceNumber: string,
  sensorIndex: number,
  switchState: string
): Promise<void> {
  return await Axios.post(
    `/api/v2/devices/${deviceNumber}/${sensorIndex}/relay-switch?relaySwitchState=${switchState}`,
    null
  ).then(parseJSON);
}

export async function getSwitchRelay(deviceNumber: string, sensorIndex: number): Promise<string> {
  return await Axios.get(`/api/v2/devices/${deviceNumber}/${sensorIndex}/relay-switch`).then(
    parseJSON
  );
}

function parseJSON(response: AxiosResponse): any {
  return response.data;
}
