import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryColorTableCell from '../../../components/PrimaryColorTableCell/PrimaryColorTableCell';
import Histogram from '../../../model/API/Histogram/Histogram';
import HistogramTableRow from '../model/HistogramTableRow';

const QFlowTable: React.FC<QFlowTableProps> = ({ qFlow, loading, error }: QFlowTableProps) => {
  const [tableItems, setTableItems] = useState<HistogramTableRow[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (qFlow) {
      let tableItems: HistogramTableRow[] = [];
      const total = qFlow.total;
      const nbins = qFlow.nbins;
      for (let i = 0; i < nbins - 1; i++) {
        const data = qFlow.values[i];
        const rangeLowerBound = i > 0 ? `Q${i}` : '0';
        const rangeUpperBound = i + 1 < nbins - 1 ? `Q${i + 1}` : 'Infinity';
        const range = `${rangeLowerBound} - ${rangeUpperBound}`;
        const value = +data.toFixed(2);
        const percent = total > 0 ? +((data / total) * 100).toFixed(2) : 0;

        tableItems.push({ range, value, percent });
      }
      setTableItems(tableItems);
    } else {
      setTableItems([]);
    }
  }, [qFlow]);

  const renderContent = (): JSX.Element => {
    if (qFlow && tableItems.length > 0) {
      return (
        <TableBody>
          {tableItems.map((item: HistogramTableRow) => (
            <TableRow hover>
              <TableCell align="center">
                <Box fontWeight="fontWeightMedium">{item.range}</Box>
              </TableCell>
              <TableCell align="center">
                {item.value} m<sup>3</sup>
              </TableCell>
              <TableCell align="center">{item.percent} %</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }

    return (
      <TableBody>
        <TableRow hover>
          <TableCell colSpan={3} align="center">
            <Typography variant="subtitle1" color="gray" paddingTop={8} paddingBottom={8}>
              {error || t('no_data_found')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <PrimaryColorTableCell align="center">{t('flow')}</PrimaryColorTableCell>
            <PrimaryColorTableCell align="center">
              m<sup>3</sup>
            </PrimaryColorTableCell>
            <PrimaryColorTableCell align="center">{t('percent')}</PrimaryColorTableCell>
          </TableRow>
        </TableHead>
        {!loading && renderContent()}
      </Table>
      {loading && <LinearProgress color="primary" />}
    </TableContainer>
  );
};

interface QFlowTableProps {
  qFlow: Histogram | null;
  loading: boolean;
  error: string | null;
}

export default QFlowTable;
