import { Theme } from '@mui/material';

import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    chartOptionsWrapper: {
      marginTop: 8,
      marginRight: 12,
      marginLeft: 12,
      marginBottom: 8,
    },
    verticalDivider: {
      borderLeft: '1px solid #c7c7c7',
      height: 25,
      marginTop: 5,
      marginBottom: 5,
    },
    formGroup: {
      marginRight: 10,
    },
    statusContainer: {
      minHeight: 250,
    },
  });
