import React, { FC, ReactNode, useRef, useState, useEffect, useCallback } from 'react';
import ModalContext from './ModalContext';
import CloseIcon from '@mui/icons-material/Close';
import ModalProps from './model/ModalProps';

const ModalProvider: FC<ModalProviderProps> = ({ children }: ModalProviderProps) => {
  const [curr, setCurr] = useState<ModalProps | null>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleEscPress = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeModal();
    }
  }, []);

  useEffect(() => {
    if (curr !== null) {
      document.addEventListener('keydown', handleEscPress);
    } else {
      document.removeEventListener('keydown', handleEscPress);
    }
  }, [curr]);

  const modal = (modal: ModalProps): void => {
    setCurr(modal);
  };

  const closeModal = (): void => {
    modalRef?.current?.classList.remove('animate-modalIn');
    backgroundRef?.current?.classList.remove('animate-modalBackgroundIn');

    modalRef?.current?.classList.add('animate-modalOut');
    backgroundRef?.current?.classList.add('animate-modalBackgroundOut');

    //remove element after animation is done
    modalRef?.current?.addEventListener('animationend', () => {
      // lets remove it
      setCurr(null);
    });
  };

  return (
    <ModalContext.Provider value={{ modal, closeModal }}>
      <>
        {curr?.content && (
          <>
            <div
              ref={modalRef}
              className="fixed z-50 flex h-full w-full animate-modalIn items-center justify-center "
            >
              <div className="m-2 w-full rounded-md bg-gray-200 px-4 py-2 opacity-100 shadow-sm md:w-auto bg-white">
                <div className="flex items-center justify-between">
                  <div className="text-2xl font-extrabold text-gray-700 md:text-lg">
                    {curr?.title && curr.title}
                  </div>
                  <div>
                    <CloseIcon
                      className="h-7 w-7 text-gray-500 transition-all duration-500 hover:text-red-600 md:h-6 md:w-6"
                      onClick={closeModal}
                    />
                  </div>
                </div>
                <div className="p-1">{curr.content}</div>
              </div>
            </div>
            <div
              ref={backgroundRef}
              className="fixed z-30 h-screen w-screen animate-modalBackgroundIn bg-black opacity-50"
            ></div>
          </>
        )}
        {children}
      </>
    </ModalContext.Provider>
  );
};

interface ModalProviderProps {
  children: ReactNode;
}

export default ModalProvider;
