import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import deviceManagerClient from './DeviceManagerClient';
import { FormGroup, ControlLabel, FormControl, Alert } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';

import { withRouter } from './common/withRouter';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';

class DeviceManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      open: false,
      buttonsDisabled: false,
      deviceNumber: '',
      hwVersion: '',
      swVersion: '',
      description: '',
      model: 'LPMDL_1101',
      swVersionIsValid: true,
      hwVersionIsValid: true,
    };
    this.handleCreateDevice = this.handleCreateDevice.bind(this);
    this.handleDeleteDevice = this.handleDeleteDevice.bind(this);
    this.handleDeviceCreated = this.handleDeviceCreated.bind(this);
    this.handleDeviceDeleted = this.handleDeviceDeleted.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleDeviceNumberChange = this.handleDeviceNumberChange.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleHwVersionChange = this.handleHwVersionChange.bind(this);
    this.handleSwVersionChange = this.handleSwVersionChange.bind(this);
    this.handleDeviceDescriptionChange = this.handleDeviceDescriptionChange.bind(this);
    this.handleSwVersionValidation = this.handleSwVersionValidation.bind(this);
    this.handleHwVersionValidation = this.handleHwVersionValidation.bind(this);
  }

  handleSwVersionValidation(ev) {
    const regex = new RegExp(/^(\d{1,2}\.\d{1,2}\.\d{1,2})(-.+|_.+|)$/);
    const input = ev.target.value;
    if (regex.test(input) === false) {
      this.setState({ swVersionIsValid: false, buttonsDisabled: true });
    } else if (regex.test(input) === true) {
      this.setState({ swVersionIsValid: true, buttonsDisabled: false });
    }
  }

  handleHwVersionValidation(ev) {
    const regex = new RegExp(/^([1-9][0-9]*)(?:\.([0-9]{1,2})){2}$/);
    const input = ev.target.value;
    if (regex.test(input) === false) {
      this.setState({ hwVersionIsValid: false, buttonsDisabled: true });
    } else if (regex.test(input) === true) {
      this.setState({ hwVersionIsValid: true, buttonsDisabled: false });
    }
  }

  errorCallback(error) {
    this.setState({
      message:
        'Error: ' + error.response.data.message || error.response.data.error || error.response.data,
      open: true,
      buttonsDisabled: false,
    });
  }

  handleDeviceNumberChange = (ev) => this.setState({ deviceNumber: ev.target.value });
  handleHwVersionChange = (ev) => this.setState({ hwVersion: ev.target.value });
  handleSwVersionChange = (ev) => this.setState({ swVersion: ev.target.value });
  handleDeviceDescriptionChange = (ev) => this.setState({ description: ev.target.value });

  handleCreateDevice(ev) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.DEVICE_MANAGER_PAGE,
      GaEventAction.DEVICE_MANAGER_CREATE_DEVICE
    );
    this.setState({
      open: true,
      message: 'Creating device: ' + this.state.deviceNumber,
      buttonsDisabled: true,
    });
    deviceManagerClient.postDevice(
      {
        number: this.state.deviceNumber,
        model: this.state.model,
        swVersion: this.state.swVersion,
        hwVersion: this.state.hwVersion,
        description: this.state.description,
        active: true,
      },
      this.handleDeviceCreated,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleDeviceCreated() {
    this.setState({ message: 'Device created successful', open: true, buttonsDisabled: false });
    this.props.devSelected([this.state.deviceNumber], 'radio');
  }

  handleDeviceDeleted() {
    this.setState({ message: 'Device deleted successful', open: true, buttonsDisabled: false });
  }

  handleModelChange = (ev) => this.setState({ model: ev.target.value });

  handleDeleteDevice(ev) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.DEVICE_MANAGER_PAGE,
      GaEventAction.DEVICE_MANAGER_DELETE_DEVICE
    );
    this.setState({
      open: true,
      message: 'Deleting device: ' + this.state.deviceNumber,
      buttonsDisabled: true,
    });
    deviceManagerClient.deleteDevice(
      this.state.deviceNumber,
      this.handleDeviceDeleted,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    return (
      <Header>
        <div className="well" style={wellStyles}>
          <p>
            {this.props.t('device_manager_create_or_delete_device', {
              device_number: this.props.match.params.deviceNumber,
            })}
          </p>
          <form>
            <FormGroup controlId="Device Number">
              <ControlLabel>{this.props.t('device_manager_device_number')}</ControlLabel>
              <FormControl
                value={this.state.deviceNumber}
                type="text"
                placeholder={this.props.t('number')}
                onChange={this.handleDeviceNumberChange}
              />
            </FormGroup>
            <FormGroup controlId="Software version">
              <FormControl
                value={this.state.swVersion}
                type="text"
                placeholder={this.props.t('device_manager_software_version')}
                onChange={this.handleSwVersionChange.bind(this)}
                onInput={this.handleSwVersionValidation}
              ></FormControl>
              {!this.state.swVersionIsValid && (
                <Alert variant="danger">
                  {this.props.t('device_manager_sw_version_validation')}
                </Alert>
              )}
            </FormGroup>
            <FormGroup controlId="Hardware version">
              <FormControl
                value={this.state.hwVersion}
                type="text"
                placeholder={this.props.t('device_manager_hardware_version')}
                onChange={this.handleHwVersionChange.bind(this)}
                onInput={this.handleHwVersionValidation}
              ></FormControl>
              {!this.state.hwVersionIsValid && (
                <Alert variant="danger">
                  {this.props.t('device_manager_hw_version_validation')}
                </Alert>
              )}
            </FormGroup>
            <FormGroup controlId="model">
              <ControlLabel>{this.props.t('device_manager_device_model')}</ControlLabel>
              <FormControl
                value={this.state.model}
                componentClass="select"
                placeholder="select model"
                onChange={this.handleModelChange.bind(this)}
              >
                <option value={'LPMDL_1101'}>LPMDL 1101</option>
                <option value={'LPMDL_1102'}>LPMDL 1102</option>
                <option value={'LPMDL_1103'}>LPMDL 1103</option>
                <option value={'LPMDL_1104'}>LPMDL 1104</option>
                <option value={'LPMDL_1105'}>LPMDL 1105</option>
                <option value={'LPMDL_1106'}>LPMDL 1106</option>
                <option value={'BL_100_L'}>BL 100 L</option>
                <option value={'W1'}>W1</option>
                <option value={'ZENNER_EDC'}>ZENNER EDC</option>
                <option value={'ZENNER_MICRO'}>ZENNER MICRO</option>
                <option value={'LPMDL_1103_OPENLORA'}>LPMDL 1103 OPENLORA</option>
                <option value={'INNOTAS_WATERMETER'}>INNOTAS WATERMETER</option>
                <option value={'INNOTAS_HCA'}>INNOTAS HCA</option>
                <option value={'MANUAL_WATER_METER'}>MANUAL WATER METER</option>
                <option value={'MANUAL_ENERGY_METER'}>MANUAL ENERGY METER</option>
                <option value={'MANUAL_HEAT_METER'}>MANUAL HEAT METER</option>
                <option value={'MANUAL_GAS_METER'}>MANUAL GAS METER</option>
                <option value={'TLCTL_21A4S'}>TLCTL 21A4S</option>
                <option value={'FINECO_EM735'}>FINECO EM735</option>
                <option value={'FINECO_EM4374'}>FINECO EM4374</option>
              </FormControl>
            </FormGroup>
            <FormGroup controlId="Description">
              <ControlLabel>{this.props.t('description')}</ControlLabel>
              <FormControl
                value={this.state.description}
                type="text"
                placeholder={this.props.t('description')}
                onChange={this.handleDeviceDescriptionChange}
              />
            </FormGroup>
            <Button onClick={this.handleCreateDevice} disabled={this.state.buttonsDisabled}>
              {this.props.t('button_create')}
            </Button>
            <Button onClick={this.handleDeleteDevice} disabled={this.state.buttonsDisabled}>
              {this.props.t('button_delete')}
            </Button>
            <Snackbar
              open={this.state.open}
              message={this.state.message}
              autoHideDuration={4000}
              onClose={this.handleRequestClose}
            />
          </form>
        </div>
      </Header>
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(DeviceManager)));
