import React, { FC, ReactElement, SyntheticEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { stringOrNull } from '../../utils/SetValueAsUtil';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { LoRaAppMode, LoRaConfigModel } from '@thingslog/repositories';

const LoRaNetworkConfiguration: FC<LoRaNetworkConfigurationProps> = () => {
  const [showABPKey, setShowABPKey] = useState<boolean>(false);
  const [showOTAAKey, setShowOTAAKey] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <ConnectForm<LoRaConfigModel>>
      {({ register, control }: UseFormReturn<LoRaConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 gap-5">
          <FormControl size="small">
            <InputLabel>{t('device_initial_config_ABP_appskey')}</InputLabel>
            <OutlinedInput
              {...register('appConfig.appkey', {
                setValueAs: stringOrNull,
              })}
              label={t('device_initial_config_ABP_appskey')}
              type={showABPKey ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={(): void => {
                      setShowABPKey(!showABPKey);
                    }}
                    onMouseDown={(e: SyntheticEvent): void => e.preventDefault()}
                    edge="end"
                  >
                    {showABPKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <TextField
            {...register('appCollect.devEui', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_ABP_deveui')}
            size="small"
          />

          <TextField
            {...register('appCollect.nwkskey', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_ABP_nwkskey')}
            size="small"
          />

          <TextField
            {...register('appCollect.devaddr', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_ABP_devaddr')}
            size="small"
          />

          <FormControl
            {...register('appConfig.appkey', {
              setValueAs: stringOrNull,
            })}
            size="small"
          >
            <InputLabel>
              {t('device_initial_config_OTAA_appkey', {
                setValueAs: stringOrNull,
              })}
            </InputLabel>
            <OutlinedInput
              label={t('device_initial_config_OTAA_appkey', {
                setValueAs: stringOrNull,
              })}
              type={showOTAAKey ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={(): void => {
                      setShowOTAAKey(!showOTAAKey);
                    }}
                    onMouseDown={(e: SyntheticEvent): void => e.preventDefault()}
                    edge="end"
                  >
                    {showOTAAKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <TextField
            {...register('appConfig.deveui', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_OTAA_deveui')}
            size="small"
          />

          <TextField
            {...register('appConfig.appeui', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_OTAA_appeui')}
            size="small"
          />

          <FormControl {...register('mode')} size="small">
            <InputLabel>{t('device_initial_config_lora_app_mode')}</InputLabel>
            <Controller
              control={control}
              name="mode"
              render={({ field }: UseControllerReturn<LoRaConfigModel, 'mode'>): ReactElement => (
                <Select<LoRaAppMode> {...field} label={t('device_initial_config_lora_app_mode')}>
                  <MenuItem value="OTAA">OTAA</MenuItem>
                  <MenuItem value="ABP">ABP</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <TextField {...register('adr')} label="adr" size="small" />
          <TextField {...register('dr')} label="dr" size="small" />
          <TextField {...register('freq')} label="freq" size="small" />
          <TextField {...register('rx2Chan')} label="rx2Chan" size="small" />
          <TextField {...register('rx2Freq')} label="rx2Freq" size="small" />

          <TextField
            {...register('platformName', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_platform_name')}
            size="small"
          />

          <TextField
            {...register('downlinkApiUrl', {
              setValueAs: stringOrNull,
            })}
            label="Downlink API Url"
            size="small"
          />

          <TextField
            {...register('downlinkApiKey', {
              setValueAs: stringOrNull,
            })}
            label="Downlink API Key"
            size="small"
          />

          <TextField
            {...register('appId')}
            label={t('device_initial_config_app_id')}
            size="small"
          />

          <TextField
            {...register('profileId', {
              setValueAs: stringOrNull,
            })}
            label={t('device_initial_config_profile_id')}
            size="small"
          />
        </div>
      )}
    </ConnectForm>
  );
};

interface LoRaNetworkConfigurationProps {}

export default LoRaNetworkConfiguration;
