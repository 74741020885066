import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  LinearProgress,
  Box,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryColorTableCell from '../../../components/PrimaryColorTableCell/PrimaryColorTableCell';
import Histogram from '../../../model/API/Histogram/Histogram';
import HistogramTableRow from '../model/HistogramTableRow';

const FlowTable: React.FC<FlowTableProps> = ({ flow, loading, error }: FlowTableProps) => {
  const [tableItems, setTableItems] = useState<HistogramTableRow[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (flow) {
      let tableItems: HistogramTableRow[] = [];
      const nbins = flow.nbins;
      const total = flow.total;
      for (let i = 0; i < nbins - 1; i++) {
        const data = flow.values[i];
        const rangeLowerBound = flow.edges[i];
        const rangeUpperBound = flow.edges[i + 1];
        const range = `${rangeLowerBound} - ${rangeUpperBound}`;
        const value = +data.toFixed(2);
        const percent = total > 0 ? +((data / total) * 100).toFixed(2) : 0;

        tableItems.push({ range, value, percent });
      }
      setTableItems(tableItems);
    } else {
      setTableItems([]);
    }
  }, [flow]);

  const renderContent = (): JSX.Element => {
    if (flow && tableItems.length > 0) {
      return (
        <TableBody>
          {tableItems.map((item: HistogramTableRow) => (
            <TableRow hover>
              <TableCell align="center">
                <Box fontWeight="fontWeightMedium">{item.range}</Box>
              </TableCell>
              <TableCell align="center">
                {item.value} m<sup>3</sup>
              </TableCell>
              <TableCell align="center">{item.percent} %</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow hover>
          <TableCell colSpan={3} align="center">
            <Typography variant="subtitle1" color="gray" paddingTop={8} paddingBottom={8}>
              {error || t('no_data_found')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <PrimaryColorTableCell align="center">
              {t('flow')}[m<sup>3</sup>/h]
            </PrimaryColorTableCell>
            <PrimaryColorTableCell align="center">
              m<sup>3</sup>
            </PrimaryColorTableCell>
            <PrimaryColorTableCell align="center">{t('percent')}</PrimaryColorTableCell>
          </TableRow>
        </TableHead>
        {!loading && renderContent()}
      </Table>
      {loading && <LinearProgress color="primary" />}
    </TableContainer>
  );
};

interface FlowTableProps {
  flow: Histogram | null;
  loading: boolean;
  error: string | null;
}

export default FlowTable;
