import { LicenseType } from '@thingslog/repositories';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PieChart from '@thingslog/ui-components/src/Charts/PieChart';

const ReadingsPieChart: FC<ReadingsPieChartProps> = ({
  usedCount,
  leftCount,
  licenseType,
}: ReadingsPieChartProps) => {
  const { t } = useTranslation();

  const translateUsed = (): string => {
    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        return t('license_info_used_devices');
      case LicenseType.PER_READING:
        return t('license_info_used_readings');
      default:
        return '';
    }
  };

  const translateRemaining = (): string => {
    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        return t('license_info_devices_left');
      case LicenseType.PER_READING:
        return t('license_info_readings_left');
      default:
        return '';
    }
  };

  const data = [
    { name: translateUsed(), value: usedCount },
    { name: translateRemaining(), value: leftCount },
  ];

  const cellColors = ['#388E3C', '#FFA000'];

  const pieProps = {
    data: data,
    dataKey: 'value',
    cx: '50%',
    cy: '50%',
    outerRadius: 80,
    fill: '#8884d8',
    label: (entry: { name: string; value: number }) => `${entry.name}: ${entry.value}`,
  };

  const containerProps = {
    width: '100%',
    height: 360,
  };

  const legendProps = {};

  const cellProps = cellColors.map((color: string, index: number) => ({
    fill: color,
  }));

  return (
    <PieChart container={containerProps} pie={pieProps} legend={legendProps} cells={cellProps} />
  );
};

interface ReadingsPieChartProps {
  usedCount: number;
  leftCount: number;
  licenseType: LicenseType;
}

export default ReadingsPieChart;
