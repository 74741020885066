import { LicenseDto } from '@thingslog/repositories';
import React, { useMemo, useState } from 'react';
import { licenseQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import Header from '../../components/header';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { LicenseWarningBox } from '@thingslog/ui-components';

const LicenseError: React.FC<LicenseErrorProps> = (props: LicenseErrorProps) => {
  const { t } = useTranslation();
  const { useLicenses } = useMemo(() => licenseQueryClient, []);

  const [foundInactiveLicense, setFoundInactiveLicense] = useState<boolean>(false);
  const [invalidLicenses, setInvalidLicenses] = useState<boolean>(false);

  const companyId = useSelector((state: ReduxState) => state.company.id);

  const { isLoading } = useLicenses(Number(companyId), undefined, undefined, {
    onSuccess: (data: LicenseDto[]) => {
      let hasInactiveLicense = data.some((license: LicenseDto) => !license.isActive);
      let hasAtleastOneValidLicense = data.some((license: LicenseDto) => license.isValid);
      setFoundInactiveLicense(hasInactiveLicense);
      setInvalidLicenses(!hasAtleastOneValidLicense);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <Header>
      {isLoading ? (
        <CircularProgress size={80} />
      ) : (
        <div>
          <h1 className="text-gray-900 font-bold">{t('license_error_page_access_restricted')}</h1>
          <div className="text-justify flex flex-col justify-center items-center ">
            <LicenseWarningBox
              warningStatus={{
                noActiveLicense: foundInactiveLicense,
                noValidLicense: invalidLicenses,
                devicesCountLimitReached: false,
              }}
              translation={{
                accessOnHoldTitle: t('license_warning_access_hold'),
                accessOnHoldDescription: t('license_warning_access_hold_description'),
                noValidLicenseTitle: t('license_warning_no_valid_license'),
                noValidLicenseDescription: t('license_warning_no_valid_license_description'),
                deviceLimitReachedTitle: t('license_warning_devices_count_limit_reached'),
                deviceLimitReachedDescription: t(
                  'license_warning_devices_count_limit_reached_description'
                ),
                defaultGenericTitle: t('license_warning_generic_title'),
                defaultGenericDescription: t('license_warning_generic_description'),
              }}
            />
          </div>
        </div>
      )}
    </Header>
  );
};

interface LicenseErrorProps {}

export default LicenseError;
