import {
  DataGrid,
  DataGridProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@thingslog/ui-components';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import {
  Currency,
  DisplayUnit,
  Unit,
  UtilityRateBareboneDto,
  UtilityRateCurrency,
} from '@thingslog/repositories';
import { DateUtil } from '../../common/DateUtil';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Article';
import JwtValidator from '../../common/JwtValidator';
import { useTranslation } from 'react-i18next';

const UtilityRateTable: FC<UtilityRateTableProps> = ({
  utilityRates,
  onInspect,
  onEdit,
  onDelete,
  ...props
}: UtilityRateTableProps) => {
  const [rows, setRows] = useState<UtilityRateTableRow[]>([]);
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const { t } = useTranslation();

  useEffect(() => {
    if (utilityRates) {
      let rows: UtilityRateTableRow[] = [];
      utilityRates.forEach((utilityRate: UtilityRateBareboneDto) => {
        rows.push({
          id: utilityRate.id,
          name: utilityRate.name,
          date: new Date(utilityRate.lastUpdateDate),
          price: utilityRate.price,
          currency: utilityRate.utilityRateCurrency,
          unit: utilityRate.unit,
          private: utilityRate.private,
          actions: (
            <span>
              <Tooltip title={t('util_rates_tooltip_inspect') || 'Inspect'}>
                <IconButton
                  size="small"
                  onClick={(): void => {
                    onInspect && onInspect(utilityRate);
                  }}
                >
                  <ViewIcon fontSize="medium" color="action" />
                </IconButton>
              </Tooltip>
              {(jwtValidator.hasRole('ROLE_SUPER_ADMIN') || utilityRate.private) && (
                <>
                  <Tooltip title={t('update') || 'Update'}>
                    <IconButton
                      size="small"
                      onClick={(): void => {
                        onEdit && onEdit(utilityRate);
                      }}
                    >
                      <EditIcon fontSize="medium" color="action" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('delete') || 'Delete'}>
                    <IconButton
                      size="small"
                      onClick={(): void => {
                        onDelete && onDelete(utilityRate);
                      }}
                    >
                      <DeleteIcon fontSize="medium" color="error" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </span>
          ),
        });
      });
      setRows(rows);
    }
  }, [utilityRates]);

  return (
    <DataGrid
      showPrintOption={false}
      selectAllEnabled={true}
      rows={rows}
      columns={[
        {
          field: 'name',
          headerName: t('util_rates_name'),
          headerAlign: 'left',
          flex: 1,
          minWidth: 100,
          renderCell: (params: GridRenderCellParams<string>): ReactNode => {
            return (
              <Tooltip title={params.value || ''}>
                <span className="cursor-pointer">{params.value}</span>
              </Tooltip>
            );
          },
        },
        {
          field: 'price',
          headerName: t('util_rates_price'),
          headerAlign: 'left',
          minWidth: 50,
        },
        {
          field: 'currency',
          headerName: t('util_rates_currency'),
          headerAlign: 'left',
          minWidth: 120,
        },
        {
          field: 'unit',
          headerName: t('util_rates_unit'),
          headerAlign: 'left',
          minWidth: 50,
          renderCell: (params: GridRenderCellParams<Unit>): ReactNode => {
            return <span className="cursor-pointer">{DisplayUnit[params.value!]}</span>;
          },
        },
        {
          field: 'private',
          headerName: t('util_rates_private'),
          headerAlign: 'left',
          minWidth: 120,
          renderCell: (params: GridRenderCellParams<boolean>): ReactNode => {
            return <span className="cursor-pointer">{params.value ? 'Yes' : 'No'}</span>;
          },
        },
        {
          field: 'date',
          headerName: t('util_rates_updated'),
          headerAlign: 'left',
          minWidth: 150,
          type: 'date',
          sortComparator: (date1: Date, date2: Date) => date1.getTime() - date2.getTime(),
          renderCell: (params: GridRenderCellParams<Date>): ReactNode => {
            const date = params.value;
            if (date) {
              return (
                <span className="cursor-pointer">
                  {DateUtil.formatTimeZoneAgnosticDate(date.toString())}
                </span>
              );
            } else {
              return <></>;
            }
          },
        },
        {
          field: 'actions',
          headerName: t('util_rates_actions'),
          headerAlign: 'left',
          sortable: false,
          filterable: false,
          renderCell: (params: GridRenderCellParams<ReactNode>): ReactNode => {
            return params.value;
          },
        },
      ]}
      {...props}
    />
  );
};

interface UtilityRateTableRow {
  id: number;
  name: string;
  price: number;
  currency: UtilityRateCurrency;
  unit: Unit;
  private: boolean;
  date: Date;
  actions?: ReactNode;
}

interface UtilityRateTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  utilityRates: UtilityRateBareboneDto[];
  onInspect?: (utilityRate: UtilityRateBareboneDto) => void;
  onEdit?: (utilityRate: UtilityRateBareboneDto) => void;
  onDelete?: (utilityRate: UtilityRateBareboneDto) => void;
}

export default UtilityRateTable;
