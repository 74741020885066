import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceInitialConfigRepository from '../clients/DeviceInitialConfig/DeviceInitialConfigRepository';
import { Port } from '../types/Port';

const useFetchDevicePortsConfig = (number?: string): UseFetchDevicePortsConfigProps => {
  const [portsConfig, setPortsConfig] = useState<{
    [index: number]: Port;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const deviceInitialConfigRepository = new DeviceInitialConfigRepository();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const portsConfig = await deviceInitialConfigRepository.getDevicePortsConfig(number!);
        setPortsConfig(portsConfig);
      } catch {
        setPortsConfig(null);
        setError(t('error_cannot_fetch_ports_config'));
      }
    };
    number && fetchData();
  }, [number]);

  return { portsConfig: portsConfig, portsConfigLoading: loading, portsConfigError: error };
};

interface UseFetchDevicePortsConfigProps {
  portsConfig: {
    [index: number]: Port;
  } | null;
  portsConfigError: string | null;
  portsConfigLoading: boolean;
}

export default useFetchDevicePortsConfig;
