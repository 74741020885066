import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import moment from 'moment';
import { TimeSeriesData } from '../../model/TimeSeriesData/TimeSeriesData';

export async function getSensorFlowAsTimeSeriesData(
  deviceNumbers: string,
  sensorIndex: number,
  startDate: string,
  endDate: string,
  every: number,
  multiply?: number
): Promise<TimeSeriesData[]> {
  return await Axios.get(`/api/devices/${deviceNumbers}/${sensorIndex}/flows`, {
    params: { fromDate: startDate, toDate: endDate, every: every, multiply: multiply },
  }).then((response: AxiosResponse) => {
    return convertSensorFlowToTimeSeriesData(response);
  });
}

export async function getSensorFlowAsTimeSeriesDataAvg(
  deviceNumbers: string,
  sensorIndex: number,
  avgStartDate: string,
  avgEndDate: string,
  startDate: string,
  endDate: string,
  every: number,
  multiply?: number
): Promise<TimeSeriesData[]> {
  // TODO: Already passing start/endDate as ISO string. Make a function to get
  //       the date and NOT the time from the ISO string, rather than doing this.
  let targetStartDate = new Date(startDate).toISOString().substring(0, 10);
  let targetEndDate = new Date(endDate).toISOString().substring(0, 10);
  return await Axios.get(`/api/devices/[${deviceNumbers}]/${sensorIndex}/flows/avg`, {
    params: {
      fromDate: avgStartDate,
      toDate: avgEndDate,
      targetFromDate: targetStartDate,
      targetToDate: targetEndDate,
      every: every,
      multiply: multiply,
    },
  }).then((response: AxiosResponse) => {
    return convertSensorFlowToTimeSeriesData(response);
  });
}

function convertSensorFlowToTimeSeriesData(response: AxiosResponse): TimeSeriesData[] {
  const data = response.data[0].value;
  let timeSeriesData: TimeSeriesData[] = [];
  data.forEach((dataPoint: { date: string; flow: number }) => {
    const date = moment(new Date(dataPoint.date)).format('YYYY-MM-DD HH:mm');
    const flowDataPoint: TimeSeriesData = { x: new Date(date), y: dataPoint.flow };
    timeSeriesData.push(flowDataPoint);
  });
  return timeSeriesData;
}
