import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const ReCaptchaV3 = ({
  className,
  action,
  onReCaptchaExecute,
  refreshReCaptchaCallback,
}: ReCaptchaV3Props): JSX.Element => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha(action);
    onReCaptchaExecute(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
    refreshReCaptchaCallback && refreshReCaptchaCallback(handleReCaptchaVerify);
  }, [handleReCaptchaVerify]);

  return (
    <div className={clsx(className && className, 'text-xs text-neutral-400 inline-block')}>
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  );
};

interface ReCaptchaV3Props {
  className?: string;
  action?: string;
  onReCaptchaExecute: (token: string) => void;
  refreshReCaptchaCallback?: (handleReCaptchaVerify: () => Promise<void>) => void;
}
