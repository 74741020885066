import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { CustomerInfoResponse } from '@thingslog/repositories';

const CustomerInfoDescription: FC<CustomerInfoDescriptionProps> = ({
  className,
  customerInfo,
}: CustomerInfoDescriptionProps) => {
  const address = useMemo(() => {
    const { municipality, quarter, street, streetNumber } = customerInfo;

    return [municipality, quarter, street, streetNumber]
      .filter((value: null | string) => value)
      .join(', ');
  }, [customerInfo]);

  return <div className={clsx(className && className, 'text-lg font-thin')}>{address}</div>;
};

interface CustomerInfoDescriptionProps {
  className?: string;
  customerInfo: CustomerInfoResponse;
}

export default CustomerInfoDescription;
