export enum SensorIconNames {
  Barometer = 'barometer-symbol',
  Electricity = 'electricity-symbol',
  Energy = 'energy-meter-symbol',
  Gas_Flow = 'gas-flow-meter-symbol',
  Gas = 'gas-symbol',
  Particulate_Meter = 'generic-particulate-meter-symbol',
  Generic = 'generic-symbol',
  Heat = 'heat-meter-symbol',
  Humidity = 'humidity-symbol',
  Leaf_Moisture = 'leaf-moisture-symbol',
  Level_Metering = 'level-metering-symbol',
  Pivot = 'pivot-symbol',
  Pressure = 'pressure-symbol',
  Radiation = 'radiation-symbol',
  Rain = 'rain-symbol',
  Soil_Moisture = 'soil-moisture-symbol',
  Temperature = 'temperature-symbol',
  Water_Meter = 'water-meter-symbol',
  Water = 'water-symbol',
  Wind_Direction = 'wind-direction-symbol',
  Wind_Speed = 'wind-speed-symbol',
  Door = 'door-symbol',
  Pump = 'pump-symbol',
}
