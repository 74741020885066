import React, { BaseSyntheticEvent, FC, useState, ChangeEvent } from 'react';
import { Button, TextFieldProps, TextField } from '@mui/material';
import { useModal } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import {
  LicenseDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseType,
  CompanyLicenseDto,
} from '@thingslog/repositories';
import { format } from 'date-fns';
import LicenseValidator from '../../../common/LicenseValidator';

const ConfirmActivityStatusChangeModal: FC<ConfirmActivityStatusChangeModalProps> = ({
  license,
  licenseId,
  status,
  updateLicenseActivityStatus,
}: ConfirmActivityStatusChangeModalProps) => {
  const { t } = useTranslation();
  const { modal, closeModal } = useModal();

  const renderMaxCountBasedOnLicenseType = (licenseType: LicenseType): JSX.Element => {
    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        const licensePerDevice = license as LicensePerDeviceDto;
        return (
          <>
            {t('license_max_devices')}: {licensePerDevice.maxDataLoggers}
          </>
        );
      case LicenseType.PER_READING:
        const licensePerReading = license as LicensePerReadingDto;
        return (
          <>
            {t('license_max_readings')}: {licensePerReading.maxReadings}
          </>
        );
      case LicenseType.PER_SENSOR:
        const licensePerSensors = license as LicensePerSensorDto;
        return (
          <>
            {t('license_max_sensors')}: {licensePerSensors.maxSensors}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div>
        <div className="mb-2">
          {status === 'INACTIVE'
            ? t('profile_page_deactivate_license_modal_description')
            : t('profile_page_activate_license_modal_description')}
        </div>
        <div className="mb-2">
          <div>
            {t('license_company')}: {license.companyName}
          </div>
          <div>
            {t('profile_page_license_type')}: {license['@type']}
          </div>
          <div>{renderMaxCountBasedOnLicenseType(license['@type'])}</div>
          <div>
            {t('license_table_valid_from')}:{' '}
            {LicenseValidator.isCompanyNonPartialLicense(license) &&
            (license as CompanyLicenseDto).validityPeriodFrom
              ? format(new Date((license as CompanyLicenseDto).validityPeriodFrom), 'dd.MM.yyyy')
              : ''}
          </div>
          <div>
            {t('license_table_valid_to')}:{' '}
            {LicenseValidator.isCompanyNonPartialLicense(license) &&
            (license as CompanyLicenseDto).validityPeriodTo
              ? format(new Date((license as CompanyLicenseDto).validityPeriodTo), 'dd.MM.yyyy')
              : ''}
          </div>
        </div>
        <p className="font-bold">
          {status === 'INACTIVE' ? t('license_deactivate_warning') : t('license_activate_warning')}
        </p>
        <div className="flex p-5 justify-center items-center space-x-5">
          <Button
            variant="contained"
            color={status === 'INACTIVE' ? 'error' : 'success'}
            className="w-5/6"
            onClick={(): void => {
              updateLicenseActivityStatus({ licenseId, body: status });
              closeModal();
            }}
          >
            {status === 'INACTIVE'
              ? t('license_deactivate_license_button')
              : t('license_activate_license_button')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="w-5/6"
            onClick={(): void => closeModal()}
          >
            {t('profile_page_cancel_button')}
          </Button>
        </div>
      </div>
    </>
  );
};

interface ConfirmActivityStatusChangeModalProps {
  updateLicenseActivityStatus: ({ licenseId, body }) => void;
  license: LicenseDto;
  licenseId: number;
  status: 'ACTIVE' | 'INACTIVE';
}

export default ConfirmActivityStatusChangeModal;
