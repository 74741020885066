import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { MutationOptions, MutationResult } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';

export interface ResetPasswordQueryClient {
  resetPassword: (body: ResetPasswordDto) => Promise<void>;
  useResetPassword: (
    options?: MutationOptions<void, ResetPasswordDto>
  ) => MutationResult<void, ResetPasswordDto>;
}

export function createResetPasswordQueryClient(axios: AxiosInstance): ResetPasswordQueryClient {
  return new ResetPasswordQueryClientImp(axios);
}

class ResetPasswordQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public resetPassword = async (body: ResetPasswordDto): Promise<void> => {
    return await this.axios
      .put(`/api/reset-password`, body)
      .then((response: AxiosResponse) => response.data);
  };

  public useResetPassword = (
    options?: MutationOptions<void, ResetPasswordDto>
  ): MutationResult<void, ResetPasswordDto> => {
    return useMutation<void, AxiosError, ResetPasswordDto>(
      [QueryKeys.ResetPassword],
      (variables: ResetPasswordDto) => this.resetPassword(variables),
      options
    );
  };
}

interface ResetPasswordDto {
  email: string;
  recaptchaToken: string | null;
}
