import Axios from './clients/Axios/Axios';

function getDeviceSignals(deviceNumber, startDate, endDate, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/signal/?fromDate=${startDate}&toDate=${endDate}`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getDevicesSignal(fromLevel, toLevel, cb, ecb) {
  if (fromLevel == null && toLevel == null) {
    return Axios.get(`/api/devices/signal/`).then(parseJSON).then(cb).catch(ecb);
  } else {
    return Axios.get(`/api/devices/signal/?fromLevel=${fromLevel}&toLevel=${toLevel}`)
      .then(parseJSON)
      .then(cb)
      .catch(ecb);
  }
}

function getDevicesSignalHistogram(companyId, cb, ecb) {
  let url = `/api/devices/signal/histogram`;
  const searchParams = new URLSearchParams();
  if (!!companyId) {
    searchParams.append('companyId', companyId);
  }
  const queryString = searchParams.toString();
  if (queryString) {
    url += `?${queryString}`;
  }

  return Axios.get(url).then(parseJSON).then(cb).catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const DeviceSignalClient = {
  getDeviceSignals,
  getDevicesSignal,
  getDevicesSignalHistogram,
};

export default DeviceSignalClient;
