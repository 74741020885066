import React, { FC } from 'react';
import {
  Cell,
  Pie,
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Legend,
  LegendProps,
} from 'recharts';
import { CellProps, PieProps, ResponsiveContainerProps } from './RechartsProps';

const PieChart: FC<PieChartProps> = ({
  container: containerProps,
  pie: pieProps,
  legend: legendProps,
  cells: cellsProps,
}: PieChartProps) => {
  return (
    <ResponsiveContainer {...containerProps}>
      <RechartsPieChart>
        <Pie {...pieProps}>
          {cellsProps && cellsProps.map((cellProp: CellProps) => <Cell {...cellProp} />)}
        </Pie>
        <Legend {...legendProps} />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

interface PieChartProps {
  container: Omit<ResponsiveContainerProps, 'children'>;
  pie: Omit<PieProps, 'ref'>;
  legend: Omit<LegendProps, 'ref'>;
  cells?: CellProps[];
}

export type { CellProps, PieProps, ResponsiveContainerProps };

export default PieChart;
