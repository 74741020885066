import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { EventValueRange, Port, SensorType } from '@thingslog/repositories';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSensorDefaults } from '../context/SensorDefaultsContext';

export const DefaultValueRangeModal: FC<DefaultValueRangeModalProps> = ({
  deviceNumber,
  portsConfig,
  onPortChange,
  onSelectionSubmit,
}: DefaultValueRangeModalProps) => {
  const { t } = useTranslation();
  const [sensorIndex, setSensorIndex] = useState<number | null>(null);
  const [selectedRangeNames, setSelectedRangeNames] = useState<string[]>([]);

  const { sensorDefaults, isLoading } = useSensorDefaults();

  const selectablePorts: [string, Port][] = useMemo(() => {
    if (portsConfig === null) return [];
    return Object.entries(portsConfig).filter(
      ([sensorIndex, port]: [string, Port]) => port.enabled === true
    );
  }, [portsConfig]);

  const selectedPort: Port | null = useMemo(() => {
    if (sensorIndex === null || portsConfig === null) return null;
    return portsConfig[sensorIndex];
  }, [sensorIndex, portsConfig]);

  useEffect(() => {
    if (sensorIndex !== null) return;
    if (portsConfig === null) {
      setSensorIndex(null);
      return;
    }

    let newSensorIndex: number | null = null;
    selectablePorts.forEach(([sensorIndex, port]: [string, Port]) => {
      if (port.enabled === true && newSensorIndex === null) {
        newSensorIndex = parseInt(sensorIndex);
      }
    });
    setSensorIndex(newSensorIndex);
  }, [selectablePorts, sensorIndex]);

  useEffect(() => {
    if (selectedPort === null) {
      onPortChange(null);
      return;
    }

    const sensorType = selectedPort.sensor['@type'];
    onPortChange(sensorType);
  }, [selectedPort]);

  useEffect(() => {
    setSelectedRangeNames([]);
  }, [sensorIndex]);

  const handleSensorChange = (e: SelectChangeEvent<number>): void => {
    setSensorIndex(e.target.value as number);
  };

  const handleSubmit = useCallback(() => {
    if (sensorIndex === null || selectedRangeNames.length === 0) return;

    const eventValueRange: EventValueRange[] = [];
    selectedRangeNames.forEach((name: string) => {
      const found = sensorDefaults?.rangeEventConfigs.find(
        (evr: EventValueRange) => evr.name === name
      );
      if (found) eventValueRange.push(found);
    });

    onSelectionSubmit(deviceNumber, sensorIndex, eventValueRange);
  }, [sensorIndex, selectedRangeNames, sensorDefaults]);

  return (
    <section className="flex flex-col gap-1 p-2">
      {portsConfig !== null && sensorIndex !== null && (
        <FormControl fullWidth>
          <InputLabel>{t('value_range_form_sensor')}</InputLabel>
          <Select
            size="small"
            value={sensorIndex}
            label={t('value_range_form_sensor')}
            onChange={handleSensorChange}
          >
            {selectablePorts.map((i: [string, Port]) => (
              <MenuItem key={i[0]} value={i[0]}>
                {i[1].sensor.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isLoading && <div>{t('value_ranges_loading')}</div>}
      {sensorDefaults === null && isLoading === false && (
        <div>{t('value_ranges_nothing_to_show')}</div>
      )}
      {sensorDefaults !== null && isLoading !== true && (
        <div className="flex flex-col gap-y-1">
          {sensorDefaults.rangeEventConfigs.map((evr: EventValueRange) => {
            const isChecked = selectedRangeNames.includes(evr.name);
            return (
              <div className="flex flex-row gap-2">
                <div>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      if (e.target.checked) {
                        setSelectedRangeNames([...selectedRangeNames, evr.name]);
                      } else {
                        setSelectedRangeNames(
                          selectedRangeNames.filter((name: string) => name !== evr.name)
                        );
                      }
                    }}
                  />
                </div>
                <div className="flex-grow grid auto-rows-min grid-cols-[auto_1fr] gap-y-0.5 gap-x-1 mb-4">
                  <span className="font-semibold">{t('value_range_form_name')}:</span>
                  <span>{evr.name}</span>
                  <span className="font-semibold">{t('value_range_form_function')}:</span>
                  <span>{evr.function}</span>
                  <span className="font-semibold">{t('value_range_form_min')}:</span>
                  <span>{evr.min !== null ? evr.min : 'N/A'}</span>
                  <span className="font-semibold">{t('value_range_form_max')}:</span>
                  <span>{evr.max !== null ? evr.max : 'N/A'}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div>
        <Button
          fullWidth
          disabled={sensorIndex === null || selectedRangeNames.length === 0}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </section>
  );
};

interface DefaultValueRangeModalProps {
  deviceNumber: string;
  portsConfig: Record<number, Port> | null;
  onPortChange: (type: SensorType | null) => void;
  onSelectionSubmit: (
    deviceNumber: string,
    sensorIndex: number,
    eventValueRange: EventValueRange[]
  ) => void;
}
