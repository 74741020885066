import React, { FC, useState, useEffect, ChangeEvent, MouseEvent, ReactNode } from 'react';
import {
  UtilityRateDto,
  UtilityRateCurrency,
  MutateUtilityRate,
  Unit,
  DisplayUnit,
  CompanyDto,
  UtilityRateBareboneDto,
} from '@thingslog/repositories';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Button,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const MutateUtilRateModal: FC<MutateUtilityModalProps> = ({
  utilityRate,
  onSubmit,
  companies,
}: MutateUtilityModalProps) => {
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [price, setPrice] = useState<string>('0');
  const [priceError, setPriceError] = useState<string | null>(null);
  const [unit, setUnit] = useState<Unit>(Unit.KWH);
  const [utilityRateCurrency, setUtilityRateCurrency] = useState<UtilityRateCurrency>(
    UtilityRateCurrency.USD
  );
  const [companyName, setCompanyName] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (utilityRate) {
      setName(utilityRate.name);
      setPrice(utilityRate.price.toString());
      setUnit(utilityRate.unit);
      setUtilityRateCurrency(utilityRate.utilityRateCurrency);
    }
  }, [utilityRate]);

  useEffect(() => {
    if (name.length < 2) {
      setNameError(t('util_rates_name_required'));
    } else {
      setNameError(null);
    }
  }, [name]);

  useEffect(() => {
    if (price.length === 0) {
      setPriceError(t('util_rates_price_required'));
    } else {
      setPriceError(null);
    }
  }, [price]);

  return (
    <section className="md:w-96 flex flex-col space-y-2">
      <TextField
        fullWidth
        label={t('util_rates_name')}
        size="small"
        value={name}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setName(e.target.value);
        }}
      />
      {nameError && <span className="pl-1 text-sm text-red-500">{nameError}</span>}
      <div className="flex space-x-1">
        <div className="flex flex-col flex-1">
          <TextField
            fullWidth
            label={t('util_rates_price')}
            size="small"
            value={price}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              if (/^\d*\.?\d*$/.test(e.target.value)) {
                // Allow numbers and a single dot for decimals
                setPrice(e.target.value);
              }
            }}
          />
          {priceError && <span className="pl-1 text-sm text-red-500">{priceError}</span>}
        </div>
        <FormControl fullWidth variant="outlined" size="small" className="flex-1">
          <InputLabel>{t('util_rates_currency')}</InputLabel>
          <Select
            value={utilityRateCurrency}
            onChange={(e: SelectChangeEvent<UtilityRateCurrency>): void => {
              setUtilityRateCurrency(e.target.value as UtilityRateCurrency);
            }}
            label={t('util_rates_currency')}
          >
            {Object.values(UtilityRateCurrency).map((currency: UtilityRateCurrency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel>{t('util_rates_unit')}</InputLabel>
        <Select
          value={unit}
          onChange={(e: SelectChangeEvent<Unit>): void => {
            setUnit(e.target.value as Unit);
          }}
          label={t('util_rates_unit')}
        >
          {Object.values(Unit).map((unit: Unit) => (
            <MenuItem key={unit} value={unit}>
              {DisplayUnit[unit]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {companies !== null &&
        companies.length > 0 &&
        (!utilityRate || (utilityRate && utilityRate.private === false)) && (
          <Autocomplete
            value={companyName}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: string | null): void => {
              setCompanyName(value);
            }}
            options={companies.map((company: CompanyDto) => company.name)}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField {...params} label={t('util_rate_company')} size="small" />
            )}
          />
        )}
      <Button
        fullWidth
        variant="contained"
        disabled={nameError !== null || priceError !== null}
        onClick={(event: MouseEvent): void => {
          const body: MutateUtilityRate = {
            name: name,
            price: parseFloat(price),
            unit: unit,
            utilityRateCurrency: utilityRateCurrency,
            companyName: companyName || undefined,
          };
          onSubmit(body);
        }}
      >
        {utilityRate === undefined ? t('create') : t('update')}
      </Button>
    </section>
  );
};

interface MutateUtilityModalProps {
  utilityRate?: UtilityRateBareboneDto;
  companies: CompanyDto[] | null;
  onSubmit: (utilityRate: MutateUtilityRate) => void;
}

export default MutateUtilRateModal;
