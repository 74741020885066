import React, { useState, ReactNode, FC, SyntheticEvent, ChangeEvent } from 'react';
import {
  DataGrid,
  DataGridHeader,
  DataGridProps,
  GridColumnHeaderParams,
  GridRenderCellParams,
  useModal,
  useToast,
} from '@thingslog/ui-components';
import { GrUser, GrUserAdmin } from 'react-icons/gr';

import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserDto } from '@thingslog/repositories';
import { GridFilterModel } from '@thingslog/ui-components/src/DataGrid';
import DeleteUserModal from './modals/DeleteUserModal';
import AddUserModal from './modals/AddUserModal';
import EditUserModal from './modals/EditUserModal';

const UsersTable: FC<UsersTableProps> = ({
  onDeleteUser,
  onCreateUser,
  onUpdateUser,
  ...props
}: UsersTableProps) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [filterValue, setFilterValue] = useState<string>('');
  const [activeAttribute, setActiveAttribute] = useState<UserFilterAttributes | null>(null);

  const { t } = useTranslation();
  const { modal, closeModal } = useModal();

  const handleFilterValueChange = (
    attribute: UserFilterAttributes,
    value: string | boolean
  ): void => {
    let newFilterModel: GridFilterModel;
    let newActiveAttribute: UserFilterAttributes | null;

    if (value !== '') {
      newFilterModel = {
        items: [
          {
            columnField: attribute,
            operatorValue: 'contains',
            value: value,
          },
        ],
      };
      newActiveAttribute = attribute;
    } else {
      newFilterModel = { items: [] };
      newActiveAttribute = null;
    }
    setFilterModel(newFilterModel);
    setFilterValue(value as string);
    setActiveAttribute(newActiveAttribute);
  };

  const handleDeleteUserClick = (user: UserDto): void => {
    modal({
      title: t('users_remove_modal_header', { username: user.email }),
      content: (
        <DeleteUserModal
          selectedUser={user}
          onCloseModal={closeModal}
          onDeleteUser={onDeleteUser}
        />
      ),
    });
  };

  const handleOnCreateUserClick = (): void => {
    modal({
      title: t('users_add_modal_header'),
      content: <AddUserModal onCloseModal={closeModal} onCreateUser={onCreateUser} />,
    });
  };

  const handleOnEditUserClick = (user: UserDto): void => {
    modal({
      title: t('users_update_modal_header'),
      content: (
        <EditUserModal onCloseModal={closeModal} onUpdateUser={onUpdateUser} selectedUser={user} />
      ),
    });
  };

  return (
    <DataGrid
      filterModel={filterModel}
      onFilterModelChange={(newModel: GridFilterModel): void => setFilterModel(newModel)}
      onColumnHeaderClick={(params, event): void => {
        event.defaultMuiPrevented = true;
      }}
      disableColumnFilter={true}
      columns={[
        {
          field: 'username',
          headerName: t('users_table_username'),
          minWidth: 200,
          flex: 1,
          hideSortIcons: true,
          valueGetter: (params: GridRenderCellParams<string, UserDto>): string =>
            params.row.username,
          renderCell: (params: GridRenderCellParams<string, UserDto>): ReactNode => {
            return (
              <div className="flex items-center">
                {params.row.roles.includes('ADMIN') ? (
                  <GrUserAdmin className="text-2xl mr-3" />
                ) : (
                  <GrUser className="text-2xl mr-3" />
                )}
                <Tooltip title={params.row.username}>
                  <div>{params.value}</div>
                </Tooltip>
              </div>
            );
          },
          renderHeader: (params: GridColumnHeaderParams<string, UserDto>): ReactNode => {
            return (
              <DataGridHeader<UserFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={UserFilterAttributes.username}
                filterValue={filterValue}
                activeAttribute={activeAttribute}
                onFilterValueChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  handleFilterValueChange(UserFilterAttributes.username, e.target.value)
                }
              />
            );
          },
        },
        {
          field: 'firstName',
          headerName: t('users_table_first_name'),
          minWidth: 200,
          flex: 1,
          hideSortIcons: true,
          renderHeader: (params: GridColumnHeaderParams<string, UserDto>): ReactNode => {
            return (
              <DataGridHeader<UserFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={UserFilterAttributes.firstName}
                filterValue={filterValue}
                activeAttribute={activeAttribute}
                onFilterValueChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  handleFilterValueChange(UserFilterAttributes.firstName, e.target.value)
                }
              />
            );
          },
        },
        {
          field: 'lastName',
          headerName: t('users_table_last_name'),
          minWidth: 200,
          flex: 1,
          hideSortIcons: true,
          renderHeader: (params: GridColumnHeaderParams<string, UserDto>): ReactNode => {
            return (
              <DataGridHeader<UserFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={UserFilterAttributes.lastName}
                filterValue={filterValue}
                activeAttribute={activeAttribute}
                onFilterValueChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  handleFilterValueChange(UserFilterAttributes.lastName, e.target.value)
                }
              />
            );
          },
        },
        {
          field: 'email',
          headerName: t('users_table_email'),
          minWidth: 200,
          flex: 1,
          hideSortIcons: true,
          renderHeader: (params: GridColumnHeaderParams<string, UserDto>): ReactNode => {
            return (
              <DataGridHeader<UserFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={UserFilterAttributes.email}
                filterValue={filterValue}
                activeAttribute={activeAttribute}
                onFilterValueChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  handleFilterValueChange(UserFilterAttributes.email, e.target.value)
                }
              />
            );
          },
        },
        {
          field: 'companyName',
          headerName: t('users_table_company_name'),
          minWidth: 200,
          flex: 1,
          hideSortIcons: true,
          renderHeader: (params: GridColumnHeaderParams<string, UserDto>): ReactNode => {
            return (
              <DataGridHeader<UserFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={UserFilterAttributes.companyName}
                filterValue={filterValue}
                activeAttribute={activeAttribute}
                onFilterValueChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  handleFilterValueChange(UserFilterAttributes.companyName, e.target.value)
                }
              />
            );
          },
        },
        {
          field: 'isContactPerson',
          headerName: t('users_table_is_contact_person'),
          minWidth: 150,
          flex: 0.7,
          hideSortIcons: true,
          renderCell: (params: GridRenderCellParams<boolean, UserDto>): ReactNode => {
            if (params.value === true) {
              return <CheckCircleIcon className="text-green-500 text-3xl" />;
            } else {
              return <></>;
            }
          },
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
        {
          field: 'actions',
          headerName: t('users_table_actions'),
          minWidth: 100,
          disableColumnMenu: true,
          flex: 0.7,
          sortable: false,
          filterable: false,
          renderHeader: (): ReactNode => (
            <Tooltip placement="top" title={<span>{t<string>('users_add_modal_header')}</span>}>
              <IconButton
                className="text-3xl"
                onClick={(): void => {
                  handleOnCreateUserClick();
                }}
              >
                <IoMdAddCircleOutline />
              </IconButton>
            </Tooltip>
          ),
          renderCell: (params: GridRenderCellParams<string, UserDto>): ReactNode => {
            return (
              <div className="flex justify-center">
                <Tooltip title={<span>{t('users_update_modal_header')}</span>}>
                  <IconButton onClick={(): void => handleOnEditUserClick(params.row)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<span>{t<string>('user_remove_modal_icon')}</span>}>
                  <IconButton
                    onClick={(): void => {
                      handleDeleteUserClick(params.row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      ]}
      {...props}
    />
  );
};

interface UsersTableProps extends Omit<DataGridProps, 'columns'> {
  onCreateUser: (user: UserDto) => void;
  onUpdateUser: (user: UserDto) => void;
  onDeleteUser: (username: string) => void;
}

enum UserFilterAttributes {
  username = 'username',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  companyName = 'companyName',
  isContactPerson = 'isContactPerson',
}

export default UsersTable;
