import { CommandParameters, CommandState } from '../../../model/API/Command/Command';

export default class CommandFormatUtil {
  public static translateCommandType = (commandType: string): string => {
    switch (commandType) {
      case 'RELAY_SWITCH':
        return 'commands_command_type_relay_switch';
      case 'SINGLE_RELAY_SWITCH':
        return 'commands_command_type_single_relay_switch';
      case 'RELAY_SWITCH_WITH_DELAY':
        return 'commands_command_type_relay_switch_with_delay';
      case 'RELAY_STATUSES':
        return 'commands_command_type_relay_statuses';
      case 'SEND_CONFIG_OVER_MQTT':
        return 'commands_command_type_send_config_over_mqtt';
      case 'SEND_DATE_OVER_MQTT':
        return 'commands_command_type_send_date_over_mqtt';
      case 'MODBUS':
        return 'commands_command_type_modbus';
      case 'TASMOTA_RELAY_SWITCH':
        return 'commands_command_type_tasmota_relay_switch';
      default:
        return commandType;
    }
  };

  public static translateCommandState = (commandState: CommandState): string => {
    switch (commandState) {
      case CommandState.PENDING:
        return 'commands_command_state_pending';
      case CommandState.SENT:
        return 'commands_command_state_sent';
      case CommandState.STATUS_OK:
        return 'commands_command_state_status_ok';
      case CommandState.STATUS_NOK:
        return 'commands_command_state_status_nok';
      case CommandState.CANCELED:
        return 'commands_command_state_cancelled';
      case CommandState.UNKNOWN:
        return 'commands_command_state_unknown';
      case CommandState.TIMEOUT:
        return 'commands_command_state_timeout';
      default:
        return commandState;
    }
  };

  public static convertCmdParamsToArray = (cmdParams: CommandParameters): [string, string][] => {
    let result: [string, string][] = [];

    for (const key in cmdParams) {
      result.push([key, cmdParams[key]]);
    }

    return result;
  };

  public static convertArrayToCmdParams = (
    cmdParamArray: [string, string][]
  ): CommandParameters => {
    let result: CommandParameters = {};

    for (var cmdParam of cmdParamArray) {
      result[cmdParam[0]] = cmdParam[1];
    }

    return result;
  };

  public static convertMapToCmdParams = (cmdParamMap: Map<string, string>): CommandParameters => {
    let result: CommandParameters = {};

    cmdParamMap.forEach((value: string, key: string) => {
      result[key] = value;
    });

    return result;
  };
}
