import Axios from './clients/Axios/Axios';

function getCompany(companyId, cb, ecb) {
  return Axios.get(`/api/companies/${companyId}`)
    .then((resp) => resp.data)
    .then(cb)
    .catch(ecb);
}

function getCompanies(cb, ecb) {
  return Axios.get(`/api/companies`)
    .then((resp) => resp.data)
    .then(cb)
    .catch(ecb);
}

function postCompany(data, cb, ecb) {
  return Axios.post(`/api/companies`, data).then(parseJSON).then(cb).catch(ecb);
}

function putCompany(data, cb, ecb) {
  return Axios.put(`/api/companies`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function deleteCompany(companyName, cb, ecb) {
  return Axios.delete(`/api/companies/${companyName}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const CompaniesClient = {
  getCompany,
  getCompanies,
  postCompany,
  putCompany,
  deleteCompany,
};

export default CompaniesClient;
