import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithRouterProps } from '../../common/withRouter';
import Commands from './Commands';
import CommandPageContextProvider from './context/CommandPageContextProvider';

class CommandsWithContext extends React.Component<CommandsWithContextProps> {
  public render = (): React.ReactNode => {
    return (
      <CommandPageContextProvider>
        <Commands {...this.props} />
      </CommandPageContextProvider>
    );
  };
}

interface CommandsWithContextProps extends WithRouterProps, WithTranslation {}

export interface CommandPageMatchParams {
  deviceNumber?: string;
}

export default withTranslation()(CommandsWithContext);
