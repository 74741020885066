import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import UserClient from './UserClient';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';
import { withRouter } from './common/withRouter';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import TextField from '@mui/material/TextField/TextField';
import withStyles from '@mui/styles/withStyles';
import ErrorUtil from './common/ErrorUtil';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPassword: '', newPassword1: '', newPassword2: '', open: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.handleNewPassword1Change = this.handleNewPassword1Change.bind(this);
    this.handleNewPassword2Change = this.handleNewPassword2Change.bind(this);
  }

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
    this.setState({ saveDisabled: false });
  }
  handleNewPassword1Change = (event) => this.setState({ newPassword1: event.target.value });
  handleNewPassword2Change = (event) => this.setState({ newPassword2: event.target.value });
  handleRequestClose = () => {
    this.setState({ open: false });
  };
  handleSubmit(event) {
    if (this.state.newPassword1 !== this.state.newPassword2) {
      alert('Password does not match');
      return false;
    }
    this.setState({ saveDisabled: true });
    UserClient.setNewPassword(
      this.props.match.params.confirmationToken,
      this.state.newPassword1,
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  }

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false }, () => {
      this.props.history.push('/app/signout');
    });
  }

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

    const { classes } = this.props;

    return (
      <Header>
        <div className="well" style={wellStyles}>
          <form className={classes.root} autoComplete="off">
            <Grid container justifyContent={'center'}>
              <Grid item>
                <Typography variant="h5" component="h5" paragraph>
                  Set New password
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="newPass"
                  label="New Password"
                  type="password"
                  className={classes.textField}
                  value={this.state.newPassword1}
                  onChange={this.handleNewPassword1Change}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="confirmPass"
                  label="Confirm New Password"
                  type="password"
                  className={classes.textField}
                  value={this.state.newPassword2}
                  onChange={this.handleNewPassword2Change}
                  margin="normal"
                />
              </Grid>
              <Grid container alignItems="flex-end" justifyContent={'center'} spacing={4}>
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.margin}
                    onClick={this.handleSubmit}
                    disabled={this.state.saveDisabled}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={this.state.open}
            message="Password changed."
            autoHideDuration={4000}
            onClose={this.handleRequestClose}
          />
        </div>
      </Header>
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

export default withRouter(withStyles(styles)(SetPassword));
