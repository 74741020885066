import React from 'react';
import { PickerPeriod } from '../../model/Pickers/PickerPeriod';
import ConnectedDatePicker from '../PeriodPicker/ConnectedDatePicker';
import ConnectedDateTimePicker from '../PeriodPicker/ConnectedDateTimePicker';
import PeriodModePicker from '../PeriodPicker/PeriodModePicker';
import { Box } from '@mui/material';
import usePeriodHelper from './hooks/usePeriodHelper';

const PeriodPickerFlex: React.FC<PeriodPickerFlexProps> = ({
  hidePeriodSelector = false,
  alwaysShowDatePickers = false,
  dateDifference,
}: PeriodPickerFlexProps) => {
  const {
    displayFromDatePicker,
    displayFromDateTimePicker,
    displayToDatePicker,
    displayToDateTimePicker,
  } = usePeriodHelper(alwaysShowDatePickers);

  // The hook automatically changes period mode to date range
  // if alwaysShowDatePickers is true
  if (alwaysShowDatePickers) {
    return (
      <React.Fragment>
        <Box padding={1}>
          <ConnectedDatePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
        </Box>
        <Box padding={1}>
          <ConnectedDatePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {!hidePeriodSelector && (
        <Box padding={1}>
          <PeriodModePicker />
        </Box>
      )}
      {displayFromDatePicker && (
        <Box padding={1}>
          <ConnectedDatePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
        </Box>
      )}
      {displayToDatePicker && (
        <Box padding={1}>
          <ConnectedDatePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
        </Box>
      )}
      {displayFromDateTimePicker && (
        <Box padding={1}>
          <ConnectedDateTimePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
        </Box>
      )}
      {displayToDateTimePicker && (
        <Box padding={1}>
          <ConnectedDateTimePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
        </Box>
      )}
    </React.Fragment>
  );
};

interface PeriodPickerFlexProps {
  hidePeriodSelector?: boolean;
  alwaysShowDatePickers?: boolean;
  dateDifference?: number;
}

export default PeriodPickerFlex;
