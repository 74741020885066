import { Dispatch } from 'react';
import { GetDevicesAction, DevicesActionType } from './DevicesAction';
import { AuthErrorAction } from './AuthAction';
import { devicesQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { Device } from '@thingslog/repositories';

export const setDevices = (devices: Device[]): GetDevicesAction => {
  return {
    payload: devices,
    type: DevicesActionType.SET_DEVICES,
  };
};
