import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import { Navigate } from 'react-router-dom';
import * as actions from './actions';
import { connect } from 'react-redux';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withRouter } from './common/withRouter';
import ArrowRight from '@mui/icons-material/ArrowRight';
import * as roles from './common/role_utils';
import { withTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventAction } from './common/GaEventAction';
import { GaEventCategory } from './common/GaEventCategory';

class DevicesMenu extends Component {
  constructor(props) {
    super(props);
    const decodedToken = localStorage.getItem('decoded_token');
    let email = null;
    try {
      email = JSON.parse(decodedToken).email;
    } catch (error) {
      console.log(error);
    }
    this.state = {
      redirect: false,
      email: email,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => this.props.onClose();

  render() {
    // let hasUserRole = roles.hasRole(this.props.auth, roles.ROLE_USER);
    let hasAdminRole = roles.hasRole(this.props.auth, roles.ROLE_ADMIN);
    let hasSuperAdminRole = roles.hasRole(this.props.auth, roles.ROLE_SUPER_ADMIN);
    if (this.state.redirect) {
      return <Navigate to={this.state.path + this.props.deviceNumber} />;
    } else {
      return (
        <List component="div" disablePadding>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.devOpenCloseDevMenu(true, false);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_ALL_DEVICES_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_all_devices')} />
            <ListItemIcon>
              <ArrowRight />
            </ListItemIcon>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(`/app/DeviceCounters/${this.props.deviceNumber}`);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_READINGS_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_readings')} />
          </ListItem>
          {(hasAdminRole || hasSuperAdminRole) && (
            <ListItem
              button
              onClick={() => {
                this.props.devOpenCloseMenu(false);
                this.props.history.push(`/app/Alarms/${this.props.deviceNumber}`);
                GoogleAnalyticsService.triggerEvent(
                  GaEventCategory.MENU_NAVIGATION,
                  GaEventAction.MENU_ALARMS_PAGE_CLICK
                );
              }}
            >
              <ListItemText primary={this.props.t('link_alarms')} />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(`/app/Graph/${this.props.deviceNumber}`);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_NEW_GRAPH_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_new_graph')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(`/app/DeviceCountersGraph/${this.props.deviceNumber}`);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_FLOW_GRAPH_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_flow')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(
                `/app/DeviceCountersCumulativeGraph/${this.props.deviceNumber}`
              );
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_GRAPH_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_graph')} />
          </ListItem>
          {this.props.deviceNumber === 'FFFF1CBA' && (
            <>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/Pump`);
                }}
              >
                <ListItemText primary={this.props.t('link_pump_control')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/Relay`);
                }}
              >
                <ListItemText primary={this.props.t('link_relay_control')} />
              </ListItem>
            </>
          )}
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(`/app/AlarmConfig/${this.props.deviceNumber}`);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_ALARM_CONFIG_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_alarm_config')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(`/app/DeviceLocation/${this.props.deviceNumber}`);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_DEVICE_MAP_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_map')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              this.props.devOpenCloseMenu(false);
              this.props.history.push(`/app/DeviceCountersStatistics/${this.props.deviceNumber}`);
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.MENU_NAVIGATION,
                GaEventAction.MENU_STATISTICS_PAGE_CLICK
              );
            }}
          >
            <ListItemText primary={this.props.t('link_statistics')} />
          </ListItem>
          {(hasAdminRole || hasSuperAdminRole) && (
            <React.Fragment>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/CustomerInfo/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_CUSTOMER_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_customer')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/DeviceConfig/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_CONFIG_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_config')} />
              </ListItem>
              {(hasSuperAdminRole || hasAdminRole) && (
                <ListItem
                  button
                  onClick={() => {
                    this.props.devOpenCloseMenu(false);
                    this.props.history.push(`/app/DeviceInitialConfig/${this.props.deviceNumber}`);
                    GoogleAnalyticsService.triggerEvent(
                      GaEventCategory.MENU_NAVIGATION,
                      GaEventAction.MENU_INITIAL_CONFIG_PAGE_CLICK
                    );
                  }}
                >
                  <ListItemText primary={this.props.t('link_initial_config')} />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/SensorsConfig/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_SENSOR_CONFIG_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_sensor_config')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/Commands/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICE_COMMANDS_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_commands')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/CopyConfig/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICE_COPY_CONFIG_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_copy_config')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/DevicePrediction/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.DEVICE_PREDICTION_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('device_prediction')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/DeviceSignals/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICE_SIGNAL_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_signal')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/DeviceBattery/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_DEVICE_BATTERY_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_battery')} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.props.devOpenCloseMenu(false);
                  this.props.history.push(`/app/EventValueRange/${this.props.deviceNumber}`);
                  GoogleAnalyticsService.triggerEvent(
                    GaEventCategory.MENU_NAVIGATION,
                    GaEventAction.MENU_VALUE_RANGE_PAGE_CLICK
                  );
                }}
              >
                <ListItemText primary={this.props.t('link_value_ranges')} />
              </ListItem>
            </React.Fragment>
          )}
        </List>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deviceNumber: state.dev.devices[0],
  };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(DevicesMenu)));
