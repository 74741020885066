import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import * as actions from '../actions';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { withTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

class SensorSelector extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid item>
          <FormControl style={{ width: 180 }}>
            <InputLabel id="demo-simple-select-label">{this.props.t('sensor')}</InputLabel>
            <Select
              label={this.props.t('sensor')}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.props.sensorIndex}
              onChange={(event) => this.props.sensorIndexChanged(event.target.value)}
              size="small"
            >
              {!this.props.hidePulse &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[0] &&
                    (this.props.portsConfig[0].enabled === null ||
                      this.props.portsConfig[0].enabled))) &&
                (this.isSensorEnabled(0) && this.props.portsConfig[0].sensor.name ? (
                  <MenuItem value={0}>{this.props.portsConfig[0].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={0}>{this.props.t('pulse_sensor_1')}</MenuItem>
                ))}
              {!this.props.hidePulse &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[1] &&
                    (this.props.portsConfig[1].enabled === null ||
                      this.props.portsConfig[1].enabled))) &&
                (this.isSensorEnabled(1) && this.props.portsConfig[1].sensor.name ? (
                  <MenuItem value={1}>{this.props.portsConfig[1].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={1}>{this.props.t('pulse_sensor_2')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[2] &&
                    (this.props.portsConfig[2].enabled === null ||
                      this.props.portsConfig[2].enabled))) &&
                (this.isSensorEnabled(2) && this.props.portsConfig[2].sensor.name ? (
                  <MenuItem value={2}>{this.props.portsConfig[2].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={2}>{this.props.t('analog_sensor_1')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[3] &&
                    (this.props.portsConfig[3].enabled === null ||
                      this.props.portsConfig[3].enabled))) &&
                (this.isSensorEnabled(3) && this.props.portsConfig[3].sensor.name ? (
                  <MenuItem value={3}>{this.props.portsConfig[3].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={3}>{this.props.t('analog_sensor_2')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[8] &&
                    (this.props.portsConfig[8].enabled === null ||
                      this.props.portsConfig[8].enabled))) &&
                (this.isSensorEnabled(8) && this.props.portsConfig[8].sensor.name ? (
                  <MenuItem value={8}>{this.props.portsConfig[8].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={8}>{this.props.t('analog_sensor_3')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[9] &&
                    (this.props.portsConfig[9].enabled === null ||
                      this.props.portsConfig[9].enabled))) &&
                (this.isSensorEnabled(9) && this.props.portsConfig[9].sensor.name ? (
                  <MenuItem value={9}>{this.props.portsConfig[9].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={9}>{this.props.t('analog_sensor_4')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[10] &&
                    (this.props.portsConfig[10].enabled === null ||
                      this.props.portsConfig[10].enabled))) &&
                (this.isSensorEnabled(10) && this.props.portsConfig[10].sensor.name ? (
                  <MenuItem value={10}>{this.props.portsConfig[10].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={10}>{this.props.t('analog_sensor_5')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[11] &&
                    (this.props.portsConfig[11].enabled === null ||
                      this.props.portsConfig[11].enabled))) &&
                (this.isSensorEnabled(11) && this.props.portsConfig[11].sensor.name ? (
                  <MenuItem value={11}>{this.props.portsConfig[11].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={11}>{this.props.t('analog_sensor_6')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[12] &&
                    (this.props.portsConfig[12].enabled === null ||
                      this.props.portsConfig[12].enabled))) &&
                (this.isSensorEnabled(12) && this.props.portsConfig[12].sensor.name ? (
                  <MenuItem value={12}>{this.props.portsConfig[12].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={12}>{this.props.t('analog_sensor_7')}</MenuItem>
                ))}
              {!this.props.hideAnalog &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[13] &&
                    (this.props.portsConfig[13].enabled === null ||
                      this.props.portsConfig[13].enabled))) &&
                (this.isSensorEnabled(13) && this.props.portsConfig[13].sensor.name ? (
                  <MenuItem value={13}>{this.props.portsConfig[13].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={13}>{this.props.t('analog_sensor_8')}</MenuItem>
                ))}
              {!this.props.hideOnOffInput &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[4] &&
                    (this.props.portsConfig[4].enabled === null ||
                      this.props.portsConfig[4].enabled))) &&
                (this.isSensorEnabled(4) && this.props.portsConfig[4].sensor.name ? (
                  <MenuItem value={4}>{this.props.portsConfig[4].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={4}>{this.props.t('on_off_sensor_1')}</MenuItem>
                ))}
              {!this.props.hideOnOffInput &&
                (!this.props.portsConfig ||
                  (this.props.portsConfig &&
                    this.props.portsConfig[5] &&
                    (this.props.portsConfig[5].enabled === null ||
                      this.props.portsConfig[5].enabled))) &&
                (this.isSensorEnabled(5) && this.props.portsConfig[5].sensor.name ? (
                  <MenuItem value={5}>{this.props.portsConfig[5].sensor.name}</MenuItem>
                ) : (
                  <MenuItem value={5}>{this.props.t('on_off_sensor_2')}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </React.Fragment>
    );
  }

  isSensorEnabled(index) {
    return (
      this.props.portsConfig &&
      this.props.portsConfig[index] &&
      this.props.portsConfig[index].enabled &&
      this.props.portsConfig[index].sensor
    );
  }
}

function mapStateToProps(state) {
  return {
    sensorIndex: state.dev.sensorIndex,
  };
}

export function findFirstEnabledSensor(portsConfig, sensorIndex) {
  if (
    portsConfig &&
    portsConfig[sensorIndex] &&
    portsConfig[sensorIndex].enabled &&
    portsConfig[sensorIndex].sensor
  ) {
    return sensorIndex;
  }
  let idx = 0;
  for (let index = 0; index < 4; index++) {
    if (
      portsConfig &&
      portsConfig[index] &&
      portsConfig[index].enabled &&
      portsConfig[index].sensor
    ) {
      idx = index;
      break;
    }
  }
  return idx;
}

const analogPorts = {
  ANALOG_INPUT_PORT: 'analog_input_port',
  CURRENT_420_INPUT_PORT: 'current_420_input_port',
  VOLTAGE_05_INPUT_PORT: 'voltage_05_input_port',
  MA_4_20_INPUT_PORT: '4_20_mA_input_port',
  V_0_5_INPUT_PORT: '0_5_V_input_port',
};

const digitalPorts = {
  DIGITAL_INPUT_PORT: 'digital_input_port',
  PULSE_INPUT_PORT: 'pulse_input_port',
  PULSE_S0_INPUT_PORT: 'pulse_s0_input_port',
};

function isAnalogPort(type) {
  for (const [key, value] of Object.entries(analogPorts)) {
    if (value === type) return true;
  }
  return false;
}

function isDigitalPort(type) {
  for (const [key, value] of Object.entries(digitalPorts)) {
    if (value === type) return true;
  }
  return false;
}

export function findFirstEnabledAnalogSensor(portsConfig, sensorIndex) {
  if (
    portsConfig &&
    portsConfig[sensorIndex] &&
    portsConfig[sensorIndex].enabled &&
    portsConfig[sensorIndex].sensor &&
    isAnalogPort(portsConfig[sensorIndex]['@type'])
  ) {
    return sensorIndex;
  }
  let idx = null;
  for (let index = 0; index < 4; index++) {
    if (
      portsConfig &&
      portsConfig[index] &&
      portsConfig[index].enabled &&
      portsConfig[index].sensor &&
      isAnalogPort(portsConfig[index]['@type'])
    ) {
      idx = index;
      break;
    }
  }
  return idx;
}

export function findFirstEnabledDigitalSensor(portsConfig, sensorIndex) {
  if (
    portsConfig &&
    portsConfig[sensorIndex] &&
    portsConfig[sensorIndex].enabled &&
    portsConfig[sensorIndex].sensor &&
    isDigitalPort(portsConfig[sensorIndex]['@type'])
  ) {
    return sensorIndex;
  }
  let idx = null;
  for (let index = 0; index < 4; index++) {
    if (
      portsConfig &&
      portsConfig[index] &&
      portsConfig[index].enabled &&
      portsConfig[index].sensor &&
      isDigitalPort(portsConfig[index]['@type'])
    ) {
      idx = index;
      break;
    }
  }
  return idx;
}

export default connect(mapStateToProps, actions)(withTranslation()(SensorSelector));
