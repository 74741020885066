import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddGroupModal from './modals/AddGroupModal';
import DeleteGroupModal from './modals/DeleteGroupModal';
import EditGroupModal from './modals/EditGroupModal';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeviceGroupDto, MutateDeviceGroup } from '@thingslog/repositories';
import { useModal } from '@thingslog/ui-components';

//Component
const DeviceGroupTable: FC<DeviceGroupTableProps> = ({
  companyId,
  deviceGroups,
  onSelectedDeviceGroupChange,
  selectedDeviceGroup,
  createDeviceGroup,
  updateDeviceGroup,
  deleteDeviceGroup,
}: DeviceGroupTableProps) => {
  const { t } = useTranslation();
  const { modal } = useModal();

  //Device groups states
  const [deviceGroupsPage, setDeviceGroupsPage] = useState<number>(0);
  const [deviceGroupsPageSize, setDeviceGroupsPageSize] = useState<number>(15);
  const [displayedDeviceGroups, setDisplayedDeviceGroups] = useState<DeviceGroupDto[]>([]);

  useEffect(() => {
    if (selectedDeviceGroup) {
      const updatedSelectedDeviceGroup = deviceGroups.find(
        (deviceGroup: DeviceGroupDto) =>
          deviceGroup.deviceGroupId === selectedDeviceGroup.deviceGroupId
      );

      onSelectedDeviceGroupChange(updatedSelectedDeviceGroup || null);
    }
  }, [deviceGroups]);

  useEffect(() => {
    setDisplayedDeviceGroups(
      paginateDeviceGroups(deviceGroups, deviceGroupsPage, deviceGroupsPageSize)
    );
  }, [deviceGroups, deviceGroupsPage, deviceGroupsPageSize]);

  //Device groups table related funcions
  const paginateDeviceGroups = (
    array: DeviceGroupDto[],
    page: number,
    size: number
  ): DeviceGroupDto[] => {
    return array.slice(page * size).slice(0, size);
  };

  const changeDeviceGroupsPage = (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void => {
    changeDisplayedDeviceGroups(page, deviceGroupsPageSize);
  };

  const changeRowsPerDeviceGroupsPage = (e: ChangeEvent<HTMLInputElement>): void => {
    changeDisplayedDeviceGroups(0, Number(e.target.value));
  };

  const changeDisplayedDeviceGroups = (page: number, size: number): void => {
    setDeviceGroupsPage(page);
    setDeviceGroupsPageSize(size);
    setDisplayedDeviceGroups(paginateDeviceGroups(deviceGroups, page, size));
  };

  const handleAddDeviceGroupClick = (): void => {
    modal({
      title: t('device_groups_add_device_group'),
      content: (
        <div className="md:w-[400px] overflow-y-auto" style={{ maxHeight: '80vh' }}>
          <AddGroupModal
            companyId={companyId}
            handleOnCreateDeviceGroup={createDeviceGroup}
            deviceGroups={deviceGroups}
          />
        </div>
      ),
    });
  };

  const updateDeviceGroupModal = (deviceGroup: DeviceGroupDto): void => {
    modal({
      title: t('device_groups_edit_device_group'),
      content: (
        <div className="md:w-[400px] overflow-y-auto" style={{ maxHeight: '80vh' }}>
          <EditGroupModal
            deviceGroupToModify={deviceGroup}
            handleOnUpdateDeviceGroup={(body: MutateDeviceGroup): void =>
              updateDeviceGroup(deviceGroup.deviceGroupId, body)
            }
            deviceGroups={deviceGroups}
          />
        </div>
      ),
    });
  };

  const deleteDeviceGroupModal = (deviceGroup: DeviceGroupDto): void => {
    modal({
      title: `${t('device_groups_delete_device_group')}: ${deviceGroup.deviceGroupName}`,
      content: (
        <div className="md:w-[400px] overflow-y-auto" style={{ maxHeight: '80vh' }}>
          <DeleteGroupModal
            handleOnDeleteDeviceGroup={(): void => {
              deleteDeviceGroup(deviceGroup.deviceGroupId, companyId);
            }}
          />
        </div>
      ),
    });
  };

  return (
    <>
      <TableContainer className="mr-5">
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow className="bg-primary-main">
              <TableCell className="font-extrabold text-base bg-primary-main" size="small">
                {t('device_groups_table_sensor_group_name_header')}
              </TableCell>
              <TableCell className="font-extrabold text-base bg-primary-main" size="small">
                {t('device_groups_table_parent_group_name_header')}
              </TableCell>
              <TableCell
                className="font-extrabold text-base bg-primary-main"
                size="small"
                align="center"
              >
                {t('device_groups_table_device_count_header')}
              </TableCell>
              <TableCell className="flex justify-end bg-primary-main" size="small" align="center">
                <Tooltip title={<span>{t('device_groups_btn_add')}</span>} arrow placement="top">
                  <IconButton aria-label="Add" size="large" onClick={handleAddDeviceGroupClick}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedDeviceGroups.map((deviceGroup: DeviceGroupDto) => {
              return (
                <TableRow
                  hover
                  className={
                    selectedDeviceGroup?.deviceGroupId === deviceGroup.deviceGroupId
                      ? 'bg-gray-200'
                      : ''
                  }
                  key={deviceGroup.deviceGroupName}
                  onClick={(): void => {
                    onSelectedDeviceGroupChange(deviceGroup);
                  }}
                >
                  <TableCell size="small">
                    <Tooltip title={deviceGroup.deviceGroupName} arrow placement="top">
                      <Typography variant="subtitle1">{deviceGroup.deviceGroupName}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small">
                    <Tooltip
                      title={
                        deviceGroup.parentDeviceGroupName
                          ? deviceGroup.parentDeviceGroupName
                          : 'None'
                      }
                      arrow
                      placement="top"
                    >
                      <Typography variant="subtitle1">
                        {deviceGroup.parentDeviceGroupName
                          ? deviceGroup.parentDeviceGroupName
                          : 'None'}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Tooltip
                      title={deviceGroup.deviceSensors ? deviceGroup.deviceSensors.length : 0}
                      arrow
                      placement="top"
                    >
                      <Typography variant="subtitle1">
                        {deviceGroup.deviceSensors ? deviceGroup.deviceSensors.length : 0}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="flex justify-end gap-2" size="small">
                    <Tooltip
                      title={<span>{t('device_groups_btn_edit')}</span>}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        aria-label="Update"
                        size="large"
                        onClick={(): void => updateDeviceGroupModal(deviceGroup)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={<span>{t('device_groups_btn_delete')}</span>}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        aria-label="Delete"
                        size="large"
                        onClick={(): void => deleteDeviceGroupModal(deviceGroup)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          className="mr-9"
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          component="div"
          count={deviceGroups.length}
          rowsPerPage={deviceGroupsPageSize}
          page={deviceGroupsPage}
          onPageChange={changeDeviceGroupsPage}
          onRowsPerPageChange={changeRowsPerDeviceGroupsPage}
        />
      </TableContainer>
    </>
  );
};

interface DeviceGroupTableProps {
  companyId: number;
  deviceGroups: DeviceGroupDto[];
  selectedDeviceGroup: DeviceGroupDto | null;
  onSelectedDeviceGroupChange: (deviceGroup: DeviceGroupDto | null) => void;
  createDeviceGroup: (deviceGroup: MutateDeviceGroup) => void;
  updateDeviceGroup: (deviceGroupId: number, body: MutateDeviceGroup) => void;
  deleteDeviceGroup: (deviceGroupId: number, companyId?: number) => void;
}

export default DeviceGroupTable;
