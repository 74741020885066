import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import {
  CopyConfigType,
  DeviceConfig,
  MutationOptions,
  MutationResult,
  QueryResult,
} from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';

export function createDeviceConfigQueryClient(axios: AxiosInstance): DeviceConfigQueryClient {
  return new DeviceConfigQueryClientImp(axios);
}

class DeviceConfigQueryClientImp {
  public constructor(private axios: AxiosInstance) {}
  public useDeviceConfig = (
    deviceNumber: string,
    options?: QueryOptions<DeviceConfig>
  ): QueryResult<DeviceConfig> => {
    return useQuery<DeviceConfig, AxiosError>(
      [QueryKeys.GetDeviceConfig, deviceNumber],
      () => this.getDeviceConfig(deviceNumber),
      options
    );
  };

  public useGetDevicesConfigsBatch = (
    options?: MutationOptions<Record<string, DeviceConfig>, string[]>
  ): MutationResult<Record<string, DeviceConfig>, string[]> => {
    return useMutation<Record<string, DeviceConfig>, AxiosError, string[]>(
      [QueryKeys.GetDevicesConfigsBatch],
      (props: string[]) => this.getDevicesConfigsBatch(props),
      options
    );
  };

  public useCopyDeviceConfig = (
    options?: MutationOptions<void, CopyConfigParams>
  ): MutationResult<void, CopyConfigParams> => {
    return useMutation<void, AxiosError, CopyConfigParams>(
      [QueryKeys.CopyDeviceConfig],
      (variables: CopyConfigParams) =>
        this.copyDeviceConfig(
          variables.toDeviceNumber,
          variables.fromDeviceNumber,
          variables.copyConfigType
        ),
      options
    );
  };

  public getDeviceConfig = async (deviceNumber: string): Promise<DeviceConfig> => {
    return await this.axios
      .get(`/api/devices/${deviceNumber}/config`)
      .then((response: AxiosResponse) => response.data);
  };

  public getDevicesConfigsBatch = async (
    deviceNumbers: string[]
  ): Promise<Record<string, DeviceConfig>> => {
    return await this.axios
      .post(`/api/devices/batch/config`, deviceNumbers)
      .then((response: AxiosResponse) => response.data);
  };

  public copyDeviceConfig = async (
    toDeviceNumber: string,
    fromDeviceNumber: string,
    copyConfigType: CopyConfigType
  ): Promise<void> => {
    return await this.axios
      .put(`/api/devices/${toDeviceNumber}/copy-config`, null, {
        params: { fromDeviceNumber, copyConfigType },
      })
      .then((response: AxiosResponse) => response.data);
  };
}

export interface DeviceConfigQueryClient {
  useDeviceConfig: (
    deviceNumber: string,
    options?: QueryOptions<DeviceConfig>
  ) => QueryResult<DeviceConfig>;
  useGetDevicesConfigsBatch: (
    options?: MutationOptions<Record<string, DeviceConfig>, string[]>
  ) => MutationResult<Record<string, DeviceConfig>, string[]>;
  useCopyDeviceConfig: (
    options?: MutationOptions<void, CopyConfigParams>
  ) => MutationResult<void, CopyConfigParams>;

  getDeviceConfig: (deviceNumber: string) => Promise<DeviceConfig>;
  getDevicesConfigsBatch: (deviceNumbers: string[]) => Promise<Record<string, DeviceConfig>>;
  copyDeviceConfig: (
    toDeviceNumber: string,
    fromDeviceNumber: string,
    copyConfigType: CopyConfigType
  ) => Promise<void>;
}

interface CopyConfigParams {
  copyConfigType: CopyConfigType;
  fromDeviceNumber: string;
  toDeviceNumber: string;
}
