import React, { FC } from 'react';
import { SensorDefaultsProvider } from './pages/eventValueRage/context/SensorDefaultsContext';

export const ContextProviders: FC<Props> = ({ children }: Props) => {
  return <SensorDefaultsProvider>{children}</SensorDefaultsProvider>;
};

interface Props {
  children: React.ReactNode;
}
