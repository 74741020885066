import { L } from '@thingslog/ui-components';

import sig_bat_0_0 from '../../../resources/sig_bat_0_0.png';
import sig_bat_0_25 from '../../../resources/sig_bat_0_25.png';
import sig_bat_0_50 from '../../../resources/sig_bat_0_50.png';
import sig_bat_0_75 from '../../../resources/sig_bat_0_75.png';
import sig_bat_0_100 from '../../../resources/sig_bat_0_100.png';

import sig_bat_25_0 from '../../../resources/sig_bat_25_0.png';
import sig_bat_25_25 from '../../../resources/sig_bat_25_25.png';
import sig_bat_25_50 from '../../../resources/sig_bat_25_50.png';
import sig_bat_25_75 from '../../../resources/sig_bat_25_75.png';
import sig_bat_25_100 from '../../../resources/sig_bat_25_100.png';

import sig_bat_50_0 from '../../../resources/sig_bat_50_0.png';
import sig_bat_50_25 from '../../../resources/sig_bat_50_25.png';
import sig_bat_50_50 from '../../../resources/sig_bat_50_50.png';
import sig_bat_50_75 from '../../../resources/sig_bat_50_75.png';
import sig_bat_50_100 from '../../../resources/sig_bat_50_100.png';

import sig_bat_75_0 from '../../../resources/sig_bat_75_0.png';
import sig_bat_75_25 from '../../../resources/sig_bat_75_25.png';
import sig_bat_75_50 from '../../../resources/sig_bat_75_50.png';
import sig_bat_75_75 from '../../../resources/sig_bat_75_75.png';
import sig_bat_75_100 from '../../../resources/sig_bat_75_100.png';

import sig_bat_100_0 from '../../../resources/sig_bat_100_0.png';
import sig_bat_100_25 from '../../../resources/sig_bat_100_25.png';
import sig_bat_100_50 from '../../../resources/sig_bat_100_50.png';
import sig_bat_100_75 from '../../../resources/sig_bat_100_75.png';
import sig_bat_100_100 from '../../../resources/sig_bat_100_100.png';

import sig_bat_unknown from '../../../resources/sig_bat_unknow.png';
import sig_bat_selected from '../../../resources/blue_sig_bat_100_100.png';

class MapIconManager {
  private readonly icons = [
    [sig_bat_0_0, sig_bat_0_25, sig_bat_0_50, sig_bat_0_75, sig_bat_0_100],
    [sig_bat_25_0, sig_bat_25_25, sig_bat_25_50, sig_bat_25_75, sig_bat_25_100],
    [sig_bat_50_0, sig_bat_50_25, sig_bat_50_50, sig_bat_50_75, sig_bat_50_100],
    [sig_bat_75_0, sig_bat_75_25, sig_bat_75_50, sig_bat_75_75, sig_bat_75_100],
    [sig_bat_100_0, sig_bat_100_25, sig_bat_100_50, sig_bat_100_75, sig_bat_100_100],
  ];

  public getIcon(
    batteryLevel: number | null,
    signalLevel: number | null,
    isSelected: boolean
  ): L.Icon<L.IconOptions> {
    if (isSelected) {
      return new L.Icon({
        iconUrl: sig_bat_selected,
        iconSize: [45, 45],
        iconAnchor: [25, 40],
      });
    }

    if (batteryLevel === null || signalLevel === null) {
      return new L.Icon({
        iconUrl: sig_bat_unknown,
        iconSize: [45, 45],
        iconAnchor: [25, 40],
      });
    }

    const batteryIndex = Math.floor((batteryLevel + 25.0 / 2) / 25);
    const signalIndex = Math.floor((signalLevel + 25.0 / 2) / 25);

    return new L.Icon({
      iconUrl: this.icons[signalIndex][batteryIndex],
      iconSize: [45, 45],
      iconAnchor: [25, 40],
    });
  }
}

export default MapIconManager;
