import { combineReducers } from 'redux';
import authReducer from './auth_reducer';
import devReducer from './dev_reducer';
import periodSelectionReducer from './period_selection_reducer';
import companyReducer from './company_reducer';
import featureFlagsReducer from './feature_flag_reducer';
import solutionSettingsReducer from './solution_settings_reducer';

const appReducer = combineReducers({
  auth: authReducer,
  dev: devReducer,
  period: periodSelectionReducer,
  company: companyReducer,
  featureFlags: featureFlagsReducer,
  settings: solutionSettingsReducer,
});

const rootReducer = (state: any, action: any): any => {
  if (action.type === 'SIGNED_OUT') {
    state = {};
  }

  return appReducer(state, action);
};

export type ReduxState = ReturnType<typeof appReducer>;

// TODO: Move this entire folder to src/state_management/reduces
export default rootReducer;
