import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StatisticsRepository from '../clients/Statistics/StatisticsClient';
import { ReduxState } from '../reducers';
import Statistics from '../model/API/Statistics/Statistics';
import { useTranslation } from 'react-i18next';

const useFetchGeneralStatistics = (
  number: string | undefined,
  sensorIndex: number | null,
  every: number | null
): UseFetchGeneralStatisticsProps => {
  const fromDate = useSelector((state: ReduxState) => state.period.fromDate);
  const toDate = useSelector((state: ReduxState) => state.period.toDate);
  const [general, setGeneral] = useState<Statistics | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const statisticsRepository = new StatisticsRepository();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const general = await statisticsRepository.getDeviceCounterGeneralStatistics(
          number!,
          sensorIndex!,
          every!,
          fromDate,
          toDate
        );
        setGeneral(general);
      } catch {
        setGeneral(null);
        setError(t('error_cannot_fetch_general_stats'));
      }
      setLoading(false);
    };

    number && every !== null && sensorIndex !== null && fetchData();
  }, [number, every, sensorIndex, fromDate, toDate]);

  return { general: general, generalLoading: loading, generalError: error };
};

interface UseFetchGeneralStatisticsProps {
  general: Statistics | null;
  generalError: string | null;
  generalLoading: boolean;
}

export default useFetchGeneralStatistics;
