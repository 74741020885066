import {
  CompanySelectedAction,
  CompanySelectionActionType,
  CompanySetLogoAction,
} from './CompanySelectionAction';

export class CompanySelectionActionCreator {
  public static selectCompany(company: {
    id: number | null;
    name: string | null;
  }): CompanySelectedAction {
    return {
      company: company,
      type: CompanySelectionActionType.COMPANY_SELECTED,
    };
  }

  public static setCompanyLogo(companyLogoBlob: Blob): CompanySetLogoAction {
    return {
      companyLogoBlob: companyLogoBlob,
      type: CompanySelectionActionType.COMPANY_LOGO_SET,
    };
  }
}
