import { deDE as deDatePickerLocale, enUS as enDatePikerLocale } from '@mui/x-date-pickers';
import {
  CalendarPickerView,
  ClockPickerView,
  MuiPickersAdapter,
} from '@mui/x-date-pickers/internals/models';

interface Locales {
  de: typeof deDatePickerLocale;
  en: typeof enDatePikerLocale;
  bg: typeof enDatePikerLocale;
  gr: typeof enDatePikerLocale;
  es: typeof enDatePikerLocale;
  ru: typeof enDatePikerLocale;
}

const DatePickerLocale: Locales = {
  de: deDatePickerLocale,
  en: enDatePikerLocale,
  bg: {
    ...enDatePikerLocale,
    components: {
      ...enDatePikerLocale.components,
      MuiLocalizationProvider: {
        ...enDatePikerLocale.components.MuiLocalizationProvider,
        defaultProps: {
          ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps,
          localeText: {
            ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps.localeText,
            previousMonth: 'Предишен месец',
            nextMonth: 'Следващ месец',
            openPreviousView: 'Отвори предишен изглед',
            openNextView: 'Отвори следващ изглед',
            calendarViewSwitchingButtonAriaLabel: (currentView: CalendarPickerView) =>
              currentView === 'year' ? 'Отвори изглед на години' : 'Отвори календарен изглед',
            start: 'Начало',
            end: 'Край',
            cancelButtonLabel: 'Отказ',
            clearButtonLabel: 'Изчисти',
            okButtonLabel: 'OK',
            todayButtonLabel: 'Днес',
            clockLabelText: (view: ClockPickerView, time: any, adapter: MuiPickersAdapter<any>) =>
              `Избери ${view}. ${
                time === null
                  ? 'Няма избрано време'
                  : `Избраното време е ${adapter.format(time, 'fullTime')}`
              }`,
            hoursClockNumberText: (hours: string) => `${hours} часа`,
            minutesClockNumberText: (minutes: string) => `${minutes} минути`,
            secondsClockNumberText: (seconds: string) => `${seconds} секунди`,
            openDatePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Избери дата, избраната дата е ${utils.format(utils.date(date), 'fullDate')}`
                : 'Избери дата',
            openTimePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Избери време, избраното време е ${utils.format(utils.date(date), 'fullTime')}`
                : 'Избери време',
            timeTableLabel: 'избери време',
            dateTableLabel: 'избери дата',
          },
        },
      },
    },
  },
  gr: {
    ...enDatePikerLocale,
    components: {
      ...enDatePikerLocale.components,
      MuiLocalizationProvider: {
        ...enDatePikerLocale.components.MuiLocalizationProvider,
        defaultProps: {
          ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps,
          localeText: {
            ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps.localeText,
            previousMonth: 'Προηγούμενος μήνας',
            nextMonth: 'Επόμενος μήνας',
            openPreviousView: 'Άνοιγμα προηγούμενης προβολής',
            openNextView: 'Άνοιγμα επόμενης προβολής',
            calendarViewSwitchingButtonAriaLabel: (currentView: CalendarPickerView) =>
              currentView === 'year' ? 'Άνοιγμα προβολής ετών' : 'Άνοιγμα ημερολογιακής προβολής',
            start: 'Έναρξη',
            end: 'Τέλος',
            cancelButtonLabel: 'Ακύρωση',
            clearButtonLabel: 'Καθαρισμός',
            okButtonLabel: 'OK',
            todayButtonLabel: 'Σήμερα',
            clockLabelText: (view: ClockPickerView, time: any, adapter: MuiPickersAdapter<any>) =>
              `Επιλέξτε ${view}. ${
                time === null
                  ? 'Δεν έχει επιλεγεί ώρα'
                  : `Η επιλεγμένη ώρα είναι ${adapter.format(time, 'fullTime')}`
              }`,
            hoursClockNumberText: (hours: string) => `${hours} ώρες`,
            minutesClockNumberText: (minutes: string) => `${minutes} λεπτά`,
            secondsClockNumberText: (seconds: string) => `${seconds} δευτερόλεπτα`,
            openDatePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Επιλέξτε ημερομηνία, η επιλεγμένη ημερομηνία είναι ${utils.format(
                    utils.date(date),
                    'fullDate'
                  )}`
                : 'Επιλέξτε ημερομηνία',
            openTimePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Επιλέξτε ώρα, η επιλεγμένη ώρα είναι ${utils.format(
                    utils.date(date),
                    'fullTime'
                  )}`
                : 'Επιλέξτε ώρα',
            timeTableLabel: 'Επιλέξτε ώρα',
            dateTableLabel: 'Επιλέξτε ημερομηνία',
          },
        },
      },
    },
  },
  es: {
    ...enDatePikerLocale,
    components: {
      ...enDatePikerLocale.components,
      MuiLocalizationProvider: {
        ...enDatePikerLocale.components.MuiLocalizationProvider,
        defaultProps: {
          ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps,
          localeText: {
            ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps.localeText,
            previousMonth: 'Último mes',
            nextMonth: 'Próximo mes',
            openPreviousView: 'abrir la última vista',
            openNextView: 'abrir la siguiente vista',
            cancelButtonLabel: 'Cancelar',
            clearButtonLabel: 'Limpiar',
            okButtonLabel: 'OK',
            todayButtonLabel: 'Hoy',
            start: 'Empezar',
            end: 'Terminar',
            calendarViewSwitchingButtonAriaLabel: (currentView: CalendarPickerView) =>
              currentView === 'year'
                ? 'la vista del año está abierta, cambie a la vista de calendario'
                : 'la vista de calendario está abierta, cambie a la vista del año',
            clockLabelText: (view: ClockPickerView, time: any, adapter: MuiPickersAdapter<any>) =>
              `Seleccione ${view}. ${
                time === null
                  ? 'Sin tiempo seleccionado'
                  : `El tiempo seleccionado es ${adapter.format(time, 'fullTime')}`
              }`,
            hoursClockNumberText: (hours: string) => `${hours} horas`,
            minutesClockNumberText: (minutes: string) => `${minutes} minutos`,
            secondsClockNumberText: (seconds: string) => `${seconds} segundos`,
            openDatePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Elige la fecha, la fecha elegida es ${utils.format(date, 'fullDate')}`
                : 'Elige la fecha',
            openTimePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Elige una hora, la hora seleccionada es ${utils.format(
                    utils.date(date),
                    'fullTime'
                  )}`
                : 'Elige una hora',
            timeTableLabel: 'seleccionar hora',
            dateTableLabel: 'seleccionar fecha',
          },
        },
      },
    },
  },
  ru: {
    ...enDatePikerLocale,
    components: {
      ...enDatePikerLocale.components,
      MuiLocalizationProvider: {
        ...enDatePikerLocale.components.MuiLocalizationProvider,
        defaultProps: {
          ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps,
          localeText: {
            ...enDatePikerLocale.components.MuiLocalizationProvider.defaultProps.localeText,
            previousMonth: 'Предыдущий месяц',
            nextMonth: 'Следующий месяц',
            openPreviousView: 'открыть предыдущий вид',
            openNextView: 'открыть следующий вид',
            calendarViewSwitchingButtonAriaLabel: (currentView: CalendarPickerView) =>
              currentView === 'year'
                ? 'вид года открыт, перейти к виду календаря'
                : 'вид календаря открыт, перейти к виду года',
            start: 'Начало',
            end: 'Конец',
            cancelButtonLabel: 'Отмена',
            clearButtonLabel: 'Очистить',
            okButtonLabel: 'ОК',
            todayButtonLabel: 'Сегодня',
            clockLabelText: (view: ClockPickerView, time: any, adapter: MuiPickersAdapter<any>) =>
              `Выберите ${view}. ${
                time === null
                  ? 'Не выбрано время'
                  : `Выбранное время - ${adapter.format(time, 'fullTime')}`
              }`,
            hoursClockNumberText: (hours: string) => `${hours} часов`,
            minutesClockNumberText: (minutes: string) => `${minutes} минут`,
            secondsClockNumberText: (seconds: string) => `${seconds} секунд`,
            openDatePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Выберите дату, выбранная дата - ${utils.format(utils.date(date), 'fullDate')}`
                : 'Выберите дату',
            openTimePickerDialogue: (date: any, utils: MuiPickersAdapter<any>) =>
              date && utils.isValid(utils.date(date))
                ? `Выберите время, выбранное время - ${utils.format(utils.date(date), 'fullTime')}`
                : 'Выберите время',
            timeTableLabel: 'выбрать время',
            dateTableLabel: 'выбрать дату',
          },
        },
      },
    },
  },
};

export default DatePickerLocale;
