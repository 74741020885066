import React, { FC, useEffect, useState } from 'react';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import {
  eventValueRangeQueryClient,
  initialConfigQueryClient,
  sensorDefaultConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useParams } from 'react-router-dom';
import { EventValueRange, SensorDefaultQueryDto, SensorType } from '@thingslog/repositories';
import { Container, Button, CircularProgress, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useModal, useToast } from '@thingslog/ui-components';
import ValueRangeModal from './components/ValueRangeModal';
import { useTranslation } from 'react-i18next';
import ConfirmDeleteValueRange from './components/ConfirmDeleteValueRange';
import EventValueRangeItem from './components/EventValueRangeItem';
import ArrowUp from '@mui/icons-material/ExpandLess';
import ArrowDown from '@mui/icons-material/ExpandMore';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { ReduxState } from '../../reducers';
import { useSelector } from 'react-redux';
import { DefaultValueRangeModal } from './components/DefaultValueRangeModal';
import { useSensorDefaults } from './context/SensorDefaultsContext';
import { AxiosError } from 'axios';

const EventValueRangePage: FC<EventValueRangePageProps> = () => {
  // #region State
  const eventValueRangeClient = eventValueRangeQueryClient;
  const initialConfigClient = initialConfigQueryClient;
  const sensorDefaultClient = sensorDefaultConfigQueryClient;
  const queryClient = useQueryClient();
  const { modal, closeModal } = useModal();
  const { toast } = useToast();
  const { t } = useTranslation();
  const { deviceNumber } = useParams();

  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);

  const { setSensorDefaults, setIsLoading: setIsSensorDefaultsLoading } = useSensorDefaults();
  const [defaultSensorType, setDefaultSensorType] = useState<SensorType | null>(null);
  // #endregion

  // #region Http Request
  const {
    isLoading: isEventValueRangesLoading,
    isError: isEventValueRangesError,
    isSuccess: isEventValueRangesSuccess,
    isFetching: isEventValueRangesFetching,
    data: eventValueRanges,
  } = eventValueRangeClient.useDeviceEventValueRangesData(deviceNumber!);

  const {
    data: portsConfig,
    isLoading: isPortsConfigLoading,
    isError: isPortsConfigError,
    isSuccess: isPortsConfigSuccess,
  } = initialConfigClient.useDevicePortsConfigData(deviceNumber!);

  const sensorDefaultsQuery = sensorDefaultClient.useGetSensorTypeConfig(defaultSensorType!, {
    enabled: defaultSensorType !== null,
    onSuccess: (data: SensorDefaultQueryDto) => {
      setSensorDefaults(data);
    },
  });

  const { mutate: updateValueRangePriority, isLoading: isUpdatingValueRangePriority } =
    eventValueRangeClient.useUpdateValueRangePriority({
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.EventValueRangesForDevice, deviceNumber!]);
      },
    });

  const batchCreateMutation = eventValueRangeClient.useBatchAddValueRangesData({
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries([QueryKeys.EventValueRangesForDevice, deviceNumber!]);
      queryClient.invalidateQueries([QueryKeys.SensorTypeConfig, defaultSensorType!]);
      setDefaultSensorType(null);
      setSensorDefaults(null);
    },
    onError: (err: AxiosError) => {
      const errorCode = err.response?.data?.errorCode;
      if (errorCode === 'EVENT_VALUE_RANGE_NAME_ALREADY_EXISTS') {
        toast({
          type: 'error',
          message: t('value_range_name_already_exists'),
        });
      }
      closeModal();
    },
  });
  // #endregion

  // #region Effect
  useEffect(() => {
    if (sensorDefaultsQuery.isFetching) {
      setIsSensorDefaultsLoading(true);
    } else {
      setIsSensorDefaultsLoading(false);
    }
  }, [sensorDefaultsQuery.isFetching]);
  // #endregion

  // #region Event Handlers
  const handleAddEventRangeClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    if (portsConfig) {
      modal({
        title: t('value_ranges_add_event'),
        content: (
          <div className="md:w-[520px] max-h-[82vh] overflow-y-auto overflow-x-hidden">
            <ValueRangeModal
              deviceNumber={deviceNumber!}
              sensorIndex={null}
              valueRangeName={null}
              portsConfig={portsConfig}
              onSuccess={(): void => {
                toast({
                  type: 'success',
                  message: t('successfully_saved'),
                });
              }}
            />
          </div>
        ),
      });
    }
  };

  const handleEditValueRangeClick = (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string
  ): void => {
    modal({
      title: t('value_ranges_edit_event'),
      content: (
        <div className="md:w-[520px] max-h-[82vh] overflow-y-auto overflow-x-hidden">
          <ValueRangeModal
            deviceNumber={deviceNumber}
            sensorIndex={sensorIndex}
            valueRangeName={valueRangeName}
            portsConfig={portsConfig || null}
            onSuccess={(): void => {
              toast({
                type: 'success',
                message: t('successfully_saved'),
              });
            }}
          />
        </div>
      ),
    });
  };

  const handleDeleteValueRangeClick = (
    deviceNumber: string,
    sensorIndex: number,
    valueRangeName: string
  ): void => {
    modal({
      title: `${t('delete')} ${valueRangeName}?`,
      content: (
        <div className="w-[300px]">
          <ConfirmDeleteValueRange
            deviceNumber={deviceNumber}
            sensorIndex={sensorIndex}
            valueRangeName={valueRangeName}
          />
        </div>
      ),
    });
  };

  const handleAddDefaultValueRangeClick = (deviceNumber: string): void => {
    setDefaultSensorType(null);
    setSensorDefaults(null);
    queryClient.invalidateQueries([QueryKeys.SensorTypeConfig, defaultSensorType!]);
    modal({
      title: t('value_ranges_add_default_events'),
      content: (
        <div className="md:w-[520px] max-h-[82vh] overflow-y-auto overflow-x-hidden">
          <DefaultValueRangeModal
            deviceNumber={deviceNumber}
            portsConfig={portsConfig || null}
            onPortChange={(type: SensorType | null): void => {
              setDefaultSensorType(type);
            }}
            onSelectionSubmit={handleBatchCreateValueRanges}
          />
        </div>
      ),
    });
  };

  const handleMoveEventValueRangeUp = (eventValueRange: EventValueRange): void => {
    updateValueRangePriority({
      deviceNumber: eventValueRange.deviceNumber,
      sensorIndex: eventValueRange.sensorIndex,
      valueRangeName: eventValueRange.name,
      direction: 'UP',
    });
  };

  const handleMoveEventValueRangeDown = (eventValueRange: EventValueRange): void => {
    updateValueRangePriority({
      deviceNumber: eventValueRange.deviceNumber,
      sensorIndex: eventValueRange.sensorIndex,
      valueRangeName: eventValueRange.name,
      direction: 'DOWN',
    });
  };

  const handleBatchCreateValueRanges = (
    deviceNumber: string,
    sensorIndex: number,
    eventValueRange: EventValueRange[]
  ): void => {
    batchCreateMutation.mutate({
      deviceNumber: deviceNumber,
      sensorIndex: sensorIndex,
      valueRanges: eventValueRange,
    });
  };
  // #endregion

  // #region Render
  if (isPortsConfigLoading || isEventValueRangesLoading) {
    return (
      <Header>
        <Container>
          <CircularProgress />
        </Container>
      </Header>
    );
  }

  if (isPortsConfigError || isEventValueRangesError) {
    return (
      <Header>
        <Container>{t('value_range_error_occured')}</Container>
      </Header>
    );
  }

  return (
    <Header>
      <Container>
        {selectedDevice && (
          <TitleHeader
            className="mb-4"
            title={t('value_ranges_header')}
            deviceNumber={selectedDevice.number}
            deviceName={selectedDevice.name}
            customerInfo={selectedDevice.customerInfo}
          />
        )}
        <section className="flex justify-end mb-2">
          <Button
            variant="contained"
            disableElevation
            className="mr-1"
            size="small"
            startIcon={<AddIcon />}
            onClick={(): void => {
              handleAddDefaultValueRangeClick(deviceNumber!);
            }}
          >
            {t('value_ranges_btn_defaults')}
          </Button>
          <Button
            variant="contained"
            disableElevation
            className="mr-1"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddEventRangeClick}
          >
            {t('value_ranges_btn_add')}
          </Button>
        </section>
        {isEventValueRangesSuccess &&
          isPortsConfigSuccess &&
          eventValueRanges.rangeEventConfigDtoList.map(
            (eventValueRange: EventValueRange, index: number, arr: EventValueRange[]) => {
              return (
                <div className="flex space-x-1">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    {index !== 0 && (
                      <IconButton
                        size="small"
                        disabled={isUpdatingValueRangePriority || isEventValueRangesFetching}
                        onClick={(): void => {
                          handleMoveEventValueRangeUp(eventValueRange);
                        }}
                      >
                        <ArrowUp />
                      </IconButton>
                    )}
                    {index !== arr.length - 1 && (
                      <IconButton
                        size="small"
                        disabled={isUpdatingValueRangePriority || isEventValueRangesFetching}
                        onClick={(): void => {
                          handleMoveEventValueRangeDown(eventValueRange);
                        }}
                      >
                        <ArrowDown />
                      </IconButton>
                    )}
                  </div>
                  <div className="flex-1">
                    <EventValueRangeItem
                      timezone={eventValueRanges.timezone}
                      eventValueRange={eventValueRange}
                      portsConfig={portsConfig}
                      onEditClick={(eventValueRange: EventValueRange): void => {
                        handleEditValueRangeClick(
                          deviceNumber!,
                          eventValueRange.sensorIndex,
                          eventValueRange.name
                        );
                      }}
                      onDeleteClick={(eventValueRange: EventValueRange): void => {
                        handleDeleteValueRangeClick(
                          deviceNumber!,
                          eventValueRange.sensorIndex,
                          eventValueRange.name
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
          )}
      </Container>
    </Header>
  );
  // #endregion
};

interface EventValueRangePageProps {}

export default EventValueRangePage;
