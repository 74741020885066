import {
  PERIOD_FROM_DATE_CHANGED,
  PERIOD_TO_DATE_CHANGED,
  PERIOD_AVG_FROM_DATE_CHANGED,
  PERIOD_AVG_TO_DATE_CHANGED,
  PERIOD_TIMESPAN_MODE_CHANGED,
} from '../actions/types';
import { PeriodMode } from '../model/Pickers/PeriodMode';
import {
  PeriodSelectionAction,
  FromDatePeriodAction,
  ToDatePeriodAction,
  AvgFromDatePeriodAction,
  AvgToDatePeriodAction,
  PeriodModePeriodAction,
} from '../state_management/actions/PeriodSelectionAction';
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  subDays,
  subMonths,
} from 'date-fns';

export default function periodSelectionReducer(
  state: PeriodSelectionState = noState,
  action: PeriodSelectionAction
): PeriodSelectionState {
  switch (action.type) {
    case PERIOD_FROM_DATE_CHANGED: {
      const periodFromDateChangedAction = action as FromDatePeriodAction;
      const fromDate = new Date(periodFromDateChangedAction.fromDate);
      const tzOffset = fromDate.getTimezoneOffset() * 60000;
      const fromDateTz = new Date(new Date(fromDate).getTime() - tzOffset);
      return {
        ...state,
        fromDate: fromDate,
        fromDateTz: fromDateTz,
      };
    }
    case PERIOD_TO_DATE_CHANGED: {
      const periodToDateChangedAction = action as ToDatePeriodAction;
      const toDate = new Date(periodToDateChangedAction.toDate);
      const tzOffset = toDate.getTimezoneOffset() * 60000;
      const toDateTz = new Date(new Date(toDate).getTime() - tzOffset);

      return {
        ...state,
        toDate: toDate,
        toDateTz: toDateTz,
      };
    }
    case PERIOD_AVG_FROM_DATE_CHANGED: {
      const avgPeriodFromDateChangedAction = action as AvgFromDatePeriodAction;
      const avgFromDate = new Date(avgPeriodFromDateChangedAction.avgFromDate);
      const tzOffset = avgFromDate.getTimezoneOffset() * 60000;
      const avgFromDateTz = new Date(
        new Date(avgFromDate.setHours(0, 0, 0, 0)).getTime() - tzOffset
      );

      return {
        ...state,
        avgFromDate: avgFromDate,
        avgFromDateTz: avgFromDateTz,
      };
    }
    case PERIOD_AVG_TO_DATE_CHANGED: {
      const avgPeriodToDateChangedAction = action as AvgToDatePeriodAction;
      const avgToDate = new Date(avgPeriodToDateChangedAction.avgToDate);
      const tzOffset = avgToDate.getTimezoneOffset() * 60000;
      const avgToDateTz = new Date(
        new Date(avgToDate.setHours(23, 59, 59, 0)).getTime() - tzOffset
      );

      return {
        ...state,
        avgToDate: avgToDate,
        avgToDateTz: avgToDateTz,
      };
    }
    case PERIOD_TIMESPAN_MODE_CHANGED: {
      const periodTimespanModeChangedAction = action as PeriodModePeriodAction;
      const periodMode = periodTimespanModeChangedAction.periodMode;
      const fromDate = new Date(state.fromDate);
      const toDate = new Date(state.toDate);
      const now = new Date();

      switch (periodMode) {
        case PeriodMode.LAST_1_HOUR:
          toDate.setTime(now.getTime());
          fromDate.setTime(addHours(now, -1).getTime());
          break;
        case PeriodMode.LAST_2_HOURS:
          toDate.setTime(now.getTime());
          fromDate.setTime(addHours(now, -2).getTime());
          break;
        case PeriodMode.LAST_4_HOURS:
          toDate.setTime(now.getTime());
          fromDate.setTime(addHours(now, -4).getTime());
          break;
        case PeriodMode.LAST_24_HOURS:
          toDate.setTime(now.getTime());
          fromDate.setTime(addDays(now, -1).getTime());
          break;
        case PeriodMode.LAST_48_HOURS:
          toDate.setTime(now.getTime());
          fromDate.setTime(addDays(now, -2).getTime());
          break;
        case PeriodMode.TODAY:
          toDate.setTime(endOfDay(now).getTime());
          fromDate.setTime(startOfDay(now).getTime());
          break;
        case PeriodMode.WEEK:
          toDate.setTime(endOfDay(now).getTime());
          fromDate.setTime(startOfDay(subDays(now, 6)).getTime());
          break;
        case PeriodMode.MONTH:
          toDate.setTime(endOfDay(now).getTime());
          fromDate.setTime(startOfDay(addDays(subMonths(now, 1), 1)).getTime());
          break;
        case PeriodMode.DATE_RANGE:
          toDate.setTime(endOfDay(toDate).getTime());
          fromDate.setTime(startOfDay(fromDate).getTime());
          break;
        case PeriodMode.YESTERDAY:
          toDate.setTime(endOfDay(addDays(now, -1)).getTime());
          fromDate.setTime(startOfDay(addDays(now, -1)).getTime());
          break;
        case PeriodMode.LAST_WEEK:
          toDate.setTime(endOfWeek(addWeeks(now, -1), { weekStartsOn: 1 }).getTime());
          fromDate.setTime(startOfWeek(addWeeks(now, -1), { weekStartsOn: 1 }).getTime());
          break;
        case PeriodMode.LAST_MONTH:
          toDate.setTime(endOfMonth(addMonths(now, -1)).getTime());
          fromDate.setTime(startOfMonth(addMonths(now, -1)).getTime());
          break;
        case PeriodMode.CUSTOM:
          toDate.setTime(endOfDay(toDate).getTime());
          fromDate.setTime(startOfDay(fromDate).getTime());
          break;
        default:
          break;
      }

      const tzOffsetFrom = fromDate.getTimezoneOffset() * 60000;
      const fromDateTz = new Date(new Date(fromDate).getTime() - tzOffsetFrom);
      const tzOffsetTo = toDate.getTimezoneOffset() * 60000;
      const toDateTz = new Date(new Date(toDate).getTime() - tzOffsetTo);

      return {
        ...state,
        fromDate: fromDate,
        fromDateTz: fromDateTz,
        toDate: toDate,
        toDateTz: toDateTz,
        periodMode: periodTimespanModeChangedAction.periodMode,
      };
    }
    default:
      return state;
  }
}

const initialDate = (
  hours: number,
  min: number,
  sec: number,
  ms: number,
  isTimezoned: boolean,
  monthsBack?: number | undefined
): Date => {
  let date = new Date(new Date().setHours(hours, min, sec, ms));
  if (monthsBack) {
    const adjustedDate = new Date(date.setMonth(date.getMonth() - monthsBack));
    date = adjustedDate;
  }
  const tzOffset = isTimezoned ? date.getTimezoneOffset() * 60000 : 0;
  return new Date(date.getTime() - tzOffset);
};

const noState: PeriodSelectionState = {
  fromDate: initialDate(0, 0, 0, 0, false),
  fromDateTz: initialDate(0, 0, 0, 0, true),
  toDate: initialDate(23, 59, 59, 0, false),
  toDateTz: initialDate(23, 59, 59, 0, true),
  avgFromDate: initialDate(0, 0, 0, 0, false, 1),
  avgFromDateTz: initialDate(0, 0, 0, 0, true, 1),
  avgToDate: initialDate(23, 59, 59, 0, false, 1),
  avgToDateTz: initialDate(23, 59, 59, 0, true, 1),
  periodMode: PeriodMode.TODAY,
};

interface PeriodSelectionState {
  fromDate: Date;
  fromDateTz: Date;
  toDate: Date;
  toDateTz: Date;
  avgFromDate: Date;
  avgFromDateTz: Date;
  avgToDate: Date;
  avgToDateTz: Date;
  periodMode: PeriodMode;
}
