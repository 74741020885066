import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import { Port } from '../../types/Port';

export default class DeviceInitialConfigRepository {
  public getDevicePortsConfig = async (
    number: string
  ): Promise<{
    [index: number]: Port;
  }> => {
    return await Axios.get(`/api/devices/${number}/initial-config/ports`).then(
      (response: AxiosResponse) => response.data
    );
  };
}
