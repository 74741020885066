import React, { useEffect, useState, FC, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Container, CircularProgress, Button, TextField } from '@mui/material';
import Header from '../../components/header';
import { ReduxState } from '../../reducers';
import { settingsQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useToast } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import SettingsDto, { SolutionSettings } from '@thingslog/repositories/src/settings/SettingsDto';
import DashboardLayoutEdit from './components/DashboardLayoutEdit';
import CertificatePathEdit from './components/CertificatePathEdit';

const SettingsV2: FC<SettingsV2Props> = () => {
  const { useUpdateSettings } = useMemo(() => settingsQueryClient, []);
  const { toast } = useToast();
  const { t } = useTranslation();

  const companyId = useSelector((state: ReduxState) => state.company.id);
  const reduxSettings = useSelector((state: ReduxState) => state.settings.data);

  const [dashboardLayout, setDashboardLayout] = useState<string>('');
  const [isDashboardLayoutValid, setIsDashboardLayoutValid] = useState<boolean>(true);
  const [isCertificatePathValid, setIsCertificatePathValid] = useState<boolean>(true);
  const [certificatePath, setCertificatePath] = useState<string>('');
  const [deviceInformationTitle, setDeviceInformationTitle] = useState<string>('');
  const [sensorInformationTitle, setSensorInformationTitle] = useState<string>('');
  const [headerHomeLabelPortal, setHeaderHomeLabelPortal] = useState<string>('');
  const [headerDashboardLabelPortal, setHeaderDashboardLabelPortal] = useState<string>('');
  const [footerMainLabelPortal, setFooterMainLabelPortal] = useState<string>('');
  const [footerLinkLabelPortal, setFooterLinkLabelPortal] = useState<string>('');
  const [footerLinkUrlPortal, setFooterLinkUrlPortal] = useState<string>('');
  const [portalAlarmNotificationTimeSpan, setPortalAlarmNotificationTimeSpan] = useState<
    number | null
  >(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const { mutate: updateCompanySettings } = useUpdateSettings(companyId, {
    onSuccess: () => {
      toast({ type: 'success', message: t('settings_submit_success_msg') });
    },
    onError: () => {
      toast({ type: 'error', message: t('settings_submit_error_msg') });
    },
  });

  useEffect(() => {
    if (reduxSettings) {
      setDashboardLayout(JSON.stringify(reduxSettings.dashboardLayout));
      setCertificatePath(reduxSettings.certificateEuroPath || '');
      setDeviceInformationTitle(reduxSettings.deviceInformationWidgetHeaderTitle || '');
      setSensorInformationTitle(reduxSettings.sensorInformationWidgetHeaderTitle || '');
      setHeaderHomeLabelPortal(reduxSettings.portalMainHeaderTitle || '');
      setHeaderDashboardLabelPortal(reduxSettings.portalDashboardHeaderTitle || '');
      setFooterMainLabelPortal(reduxSettings.portalFooterMainLabel || '');
      setFooterLinkLabelPortal(reduxSettings.portalFooterLinkLabel || '');
      setFooterLinkUrlPortal(reduxSettings.portalFooterLinkUrl || '');
      setPortalAlarmNotificationTimeSpan(reduxSettings.portalAlarmNotificationTimeSpan || null);
    }
  }, [reduxSettings]);

  useEffect(() => {
    if (!isDashboardLayoutValid || !isCertificatePathValid) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [isDashboardLayoutValid, isCertificatePathValid]);

  const handleSubmit = (): void => {
    let newSettings: SettingsDto = {
      data: {
        dashboardLayout: JSON.parse(dashboardLayout),
        certificateEuroPath: certificatePath || undefined,
        deviceInformationWidgetHeaderTitle: deviceInformationTitle || undefined,
        sensorInformationWidgetHeaderTitle: sensorInformationTitle || undefined,
        portalMainHeaderTitle: headerHomeLabelPortal || undefined,
        portalDashboardHeaderTitle: headerDashboardLabelPortal || undefined,
        portalFooterMainLabel: footerMainLabelPortal || undefined,
        portalFooterLinkLabel: footerLinkLabelPortal || undefined,
        portalFooterLinkUrl: footerLinkUrlPortal || undefined,
        portalAlarmNotificationTimeSpan: portalAlarmNotificationTimeSpan || undefined,
      },
    };
    updateCompanySettings(newSettings);
  };

  const handlePortalAlarmNotificationTimeSpanChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setPortalAlarmNotificationTimeSpan(value === '' ? null : parseInt(value));
    }
  };

  return (
    <>
      <Header>
        <Container>
          <section className="flex justify-center mb-6">
            <div className="text-2xl font-bold">{t('settings_page_header')}</div>
          </section>
          {reduxSettings ? (
            <section className="flex flex-col items-center justify-center mb-10 space-y-3 mx-2 w-full">
              <Button
                className="w-1/6"
                variant="contained"
                color="primary"
                startIcon={<CheckCircleIcon />}
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                {t('settings_submit_button')}
              </Button>
              <div className="flex w-full items-start space-x-4">
                <label className="w-1/4 text-right mt-2">
                  *{t('settings_dashboard_layout_label')}
                </label>
                <div className="w-1/2">
                  <DashboardLayoutEdit
                    dashboardLayout={dashboardLayout}
                    isDashboardLayoutValid={isDashboardLayoutValid}
                    onChange={(newLayout: string): void => setDashboardLayout(newLayout)}
                    setIsDashboardLayoutValid={setIsDashboardLayoutValid}
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_certificate_label')}</label>
                <div className="w-1/2">
                  <CertificatePathEdit
                    certificatePath={certificatePath}
                    isCertificatePathValid={isCertificatePathValid}
                    onChange={(newPath: string): void => setCertificatePath(newPath)}
                    setIsCertificatePathValid={setIsCertificatePathValid}
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_device_panel_title')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_input_value_label')}
                    value={deviceInformationTitle}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setDeviceInformationTitle(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_sensor_panel_title')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_input_value_label')}
                    value={sensorInformationTitle}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setSensorInformationTitle(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_header_home_label')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_header_home_label')}
                    value={headerHomeLabelPortal}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setHeaderHomeLabelPortal(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_header_dashboard_label')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_header_dashboard_label')}
                    value={headerDashboardLabelPortal}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setHeaderDashboardLabelPortal(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_footer_main_label')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_footer_main_label')}
                    value={footerMainLabelPortal}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setFooterMainLabelPortal(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_footer_link_label')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_footer_link_label')}
                    value={footerLinkLabelPortal}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setFooterLinkLabelPortal(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">{t('settings_footer_link_url')}</label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    label={t('settings_footer_link_url')}
                    value={footerLinkUrlPortal}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
                      setFooterLinkUrlPortal(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex w-full items-center space-x-4">
                <label className="w-1/4 text-right">
                  {t('settings_alarm_notification_display_period')}
                </label>
                <div className="w-1/2">
                  <TextField
                    className="w-full"
                    type="text"
                    label={t('settings_input_value_label')}
                    value={
                      portalAlarmNotificationTimeSpan === null
                        ? ''
                        : portalAlarmNotificationTimeSpan.toString()
                    }
                    onChange={handlePortalAlarmNotificationTimeSpanChange}
                  />
                </div>
              </div>
            </section>
          ) : (
            <CircularProgress size={80} />
          )}
        </Container>
      </Header>
    </>
  );
};

interface SettingsV2Props {}

export default SettingsV2;
