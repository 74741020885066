import React, { FC, useState, useMemo } from 'react';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { useTranslation } from 'react-i18next';
import { licenseQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import {
  LicenseDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseType,
  CompanyLicenseDto,
} from '@thingslog/repositories';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import LicensesTable from './components/LicensesTable';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button } from '@mui/material';
import {
  LicenseWarningBox,
  LicenseWarningBoxStatus,
  useModal,
  useToast,
} from '@thingslog/ui-components';
import AddLicenseModal from './components/AddLicenseModal';
import JwtValidator from '../../common/JwtValidator';
import { CircularProgress } from '@mui/material';
import LicenseValidator from '../../common/LicenseValidator';

const LicensePage: FC<LicensePageProps> = (props: LicensePageProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { modal, closeModal } = useModal();
  const { hasRole } = useMemo(() => new JwtValidator(), []);
  const { useLicenses, useCreateLicense } = useMemo(() => licenseQueryClient, []);
  const companyId = useSelector((state: ReduxState) => state.company.id);
  const companyName = useSelector((state: ReduxState) => state.company.name);
  const featureFlagsArray = useSelector(
    (state: ReduxState) => state.featureFlags.featureFlagsArray
  );
  const [tableData, setTableData] = useState<LicenseTableDataItem[]>([]);
  const [warningStatus, setWarningStatus] = useState<LicenseWarningBoxStatus>({
    noActiveLicense: false,
    noValidLicense: false,
    devicesCountLimitReached: false,
  });

  const {
    isLoading,
    refetch: loadLicenses,
    data: licenses,
  } = useLicenses(Number(companyId), undefined, undefined, {
    onSuccess: (data: LicenseDto[]) => {
      if (data) {
        let foundInactive = false;
        let allLicensesInvalid = true;

        const tableData = data.map((licenseItem: LicenseDto): LicenseTableDataItem => {
          if (!licenseItem.isActive) {
            foundInactive = true;
          }

          if (licenseItem.isValid) {
            allLicensesInvalid = false;
          }

          return {
            ...(licenseItem as CompanyLicenseDto),
            maxCount: LicenseValidator.isCompanyNonPartialLicense(licenseItem)
              ? Number(renderMaxCountBasedOnLicenseType(licenseItem as CompanyLicenseDto))
              : null,
          };
        });

        setTableData(tableData);

        // Update warning status based on the found flags
        const newWarningStatus: LicenseWarningBoxStatus = {
          noActiveLicense: foundInactive,
          noValidLicense: allLicensesInvalid,
          devicesCountLimitReached: false,
        };

        setWarningStatus(newWarningStatus);
      }
    },
    onError: (error: AxiosError) => {},
    refetchOnWindowFocus: false,
  });

  const { mutate: addLicense } = useCreateLicense({
    onSuccess: () => {
      toast({ message: t('license_add_success_message'), type: 'success' });
      closeModal();
      loadLicenses();
    },
    onError: (error: AxiosError) => {
      toast({
        message: error.response?.data.message || t('license_add_error_message'),
        type: 'error',
      });
    },
  });

  const onAddLicenseClickHandler = (): void => {
    if (companyId) {
      modal({
        title: t('license_add_button') + ` (${companyName})`,
        content: (
          <AddLicenseModal
            companyName={companyName || ''}
            addLicense={addLicense}
            licenses={licenses}
          />
        ),
      });
    } else {
      toast({ message: t('license_select_company_warning_message'), type: 'warning' });
    }
  };

  const renderMaxCountBasedOnLicenseType = (row: CompanyLicenseDto): number | null => {
    let renderedContent: number | null = null;
    switch (row['@type']) {
      case LicenseType.PER_DEVICE:
        const licensePerDevice = row as LicensePerDeviceDto;
        renderedContent = licensePerDevice.maxDataLoggers || null;
        break;
      case LicenseType.PER_READING:
        const licensePerReading = row as LicensePerReadingDto;
        renderedContent = licensePerReading.maxReadings || null;
        break;
      case LicenseType.PER_SENSOR:
        const licensePerSensors = row as LicensePerSensorDto;
        renderedContent = licensePerSensors.maxSensors || null;
        break;
      default:
        break;
    }

    return renderedContent;
  };

  return (
    <Header>
      <TitleHeader title={t('license_header')} />
      <div className="mb-5 flex justify-between">
        <h5 className="font-bold">{t('license_available_licenses')}</h5>
        {hasRole('ROLE_SUPER_ADMIN') && (
          <Button
            variant="contained"
            color="primary"
            className="max-md:w-1/2"
            disableElevation
            startIcon={<AddBoxIcon />}
            onClick={onAddLicenseClickHandler}
          >
            {t('license_add_license')}
          </Button>
        )}
      </div>
      {featureFlagsArray.includes('LICENSES_MANAGEMENT_INVALID_OR_INACTIVE_LICENSE_BANNER') && (
        <div className="flex justify-center mb-5 space-x-1">
          {(warningStatus.noActiveLicense || warningStatus.noValidLicense) && (
            <LicenseWarningBox
              warningStatus={warningStatus}
              translation={{
                accessOnHoldTitle: t('license_warning_access_hold'),
                accessOnHoldDescription: t('license_warning_access_hold_description'),
                noValidLicenseTitle: t('license_warning_no_valid_license'),
                noValidLicenseDescription: t('license_warning_no_valid_license_description'),
                deviceLimitReachedTitle: t('license_warning_devices_count_limit_reached'),
                deviceLimitReachedDescription: t(
                  'license_warning_devices_count_limit_reached_description'
                ),
                defaultGenericTitle: t('license_warning_generic_title'),
                defaultGenericDescription: t('license_warning_generic_description'),
              }}
            />
          )}
        </div>
      )}

      {isLoading ? (
        <CircularProgress size={50} />
      ) : (
        <LicensesTable rows={tableData} loadLicenses={loadLicenses} />
      )}
    </Header>
  );
};

interface LicenseTableDataItem extends CompanyLicenseDto {
  maxCount: number | null;
}

interface LicensePageProps {}

export default LicensePage;
