import React, { FC, ReactElement, SyntheticEvent, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReduxState } from '../../../../reducers';
import JwtValidator from '../../../../common/JwtValidator';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { Device, LocalControllerConfigModel } from '@thingslog/repositories';

const LocalControllerNetworkConfiguration: FC<LocalControllerNetworkConfigurationProps> = () => {
  const { t } = useTranslation();
  const { deviceNumber } = useParams();
  const devices = useSelector((state: ReduxState) => state.dev.devicesArray);

  const { hasRole } = useMemo(() => new JwtValidator(), []);
  const slaveDevicesOptions: string[] = useMemo(
    () =>
      devices
        .filter(
          (device: Device) =>
            device.number !== deviceNumber &&
            (device.model === 'FINECO_EM735' || device.model === 'FINECO_EM4374')
        )
        .map((device: Device) => device.number),
    [devices]
  );

  return (
    <ConnectForm<LocalControllerConfigModel>>
      {({ control }: UseFormReturn<LocalControllerConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 gap-5">
          <Controller
            control={control}
            name="slaveDevices"
            render={({
              field,
            }: UseControllerReturn<LocalControllerConfigModel, 'slaveDevices'>): ReactElement => (
              <Autocomplete
                className="my-5"
                multiple
                freeSolo={hasRole('ROLE_SUPER_ADMIN')}
                size="small"
                options={slaveDevicesOptions}
                value={field.value}
                onChange={(
                  event: SyntheticEvent,
                  value: string[],
                  reason: AutocompleteChangeReason,
                  details?: AutocompleteChangeDetails
                ): void => {
                  let slaveDevices = structuredClone(field.value);

                  if (reason === 'selectOption' && details) {
                    slaveDevices.push(details.option);
                  } else if (reason === 'createOption' && details) {
                    const newSlaves = details.option.replace(/\s+/g, '').split(',');
                    slaveDevices.push(...newSlaves);
                  } else if (reason === 'removeOption' && details) {
                    const indexToRemove = slaveDevices.indexOf(details.option);
                    slaveDevices.splice(indexToRemove, 1);
                  } else if (reason === 'clear') {
                    slaveDevices = [];
                  }

                  field.onChange(slaveDevices);
                }}
                renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
                  <TextField {...params} label={t('device_initial_config_slave_devices')} />
                )}
              />
            )}
          />
        </div>
      )}
    </ConnectForm>
  );
};

interface LocalControllerNetworkConfigurationProps {}

export default LocalControllerNetworkConfiguration;
