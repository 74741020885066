import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { CountriesListDto, QueryOptions, QueryResult } from '@thingslog/repositories';

export interface CountriesQueryClient {
  getCountries: () => Promise<CountriesListDto>;
  useGetCountries: (
    options?: UseQueryOptions<CountriesListDto, AxiosError>
  ) => QueryResult<CountriesListDto>;
}

export function createCountriesQueryClient(axios: AxiosInstance): CountriesQueryClient {
  return new CountriesQueryImp(axios);
}

class CountriesQueryImp {
  public constructor(private axios: AxiosInstance) {}

  public getCountries = async (): Promise<CountriesListDto> => {
    return await this.axios
      .get('/api/countries')
      .then((response: AxiosResponse<CountriesListDto>) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  public useGetCountries = (
    options?: QueryOptions<CountriesListDto>
  ): QueryResult<CountriesListDto> => {
    return useQuery<CountriesListDto, AxiosError>(
      [QueryKeys.GetCountries],
      () => this.getCountries(),
      options
    );
  };
}
