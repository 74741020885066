import { useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { QueryOptions, QueryResult } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { SwaggerDefinitions } from '@thingslog/repositories/openapi-typescript';

export interface CustomerBillingReportQueryClient {
  getCustomerBillingReport: (
    companyId: number | null
  ) => Promise<SwaggerDefinitions['CustomerBillingReportDto'][]>;
  useCustomerBillingReport: (
    companyId: number | null,
    options?: QueryOptions<SwaggerDefinitions['CustomerBillingReportDto'][]>
  ) => QueryResult<SwaggerDefinitions['CustomerBillingReportDto'][]>;
}

export function createCustomerBillingReportQueryClient(
  axios: AxiosInstance
): CustomerBillingReportQueryClient {
  return new CustomerBillingReportQueryClientImp(axios);
}

class CustomerBillingReportQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getCustomerBillingReport = async (
    companyId: number | null
  ): Promise<SwaggerDefinitions['CustomerBillingReportDto'][]> => {
    return await this.axios
      .get(`/api/customer-billing-report/`, { params: { companyId: companyId || undefined } })
      .then((response: AxiosResponse) => response.data);
  };

  public useCustomerBillingReport = (
    companyId: number | null,
    options?: QueryOptions<SwaggerDefinitions['CustomerBillingReportDto'][]>
  ): QueryResult<SwaggerDefinitions['CustomerBillingReportDto'][]> => {
    return useQuery<SwaggerDefinitions['CustomerBillingReportDto'][], AxiosError>(
      [QueryKeys.GetCustomerBillingReport, companyId],
      () => this.getCustomerBillingReport(companyId),
      options
    );
  };
}
