import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReduxState } from '../../reducers';
import * as periodActionsCreators from '../../state_management/actions/PeriodSelectionActionCreator';
import { PeriodMode } from '../../model/Pickers/PeriodMode';

const PeriodModePicker: React.FC<PeriodModePickerProps> = (props: PeriodModePickerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changePeriodMode } = bindActionCreators(periodActionsCreators, dispatch);
  const periodMode = useSelector((state: ReduxState) => state.period.periodMode);

  return (
    <FormControl className="w-full" size="small" variant="outlined">
      <InputLabel>{t<string>('period')}</InputLabel>
      <Select value={periodMode} label={t<string>('period')}>
        <MenuItem
          value={PeriodMode.DATE_RANGE}
          onClick={(): void => {
            changePeriodMode(PeriodMode.DATE_RANGE);
          }}
        >
          {t<string>('period_date_range')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.CUSTOM}
          onClick={(): void => {
            changePeriodMode(PeriodMode.CUSTOM);
          }}
        >
          {t<string>('period_custom')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.TODAY}
          onClick={(): void => {
            changePeriodMode(PeriodMode.TODAY);
          }}
        >
          {t<string>('period_today')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.WEEK}
          onClick={(): void => {
            changePeriodMode(PeriodMode.WEEK);
          }}
        >
          {t<string>('period_week')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.MONTH}
          onClick={(): void => {
            changePeriodMode(PeriodMode.MONTH);
          }}
        >
          {t<string>('period_month')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_1_HOUR}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_1_HOUR);
          }}
        >
          {t<string>('period_last_1_hours')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_2_HOURS}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_2_HOURS);
          }}
        >
          {t<string>('period_last_2_hours')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_4_HOURS}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_4_HOURS);
          }}
        >
          {t<string>('period_last_4_hours')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_24_HOURS}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_24_HOURS);
          }}
        >
          {t<string>('period_last_24_hours')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_48_HOURS}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_48_HOURS);
          }}
        >
          {t<string>('period_last_48_hours')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.YESTERDAY}
          onClick={(): void => {
            changePeriodMode(PeriodMode.YESTERDAY);
          }}
        >
          {t<string>('period_yesterday')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_WEEK}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_WEEK);
          }}
        >
          {t<string>('period_last_week')}
        </MenuItem>
        <MenuItem
          value={PeriodMode.LAST_MONTH}
          onClick={(): void => {
            changePeriodMode(PeriodMode.LAST_MONTH);
          }}
        >
          {t<string>('period_last_month')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

interface PeriodModePickerProps {}

export default PeriodModePicker;
