import { Box, Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import CircleIcon from '@mui/icons-material/Brightness1';
import { sortBy, find } from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styles } from './ChartStatistics.styles';
import DeviceCountersStatisticsClient from '../../../DeviceCountersStatisticsClient';
import { connect } from 'react-redux';
import { GeneralStatistics } from '../../../model/GeneralStatistics/GeneralStatistics';
import { GraphType } from '../model/GraphType';
import { TimeSeriesChartData } from '../model/TimeSeriesChartData';
import { ChartSensorStatistics } from '../model/ChartSensorStatistics';

class ChartStatistics extends Component<ChartStatisticsProps, ChartStatisticsState> {
  public state: ChartStatisticsState = {
    statistics: [],
    total: new Map<number, number>(), // key = sensor index, value = total
  };

  public componentDidMount = async (): Promise<void> => {
    this.orderStatisticsBySensorIndex();
    await this.getTotalDeviceCountersStatistics();
  };

  public componentDidUpdate = async (prevProps: ChartStatisticsProps): Promise<void> => {
    if (prevProps.statistics.length !== this.props.statistics.length) {
      this.orderStatisticsBySensorIndex();
      await this.getTotalDeviceCountersStatistics();
    }
  };

  private getTotalDeviceCountersStatistics = async (): Promise<void> => {
    if (this.props.graphType === GraphType.DIGITAL) {
      this.props.chartData.forEach((chartData: TimeSeriesChartData) => {
        DeviceCountersStatisticsClient.getDeviceCountersStatisticsGeneral(
          chartData.deviceNumber,
          chartData.sensorIndex,
          this.props.fromDateTz.toISOString(),
          this.props.toDateTz.toISOString(),
          this.props.every,
          (data: GeneralStatistics) => {
            if (data.total) {
              const total = new Map(this.state.total);
              total.set(chartData.sensorIndex, data.total);
              this.setState({ total });
            }
          }
        );
      });
    }
  };

  private orderStatisticsBySensorIndex = (): void => {
    let statistics = [...this.props.statistics];
    const ordered = sortBy(statistics, (stat: ChartSensorStatistics) => stat.sensorIndex);
    this.setState({ statistics: ordered });
  };

  private getColorForSensorIndex = (index: number): string => {
    const color = find(this.props.chartData, { sensorIndex: index })?.primaryColor;
    return color || '#000000';
  };

  private getSensorLabelForSensorIndex = (index: number): string => {
    const label = find(this.props.chartData, { sensorIndex: index })?.label;
    return label || `Sensor ${index}`;
  };

  public render(): React.ReactNode {
    const { classes } = this.props;

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.mainContainer}
      >
        {this.state.statistics.map((chartStat: ChartSensorStatistics, index: number) => {
          const sensorIndex = chartStat.sensorIndex;
          const sensorColor = this.getColorForSensorIndex(sensorIndex);
          const sensorLabel = this.getSensorLabelForSensorIndex(sensorIndex);

          return (
            <Grid key={index} item style={{ marginTop: index !== 0 ? 8 : 0 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={12} sm={'auto'} className={classes.measurementGridItem}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <CircleIcon style={{ color: sensorColor }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        <Box fontWeight={800}>{sensorLabel}</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={'auto'} className={classes.measurementGridItem}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body1">
                        {this.props.t<string>('chart_statistics_min')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.measurementUnderline}
                        style={{ textDecorationColor: sensorColor }}
                      >
                        <Box fontWeight={600}>{chartStat.min.toFixed(3)}</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={'auto'} className={classes.measurementGridItem}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body1">
                        {this.props.t<string>('chart_statistics_max')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.measurementUnderline}
                        style={{ textDecorationColor: sensorColor }}
                      >
                        <Box fontWeight={600}>{chartStat.max.toFixed(3)}</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={'auto'} className={classes.measurementGridItem}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body1">
                        {this.props.t<string>('chart_statistics_avg')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.measurementUnderline}
                        style={{ textDecorationColor: sensorColor }}
                      >
                        <Box fontWeight={600}>{chartStat.avg.toFixed(3)}</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.total.has(sensorIndex) && (
                  <Grid item xs={12} sm={'auto'} className={classes.measurementGridItem}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Typography variant="body1">
                          {this.props.t<string>('chart_statistics_total')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          className={classes.measurementUnderline}
                          style={{ textDecorationColor: sensorColor }}
                        >
                          <Box fontWeight={600}>{this.state.total.get(sensorIndex)}</Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

interface ChartStatisticsProps extends WithStyles<typeof styles>, WithTranslation, StateProps {
  statistics: ChartSensorStatistics[];
  chartData: TimeSeriesChartData[];
  graphType: GraphType;
  every: number;
}

interface ChartStatisticsState {
  statistics: ChartSensorStatistics[];
  total: Map<number, number>;
}

interface StateProps {
  fromDateTz: Date;
  toDateTz: Date;
}

const mapStateToProps = (stateProps: { period: StateProps }): StateProps => ({
  fromDateTz: stateProps.period.fromDateTz,
  toDateTz: stateProps.period.toDateTz,
});

export default connect(mapStateToProps, {})(withTranslation()(withStyles(styles)(ChartStatistics)));
