import React, { FC, useState, useEffect } from 'react';
import {
  DataGridProps as MuiDataGridProps,
  DataGrid as MuiDataGrid,
  GridColumnVisibilityModel,
  GridCellParams,
} from '@mui/x-data-grid';
import DataGridPagination from './DataGridPagination';
import clsx from 'clsx';

const DataGrid: FC<DataGridProps> = ({
  localeText,
  showPrintOption = false,
  sx,
  componentsProps,
  columnVisibilityModel: propsColumnVisibilityModel,
  selectAllEnabled,
  ...props
}: DataGridProps) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<
    GridColumnVisibilityModel | undefined
  >(propsColumnVisibilityModel);
  useEffect(() => {
    setColumnVisibilityModel(propsColumnVisibilityModel);
  }, [propsColumnVisibilityModel]);

  return (
    <MuiDataGrid
      getCellClassName={(params: GridCellParams): string =>
        clsx(params.colDef.type === 'number' ? 'text-right' : 'text-left')
      }
      sx={{
        ...sx,
        '.MuiDataGrid-menuIcon': {
          width: 'auto',
          visibility: 'visible !important',
          justifyContent: 'flex-end !important',
        },
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
          display: selectAllEnabled ? 'flex' : 'none',
        },
        '.MuiDataGrid-columnHeaderTitleContainer': {
          justifyContent: 'space-between',
        },
        '.MuiDataGrid-iconButtonContainer': {
          width: 'auto',
          display: 'flex',
        },
        '.MuiDataGrid-sortIcon': {
          visibility: 'visible',
          opacity: 'inherit !important',
        },
        '.MuiDataGrid-cellContent': {
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': props.density === 'compact' ? '2' : '3',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        },
      }}
      {...props}
      pagination
      columnVisibilityModel={columnVisibilityModel}
      componentsProps={{
        ...componentsProps,
        pagination: {
          ActionsComponent: DataGridPagination,
          ...componentsProps?.pagination,
        },
        toolbar: {
          ...componentsProps?.toolbar,
          csvOptions: {
            ...componentsProps?.toolbar?.csvOptions,
            utf8WithBom: true,
          },
          printOptions: { disableToolbarButton: !showPrintOption },
        },
      }}
    />
  );
};

interface DataGridProps extends MuiDataGridProps {
  showPrintOption?: boolean;
  selectAllEnabled?: boolean;
}

export default DataGrid;
