import React, { FC, useEffect, useMemo, useState, useRef, ForwardedRef, useCallback } from 'react';
import JwtValidator from '../../common/JwtValidator';
import Header from '../../components/header';
import { DragEndEvent, useModal, useToast } from '@thingslog/ui-components';
import {
  mapSettingsQueryClient,
  deviceLocationQueryClient,
  devicesSignalsQueryClient,
  devicesBatteryQueryClient,
  userQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { AxiosError } from 'axios';
import DevicesMap from './DevicesMap';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { CircularProgress, Fab } from '@mui/material';
import DeviceLocationEditor from './DeviceLocationEditor';
import { useTranslation } from 'react-i18next';
import { LeafletMap as LeafletMapType } from '@thingslog/ui-components';
import { DeviceLocationDto, MapSettings, UserDto } from '@thingslog/repositories';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SetMapSettingsModal from './SetMapSettingsModal';
import useDetermineCompanyId from '../../hooks/useDetermineCompanyId';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';

const Location: FC<LocationProps> = () => {
  // #region State
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const companyIdRedux = useSelector((state: ReduxState) => state.company.id);
  const companyId = useMemo(
    () => jwtValidator.determineCompanyIdForUserRole(companyIdRedux),
    [companyIdRedux, jwtValidator]
  );
  const [deviceLocations, setDeviceLocations] = useState<Map<string, DeviceLocationDto>>(new Map());
  const [mapName] = useState<string>('map');
  const [selectedDeviceNumber, setSelectedDeviceNumber] = useState<string | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number | null>(null);
  const [users, setUsers] = useState<UserDto[]>([]);
  const { deviceNumber: paramsDeviceNumber } = useParams();
  // #endregion

  // #region Util
  const { toast } = useToast();
  const { modal } = useModal();
  const { t } = useTranslation();
  const devicesMapRef: ForwardedRef<{ map: LeafletMapType | null }> = useRef<{
    map: LeafletMapType | null;
  }>(null);

  const queryClient = useQueryClient();
  const { useDeviceLocationsData, useUpdateDeviceLocation } = useMemo(
    () => deviceLocationQueryClient,
    []
  );
  const { useMap, useUpdateMapSettings } = useMemo(() => mapSettingsQueryClient, []);
  const { useDevicesSignal } = useMemo(() => devicesSignalsQueryClient, []);
  const { useBatteryStatus } = useMemo(() => devicesBatteryQueryClient, []);
  const { useUsers } = useMemo(() => userQueryClient, []);
  const isAdmin = useMemo(() => {
    return jwtValidator.hasRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
  }, [jwtValidator]);

  const saveMapSettings = useCallback(
    (username?: string, companyName?: string) => {
      if (latitude !== null && longitude !== null && zoomLevel !== null) {
        return mapSettingsMutation.mutate({
          mapName: mapName,
          settings: {
            centerLatitude: latitude,
            centerLongitude: longitude,
            zoomLevel: zoomLevel,
          },
          username: username,
          companyName: companyName,
        });
      }
    },
    [latitude, longitude, zoomLevel]
  );

  const openSetMapSettingsModal = useCallback(() => {
    modal({
      title: t('map_coordinates_modal_title'),
      content: (
        <SetMapSettingsModal users={users} isAdmin={isAdmin} saveMapSettings={saveMapSettings} />
      ),
    });
  }, [users, isAdmin, saveMapSettings]);

  // #endregion

  useEffect(() => {
    (): void => {
      devicesMapRef.current?.map?.removeEventListener('dragend', handleMapDragEnd);
    };
  }, []);

  useEffect(() => {
    if (
      paramsDeviceNumber &&
      deviceLocations &&
      devicesMapRef.current?.map &&
      deviceLocations[paramsDeviceNumber] !== undefined
    ) {
      const lat = deviceLocations[paramsDeviceNumber].latitude;
      const lng = deviceLocations[paramsDeviceNumber].longitude;
      devicesMapRef.current?.map?.setView([lat, lng]);
    }
  }, [paramsDeviceNumber, deviceLocations, devicesMapRef.current?.map]);

  // #endregion

  // #region HTTP
  useUsers(companyId, {
    enabled: companyId !== null,
    refetchOnWindowFocus: false,
    onSuccess: (data: UserDto[]) => {
      setUsers(data);
    },
  });

  const deviceLocationsQuery = useDeviceLocationsData(companyId, {
    onSuccess: (data: Map<string, DeviceLocationDto>) => {
      setDeviceLocations(data);
    },
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: t('error_occured'),
      });
    },
  });

  const updateLocation = useUpdateDeviceLocation({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.UseDeviceLocationData]);
      toast({
        type: 'success',
        message: t('successfully_saved'),
      });
    },
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: t('map_err_change_location'),
      });
    },
  });

  const deviceSignalsQuery = useDevicesSignal(companyId, undefined, undefined, {
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: t('error_occured'),
      });
    },
  });

  const deviceBatteriesQuery = useBatteryStatus(companyId, undefined, undefined, {
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        message: t('error_occured'),
      });
    },
  });

  const mapSettingsQuery = useMap(mapName, {
    onSuccess: (data: MapSettings) => {
      const { centerLatitude, centerLongitude, zoomLevel } = data;

      setLatitude(centerLatitude);
      setLongitude(centerLongitude);
      setZoomLevel(zoomLevel);
    },
  });
  const mapSettingsMutation = useUpdateMapSettings({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetMap]);
    },
  });
  // #endregion

  // #region Event Handlers
  const handleMarkerClick = (deviceNumber: string): void => {
    setSelectedDeviceNumber(deviceNumber === selectedDeviceNumber ? null : deviceNumber);
  };

  const handleMarkerDragEnd = (deviceNumber: string, lat: number, lng: number): void => {
    updateLocation.mutate({
      deviceNumber: deviceNumber,
      location: { latitude: lat, longitude: lng },
    });
  };

  const handleMapDragEnd = (event: DragEndEvent): void => {
    const lat = event.target.getCenter().lat;
    const lng = event.target.getCenter().lng;
    const zoom = event.target.getZoom();

    setLatitude(lat);
    setLongitude(lng);
    setZoomLevel(zoom);
  };

  const handleAddOrUpdateLocation = (deviceNumber: string, lat: number, lng: number): void => {
    devicesMapRef.current?.map?.setView([lat, lng]);
    updateLocation.mutate({
      deviceNumber: deviceNumber,
      location: { latitude: lat, longitude: lng },
    });
  };

  const setDevicesMapRef = (instance: { map: LeafletMapType | null }): void => {
    if (instance && devicesMapRef.current !== instance) {
      if (instance.map === null) {
        devicesMapRef.current?.map?.off('dragend', handleMapDragEnd);
      }
      devicesMapRef.current = instance;
      devicesMapRef.current.map?.on('dragend', handleMapDragEnd);
    } else {
      devicesMapRef.current?.map?.off('dragend', handleMapDragEnd);
      devicesMapRef.current = null;
    }
  };
  // #endregion

  if (
    mapSettingsQuery.isLoading ||
    deviceLocationsQuery.isLoading ||
    deviceBatteriesQuery.isLoading ||
    deviceSignalsQuery.isLoading
  ) {
    return (
      <Header>
        <div className="flex justify-center items-center h-full">
          <CircularProgress />
        </div>
      </Header>
    );
  }

  return (
    <Header>
      <Fab
        className="absolute bottom-32 right-10 flex gap-2"
        variant="extended"
        color="secondary"
        onClick={openSetMapSettingsModal}
      >
        <MyLocationIcon />
        <span>{t('map_coordinates_modal_btn_label')}</span>
      </Fab>
      <div className="w-full h-[80vh] relative overflow-auto rounded-xl">
        {deviceLocationsQuery.isSuccess && (
          <div className="z-20 absolute top-3 left-1/2 transform -translate-x-1/2">
            <DeviceLocationEditor
              paramsDeviceNumber={paramsDeviceNumber || null}
              selectedDeviceNumber={selectedDeviceNumber}
              deviceLocations={deviceLocations}
              onChangeLocation={handleAddOrUpdateLocation}
            />
          </div>
        )}
        {deviceLocationsQuery.isSuccess &&
          deviceBatteriesQuery.isSuccess &&
          deviceSignalsQuery.isSuccess &&
          mapSettingsQuery.isSuccess && (
            <DevicesMap
              center={[mapSettingsQuery.data.centerLatitude, mapSettingsQuery.data.centerLongitude]}
              zoom={mapSettingsQuery.isSuccess ? mapSettingsQuery.data.zoomLevel : 15}
              deviceLocations={deviceLocations}
              deviceBatteries={deviceBatteriesQuery.data}
              deviceSignals={deviceSignalsQuery.data}
              selectedDeviceNumber={selectedDeviceNumber}
              deviceNumberFilter={paramsDeviceNumber ? [paramsDeviceNumber] : undefined}
              onMarkerClick={handleMarkerClick}
              onDragEnd={handleMarkerDragEnd}
              ref={setDevicesMapRef}
            />
          )}
      </div>
    </Header>
  );
};

interface LocationProps {}

export default Location;
