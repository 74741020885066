import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { QueryOptions } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';

export interface VersionInfoQueryClient {
  getVersionInfo: () => Promise<VersionInfo>;
  useVersionInfo: (options?: QueryOptions<VersionInfo>) => UseQueryResult<VersionInfo, AxiosError>;
}

export function createVersionInfoQueryClient(axios: AxiosInstance): VersionInfoQueryClient {
  return new VersionInfoQueryClientImp(axios);
}

class VersionInfoQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getVersionInfo = async (): Promise<VersionInfo> => {
    return await this.axios
      .get(`/api/v2/version-info`)
      .then((response: AxiosResponse) => response.data);
  };

  public useVersionInfo = (
    options?: QueryOptions<VersionInfo>
  ): UseQueryResult<VersionInfo, AxiosError> => {
    return useQuery<VersionInfo, AxiosError>(
      [QueryKeys.GetVersionInfo],
      () => this.getVersionInfo(),
      options
    );
  };
}

interface VersionInfo {
  commitIdAbrev: string;
  commitIdFull: string;
  dateTime: string;
  version: string;
}
