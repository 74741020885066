import React, { Component } from 'react';
import { WithRouterProps, withRouter } from '../../common/withRouter';
import { withTranslation, WithTranslation } from 'react-i18next';
import sensorsConfigClient from '../../SensorsConfigClient';
import Header from '../../components/header';
import { Box, Grid, Switch, Theme, Typography } from '@mui/material';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import 'chartjs-plugin-zoom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { getSwitchRelay, postSwitchRelay } from '../../clients/RelaySwitch/RelaySwitchClient';
import { PortConfig } from '../../model/API/PortConfig/PortConfig';
import { AnalogPorts } from '../../model/AnalogPorts/AnalogPorts';
import { DigitalPorts } from '../../model/DigitalPorts/DigitalPorts';
import { PortType } from '../graph/model/PortType';

const DEVICE_NUMBER = 'FFFF1CBA';

export class Relay extends Component<RelayProps, RelayState> {
  public state: RelayState = {
    deviceOnOffSensorName1: '',
    relaySwitchStatusOnOff1: false,
    deviceOnOffSensorName2: '',
    relaySwitchStatusOnOff2: false,
  };

  public componentDidMount = async (): Promise<void> => {
    await this.loadPortsConfig();

    setInterval(async (): Promise<void> => {
      await this.loadRelaySwitchStatuses();
    }, 1000 * 5); // 5 secs
  };

  private loadPortsConfig = async (): Promise<void> => {
    const portsConfigResponse = await sensorsConfigClient.getPortsConfigAsync(DEVICE_NUMBER);
    let deviceOnOffSensorName1 = this.state.deviceOnOffSensorName1;
    let deviceOnOffSensorName2 = this.state.deviceOnOffSensorName2;

    Object.keys(portsConfigResponse).forEach(
      async (value: string, index: number): Promise<void> => {
        const portConfig: PortConfig = portsConfigResponse[index];
        const portType = this.getPortType(portConfig['@type']);
        if (portConfig.enabled && portType === PortType.ON_OFF && index === 4) {
          deviceOnOffSensorName1 = portConfig.sensor.name;
          await this.loadRelaySwitchStatus(4);
        }
        if (portConfig.enabled && portType === PortType.ON_OFF && index === 5) {
          deviceOnOffSensorName2 = portConfig.sensor.name;
          await this.loadRelaySwitchStatus(5);
        }
      }
    );

    this.setState({ deviceOnOffSensorName1, deviceOnOffSensorName2 });
  };

  private getPortType = (type: string): PortType => {
    for (const key of this.enumKeys(AnalogPorts)) {
      if (AnalogPorts[key] === type) return PortType.ANALOG;
    }
    for (const key of this.enumKeys(DigitalPorts)) {
      if (DigitalPorts[key] === type) return PortType.DIGITAL;
    }
    return PortType.ON_OFF;
  };

  private enumKeys<E>(e: E): (keyof E)[] {
    return Object.keys(e) as (keyof E)[];
  }

  private loadRelaySwitchStatuses = async (): Promise<void> => {
    await this.loadRelaySwitchStatus(4);
    await this.loadRelaySwitchStatus(5);
  };

  private loadRelaySwitchStatus = async (index: number): Promise<void> => {
    const deviceNumber = DEVICE_NUMBER;
    const response = await getSwitchRelay(deviceNumber, index);
    const status = response === 'OFF' ? false : true;

    if (index === 4) {
      this.setState({ relaySwitchStatusOnOff1: status });
    } else if (index === 5) {
      this.setState({ relaySwitchStatusOnOff2: status });
    }
  };

  private postRelaySwitchChange = async (index: number): Promise<void> => {
    const deviceNumber = DEVICE_NUMBER;
    if (index === 4) {
      await postSwitchRelay(deviceNumber, index, this.state.relaySwitchStatusOnOff1 ? 'OFF' : 'ON');
    } else if (index === 5) {
      await postSwitchRelay(deviceNumber, index, this.state.relaySwitchStatusOnOff2 ? 'OFF' : 'ON');
    }
  };

  private toggleRelaySwitch = async (index: number): Promise<void> => {
    await this.postRelaySwitchChange(index);
    await this.loadRelaySwitchStatus(index);
  };

  public render = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Header>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={7}>
            <Accordion expanded={false}>
              <AccordionSummary expandIcon={null}>
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid item>
                    <Typography variant="h6">
                      <Box fontWeight={600}> {this.state.deviceOnOffSensorName1}</Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      color="primary"
                      onClick={async (): Promise<void> => {
                        await this.toggleRelaySwitch(4);
                      }}
                      checked={this.state.relaySwitchStatusOnOff1}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          </Grid>
          <Grid xs={7}>
            <Accordion expanded={false}>
              <AccordionSummary expandIcon={null}>
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid item>
                    <Typography variant="h6">
                      <Box fontWeight={600}> {this.state.deviceOnOffSensorName2}</Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      color="primary"
                      onClick={async (): Promise<void> => {
                        await this.toggleRelaySwitch(5);
                      }}
                      checked={this.state.relaySwitchStatusOnOff2}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          </Grid>
        </Grid>
      </Header>
    );
  };
}

const styles: StyleRulesCallback<Theme, RelayPropsWithoutStyles> = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  });

interface RelayPropsWithoutStyles extends WithRouterProps, WithTranslation {}
interface RelayProps extends RelayPropsWithoutStyles, WithStyles<typeof styles> {}

interface RelayState {
  deviceOnOffSensorName1: string;
  relaySwitchStatusOnOff1: boolean;
  deviceOnOffSensorName2: string;
  relaySwitchStatusOnOff2: boolean;
}

interface MatchParams {
  deviceNumber: string;
}

export default withRouter(withStyles(styles, { withTheme: true })(withTranslation()(Relay)));
