import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ReferenceLine,
  ReferenceLineProps,
} from 'recharts';
import {
  CartesianGridProps,
  ResponsiveContainerProps,
  XAxisProps,
  YAxisProps,
  LineProps,
  TooltipProps,
} from './RechartsProps';
import { ValueType, NameType, Payload } from 'recharts/types/component/DefaultTooltipContent';

const LineChart: FC<LineChartProps> = ({
  container: containerProps,
  grid: gridProps,
  tooltip: tooltipCustomProps,
  xAxis: xAxisProps,
  yAxis: yAxisProps,
  lineChart: lineChartProps,
  lines: lineProps,
  referenceLines: referenceLinesProps,
}: LineChartProps) => {
  return (
    <ResponsiveContainer {...containerProps}>
      <RechartsLineChart {...lineChartProps}>
        <CartesianGrid {...gridProps} />
        <Tooltip {...tooltipCustomProps} />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />

        {lineProps && lineProps.map((lineProp: Omit<LineProps, 'ref'>) => <Line {...lineProp} />)}
        {referenceLinesProps?.map((referenceLineProp: ReferenceLineProps) => (
          <ReferenceLine {...referenceLineProp} />
        ))}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};

interface LineChartProps {
  container: Omit<ResponsiveContainerProps, 'children'>;
  grid: Omit<CartesianGridProps, 'ref'>;
  tooltip?: TooltipProps<ValueType, NameType>;
  xAxis: XAxisProps;
  yAxis: YAxisProps;
  referenceLines?: ReferenceLineProps[];
  lineChart: {
    width?: number;
    height?: number;
    data: object[];
    margin?: {
      top?: number;
      right?: number;
      bottom?: number;
      left?: number;
    };
  };
  lines?: Omit<LineProps, 'ref'>[];
}

export type {
  CartesianGridProps,
  ResponsiveContainerProps,
  XAxisProps,
  YAxisProps,
  LineProps,
  TooltipProps,
  ReferenceLineProps,
};

export default LineChart;
