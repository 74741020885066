import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import RedirectHelper from '../../common/RedirectHelper';

const BasePage: React.FC<BasePageProps> = (props: BasePageProps) => {
  const redirectHelper = useMemo(() => new RedirectHelper(), []);
  const navigate = useNavigate();

  useEffect((): void => {
    if (redirectHelper.shouldRedirectToPortal()) {
      redirectHelper.redirectToPortal();
    } else {
      navigate('/app');
    }
  }, [redirectHelper]);

  return <></>;
};

interface BasePageProps {}

export default BasePage;
