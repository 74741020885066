import { AxiosResponse } from 'axios';
import Axios from '../Axios/Axios';
import Device from '../../model/API/Device/Device';

export default class DeviceRepository {
  public getDevices = async (number: string): Promise<Device> => {
    return await Axios.get(`/api/v2/devices/${number}/`).then(
      (response: AxiosResponse) => response.data
    );
  };
}
