import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation } from '@tanstack/react-query';
import { MutationOptions, MutationResult } from '@thingslog/repositories';

export interface LinkDeviceQueryClient {
  linkDevice: (deviceNumber: string, companyId: number) => Promise<void>;
  useLinkDevice: (
    options?: MutationOptions<void, LinkUnlinkDeviceVariables>
  ) => MutationResult<void, LinkUnlinkDeviceVariables>;
  unlinkDevice: (deviceNumber: string, companyId: number) => Promise<void>;
  useUnlinkDevice: (
    options?: MutationOptions<void, LinkUnlinkDeviceVariables>
  ) => MutationResult<void, LinkUnlinkDeviceVariables>;
}

export function createLinkDeviceQueryClient(axios: AxiosInstance): LinkDeviceQueryClient {
  return new LinkDeviceQueryImp(axios);
}

class LinkDeviceQueryImp {
  public constructor(private axios: AxiosInstance) {}

  public linkDevice = async (deviceNumber: string, companyId: number): Promise<void> => {
    return await this.axios
      .post(`/api/link_devices/${deviceNumber}/${companyId}`)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  public useLinkDevice = (
    options?: MutationOptions<void, LinkUnlinkDeviceVariables>
  ): MutationResult<void, LinkUnlinkDeviceVariables> => {
    return useMutation<void, AxiosError, LinkUnlinkDeviceVariables>(
      [QueryKeys.LinkDevice],
      (params: LinkUnlinkDeviceVariables) => this.linkDevice(params.deviceNumber, params.companyId),
      options
    );
  };

  public unlinkDevice = async (deviceNumber: string, companyId: number): Promise<void> => {
    return await this.axios
      .delete(`/api/link_devices/${deviceNumber}/${companyId}`)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  public useUnlinkDevice = (
    options?: MutationOptions<void, LinkUnlinkDeviceVariables>
  ): MutationResult<void, LinkUnlinkDeviceVariables> => {
    return useMutation<void, AxiosError, LinkUnlinkDeviceVariables>(
      [QueryKeys.UnlinkDevice],
      (params: LinkUnlinkDeviceVariables) =>
        this.unlinkDevice(params.deviceNumber, params.companyId),
      options
    );
  };
}

interface LinkUnlinkDeviceVariables {
  deviceNumber: string;
  companyId: number;
}
