export default interface Jwt {
  sub: string;
  companyId: string;
  roles: JwtRole[];
  companyName: string;
  language: string;
  exp: number;
  email: string;
  isDashboardEnabled: boolean;
  solution: AccountSolution | null;
}

export interface JwtRole {
  authority: UserRole;
}

export enum AccountSolution {
  AGRI = 'AGRI',
  STREET_LIGHTS = 'STREET_LIGHTS',
  AIR_QUALITY = 'AIR_QUALITY',
  PUMP_STATION = 'PUMP_STATION',
}

export type UserRole =
  | 'ROLE_USER'
  | 'ROLE_ADMIN'
  | 'ROLE_RESTRICTED'
  | 'ROLE_SUPER_ADMIN'
  | 'ROLE_AGRICULTURE'
  | 'ROLE_METER_READER'
  | 'ROLE_BILLING'
  | 'ROLE_PUBLIC';
