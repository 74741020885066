import { Chip, IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Sensor } from '@thingslog/repositories';
import { SensorsUtil } from '../../common/SensorsUtil';
import { Delete } from '@mui/icons-material';
import { GRAPH_SENSORS_LIMIT } from './GraphConstants';

const SelectedSensorsDisplay: FC<SelectedSensorsDisplayProps> = ({
  selectedSensors,
  onRemoveClicked,
  onRemoveAllSensorsClicked,
}: SelectedSensorsDisplayProps) => {
  const { t } = useTranslation();

  const isColorDark = (hexColor: string): boolean => {
    const red = parseInt(hexColor.substring(1, 3), 16);
    const green = parseInt(hexColor.substring(3, 5), 16);
    const blue = parseInt(hexColor.substring(5, 7), 16);
    const luminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;
    return luminance < 0.55;
  };

  return (
    <>
      <div className="bg-zinc-200 rounded-2xl w-full mt-10 text-left pb-10 pt-4">
        <div className="w-full flex justify-between">
          <div className="text-gray-600 font-semibold pl-10">
            {t('new_graph_selected_sensors_heading')}
          </div>
          <div className="mr-5">
            {selectedSensors.length !== 0 && (
              <Tooltip
                arrow
                placement="top-start"
                title={<span>{t('new_graph_selected_sensors_remove_all_tooltip')}</span>}
              >
                <IconButton className="mr-1" size="small" onClick={onRemoveAllSensorsClicked}>
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            <Chip size="small" label={`${selectedSensors.length}/${GRAPH_SENSORS_LIMIT}`} />
          </div>
        </div>
        <div className="flex flex-wrap gap-3 mx-3 max-sm:mt-3">
          {selectedSensors.map((entry: SelectedSensorsEntry) => {
            const { deviceName, deviceNumber, sensor, sensorIndex, color } = entry;
            const units = SensorsUtil.getSensorUnits(sensor);

            const displayUnits = units ? `| ${units}` : '';
            const label = `${deviceName || deviceNumber} - ${sensor.name} ${displayUnits}`;
            return (
              <Chip
                key={`${deviceNumber}-${sensorIndex}`}
                className={clsx('text-sm', isColorDark(color) ? 'text-white' : 'text-black')}
                sx={{ background: color }}
                size="small"
                label={label}
                onDelete={(): void => onRemoveClicked(deviceNumber, sensorIndex)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

interface SelectedSensorsDisplayProps {
  selectedSensors: SelectedSensorsEntry[];
  onRemoveClicked: (deviceNumber: string, sensorIndex: number) => void;
  onRemoveAllSensorsClicked: () => void;
}

export interface SelectedSensorsEntry {
  deviceName: string;
  deviceNumber: string;
  sensor: Sensor;
  sensorIndex: number;
  color: string;
}

export default SelectedSensorsDisplay;
