export enum PeriodMode {
  LAST_1_HOUR,
  LAST_2_HOURS,
  LAST_4_HOURS,
  LAST_24_HOURS,
  LAST_48_HOURS,
  TODAY,
  WEEK,
  MONTH,
  CUSTOM,
  DATE_RANGE,
  YESTERDAY,
  LAST_WEEK,
  LAST_MONTH,
}
