import React from 'react';
import { PickerPeriod } from '../../model/Pickers/PickerPeriod';
import ConnectedDatePicker from '../PeriodPicker/ConnectedDatePicker';
import ConnectedDateTimePicker from '../PeriodPicker/ConnectedDateTimePicker';
import PeriodModePicker from '../PeriodPicker/PeriodModePicker';
import { Grid } from '@mui/material';
import usePeriodHelper from './hooks/usePeriodHelper';
import clsx from 'clsx';

const PeriodPicker: React.FC<PeriodPickerProps> = ({
  datePickerClassName,
  hidePeriodSelector = false,
  alwaysShowDatePickers = false,
  dateDifference,
}: PeriodPickerProps) => {
  const {
    displayFromDatePicker,
    displayFromDateTimePicker,
    displayToDatePicker,
    displayToDateTimePicker,
  } = usePeriodHelper(alwaysShowDatePickers);

  // The hook automatically changes period mode to date range
  // if alwaysShowDatePickers is true
  if (alwaysShowDatePickers) {
    return (
      <React.Fragment>
        <Grid item className="max-md:w-full">
          <ConnectedDatePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
        </Grid>
        <Grid item className="max-md:w-full max-md:mt-4">
          <ConnectedDatePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {!hidePeriodSelector && (
        <Grid item className="max-md:w-full">
          <PeriodModePicker />
        </Grid>
      )}
      {displayFromDatePicker && (
        <Grid item className={clsx('max-md:w-full', datePickerClassName)}>
          <ConnectedDatePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
        </Grid>
      )}
      {displayToDatePicker && (
        <Grid item className={clsx('max-md:w-full', datePickerClassName)}>
          <ConnectedDatePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
        </Grid>
      )}
      {displayFromDateTimePicker && (
        <Grid item className={clsx('max-md:w-full', datePickerClassName)}>
          <ConnectedDateTimePicker period={PickerPeriod.FROM} dateDifference={dateDifference} />
        </Grid>
      )}
      {displayToDateTimePicker && (
        <Grid item className={clsx('max-md:w-full', datePickerClassName)}>
          <ConnectedDateTimePicker period={PickerPeriod.TO} dateDifference={dateDifference} />
        </Grid>
      )}
    </React.Fragment>
  );
};

interface PeriodPickerProps {
  datePickerClassName?: string;
  hidePeriodSelector?: boolean;
  alwaysShowDatePickers?: boolean;
  dateDifference?: number;
}

export default PeriodPicker;
