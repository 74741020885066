import { Action } from 'redux';
import { PeriodMode } from '../../model/Pickers/PeriodMode';

export enum PeriodSelectionActionType {
  PERIOD_FROM_DATE_CHANGED = 'PERIOD_FROM_DATE_CHANGED',
  PERIOD_TO_DATE_CHANGED = 'PERIOD_TO_DATE_CHANGED',
  PERIOD_AVG_FROM_DATE_CHANGED = 'PERIOD_AVG_FROM_DATE_CHANGED',
  PERIOD_AVG_TO_DATE_CHANGED = 'PERIOD_AVG_TO_DATE_CHANGED',
  PERIOD_TIMESPAN_MODE_CHANGED = 'PERIOD_TIMESPAN_MODE_CHANGED',
}

export type PeriodSelectionAction = Action<PeriodSelectionActionType>;

export interface FromDatePeriodAction extends PeriodSelectionAction {
  fromDate: Date;
  type: PeriodSelectionActionType.PERIOD_FROM_DATE_CHANGED;
}

export interface ToDatePeriodAction extends PeriodSelectionAction {
  toDate: Date;
  type: PeriodSelectionActionType.PERIOD_TO_DATE_CHANGED;
}

export interface AvgFromDatePeriodAction extends PeriodSelectionAction {
  avgFromDate: Date;
  type: PeriodSelectionActionType.PERIOD_AVG_FROM_DATE_CHANGED;
}

export interface AvgToDatePeriodAction extends PeriodSelectionAction {
  avgToDate: Date;
  type: PeriodSelectionActionType.PERIOD_AVG_TO_DATE_CHANGED;
}

export interface PeriodModePeriodAction extends PeriodSelectionAction {
  periodMode: PeriodMode;
  type: PeriodSelectionActionType.PERIOD_TIMESPAN_MODE_CHANGED;
}
