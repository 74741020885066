import React, { FC, useEffect, useState } from 'react';
import {
  DisplayUnit,
  UtilityRateBareboneDto,
  UtilityRateCurrency,
  UtilityRateDto,
} from '@thingslog/repositories';
import { DateUtil } from '../../../common/DateUtil';
import { utilityRateQueryClient } from '../../../clients/ReactQueryClients/ReactQueryClients';
import { useTranslation } from 'react-i18next';

const ViewUtilRateModal: FC<Props> = ({ utilityRate }: Props) => {
  const { id } = utilityRate;
  const { t } = useTranslation();
  const { useUtilityRate } = utilityRateQueryClient;
  const { isLoading, isError, data } = useUtilityRate(id);

  if (isLoading) {
    return <div>{t('util_rates_loading')}</div>;
  }

  if (isError) {
    return <div>{t('util_rates_error')}</div>;
  }

  return (
    <section className="md:w-96 flex flex-col">
      {data.companyName && (
        <div className="grid grid-cols-5">
          <span className="col-span-2 text-gray-700 font-semibold">{t('util_rate_company')}</span>
          <span className="col-span-3">{data.companyName}</span>
        </div>
      )}
      <div className="grid grid-cols-5">
        <span className="col-span-2 text-gray-700 font-semibold">{t('util_rates_price')}</span>
        <span className="col-span-3">{data.price}</span>
      </div>
      <div className="grid grid-cols-5">
        <span className="col-span-2 text-gray-700 font-semibold">{t('util_rates_unit')}</span>
        <span className="col-span-3">{DisplayUnit[data.unit]}</span>
      </div>
      <div className="grid grid-cols-5">
        <span className="col-span-2 text-gray-700 font-semibold">{t('util_rates_currency')}</span>
        <span className="col-span-3">{data.utilityRateCurrency}</span>
      </div>
      <div className="grid grid-cols-5">
        <span className="col-span-2 text-gray-700 font-semibold">{t('util_rate_created')}</span>
        <span className="col-span-3">
          {DateUtil.formatTimeZoneAgnosticDate(data.creationDate.toString())}
        </span>
      </div>
      <div className="grid grid-cols-5">
        <span className="col-span-2 text-gray-700 font-semibold">{t('util_rates_updated')}</span>
        <span className="col-span-3">
          {DateUtil.formatTimeZoneAgnosticDate(data.lastUpdateDate.toString())}
        </span>
      </div>
    </section>
  );
};

interface Props {
  utilityRate: UtilityRateBareboneDto;
}

export default ViewUtilRateModal;
