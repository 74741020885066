import React, { FC, useMemo, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';

import {
  Pagination,
  ExportFormats,
  ConsumptionExportDto,
  AggregationOptions,
} from '@thingslog/repositories';
import Header from '../../components/header';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import MultipleSelectTable, {
  MultipleSelectTableOption,
} from '../../components/MultipleSelectTable/MultipleSelectTable';
import { useTranslation } from 'react-i18next';
import ConsumptionTable from './ConsumptionTable';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';

import { Device } from '@thingslog/repositories';

import DeviceSensorsDto from '@thingslog/repositories/src/deviceSensorsDto/DeviceSensorsDto';

import {
  devicesQueryClient,
  multipleConsumptionExportQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';

const ConsumptionExport: FC<ConsumptionExportProps> = (props: ConsumptionExportProps) => {
  const { useDevicesData } = useMemo(() => devicesQueryClient, []);
  const {
    useMultipleConsumptionExportCSV,
    useMultipleConsumptionExportExcel,
    useMultipleConsumptionExportJSON,
  } = useMemo(() => multipleConsumptionExportQueryClient, []);

  const { t } = useTranslation();

  const companyId = useSelector((state: ReduxState) => state.company.id);
  const toDateTz = useSelector((state: ReduxState) => state.period.toDateTz);
  const fromDateTz = useSelector((state: ReduxState) => state.period.fromDateTz);

  const [aggregationPeriod, setAggregationPeriod] = useState<AggregationOptions | null>(null);
  const [isConsumptionExportAggregated, setIsConsumptionReportAggregated] =
    useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<ExportFormats>('Excel');
  const [data, setData] = useState<ConsumptionExportDto[]>([]);
  const [deviceTableOptions, setDeviceTableOptions] = useState<MultipleSelectTableOption<string>[]>(
    []
  );
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [selectedSensors, setSelectedSensors] = useState<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ]);

  const aggregationSelectOptions: Record<AggregationOptions | 'NONE', string> = {
    NONE: t('consumption_export_aggregation_options_none'),
    DAILY: t('consumption_export_aggregation_options_daily'),
    WEEKLY: t('consumption_export_aggregation_options_weekly'),
    MONTHLY: t('consumption_export_aggregation_options_monthly'),
    YEARLY: t('consumption_export_aggregation_options_yearly'),
  };

  //query for devices
  const { isLoading: isLoadingDevices } = useDevicesData(companyId, 0, 9999, null, null, {
    onSuccess: (data: Pagination<Device>) => {
      let deviceTableOptions = data.content.map((device: Device) => {
        return {
          value: device.number,
          label: device.name ? `${device.name} (${device.number})` : device.number,
        };
      });
      setDeviceTableOptions(deviceTableOptions);
    },
    onError: () => {
      alert(t('counters_export_error_alert_message'));
    },
    refetchOnWindowFocus: false,
  });
  //mutations for json load and export
  const {
    mutate: jsonMutate,
    isLoading: isJSONLoading,
    isSuccess,
  } = useMultipleConsumptionExportJSON({
    onSuccess: (data: ConsumptionExportDto[]) => {
      setData(data);
    },
    onError: () => {
      alert(t('counters_export_error_alert_message'));
    },
  });

  const { mutate: exportMutateCSV, isLoading: isExportingCSV } = useMultipleConsumptionExportCSV({
    onSuccess: (data: ArrayBuffer) => {
      let fileExtension = 'csv';
      const fileName = `ConsumptionExport-${exportFormat}-from-${fromDateTz.toISOString()}-to-${toDateTz.toISOString()}.${fileExtension}`;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
    },
    onError: () => {
      alert(t('counters_export_error_alert_message'));
    },
  });

  const { mutate: exportMutateExcel, isLoading: isExportingExcel } =
    useMultipleConsumptionExportExcel({
      onSuccess: (data: ArrayBuffer) => {
        let fileExtension = 'xlsx';
        const fileName = `ConsumptionExport-${exportFormat}-from-${fromDateTz.toISOString()}-to-${toDateTz.toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      },
      onError: () => {
        alert(t('counters_export_error_alert_message'));
      },
    });

  const formDeviceSensorsOptions = (): DeviceSensorsDto[] => {
    const deviceSensorOptions = selectedDevices.map((deviceNumber: string) => {
      return {
        deviceNumber: deviceNumber,
        sensorIndexes: selectedSensors.sort((a: number, b: number) => {
          return a - b;
        }),
      };
    });

    return deviceSensorOptions;
  };

  const downloadHandler = (): void => {
    const deviceSensors = formDeviceSensorsOptions();
    switch (exportFormat) {
      case 'CSV':
        exportMutateCSV({
          body: {
            deviceSensors: deviceSensors,
            fromDate: fromDateTz,
            toDate: toDateTz,
          },
          fetchType: aggregationPeriod || undefined,
        });
        break;
      case 'Excel':
        exportMutateExcel({
          body: {
            deviceSensors: deviceSensors,
            fromDate: fromDateTz,
            toDate: toDateTz,
          },
          fetchType: aggregationPeriod || undefined,
        });
        break;
      default:
        break;
    }
  };

  const loadJsonDataHandler = (): void => {
    const deviceSensors = formDeviceSensorsOptions();
    jsonMutate({
      body: {
        deviceSensors: deviceSensors,
        fromDate: fromDateTz,
        toDate: toDateTz,
      },
      fetchType: aggregationPeriod || undefined,
    });
    setIsConsumptionReportAggregated(!!aggregationPeriod);
  };

  const disableButtons = (): boolean => {
    return isLoadingDevices || isJSONLoading || isExportingCSV || isExportingExcel;
  };

  return (
    <Header>
      <Container>
        <section className="flex justify-center mb-6">
          <TitleHeader title={t('consumption_export_header')} />
        </section>
        <section className="flex justify-between flex-wrap">
          <div className="flex flex-wrap md:space-x-2">
            <PeriodPicker alwaysShowDatePickers />
            <Button
              variant="contained"
              color="primary"
              className="max-md:w-full max-md:mt-4"
              disableElevation
              startIcon={isJSONLoading ? <CircularProgress size={15} /> : <SearchIcon />}
              disabled={disableButtons()}
              onClick={loadJsonDataHandler}
            >
              {t('consumption_export_search')}
            </Button>
          </div>
          <div className="flex space-x-2 max-lg:mt-4 max-md:w-full">
            <FormControl className="max-md:w-1/3 w-44">
              <InputLabel>{t('consumption_export_aggregation_period')}</InputLabel>
              <Select
                size="small"
                value={aggregationPeriod || 'NONE'}
                label={t('consumption_export_aggregation_period')}
                onChange={(e: SelectChangeEvent<AggregationOptions | 'NONE'>): void => {
                  setAggregationPeriod(
                    e.target.value !== 'NONE' ? (e.target.value as AggregationOptions) : null
                  );
                }}
              >
                {Object.entries(aggregationSelectOptions).map(([key, value]: [string, string]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="max-md:w-1/3">
              <InputLabel>{t('consumption_export_format')}</InputLabel>
              <Select
                size="small"
                value={exportFormat}
                label={t('consumption_export_format')}
                onChange={(e: SelectChangeEvent<ExportFormats>): void => {
                  setExportFormat(e.target.value as ExportFormats);
                }}
              >
                <MenuItem value={'CSV'}>CSV</MenuItem>
                <MenuItem value={'Excel'}>Excel</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="max-md:w-1/3"
              disableElevation
              startIcon={
                isExportingCSV || isExportingExcel ? (
                  <CircularProgress size={15} />
                ) : (
                  <FileDownloadIcon />
                )
              }
              onClick={downloadHandler}
              disabled={disableButtons()}
            >
              {t('consumption_export_download')}
            </Button>
          </div>
        </section>
        <section>
          <div className="w-full my-5">
            <MultipleSelectTable
              title={t('counter_export_select_devices_label')}
              options={deviceTableOptions}
              selectedOptions={selectedDevices}
              setSelectedOptions={(options: (string | number)[]): void =>
                setSelectedDevices(options as string[])
              }
            />
          </div>
        </section>
        <section>
          <div className="w-full my-5">
            {isJSONLoading ? (
              <CircularProgress />
            ) : (
              <>
                {isSuccess && (
                  <ConsumptionTable
                    rows={data}
                    density="compact"
                    getRowId={(row: ConsumptionExportDto): string =>
                      row.deviceNumber +
                      row.sensorIndex +
                      row.firstReadingDate +
                      row.lastReadingDate
                    }
                    isAggregated={isConsumptionExportAggregated}
                  />
                )}
              </>
            )}
          </div>
        </section>
      </Container>
    </Header>
  );
};

interface ConsumptionExportProps {}

export default ConsumptionExport;
