import Axios from './clients/Axios/Axios';

function getSensorsConfig(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/sensors`).then(parseJSON).then(cb).catch(ecb);
}

function getAlarmSensorConfig(deviceNumber, sensorIndex, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/sensors/${sensorIndex}/config/alarms`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function putAlarmSensorConfig(deviceNumber, sensorIndex, deviceConfig, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(
    `/api/devices/${deviceNumber}/sensors/${sensorIndex}/config/alarms`,
    deviceConfig,
    config
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function putSensorsConfig(deviceNumber, deviceConfig, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/devices/${deviceNumber}/sensors`, deviceConfig, config)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const AlarmConfigClient = {
  getSensorsConfig,
  getAlarmSensorConfig,
  putAlarmSensorConfig,
  putSensorsConfig,
};

export default AlarmConfigClient;
