import { IconButton, SvgIconTypeMap, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

class TooltipIconButton extends React.Component<TooltipIconButtonProps, TooltipIconButtonState> {
  public state: TooltipIconButtonState = {
    isHovered: false,
  };

  private handleHoverChange = (isHovered: boolean): void => {
    this.setState({ isHovered });
  };

  public render(): React.ReactNode {
    return (
      <Tooltip title={this.props.tooltipTitle} placement="top" arrow>
        <IconButton
          size="small"
          color={this.props.color}
          onClick={this.props.onClick}
          style={{ color: this.state.isHovered ? this.props.highlightColor : grey[600] }}
          onMouseEnter={(): void => this.handleHoverChange(true)}
          onMouseLeave={(): void => this.handleHoverChange(false)}
        >
          {this.props.icon}
        </IconButton>
      </Tooltip>
    );
  }
}

interface TooltipIconButtonProps {
  tooltipTitle: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon: React.ReactNode;
  highlightColor: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
}

interface TooltipIconButtonState {
  isHovered: boolean;
}

export default TooltipIconButton;
