import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import {
  DeviceFlowAvgQueryParams,
  DeviceFlowQueryParams,
  QueryOptions,
} from '@thingslog/repositories';
import { TimeSeriesData } from '@thingslog/repositories';

export function createDeviceFlowQueryClient(axios: AxiosInstance): DeviceFlowQueryClient {
  return new DeviceFlowQueryClientImp(axios);
}

class DeviceFlowQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public useDevicesFlowQueries = (
    args: DeviceFlowQueryParams<TimeSeriesData<number>[]>[]
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError>[] =>
    useQueries({
      queries: args.map(
        ({
          deviceNumbers,
          sensorIndex,
          fromDate,
          toDate,
          every,
          options,
        }: DeviceFlowQueryParams<TimeSeriesData<number>[]>) => ({
          queryKey: [QueryKeys.GetDevicesFlow, deviceNumbers, sensorIndex, fromDate, toDate, every],
          queryFn: () => this.getDevicesFlow(deviceNumbers, sensorIndex, fromDate, toDate, every),
          ...options,
        })
      ),
    });

  public useDevicesFlowAvgQueries = (
    args: DeviceFlowAvgQueryParams<TimeSeriesData<number>[]>[]
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError>[] =>
    useQueries({
      queries: args.map(
        ({
          deviceNumbers,
          sensorIndex,
          avgFromDate,
          avgEndDate,
          fromDate,
          toDate,
          every,
          options,
        }: DeviceFlowAvgQueryParams<TimeSeriesData<number>[]>) => ({
          queryKey: [
            QueryKeys.GetDevicesFlowAvg,
            deviceNumbers,
            sensorIndex,
            avgFromDate,
            avgEndDate,
            fromDate,
            toDate,
            every,
          ],
          queryFn: () =>
            this.getDevicesFlowAvg(
              deviceNumbers,
              sensorIndex,
              avgFromDate,
              avgEndDate,
              fromDate,
              toDate,
              every
            ),
          ...options,
        })
      ),
    });

  public useDevicesFlow = (
    deviceNumbers: string[],
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number,
    options?: QueryOptions<TimeSeriesData<number>[]>
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError> => {
    return useQuery<TimeSeriesData<number>[], AxiosError>(
      [QueryKeys.GetDevicesFlow, deviceNumbers, sensorIndex, fromDate, toDate, every],
      () => this.getDevicesFlow(deviceNumbers, sensorIndex, fromDate, toDate, every),
      options
    );
  };

  public useDevicesFlowAvg = (
    deviceNumbers: string[],
    sensorIndex: number,
    avgFromDate: Date,
    avgEndDate: Date,
    fromDate: Date,
    toDate: Date,
    every: number,
    options?: QueryOptions<TimeSeriesData<number>[]>
  ): UseQueryResult<TimeSeriesData<number>[], AxiosError> => {
    return useQuery<TimeSeriesData<number>[], AxiosError>(
      [
        QueryKeys.GetDevicesFlowAvg,
        deviceNumbers,
        sensorIndex,
        avgFromDate,
        avgEndDate,
        fromDate,
        toDate,
        every,
      ],
      () =>
        this.getDevicesFlowAvg(
          deviceNumbers,
          sensorIndex,
          avgFromDate,
          avgEndDate,
          fromDate,
          toDate,
          every
        ),
      options
    );
  };

  public getDevicesFlow = async (
    deviceNumbers: string[],
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number
  ): Promise<TimeSeriesData<number>[]> => {
    return await this.axios
      .get(`/api/devices/${deviceNumbers}/${sensorIndex}/flows/`, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          every: every,
        },
      })
      .then((response: AxiosResponse) => {
        const values = response.data[0].value;
        const timeSeriesData: TimeSeriesData<number>[] = [];
        values.forEach(({ date, flow }: { date: Date; flow: number }) => {
          const timestamp = new Date(date).getTime();
          const flowDataPoint: TimeSeriesData<number> = { timestamp: timestamp, value: flow };
          timeSeriesData.push(flowDataPoint);
        });

        return timeSeriesData;
      });
  };

  public getDevicesFlowAvg = async (
    deviceNumbers: string[],
    sensorIndex: number,
    avgFromDate: Date,
    avgEndDate: Date,
    fromDate: Date,
    toDate: Date,
    every: number
  ): Promise<TimeSeriesData<number>[]> => {
    const targetStartDate = new Date(fromDate).toISOString().substring(0, 10);
    const targetEndDate = new Date(toDate).toISOString().substring(0, 10);
    return await this.axios
      .get(`/api/devices/%5B${deviceNumbers}%5D/${sensorIndex}/flows/avg/`, {
        params: {
          fromDate: avgFromDate,
          toDate: avgEndDate,
          targetFromDate: targetStartDate,
          targetToDate: targetEndDate,
          every: every,
        },
      })
      .then((response: AxiosResponse) => {
        const values = response.data[0].value;
        const timeSeriesData: TimeSeriesData<number>[] = [];
        values.forEach(({ date, flow }: { date: Date; flow: number }) => {
          const timestamp = new Date(date).getTime();
          const flowDataPoint: TimeSeriesData<number> = { timestamp: timestamp, value: flow };
          timeSeriesData.push(flowDataPoint);
        });

        return timeSeriesData;
      });
  };
}

export interface DeviceFlowQueryClient {
  useDevicesFlowQueries: (
    args: DeviceFlowQueryParams<TimeSeriesData<number>[]>[]
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>[];

  useDevicesFlowAvgQueries: (
    args: DeviceFlowAvgQueryParams<TimeSeriesData<number>[]>[]
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>[];

  useDevicesFlow: (
    deviceNumbers: string[],
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number,
    options?: QueryOptions<TimeSeriesData<number>[]>
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>;

  useDevicesFlowAvg: (
    deviceNumbers: string[],
    sensorIndex: number,
    avgFromDate: Date,
    avgEndDate: Date,
    fromDate: Date,
    toDate: Date,
    every: number,
    options?: QueryOptions<TimeSeriesData<number>[]>
  ) => UseQueryResult<TimeSeriesData<number>[], AxiosError>;

  getDevicesFlow: (
    deviceNumbers: string[],
    sensorIndex: number,
    fromDate: Date,
    toDate: Date,
    every: number
  ) => Promise<TimeSeriesData<number>[]>;

  getDevicesFlowAvg: (
    deviceNumbers: string[],
    sensorIndex: number,
    avgFromDate: Date,
    avgEndDate: Date,
    fromDate: Date,
    toDate: Date,
    every: number
  ) => Promise<TimeSeriesData<number>[]>;
}
