import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  QueryOptions,
  MutationOptions,
  QueryResult,
  MutationResult,
  CompanyDto,
  CreateCompanyDto,
  UpdateCompanyDto,
} from '@thingslog/repositories';

export interface CompanyQueryClient {
  useGetAllCompanies: (options?: QueryOptions<CompanyDto[]>) => QueryResult<CompanyDto[]>;
  useCreateCompany: (
    options?: MutationOptions<void, CreateCompanyDto>
  ) => MutationResult<void, CreateCompanyDto>;
  createCompany: (body: CreateCompanyDto) => Promise<void>;
  getAllCompanies: () => Promise<CompanyDto[]>;
  useDeleteCompany: (options?: MutationOptions<void, string>) => MutationResult<void, string>;
  deleteCompany: (companyName: string) => Promise<void>;
  useUpdateCompany: (
    options?: MutationOptions<void, UpdateCompanyDto>
  ) => MutationResult<void, UpdateCompanyDto>;
  updateCompany: (body: UpdateCompanyDto) => Promise<void>;
  useGetCompany: (companyId: number, options?: QueryOptions<CompanyDto>) => QueryResult<CompanyDto>;
  getCompany: (companyId: number) => Promise<CompanyDto>;
}

export function createCompanyQueryClient(axios: AxiosInstance): CompanyQueryClient {
  return new CompanyQueryImp(axios);
}

class CompanyQueryImp {
  public constructor(private axios: AxiosInstance) {}

  public useGetAllCompanies = (options?: QueryOptions<CompanyDto[]>): QueryResult<CompanyDto[]> => {
    return useQuery<CompanyDto[], AxiosError>(
      [QueryKeys.GetAllCompanies],
      () => this.getAllCompanies(),
      options
    );
  };

  public getAllCompanies = async (): Promise<CompanyDto[]> => {
    return await this.axios.get(`/api/companies`).then((response: AxiosResponse<CompanyDto[]>) => {
      return response.data;
    });
  };

  public useCreateCompany = (
    options?: MutationOptions<void, CreateCompanyDto>
  ): MutationResult<void, CreateCompanyDto> => {
    return useMutation<void, AxiosError, CreateCompanyDto>(
      [QueryKeys.CreateCompany],
      (variables: CreateCompanyDto) => this.createCompany(variables),
      options
    );
  };

  public createCompany = async (body: CreateCompanyDto): Promise<void> => {
    return await this.axios.post(`/api/companies`, body).then((response: AxiosResponse<void>) => {
      return response.data;
    });
  };

  public useDeleteCompany = (
    options?: MutationOptions<void, string>
  ): MutationResult<void, string> => {
    return useMutation<void, AxiosError, string>(
      [QueryKeys.DeleteCompany],
      (companyName: string) => this.deleteCompany(companyName),
      options
    );
  };

  public deleteCompany = async (companyName: string): Promise<void> => {
    return await this.axios
      .delete(`/api/companies/${companyName}`)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useUpdateCompany = (
    options?: MutationOptions<void, UpdateCompanyDto>
  ): MutationResult<void, UpdateCompanyDto> => {
    return useMutation<void, AxiosError, UpdateCompanyDto>(
      [QueryKeys.UpdateCompany],
      (variables: UpdateCompanyDto) => this.updateCompany(variables),
      options
    );
  };

  public updateCompany = async (body: UpdateCompanyDto): Promise<void> => {
    return await this.axios.put(`/api/companies`, body).then((response: AxiosResponse<void>) => {
      return response.data;
    });
  };

  public useGetCompany = (
    companyId: number,
    options?: QueryOptions<CompanyDto>
  ): QueryResult<CompanyDto> => {
    return useQuery<CompanyDto, AxiosError>(
      [QueryKeys.GetCompany, companyId],
      () => this.getCompany(companyId),
      options
    );
  };

  public getCompany = async (companyId: number): Promise<CompanyDto> => {
    return await this.axios
      .get(`/api/companies/${companyId}`)
      .then((response: AxiosResponse<CompanyDto>) => response.data);
  };
}
