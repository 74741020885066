import React, { FC, ReactElement, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, useForm } from 'react-hook-form';
import { Button, Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material';
import ColorsPicker from './components/ColorsPicker';
import { DeviceGroupDto, MutateDeviceGroup } from '@thingslog/repositories';

const EditGroupModal: FC<EditGroupModalProps> = ({
  deviceGroups,
  handleOnUpdateDeviceGroup,
  deviceGroupToModify,
}: EditGroupModalProps) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<MutateDeviceGroup>({
    defaultValues: structuredClone(deviceGroupToModify),
  });

  const parentDeviceGroups = useMemo(() => {
    return deviceGroups.filter(
      (deviceGroup: DeviceGroupDto) =>
        deviceGroup.deviceGroupId !== deviceGroupToModify.deviceGroupId
    );
  }, [deviceGroups]);

  return (
    <form className="flex flex-col mt-2 gap-5" onSubmit={handleSubmit(handleOnUpdateDeviceGroup)}>
      <TextField
        {...register('deviceGroupName', {
          required: {
            value: true,
            message: t('device_groups_sensors_add_modal_device_group_name_required'),
          },
        })}
        fullWidth
        label={t('device_groups_group_name')}
        size="small"
      />

      <Controller
        control={control}
        name="parentDeviceGroupId"
        render={({
          field,
        }: UseControllerReturn<MutateDeviceGroup, 'parentDeviceGroupId'>): ReactElement => {
          const { value, onChange } = field;
          return (
            <Autocomplete
              options={parentDeviceGroups}
              getOptionLabel={(deviceGroup: DeviceGroupDto): string => deviceGroup.deviceGroupName}
              value={
                parentDeviceGroups.find(
                  (deviceGroup: DeviceGroupDto) => deviceGroup.deviceGroupId === value
                ) ?? null
              }
              onChange={(event: SyntheticEvent, newValue: DeviceGroupDto | null): void => {
                onChange(newValue ? newValue.deviceGroupId : null);
              }}
              renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                <TextField
                  {...params}
                  label={t('device_groups_parent_device_group')}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          );
        }}
      />

      <div className="flex justify-center items-center">
        <Controller
          name="deviceGroupColor"
          control={control}
          render={({
            field,
          }: UseControllerReturn<MutateDeviceGroup, 'deviceGroupColor'>): ReactElement => {
            const { onChange, value } = field;
            return (
              <ColorsPicker
                isColorPickerOpen={isColorPickerOpen}
                setIsColorPickerOpen={setIsColorPickerOpen}
                color={value}
                onColorChange={(color: string): void => onChange(color)}
              />
            );
          }}
        />
      </div>

      {!!errors.deviceGroupName && (
        <span className="m-auto text-red-500 font-semibold">
          <span>{errors.deviceGroupName.message}</span>
        </span>
      )}

      {!isColorPickerOpen && (
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          disableElevation
        >
          {t('device_groups_btn_edit')}
        </Button>
      )}
    </form>
  );
};

interface EditGroupModalProps {
  deviceGroups: DeviceGroupDto[];
  handleOnUpdateDeviceGroup: (body: MutateDeviceGroup) => void;
  deviceGroupToModify: DeviceGroupDto;
}

export default EditGroupModal;
