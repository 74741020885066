export enum SensorType {
  GENERIC_DIGITAL = 'generic_digital_sensor',
  WATER = 'water_meter',
  GAS_FLOW = 'gas_flow_meter',
  POWER = 'power_meter',
  RAIN = 'rain_sensor',
  WIND_SPEED = 'wind_speed_sensor',
  GENERIC_ANALOG = 'generic_analog_sensor',
  PRESSURE = 'pressure_sensor',
  HUMIDITY = 'humidity_sensor',
  TEMPERATURE = 'temperature_sensor',
  LEVEL = 'level_sensor',
  WEIGHT_METER = 'weight_meter',
  WEIGHT_SENSOR = 'weight_sensor',
  LOAD_CELLS = 'load_cells',
  WIND_DIRECTION = 'wind_direction_sensor',
  BAROMETRIC_PRESSURE = 'barometric_pressure_sensor',
  ON_OFF = 'on_off_sensor',
  ON_OFF_INPUT = 'on_off_input_sensor',
  ON_OFF_OUTPUT = 'on_off_output_sensor',
  ON_OFF_AUTO = 'on_off_auto_output_sensor',
  SOIL_MOISTURE = 'soil_moisture_sensor',
  HEAT = 'heat_meter',
  DEW_POINT = 'dew_point_sensor',
  SOIL_TEMPERATURE = 'soil_temperature_sensor',
  SOIL_POTENTIAL = 'soil_potential_sensor',
  PARTICULATE_MATTER = 'particulate_matter_sensor',
  GEIGER_COUNTER = 'geiger_mueller_counter',
  LEAF_WETNESS = 'leaf_wetness_sensor',
}
