import { Box } from '@mui/system';
import React from 'react';

interface WrapperProps {
  children: React.ReactNode;
  variant?: 'small' | 'regular';
}

export const Wrapper: React.FC<WrapperProps> = ({
  children,
  variant = 'regular',
}: WrapperProps) => {
  return (
    <Box maxWidth={variant === 'regular' ? 800 : 400} marginX="auto" width="100%" marginTop={4}>
      {children}
    </Box>
  );
};
