import React, { FC } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { DeviceIconDto } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import { hash } from '@thingslog/ui-components';

const IconDropdown: FC<IconDropdownProps> = ({
  label,
  icons,
  selectedIconId,
  onIconChange,
}: IconDropdownProps): JSX.Element => {
  const { t } = useTranslation();

  const handleOnIconChange = (event: SelectChangeEvent<number>): void => {
    try {
      const value = Number(event.target.value);
      onIconChange(value);
    } catch (error) {
      console.error('An error occurred while handling the icon change:', error);
    }
  };

  const labelId = hash(label) + '-label';

  return (
    <FormControl fullWidth size="small">
      <InputLabel id={labelId}>{t(label)}</InputLabel>
      <Select
        value={selectedIconId}
        onChange={handleOnIconChange}
        labelId={labelId}
        label={t(label)}
        fullWidth
      >
        {icons &&
          icons.map((icon: DeviceIconDto) => (
            <MenuItem key={hash(icon.name)} value={icon.id}>
              <img
                src={icon.icon}
                alt={icon.name}
                style={{ height: '2rem', width: 'auto', marginInlineEnd: '0.5rem' }}
              />
              <span>{icon.name}</span>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

interface IconDropdownProps {
  icons: DeviceIconDto[];
  selectedIconId: number;
  onIconChange: (iconId: number) => void;
  label: string;
}
export default IconDropdown;
