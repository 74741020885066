/**
 * **InitialConfigConverter**
 *
 * This class provides static methods to convert between `DeviceInitialConfig` and `InitialConfigFormFields`.
 *
 * **What It Converts:**
 * - Transforms the `ports` object in `DeviceInitialConfig` into an array of port objects, each with a `portKey`. All other fields and structures are preserved.
 *
 * **Why Conversion is Necessary:**
 * - Initial config contains many nested objects and dynamic fields, which `react-hook-form` and typescript does not handle well. It cannot resolve paths like `ports${portIndex}.sensor.name`.
 * - Avoids the risks associated with casting paths.
 * - Enables the use of `useFieldArray`, the integrated library's hook for dynamic array fields, which provides optimized methods to add and remove fields.
 */

import { DeviceInitialConfig, Port } from '@thingslog/repositories';
import InitialConfigFormFields from '../models/InitialConfigFormFields';

export default class InitialConfigConverter {
  public static portsConfigToFormFields(data: DeviceInitialConfig): InitialConfigFormFields {
    return {
      ...data,
      ports: Object.entries(data.ports).map(([portKey, portData]: [string, Port]) => ({
        portKey: Number(portKey),
        ...portData,
      })),
    };
  }

  public static formFieldsToPortsConfig(formFields: InitialConfigFormFields): DeviceInitialConfig {
    return {
      ...formFields,
      ports: Object.fromEntries(
        formFields.ports.map(({ portKey, ...portData }: { portKey: number } & Port) => [
          portKey,
          portData,
        ])
      ),
    };
  }
}
