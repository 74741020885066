import Axios from './clients/Axios/Axios';

function getCustomerInfo(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/customer/`).then(parseJSON).then(cb).catch(ecb);
}

function putCustomerInfo(deviceNumber, customerInfo, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/devices/${deviceNumber}/customer`, customerInfo, config)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const CustomerInfoClient = {
  getCustomerInfo,
  putCustomerInfo,
};

export default CustomerInfoClient;
