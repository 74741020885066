import {
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
  RelayState,
  RelayStatusDto,
} from '@thingslog/repositories';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';

export interface AutoRelayQueryClient {
  getRelayStatus: (deviceNumber: string, sensorIndex: number) => Promise<RelayState>;
  useRelayStatus: (
    deviceNumber: string,
    sensorIndex: number,
    options?: QueryOptions<RelayState>
  ) => QueryResult<RelayState>;

  getRelayStatuses: (deviceNumber: string) => Promise<RelayStatusDto>;
  useRelayStatuses: (
    deviceNumber: string,
    options?: QueryOptions<RelayStatusDto>
  ) => QueryResult<RelayStatusDto>;

  setRelayStatus: (
    deviceNumber: string,
    sensorIndex: number,
    relayState: RelayState
  ) => Promise<void>;
  useSetRelayStatus: (
    options?: MutationOptions<void, RelayMutationParams>
  ) => MutationResult<void, RelayMutationParams>;
}

interface RelayMutationParams {
  deviceNumber: string;
  sensorIndex: number;
  relayState: RelayState;
}
export function createAutoRelayQueryClient(axios: AxiosInstance): AutoRelayQueryClient {
  return new AutoRelayImp(axios);
}

class AutoRelayImp {
  public constructor(private axios: AxiosInstance) {}

  public getRelayStatus = (deviceNumber: string, sensorIndex: number): Promise<RelayState> => {
    return this.axios
      .get(`/api/v2/devices/${deviceNumber}/${sensorIndex}/auto-relay-switch`)
      .then((response: AxiosResponse<RelayState>) => response.data);
  };

  public useRelayStatus = (
    deviceNumber: string,
    sensorIndex: number,
    options?: QueryOptions<RelayState>
  ): QueryResult<RelayState> => {
    return useQuery<RelayState, AxiosError>(
      [QueryKeys.UseRelayStatus, deviceNumber, sensorIndex],
      () => this.getRelayStatus(deviceNumber, sensorIndex),
      options
    );
  };

  public getRelayStatuses = (deviceNumber: string): Promise<RelayStatusDto> => {
    return this.axios
      .get(`/api/v2/devices/${deviceNumber}/relay-states`)
      .then((response: AxiosResponse<RelayStatusDto>) => response.data);
  };

  public useRelayStatuses = (
    deviceNumber: string,
    options?: QueryOptions<RelayStatusDto>
  ): QueryResult<RelayStatusDto> => {
    return useQuery<RelayStatusDto, AxiosError>(
      [QueryKeys.UseRelayStatuses, deviceNumber],
      () => this.getRelayStatuses(deviceNumber),
      options
    );
  };

  public setRelayStatus = (
    deviceNumber: string,
    sensorIndex: number,
    relayState: RelayState
  ): Promise<void> => {
    return this.axios
      .post(`/api/v2/devices/${deviceNumber}/${sensorIndex}/auto-relay-switch`, undefined, {
        params: {
          relayState: relayState,
        },
      })
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  };

  public useSetRelayStatus = (
    options?: MutationOptions<void, RelayMutationParams>
  ): MutationResult<void, RelayMutationParams> => {
    return useMutation<void, AxiosError, RelayMutationParams>(
      [QueryKeys.UseRelayStatus],
      (params: RelayMutationParams) =>
        this.setRelayStatus(params.deviceNumber, params.sensorIndex, params.relayState),
      options
    );
  };
}
