export enum DisplayTimeUnit {
  MILLIS = 'ms',
  SECONDS = 's',
  MINUTES = 'm',
  HOURS = 'h',
  DAYS = 'd',
  WEEKS = 'w',
  MONTHS = 'mo',
  YEARS = 'y',
  NANOS = 'ns',
  MICROS = 'μs',
  HALFDAYS = 'hd',
  DECADES = 'dec',
  CENTURIES = 'cent',
  MILLENNIA = 'mil',
  ERAS = 'era',
  FOREVER = 'forever',
}
