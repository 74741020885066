import { Device, DeviceLocationDto } from '@thingslog/repositories';
import React, {
  FC,
  useEffect,
  useState,
  useRef,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  RefAttributes,
} from 'react';
import {
  DragEndEvent,
  LatLng,
  Map as LeafletMap,
  LeafletMouseEvent,
  MapMarker,
  LeafletMap as LeafletMapType,
} from '@thingslog/ui-components';
import { BatteryStatus } from '@thingslog/repositories/src/battery/BatteryStatus';
import DeviceSignal from '../../model/API/DeviceSignal/DeviceSignal';
import MapIconManager from './utils/IconManager';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import Description from '../../components/TitleHeader/CustomerInfoDescription';

const DevicesMap: FC<DevicesMapProps> = forwardRef(
  (
    {
      center,
      zoom,
      deviceLocations,
      selectedDeviceNumber,
      deviceBatteries: devicesBatteryArr,
      deviceSignals: devicesSignalArr,
      onMarkerClick,
      onDragEnd,
      deviceNumberFilter,
    }: DevicesMapProps,
    ref: ForwardedRef<{ map: LeafletMapType | null }>
  ) => {
    // #region State
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [deviceBatteries, setDeviceBatteries] = useState<Map<string, BatteryStatus> | null>(null);
    const [deviceSignals, setDeviceSignals] = useState<Map<string, DeviceSignal> | null>(null);
    const iconManager = new MapIconManager();
    const mapRef: ForwardedRef<{ map: LeafletMapType | null }> = useRef<{
      map: LeafletMapType | null;
    } | null>(null);
    // #endregion

    // #region Hooks
    const devices = useSelector((state: ReduxState) => state.dev.devicesArray);
    // #endregion

    // #region Effects
    useImperativeHandle(ref, () => ({
      map: mapRef.current?.map || null,
    }));

    useEffect(() => {
      let newDeviceBatteries: Map<string, BatteryStatus> = new Map<string, BatteryStatus>();
      for (const deviceBattery of devicesBatteryArr) {
        newDeviceBatteries.set(deviceBattery.deviceNumber, deviceBattery);
      }
      setDeviceBatteries(newDeviceBatteries);
    }, [devicesBatteryArr]);

    useEffect(() => {
      let newDeviceSignals: Map<string, DeviceSignal> = new Map<string, DeviceSignal>();
      for (const deviceSignal of devicesSignalArr) {
        newDeviceSignals.set(deviceSignal.deviceNumber, deviceSignal);
      }
      setDeviceSignals(newDeviceSignals);
    }, [devicesSignalArr]);

    useEffect(() => {
      let newMarkers: MapMarker[] = [];
      for (const deviceNumber of Object.keys(deviceLocations)) {
        // Skip marker if number not contained in filter
        if (!isDeviceInFilter(deviceNumber, deviceNumberFilter)) {
          continue;
        }

        const device = devices.find((device: Device) => device.number === deviceNumber);
        const deviceName = getDeviceName(deviceNumber);
        const popupTitle = deviceName ? `${deviceName} (${deviceNumber})` : `${deviceNumber}`;
        const isSelected = deviceNumber === selectedDeviceNumber;
        const location: DeviceLocationDto | undefined = deviceLocations[deviceNumber];

        if (location) {
          let newMarker: MapMarker = {
            markerProps: {
              draggable: true,
              position: [location.latitude, location.longitude],
              icon: iconManager.getIcon(
                getBatteryLevel(deviceNumber),
                getSignalLevel(deviceNumber),
                isSelected
              ),
              eventHandlers: {
                click: (event: LeafletMouseEvent) => {
                  onMarkerClick(deviceNumber);
                },
                dragend: (event: DragEndEvent) => {
                  const latLng: LatLng = event.target['_latlng'];
                  onDragEnd(deviceNumber, latLng.lat, latLng.lng);
                },
                mouseover: (event: LeafletMouseEvent) => {
                  event.target.openPopup();
                },
                mouseout: (event: LeafletMouseEvent) => {
                  event.target.closePopup();
                },
              },
            },
            popupProps: {
              minWidth: 150,
              children: (
                <div className="flex flex-col text-center">
                  <div className="text-base text-gray-600 font-bold">{popupTitle}</div>
                  {device && device.customerInfo && (
                    <Description className="text-sm" customerInfo={device.customerInfo} />
                  )}
                </div>
              ),
              offset: [0, -35],
              closeButton: false,
            },
          };
          newMarkers.push(newMarker);
        }
      }
      setMarkers(newMarkers);
    }, [deviceNumberFilter, deviceLocations, selectedDeviceNumber, deviceBatteries, deviceSignals]);
    // #endregion

    // #region Functions
    const getBatteryLevel = (deviceNumber: string): number | null => {
      let batteryLevel: number | null = null;
      if (deviceBatteries && deviceBatteries.has(deviceNumber)) {
        batteryLevel = deviceBatteries.get(deviceNumber)!.level; // safe
      }
      return batteryLevel;
    };

    const getSignalLevel = (deviceNumber: string): number | null => {
      let signalLevel: number | null = null;
      if (deviceSignals && deviceSignals.has(deviceNumber)) {
        signalLevel = deviceSignals.get(deviceNumber)!.level; // safe
      }
      return signalLevel;
    };

    const getDeviceName = (deviceNumber: string): string | null => {
      let deviceName: string | null = null;
      if (deviceSignals && deviceSignals.has(deviceNumber)) {
        deviceName = deviceSignals.get(deviceNumber)!.deviceName;
      }
      return deviceName;
    };

    const isDeviceInFilter = (deviceNumber: string, deviceNumberFilter?: string[]): boolean => {
      if (!deviceNumberFilter || deviceNumberFilter.length === 0) {
        return true;
      } else if (deviceNumberFilter.includes(deviceNumber)) {
        return true;
      } else {
        return false;
      }
    };
    // #endregion

    return <LeafletMap markers={markers} center={center} zoom={zoom} ref={mapRef} />;
  }
);

interface DevicesMapProps extends RefAttributes<{ map: LeafletMapType | null }> {
  center: [number, number];
  zoom: number;
  deviceLocations: Map<string, DeviceLocationDto>;
  deviceBatteries: BatteryStatus[];
  deviceSignals: DeviceSignal[];
  selectedDeviceNumber: string | null;
  onMarkerClick: (deviceNumber: string) => void;
  onDragEnd: (deviceNumber: string, lat: number, lng: number) => void;
  deviceNumberFilter?: string[];
}

export default DevicesMap;
