import React, { ChangeEvent, FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { analogSensorTypeOptions } from './common/SensorTypeOptions';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import FractionSelector from '../../../../components/FractionSelector/FractionSelector';
import { HelperTextInputField } from '@thingslog/ui-components';
import { AnalogSensorType, FormulaValidationProps } from '@thingslog/repositories';

const GenericAnalogConfig: FC<GenericAnalogConfigProps> = ({
  index,
  formulaToValidateChangeCallback,
}: GenericAnalogConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, getValues }: UseFormReturn<InitialConfigFormFields>): ReactElement => {
        return (
          <>
            <FractionSelector
              className="flex gap-7 items-center justify-center self-start"
              disabled={true}
              fraction={getValues(`ports.${index}.sensor.scale`)}
            />

            <Controller
              control={control}
              name={`ports.${index}.sensor.@type`}
              render={({
                field,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.@type`
              >): ReactElement => (
                <FormControl size="small">
                  <InputLabel>{t('device_config_sensor_type')}</InputLabel>
                  <Select
                    {...field}
                    disabled={field.value === 'modbus_generic_analog_sensor'}
                    label={t('device_config_sensor_type')}
                  >
                    {Object.entries(analogSensorTypeOptions).map(
                      ([value, label]: [string, string]) => (
                        <MenuItem
                          key={value}
                          value={value}
                          disabled={value === 'modbus_generic_analog_sensor'}
                        >
                          {t(label)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name={`ports.${index}.formula`}
              rules={{
                required: {
                  value: true,
                  message: t('sensors_and_network_config_required_field_error'),
                },
              }}
              render={({
                field,
                fieldState,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.formula`
              >): ReactElement => {
                return (
                  <HelperTextInputField
                    {...field}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      const formula = event.target.value || null;
                      field.onChange(formula);

                      if (formula !== null) {
                        formulaToValidateChangeCallback &&
                          formulaToValidateChangeCallback({
                            formula: formula,
                            sensorIndex: index,
                            formulaType: 'port',
                          });
                      }
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    label={t('sensor_config_port_conversion_formula')}
                    size="small"
                  />
                );
              }}
            />

            <Controller
              control={control}
              name={`ports.${index}.sensor.formula`}
              rules={{
                required: {
                  value: true,
                  message: t('sensors_and_network_config_required_field_error'),
                },
              }}
              render={({
                field,
                fieldState,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.formula`
              >): ReactElement => {
                return (
                  <HelperTextInputField
                    {...field}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      const formula = event.target.value || null;
                      field.onChange(formula);

                      if (formula !== null) {
                        formulaToValidateChangeCallback &&
                          formulaToValidateChangeCallback({
                            formula: formula,
                            sensorIndex: index,
                            formulaType: 'sensor',
                          });
                      }
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    label={t('sensor_conversion_formula')}
                    size="small"
                  />
                );
              }}
            />
          </>
        );
      }}
    </ConnectForm>
  );
};

interface GenericAnalogConfigProps {
  index: number;
  formulaToValidateChangeCallback?: (props: FormulaValidationProps) => void;
}

export default GenericAnalogConfig;
