enum EventValueRangeFunction {
  LAST = 'LAST',
  FIRST = 'FIRST',
  AVG = 'AVG',
  MIN = 'MIN',
  MAX = 'MAX',
  MEDIAN = 'MEDIAN',
  COUNT = 'COUNT',
  LAST_FLOW = 'LAST_FLOW',
  FIRST_FLOW = 'FIRST_FLOW',
  AVG_FLOW = 'AVG_FLOW',
  MAX_FLOW = 'MAX_FLOW',
  MIN_FLOW = 'MIN_FLOW',
  MEDIAN_FLOW = 'MEDIAN_FLOW',
}

export default EventValueRangeFunction;
