import { Port } from '../port/Port';

export default interface ConfigModelBase {
  '@type': DeviceType;
  iconName: string;
  isReceiveDataBin: boolean;
  isSendDataBin: boolean;
  ports: PortsConfig;
  transmissionSettings: { countsThreshold: number; every: number; recordPeriod: RecordPeriod };
}

export enum RecordPeriod {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export type PortsConfig = Record<number, Port>;

export type DeviceType =
  | 'LPMDL_1101'
  | 'LPMDL_1102'
  | 'LPMDL_1103'
  | 'LPMDL_1104'
  | 'LPMDL_1105'
  | 'LPMDL_1106'
  | 'BL_100_L'
  | 'W1'
  | 'ZENNER_MICRO'
  | 'ZENNER_EDC'
  | 'INNOTAS_WATERMETER'
  | 'MANUAL_METER'
  | 'MODBUS_SLAVE'
  | 'LOCAL_CONTROLLER';
