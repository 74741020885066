import { IconButton } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';

const ColorPickerButton: FC<ColorPickerButtonProps> = ({
  hexColor,
  onChange,
}: ColorPickerButtonProps) => {
  const colors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#4caf50',
    '#ff9800',
    '#ff5722',
    '#795548',
  ];
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>(hexColor);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);

  const isValidHexColor = (hexString: string): boolean => {
    // Check if the string starts with '#', and followed by 6 hexadecimal characters
    const hexColorRegex = /^#([A-Fa-f0-9]{6})$/;
    return hexColorRegex.test(hexString);
  };

  useEffect(() => {
    setSelectedColor(hexColor);
  }, [hexColor]);

  useEffect(() => {
    if (isValidHexColor(selectedColor)) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [selectedColor]);

  const handleClick = (): void => {
    setShowPicker(!showPicker);
  };

  const handleClose = (): void => {
    setShowPicker(false);
  };

  const handleSubmit = (): void => {
    if (isValidHexColor(selectedColor)) {
      onChange(selectedColor);
      handleClose();
    }
  };

  return (
    <div className="relative">
      <IconButton onClick={handleClick}>
        <div className={`rounded-full w-10 h-10 `} style={{ backgroundColor: hexColor }} />
      </IconButton>
      {showPicker && (
        <div className="absolute left-0 mt-10 z-50">
          <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center  w-80 -mt-14">
            <div>
              <div className="grid grid-cols-5 gap-2 w-[17rem]">
                {colors.map((color: string, index: number) => (
                  <div
                    key={index}
                    className="w-full h-10 rounded cursor-pointer"
                    style={{ backgroundColor: color }}
                    onClick={(event: React.MouseEvent<HTMLDivElement>): void => {
                      event.preventDefault();
                      setSelectedColor(color);
                    }}
                  />
                ))}
              </div>
              <div className="flex justify-between items-center mt-4">
                <div className="relative">
                  <input
                    placeholder="#f44336"
                    type="text"
                    maxLength={7}
                    value={selectedColor}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setSelectedColor(e.target.value)
                    }
                    className="border-2 border-gray-300 rounded-md p-2 w-full"
                  />
                </div>
                <button
                  onClick={handleSubmit}
                  className={'p-1 ml-2 rounded w-[3rem] border-none text-white'}
                  style={{
                    backgroundColor: isSubmitButtonDisabled ? 'gray' : selectedColor,
                  }}
                  disabled={isSubmitButtonDisabled}
                >
                  <DoneIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface ColorPickerButtonProps {
  hexColor: string;
  onChange: (hexColor: string) => void;
}

export default ColorPickerButton;
