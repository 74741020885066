import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';

import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { HelperTextInputField } from '@thingslog/ui-components';

const ModbusGenericAnalogConfig: FC<ModbusGenericAnalogConfigProps> = ({
  index,
}: ModbusGenericAnalogConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ register }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <HelperTextInputField
            disabled
            {...register(`ports.${index}.sensor.numberBytes`)}
            label={t('sensor_config_number_bytes')}
            size="small"
          />
        </>
      )}
    </ConnectForm>
  );
};

interface ModbusGenericAnalogConfigProps {
  index: number;
}

export default ModbusGenericAnalogConfig;
