import * as actions from './../../actions/index';

import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import { GaEventAction } from '../../common/GaEventAction';
import { GaEventCategory } from '../../common/GaEventCategory';
import GoogleAnalyticsService from '../../common/GoogleAnalyticsService';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { ReduxState } from '../../reducers';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Transmission } from '@thingslog/repositories/src/transmissions/Transmission';
import { TransmissionsDto } from '@thingslog/repositories';
import TransmissionsTable from './TransmissionsTable';
import { transmissionsQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useTranslation } from 'react-i18next';
import { GridToolbar, useToast } from '@thingslog/ui-components';
import { AxiosError } from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { GridFilterItem, GridFilterModel, GridRowId } from '@thingslog/ui-components/src/DataGrid';

const TransmissionsPage: FC<TransmissionsProps> = () => {
  const [transmissions, setTransmissions] = useState<Transmission[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const companyId = useSelector((state: ReduxState) => state.company.id);
  const [selectedTableRows, setSelectedTableRows] = useState<GridRowId[]>([]);

  const dataGridFilters: GridFilterItem[] = useSelector(
    (state: ReduxState) => state.dev.dataGridFilters
  );

  const { useTransmissions } = useMemo(() => transmissionsQueryClient, []);

  const {
    data: transmissionsResp,
    isLoading: isTransmissionsLoading,
    isRefetching: isTransmissionsRefetching,
    refetch: refetchTransmissions,
  } = useTransmissions(companyId, {
    onSuccess: (data: TransmissionsDto) => {
      setTransmissions(data.transmissions);
    },
    onError: (error: AxiosError) => {
      navigate('/app/Devices');
      toast({
        type: 'error',
        message: t('transmissions_page_error_message'),
      });
    },
    refetchOnWindowFocus: true,
  });

  const handleOnFilterModelChange = (model: GridFilterModel): void => {
    if (model.items.some((item: GridFilterItem) => item.value !== '')) {
      dispatch(actions.filterChanged(model.items));
    } else {
      dispatch(actions.filterChanged([]));
    }
  };

  return (
    <Header>
      <div className="flex flex-col mb-4">
        <TitleHeader title={t('transmissions_page_title')} />
        <div className="flex h-10 gap-2 self-end">
          <Button
            disableElevation={true}
            disabled={isTransmissionsRefetching || isTransmissionsLoading}
            variant="contained"
            startIcon={
              isTransmissionsRefetching || isTransmissionsLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                <RefreshIcon />
              )
            }
            onClick={(): void => {
              refetchTransmissions();
              GoogleAnalyticsService.triggerEvent(
                GaEventCategory.TRANSMISSIONS_PAGE,
                GaEventAction.TRANSMISSIONS_PAGE_APPLY_BUTTON_CLICKED
              );
            }}
          >
            {t('transmissions_page_refresh')}
          </Button>
        </div>
      </div>
      <TransmissionsTable
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastTransmission', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              devEUI: false,
              simCardNumber: false,
              simCardProvider: false,
              apn: false,
            },
          },
        }}
        autoHeight={true}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number): void => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        pagination
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            csvOptions: { allColumns: true },
          },
        }}
        transmissions={transmissions}
        checkboxSelection
        selectionModel={selectedTableRows}
        onSelectionModelChange={(selectedRowIds: GridRowId[]): void => {
          setSelectedTableRows(selectedRowIds);
          const selectedDeviceRedux = selectedRowIds.length === 1 ? selectedRowIds : [];
          dispatch(actions.devSelected(selectedDeviceRedux, 'radio'));
        }}
        filterModel={{ items: dataGridFilters }}
        onFilterModelChange={handleOnFilterModelChange}
      />
    </Header>
  );
};

export default TransmissionsPage;

interface TransmissionsProps {}
