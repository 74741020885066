import Axios from './clients/Axios/Axios';

function getBasicSensorsConfig(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/sensors`).then(parseJSON).then(cb).catch(ecb);
}

function getPortsConfig(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/initial-config/ports`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getPortsConfigAsync(deviceNumber) {
  return Axios.get(`/api/devices/${deviceNumber}/initial-config/ports`).then(parseJSON);
}

function putPortsConfig(deviceNumber, deviceConfig, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/devices/${deviceNumber}/initial-config/ports`, deviceConfig, config)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getUtilityRatesForDevice(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/utility-rates`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function getUtilityRatesForCompany(companyId, cb, ecb) {
  return Axios.get(`/api/utility-rates`, { params: { companyId } })
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function linkUtilityRateToDeviceSensor(deviceNumber, sensorIndex, utilityRateId, cb, ecb) {
  return Axios.put(
    `/api/devices/${deviceNumber}/sensor/${sensorIndex}/utility-rate/link`,
    undefined,
    {
      params: { utilityRateId: utilityRateId },
    }
  )
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function unlinkUtilityRateFromDeviceSensor(deviceNumber, sensorIndex, cb, ecb) {
  return Axios.put(`/api/devices/${deviceNumber}/sensor/${sensorIndex}/utility-rate/unlink`)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const SensorsConfigClient = {
  getBasicSensorsConfig,
  getPortsConfig,
  putPortsConfig,
  getPortsConfigAsync,
  getUtilityRatesForDevice,
  getUtilityRatesForCompany,
  linkUtilityRateToDeviceSensor,
  unlinkUtilityRateFromDeviceSensor,
};

export default SensorsConfigClient;
