import Axios from './clients/Axios/Axios';

function getMeterInfo(deviceNumber, cb, ecb) {
  return Axios.get(`/api/devices/${deviceNumber}/meter/`).then(parseJSON).then(cb).catch(ecb);
}

function putMeterInfo(deviceNumber, meterInfo, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return Axios.put(`/api/devices/${deviceNumber}/meter`, meterInfo, config)
    .then(parseJSON)
    .then(cb)
    .catch(ecb);
}

function parseJSON(response) {
  return response.data;
}

const MeterInfoClient = {
  getMeterInfo,
  putMeterInfo,
};

export default MeterInfoClient;
