import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { devOpenCloseMenu, devSelected } from '../../actions';

import { Autocomplete, TextField, TextFieldProps, createFilterOptions } from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Device } from '@thingslog/repositories';
import { useToast } from '@thingslog/ui-components';
import { QueryKeys, queryClient } from '@thingslog/queries';
import { MenuItem } from '../../model/Navigation/MenuItem';
import GoogleAnalyticsService from '../../common/GoogleAnalyticsService';
import { GaEventCategory } from '../../common/GaEventCategory';
import JwtValidator from '../../common/JwtValidator';
import { getUpdatedMenuListWithFeatureFlags } from './MenuConfigList';
import MenuLink from './MenuLink';
import SubMenu from './SubMenu';

const SideMenu: React.FC<SideMenuProps> = ({
  companyName,
  username,
  selectedDevice,
  menuOpen,
  switchToOldMenu,
}: SideMenuProps) => {
  const location = useLocation();
  const { deviceNumber } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();
  const [isMainMenu, setIsMainMenu] = useState<boolean>(true);
  const [selectedMenu, setSelectedMenu] = useState<MenuItem[]>([]);
  const companyId = useSelector((state: ReduxState) => state.company.id);
  const companyLogoBlob = useSelector((state: ReduxState) => state.company.companyLogoBlob);
  const [logo, setLogo] = useState<string | undefined>();
  const featureFlagsArray = useSelector(
    (state: ReduxState) => state.featureFlags.featureFlagsArray
  );
  const devices = useSelector((state: ReduxState) => state.dev.devicesArray);
  const licenses = useSelector((state: ReduxState) => state.auth.licenses);
  const hasLicensesAccess = useSelector((state: ReduxState) => state.auth.hasLicensesAccess);

  const jwtValidator = useMemo(() => new JwtValidator(), []);

  useEffect(() => {
    if (companyLogoBlob) {
      const logo = URL.createObjectURL(companyLogoBlob);
      setLogo(logo);

      return () => {
        URL.revokeObjectURL(logo);
      };
    }
  }, [companyLogoBlob]);

  useEffect(() => {
    if (menuOpen) {
      if (deviceNumber) {
        dispatch(devSelected([deviceNumber], 'radio'));
        setIsMainMenu(false);
      } else {
        setIsMainMenu(true);
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    const updatedMenu = getUpdatedMenuListWithFeatureFlags(featureFlagsArray, isMainMenu, {
      link_dashboard: jwtValidator.hasRole('ROLE_BILLING'),
      link_licenses: !hasLicensesAccess,
      link_accounts_manager: featureFlagsArray.includes('CREATE_ACCOUNT_MODAL'),
      link_sensors_and_network_config: !featureFlagsArray.includes('SENSORS_AND_NETWORK_CONFIG'),
    });

    setSelectedMenu(updatedMenu);
  }, [isMainMenu, featureFlagsArray, licenses, hasLicensesAccess, jwtValidator]);

  const handleLinkClick = (gaId?: string): void => {
    if (!selectedDevice && !isMainMenu) {
      toast({
        message: t('error_select_device_message'),
        type: 'error',
        duration: 5000,
      });
    } else {
      dispatch(devOpenCloseMenu(false));
      if (gaId) {
        GoogleAnalyticsService.triggerEvent(GaEventCategory.MENU_NAVIGATION, gaId);
      }
    }
  };

  const replaceDeviceNumberInUrl = (currentURL: string, newDeviceNumber: string): string => {
    let url = '';
    let pathParts = currentURL.split('/');
    pathParts[pathParts.length - 1] = newDeviceNumber;
    url = pathParts.join('/');
    return url.toString();
  };

  const getDevice = useMemo(() => {
    return devices.find((item: Device) => item.number === selectedDevice);
  }, [devices, selectedDevice]);

  return (
    <div
      className={clsx(
        'relative max-sm:absolute max-sm:w-screen h-screen top-0 bg-white border-r border-t-0 border-l-0 border-b-0 border-solid border-black border-opacity-[0.15] shadow-2xl transition-all duration-200 z-50',
        menuOpen ? 'w-[260px] left-0' : 'w-[0px] left-[-260px] max-sm:left-[-115%]'
      )}
    >
      <div className="flex flex-col items-start h-full">
        <div className="flex justify-between w-full px-4 pt-4 text-gray-700">
          <span className="cursor-pointer" onClick={(): void => switchToOldMenu(true)}>
            {t('switch_to_old_menu_label')}
          </span>
          <ArrowBackIosIcon
            className="cursor-pointer"
            onClick={(): void => {
              dispatch(devOpenCloseMenu(false));
            }}
          />
        </div>
        <div className="flex flex-col items-start w-full px-6 pt-8 pb-4">
          <div className="w-full flex justify-center">
            <img src={logo} alt="image" className="max-h-[120px] max-w-[220px] mb-8" />
          </div>
          <span className="max-w-full text-xl truncate" title={companyName}>
            {companyName}
          </span>
          <div className="flex justify-between w-full">
            <span className="w-full text-base text-gray-500 text-left truncate" title={username}>
              {username}
            </span>
          </div>
        </div>
        <div className="w-full h-[2px] bg-gray-200"></div>
        <div className="flex justify-center w-full p-4">
          <div className="w-fit p-1 rounded-lg border-solid border-3 border-cyan-400">
            <button
              className={clsx(
                'min-w-[80px] min-h-[25px] text-sm  border-none rounded-md',
                isMainMenu ? 'text-white bg-cyan-400' : 'bg-transparent'
              )}
              onClick={(): void => setIsMainMenu(true)}
            >
              {t('menu_main')}
            </button>
            <button
              className={clsx(
                'min-w-[80px] min-h-[25px] text-sm border-none rounded-md',
                isMainMenu ? 'bg-transparent' : 'text-white bg-cyan-400'
              )}
              onClick={(): void => setIsMainMenu(false)}
            >
              {t('menu_device')}
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className={clsx('mx-4 my-2', isMainMenu && 'hidden')}>
            {!isMainMenu ? (
              <>
                {jwtValidator.isAllowedUnlessSuperAdminWithoutSelectedCompany(companyId) ? (
                  <Autocomplete
                    disablePortal
                    filterOptions={createFilterOptions({
                      trim: true,
                      matchFrom: 'any',
                    })}
                    disableClearable
                    options={devices}
                    getOptionLabel={(option: Device): string => {
                      const { name, number } = option;
                      return name ? `${name} (${number})` : number;
                    }}
                    size="small"
                    renderInput={(params: TextFieldProps): JSX.Element => (
                      <TextField
                        {...params}
                        label={t('select_device')}
                        InputProps={{
                          ...params.InputProps,
                          style: { paddingRight: '24px' }, // Adjust the padding value as needed
                        }}
                      />
                    )}
                    onChange={(event: React.SyntheticEvent, newValue: Device | null): void => {
                      if (deviceNumber && newValue?.number) {
                        navigate(replaceDeviceNumberInUrl(location.pathname, newValue.number));
                      }
                      dispatch(devSelected([newValue?.number], 'radio'));
                    }}
                    onOpen={(): void => {
                      queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
                    }}
                    value={getDevice}
                  />
                ) : (
                  <div className="text-red-500">{t('select_company_menu_message')}</div>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col flex-1 w-full overflow-y-auto">
          {selectedMenu.map((item: MenuItem, key: number) => (
            <div key={key}>
              {(item.roles.length === 0 || jwtValidator.hasRoles(item.roles)) && (
                <>
                  {item.subMenu ? (
                    <SubMenu
                      menu={item.subMenu}
                      icon={item.icon}
                      name={item.name}
                      isMainMenu={isMainMenu}
                      selectedDevice={selectedDevice}
                      menuOpen={menuOpen}
                      handleLinkClick={handleLinkClick}
                    />
                  ) : (
                    <MenuLink
                      name={item.name}
                      path={item.path}
                      icon={item.icon}
                      gaId={item.ga_id}
                      isMainMenu={isMainMenu}
                      selectedDevice={selectedDevice}
                      pageId={item.id}
                      onClick={handleLinkClick}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface SideMenuProps {
  companyName: string;
  username: string;
  selectedDevice: string | null;
  menuOpen: boolean;
  switchToOldMenu: (value: boolean) => void;
}

export default SideMenu;
