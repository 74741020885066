import React, { FC, useState, useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import { Button } from '@mui/material';
import { useModal, useToast } from '@thingslog/ui-components';
import LicenseValidityIcon from './widgets/LicenseValidityIcon';
import ReadingsPieChart from './widgets/ReadingsPieChart';
import RenewLicenseModal from './components/RenewLicenseModal';
import ConfirmActivityStatusChangeModal from '../license/components/ConfirmActivityStatusChangeModal';
import {
  LicenseDto,
  LicensePerDeviceDto,
  LicensePerReadingDto,
  LicensePerSensorDto,
  LicenseRequest,
  LicenseType,
  CompanyLicenseDto,
} from '@thingslog/repositories';
import JwtValidator from '../../common/JwtValidator';
import LicenseValidator from '../../common/LicenseValidator';

const LicenseInfo: FC<LicenseInfoProps> = ({
  licenses,
  license,
  licenseType,
  translatedLicenseType,
  deviceCountPerCompany,
  addLicense,
  updateLicenseActivityStatus,
}: LicenseInfoProps) => {
  const { t } = useTranslation();

  const { modal, closeModal } = useModal();
  const { hasRole } = useMemo(() => new JwtValidator(), []);

  const [maxColumn, setMaxColumn] = useState<MaxColumn>({ columnName: '', columnValue: '' });

  useEffect(() => {
    renderMaxCountBasedOnLicenseType(licenseType);
  }, [license]);

  const renewLicenseClickHandler = (): void => {
    modal({
      title: t('license_info_renew_button'),
      content: <RenewLicenseModal addLicense={addLicense} license={license} licenses={licenses} />,
    });
  };

  const renderMaxCountBasedOnLicenseType = (licenseType: LicenseType): void => {
    const renderedContent: MaxColumn | null = {
      columnName: '',
      columnValue: '',
    };

    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        const licensePerDevice = license as LicensePerDeviceDto;
        renderedContent.columnName = t('license_max_devices');
        renderedContent.columnValue = licensePerDevice.maxDataLoggers || '';
        break;
      case LicenseType.PER_READING:
        const licensePerReading = license as LicensePerReadingDto;
        renderedContent.columnName = t('license_max_readings');
        renderedContent.columnValue = licensePerReading.maxReadings || '';
        break;
      case LicenseType.PER_SENSOR:
        const licensePerSensors = license as LicensePerSensorDto;
        renderedContent.columnName = t('license_max_sensors');
        renderedContent.columnValue = licensePerSensors.maxSensors || '';
        break;
      default:
        break;
    }

    setMaxColumn(renderedContent);
  };

  const renderUsedCountBasedOnLicenseType = (licenseType: LicenseType): number => {
    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        return deviceCountPerCompany;
      case LicenseType.PER_READING:
        let perReadingLicense = license as LicensePerReadingDto;
        return Number(perReadingLicense.licenseReadingsCount);
      default:
        return 0;
    }
  };

  const renderCountLeftBasedOnLicenseType = (licenseType: LicenseType): number => {
    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        return Number(maxColumn.columnValue) - deviceCountPerCompany;
      case LicenseType.PER_READING:
        let perReadingLicense = license as LicensePerReadingDto;
        return Number(maxColumn.columnValue) - Number(perReadingLicense.licenseReadingsCount);
      default:
        return 0;
    }
  };

  const changeLicenseStatusHandler = (status: 'ACTIVE' | 'INACTIVE'): void => {
    modal({
      title:
        status === 'INACTIVE'
          ? t('profile_page_deactivate_modal_title')
          : t('profile_page_activate_modal_title'),
      content: (
        <ConfirmActivityStatusChangeModal
          status={status}
          updateLicenseActivityStatus={updateLicenseActivityStatus}
          license={license}
          licenseId={(license as CompanyLicenseDto).licenseId}
        />
      ),
    });
  };

  return (
    <div className="flex flex-col py-2">
      <div className="flex flex-col lg:flex-row w-full text-left text-lg items-center">
        {license && (
          <>
            <div className="grid grid-cols-12  w-full lg:w-6/12 mb-10 gap-2">
              <div className="col-span-6 font-bold">
                <div>{t('profile_page_license_type')}</div>
                {LicenseValidator.isCompanyNonPartialLicense(license) && (
                  <>
                    <div>{t('profile_page_valid_from')}</div>
                    <div>{t('profile_page_valid_to')}</div>
                    {maxColumn.columnName && <div className="truncate">{maxColumn.columnName}</div>}
                    <div>{t('profile_page_first_reading')}</div>
                    <div>{t('profile_page_last_reading')}</div>
                  </>
                )}
                <div>{t('profile_page_license_status')}</div>
                {hasRole('ROLE_SUPER_ADMIN') && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-2/5"
                    onClick={(): void => renewLicenseClickHandler()}
                  >
                    {t('license_info_renew_modal_button')}
                  </Button>
                )}
              </div>
              <div className="col-span-6 text-right">
                <div>{translatedLicenseType}</div>
                {LicenseValidator.isCompanyNonPartialLicense(license) && (
                  <>
                    <div>
                      {(license as CompanyLicenseDto).validityPeriodFrom
                        ? format(
                            new Date((license as CompanyLicenseDto).validityPeriodFrom),
                            'dd.MM.yyyy'
                          )
                        : '----'}
                    </div>
                    <div>
                      {(license as CompanyLicenseDto).validityPeriodTo
                        ? format(
                            new Date((license as CompanyLicenseDto).validityPeriodTo),
                            'dd.MM.yyyy'
                          )
                        : '----'}
                    </div>
                    {maxColumn.columnValue && <div>{maxColumn.columnValue}</div>}
                    <div>
                      {(license as CompanyLicenseDto).licenseReadingsFirstDate
                        ? format(
                            new Date((license as CompanyLicenseDto).licenseReadingsFirstDate || ''),
                            'dd.MM.yyyy'
                          )
                        : '----'}
                    </div>
                    <div>
                      {(license as CompanyLicenseDto).licenseReadingsLastDate
                        ? format(
                            new Date((license as CompanyLicenseDto)?.licenseReadingsLastDate || ''),
                            'dd.MM.yyyy'
                          )
                        : '----'}
                    </div>
                  </>
                )}

                <div>
                  {(license as CompanyLicenseDto).isActive
                    ? t('license_status_active')
                    : t('license_status_inactive')}
                </div>
                {hasRole('ROLE_SUPER_ADMIN') && (
                  <>
                    {(license as CompanyLicenseDto).isActive ? (
                      <Button
                        className="float-right"
                        variant="contained"
                        color="error"
                        onClick={(): void => changeLicenseStatusHandler('INACTIVE')}
                      >
                        {t('license_deactivate_license_button')}
                      </Button>
                    ) : (
                      <Button
                        className="float-right"
                        variant="contained"
                        color="success"
                        onClick={(): void => changeLicenseStatusHandler('ACTIVE')}
                      >
                        {t('license_activate_license_button')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="w-full lg:w-6/12 flex flex-col items-center">
              <LicenseValidityIcon licenseValidity={license.isValid} />
              {license.isActive && license.isValid && licenseType === LicenseType.PER_READING && (
                <ReadingsPieChart
                  licenseType={licenseType}
                  usedCount={renderUsedCountBasedOnLicenseType(licenseType)}
                  leftCount={renderCountLeftBasedOnLicenseType(licenseType)}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface MaxColumn {
  columnName: string;
  columnValue: string | number;
}

interface LicenseInfoProps {
  license: LicenseDto;
  licenses: LicenseDto[] | undefined;
  licenseType: LicenseType;
  deviceCountPerCompany: number;
  addLicense: (body: LicenseRequest) => void;
  updateLicenseActivityStatus: ({ licenseId, body }) => void;
  translatedLicenseType: string;
}

export default LicenseInfo;
