import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  DataGridHeader,
  DataGridProps,
  GridColumnHeaderParams,
  GridValueFormatterParams,
} from '@thingslog/ui-components';
import { BatteryStatus } from '@thingslog/repositories/src/battery/BatteryStatus';
import { DeviceFilterAttributes } from '@thingslog/repositories';
import { DateUtil } from '../../common/DateUtil';

const BatteryStatusTable: FC<BatteryStatusTableProps> = ({
  batteryStatusReadings,
  ...props
}: BatteryStatusTableProps) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState<BatteryStatusRow[]>([]);
  const [activeAttribute, setActiveAttribute] = useState<DeviceFilterAttributes | null>(null);
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    let rows: BatteryStatusRow[] = [];
    batteryStatusReadings.forEach((batteryStatus: BatteryStatus) => {
      const { date, voltage, level, deviceNumber, deviceName } = batteryStatus;

      rows.push({
        id: deviceNumber,
        date: date,
        voltage: voltage,
        level: level,
        deviceNumber: deviceNumber,
        deviceName: deviceName,
      });
    });

    if (activeAttribute && filterValue) {
      rows = rows.filter((row: BatteryStatusRow) => {
        return row[activeAttribute].toLowerCase().includes(filterValue.toLowerCase());
      });
    }

    setRows(rows);
  }, [batteryStatusReadings, activeAttribute, filterValue]);

  const handleFilterValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '' || event.target.value === null) {
      setActiveAttribute(null);
      setFilterValue('');
    } else {
      setActiveAttribute(DeviceFilterAttributes[event.target.id]);
      setFilterValue(event.target.value);
    }
  };

  return (
    <DataGrid
      showPrintOption={false}
      selectAllEnabled={true}
      rows={rows}
      columns={[
        {
          field: 'date',
          headerName: t('date'),
          flex: 1,
          minWidth: 140,
          type: 'date',
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            DateUtil.formatTimeZoneAgnosticDate(params.value),
          sortComparator: (BatteryDate1: string, BatteryDate2: string) =>
            new Date(BatteryDate1).getTime() - new Date(BatteryDate2).getTime(),
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName} />;
          },
        },
        {
          field: 'deviceNumber',
          headerName: t('devices_table_device_number'),
          flex: 1,
          minWidth: 170,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={DeviceFilterAttributes.deviceNumber}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'deviceName',
          headerName: t('devices_table_device_name'),
          flex: 1,
          minWidth: 160,
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return (
              <DataGridHeader<DeviceFilterAttributes>
                headerName={params.colDef.headerName}
                attribute={DeviceFilterAttributes.deviceName}
                activeAttribute={activeAttribute}
                filterValue={filterValue}
                onFilterValueChange={handleFilterValueChange}
              />
            );
          },
        },
        {
          field: 'voltage',
          headerName: t('devices_battery_voltage'),
          flex: 0.5,
          minWidth: 160,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName} />;
          },
        },
        {
          field: 'level',
          headerName: t('devices_battery_level'),
          flex: 0.5,
          minWidth: 190,
          type: 'number',
          headerAlign: 'left',
          align: 'center',
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName} />;
          },
        },
      ]}
      {...props}
    />
  );
};

interface BatteryStatusTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  batteryStatusReadings: BatteryStatus[];
}

interface BatteryStatusRow {
  id: string;
  deviceNumber: string;
  deviceName: string;
  date: Date;
  voltage: number;
  level: number;
}

export default BatteryStatusTable;
