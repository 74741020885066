import React, { useEffect, useState, ReactNode } from 'react';
import { DataGrid, DataGridProps } from '@thingslog/ui-components';
import { GridRenderCellParams } from '@thingslog/ui-components/src/DataGrid';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridToolbar } from '@mui/x-data-grid';

const GraphDataGrid: React.FC<GraphDataGridProps> = ({ graphDataGridRows }: GraphDataGridProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState<number>(10);
  const [rows, setRows] = useState<GraphDataGridRow[]>([]);

  useEffect(() => {
    setRows(graphDataGridRows);
  }, [graphDataGridRows]);

  return (
    <DataGrid
      pageSize={size}
      autoHeight={true}
      disableColumnMenu={true}
      hideFooterSelectedRowCount={true}
      rowsPerPageOptions={[10, 25, 50]}
      filterMode="client"
      paginationMode="client"
      density="compact"
      initialState={{ sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] } }}
      components={{
        Toolbar: GridToolbar,
      }}
      onPageSizeChange={(pageSize: number): void => {
        setSize(pageSize);
      }}
      showPrintOption={false}
      rows={rows}
      columns={[
        {
          field: 'timestamp',
          headerName: t('graph_column_date'),
          type: 'date',
          sortable: true,
          filterable: true,
          minWidth: 150,
          flex: 1,
          headerAlign: 'left',
          align: 'left',
          valueGetter: (params: GridRenderCellParams): string => {
            return format(new Date(params.value), 'dd MMM yyyy HH:mm');
          },
          renderCell: (params: GridRenderCellParams): ReactNode => {
            return (
              <Tooltip key={params.id} title={params.value} arrow>
                <span> {params.value}</span>
              </Tooltip>
            );
          },
        },
        {
          field: 'sensor',
          headerName: t('graph_column_sensor'),
          sortable: true,
          filterable: true,
          minWidth: 150,
          flex: 1,
          renderCell: (params: GridRenderCellParams): ReactNode => {
            return (
              <Tooltip key={params.id} title={params.value ? params.value : ''} arrow>
                <span> {params.value ? params.value : ''}</span>
              </Tooltip>
            );
          },
        },
        {
          field: 'index',
          headerName: t('graph_column_index'),
          type: 'number',
          headerAlign: 'left',
          sortable: true,
          filterable: true,
          minWidth: 90,
          flex: 0.5,
          align: 'left',
        },
        {
          field: 'deviceNumber',
          headerName: t('graph_column_device'),
          sortable: true,
          filterable: true,
          flex: 0.75,
          minWidth: 100,
        },
        {
          field: 'deviceName',
          headerName: t('graph_column_device_name'),
          sortable: true,
          filterable: true,
          minWidth: 150,
          flex: 1,
          renderCell: (params: GridRenderCellParams): ReactNode => {
            return (
              <Tooltip key={params.id} title={params.value ? params.value : ''} arrow>
                <span> {params.value ? params.value : ''}</span>
              </Tooltip>
            );
          },
        },
        {
          field: 'reading',
          headerName: t('graph_column_readings'),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          sortable: true,
          filterable: true,
          minWidth: 100,
          flex: 0.75,
          renderCell: (params: GridRenderCellParams<number, GraphDataGridRow>): ReactNode => {
            const { reading, units } = params.row;
            const cellText = `${parseFloat(reading.toFixed(5))} ${units}`;
            return <span key={params.id}> {cellText}</span>;
          },
        },
        {
          field: 'prevReading',
          headerName: t('graph_column_previous_readings'),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          sortable: true,
          filterable: true,
          minWidth: 100,
          flex: 0.75,
          renderCell: (
            params: GridRenderCellParams<number | null, GraphDataGridRow>
          ): ReactNode => {
            const { prevReading, units } = params.row;
            const cellText =
              prevReading !== null ? `${parseFloat(prevReading.toFixed(5))} ${units}` : '';
            return <span key={params.id}> {cellText}</span>;
          },
        },
        {
          field: 'delta',
          headerName: t('graph_column_delta'),
          type: 'number',
          headerAlign: 'left',
          sortable: true,
          filterable: true,
          align: 'left',
          minWidth: 100,
          flex: 0.75,
          renderCell: (
            params: GridRenderCellParams<number | null, GraphDataGridRow>
          ): ReactNode => {
            const { delta, units } = params.row;

            const cellText = delta !== null ? `${parseFloat(delta.toFixed(5))} ${units}` : '';
            return <span key={params.id}> {cellText}</span>;
          },
        },
      ]}
    />
  );
};

interface GraphDataGridProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  graphDataGridRows: GraphDataGridRow[];
}

export interface GraphDataGridRow {
  id: string;
  sensor: string | null;
  index: number;
  deviceNumber: string;
  deviceName: string;
  timestamp: number;
  reading: number;
  prevReading: number | null;
  delta: number | null;
  units: string;
}

export default GraphDataGrid;
