import React, { FC, useMemo } from 'react';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { userQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { UserDto } from '@thingslog/repositories';
import { useToast } from '@thingslog/ui-components';
import UsersTable from './UsersTable';

const Users: FC<UsersProps> = (props: UsersProps) => {
  const companyId = useSelector((state: ReduxState) => state.company.id);

  const { t } = useTranslation();
  const { toast } = useToast();
  const { useUsers, useCreateUser, useDeleteUser, useUpdateUser } = useMemo(
    () => userQueryClient,
    []
  );

  const getUsersQuery = useUsers(companyId, {
    onError: (error: AxiosError) => {
      toast(t('users_load_error'));
    },
    refetchOnWindowFocus: false,
  });

  const updateUserQuery = useUpdateUser({
    onSuccess: () => {
      getUsersQuery.refetch();
      toast({ type: 'success', message: t('users_update_success') });
    },
    onError: (error: AxiosError) => {
      toast({ type: 'error', message: t('users_update_error') });
    },
  });

  const deleteUserQuery = useDeleteUser({
    onSuccess: () => {
      getUsersQuery.refetch();
      toast({ type: 'success', message: t('users_delete_success') });
    },
    onError: (error: AxiosError) => {
      toast({ type: 'error', message: t('users_delete_error') });
    },
  });

  const createUserQuery = useCreateUser({
    onSuccess: () => {
      getUsersQuery.refetch();
      toast({ type: 'success', message: t('users_create_success') });
    },
    onError: (error: AxiosError) => {
      toast({ type: 'error', message: t('users_create_error') });
    },
  });

  const handleCreateUser = (user: UserDto): void => {
    createUserQuery.mutate(user);
  };
  const handleUpdateUser = (user: UserDto): void => {
    updateUserQuery.mutate(user);
  };
  const handleDeleteUser = (username: string): void => {
    deleteUserQuery.mutate(username);
  };

  return (
    <Header>
      <TitleHeader className="mb-4" title={t('users_header')} />
      <UsersTable
        rows={getUsersQuery.data || []}
        onCreateUser={handleCreateUser}
        onUpdateUser={handleUpdateUser}
        onDeleteUser={handleDeleteUser}
        getRowId={(row: UserDto): string => row.email}
        autoHeight={true}
        headerHeight={100}
        rowsPerPageOptions={[10, 25, 50]}
        initialState={{
          pagination: {
            pageSize: 10,
          },
        }}
      />
    </Header>
  );
};

interface UsersProps {}

export default Users;
