import React, { FC, ReactNode, SyntheticEvent } from 'react';

import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';

import { EventValueRange } from '@thingslog/repositories';

const ValueRangeSelector: FC<ValueRangeSelectorProps> = (props: ValueRangeSelectorProps) => {
  return (
    <Autocomplete
      className={props.className}
      multiple
      options={props.allValueRanges}
      getOptionLabel={(option: EventValueRange): string => (option ? option.name : '')}
      onChange={(events: SyntheticEvent<Element, Event>, values: EventValueRange[]): void => {
        props.onValueRangeChange(values);
      }}
      renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
        <TextField
          {...params}
          variant="standard"
          label={props.label}
          InputLabelProps={{ className: 'pr-5' }}
        />
      )}
      limitTags={1}
      value={props.selectedValueRanges}
    />
  );
};

interface ValueRangeSelectorProps {
  label: string;
  className?: string;
  allValueRanges: EventValueRange[];
  selectedValueRanges: EventValueRange[];
  onValueRangeChange: (eventValueRanges: EventValueRange[]) => void;
}

export default ValueRangeSelector;
