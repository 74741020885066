import { differenceInDays, endOfYear, startOfYear, format } from 'date-fns';

export class DateUtil {
  public static daysDifferenceInCurrentYear = (): number => {
    const yearEnd = endOfYear(new Date());
    const yearStart = startOfYear(new Date());
    return differenceInDays(yearEnd, yearStart) + 1;
  };

  public static formatTimeZoneAgnosticDate = (date: string): string => {
    const timezoneRegex = /(\+|-)(\d{2}):(\d{2})$/;

    if (!isNaN(Date.parse(date))) {
      if (date.includes('Z')) {
        const dateWithoutZ = date.substring(0, date.length - 1);
        const formattedDate = format(new Date(dateWithoutZ), 'dd/MM/yyyy HH:mm:ss');
        return `${formattedDate}Z`;
      } else if (timezoneRegex.test(date)) {
        const dateWithoutTimezone = date.replace(timezoneRegex, '');
        const formattedDate = format(new Date(dateWithoutTimezone), 'dd/MM/yyyy HH:mm:ss');
        const timezone = date.slice(-6);
        return `${formattedDate} ${timezone}`;
      } else {
        return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
      }
    } else {
      return '';
    }
  };
}
