import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CustomerBillingReportTable from './CustomerBillingReportTable';
import { customerBillingReportQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import Header from '../../components/header';
import { CircularProgress } from '@mui/material';
import { ReduxState } from '../../reducers';
import { definitions } from '@thingslog/repositories/openapi-typescript/swaggerInterfaces';
import { ErrorResponse } from '@thingslog/repositories';
import { AxiosError } from 'axios';
import { useToast } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';

const CustomerBillingReport: FC<CustomerBillingReportProps> = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const selectedCompanyId = useSelector((state: ReduxState) => state.company.id);
  const { useCustomerBillingReport } = useMemo(() => customerBillingReportQueryClient, []);

  const { data, isLoading } = useCustomerBillingReport(selectedCompanyId, {
    onError: (error: AxiosError<ErrorResponse>) => {
      const errorMessage =
        error.response?.data.message || t('customer_billing_report_default_error');

      toast({
        type: 'error',
        message: errorMessage,
        duration: 5000,
      });
    },
  });

  return (
    <Header>
      {isLoading ? (
        <CircularProgress size={80} />
      ) : (
        <CustomerBillingReportTable
          customerBillingReportRows={
            data
              ? data.map((report: definitions['CustomerBillingReportDto'], index: number) => ({
                  ...report,
                  rowId: index,
                }))
              : []
          }
        />
      )}
    </Header>
  );
};

interface CustomerBillingReportProps {}

export default CustomerBillingReport;
