import {
  DataGrid,
  DataGridProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@thingslog/ui-components';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Units } from '../../model/Units/Units';
import { ReadingsExportDto } from '@thingslog/repositories';

const CountersTable: React.FC<CountersTableProps> = ({
  pageSize,
  autoHeight,
  rowsPerPageOptions,
  onPageSizeChange,
  ...props
}: CountersTableProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState(10);

  const onPageSizeChangeHandler = (pageSize: number): void => setSize(pageSize);

  const renderReadingCell = (
    params: GridRenderCellParams<number | null, ReadingsExportDto>
  ): JSX.Element => {
    let cellContent: string = '';

    const value = params.value;
    const unit = params.row.unit;
    const displayUnit = unit ? Units[unit] : null;

    if (typeof value === 'number') {
      if (displayUnit !== null) {
        cellContent = `${value} ${displayUnit}`;
      } else if (displayUnit === null) {
        cellContent = `${value}`;
      }
    }

    return <span>{cellContent}</span>;
  };

  return (
    <DataGrid
      pageSize={pageSize ? pageSize : size}
      rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50]}
      autoHeight={autoHeight ? autoHeight : true}
      onPageSizeChange={onPageSizeChange ? onPageSizeChange : onPageSizeChangeHandler}
      columns={[
        {
          field: 'date',
          headerName: t('counters_export_table_date'),
          flex: 1,
          minWidth: 150,
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            params.value ? format(new Date(params.value), 'dd MMM yyyy HH:mm') : '',
        },
        {
          field: 'deviceName',
          headerName: t('counters_export_table_device_name'),
          flex: 1,
          minWidth: 140,
        },
        {
          field: 'deviceNumber',
          headerName: t('counters_export_table_device_number'),
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'sensorIndex',
          headerName: t('counters_export_table_sensor_index'),
          headerAlign: 'left',
          type: 'number',
          flex: 1,
          minWidth: 90,
        },
        {
          field: 'sensorName',
          headerName: t('counters_export_table_sensor_name'),
          flex: 1,
          minWidth: 150,
        },
        {
          field: 'counters',
          headerName: t('counters_export_table_counters'),
          type: 'number',
          headerAlign: 'left',
          flex: 1,
          minWidth: 150,
          renderCell: renderReadingCell,
        },
        {
          field: 'previousCounter',
          headerName: t('counters_export_table_prev_counters'),
          type: 'number',
          headerAlign: 'left',
          flex: 1,
          minWidth: 150,
          renderCell: renderReadingCell,
        },
        {
          field: 'dt',
          type: 'number',
          headerAlign: 'left',
          headerName: t('counters_export_table_dt'),
          flex: 1,
          minWidth: 150,
          renderCell: (
            params: GridRenderCellParams<number | null, ReadingsExportDto>
          ): JSX.Element => {
            const value = params.value;

            return <span>{typeof value === 'number' && `${value} s`}</span>;
          },
        },
        {
          field: 'delta',
          type: 'number',
          headerAlign: 'left',
          headerName: t('counters_export_table_delta'),
          flex: 1,
          minWidth: 150,
          renderCell: renderReadingCell,
        },
      ]}
      {...props}
    />
  );
};

interface CountersTableProps extends Omit<DataGridProps, 'columns'> {}

export default CountersTable;
