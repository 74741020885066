import { Theme } from '@mui/material';

import { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export const styles: StyleRulesCallback<Theme, any> = (theme: Theme) =>
  createStyles({
    tableHeader: {
      backgroundColor: theme.palette.primary.light,
    },
    tableHeaderTypography: {
      color: theme.palette.primary.contrastText,
    },
  });
