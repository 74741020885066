import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';

export interface FeatureFlagsQueryClient {
  getEnabledFeatureFlags: () => Promise<string[]>;
  useEnabledFeatureFlags: (
    options?: QueryOptions<string[]>
  ) => UseQueryResult<string[], AxiosError>;
}

export function createFeatureFlagsQueryClient(axios: AxiosInstance): FeatureFlagsQueryClient {
  return new AlarmsExportImp(axios);
}

class AlarmsExportImp {
  public constructor(private axios: AxiosInstance) {}

  public getEnabledFeatureFlags = async (): Promise<string[]> => {
    return this.axios
      .get('/api/feature-flags', { params: { active: true } })
      .then((response: AxiosResponse<string[]>) => {
        return response.data;
      });
  };

  public useEnabledFeatureFlags = (
    options?: QueryOptions<string[]>
  ): UseQueryResult<string[], AxiosError> => {
    return useQuery<string[], AxiosError>(
      [QueryKeys.GetFeatureFlags],
      () => this.getEnabledFeatureFlags(),
      options
    );
  };
}
