import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReduxState } from '../../reducers';
import * as periodActionsCreators from '../../state_management/actions/PeriodSelectionActionCreator';
import { PickerPeriod } from '../../model/Pickers/PickerPeriod';
import { styles } from './PeriodPicker.styles';
import { useTheme } from '@mui/material';
import { addDays, endOfDay, isAfter, isBefore, isValid, startOfDay, subDays } from 'date-fns';
import { LocalizedDateTimePicker } from '@thingslog/ui-components';
import { MUI_LIMIT_DATE_PICKER_MAX_DATE, MUI_LIMIT_DATE_PICKER_MIN_DATE } from './DatePickerLimits';

const ConnectedDateTimePicker: FC<ConnectedDateTimePickerProps> = ({
  period,
  dateDifference,
}: ConnectedDateTimePickerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeFromDate, changeToDate } = bindActionCreators(periodActionsCreators, dispatch);
  const toDateRedux = useSelector((state: ReduxState) => state.period.toDate);
  const fromDateRedux = useSelector((state: ReduxState) => state.period.fromDate);
  const theme = useTheme();
  const classes = styles(theme);

  const [fromDate, setFromDate] = useState<Date | null>(fromDateRedux);
  const [toDate, setToDate] = useState<Date | null>(toDateRedux);
  const [minFromDate, setMinFromDate] = useState<Date | undefined>();
  const [maxToDate, setMaxToDate] = useState<Date | undefined>();

  useEffect(() => {
    setMinFromDate(dateDifference ? startOfDay(subDays(toDateRedux, dateDifference)) : undefined);
  }, [toDateRedux]);

  useEffect(() => {
    setMaxToDate(dateDifference ? endOfDay(addDays(fromDateRedux, dateDifference)) : undefined);
  }, [fromDateRedux]);

  useEffect(() => {
    if (fromDate === null || !isValid(fromDate)) return;
    if (isAfter(fromDate, toDateRedux)) return;
    if (minFromDate && isBefore(fromDate, minFromDate)) return;
    if (isBefore(fromDate, MUI_LIMIT_DATE_PICKER_MIN_DATE)) return;

    changeFromDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    if (toDate === null || !isValid(toDate)) return;
    if (isBefore(toDate, fromDateRedux)) return;
    if (maxToDate && isAfter(toDate, maxToDate)) return;
    if (isAfter(toDate, MUI_LIMIT_DATE_PICKER_MAX_DATE)) return;

    changeToDate(toDate);
  }, [toDate]);

  if (period === PickerPeriod.FROM) {
    return (
      <LocalizedDateTimePicker
        label={t('from_date')}
        value={fromDate}
        onChange={(date: null | Date): void => {
          setFromDate(date);
        }}
        minDateTime={minFromDate}
        maxDateTime={toDateRedux}
        inputFormat="dd/MM/yyyy HH:mm"
        renderInputTextField={{
          size: 'small',
          className: classes.fixedWidthTextField,
          onBlur: (): void => {
            fromDate !== fromDateRedux && setFromDate(fromDateRedux);
          },
        }}
        minutesStep={1}
      />
    );
  } else if (period === PickerPeriod.TO) {
    return (
      <LocalizedDateTimePicker
        label={t('to_date')}
        value={toDate}
        onChange={(date: null | Date): void => {
          setToDate(date);
        }}
        minDateTime={fromDateRedux}
        maxDateTime={maxToDate}
        inputFormat="dd/MM/yyyy HH:mm"
        renderInputTextField={{
          size: 'small',
          className: classes.fixedWidthTextField,
          onBlur: (): void => {
            toDate !== toDateRedux && setToDate(toDateRedux);
          },
        }}
        minutesStep={1}
      />
    );
  }

  return <></>;
};

interface ConnectedDateTimePickerProps {
  period: PickerPeriod;
  dateDifference?: number;
}

export default ConnectedDateTimePicker;
